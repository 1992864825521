import { apiAction } from "../../api";
import { SERVICE_URLS } from "../../../../globals/service-urls";
import { generateParams } from "../../../../helpers/generate-params";
// import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";

// ? TYPES:
// import { ElectiveData } from "../../../types/maps-elective";
import { ApiCallback, ApiAction } from "../../../types/api";

const VERSION = 1;

export const getResilienceBoundary = (callbacks: ApiCallback): ApiAction => {
  const params = {
    disablePagination: true,
  };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["resilience-service"]}${VERSION}/map/boundary${generateParams(params)}`,
    ...callbacks,
  });
};

export const getResilienceHospitals = (callbacks: ApiCallback): ApiAction => {
  const params = {
    disablePagination: true,
  };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["resilience-service"]}${VERSION}/map/hospitals${generateParams(params)}`,
    ...callbacks,
  });
};
