// import API from "../API";
import axios, { AxiosPromise } from "axios";
// import Indicator from "types/indicator.type";
// import { generateApiUrl } from "./config";\
import { SERVICE_URLS } from "../../../globals/service-urls";
import { generateParams } from "../../../helpers/generate-params";
import { SERVICE_NAME, DEFAULT_REPORT_SETTINGS } from "./consts";
import { ImportedConfigIndicator } from "../../../types/forms-config";
import { PastIndicator } from "../../../types/indicator-past";

// ! won't trigger token refresh, but is only used on success with other api that refreshes token

// const SAVE_HEADERS = {
//   "Content-Type": "application/json",
// };

const API_VERSION = 1;

const getAllIndicatorsByIds = (indicatorsIds: number[]): AxiosPromise<any> => {
  const params: any = {
    // ids: indicatorsIds.join(",")
    disablePagination: true,
    // page: 1,
    // limit: 20,
    //Array.from(new Set(indicatorsIds)).join(",")
    indicatorIdList: Array.from(new Set(indicatorsIds)), // ? removes duplicate values
  };
  // console.log("getting indicators", params);
  // return Promise.resolve({ data: { results: [] } }) as any;
  return axios({
    ...DEFAULT_REPORT_SETTINGS(),
    url: `${SERVICE_URLS[SERVICE_NAME]}${API_VERSION}/indicators/currentValues${generateParams(params)}`,
    method: "GET",
  });
};

const getPastIndicatorValues = (indicatorList: ImportedConfigIndicator[]): AxiosPromise<any> => {
  const body: PastIndicator[] = indicatorList.map((indicator: ImportedConfigIndicator) => {
    return {
      idIndicator: +indicator.indicatorId,
      aggregationCondition: indicator.aggregationCondition as string,
      normalisationCondition: indicator.normalisationCondition as string,
      valuePeriod: indicator.valuePeriod,
    };
  });
  // console.log("getting past indicators");
  return axios({
    ...DEFAULT_REPORT_SETTINGS(),
    url: `${SERVICE_URLS[SERVICE_NAME]}${API_VERSION}/indicators/pastValues`,
    data: body,
    method: "POST",
  });
  // return Promise.resolve({ data: {} }) as any;
};

const IndicatorService = {
  getAllIndicatorsByIds,
  getPastIndicatorValues,
};

export default IndicatorService;
