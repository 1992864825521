import { apiAction } from "../api";
import { SERVICE_URLS } from "../../../globals/service-urls";
// import { generateParams } from "../../../helpers/generate-params";

// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";
import { ProfileDetails, NewPassword } from "../../../schemas/my-account.schema";

const getProfile = (callbacks: ApiCallback): ApiAction => {
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["user-service"]}/account/profile`,
    ...callbacks,
  });
};

const getOrganisationList = (callbacks: ApiCallback): ApiAction => {
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["user-service"]}/primary-organisations`,
    ...callbacks,
  });
};

const updateProfile = (values: ProfileDetails, callbacks: ApiCallback): ApiAction => {
  const body = values;
  return apiAction({
    method: "PUT",
    url: `${SERVICE_URLS["user-service"]}/account/profile`,
    data: body,
    ...callbacks,
  });
};

const updatePassword = (values: NewPassword, callbacks: ApiCallback): ApiAction => {
  const body = values;
  return apiAction({
    method: "PUT",
    url: `${SERVICE_URLS["user-service"]}/account/password`,
    data: body,
    ...callbacks,
  });
};

const ProfileService = {
  getProfile,
  getOrganisationList,
  updateProfile,
  updatePassword,
};

export default ProfileService;
