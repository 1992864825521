// ? TYPES
import { Dial } from "../../types/system-dashboard-state";

export const updateDial = (
  dials: Dial[],
  siteId: number,
  newData: Partial<Dial>,
  preserveEscalation = false
): Dial[] => {
  return dials.map((dial) => {
    if (dial.siteId === siteId) {
      const oldUnitList = dial.unitList || [];
      let unitList = newData.unitList || oldUnitList;
      if (preserveEscalation) {
        // ? Preserving escalation status; after updating the dials
        unitList = (unitList || []).map((item, i) => {
          return {
            ...item,
            childUnitList: item.childUnitList.map((child, ci) => {
              return {
                ...child,
                escalated: oldUnitList[i].childUnitList[ci] ? oldUnitList[i].childUnitList[ci].escalated : false,
              };
            }),
            // escalated: oldUnitList[i] ? oldUnitList[i].escalated : false,
          };
        });
      }
      return {
        ...dial,
        hasErrored: false,
        ...newData,
        unitList,
      };
    }
    return dial;
  });
};
