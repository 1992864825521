import { combineReducers } from "redux";
import credentialsReducer from "./credentials";
import menuReducer from "./menu";
import manualUpdatesReducer from "./manual-updates";
import systemDashboardReducer from "./system-dashboard";
import myAccountReducer from "./my-account";
import documentsReducer from "./documents";
import personalisedIndicatorsReducer from "./personalised-indicators";
import indicatorHistoryReducer from "./indicator-history";
import escalationReducer from "./escalation";
import nhsrDocumentsReducer from "./nhsr-documents";
import alertsReducer from "./alerts";
import mapsReducer from "./maps";
import powerBIReducer from "./powerbi";
import reportsReducer from "./reports";
import predictionReducer from "./prediction";
import indexedDbReducer from "./indexed-db";
import integrationsReducer from "./integrations";
// ? TYPES:
import { CredentialsState } from "../types/credentials-state";
import { MenuState } from "../types/menu-state";
import { ManualUpdatesState } from "../types/manual-updates-state";
import { SystemDashboardState } from "../types/system-dashboard-state";
import { MyAccountState } from "../types/my-account-state";
import { DocumentsState } from "../types/documents-state";
import { PersonalisedIndicatorsState } from "../types/personalised-indicators-state";
import { IndicatorHistoryState } from "../types/indicator-history-state";
import { EscalationState } from "../types/escalation-state";
import { AlertsState } from "../types/alerts-state";
import { MapsState } from "../types/maps-state";
import { PowerBIState } from "../types/powerbi-state";
import { ReportsState } from "../types/reports-state";
import { PredictionState } from "../types/prediction-state";
import { IndexedDBState } from "../types/indexed-db-state";
import { IntegrationsStateFull } from "../types/integrations-state";

export interface ApplicationState {
  indexedDbReducer: IndexedDBState;
  credentialsReducer: CredentialsState;
  menuReducer: MenuState;
  manualUpdatesReducer: {
    [contractId: string]: ManualUpdatesState;
  };
  systemDashboardReducer: {
    [systemDashboardId: string]: SystemDashboardState;
  };
  myAccountReducer: MyAccountState;
  documentsReducer: {
    [contractId: string]: DocumentsState;
  };
  personalisedIndicatorsReducer: PersonalisedIndicatorsState;
  indicatorHistoryReducer: {
    [comparisonKey: string]: IndicatorHistoryState;
  };
  escalationReducer: {
    [siteId: string]: EscalationState; // Shrewd Action
  };
  nhsrDocumentsReducer: {
    [contractId: string]: DocumentsState;
  };
  alertsReducer: AlertsState;
  mapsReducer: MapsState;
  powerBIReducer: {
    [reportId: string]: PowerBIState;
  };
  reportsReducer: {
    [organisationId: string]: ReportsState;
  };
  predictionReducer: {
    [dashboardId: string]: PredictionState;
  };
  integrationsReducer: IntegrationsStateFull;
}

const appReducer = combineReducers<ApplicationState>({
  indexedDbReducer,
  credentialsReducer,
  menuReducer,
  manualUpdatesReducer,
  systemDashboardReducer,
  myAccountReducer,
  documentsReducer,
  personalisedIndicatorsReducer,
  indicatorHistoryReducer,
  escalationReducer,
  nhsrDocumentsReducer,
  alertsReducer,
  mapsReducer,
  powerBIReducer,
  reportsReducer,
  predictionReducer,
  integrationsReducer,
});

const rootReducer = (state: ApplicationState | undefined, action: any) => {
  if (action.type === "LOG_OUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
