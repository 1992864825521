import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { VALIDATE_ON } from "../../components/forms";
// ? TYPES:
import { TFunction } from "i18next";
import { ElementDto } from "@forms/validation/model";

interface Props {
  t: TFunction;
  elements: ElementDto[];
  errors: {
    [key: string]: string[];
  };
  submitCount: number;
}

type NameTitleMap = { [name: string]: string };

const SCROLL_OFFSET = -70;

const generateNameTitleMap = (elements: ElementDto[]): NameTitleMap => {
  return elements.reduce((final, current) => {
    const childrenTitles =
      current.children && current.children.length > 0 ? generateNameTitleMap(current.children) : {};
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const gridLayoutTitles = current.fieldType === "gridLayout" ? getGridLayoutValues(current) : {};
    return {
      ...final,
      ...childrenTitles,
      ...gridLayoutTitles,
      [current.elementId]: current.title,
    };
  }, {});
};

function getGridLayoutValues(element: ElementDto) {
  // ? maybe refactor
  return element.rowElements.reduce((finalrow: any, row: any) => {
    return {
      ...finalrow,
      ...row.colElements.reduce((finalcell: any, cell: any) => {
        return {
          ...finalcell,
          ...generateNameTitleMap(cell.value),
        };
      }, {}),
    };
  }, {});
}

const ReportErrorSummaryWoT: React.FC<Props> = ({ errors, elements, submitCount, t }) => {
  const [list, setList] = useState<[string, string][]>([]);
  const [titles, setTitles] = useState<NameTitleMap>({});
  const [closedOn, setClosedOn] = useState(-1);

  useEffect(() => {
    setTitles(generateNameTitleMap(elements));
  }, [setTitles, elements]);
  useEffect(() => {
    setList(
      Object.entries(errors).map(([key, messageList]: [string, string[]]) => {
        return [key, messageList.join(", ")];
      })
    );
  }, [setList, errors]);

  const bringMeTo = (id: string): void => {
    const element = document.querySelector(`[name^='${id}'],[data-name^='${id}']`);
    if (!element) {
      return;
    }
    const parentElement = element.closest(".element");
    if (!parentElement) {
      return;
    }
    const position = parentElement.getBoundingClientRect();
    window.scrollTo({
      top: position.top + window.scrollY + SCROLL_OFFSET,
      left: 0,
      behavior: "smooth",
    });
    // parentElement.scrollIntoView({
    //   behavior: "smooth",
    //   block: "start",
    // });
  };
  if (VALIDATE_ON.submitted === true && submitCount <= 0) {
    return null;
  }
  if (closedOn === submitCount) {
    return null;
  }
  if (list.length <= 0) {
    return null;
  }
  return (
    <div id="report-error-summary">
      <h3>{t("regionReports:validationErrorsSummary", { count: list.length })}</h3>
      <button onClick={() => setClosedOn(submitCount)} className="modal__close" aria-labelledby="Close Modal">
        <span className="u-hide-visually">Close</span>
        <svg className="modal__close-icon" viewBox="0 0 40 40">
          <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
        </svg>
      </button>
      <div className="list__holder">
        <ul className="list bulleted">
          {list.map(([key, message]) => (
            <li onClick={() => bringMeTo(key)} key={key}>
              <b>{titles[key] || "?"}</b> {message}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const ReportErrorSummary = withTranslation(["regionReports"])(ReportErrorSummaryWoT);
