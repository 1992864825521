import React, { useRef, useLayoutEffect, useState, useCallback } from "react";
import { useConfig } from "../providers/config.provider";
// import debounce from "lodash/debounce";
// ? TYPES:
import { ReactNode } from "react";

interface OverlayScrollerNativeProps {
  scrollerPosition?: "absolute" | "fixed" | "sticky";
  maxWidth: (() => number | "auto") | number | "auto";
  maxWidthSuffix?: "px" | "rem" | "em" | "%";
  updateOn: string;
  children: ReactNode;
}

export const OverlayScrollerNative: React.FC<OverlayScrollerNativeProps> = ({
  scrollerPosition = "fixed",
  maxWidthSuffix = "px",
  maxWidth,
  children,
  updateOn,
}) => {
  const { sidebarCollapsedFinished } = useConfig();
  const [mw, setMw] = useState<number | "auto">(typeof maxWidth === "function" ? "auto" : maxWidth);
  const originalContainer = useRef<HTMLDivElement>(null!);
  const fakeContainer = useRef<HTMLDivElement>(null!);
  const widthContainer = useRef<HTMLDivElement>(null!);
  const running = useRef<boolean>(false);
  const [scrollWidth, setScrollWidth] = useState<number>(0);
  const setWidths = function () {
    if (typeof maxWidth === "function") {
      setMw(maxWidth());
    }
    if (widthContainer.current) {
      const sw = widthContainer.current.scrollWidth;
      const newWidth = widthContainer.current.offsetWidth >= sw ? 0 : sw;
      setScrollWidth(newWidth);
    }
  };
  const stableSetWidths = useCallback(setWidths, []);
  useLayoutEffect(() => {
    window.addEventListener("resize", stableSetWidths, true);

    return () => window.removeEventListener("resize", stableSetWidths);
  }, [stableSetWidths]);

  useLayoutEffect(() => {
    stableSetWidths();
  }, [updateOn, sidebarCollapsedFinished, stableSetWidths]);

  // useLayoutEffect(() => {
  //   const newWidth =
  //     widthContainer.current.offsetWidth >= widthContainer.current.scrollWidth ? 0 : widthContainer.current.scrollWidth;
  //   setScrollWidth(newWidth);
  //   // rerender on children change
  // }, []);

  useLayoutEffect(() => {
    const fc = fakeContainer.current;
    const oc = originalContainer.current;
    const scrollFn = function (thisElement: HTMLElement, receptorElement: HTMLElement) {
      if (running.current) {
        running.current = false;
        return;
      }
      running.current = true;
      receptorElement.scrollLeft = thisElement.scrollLeft;
    };
    const fakeScrollFn = function (e: Event) {
      scrollFn(e.target as HTMLElement, oc);
    };
    const originalScrollFn = function (e: Event) {
      scrollFn(e.target as HTMLElement, fc);
    };
    fc.addEventListener("scroll", fakeScrollFn);
    oc.addEventListener("scroll", originalScrollFn);

    return () => {
      fc.removeEventListener("scroll", fakeScrollFn);
      oc.removeEventListener("scroll", originalScrollFn);
      return;
    };
  }, []);

  // inner div (div1, div2) must be the same size
  return (
    <div className="overlay-scroller-wrapper">
      <div
        className="overlay-scroller-content"
        ref={originalContainer}
        style={{ maxWidth: `${mw === "auto" ? "none" : mw + maxWidthSuffix}` }}>
        <div className="overlay-scroller-width" ref={widthContainer}>
          {children}
        </div>
      </div>
      <div
        className="overlay-scroller"
        ref={fakeContainer}
        style={{
          display: scrollWidth === 0 ? "none" : undefined,
          position: scrollerPosition,
          maxWidth: `${mw === "auto" ? "none" : mw + maxWidthSuffix}`,
        }}>
        <div className="overlay-scroller-width-duplicate" style={{ width: `${scrollWidth}px` }} />
      </div>
    </div>
  );
};
