import React from "react";
import { withFormik } from "formik";
import { FormikInput } from "../../components/formik/formik-input";
import { Button } from "@tscore/react-components";
import classNames from "classnames";
import { forgotSchema } from "../../schemas/login.schema";
import { fetchForgotPasswordMail } from "../../store/actions/auth";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

// import { NavLink } from "react-router-dom";

// import { FORGOT_PASSWORD_URL } from "./consts";
// ? TYPES:
import { ApplicationState } from "../../store/reducers";

const forgotFormState = {
  username: "",
};

export const FormForgot = (props: any) => {
  const { t } = useTranslation();
  // console.log(props);
  const {
    isFetching,
    // onKeyDown,
    // receivedErrors,
    values,
    // touched,
    // errors,
    // dirty,
    isSubmitting,
    handleSubmit,
    submitCount,
    onInputChange,
    // apiErrors,
    listOfErrors,
    // forgotMailUsernames,
  } = props;
  const forgotMailUsernames = useSelector((state: ApplicationState) => state.credentialsReducer.mailSent);

  const onKeyDown = ({ keyCode }: any): boolean | void => keyCode === 13 && handleSubmit;
  // const listOfErrors: string[] = [...Object.values(errors), ...receivedErrors];
  // console.log(errors);
  // const listOfErrors: string[] = Object.values({
  //   ...errors,
  //   ...(apiErrors && { general: handleError(apiErrors) }),
  // });
  const isSubmitted = !!forgotMailUsernames[values.username];
  if (isSubmitted) {
    return <p style={{ paddingBottom: "15px" }}>{t("login:forgotSubmitted")}</p>;
  }
  return (
    <>
      <p style={{ paddingBottom: "15px" }}>{t("login:forgotDescription")}</p>
      <form className="login-form" onKeyDown={onKeyDown} onSubmit={handleSubmit}>
        <ul className={classNames("form-error", { "is-visible": submitCount >= 1 && listOfErrors.length >= 1 })}>
          {listOfErrors.map((error: string) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
        <div className="login-input">
          <label>{t("login:Your username")}</label>
          <FormikInput
            onKeyDown={onInputChange}
            name="username"
            placeholder={t("login:Your username")}
            autoComplete="username"
            scale="large"
          />
        </div>
        <div>
          {/* add size="large" */}
          <Button
            className="bold"
            type="submit"
            isLoading={isSubmitting || isFetching}
            scale="large"
            length="fullwidth">
            {t("login:REQUEST PASSWORD RESET")}
          </Button>
        </div>
      </form>
    </>
  );
};

export const formikEnhancerForgot = withFormik({
  validationSchema: ({ t }: { t: TFunction }) => forgotSchema(t),
  mapPropsToValues: (_props: any) => forgotFormState,
  handleSubmit: (values, { setSubmitting, props }) => {
    const payload = {
      ...values,
    };
    props.fetchForgotPasswordMail(payload);
    // dispatch(fetchLogIn(values.username, values.password));
    setSubmitting(false);
  },
  displayName: "ForgotPage",
});

export const mapDispatchToPropsForgot = (dispatch: any) => ({
  fetchForgotPasswordMail: (values: typeof forgotFormState) => dispatch(fetchForgotPasswordMail(values.username)),
  // fetchLogIn: (values: typeof loginForm) => dispatch(fetchLogIn(values.username, values.password)),
});
