import { apiAction } from "../api";
import { SERVICE_URLS } from "../../../globals/service-urls";
import { generateParams } from "../../../helpers/generate-params";
// import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";
import PersonalisedIndicatorsTransform from "./personalised-indicators.transform";
import { getLocalDateStr } from "../../../lang/date-fns";

// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";
import { PaginatedParams } from "../../../types/paginated-params";
import { IndicatorGroup, UpdatedIndicatorGroup } from "../../types/personalised-indicators-state";

const getPersonalisedIndicators = (
  { page = 1, limit = 9999, disablePagination = true, extended = true }: PaginatedParams & { extended?: boolean },
  callbacks: ApiCallback<{ results: IndicatorGroup[] }>,
  apiVersion = 1
): ApiAction => {
  const triggerDateList = [getLocalDateStr()];
  const params = { disablePagination, page, limit, extended, triggerDate: triggerDateList };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["resilience-service"]}${apiVersion}/personalised-indicators${generateParams(params)}`,
    transformResponse: PersonalisedIndicatorsTransform.getPersonalisedIndicators,
    ...callbacks,
  });
};

const getProviders = (
  { page = 1, limit = 9999, disablePagination = true }: PaginatedParams,
  callbacks: ApiCallback,
  apiVersion = 1
): ApiAction => {
  const params = { disablePagination, page, limit };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["resilience-service"]}${apiVersion}/resilience/providers${generateParams(params)}`,
    ...callbacks,
  });
};

// const getCCGs = ({ page, limit, disablePagination = true }: PaginatedParams, callbacks: ApiCallback): ApiAction => {
//   const params = { disablePagination, page, limit };
//   return apiAction({
//     method: "GET",
//     url: `${SERVICE_URLS["resilience-service"]}/resilience/ccgs${generateParams(params)}`,
//     ...callbacks,
//   });
// };

// const getAgenciesByCCGs = (
//   { page, limit, disablePagination = true, ccgIdList }: PaginatedParams & { ccgIdList: number[] },
//   callbacks: ApiCallback
// ): ApiAction => {
//   const params = { disablePagination, page, limit, ccgIdList };
//   return apiAction({
//     method: "GET",
//     url: `${SERVICE_URLS["resilience-service"]}/resilience/agencies${generateParams(params)}`,
//     ...callbacks,
//   });
// };
const getIndicatorsByAgencies = (
  // by organisations
  {
    page = 1,
    limit = 9999,
    disablePagination = true,
    organisationIdList,
  }: PaginatedParams & { organisationIdList: number[] },
  callbacks: ApiCallback,
  apiVersion = 1
): ApiAction => {
  const params = { disablePagination, page, limit, organisationIdList };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["resilience-service"]}${apiVersion}/resilience/indicators${generateParams(params)}`,
    ...callbacks,
  });
};

const updateIndicatorGroup = (
  { organisationId, indicatorList }: UpdatedIndicatorGroup,
  callbacks: ApiCallback,
  apiVersion = 1
): ApiAction => {
  const values = {
    organisationId: organisationId,
    indicatorList: indicatorList,
  };
  return apiAction({
    method: "PATCH",
    data: values,
    url: `${SERVICE_URLS["resilience-service"]}${apiVersion}/personalised-indicators`,
    ...callbacks,
  });
};

const PersonalisedIndicatorsService = {
  getPersonalisedIndicators,
  getProviders,
  // getCCGs,
  // getAgenciesByCCGs,
  getIndicatorsByAgencies,
  updateIndicatorGroup,
};

export default PersonalisedIndicatorsService;
