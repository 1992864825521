const generateWheelScripts = (scripts?: string, id: string = "wheelScripts", callback?: Function): void => {
    if (scripts && !document.getElementById(id)) {
        const script = document.createElement("script");
        script.id = id;
        script.text = scripts;
        document.head.append(script);
        if (callback) callback();
    }
}

export default generateWheelScripts;