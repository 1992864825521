import React, { useRef } from "react";
// import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { useNewVersionHistoryCheck } from "./hooks/use-new-version-history-check";
// import { logOut } from "./store/actions/auth";
// import AuthService from "./store/actions/auth/auth.service";
import { CookieNotice } from "./components/structure/cookie-notice";
import { GlobalMessages } from "./components/structure/global-messages";
// import { LanguageSwitcher } from "./components/structure/language-switcher";

import { RoutesList } from "./utils/RoutesList";
// import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { routes, unauthorizedRoutes } from "./routes";

import { Header, Footer, Sidebar, Title } from "./components/structure";
import { ScrollEvents } from "./hooks/scroll-events";
import ErrorCatcher from "./components/error-catcher";

// ? TYPES:

import { ApplicationState } from "./store/reducers";
import { RouteComponentProps } from "react-router-dom";
import { ModalRefMethods } from "./types/modal";

type AppProps = RouteComponentProps<any>;

const App: React.FC<AppProps> = ({ history }) => {
  const cookieModalRef = useRef<ModalRefMethods>(undefined!);
  // const languageModalRef = useRef<ModalRefMethods>(undefined!);
  // const credentials = useSelector((state: ApplicationState) => state.credentialsReducer.userInfo);
  const isAuthorized = useSelector((state: ApplicationState) => state.credentialsReducer.type === "private");
  const moduleClassName = useSelector((state: ApplicationState) => state.menuReducer.meta.moduleClassName);
  useNewVersionHistoryCheck(history);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   async function auth() {
  //     await dispatch(fetchAuthorizeIfNeeded());
  //   }
  //   auth();
  // }, [dispatch]);

  if (isAuthorized) {
    return (
      <div id="wrapper" className={moduleClassName}>
        <ScrollEvents />
        <Sidebar />
        <section id="holder">
          <div id="header-wrapper">
            <header id="header">
              <Header />
              <Title />
            </header>
          </div>
          <div id="container">
            <div id="header-placeholder" />
            <div id="content">
              <div>
                <article>
                  <ErrorCatcher>
                    <RoutesList routes={routes} />
                  </ErrorCatcher>
                </article>
              </div>
            </div>
            {/* <LanguageSwitcher ref={languageModalRef} /> */}
            <CookieNotice ref={cookieModalRef} />
            <Footer cookieModalRef={cookieModalRef} />
          </div>
        </section>
        <GlobalMessages type="private" />
      </div>
    );
  }
  return (
    <div id="wrapper">
      <ErrorCatcher>
        <RoutesList routes={unauthorizedRoutes} extraProps={{ cookieModalRef: cookieModalRef }} />
      </ErrorCatcher>
      <GlobalMessages type="guest" />
      <CookieNotice ref={cookieModalRef} />
      {/* <LanguageSwitcher ref={languageModalRef} /> */}
    </div>
  );
  // return (
  //   <button style={{ color: "blue" }} onClick={() => dispatch(fetchLogIn("artur", "test"))}>
  //     login
  //   </button>
  // );
};

export default withRouter(App);

// const mapStateToProps = (state: any) => {
//   return {
//     credentialsReducer: state.credentialsReducer,
//   };
// };
// const mapDispatchToProps = (dispatch: any): any => ({
//   isLoggedIn: () => dispatch(isLoggedIn()),
// });

// // export default withRouter(
// //   connect(
// //     mapStateToProps,
// //     mapDispatchToProps
// //   )(App)
// // );

// export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(App);
