import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useTimer } from "react-timer-hook";
import { addSecondsToDate } from "../../../helpers/add-seconds-to-date";
// ? TYPES:
import { TFunction } from "i18next";

interface ResendCodeProps {
  onSend: () => void;
  textKey: string;
  disabledTextKey: string;
  expiryTimestamp: Date;
  // countStartMs?: number;
  t: TFunction;
}

const ResendCodeWoT: React.FC<ResendCodeProps> = ({ onSend, textKey, disabledTextKey, t, expiryTimestamp }) => {
  const { totalSeconds, restart } = useTimer({
    expiryTimestamp: addSecondsToDate(-1),
    autoStart: false,
  });
  useEffect(() => {
    restart(expiryTimestamp, true);
  }, [expiryTimestamp]);
  // const onClick = (e?: any) => {
  //   e.preventDefault();
  //   onSend();
  //   restart(addSecondsToDate(countStartMs), true);
  // };
  if (totalSeconds > 0) {
    return <span>{t(disabledTextKey, { seconds: totalSeconds })}</span>;
  }
  return (
    <button type="button" className="as-link" onClick={onSend}>
      {t(textKey)}
    </button>
  );
};

export const ResendCode = withTranslation()(ResendCodeWoT);
