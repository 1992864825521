import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSiteModule } from "../../store/actions/auth/menu";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { MODULE_URLS } from "../../globals/internal-urls";
import { useTranslation } from "react-i18next";
// ? TYPES:
import { ApplicationState } from "../../store/reducers";
import { RouteComponentProps } from "react-router-dom";
// import { MenuState } from "../../store/types/menu-state";
type TitleProps = RouteComponentProps<any>;

const Title: React.FC<TitleProps> = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSiteModule(location.pathname));
  }, [dispatch, location.pathname]);
  const meta = useSelector((state: ApplicationState) => state.menuReducer.meta);
  const { module, moduleId, title, breadcrumbs, isFetching, hideModuleName } = meta;

  return (
    <nav id="title">
      <div className="isFetching">
        <h1 style={{ marginBottom: "0.8rem" }} className={isFetching ? "loading-element" : undefined}>
          {title}
        </h1>
        <ul className="breadcrumbs">
          {!hideModuleName && (
            <li>
              <NavLink to={MODULE_URLS[module] || "/"}>{t(`g:${moduleId}`)}</NavLink>
            </li>
          )}
          {breadcrumbs.map((b, index) => {
            if (!b) {
              return null;
            }
            return <li key={index}>{typeof b === "string" ? b : <NavLink to={b.to}>{b.name}</NavLink>}</li>;
          })}
        </ul>
      </div>
    </nav>
  );
};

// export default Title;
export default withRouter(Title);
