import React from "react";

export const ErrorField: React.FC<{ error?: string | false }> = ({ error }) => {
  if (!error) {
    return null;
  }
  return (
    <div className="form-error is-visible" style={{ marginTop: "1.2rem" }}>
      {error}
    </div>
  );
};
