import { RecentReport } from "../../../types/forms/recent-report";

export const EMPTY_REPORT: RecentReport = {
  areaId: -1,
  areaName: "",
  accessedAt: null,
  accessedBy: null,
  additionalQuestionList: null,
  approvedDatetime: null,
  createdDatetime: "2020-10-14T17:09:07+0000",
  data: {},
  id: -1,
  idUserApproved: null,
  idUserApprovedBy: null,
  idUserMoreInfoRequested: null,
  idUserMoreInfoRequestedBy: null,
  idUserMoreInfoSubmitted: null,
  idUserMoreInfoSubmittedBy: null,
  idUserRequested: -1,
  idUserRequestedBy: {
    id: -1,
    fullname: "",
    email: "",
    username: "",
  },
  idUserSubmitted: null,
  idUserSubmittedBy: null,
  isAccessableReport: null,
  isOldReport: null,
  moreInfoRequestedDatetime: null,
  moreInfoSubmittedDatetime: null,
  oldReportId: null,
  opelIndicatorId: -1,
  opelIndicatorLevel: -1,
  opelIndicatorValue: null,
  reportTitle: "_UNKNOWN_",
  requestedDatetime: "2020-10-14T17:09:07+0000",
  status: "Requested",
  submittedDatetime: "",
  template: null,
  templateId: "_UNKNOWN_",
};
