import React from "react";
import { SegmentedControl } from "@tscore/react-components";
import { withTranslation } from "react-i18next";
import { Config, useConfig } from "../../providers/config.provider";
// ? TYPES:
import { TFunction } from "i18next";

interface ViewSwitcherProps {
  t: TFunction;
  name: Utils.KeysMatchingType<Config, string>;
}

const ViewSwitcherWoT: React.FC<ViewSwitcherProps> = ({ t, name }) => {
  const { [name]: selected, updateConfig } = useConfig();
  const updateView = (e: any) => {
    const { value } = e.target;
    updateConfig({ viewTableauRepository: value });
  };
  return (
    <SegmentedControl
      name={name}
      iconsOnly={true}
      tooltip={{ placement: "bottom" }}
      options={[
        { icon: "grid_view", label: t("generic:views.grid"), value: "grid" },
        { icon: "view_headline", label: t("generic:views.table"), value: "table" },
      ]}
      selected={selected}
      onChange={updateView}></SegmentedControl>
  );
};

export const ViewSwitcher = withTranslation("generic")(ViewSwitcherWoT);
