import { useCallback, useReducer } from "react";
import useCustomCompareEffect from "../hooks/use-custom-compare-effect";
import { simpleApi } from "../utils/simple-api";
import isEqual from "lodash/isEqual";
// ? TYPES:
import { ApiActionPayload, ApiError } from "../store/types/api";

type Status = "idle" | "loading" | "success" | "error";

type DefaultResponse = undefined | any;

interface State<T = DefaultResponse> {
  status: Status;
  response: T;
  error: undefined | ApiError;
}

function reducer(state: State, action: any): State {
  // if (action.type === "CHANGE_STATUS") {
  //   return {
  //     ...state,
  //     status: action.status,
  //   };
  // }
  if (action.type === "SIMPLE_API_LOADING") {
    if (state.status === "loading") {
      return state;
    }
    return {
      ...state,
      status: "loading",
    };
  }
  if (action.type === "SIMPLE_API_SUCCESS") {
    return {
      ...state,
      status: "success",
      response: action.response,
    };
  }
  if (action.type === "SIMPLE_API_ERROR") {
    return {
      ...state,
      status: "error",
      response: action.defaultResponse,
      error: action.error,
    };
  }
  throw Error("Unknown action.");
}

export const useSimpleApi = <T = DefaultResponse>(
  simpleAction: ApiActionPayload,
  {
    defaultResponse = undefined,
    withAuthorization = true,
    skipStore = false,
    enabled = true,
  }: { defaultResponse?: T; withAuthorization?: boolean; skipStore?: boolean; enabled?: boolean } = {}
) => {
  const [state, dispatch] = useReducer(reducer, {
    status: enabled ? "loading" : "idle",
    response: defaultResponse,
    error: undefined,
  });

  const api = useCallback(
    (simpleAction: ApiActionPayload) => {
      dispatch({ type: "SIMPLE_API_LOADING" });
      simpleApi(
        {
          ...simpleAction,
          onSuccess: (response: T) => dispatch({ type: "SIMPLE_API_SUCCESS", response }),
          onFailure: (error: ApiError) => dispatch({ type: "SIMPLE_API_ERROR", defaultResponse, error }),
        },
        { withAuthorization, skipStore }
      );
    },
    [dispatch, withAuthorization, skipStore]
  );
  useCustomCompareEffect(
    () => {
      if (!enabled) {
        return;
      }
      api(simpleAction);
    },
    [simpleAction, enabled, api],
    isEqual
  );

  return state as State<T>;
};
