import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultApiLoading } from "../default-api-loading";
import { defaultApiSuccess } from "../default-api-success";
// import history from "../../../history";
// import { MODULE_URLS } from "../../../globals/internal-urls";
// import { sortAuthorities } from "./sort-authorities";
import { DEFAULT_PHONE_COUNTRY } from "../../../globals/settings";
import { findPhoneCode } from "../../utils/find-phone-code";
import { addSecondsToDate } from "../../../helpers/add-seconds-to-date";
import { getTimeZoneOffset } from "../../../helpers/to-iso-local";
import { getInitials } from "./get-initials";
import { encrypt } from "../../../utils/simple-encryption";
import { arrayIncludesAnyArrayItem } from "../../../helpers/array-includes-any-array-item";
import { CredentialsState, MobileValues, PreloginRegister } from "../../types/credentials-state";

const DEFAULT_TOTP_DETAILS = {
  label: "",
  otpauthUri: "",
  passcode32: "",
  passcodePretty: "",
};

const DEFAULT_SMS_DETAILS = {
  mobileValues: {
    phoneCountryCode: null,
  },
  sent: false as const,
};

const DEFAULT_RESEND = {
  SMS: addSecondsToDate(-1),
  EMAIL: addSecondsToDate(-1),
  TOTP: new Date(), // not used
};

const RESEND_SECONDS_DELAY = 59;
const SENDAFTER_SECONDS_DELAY = 5 * 60;

const DEFAULT_MFA: CredentialsState["mfa"] = {
  credentials: {
    username: "",
    password: "",
  },
  info: {
    requirement: "SKIP",
    options: [],
    optionsNextMap: {},
  },
  resend: DEFAULT_RESEND,
  canSendAfter: DEFAULT_RESEND,
  totpDetails: DEFAULT_TOTP_DETAILS,
  smsDetails: DEFAULT_SMS_DETAILS,
  setupSuccess: null,
};

const defaultState: CredentialsState = {
  // access_token: null,
  // refresh_token: "",
  // deviceId: "",
  mfa: DEFAULT_MFA,
  localTimeZoneOffset: "+00:00",
  localTimeZone: "Europe/London",
  type: "guest",
  uid: "VA",
  app_user_info: {
    userId: -1,
    username: "",
    fullname: "",
    initials: "??",
    contracts: [],
    productList: [],
  },
  genericRoles: { ANY_ADMIN: false },
  authorities: [],
  authoritiesByOrganisation: {},
  isFetching: {
    LOG_IN: false,
    FORGOT_PASSWORD_MAIL: false,
    ACTIVATE_ACCOUNT: false,
    PRE_LOGIN: false,
  },
  mailSent: {},
  passwordReset: {},
  errors: {},
};

// const REDIRECT_MAP: any = {
//   regions: MODULE_URLS["NHS Region"],
// };

const credentialsReducer = (state: CredentialsState = defaultState, action: any): CredentialsState => {
  switch (action.type) {
    case API_START: {
      if (["PRE_LOGIN"].includes(action.payload)) {
        // reset MFA status on prelogin
        return defaultApiLoading(
          {
            ...state,
            mfa: DEFAULT_MFA,
          },
          action,
          true
        );
      }
      if (["RESEND_MFA_CODE", "REGISTER_MFA"].includes(action.payload) && action.restartTimer) {
        return defaultApiLoading(
          {
            ...state,
            mfa: {
              ...state.mfa,
              resend: {
                ...state.mfa.resend,
                [action.mfaType]: addSecondsToDate(RESEND_SECONDS_DELAY),
              },
            },
          },
          action,
          true
        );
      }
      if (action.payload === "REGISTER_MFA") {
        // clear errors on REGISTER_MFA
        return defaultApiLoading({ ...state, errors: {} }, action, true);
      }
      return defaultApiLoading(state, action, true);
    }
    case API_ERROR: {
      if (["LOG_IN", "PRE_LOGIN", "REGISTER_MFA", "VERIFY_REGISTER_MFA"].includes(action.payload.label)) {
        const error = action.payload.error;
        const errorBase = error.response?.data || { error_description: error.message };
        return defaultApiError(state, {
          ...action,
          payload: {
            ...action.payload,
            errorMessage: errorBase.error_description || errorBase.message,
          },
        });
      }
      if (["FORGOT_SET_PASSWORD", "ACCOUNT_ACTIVATE"].includes(action.payload.label)) {
        const data = action.payload.error.response.data;
        const prevErrorMessage = action.payload.errorMessage;
        const errorMessage: string | string[] =
          (data || { responseObject: prevErrorMessage }).responseObject || prevErrorMessage;
        return defaultApiError(state, {
          ...action,
          payload: {
            ...action.payload,
            errorMessage,
          },
        });
      }
      return defaultApiError(state, action);
    }
    case API_END: {
      return defaultApiLoading(state, action, false);
    }
    case "AUTHORIZE": {
      // update maybe roles etc on refresh
      const localTimeZoneOffset = getTimeZoneOffset(new Date());
      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // eslint-disable-next-line
      console.log("TZ:", localTimeZone, localTimeZoneOffset);
      // console.log(action);
      return {
        ...state,
        localTimeZoneOffset,
        localTimeZone,
        type: "private",
      };
    }
    case "REFRESH_TOKEN": {
      return state;
    }
    case "LOG_IN": {
      // const sortedAuthorities: { authorities: string[]; authoritiesByOrganisation: any } = sortAuthorities(
      //   action.data.userInfo.authorities
      // );
      const sortedAuthorities = {
        authorities: [],
        authoritiesByOrganisation: {},
      };
      const isAnyAdmin = arrayIncludesAnyArrayItem(action.data.app_user_info.roles, [
        "TECHNICAL_ADMIN",
        "SUPPORT_ADMIN",
      ]);
      return {
        ...state,
        type: "private",
        uid: encrypt(action.data.app_user_info.userId, "dannytrejo"),
        // access_token: action.data.access_token,
        // refresh_token: action.data.refresh_token,
        genericRoles: {
          ...action.data.app_user_info.roles.reduce((final: any, currentValue: string) => {
            final[currentValue] = true;
            return final;
          }, {}),
          ANY_ADMIN: isAnyAdmin,
        },
        authorities: sortedAuthorities.authorities,
        authoritiesByOrganisation: sortedAuthorities.authoritiesByOrganisation,
        app_user_info: {
          initials: getInitials(action.data.app_user_info.fullname),
          ...action.data.app_user_info,
        },
        errors: {
          [action.type]: [],
        },
        mfa: {
          ...DEFAULT_MFA,
          setupSuccess: action.setupSuccess,
        },
      };
    }
    case "PRE_LOGIN": {
      const {
        data,
        credentials,
      }: { data: CredentialsState["mfa"]["info"]; credentials: CredentialsState["mfa"]["credentials"] } = action;
      if (data.requirement === "SKIP") {
        return state;
      }
      const defaultPhoneCountryItem =
        data.requirement === "REGISTER" ? findPhoneCode(data.phoneCountryCodeList, DEFAULT_PHONE_COUNTRY) : null;
      return {
        ...state,
        mfa: {
          ...state.mfa,
          resend: DEFAULT_RESEND,
          canSendAfter: {
            ...DEFAULT_RESEND,
            ...(data.requirement === "ENFORCE"
              ? { [data.options[0] as string]: addSecondsToDate(SENDAFTER_SECONDS_DELAY) }
              : {}),
          },
          credentials,
          info: data,
          totpDetails: DEFAULT_TOTP_DETAILS,
          smsDetails: {
            ...DEFAULT_SMS_DETAILS,
            mobileValues: {
              ...state.mfa.smsDetails.mobileValues,
              phoneCountryCode: defaultPhoneCountryItem,
            },
          },
        },
      };
    }
    case "REGISTER_MFA": {
      if (action.mfaType === "TOTP") {
        const passcodeSplit: string[] = action.data.passcode32.match(/.{1,6}/g) || [];
        return {
          ...state,
          mfa: {
            ...state.mfa,
            totpDetails: {
              ...action.data,
              passcodePretty: passcodeSplit.join(" "),
            },
          },
        };
      }
      if (action.mfaType === "SMS") {
        return {
          ...state,
          mfa: {
            ...state.mfa,
            smsDetails: {
              ...state.mfa.smsDetails,
              sent: action.data.message,
            },
          },
        };
      }
      return state;
    }
    case "UPDATE_MFA_MOBILE_LOCAL": {
      const mobileValues: Partial<MobileValues> = action.mobileValues;
      return {
        ...state,
        mfa: {
          ...state.mfa,
          smsDetails: {
            ...state.mfa.smsDetails,
            mobileValues: {
              ...state.mfa.smsDetails.mobileValues,
              ...mobileValues,
            },
            sent: false,
          },
        },
        errors: {},
      };
    }
    // case "UPDATE_PROFILE": {
    //   const fullname = action.data.firstname + " " + action.data.surname;
    //   return {
    //     ...state,
    //     app_user_info: {
    //       ...state.app_user_info,
    //       initials: getInitials(fullname),
    //       username: action.data.username,
    //       fullname: fullname,
    //     },
    //   };
    // }
    case "RESET_MFA_DETAILS": {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          ...(action.resetMap["TOTP"] && { totpDetails: DEFAULT_TOTP_DETAILS }),
          ...(action.resetMap["SMS"] && {
            smsDetails: {
              ...DEFAULT_SMS_DETAILS,
              mobileValues: {
                ...DEFAULT_SMS_DETAILS.mobileValues,
                phoneCountryCode: findPhoneCode(
                  (state.mfa.info as PreloginRegister).phoneCountryCodeList || [],
                  DEFAULT_PHONE_COUNTRY
                ),
              },
            },
          }),
        },
      };
    }
    case "RESEND_MFA_CODE": {
      if (action.restartTimer) {
        return state;
      }
      return {
        ...state,
        mfa: {
          ...state.mfa,
          canSendAfter: {
            ...state.mfa.canSendAfter,
            [action.mfaType]: addSecondsToDate(SENDAFTER_SECONDS_DELAY),
          },
        },
      };
    }
    case "VERIFY_REGISTER_MFA": {
      return state;
    }
    case "CLEAR_AUTH_ERRORS": {
      return {
        ...state,
        errors: {},
      };
    }
    case "LOG_OUT": {
      return defaultState; //! default
    }
    case "FORGOT_PASSWORD_MAIL": {
      // console.log(action);
      return defaultApiSuccess(state, "mailSent", { [action.username]: new Date().toISOString() });
    }
    case "FORGOT_SET_PASSWORD":
    case "ACCOUNT_ACTIVATE": {
      return defaultApiSuccess(state, "passwordReset", { [action.username]: new Date().toISOString() });
    }
    case "CLEAR_ALL_SUCCESS": {
      return {
        ...state,
        mailSent: {},
        passwordReset: {},
      };
    }
    default:
      return state;
  }
};

export default credentialsReducer;
