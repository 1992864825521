import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import useCustomCompareEffect from "../hooks/use-custom-compare-effect";
import { setSiteMeta } from "../store/actions/auth/menu";
import Helmet from "react-helmet";
// import { NavLink } from "react-router-dom";
import isEqual from "lodash/isEqual";
// ? TYPES:
import { TFunction } from "i18next";
import { ApplicationState } from "../store/reducers";
import { Breadcrumb } from "../types/generic";
interface MetaProps {
  title: string;
  t: TFunction;
  breadcrumbs?: Breadcrumb[];
  isFetching?: boolean;
  hideMeta?: boolean;
  hideFooter?: boolean;
  hideModuleName?: boolean;
  children?: any;
  helmetProps?: any;
}

const dangerHtml = `
  #title {
    display:none !important;
  }
  .sticky #header-placeholder::before {
    height:72px !important;
  }`;

const footerDangerHtml = `#footer {display: none;}`;

const Meta: React.FC<MetaProps> = ({
  title,
  t,
  breadcrumbs = [],
  isFetching = false,
  hideMeta = false,
  hideFooter = false,
  helmetProps = {},
  hideModuleName,
  children,
}) => {
  const [lang, setLang] = useState(i18n.language);
  const dispatch = useDispatch();
  useCustomCompareEffect(
    () => {
      dispatch(setSiteMeta(title, breadcrumbs, isFetching, hideModuleName));
    },
    [dispatch, title, breadcrumbs, isFetching],
    isEqual
  );
  useEffect(() => {
    function onChangeLanguage(lng: string) {
      setLang(lng);
    }
    i18n.on("languageChanged", onChangeLanguage);
    return () => i18n.off("languageChanged", onChangeLanguage);
  }, [setLang]);
  const moduleId = useSelector((state: ApplicationState) => state.menuReducer.meta.moduleId);
  return (
    <>
      <Helmet htmlAttributes={{ lang: lang }} titleTemplate={`%s | ${t(moduleId)}`} {...helmetProps}>
        <title>{isFetching ? "-" : title}</title>
        {children}
      </Helmet>
      {hideMeta && <style dangerouslySetInnerHTML={{ __html: dangerHtml }} />}
      {hideFooter && <style dangerouslySetInnerHTML={{ __html: footerDangerHtml }} />}
    </>
  );
};

export default withTranslation(["g"])(Meta);
