import { apiAction } from "../api";
import { SERVICE_URLS } from "../../../globals/service-urls";
import { generateParams } from "../../../helpers/generate-params";

// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";
import {
  IndicatorHistoryBulkResponse,
  IndicatorServiceRequestParams,
  RawGraphConfig,
  ISHExtraParams,
  ISHExtraParamsSystemDashboard,
  ISHExtraParamsPersonalised,
} from "../../types/indicator-history-state";

// const AUTHORIZATION_ISH = {
//   baseURL: process.env.REACT_APP_API_ISH,
//   headers: OVERWRITE_HEADERS({
//     "x-api-key": process.env.REACT_APP_API_ISHKEY,
//   }),
// };

const getIndicators = (
  params: IndicatorServiceRequestParams,
  extraParams: ISHExtraParams,
  callbacks: ApiCallback<IndicatorHistoryBulkResponse>
): ApiAction => {
  function isSysDashboard(p: ISHExtraParams): p is ISHExtraParamsSystemDashboard {
    return (p as ISHExtraParamsSystemDashboard).unitId !== undefined;
  }
  if (isSysDashboard(extraParams)) {
    return getIndicatorsSystemDashboard(params, extraParams, callbacks);
  }
  return getIndicatorsPersonalised(params, extraParams, callbacks);
};

const getIndicatorsSystemDashboard = (
  { ids, freq, agg = "max", start, end, timezone = "auto", originator = "resilience" }: IndicatorServiceRequestParams,
  { systemDashboardId, unitId, product }: ISHExtraParamsSystemDashboard,
  callbacks: ApiCallback<IndicatorHistoryBulkResponse>,
  apiVersion = 1
): ApiAction => {
  const params = {
    frequency: freq,
    aggregation: agg,
    startDate: start,
    endDate: end,
    userTimezone: timezone,
    originator,
    indicatorIdList: ids,
    product,
  };
  return apiAction({
    method: "GET",
    url: `${
      SERVICE_URLS["system-dashboard-service"]
    }${apiVersion}/systemDashboard/${systemDashboardId}/unit/${unitId}/indicator-value-history/bulk${generateParams(
      params,
      {
        encodeValues: true,
      }
    )}`,
    ...callbacks,
  });
};

const getIndicatorsPersonalised = (
  { ids, freq, agg = "max", start, end, timezone = "auto", originator = "resilience" }: IndicatorServiceRequestParams,
  { organisationId }: ISHExtraParamsPersonalised,
  callbacks: ApiCallback<IndicatorHistoryBulkResponse>,
  apiVersion = 1
): ApiAction => {
  const params = {
    frequency: freq,
    aggregation: agg,
    startDate: start,
    endDate: end,
    userTimezone: timezone,
    originator,
    // indicatorIdList: ids,
    organisationId,
  };
  const indicatorId = ids[0]; // no support for bulk
  return apiAction({
    method: "GET",
    url: `${
      SERVICE_URLS["resilience-service"]
    }${apiVersion}/personalised-indicators/${indicatorId}/value-history${generateParams(params, {
      encodeValues: true,
    })}`,
    ...callbacks,
  });
};

const downloadIndicatorGraphUserValueReport = (
  { ids, freq, agg = "max", start, end, timezone = "skip" }: Omit<IndicatorServiceRequestParams, "originator">,
  callbacks: ApiCallback,
  apiVersion = 1
): ApiAction => {
  const params = { freq, agg, startDate: start, endDate: end, indicatorIdList: ids, timezone };
  return apiAction({
    method: "GET",
    responseType: "blob",
    url: `${SERVICE_URLS["resilience-service"]}${apiVersion}/indicatorValuesReport${generateParams(params, {
      encodeValues: true,
    })}`,
    ...callbacks,
  });
};

const getIndicatorGraphConfig = (
  { indicatorId }: { indicatorId: number },
  callbacks: ApiCallback<RawGraphConfig>,
  apiVersion = 1
): ApiAction => {
  const params = { indicatorId };
  return apiAction({
    method: "GET",
    url: `${
      SERVICE_URLS["resilience-service"]
    }${apiVersion}/resilience/indicator-graph-config-formatted${generateParams(params)}`,
    ...callbacks,
  });
};

const getIndicatorGroupGraphConfig = (
  { indicatorGroupId }: { indicatorGroupId: number },
  callbacks: ApiCallback<RawGraphConfig>,
  apiVersion = 1
): ApiAction => {
  const params = { indicatorGroupId };
  return apiAction({
    method: "GET",
    url: `${
      SERVICE_URLS["resilience-service"]
    }${apiVersion}/resilience/indicator-group-graph-config-formatted${generateParams(params)}`,
    ...callbacks,
  });
};

const getGraphConfig = (
  { type, id }: { type: "INDICATOR" | "INDICATOR_GROUP"; id: number },
  callbacks: ApiCallback<RawGraphConfig>
): ApiAction => {
  return type === "INDICATOR"
    ? getIndicatorGraphConfig({ indicatorId: id }, callbacks)
    : getIndicatorGroupGraphConfig({ indicatorGroupId: id }, callbacks);
};

const IndicatorHistoryService = {
  getIndicators,
  downloadIndicatorGraphUserValueReport,
  getGraphConfig,
};

export default IndicatorHistoryService;
