const INFINITY_SYMBOL = "\u221E";
const MINUS_INFINITY_SYMBOL = `\u2212${INFINITY_SYMBOL}`;

export const showThresholdValue = (value: number | null, isEnd = false) => {
  if (value === null || value === undefined) {
    if (isEnd) {
      return INFINITY_SYMBOL;
    }
    return MINUS_INFINITY_SYMBOL;
  }
  return value;
};
