export const joinErrors = (localErrors: { [key: string]: string }, apiErrors: any): { [key: string]: string } => {
  const fetchedErrors =
    apiErrors && Array.isArray(apiErrors)
      ? apiErrors.reduce((final: { [key: string]: string }, current: { field: string; error: string }) => {
          final[current.field] = current.error;
          return final;
        }, {})
      : {};
  return {
    ...localErrors,
    ...fetchedErrors,
  };
};

export const joinErrorsAsArray = (
  localErrors: { [key: string]: string },
  apiErrors: any
): { [key: string]: string[] } => {
  const fetchedErrors: { [key: string]: string[] } =
    apiErrors && Array.isArray(apiErrors)
      ? apiErrors.reduce((final, current: { field: string; error: string; errorList?: string[] }) => {
          final[current.field] = current.errorList ? current.errorList : [current.error];
          return final;
        }, {})
      : {};
  return {
    ...Object.entries(localErrors).reduce((final, [key, value]) => {
      final[key] = [value];
      return final;
    }, {} as { [key: string]: string[] }),
    ...fetchedErrors,
  };
};
