import React, { Suspense, lazy } from "react";
import { LexicalRichEditor } from "@tscore/react-components/lexical";
import { useField } from "formik";

// ? TYPES
import { ComponentProps } from "../../types/generic";

type LexicalRichEditorProps = ComponentProps<typeof LexicalRichEditor>;

const LexicalRichEditorLazy = lazy(async () =>
  import(/* webpackChunkName: "lexical-rte" */ "@tscore/react-components/lexical").then(({ LexicalRichEditor }) => ({
    default: LexicalRichEditor,
  }))
);

const Fallback = () => <div>...</div>;

export const FormikRichText = ({
  _name,
  placeholder,
  setFieldValue,
  ...props
}: any & LexicalRichEditorProps): JSX.Element => {
  const [{ onChange, ...field }] = useField(props);
  const handleChange: LexicalRichEditorProps["onBlur"] = (editorState, _editor, _tags, editorTextState) => {
    const payload = {
      state: JSON.stringify(editorState),
      plaintext: editorTextState,
    };
    setFieldValue(_name, payload);
  };
  const initialState = field.value[_name].state;
  return (
    <Suspense fallback={<Fallback />}>
      <LexicalRichEditorLazy
        namespace={_name}
        editorState={initialState}
        placeholder={placeholder}
        {...field}
        onBlur={handleChange}
      />
    </Suspense>
  );
};

export const ViewRichText: React.FC<{ value: { state: string; plaintext: string } }> = ({ value }) => {
  return (
    <div className="view-accent">
      <Suspense fallback={<Fallback />}>
        <LexicalRichEditorLazy editorState={value.state} type="preview" />
      </Suspense>
    </div>
  );
};
