import { slugify } from "../../../helpers/slugify";
import { generateParams } from "../../../helpers/params";
// ? TYPES:
import { ImportedOrganisation, ListGeneratorSettings } from "../../types/menu-state";

export const urlGenerator = (
  url: string,
  paramValues: (string | number)[],
  name: string | number,
  searchParamValues: { [key: string]: number | string } = {},
  dontSlugify = false
): string => {
  const searchParams = generateParams(searchParamValues);
  return `${url}/${paramValues.join("/")}${!dontSlugify ? "/" + slugify(name) : ""}${searchParams}`;
};

export const listGenerator = (
  organisationList: ImportedOrganisation[],
  urlString: string,
  {
    paramNameList = ["id"],
    slugName = "name",
    extraParams = [],
    dontSlugify,
    searchParamNameList = [],
  }: ListGeneratorSettings
) => {
  return organisationList.map((item: ImportedOrganisation) => {
    // const paramValues = paramNameList.reduce((final: any[], current: keyof ImportedOrganisation) => {
    //   final.push(item[current]);
    //   return final;
    // }, []);
    const paramValues = paramNameList.map((paramName) => item[paramName]);
    const searchParamValues = searchParamNameList.reduce((final, paramName) => {
      const a = item[paramName];
      return {
        ...final,
        [paramName]: Array.isArray(a) ? a.join(",") : a,
      };
    }, {});
    return {
      ...item,
      name: item[slugName],
      url: urlGenerator(urlString, [...paramValues, ...extraParams], item[slugName], searchParamValues, dontSlugify),
    };
  });
};
