import { useState, useEffect } from "react";
import { thirdPartyCookieTest } from "../helpers/third-party-cookie-test";

export function useThirdPartyCookieTest({ enabled = true }) {
  const [thirdPartyCookiesEnabled, setThirdPartyCookiesEnabled] = useState(true);
  useEffect(() => {
    if (enabled) {
      thirdPartyCookieTest((isEnabled) => {
        setThirdPartyCookiesEnabled(isEnabled);
      });
    }
  }, [setThirdPartyCookiesEnabled, enabled]);
  return thirdPartyCookiesEnabled;
}
