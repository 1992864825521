import React from "react";
import { useSelector } from "react-redux";
import { withFormik } from "formik";
import { FormikInput } from "../../components/formik/formik-input";
import { Button } from "@tscore/react-components";
import classNames from "classnames";
import { loginSchema } from "../../schemas/login.schema";
import { fetchPreLogin } from "../../store/actions/auth";
import history from "../../history";
import { useTranslation } from "react-i18next";

import { NavLink } from "react-router-dom";

import { FORGOT_PASSWORD_URL } from "./consts";
// ? TYPES:
import { TFunction } from "i18next";
import { ApplicationState } from "../../store/reducers";

const loginFormState = {
  username: "",
  password: "",
};

export const FormLogin = (props: any) => {
  const { t } = useTranslation();
  // console.log(props);
  const {
    isFetching,
    // onKeyDown,
    // receivedErrors,
    // values,
    // touched,
    // errors,
    // dirty,
    isSubmitting,
    handleSubmit,
    submitCount,
    onInputChange,
    // apiErrors,
    listOfErrors,
  } = props;
  const isFetchingToken = useSelector((state: ApplicationState) => state.credentialsReducer.isFetching["LOG_IN"]);
  const onKeyDown = ({ keyCode }: any): boolean | void => keyCode === 13 && handleSubmit;
  // const listOfErrors: string[] = [...Object.values(errors), ...receivedErrors];
  // console.log(errors);
  // const listOfErrors: string[] = Object.values({
  //   ...errors,
  //   ...(apiErrors && { general: handleError(apiErrors) }),
  // });
  return (
    <form className="login-form" onKeyDown={onKeyDown} onSubmit={handleSubmit}>
      <ul className={classNames("form-error", { "is-visible": submitCount >= 1 && listOfErrors.length >= 1 })}>
        {listOfErrors.map((error: string) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
      <div className="login-input">
        <label>{t("login:username")}</label>
        <FormikInput
          onKeyDown={onInputChange}
          name="username"
          placeholder={t("login:username")}
          autoComplete="username"
          scale="large"
        />
      </div>
      <div className="login-input">
        <label>{t("login:password")}</label>
        <FormikInput
          onKeyDown={onInputChange}
          name="password"
          placeholder={t("login:password")}
          autoComplete="current-password"
          type="password"
          scale="large"
        />
      </div>
      <div>
        {/* add size="large" */}
        <Button
          className="bold"
          type="submit"
          isLoading={isSubmitting || isFetching || isFetchingToken}
          scale="large"
          length="fullwidth">
          {t("login:SIGN IN")}
        </Button>
      </div>
      <div className="ta-center">
        <NavLink to={FORGOT_PASSWORD_URL} className="bold">
          {t("login:Forgot Password?")}
        </NavLink>
      </div>
    </form>
  );
};

export const formikEnhancerLogin = withFormik({
  validationSchema: ({ t }: { t: TFunction }) => loginSchema(t),
  mapPropsToValues: (_props: any) => loginFormState,
  handleSubmit: (values, { setSubmitting, props }) => {
    const payload = {
      ...values,
    };
    // console.log(props);
    setTimeout(() => {
      props.fetchPreLogin(payload, history.location.pathname);
      // dispatch(fetchLogIn(values.username, values.password));
      setSubmitting(false);
    }, 5);
  },
  displayName: "LoginPage",
});

export const mapDispatchToPropsLogin = (dispatch: any) => ({
  fetchPreLogin: (values: typeof loginFormState, pathname = "") => dispatch(fetchPreLogin(values, pathname)),
});

// export const LoginPage = connect(null, mapDispatchToPropsLogin)(formikEnhancerLogin);
