import React from "react";
import Meta from "../../../components/meta";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loader } from "@tscore/react-components";
import { INTERNAL_URLS } from "../../../globals/internal-urls";
import { useTranslation } from "react-i18next";
import { REROUTE_CONFIG } from "./home/consts";

//? TYPES:
import { ApplicationState } from "../../../store/reducers";
import { RouteComponentProps } from "react-router-dom";
import { urlGenerator } from "../../../store/reducers/menu/list-generator";

type HomePageProps = RouteComponentProps<{}>;

export const HomePage: React.FC<HomePageProps> = () => {
  const { t } = useTranslation();
  const state = useSelector((state: ApplicationState) => state.menuReducer);
  const { isFetching, rawMenu } = state;
  const list: { id: number; name: string }[] =
    (rawMenu[REROUTE_CONFIG.SERVICE_NAME] || {})[REROUTE_CONFIG.LIST_NAME] || [];
  // useEffect(() => {
  //   if (isFetching === false && list.length > 0) {
  //     const url = INTERNAL_URLS[CONFIG.LIST_NAME] || "";
  //     history.push(url + "/" + list[0].id);
  //   }
  // }, [isFetching, list, history]);
  return (
    <main id="vantage-map" className="google-map-holder" style={{ padding: 0 }}>
      <Meta title={t("vantageHome:title")} hideMeta hideFooter />
      <div id="map" className="google-map shadowed" style={{ height: "100%" }}>
        <Loader placement="top left" />
        {isFetching === false && list.length > 0 && (
          <Redirect to={urlGenerator(INTERNAL_URLS[REROUTE_CONFIG.LIST_NAME], [list[0].id], list[0].name)} />
        )}
      </div>
    </main>
  );
};
