import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Tippy, Input, Select, Button } from "@tscore/react-components";
import Moment from "react-moment";
import { momentTimeDateFormat } from "../../../lang/DateTimeFormats";
// import classNames from "classnames";
import { BRAG_PRESSURE_LEVEL_MAPPING } from "../../../helpers/brag-helpers";
import { STATUS_COLOUR_MAPPING, GENERATE_STATUSES } from "./consts";
import get from "lodash/get";
import useElementSize from "../../../hooks/use-element-size";
import { UserList } from "./user-list";
import { useEffectOnce } from "../../../hooks/use-effect-once";
import { useTranslation } from "react-i18next";
// ? TYPES:
// import { ApplicationState } from "../../../store/reducers";
import { Action, ActionHistory, EscalationUser } from "../../../store/types/escalation-state";
import UserInfo from "../../../types/user-info";
import { ApplicationState } from "../../../store/reducers";

const PRESSURE_LEVEL_CELL_STYLES = {
  padding: 0,
  display: "table-cell",
  width: "1px",
  borderRadius: 0,
  boxShadow: "none",
};

const UPDATE_BACKGROUND = "#f6f9fd";

export const ActionTable: React.FC<{
  actionSelectorKeys: string | (string | number)[];
  // actions: Action[] | ActionHistory[];
  credentials: UserInfo;
  usersAssigned?: Action["usersAssigned"];
  showUsersAssigned?: boolean;
  onSave?: (values: { id: number; status: string; comment?: string }[]) => void;
  pdSiteId: number;
  isLastTable?: boolean;
  selectedFns?: {
    onSelect: (action: Action) => void;
    selectedAction: Action | { usersAssigned: EscalationUser[]; id: number; [other: string]: any };
  };
}> = ({
  actionSelectorKeys,
  selectedFns,
  // usersAssigned = [],
  credentials,
  showUsersAssigned = true,
  onSave,
  pdSiteId,
  isLastTable = false,
}) => {
  const { t } = useTranslation();
  const STATUSES = GENERATE_STATUSES(t);
  const [updated, setUpdated] = useState<{
    [actionId: number]: {
      id: number;
      status?: string;
      comment?: string;
    };
  }>({});
  const descriptionRef = useRef(null);
  const { width } = useElementSize(descriptionRef);
  const isUpdating = useSelector(
    (state: ApplicationState) => state.escalationReducer[pdSiteId].isFetching["UPDATE_ESCALATION_ACTIONS"]
  );
  const actions = useSelector((state: ApplicationState) =>
    get(state.escalationReducer[pdSiteId], actionSelectorKeys, [])
  ) as Action[] | ActionHistory[];
  useEffect(() => {
    if (isUpdating === false) {
      setUpdated({});
    }
  }, [isUpdating]);
  useEffectOnce(() => {
    selectedFns?.onSelect(actions[0]);
  }, [actions.length > 0]);
  const onValueChange = ({ value }: { value: string; label?: string }, type: "status" | "comment", id: number) => {
    setUpdated({
      ...updated,
      [id]: {
        ...updated[id],
        id,
        [type]: value === "" ? undefined : value,
      },
    });
  };
  const saveActions = () => {
    if (!onSave) {
      return;
    }
    const filteredUpdated = Object.values(updated)
      .map((entry) => {
        const previousStatus = actions.find((a) => a.id === entry.id)?.status;
        return {
          ...entry,
          ...(!entry.status && { status: previousStatus || "error" }),
        } as { id: number; status: string; comment?: string };
      })
      .filter((action) => {
        if (action.comment === undefined) {
          const previousStatus = actions.find((a) => a.id === action.id)?.status;
          if (previousStatus === action.status) {
            return false;
          }
          return true;
        }
        return true;
      });
    if (filteredUpdated.length > 0) {
      onSave(filteredUpdated);
    }
  };
  return (
    <div>
      <table className="table bordered main">
        <thead>
          <tr>
            <th style={{ padding: "0 2px" }}>{t("escalation:actionTable.0")}</th>
            <th style={{ borderLeftColor: "transparent" }} ref={descriptionRef}>
              {t("escalation:actionTable.1")}
            </th>
            <th className="nowrap">{t("escalation:actionTable.2")}</th>
            <th>{t("escalation:actionTable.3")}</th>
            <th className="nowrap">{t("escalation:actionTable.4")}</th>
            {selectedFns && <th className="w1 nowrap">{t("escalation:actionTable.5")}</th>}
          </tr>
        </thead>
        <tbody>
          {(actions as ActionHistory[]).map((action, i) => {
            const isLast = isLastTable && actions.length === i + 1;
            const statusColour = STATUS_COLOUR_MAPPING.getColour(action.status);
            const colour = (BRAG_PRESSURE_LEVEL_MAPPING as any)[action.pressureLevel];
            return (
              <Fragment key={action.actionHistoryId || action.id}>
                <tr
                  className={selectedFns?.selectedAction?.id === action.id ? "selected-row" : ""}
                  onClick={() => selectedFns?.onSelect(action)}>
                  <td className={`brag brag-${colour}`} style={PRESSURE_LEVEL_CELL_STYLES}></td>
                  <td>
                    <span>{action.description}</span>
                    {showUsersAssigned && (
                      <UserList usersAssigned={action.usersAssigned} currentUserId={credentials.userId} width={width} />
                    )}
                  </td>
                  <td style={{ width: "144px" }} className="ta-center nowrap">
                    <span className="escalation-status" style={statusColour}>
                      {t("escalation:statuses." + action.status, { defaultValue: action.status })}
                    </span>
                  </td>
                  <td style={{ minWidth: "240px" }} className="w1">
                    {action.comment || t("escalation:N/A")}
                  </td>
                  <td style={{ width: "130px" }} className="nowrap">
                    <div>{action.updatedBy.fullname}</div>
                    <div className="meta">
                      <Tippy
                        content={
                          <div style={{ fontSize: "1.1rem" }}>
                            <span>{t("escalation:tooltipLastUpdated")} </span>
                            <Moment fromNow>{action.updatedAt}</Moment>
                          </div>
                        }
                        placement="right">
                        <span>
                          <Moment format={momentTimeDateFormat}>{action.updatedAt}</Moment>
                        </span>
                      </Tippy>
                    </div>
                  </td>
                  {selectedFns && (
                    <td className="ta-center" style={{ padding: 0 }}>
                      <label className="radio" style={{ paddingLeft: "16px" }}>
                        <input type="radio" checked={selectedFns.selectedAction?.id === action.id} />
                        <span />
                      </label>
                      <span className="table-link"></span>
                    </td>
                  )}
                </tr>
                {!!onSave && (
                  <tr>
                    <td
                      className={`brag brag-${colour}`}
                      style={{ ...PRESSURE_LEVEL_CELL_STYLES, borderTopColor: "transparent" }}></td>
                    <td
                      style={{
                        borderTopColor: "transparent",
                      }}
                      className="ta-right"></td>
                    <td
                      style={{
                        width: "144px",
                        paddingLeft: "0.6rem",
                        paddingRight: "0.6rem",
                        background: UPDATE_BACKGROUND,
                      }}
                      className="ta-center nowrap">
                      <Select
                        menuPlacement={isLast ? "top" : "auto"}
                        minMenuHeight={172}
                        options={STATUSES}
                        onChange={(value: any) => onValueChange(value, "status", action.id)}
                        defaultValue={STATUSES.find((status) => status.value === action.status)}
                      />
                    </td>
                    <td
                      colSpan={2}
                      style={{ minWidth: "370px", borderLeftColor: "transparent", background: UPDATE_BACKGROUND }}
                      className="w1 nowrap">
                      <Input
                        value={(updated[action.id] || { comment: "" }).comment}
                        onChange={(e: any) => onValueChange({ value: e.target.value }, "comment", action.id)}
                        isTextarea
                        placeholder={t("escalation:commentPlaceholder")}
                      />
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      {/* {showUsersAssigned && (
        <aside className="ta-right mt12" style={{ fontSize: "1.2rem" }}>
          <div className="escalation-user-list">
            {usersAssigned.map((user) => (
              <span
                className={classNames("escalation-user", { current: credentials.userId === user.id })}
                key={user.id}>
                {user.fullname}
              </span>
            ))}
          </div>
        </aside>
      )} */}
      {!!onSave && (
        <aside className="ta-right mt12">
          <Button isLoading={isUpdating} length="longer" onClick={() => saveActions()}>
            {t("generic:Save")}
          </Button>
        </aside>
      )}
    </div>
  );
};
