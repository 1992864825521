import { momentUpdatedTimeDateFormat } from "../../lang/DateTimeFormats";
// ? TYPES:
import { ReportType } from "../../store/types/reports-state";
import { TKnownRbaNames } from "../../store/reducers/menu/consts";

// ? >> NAME INFO
// ? >> Old name: Winter Report ==> New name: Regional Report

export const DATE_TIME_FORMAT = momentUpdatedTimeDateFormat; // "LT, DD MMM YYYY"; // "HH:mm, DD MMM yyyy" // momentTimeDateFormat;
export const WINTER_REPORT_NAME = "winterReport";
export const WINTER_REPORT_COSMETIC_NAME = "regional-report";
export const WINTER_REPORT_SERVER_NAME = "regional-report";
export const RBA_PARTIAL = (
  reportType: ReportType,
  suffix: "SUBMITTER" | "REQUESTER" | "FILLER" | "DELETE"
): TKnownRbaNames =>
  reportType === WINTER_REPORT_NAME
    ? (`WINTER_REPORT_${suffix}` as TKnownRbaNames)
    : (`OTHER_REPORT_${suffix}` as TKnownRbaNames);

export const COMPLETED_STATUS = "Completed";
