import { FOLDER_ID_DELIMINATOR } from "../../../globals/tableau-settings";
import { IntegrationPlot } from "../../reducers/integrations/consts";
// ? TYPES:
import { IntegrationListItem } from "../../types/integrations-state";

type AppendedKeys = "dynamicId" | "dynamicIdKey" | "linkOrigin" | "linkId";

function getDynamicId(item: Omit<IntegrationListItem, AppendedKeys>) {
  const dynamicIdKey: keyof IntegrationListItem = item.contractId > 0 ? "contractId" : "integrationLinkId";
  return {
    dynamicIdKey,
    dynamicId: item[dynamicIdKey],
  };
}

function getIntegrationLinkDetails(item: Omit<IntegrationListItem, AppendedKeys>): {
  linkOrigin: string;
  linkId: string;
} {
  if (item.integrationId === IntegrationPlot.TABLEAU) {
    const url = new URL(item.link);
    const [tFolder, tId] = url.pathname.split("/").slice(-2);
    const linkId = tFolder && tId ? tFolder + FOLDER_ID_DELIMINATOR + tId : "";
    return {
      linkOrigin: url.origin,
      linkId,
    };
  }
  return { linkOrigin: "", linkId: "" };
}

function updateIntegrationListItem(item: Omit<IntegrationListItem, AppendedKeys>): IntegrationListItem {
  return {
    ...item,
    link: "",
    description: item.description || "",
    ...getIntegrationLinkDetails(item),
    ...getDynamicId(item),
  };
}

export const transformGetIntegrations = (response: {
  results: Omit<IntegrationListItem, AppendedKeys>[];
}): { results: IntegrationListItem[] } => {
  return {
    ...response,
    results: response.results.map(updateIntegrationListItem),
  };
};
