import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultApiLoading } from "../default-api-loading";
import { defaultPagination } from "../default-pagination";
import { paginate } from "../../../helpers/paginate";
import { downloadFile } from "../../../helpers/download-file";
// import { defaultApiSuccess } from "../default-api-success";
import { EMPTY_REPORT } from "./empty-report";
// ? TYPES:
import { ReportsState } from "../../types/reports-state";
import { ImportedAccessInfo } from "../../types/menu-state";

const defaultState: ReportsState = {
  organisationList: {
    "-1": [],
  },
  reportTypeNames: {},
  reports: [],
  isFetching: {},
  errors: {},
  didInvalidate: {}, // not used
  meta: defaultPagination,
  apiMeta: {
    areaList: [],
  },
  downloadProgress: {},
  downloadCSSAssets: [],
};

const report = (state: ReportsState = defaultState, action: any): ReportsState => {
  switch (action.type) {
    case API_START: {
      return defaultApiLoading(state, action, true, [
        "GET_REPORT_LIST",
        "REQUEST_REPORT",
        "REQUEST_MORE_INFO_WINTER_REPORT",
        "DOWNLOAD_NHS_REPORT",
        "GET_LOCAL_MANIFEST_DATA",
      ]);
    }
    case API_ERROR: {
      return defaultApiError(state, action);
    }
    case API_END: {
      return defaultApiLoading(state, action, false, [
        "GET_REPORT_LIST",
        "REQUEST_REPORT",
        "REQUEST_MORE_INFO_WINTER_REPORT",
        "DOWNLOAD_NHS_REPORT",
        "GET_LOCAL_MANIFEST_DATA",
      ]);
    }
    case "GET_REPORT_LIST": {
      const apiMeta: ReportsState["apiMeta"] = action.data.meta || defaultState.apiMeta;
      return {
        ...state,
        reports: action.data.results,
        apiMeta: {
          ...apiMeta,
          areaList: apiMeta.areaList.sort((a, b) => Number(b.active) - Number(a.active)),
        },
        meta: paginate(action.data.totalRecords, action.page, action.data.limit),
      };
    }
    case "REQUEST_REPORT": {
      if (state.meta.currentPage !== 1) {
        return state;
      }
      const nowIso = new Date().toISOString();
      return {
        ...state,
        reports: [
          {
            ...EMPTY_REPORT,
            id: -1,
            createdDatetime: nowIso,
            requestedDatetime: nowIso,
            status: "Requested",
            idUserRequestedBy: action.userInfo,
            areaId: action.id,
            areaName: action.organisationName,
          },
          ...state.reports,
        ],
        meta: paginate(state.meta.totalItems + 1, state.meta.currentPage, state.meta.pageSize + 1),
      };
    }
    case "DELETE_REPORT": {
      // console.log({ action, z: state.reports });
      return {
        ...state,
        reports: state.reports.filter((report) => report.id !== action.reportId),
        meta: paginate(state.meta.totalItems - 1, state.meta.currentPage, state.meta.pageSize - 1),
      };
    }
    case "REQUEST_MORE_INFO_WINTER_REPORT": {
      return {
        ...state,
        reports: state.reports.map((report) => {
          if (report.id === action.reportId) {
            const nowIso = new Date().toISOString();
            return {
              ...report,
              status: "More info requested",
              moreInfoRequestedDatetime: nowIso,
              idUserMoreInfoRequestedBy: action.userInfo,
              idUserMoreInfoRequested: action.userInfo.userId,
            };
          }
          return report;
        }),
      };
    }
    case "GET_MENU": {
      // if (action.id !== "all") {
      //   console.log("WDWD", action);
      //   return state;
      // }
      const data: ImportedAccessInfo = action.data;
      const dataNhsr: ImportedAccessInfo["nhsr-service"] =
        data["nhsr-service"] ||
        ({
          winterReportOrganisationList: [],
          otherReportOrganisationList: [],
        } as unknown as ImportedAccessInfo["nhsr-service"]);
      const otherReportOrganisationList: { [reportId: string]: any[] } = dataNhsr.otherReportOrganisationList.reduce(
        (final: { [reportId: string]: any[] }, current) => {
          // console.log({ current });
          // if (current.feature.OTHER_REPORT_SUBMITTER) {
          const newValue = {
            value: current.areaId,
            label: current.areaName,
            reportLabel: current.reportTypeName,
            REPORT_SUBMITTER: current.feature.OTHER_REPORT_SUBMITTER,
            REPORT_REQUESTER: current.feature.OTHER_REPORT_REQUESTER,
            REPORT_FILLTER: current.feature.OTHER_REPORT_FILLER,
          };
          const prevValue = final[current.reportTypeId];
          const list: any[] = prevValue ? [...prevValue, newValue] : [newValue];
          return {
            ...final,
            [current.reportTypeId]: list,
          };
          // }
          // return final;
        },
        {}
      );
      const winterReport = dataNhsr.winterReportOrganisationList
        // .filter((item) => !!item.feature!.WINTER_REPORT_REQUESTER)
        .map((item) => ({
          label: item.name,
          value: item.id,
          reportLabel: "Winter Report",
          REPORT_SUBMITTER: item.feature!.WINTER_REPORT_SUBMITTER,
          REPORT_REQUESTER: item.feature!.WINTER_REPORT_REQUESTER,
          REPORT_FILLER: item.feature!.WINTER_REPORT_FILLER,
        }));
      const reportTypeNames: { [reportTypeId: string]: string } = Object.entries(otherReportOrganisationList).reduce(
        (acc, [reportTypeId, arr]) => {
          const firstItem = arr[0] || {};
          return {
            ...acc,
            [reportTypeId]: firstItem.reportLabel,
          };
        },
        {}
      );
      return {
        ...state,
        reportTypeNames,
        organisationList: {
          ...state.organisationList,
          winterReport: winterReport,
          ...otherReportOrganisationList,
        },
      };
    }
    case "UPDATE_REPORT_DOWNLOAD_PROGRESS": {
      return {
        ...state,
        downloadProgress: {
          ...state.downloadProgress,
          [action.reportId]: action.percentage,
        },
      };
    }
    case "GET_LOCAL_MANIFEST_DATA": {
      return {
        ...state,
        downloadCSSAssets: action.cssAssets,
      };
    }
    case "DOWNLOAD_NHS_REPORT":
      // download here
      downloadFile(action.data, action.fileName + ".pdf");
      return {
        ...state,
        downloadProgress: {
          ...state.downloadProgress,
          [action.reportId]: 100,
        },
      };
    default:
      return state;
  }
};

const reportsById = (
  state: { [reportTypeId: string]: ReportsState } = { "-1": defaultState, all: defaultState },
  action: any
): any => {
  switch (action.type) {
    case API_START:
    case API_ERROR:
    case API_END:
    case "GET_REPORT_LIST":
    case "GET_MENU":
    case "REQUEST_REPORT":
    case "DELETE_REPORT":
    case "DOWNLOAD_NHS_REPORT":
    case "GET_LOCAL_MANIFEST_DATA":
    case "UPDATE_REPORT_DOWNLOAD_PROGRESS":
    case "REQUEST_MORE_INFO_WINTER_REPORT": {
      if (!action.id && action.type !== "GET_MENU") {
        return state;
      }
      const id = action.type === "GET_MENU" || action.type === "REQUEST_REPORT" ? "all" : action.id; //action.id
      return {
        ...state,
        [id]: report(state[id], action),
      };
    }
    default:
      return state;
  }
};

export default reportsById;
