import { useCallback, useReducer, useEffect, useRef } from "react";
import useCustomCompareEffect from "./use-custom-compare-effect";
import isEqual from "lodash/isEqual";

interface State<T = unknown> {
  key: number;
  alwaysKey: number;
  resetKey: T;
}

const initialState: State = {
  key: 0,
  alwaysKey: 0,
  resetKey: undefined,
};

function createInitialState<T>({ resetKey }: { resetKey: T }): State<T> {
  return {
    ...initialState,
    resetKey: resetKey,
  };
}

const isDocumentVisible = () => document.visibilityState === "visible";

function reducer(state: State, action: any): State {
  if (action.type === "RESET") {
    return createInitialState({ resetKey: action.resetKey });
  }
  if (action.type === "SET_KEY") {
    return {
      ...state,
      key: action.value,
    };
  }
  if (action.type === "INCREMENT_ALWAYS_KEY") {
    const alwaysKey = state.alwaysKey + 1;
    return {
      ...state,
      alwaysKey: alwaysKey,
      key: isDocumentVisible() ? alwaysKey : state.key,
    };
  }
  if (action.type === "VISIBILITY_CHANGE") {
    if (!isDocumentVisible()) {
      return state;
    }
    return {
      ...state,
      key: state.alwaysKey,
    };
  }
  // if (action.type === "SET_ALWAYS_KEY") {
  //   return {
  //     ...state,
  //     alwaysKey: action.alwaysKey,
  //   };
  // }
  throw Error("Unknown action.");
}

export function useActiveTabTimerKey<T = (string | number)[]>(
  { interval, resetKey: originalResetKey }: { interval: number; resetKey?: T } = {
    interval: 5 * 60000,
    resetKey: undefined as T,
  }
) {
  // const [visibilityState, setVisibilityState] = useState(true);
  const [{ key, resetKey }, localDispatch] = useReducer(reducer, { resetKey: originalResetKey }, createInitialState);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const handleVisibilityChange = useCallback(() => {
    localDispatch({ type: "VISIBILITY_CHANGE" });
  }, []);

  useCustomCompareEffect(
    () => {
      localDispatch({ type: "RESET", resetKey: originalResetKey });
    },
    [localDispatch, originalResetKey],
    isEqual
  );

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    timer.current = setTimeout(() => {
      localDispatch({ type: "INCREMENT_ALWAYS_KEY" });
    }, interval);
    return () => clearTimeout(timer.current!);
  }, [localDispatch, key]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange, false);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange, false);
    };
  }, [handleVisibilityChange]);

  return [key, resetKey] as [number, T];
}
