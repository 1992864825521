import React from "react";
import { Input } from "@tscore/react-components";
import { TEMP_LANG } from "../forms/temp-lang";
// ? TYPES
import { ComponentProps } from "../../types/generic";
import { useField } from "formik";
// import { FieldInputProps } from "formik";

// type FormikInputExtendedProps = React.HTMLProps<HTMLInputElement> & React.HTMLAttributes<HTMLInputElement>;

interface FormikDurationProps extends ComponentProps<typeof Input> {
  _name?: string;
  // name?: string;
  validateNumeric: (event: any, config: any) => boolean;
  setFieldValue: Function;
  [key: string]: any; // TODO InputProps
}

export const FormikDuration: React.FC<FormikDurationProps> = ({
  _name,
  validateNumeric,
  setFieldValue,
  name,
  ...props
}) => {
  const [
    {
      value: { h, m },
    },
  ] = useField({
    ...props,
    name: _name || name,
  } as any);
  const onChange = (event: any) => {
    const { name, value } = event.target;
    const newValue = value === "" ? "" : parseInt(value, 10);
    setFieldValue(name, newValue);
  };
  const settings: any = {
    onKeyPress: (event: any) => validateNumeric(event, {}),
    min: 0,
    type: "number",
    style: { width: "94px" },
  };
  // console.log({ field, _meta, _helpers });
  return (
    <>
      <Input
        {...props}
        {...settings}
        max={99}
        value={h}
        onChange={onChange}
        name={_name + ".h"}
        placeholder={TEMP_LANG.hour_plural}
      />
      <Input
        {...props}
        {...settings}
        max={59}
        value={m}
        onChange={onChange}
        name={_name + ".m"}
        placeholder={TEMP_LANG.minute_plural}
      />
    </>
  );
};
