import * as yup from "yup";
// ? TYPES:
import { TFunction } from "i18next";

export const documentEditSchema = (t: TFunction) =>
  yup
    .object()
    .required()
    .shape({
      documentId: yup.string().required(),
      documentName: yup.string().required().label(t("documents:Document Name")),
      description: yup.string(),
    });

export type EditDocumentMeta = yup.InferType<ReturnType<typeof documentEditSchema>>;
