import { apiAction } from "../api";
import { SERVICE_URLS } from "../../../globals/service-urls";
import { generateParams, generateSortApiArray } from "../../../helpers/generate-params";
import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";
import { transformGetIntegrations } from "./get-integration-list.transform";
// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";
import { PaginatedParams } from "../../../types/paginated-params";
import { TIntegrationId, TIntegrationTypeId } from "../../reducers/integrations/consts";
import { IntegrationsListResponse } from "../../types/integrations-state";

interface GetIntergrationListPropsBase {
  integrationId: TIntegrationId;
  integrationTypeId: TIntegrationTypeId;
  search?: string;
}

interface GetIntergrationListPropsWithContracts extends GetIntergrationListPropsBase {
  contractIds: number[];
  dashboardIds?: never;
}

interface GetIntergrationListPropsWithDashboards extends GetIntergrationListPropsBase {
  contractIds?: never;
  dashboardIds: number[];
}

type GetIntergrationListProps = GetIntergrationListPropsWithContracts | GetIntergrationListPropsWithDashboards;

const getIntegrationList = (
  {
    page = 1,
    limit = DEFAULT_PAGE_LIMIT,
    disablePagination,
    integrationId,
    integrationTypeId,
    search,
    sort,
    dashboardIds,
    contractIds,
  }: PaginatedParams & GetIntergrationListProps,
  callbacks: ApiCallback<IntegrationsListResponse>
): ApiAction => {
  const ver = 1;
  const sortList = generateSortApiArray(sort);
  const params = {
    page,
    limit,
    disablePagination,
    search,
    sort: sortList,
    dashboardIdList: dashboardIds,
    contractIdList: contractIds,
  };
  return apiAction({
    method: "GET",
    url: `${
      SERVICE_URLS["integration-service"]
    }${ver}/integrations/${integrationId}/${integrationTypeId}${generateParams(params)}`,
    transformResponse: transformGetIntegrations,
    ...callbacks,
  });
};

const IntegrationsService = {
  getIntegrationList,
};

export default IntegrationsService;
