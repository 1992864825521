import React, { lazy, Suspense, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReportEmbedToken, resetReportEmbedToken } from "../../store/actions/powerbi";
import { POWERBI_REPORT_ID, GET_EMBED_TYPE, HIDE_ALL_ICONS } from "../../globals/powerbi-settings";
import { GENERATE_LOCALE_SETTINGS } from "../../store/reducers/powerbi/consts";
import { unstable_batchedUpdates } from "react-dom"; // ! Won't be needed from REACT 18
import { Button, Tippy } from "@tscore/react-components";
import Meta from "../meta";
import { decodeParams } from "../../helpers/params";
// import { POWERBI_META } from "../../globals/powerbi-settings";
// import { models } from "powerbi-client";
// import { PowerBIEmbed } from "powerbi-client-react";
import { useTranslation } from "react-i18next";
import { updateParams } from "../../helpers/params";
// import "powerbi-report-authoring";
// import isEqual from "lodash/isEqual";
// ? TYPES:
import { Report } from "powerbi-client";
import { ApplicationState } from "../../store/reducers";
import { RouteComponentProps } from "react-router-dom";

interface Params {
  reportId: string;
  pretty?: string;
}

type PowerBIProps = RouteComponentProps<Params>;

type MetaType = { title: string; breadcrumbs: string[] };

interface MetaInfo {
  meta: MetaType;
  reportKey: string;
}

const PowerBIEmbedLazy = lazy(async () =>
  import(/* webpackChunkName: "pbi-r-client" */ "powerbi-client-react").then(({ PowerBIEmbed }) => ({
    default: PowerBIEmbed,
  }))
);

const getKey = (obj: { [key: string]: string }, val: string) => Object.keys(obj).find((key) => obj[key] === val);

// interface PowerBIProps extends RouteComponentProps<Params> {}

export const PowerBI: React.FC<PowerBIProps> = ({ match, location }) => {
  const { t, i18n } = useTranslation();
  const [report, setReport] = useState<Report>();
  const [isRendered, setIsRendered] = useState(false);
  const [info, setInfo] = useState<MetaInfo>({
    meta: { title: "", breadcrumbs: [] },
    reportKey: "",
  });
  const [type, setType] = useState<string | undefined>(undefined);
  // const [error, setError] = useState("");
  const setError = (_s: string) => true;
  const reportId = match.params.reportId;
  useEffect(() => {
    const reportKey = getKey(POWERBI_REPORT_ID, reportId) || "";
    const meta: MetaType = t([`powerbi:meta.${reportKey}`, `powerbi:meta.default`], {
      returnObjects: true,
      defaultValue: { title: "", breadcrumbs: [] },
    });
    setInfo({
      meta,
      reportKey,
    });
  }, [setInfo, t, i18n.language, reportId]);
  useEffect(() => {
    setType(GET_EMBED_TYPE(reportId));
  }, [setType, reportId]);
  const dispatch = useDispatch();
  // const reportId = "5c9b23fd-63d6-4ab8-a169-8bfa77a90b30";
  // const searchParams = { dashboardId: 1608 };
  const getNewIframeSrc = useCallback((lng, src) => {
    const [host, searchParams] = src.split("?");
    const newParams = GENERATE_LOCALE_SETTINGS(lng).localeSettings;
    const newSearchString = updateParams("?" + searchParams, newParams);
    return host + newSearchString;
  }, []);
  const isBtnVisible = useCallback(
    (t: string): boolean => {
      return !isRendered || (isRendered && (report as any)[t]) ? true : false;
    },
    [report, isRendered]
  );
  useEffect(() => {
    async function fetchInitial() {
      const searchParams = decodeParams(location.search);
      await dispatch(fetchReportEmbedToken(reportId, searchParams));
    }
    unstable_batchedUpdates(() => {
      setIsRendered(false);
      setReport(undefined);
    });
    fetchInitial();
    return () => {
      dispatch(resetReportEmbedToken(reportId));
    };
  }, [dispatch, reportId, location.search, i18n.language]);
  useEffect(() => {
    if (!report) {
      return;
    }
    function onChangeLanguage(lng: string) {
      report!.iframe.src = getNewIframeSrc(lng, report!.iframe.src);
    }
    i18n.on("languageChanged", onChangeLanguage);
    return () => i18n.off("languageChanged", onChangeLanguage);
  }, [i18n, getNewIframeSrc, report]);
  const state = useSelector((state: ApplicationState) => state.powerBIReducer[reportId] || state.powerBIReducer[-1]);
  const { config, isFetching } = state;
  const print = () => {
    report?.print && report.print();
  };
  const fullscreen = () => {
    report?.fullscreen && report.fullscreen();
  };
  const refresh = () => {
    report?.refresh && report.refresh();
  };
  const reload = () => {
    report?.reload && report.reload();
  };
  // const setFilters = async (rep: Report | undefined) => {
  //   if (!rep) {
  //     rep = report;
  //   }
  //   if (!rep) {
  //     return true;
  //   }
  //   try {
  //     const prevFilters = await rep.getFilters();
  //     const prevFiltersSkimmed = prevFilters.map((f: any) => ({
  //       conditions: f.conditions,
  //       logicalOperator: f.logicalOperator,
  //       target: f.target,
  //     }));
  //     const newFiltersSkimmed = !config.filters
  //       ? []
  //       : config.filters.map((f: any) => ({
  //           conditions: f.conditions,
  //           logicalOperator: f.logicalOperator,
  //           target: f.target,
  //         }));
  //     const isEveryFilterTheSame = prevFiltersSkimmed.every((f, i) => isEqual(f, newFiltersSkimmed[i]));
  //     if (!isEveryFilterTheSame) {
  //       console.log("FILTERS: FILTERS_MUST_BE_UPDATED");
  //       rep.setFilters(config.filters!);
  //     }
  //   } catch (err) {
  //     // eslint-disable-next-line
  //     console.log({ err });
  //   }
  // };
  // useEffect(() => {
  //   if (isRendered && report) {
  //     console.log("FILTERS: CHECKING_FILTERS");
  //     setFilters(report);
  //   }
  // }, [report, isRendered]);
  // const loadedEventName = configWithType.type === "tile" ? "tileLoaded" : "loaded";
  return (
    <main id="power-bi-report">
      <Meta {...info.meta} helmetProps={{ bodyAttributes: { "data-powerbi-key": info.reportKey } }} />
      <header className="content-header">
        {!HIDE_ALL_ICONS[reportId] && (
          <div style={{ width: "100%" }}>
            {/* {error !== "" && (
            <div style={{ display: "inline-block", height: "100%", lineHeight: "3.2rem" }} className="color-red mr12">
              <span>{error}</span>
            </div>
          )} */}
            {/* <Button colour="lightgray-outline" icon="alt_save" disabled={!isRendered} onClick={save}>
            Save
          </Button> */}
            {isBtnVisible("print") && (
              <Button colour="lightgray-outline" icon="print" disabled={!isRendered} onClick={print}>
                {t("powerbi:Print")}
              </Button>
            )}
            {isBtnVisible("refresh") && (
              <Tippy content={t("powerbi:tooltipRefreshDataSources")} placement="bottom">
                <div data-tooltipped>
                  <Button colour="lightgray-outline" icon="refresh" disabled={!isRendered} onClick={refresh}></Button>
                </div>
              </Tippy>
            )}
            {isBtnVisible("reload") && (
              <Tippy content={t("powerbi:tooltipReloadReport")} placement="bottom">
                <div data-tooltipped>
                  <Button colour="lightgray-outline" icon="loop" disabled={!isRendered} onClick={reload}></Button>
                </div>
              </Tippy>
            )}
            {isBtnVisible("fullscreen") && (
              <Tippy content={t("powerbi:tooltipFullscreen")} placement="bottom">
                <div data-tooltipped>
                  <Button
                    colour="lightgray-outline"
                    icon="fullscreen"
                    disabled={!isRendered}
                    onClick={fullscreen}></Button>
                </div>
              </Tippy>
            )}
          </div>
        )}
      </header>
      {!type || isFetching["GET_POWERBI_REPORT_EMBED_DATA"] ? (
        <div className="power-bi-embed box"></div>
      ) : (
        <Suspense fallback={<div className="power-bi-embed box" />}>
          <PowerBIEmbedLazy
            embedConfig={config}
            // phasedEmbedding={true}
            eventHandlers={
              new Map([
                [
                  type === "tile" ? "tileLoaded" : "loaded",
                  function () {
                    // eslint-disable-next-line
                  console.log("Report loaded or tileLoaded");
                    setIsRendered(true);
                  },
                ],
                [
                  "rendered",
                  function () {
                    // eslint-disable-next-line
                  console.log("Report rendered");
                    setError("");
                  },
                ],
                [
                  "error",
                  function (event: any) {
                    console.warn({ powerbiError: event.detail });
                    setError(event.detail.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName="power-bi-embed box"
            getEmbeddedComponent={(embeddedReport) => {
              if (!report) {
                // console.log({ embeddedReport });
                if (HIDE_ALL_ICONS[reportId]) {
                  setIsRendered(true);
                }
                setReport(embeddedReport as Report);
              }
            }}
          />
        </Suspense>
      )}
    </main>
  );
};
