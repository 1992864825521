import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Tippy, Brag } from "@tscore/react-components";

type BragProps = React.ComponentProps<typeof Brag>;

interface BragWithTagProps extends BragProps {
  isGreyWithTag?: boolean;
  updatedAtText?: string | JSX.Element;
  tooltipDisabled?: boolean;
  extraTagStyles?: React.CSSProperties;
}

export const BragWithTag: React.FC<BragWithTagProps> = ({
  isGreyWithTag,
  updatedAtText,
  tooltipDisabled = false,
  children,
  colour,
  textColour,
  backgroundColour,
  extraTagStyles = {},
  ...props
}) => {
  const { t } = useTranslation();
  const styles = { color: textColour, backgroundColor: backgroundColour };
  const tagStyles = { ...styles, ...extraTagStyles };
  const bragExtraProps = isGreyWithTag ? { colour: "gray" } : { colour, style: styles };
  return (
    <div className="brag-tag__holder">
      {isGreyWithTag && (
        <Tippy
          disabled={tooltipDisabled}
          content={
            <div>
              {t("generic:tooltipGrayIndicatorTag")}{" "}
              {updatedAtText ? updatedAtText : t("generic:tooltipGrayIndicatorTagExtraDefault")}
            </div>
          }
          placement="top">
          <span style={tagStyles} className={classNames("brag brag-tag", { ["brag-" + colour]: !!colour })} />
        </Tippy>
      )}
      <Brag {...bragExtraProps} {...props}>
        {children}
      </Brag>
    </div>
  );
};
