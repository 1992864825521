import React from "react";
import { Input } from "@tscore/react-components";
import { useField } from "formik";

// ? TYPES
import { ComponentProps } from "../../types/generic";
// import { FieldInputProps } from "formik";

// type FormikInputExtendedProps = React.HTMLProps<HTMLInputElement> & React.HTMLAttributes<HTMLInputElement>;

interface FormikInputProps extends ComponentProps<typeof Input> {
  _name?: string;
  // name?: string;
  [key: string]: any; // TODO InputProps
}

// export const FormikInput: React.FC<FormikInputProps> = ({ _name, name, ...props }) => {
//   return (
//     <Field name={_name || name}>
//       {({ field, form, meta }: any) => <Input {...field} {...props} _name={undefined} />}
//     </Field>
//   );
// };

export const FormikInput: React.FC<FormikInputProps> = ({ _name, name, ...props }) => {
  // eslint-disable-next-line
  const [field, _meta, _helpers] = useField({
    ...props,
    name: _name || name,
  } as any);
  return <Input {...field} {...props} />;
};
