import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLiveSystemActions } from "../../../store/actions/escalation";

import { Tabs, Loader, Accordion } from "@tscore/react-components";
import { CONTENT_HEIGHT } from "./consts";
import { ActionTable } from "./action-table";
import { ErrorEscalationConnection, ErrorEscalationEmpty } from "./error-escalation";
// import { useTranslation } from "react-i18next";
// ? TYPES:
import { ApplicationState } from "../../../store/reducers";
// import { Action } from "../../../store/types/escalation-state";
import UserInfo from "../../../types/user-info";

const GET_DATA_ACTION_NAME = "GET_LIVE_SYSTEM_ACTIONS";

const unsafeCSS = `.live-system-actions-tabs .tab-content, .live-system-actions-tabs .tab-list { height: ${CONTENT_HEIGHT}; }`;

export const TabLiveSystemActions: React.FC<{ pdSiteId: number; credentials: UserInfo; title: string }> = ({
  pdSiteId,
  credentials,
  title,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchLiveSystemActions(pdSiteId));
    }
    fetchInitial();
    // document.getElementById("template")!.innerHTML = "Loading";
  }, [dispatch, pdSiteId]);
  const state = useSelector(
    (state: ApplicationState) => state.escalationReducer[pdSiteId] || state.escalationReducer[-1]
  );
  const { isFetching, liveActionList, errors } = state;
  const organisations = Object.values(liveActionList);
  if (organisations.length === 0 && isFetching[GET_DATA_ACTION_NAME]) {
    return (
      <div style={{ height: CONTENT_HEIGHT }}>
        <Loader scale="xl" />
      </div>
    );
  }
  if (errors[GET_DATA_ACTION_NAME]) {
    return <ErrorEscalationConnection title={title} error={errors[GET_DATA_ACTION_NAME]} />;
  }
  if (organisations.length === 0) {
    return <ErrorEscalationEmpty type="SYSTEM_ACTIONS" />;
  }
  return (
    <div>
      <Tabs isVertical className="tabs-nowrap live-system-actions-tabs">
        {organisations.map((organisation) => (
          <Tabs.Item
            key={organisation.labelId}
            id={"label-id-" + organisation.labelId}
            label={organisation.labelName}
            suffix={
              <span className="tag rounded bg-blue" style={{ marginLeft: "auto" }}>
                {organisation.totalActions + organisation.completedActions}
              </span>
            }>
            <Accordion allowZeroExpanded preExpanded={["0"]}>
              {Object.values(organisation.indicators).map((indicator, index) => (
                <Accordion.Item key={indicator.indicatorId} uuid={index + ""}>
                  <Accordion.ItemHeading>
                    <Accordion.ItemButton>{indicator.indicatorName}</Accordion.ItemButton>
                  </Accordion.ItemHeading>
                  <Accordion.ItemPanel>
                    <ActionTable
                      pdSiteId={pdSiteId}
                      credentials={credentials}
                      // actions={indicator.actions}
                      actionSelectorKeys={`liveActionList.${organisation.labelId}.indicators.${indicator.indicatorId}.actions`}
                      // usersAssigned={indicator.usersAssigned}
                    />
                  </Accordion.ItemPanel>
                </Accordion.Item>
              ))}
            </Accordion>
          </Tabs.Item>
        ))}
      </Tabs>
      <style dangerouslySetInnerHTML={{ __html: unsafeCSS }} />
    </div>
  );
};
