import React from "react";
import { withTranslation } from "react-i18next";
import { SVGIcon } from "@tscore/react-components";
// ? TYPES:
import { TFunction } from "i18next";

interface Props {
  children: React.ReactNode;
  no: number | any;
  t: TFunction;
}

const StepWoT: React.FC<Props> = ({ children, no, t }) => {
  return (
    <div className="mfa__step">
      {typeof no === "number" ? (
        <div className="mfa__step__title">{t("mfa.general.step", { no })}</div>
      ) : (
        <div className="mfa__step__title mfa__options__icon">
          <SVGIcon svg={no} />
        </div>
      )}
      <div className="mfa__step__side">{children}</div>
    </div>
  );
};

export const Step = withTranslation(["login"])(StepWoT);
