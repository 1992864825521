import React, { Fragment, useRef, useMemo, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { useLayoutEffectOnce } from "../../hooks/use-layout-effect-once";
import { useEffectOnce } from "../../hooks/use-effect-once";
import { isInViewport } from "../../helpers/is-in-viewport";
// import { matchPath } from "react-router-dom";
// import { decodeParams } from "../../helpers/params";

// import { INTERNAL_URLS } from "../../globals/internal-urls";
import classNames from "classnames";
import { InlineError, Tippy } from "@tscore/react-components";
// import { SidebarIcon } from "./sidebar-icon";
import { SidebarSublistSearch } from "./sidebar-sublist-search";
// eslint-disable-next-line
import { NavLink } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { useSidebar } from "../../providers/sidebar.provider";
import { useLocation } from "react-router-dom";

//? TYPES:
import { TFunction } from "i18next";
import { ApplicationState } from "../../store/reducers";
import { ImportedMenu, ExtendedLevelOneMenu, LevelTwoMenu } from "../../store/types/menu-state";
import { LazyComponent } from "../../types/lazy-component";

const SidebarIconLazy = lazy(
  async () => import(/* webpackChunkName: "sidebar-icons" */ "./sidebar-icon") as unknown as LazyComponent
);

// interface SidebarListProps {
//   ICONS: any;
// }

const SidebarListLoader: React.FC<{ cached?: boolean }> = ({ cached }) => (
  <div className={classNames("rectangle-bounce", { cached })} style={{ opacity: 0.15 }}>
    {[1, 2, 3, 4, 5].map((num) => (
      <div key={num} className={`rect-${num}`} />
    ))}
  </div>
);

const SublistItem: React.FC<{ subitem: any }> = ({ subitem }) => {
  const { isActiveDropdown } = useSidebar();
  return (
    <li>
      <NavLink exact={false} isActive={isActiveDropdown(subitem.url)} activeClassName="active" to={subitem.url}>
        <span>{subitem.name}</span>
      </NavLink>
    </li>
  );
};

// const SublistItem = React.memo(SublistItemNonMemo);

const Sublist: React.FC<{
  isDropdown: boolean;
  menu: LevelTwoMenu[];
  item: Pick<ExtendedLevelOneMenu, "showSearch" | "url" | "subListHeight" | "isSearchResultsEmpty">;
}> = ({ isDropdown, menu, item: { showSearch, url, subListHeight } }) => {
  const sublistRef = useRef<HTMLUListElement>(undefined!);
  if (!isDropdown) {
    return null;
  }
  return (
    <ul className="sublist" ref={sublistRef} style={{ height: subListHeight }}>
      <SidebarSublistSearch sublistRef={sublistRef} showSearch={showSearch} url={url} />
      {/* {isSearchResultsEmpty && <li className="sublist__search__empty">No matches found</li>} */}
      {menu!.map((subitem) => {
        const id = subitem.id || subitem.areaId;
        if (subitem.searchInvisible) {
          return null;
        }
        return <SublistItem key={id} subitem={subitem} />;
      })}
    </ul>
  );
};

// const Sublist = React.memo(SublistNonMemo);

const ItemWoT: React.FC<{
  t: TFunction;
  item: ExtendedLevelOneMenu;
}> = ({
  t,
  item: { menu, inverted, name, url, isNew, isDropdown, isEmpty, showSearch, subListHeight, isSearchResultsEmpty },
}) => {
  const { activeMenu, onItemClick, isActiveMain } = useSidebar();
  if (isEmpty) {
    return null;
  }
  return (
    <li className={classNames({ "is-dropdown": isDropdown })}>
      <NavLink
        className={classNames({
          active: isDropdown && url === activeMenu,
        })}
        onClick={onItemClick(isDropdown, url)}
        isActive={isActiveMain(url, isDropdown)}
        exact={!isDropdown}
        activeClassName="active"
        to={url}>
        <div
          className={classNames("ico", {
            invert: inverted,
          })}>
          <Suspense fallback={<div className="svg-container" />}>
            <SidebarIconLazy name={name} url={url} namespace="SIDEBAR" />
          </Suspense>
        </div>
        <div className="caret-collapsed">
          <i className="caret material-icons">expand_more</i>
        </div>
        <span>{t("sidebar:items." + name, { defaultValue: name })}</span>
        {isNew && <span className="new-tag">NEW</span>}
        <i className="caret material-icons">expand_more</i>
      </NavLink>
      <Sublist isDropdown={isDropdown} menu={menu!} item={{ showSearch, url, subListHeight, isSearchResultsEmpty }} />
    </li>
  );
};

// const ItemWoT = React.memo(ItemNonMemo);

const Item = withTranslation()(ItemWoT);

// ! Error only visible to admins in tooltip; no need to translate:
const SidebarListError: React.FC<{ cached?: boolean; showErrorWhenCached?: boolean }> = ({
  cached,
  showErrorWhenCached,
}) => (
  <li
    className={classNames("sidebar-error", { cached })}
    style={{ opacity: 0.4, display: cached && !showErrorWhenCached ? "none" : undefined }}>
    <Tippy disabled={!cached} content="Failed to get up to date menu." placement="bottom">
      <span>
        <InlineError title="Error" icon="error" description="Failed to get the menu. Please try again later." />
      </span>
    </Tippy>
  </li>
);

export const SidebarList: React.FC = () => {
  const { t } = useTranslation();
  const { searchSubList, setActiveMenu } = useSidebar();
  const location = useLocation();
  const sidebarRef = useRef<HTMLUListElement>(undefined!);
  const state = useSelector((state: ApplicationState) => {
    const reducer = state.menuReducer;
    return {
      ...reducer,
      currentMenu: reducer.menu[reducer.meta.module],
    };
  });
  const { currentMenu, isFetching, errors, cached } = state;
  useEffectOnce(() => {
    const segments = location.pathname.split("/");
    if (segments.length > 1) {
      setActiveMenu(`/${segments[1]}`); // fixes the double click needed to uncheck current sublist
    }
  }, [!!location]);
  useLayoutEffectOnce(
    (conditions) => {
      setTimeout(
        () => {
          const activeItemInSublist: HTMLElement | null = sidebarRef.current?.querySelector(".sublist .active");
          if (activeItemInSublist && !isInViewport(activeItemInSublist)) {
            activeItemInSublist.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
          }
        },
        conditions[0] ? 600 : 1900
      );
    },
    [
      sidebarRef.current && isFetching === true && cached === true,
      sidebarRef.current && isFetching === false && cached === false,
    ]
  );
  // console.log({ isFetching, cached });
  const isAnyAdmin = useSelector((state: ApplicationState) => state.credentialsReducer.genericRoles.ANY_ADMIN);

  const currentMenuWithSearch: ImportedMenu<ExtendedLevelOneMenu>[] = useMemo(() => {
    const isSearchFound = (item: LevelTwoMenu, query: string) => {
      const { feature: _1, active: _2, url: _3, description: _4, ...keyObj } = item;
      const lowerQuery = query.toLowerCase();
      return Object.keys(keyObj).some((key) => String(item[key]).toLowerCase().includes(lowerQuery));
    };
    return currentMenu.map((category) => ({
      ...category,
      menu: category.menu!.map((item) => {
        const { url, subListLength, showSearch } = item;
        const v = searchSubList[url!] || { value: "", height: undefined };
        const menu =
          subListLength > 0
            ? item.menu!.map((subitem) => {
                return { ...subitem, searchInvisible: v.value.length >= 1 ? !isSearchFound(subitem, v.value) : false };
              })
            : null;
        const isSearchResultsEmpty = !menu || menu.every((subitem) => subitem.searchInvisible);
        return {
          ...item,
          menu,
          subListHeight: showSearch && v.height ? `${v.height}px` : undefined,
          isSearchResultsEmpty,
        };
      }),
    }));
  }, [currentMenu, searchSubList]);

  // console.log(menu);
  return (
    <ul id="navigation-content" ref={sidebarRef}>
      {currentMenuWithSearch.map((category) => (
        <Fragment key={category.name}>
          {category.name !== "" && (
            <li className="spacer">
              <div className="category-collapsed"></div>
              <span>{t("sidebar:categories." + category.name, { defaultValue: category.name })}</span>
            </li>
          )}
          {category.menu!.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Fragment>
      ))}
      {isFetching && <SidebarListLoader cached={cached} />}
      {errors["GET_MENU"] && <SidebarListError cached={cached} showErrorWhenCached={isAnyAdmin} />}
    </ul>
  );
};
