import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { fetchUpdatePassword, clearMyAccountErrors } from "../../store/actions/my-account";

import { withFormik } from "formik";
import { FormikInput } from "../../components/formik";
import { newPasswordSchema } from "../../schemas/my-account.schema";

import { Button } from "@tscore/react-components";
// import classNames from "classnames";
// import { TAB_NAMES } from "./consts";
// import startCase from "lodash/startCase";
import { joinErrorsAsArray } from "../../helpers/join-errors";
// import { MINIMUM_PASSWORD_REQUIREMENTS } from "../../lang/authentication";
import { useTranslation, withTranslation } from "react-i18next";
//? TYPES:
import { ApplicationState } from "../../store/reducers";
// import { RouteComponentProps } from "react-router-dom";
import { NewPassword } from "../../schemas/my-account.schema";
import { TFunction } from "i18next";
interface TabChangePasswordBasicProps {
  values: NewPassword;
  [key: string]: any; // formik other
}

const changePasswordFormState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

type TFormKey = keyof typeof changePasswordFormState;

// https://www.chromium.org/developers/design-documents/create-amazing-password-forms
const AUTO_COMPLETE = {
  currentPassword: "current-password",
  newPassword: "new-password",
  confirmPassword: "off",
};

// type Organisation = any;

const TabChangePasswordBasic: React.FC<TabChangePasswordBasicProps> = ({
  isSubmitting,
  handleSubmit,
  values,
  errors,
  touched,
  clearMyAccountErrors: dispatchClearMyAccountErrors,
  ..._props
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    return () => {
      dispatchClearMyAccountErrors(); // just in case
    };
  }, [dispatchClearMyAccountErrors]);
  const isFetching = useSelector((state: ApplicationState) => state.myAccountReducer.isFetching);
  const apiErrors = useSelector((state: ApplicationState) => state.myAccountReducer.errors["UPDATE_PASSWORD"]);
  const username = useSelector((state: ApplicationState) => state.credentialsReducer.app_user_info.username);
  const fieldErrorsWithApi = joinErrorsAsArray(errors, apiErrors);
  const onKeyDown = ({ keyCode }: any): boolean | void =>
    keyCode === 13 && !isFetching["UPDATE_PASSWORD"] && handleSubmit;
  return (
    <div className="box">
      <h2>{t("myAccount:Change Password")}</h2>
      <div className="account-section password-requirements-section">
        {/* <h3>Change Password</h3> */}
        <hr />
        <section>
          <aside>
            <p>{t("myAccount:changePasswordAside")}</p>
            <br />
            <p>{t("myAccount:Minimum password requirements")}:</p>
            <ul className="list squared" style={{ marginTop: "0.6rem", paddingLeft: "6px" }}>
              {(t("myAccount:MINIMUM_PASSWORD_REQUIREMENTS", { returnObjects: true }) as string[]).map((text) => (
                <li key={text}>{text}</li>
              ))}
            </ul>
          </aside>
          <form className="account-form" onKeyDown={onKeyDown} onSubmit={handleSubmit}>
            <input type="hidden" name="username" value={username} />
            {Object.entries(values).map(([key, _props]) => {
              const placeholder = t("myAccount:" + key);
              return (
                <div key={key}>
                  <label>{placeholder}</label>
                  <FormikInput
                    name={key}
                    autoComplete={AUTO_COMPLETE[key as TFormKey]}
                    value={(values as any)[key]}
                    type="password"
                    placeholder={placeholder}
                  />
                  {touched[key] && fieldErrorsWithApi[key] && (
                    <ul className="form-error is-visible list">
                      {fieldErrorsWithApi[key].map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  )}
                </div>
              );
            })}
            <footer>
              <Button type="submit" isLoading={isSubmitting || isFetching["UPDATE_PASSWORD"]}>
                {t("generic:Save")}
              </Button>
            </footer>
          </form>
        </section>
      </div>
    </div>
  );
};

const formikEnhancer = withFormik({
  validationSchema: ({ t }: { t: TFunction }) => newPasswordSchema(t),
  mapPropsToValues: (_props: any) => changePasswordFormState,
  handleSubmit: (values, { setSubmitting, setValues, props }) => {
    const payload = {
      ...values,
    };
    // console.log(payload);
    props.fetchUpdatePassword(payload);
    setValues(changePasswordFormState, false);
    // resetForm(changePasswordFormState as any);
    setSubmitting(false);
  },
  enableReinitialize: true,
  validate: (values: any, { clearMyAccountErrors }: any) => {
    // hack to remove api errors on form changes
    clearMyAccountErrors();
    return {};
  },
  displayName: "EditPasswordPage",
})(TabChangePasswordBasic);

const mapDispatchToProps = (dispatch: any) => ({
  fetchUpdatePassword: (values: NewPassword) => dispatch(fetchUpdatePassword(values)),
  clearMyAccountErrors: () => dispatch(clearMyAccountErrors()),
});

export const TabChangePassword = connect(null, mapDispatchToProps)(withTranslation()(formikEnhancer));
