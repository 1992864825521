import { Toast } from "@tscore/react-components";
import { API_SUCCESS } from "../actions/api.names";
import { getSuccessToastBody } from "../../lang/success-toasts";

const SUCCESS_TOAST_AUTO_CLOSE_MS = 2000;

const IGNORE_LABELS: string[] = [
  "REFRESH_TOKEN",
  "AUTHORIZE",
  "LOG_IN",
  "PRE_LOGIN",
  "REGISTER_MFA",
  "VERIFY_REGISTER_MFA",
  "RESEND_MFA_CODE",
  "UPLOAD_DOCUMENT",
  "NHSR_UPLOAD_DOCUMENT",
  "GET_INDICATORS_HISTORY",
  "UPDATE_ELECTIVE_FILTERS",
  "UPDATE_VANTAGE_FILTERS",
  "MOVE_FAVOURITE",
  "SIMPLE_GET_TABLEAU_TOKEN",
  "SIMPLE_SAVE_WINTER_REPORT_NARRATIVE",
  "SIMPLE_REGION_REPORT_SAVE_REPORT",
  "SIMPLE_REGION_REPORT_RENDER_ADDITIONAL_QUESTIONS",
];

const GET_METHOD_SUCCESS: string[] = ["REQUEST_REPORT"];

const successMiddleware =
  (_store: any) =>
  (next: any) =>
  (action: { type: string; [key: string]: any } = { type: "" }): any => {
    if (action.type === API_SUCCESS && !IGNORE_LABELS.includes(action.payload.label)) {
      const method = action.payload.method.toLowerCase() || "other";
      if (
        ["put", "post", "delete", "patch"].includes(method) ||
        (method === "get" && GET_METHOD_SUCCESS.includes(action.payload.label))
      ) {
        Toast(getSuccessToastBody(action), "success", {
          autoClose: SUCCESS_TOAST_AUTO_CLOSE_MS,
        });
      }
      return next(action);
      // return -1;
    }
    return next(action);
  };

export default successMiddleware;
