import { API_START, API_END, ACCESS_DENIED, API_ERROR, API_SUCCESS, API } from "./api.names";
// ? TYPES:
import { ApiError, ApiActionPayload, ApiAction } from "../types/api";
// import { Method } from "axios";
import { AxiosError, Method } from "axios";

export const FAKE_AXIOS_ERROR = ({ code = 403, method = "get" }: { method?: string; code?: number }) => ({
  request: {
    status: code,
  },
  config: { method: method.toLowerCase() as "get" },
  isAxiosError: false,
  toJSON: () => ({}),
  name: "FAKE_AXIOS_ERROR",
  message: "",
});

export const OVERWRITE_HEADERS = (customHeader: { [key: string]: string | number | boolean | undefined }) => {
  return {
    Authorization: undefined,
    ...customHeader,
  };
};

export const apiStart = (label: string, other?: any) => ({
  type: API_START,
  payload: label,
  ...other,
});

export const apiEnd = (label: string, other?: any) => ({
  type: API_END,
  payload: label,
  ...other,
});

export const accessDenied = (url: string) => ({
  type: ACCESS_DENIED,
  payload: {
    url,
  },
});

export const apiError = (label: string, errorMessage: ApiError, error: AxiosError, other?: any) => ({
  type: API_ERROR,
  payload: {
    label,
    errorMessage,
    error,
  },
  ...other,
});

export const apiSuccess = (label: string, method: Method, status: number, other?: any) => ({
  type: API_SUCCESS,
  payload: {
    label,
    method,
    status,
  },
  ...other,
});

export function apiAction<T = any>({
  url = "",
  method = "GET",
  label = "",
  skipAuthorization,
  baseURL = undefined,
  ...props
}: ApiActionPayload<T>): ApiAction<T> {
  return {
    type: API,
    payload: {
      baseURL,
      skipAuthorization,
      url,
      method,
      label,
      ...props,
    },
  };
}
