export const hasClass = (element: HTMLElement, className: string): boolean => {
  return (" " + element.className + " ").indexOf(" " + className + " ") > -1;
};

export function hasClasses(element: HTMLElement, classes: string[]) {
  for (let i = 0, j = classes.length; i < j; i += 1) {
    if (hasClass(element, classes[i])) {
      return true;
    }
  }
  return false;
}
