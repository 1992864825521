import moment from "moment";
import { CUSTOM_LABEL } from "../../store/reducers/indicator-history/generate-config";
//? TYPES:
import {
  TAggregationItem,
  TFrequencyItem,
  TPeriodItem,
  TBarItem,
  GraphConfig,
} from "../../store/types/indicator-history-state";

type TooltipContext = "DAYS" | "MONTHS";

interface State {
  key: number;
  chartLoading: boolean;
  isZoomed: boolean;
  c: {
    freq: TFrequencyItem;
    startEnd: TPeriodItem;
    agg: TAggregationItem;
  };
  chartType: TBarItem;
  prevChartType: TBarItem | { value: undefined };
  dateRangeState: ReturnType<typeof calculateDateRangeState>;
}

function calculateDateRangeState(startEnd: TPeriodItem, buttonClicked?: string) {
  const EMPTY_VALUE = { start: null, end: null };
  const {
    value: { start, end },
  } = startEnd;
  const isAllFilledIn = !!start && !!end;
  const nowDate = new Date();
  function immute(d: moment.Moment): moment.Moment {
    return d.clone();
  }
  function fixEndValue(d: moment.Moment): moment.Moment {
    return d.subtract(1, "day");
  }
  function getDiffs() {
    if (!isAllFilledIn) {
      return {
        monthsDiff: 0,
        daysDiff: 0,
        onlyDaysDiff: 0,
        fixedEnd: moment(),
        s: moment(),
        type: "MONTHS" as TooltipContext,
      };
    }
    const fixedEnd = moment(end).add(1, "second"); // fixEnd(end);
    const s = moment(start);
    const onlyDaysDiff = fixedEnd.diff(s, "days");
    const monthsDiff = fixedEnd.diff(s, "months");
    const startPlusMonths = immute(s).add(monthsDiff, "months");
    const daysDiff = fixedEnd.diff(startPlusMonths, "days");
    //
    const startOfMonthDay = 1;
    const endOfMonthDay = moment(end).daysInMonth();
    const type: TooltipContext =
      s.date() === startOfMonthDay && moment(end).date() === endOfMonthDay ? "MONTHS" : "DAYS";
    return {
      onlyDaysDiff,
      monthsDiff,
      daysDiff,
      fixedEnd,
      s,
      type,
    };
  }
  const diffs = getDiffs();
  function getPrevValue() {
    if (!isAllFilledIn) {
      return EMPTY_VALUE;
    }
    const { monthsDiff, daysDiff, onlyDaysDiff, type, s } = diffs;
    function getNewStart() {
      if (type === "MONTHS") {
        return immute(s).subtract(monthsDiff, "months").subtract(daysDiff, "days");
      }
      return immute(s).subtract(onlyDaysDiff, "days");
    }
    const newStart = getNewStart();
    const newEnd = fixEndValue(immute(s)); // .subtract(1, "day");
    return {
      start: newStart.toDate(),
      end: newEnd.toDate(),
    };
  }
  function getNextValue() {
    if (!isAllFilledIn) {
      return EMPTY_VALUE;
    }
    const { monthsDiff, daysDiff, onlyDaysDiff, type, fixedEnd } = diffs;
    function getNewEnd() {
      if (type === "MONTHS") {
        return fixEndValue(immute(fixedEnd).add(monthsDiff, "months").add(daysDiff, "days"));
      }
      return fixEndValue(immute(fixedEnd).add(onlyDaysDiff, "days"));
    }
    const newStart = immute(fixedEnd);
    const proposedNewEnd = getNewEnd(); // .subtract(1, "day");
    const isSameOrAfter = proposedNewEnd.isSameOrAfter(moment(), "days");
    const newEnd = isSameOrAfter ? moment() : proposedNewEnd;
    return {
      start: newStart.toDate(),
      end: newEnd.toDate(),
    };
  }
  const endAsDate = end ? moment(end).toDate() : null;

  return {
    isClearDisabled: !start && !end,
    startAsDate: start ? moment(start).toDate() : null,
    endAsDate,
    tooltipContext: diffs.type,
    tooltipCount: diffs.type === "MONTHS" ? diffs.monthsDiff : diffs.onlyDaysDiff,
    prev: {
      lastButtonClicked: buttonClicked === "prev",
      isDisabled: !isAllFilledIn,
      value: getPrevValue(),
    },
    next: {
      lastButtonClicked: buttonClicked === "next",
      isDisabled: !isAllFilledIn || endAsDate! >= nowDate,
      value: getNextValue(),
    },
  };
}

export const localAction = {
  ON_UPDATE_CHART: "ON_UPDATE_CHART",
  SET_CHART_LOADING: "SET_CHART_LOADING",
  SET_IS_ZOOMED: "SET_IS_ZOOMED",
  ON_FREQ_CHANGE: "ON_FREQ_CHANGE",
  ON_RANGE_CHANGE: "ON_RANGE_CHANGE",
  ON_AGG_CHANGE: "ON_AGG_CHANGE",
  ON_CUSTOM_DATE_RANGE_CHANGE: "ON_CUSTOM_DATE_RANGE_CHANGE",
} as const;

export type LocalActionTypes = keyof typeof localAction;
export type LocalAction = { type: LocalActionTypes; [key: string]: any };
export type LocalDispatch = (p: LocalAction) => void;

export function reducer(state: State, action: LocalAction): State {
  // if (action.type !== "SET_CHART_LOADING") {
  // }
  if (action.type === localAction["ON_UPDATE_CHART"]) {
    const chartType: TBarItem | undefined = action.chartType;
    return {
      ...state,
      isZoomed: false,
      key: Date.now(),
      prevChartType: { value: undefined },
      ...(chartType && { chartType }),
    };
  }
  if (action.type === localAction["SET_CHART_LOADING"]) {
    return {
      ...state,
      chartLoading: action.chartLoading,
      // prevChartType: action.chartLoading ? state.chartType : { value: undefined },
      isZoomed: action.chartLoading ? false : state.isZoomed,
    };
  }
  if (action.type === localAction["SET_IS_ZOOMED"]) {
    return {
      ...state,
      isZoomed: action.isZoomed,
    };
  }
  if (action.type === localAction["ON_FREQ_CHANGE"]) {
    const option: TFrequencyItem | any = action.freq;
    const config: GraphConfig = action.config;
    const label = option.label;
    const generateNewStartEnd = () => {
      const previousStartEnd = state.c.startEnd || {};
      const wasCustom = previousStartEnd.label === CUSTOM_LABEL;
      const foundLastOption = config.period[label].find((period) => period.label === previousStartEnd.label);
      const foundOption =
        wasCustom && foundLastOption
          ? {
              ...foundLastOption,
              value: previousStartEnd.value,
            }
          : foundLastOption;
      return foundOption || config.defaults.period[option.label];
    };
    const generateNewAgg = () => {
      const previousAgg = state.c.agg || {};
      const foundOption = config.aggregation[label].find((agg) => agg.label === previousAgg.label);
      return foundOption || config.defaults.aggregation[label];
    };
    const startEnd = generateNewStartEnd();
    const agg = generateNewAgg();
    const dateRangeState = calculateDateRangeState(startEnd);
    // console.log({ dateRangeState });
    return {
      ...state,
      c: {
        ...state.c,
        freq: option,
        startEnd: startEnd,
        agg: agg,
      },
      chartType: config.defaults.graphType[label],
      prevChartType: state.chartType,
      dateRangeState,
    };
  }
  if (action.type === localAction["ON_RANGE_CHANGE"]) {
    const option: TPeriodItem = action.startEnd;
    const dateRangeState = calculateDateRangeState(option);
    // console.log({ dateRangeState });
    return {
      ...state,
      c: {
        ...state.c,
        startEnd: option,
      },
      dateRangeState,
    };
  }
  if (action.type === localAction["ON_AGG_CHANGE"]) {
    const option: TAggregationItem = action.agg;
    return {
      ...state,
      c: {
        ...state.c,
        agg: option,
      },
    };
  }
  if (action.type === localAction["ON_CUSTOM_DATE_RANGE_CHANGE"]) {
    const setOptions = {
      start: { hour: 0, minute: 0, second: 0, millisecond: 0 },
      end: { hour: 23, minute: 59, second: 59, millisecond: 999 },
    };
    const newValues = Object.entries(action.payload).reduce((acc, [startOrEnd, date]) => {
      return {
        ...acc,
        [startOrEnd]:
          date === null
            ? null
            : moment(date)
                .set(setOptions[startOrEnd as "start"])
                .format(),
      };
    }, {});
    const startEnd = {
      ...state.c.startEnd,
      label: CUSTOM_LABEL,
      value: {
        ...state.c.startEnd.value,
        ...newValues,
      },
    };
    const dateRangeState = calculateDateRangeState(startEnd, action.buttonClicked);
    // console.log({ dateRangeState, startEnd });
    return {
      ...state,
      c: {
        ...state.c,
        startEnd,
      },
      dateRangeState,
    };
  }
  throw Error("Unknown action.");
}

export function createInitialState({ config }: { config: GraphConfig }): State {
  const startEnd = {
    ...config.defaults.period["Daily"],
    value: { start: null, end: null },
  }; // treated as s: null, e: null
  const dateRangeState = calculateDateRangeState(startEnd);
  // console.log({ dateRangeState });
  return {
    chartLoading: true,
    isZoomed: false,
    key: Date.now(),
    c: {
      freq: config.defaults.freqOptions,
      startEnd,
      agg: config.defaults.aggregation["Daily"],
    },
    chartType: config.defaults.graphType["Daily"],
    prevChartType: { value: undefined },
    dateRangeState,
  };
}
