import React from "react";
import { Icon } from "@tscore/react-components";
import classNames from "classnames";
import { useFilterChange } from "./hooks/use-filter-change";
// ? TYPES:
import { PaginatedSort } from "../../types/paginated-params";

type SortHeadingProps<C extends React.ElementType = "li"> = {
  as?: C;
  parsedSort: PaginatedSort;
  cell: { value: string; class?: string; style?: any; sortName?: string };
} & React.ComponentPropsWithoutRef<C>;

export const SortHeading = <C extends React.ElementType = "li">({ as, parsedSort, cell }: SortHeadingProps<C>) => {
  const Component = as || "li";
  const { toggleSort } = useFilterChange();
  return (
    <Component
      className={classNames("th", cell.class, { "has-sort": cell.sortName })}
      onClick={toggleSort(cell.sortName)}
      style={cell.style}>
      <span>{cell.value}</span>
      {cell.sortName ? (
        <span className="sort__holder" data-order={parsedSort[cell.sortName]}>
          {(() => {
            if (!parsedSort[cell.sortName]) {
              return <Icon>unfold_more</Icon>;
            }
            if (parsedSort[cell.sortName] === "ASC") {
              return <Icon>arrow_drop_up</Icon>;
            }
            return <Icon>arrow_drop_down</Icon>;
          })()}
        </span>
      ) : null}
    </Component>
  );
};
