import React, { createContext, useContext, useCallback, useState } from "react";
import { MODULE_URLS } from "../globals/internal-urls";
import { decodeParams } from "../helpers/params";
import { extraChecks, extraChecksDropdown } from "../components/structure/sidebar-list-extra-checks";
import { unstable_batchedUpdates } from "react-dom"; // ! Won't be needed from REACT 18

type SidebarProviderProps = {
  children: React.ReactNode;
};

interface SubListValue {
  value: string;
  height?: number;
}

type SidebarProviderState = {
  activeMenu: string | null;
  searchSubList: {
    [id: string]: SubListValue;
  };
  setActiveMenu: React.Dispatch<React.SetStateAction<string | null>>;
  onItemClick: (isDropdown: boolean, url?: string) => (e: any) => void;
  onSubListSearch: (id: string, sublistRef?: React.MutableRefObject<HTMLUListElement>) => (e?: unknown) => void;
  isActiveMain: (url: string, isDropdown: boolean) => (match: any, location: any) => boolean;
  isActiveDropdown: (url: string | undefined) => (match: any, location: any) => boolean;
};

const initialValues = { activeMenu: "", searchSubList: {} };

const initialState = {
  ...initialValues,
  setActiveMenu: () => null,
  onItemClick: () => () => null,
  onSubListSearch: () => () => null,
  isActiveMain: () => () => false,
  isActiveDropdown: () => () => false,
};

const SidebarProviderContext = createContext<SidebarProviderState>(initialState);

export function SidebarProvider({ children, ...props }: SidebarProviderProps) {
  const [activeMenu, setActiveMenu] = useState<string | null>(initialValues.activeMenu);
  const [searchSubList, setSearchSublist] = useState<{ [id: string]: SubListValue }>(initialValues.searchSubList);
  const onItemClick = useCallback(
    (isDropdown: boolean, url?: string) => (e: any) => {
      if (url) {
        unstable_batchedUpdates(() => {
          setSearchSublist(initialValues.searchSubList);
          setActiveMenu((prev) => {
            const sameAsBefore = url === prev;
            if (!sameAsBefore && isDropdown) {
              const x = e.target.nextElementSibling.firstElementChild.querySelector("input");
              if (x) {
                x.focus({ preventScroll: true });
              }
            }
            return sameAsBefore ? null : url;
          });
        });
      }
      if (isDropdown) {
        e.preventDefault();
      }
    },
    [setActiveMenu, setSearchSublist]
  );
  const onSubListSearch = useCallback(
    (id: string, sublistRef?: React.MutableRefObject<HTMLUListElement>) => (e: any) => {
      const value = e.target.value || "";
      setSearchSublist((prev) => {
        const prevValue = prev[id] || { value: "", height: 0 };
        if (prevValue.value === value) {
          return prev;
        }
        const shouldSetHeight = prevValue.value.length < 1 && value.length === 1;
        const prevHeight = value.length >= 1 ? prevValue.height : undefined;
        const ref = sublistRef?.current;
        const height = shouldSetHeight ? ref?.clientHeight : prevHeight;
        if (ref && ref.scrollTop > 0) {
          ref.scroll({ top: 0, behavior: "instant" });
        }
        return { [id]: { value, height } };
      });
    },
    [setSearchSublist]
  );
  const isActiveMain = useCallback(
    (url: string, isDropdown: boolean) =>
      (match: any, location: any): boolean => {
        const pathname = location.pathname;
        const sourceUrl = decodeParams(location.search).source;

        for (let i = 0; i < extraChecks.length; i += 1) {
          if (extraChecks[i][0](sourceUrl || pathname, url) && extraChecks[i][1](sourceUrl || pathname, url, match)) {
            return true;
          }
        }

        if (sourceUrl && !Object.values(MODULE_URLS).includes(url) && sourceUrl.startsWith(url)) {
          return true;
        }
        // if (url.startsWith(INTERNAL_URLS.winterReportOrganisationList) && checkIfReportsAreActive(pathname, url)) {
        //   return true;
        // }
        // TODO: Uncheck matching & currently selected dropdown
        if (!match) {
          return false;
        }
        // ! Temporary hack for highlighting Dashboards dropdown from home maps e.g. vantage/elective/resilience
        const lastChar = (activeMenu || "").substr(-1);
        if (isDropdown && activeMenu !== url && match && lastChar === "/") {
          return true;
        }
        // ! --
        if (activeMenu !== "" && isDropdown) {
          return false;
        }
        return true;
      },
    [activeMenu]
  );
  const isActiveDropdown = useCallback(
    (url = "") =>
      (match: any, location: any): boolean => {
        const pathname = location.pathname;
        const sourceUrl = decodeParams(location.search).source;
        for (let i = 0; i < extraChecksDropdown.length; i += 1) {
          if (
            extraChecksDropdown[i][0](sourceUrl || pathname, url) &&
            extraChecksDropdown[i][1](sourceUrl || pathname, url, match)
          ) {
            return true;
          }
        }
        if (sourceUrl === url) {
          return true;
        }
        if (!match) {
          return false;
        }
        return true;
      },
    []
  );
  const value = {
    activeMenu,
    searchSubList,
    setActiveMenu,
    onItemClick,
    onSubListSearch,
    isActiveMain,
    isActiveDropdown,
  };

  return (
    <SidebarProviderContext.Provider {...props} value={value}>
      {children}
    </SidebarProviderContext.Provider>
  );
}

export const useSidebar = () => {
  const context = useContext(SidebarProviderContext);

  if (context === undefined) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }

  return context;
};
