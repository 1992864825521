import React, { useEffect, createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { loadStorage, saveStorage } from "../helpers/localstorage";
// ? TYPES:
import { ApplicationState } from "../store/reducers";

type ConfigProviderProps = {
  children: React.ReactNode;
};

export interface Config {
  sidebarCollapsed: boolean;
  viewTableauRepository: "grid" | "table";
}

type ConfigProviderState = {
  sidebarCollapsedFinished: boolean;
  setSidebarCollapsed: (isCollapsed: boolean) => void;
  toggleSidebarCollapsed: (e?: any) => void;
  updateConfig: (newValues: Partial<Config>) => void;
} & Config;

const defaultConfig: Config = {
  sidebarCollapsed: false,
  viewTableauRepository: "grid",
};

const initialState = {
  ...defaultConfig,
  sidebarCollapsedFinished: false,
  setSidebarCollapsed: () => null,
  toggleSidebarCollapsed: () => null,
  updateConfig: () => null,
};

const ConfigProviderContext = createContext<ConfigProviderState>(initialState);

export function ConfigProvider({ children, ...props }: ConfigProviderProps) {
  const storageName = useSelector((state: ApplicationState) => "shrewd-config@" + state.credentialsReducer.uid);
  const [config, setConfig] = useState(() => loadStorage<Config>(storageName, defaultConfig));
  const [sidebarCollapsedFinished, setSidebarCollapsedFinished] = useState(() => config.sidebarCollapsed);
  useEffect(() => {
    const loaded = loadStorage<Config>(storageName, defaultConfig);
    setConfig((prev) => ({ ...prev, ...loaded }));
    if (typeof loaded.sidebarCollapsed === "boolean") {
      setSidebarCollapsedFinished(loaded.sidebarCollapsed);
    }
  }, [storageName]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSidebarCollapsedFinished(config.sidebarCollapsed);
    }, 820);
    return () => clearTimeout(timeoutId);
  }, [setSidebarCollapsedFinished, config.sidebarCollapsed]);

  const saveConfigStorage = (newValues: Partial<Config>) => {
    const prevStorage = loadStorage<Partial<Config>>(storageName, {});
    saveStorage(storageName, { ...prevStorage, ...newValues });
  };

  const updateConfigWithStorage = (prev: Config, newValues: Partial<Config>) => {
    const areAnyDifferent = Object.entries(newValues).some(([key, value]) => prev[key as keyof Config] !== value);
    if (areAnyDifferent) {
      saveConfigStorage(newValues);
    }
    return { ...prev, ...newValues };
  };

  const updateConfig = (newValues: Partial<Config>) => {
    setConfig((prev) => updateConfigWithStorage(prev, newValues));
  };

  const value = {
    ...config,
    sidebarCollapsedFinished,
    setSidebarCollapsed: (isCollapsed: boolean) => {
      updateConfig({ sidebarCollapsed: isCollapsed });
    },
    toggleSidebarCollapsed: (e?: any) => {
      if (e) {
        e.preventDefault();
      }
      setConfig((prev) => updateConfigWithStorage(prev, { sidebarCollapsed: !prev.sidebarCollapsed }));
    },
    updateConfig,
  };

  return (
    <ConfigProviderContext.Provider {...props} value={value}>
      {children}
    </ConfigProviderContext.Provider>
  );
}

export const useConfig = () => {
  const context = useContext(ConfigProviderContext);

  if (context === undefined) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }

  return context;
};
