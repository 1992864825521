import { mapSettings } from "../../../../components/map/globals";
// ? TYPES:
import { MapOptions } from "../../../../types/google-map";

export const DEFAULT_ZOOM = 9;

export const homeMapSettings: MapOptions = {
  ...mapSettings,
  controlSize: 12,
  disableDefaultUI: true,
  fullscreenControl: false,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
};
