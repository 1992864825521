import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { hasClass } from "../../../helpers/has-class";
import { Icon, Modal, Loader } from "@tscore/react-components";
import { AppItem } from "./app-item";
import { ModalDisabled } from "./modal-disabled";
import { SIDEBAR_DIM_CLASS } from "./consts";
import { withTranslation } from "react-i18next";
import { ReactComponent as PlatformWhiteTransparent } from "../../../sass/assets/platform-white-transparent.svg";
//? TYPES:
import { TMyApp } from "./app-list";
import { ApplicationState } from "../../../store/reducers";
import { TFunction } from "i18next";

interface ModalRefMethods {
  show: (e?: React.MouseEvent<any, MouseEvent>) => void;
  hide: () => void;
}

// ! REMEMBER TO CHANGE $numberOfApps in _settings.scss when adding more apps.

const MyApps: React.FC<{ t: TFunction }> = ({ t }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [lastOpenModalName, setLastOpenModalName] = useState<string | null>(null);
  const node = useRef<HTMLDivElement>(null!);
  const modalRefs = useRef<{
    [key: string]: ModalRefMethods;
  }>({});

  const state = useSelector((state: ApplicationState) => state.menuReducer);
  const { meta, isFetching, myApps } = state;

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (e: any): boolean => {
      if (node.current && node.current.contains(e.target)) {
        // continue
        return false;
      }
      onClose();
      return true;
    };
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => document.removeEventListener("mousedown", handleClickOutside, false);
  }, []);

  const modalStateHandler = (state: "OPEN" | "CLOSE") => {
    const root = document.documentElement;
    if (state === "OPEN") {
      root.className += ` ${SIDEBAR_DIM_CLASS}`;
    } else if (hasClass(root, SIDEBAR_DIM_CLASS)) {
      const classesArray: string[] = root.className.split(" ");
      const index: number = classesArray.indexOf(SIDEBAR_DIM_CLASS);
      if (index !== -1) {
        classesArray.splice(index, 1);
        root.className = classesArray.join(" ");
      }
    }
  };

  // const onToggle = (e: any) => {
  //   setIsOpen((prevState) => !prevState);
  //   e.stopPropagation();
  // };

  const onOpen = (e: any) => {
    setIsOpen(true);
    e.stopPropagation();
  };

  const onCloseModal = (name: string) => {
    modalRefs.current[name].hide();
    setLastOpenModalName(null);
  };

  const itemOnClick = (event: any, name: string, isDisabled: boolean): void => {
    lastOpenModalName && onCloseModal(lastOpenModalName);
    if (isDisabled === true) {
      event.preventDefault();
      setLastOpenModalName(name);
    }
  };

  function renderItem(item: TMyApp | Required<TMyApp["children"]>[number]) {
    if (!item.isDisabled) {
      return <AppItem item={item} itemOnClick={itemOnClick} />;
    }
    return (
      <Modal
        ref={(n: ModalRefMethods) => (modalRefs.current[item.id] = n)}
        onModalOpen={() => modalStateHandler("OPEN")}
        onModalClose={() => modalStateHandler("CLOSE")}
        className="disabled-product-modal"
        style={{ maxWidth: "620px" }}
        trigger={<AppItem item={item} itemOnClick={itemOnClick} />}>
        <ModalDisabled item={item} />
      </Modal>
    );
  }

  return (
    <div id="product-select-holder" className="head dropdown-container" ref={node}>
      <button className={classNames("btn dropdown-trigger", meta.moduleClassName)} id="product-select" onClick={onOpen}>
        {/* <Icon>apps</Icon> */}
        <div>
          <div className="platform-svg">
            <PlatformWhiteTransparent />
          </div>
        </div>
        <span>{t(`g:${meta.moduleId}`)}</span>
        <Icon className="caret">unfold_more</Icon>
      </button>
      <ul className={classNames("dropdown product-dropdown", { "is-open": isOpen })}>
        <li className="header pointer" onClick={onClose}>
          <span className="product-row">
            <Icon className="back">arrow_back_ios</Icon>
            <span>{t("My Apps")}</span>
          </span>
        </li>
        {myApps.map((item, index) => {
          if (isFetching && item.isDisabled) {
            return null;
          }
          return (
            <li key={index} onClick={!item.isDisabled ? onClose : undefined}>
              {renderItem(item)}
              {item.children && (
                <ul className="subapps">
                  {item.children.map((sub, index) =>
                    (isFetching && sub.isDisabled) || (!sub.isDisabled && !sub.url) ? null : (
                      <li key={index} onClick={!sub.isDisabled ? onClose : undefined}>
                        {renderItem(sub)}
                      </li>
                    )
                  )}
                </ul>
              )}
            </li>
          );
        })}
        {isFetching && (
          <li>
            <a className="product-row" href="#!" onClick={(e) => e.preventDefault()}>
              <div className="info ta-center" style={{ padding: 0 }}>
                <Loader isInline />
              </div>
            </a>
          </li>
        )}
        {/* {errors["GET_MENU"] && <SidebarListError />} */}
      </ul>
    </div>
  );
};

export default withTranslation(["myApps", "g"])(MyApps);
