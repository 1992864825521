import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { topBoxPath } from "./HospitalIcon.component";
import { FACILITY_ICON_SIZE, FACILITY_RADIUS } from "../settings";
interface Props {
  colour?: string;
  strokeDasharray?: number | string;
  strokeDashoffset?: number;
  config: {
    strokeWidth?: number;
    width?: number;
    offset?: number;
    boxHeight?: number;
    radius?: number;
  };
}

export const MarkerOutline = ({
  colour = "#297eff",
  strokeDasharray = 6,
  strokeDashoffset = 0,
  // ieVersion = { type: null, version: 0 },
  config: {
    strokeWidth = FACILITY_ICON_SIZE.STROKE_WIDTH,
    width = FACILITY_ICON_SIZE.WIDTH,
    offset = FACILITY_ICON_SIZE.BOX_OFFSET,
    boxHeight = FACILITY_ICON_SIZE.BOX_HEIGHT,
    radius = FACILITY_RADIUS,
  },
}: Props): JSX.Element => {
  const height = offset + boxHeight;
  const d = topBoxPath({
    width: width,
    height: boxHeight,
    offset: offset,
    radius: radius,
  });
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width + strokeWidth * 2} ${height + strokeWidth * 2}`}
      width={width + strokeWidth * 2}
      height={height + strokeWidth * 2}>
      <g>
        <path
          strokeDashoffset={strokeDashoffset}
          strokeDasharray={strokeDasharray}
          transform={`translate(${width / 2 + strokeWidth},${height + strokeWidth})`}
          d={d}
          fill="transparent"
          stroke={colour}
          strokeWidth={strokeWidth}></path>
      </g>
    </svg>
  );
};

export const markerOutlineAsDataUri = (props: Props): string => {
  //   encodeURIComponent(
  //     renderToStaticMarkup(<HospitalIcon {...props} />)
  //       .replace(/"/g, "'")
  //       .replace(/(\r\n|\n|\r)/gm, "")
  //   )
  const svgString = encodeURIComponent(renderToStaticMarkup(<MarkerOutline {...props} />));
  return `data:image/svg+xml,${svgString}`;
};
