// import { createScript } from "../helpers";
import { OPTIMIZATION_SETTINGS } from "../settings";
// ? TYPES:
// import { GmMap, MapOptions } from "../types";
import {
  MapFilters,
  GmMap,
  ChildFeature,
  OpacityList,
  AreaPressureLevel,
  FilterIndicatorList,
  ParentFeature,
  // FilterIndicator
} from "../../../types/google-map";

// import { ImportedMapFilter } from "../types/filters.type";

type FilterIndicator = any;

// export const createGoogleMap = (ref: React.RefObject<HTMLDivElement>, settings: MapOptions): GmMap =>
//   new window.google.maps.Map(ref.current, settings);

// export const createGoogleMapScript = (mapUrl: string, apiKey: string, callback: () => void): void => {
//   if (!document.getElementById("googleMapScript")) {
//     const googleMapScript = createScript(`${mapUrl}?key=${apiKey}`, "googleMapScript");
//     googleMapScript.addEventListener("load", () => callback());
//   } else {
//     callback();
//   }
// };

export const mapCoordinatesToPolygon = (coordinates: number[][][]): { lat: number; lng: number }[][] => {
  return coordinates.map((outer: number[][]) => {
    return outer.map((coordinate: number[]) => ({
      lat: coordinate[1],
      lng: coordinate[0],
    }));
  });
};

export const getMouseCoordinates = (latLng: any): { lat: number; lng: number } => {
  let lat = latLng.lat();
  lat = lat.toFixed(4);
  let lng = latLng.lng();
  lng = lng.toFixed(4);
  return { lat: lat, lng: lng };
};

// export const generateFiltersList = (source: { [key: string]: string }): MapFilters => {
//   return Object.keys(source).reduce((final: MapFilters, key: string) => {
//     return {
//       ...final,
//       [key]: {
//         name: source[key],
//         enabled: true
//       }
//     };
//   }, {});
// };

export const getPolygonOpacity = (
  currentFeature: ChildFeature<any, any> | ParentFeature<any, any> | null,
  feature: ChildFeature<any, any> | ParentFeature<any, any>,
  opacityList: OpacityList | number,
  turnOffHoverOutOtherPolygons = OPTIMIZATION_SETTINGS.turnOffHoverOutOtherPolygons
): number => {
  if (typeof opacityList === "number") {
    return opacityList;
  }
  if (!currentFeature) {
    return opacityList.DEFAULT;
  }
  if (currentFeature === feature) {
    return opacityList.ON_HOVER;
  }
  if (turnOffHoverOutOtherPolygons) {
    return opacityList.DEFAULT;
  }
  return opacityList.OTHER_HOVERED;
};

export const getPixelFromLatLng = (latLng: any, map: GmMap): number[] => {
  const projection = map.getProjection();
  const bounds = map.getBounds();
  const topRight = projection!.fromLatLngToPoint(bounds!.getNorthEast());
  const bottomLeft = projection!.fromLatLngToPoint(bounds!.getSouthWest());
  const scale = Math.pow(2, map.getZoom());
  const worldPoint = projection!.fromLatLngToPoint(latLng);
  return [Math.floor((worldPoint.x - bottomLeft.x) * scale), Math.floor((worldPoint.y - topRight.y) * scale)];
};

//
const aggregatePressureLevels = (
  children: ChildFeature[],
  filters: MapFilters
): { [keyCounty: string]: AreaPressureLevel<FilterIndicatorList>[] } => {
  return children.reduce(
    (final: { [countyKey: string]: AreaPressureLevel<FilterIndicatorList>[] }, child: ChildFeature) => {
      const parentName = child.properties.parentName;
      const filterIndicatorList = Object.keys(filters).reduce((final: FilterIndicatorList, key: string) => {
        return {
          ...final,
          ...(filters[key].enabled && { [key]: child.properties[key] }),
        };
      }, {});
      return {
        ...final,
        [parentName]: [
          ...(final[parentName] ? final[parentName] : []),
          { id: child.properties.ccgName, filters: filterIndicatorList },
        ],
      };
    },
    {}
  );
};

export const getAreaPressureLevels = (
  children: ChildFeature[],
  filters: MapFilters
): {
  children: AreaPressureLevel<FilterIndicatorList>[];
  parent: AreaPressureLevel<FilterIndicatorList>[];
} => {
  const aggregated = aggregatePressureLevels(children, filters);
  const childrenList = Object.keys(aggregated).reduce(
    (final: AreaPressureLevel<FilterIndicatorList>[], key: string) => {
      return [...final, ...aggregated[key]];
    },
    []
  );
  const parentObj: { [countyKey: string]: FilterIndicatorList } = Object.keys(aggregated).reduce(
    (final: { [countyKey: string]: FilterIndicatorList }, key: string) => {
      const parentFilters = aggregated[key].reduce(
        (finalp: { [countyKey: string]: FilterIndicatorList }, item: AreaPressureLevel<FilterIndicatorList>) => {
          const f = Object.keys(item.filters).reduce(
            (finalf: { [filterKey: string]: FilterIndicator[] }, fkey: string) => {
              return {
                ...finalf,
                [fkey]: [...item.filters[fkey], ...(finalp[key] ? finalp[key][fkey] : [])],
              };
            },
            {}
          );
          return {
            ...finalp,
            [key]: f,
          };
        },
        {}
      );
      return { ...final, ...parentFilters };
    },
    {}
  );
  const parentList = Object.keys(parentObj).map((key: string) => {
    return {
      id: key,
      filters: parentObj[key],
    };
  });
  // console.log(childrenList);
  // console.log(parentList);
  return {
    children: childrenList,
    parent: parentList,
  };
};

export const howManySegmentsSelected = (filters: MapFilters): number => {
  return Object.keys(filters).reduce((final: number, key: string) => (filters[key].enabled ? final + 1 : final), 0);
};
