import React, { PureComponent } from "react";
// import { setClasses } from "helpers/helpers";
import classNames from "classnames";

// interface State {}

interface Props {
  isToggle?: boolean;
}

interface Info {
  checked: boolean;
  value?: string | string[] | number | null | readonly string[];
  name?: string | null;
  disabled: boolean;
}

export class SimpleRadio extends PureComponent<
  Props & React.HTMLProps<HTMLInputElement> & React.HTMLAttributes<HTMLInputElement>,
  {}
> {
  handleChange = (event: any) => {
    const isChecked = event.target.checked;
    const info: Info = {
      checked: isChecked,
      value: this.props.value || null,
      name: this.props.name || null,
      disabled: this.props.disabled === true ? true : false,
    };
    typeof this.props.onChange === "function" && this.props.onChange(info as any);
  };

  render() {
    const {
      props: { children, checked, className, isToggle, onChange, ...rest },
    } = this;
    const elementType = isToggle ? "toggle" : "radio";
    return (
      <label className={classNames(className, elementType)}>
        <input onChange={this.handleChange} type="radio" checked={checked} {...rest} />
        <span>{children}</span>
      </label>
    );
  }
}
