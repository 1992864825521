import pointInPolygon from "point-in-polygon";

// ? TYPES:
import { ChildFeature } from "../../../types/google-map";

export function polygonsContainLatLng(
  polygons: ChildFeature[],
  [lng, lat]: [number, number],
  notFoundObject = {}
): any {
  let isWithinPolygon = false;
  for (let i = 0; i < polygons.length; i += 1) {
    const feature = polygons[i];
    // MultiPolygon, most of the time type === "Polygon" just 1 in array
    for (let j = 0; j < feature.geometry.coordinates.length; j += 1) {
      const coordinates = feature.geometry.coordinates[j];
      isWithinPolygon = pointInPolygon([lng, lat], coordinates);
      if (isWithinPolygon) {
        return feature;
      }
    }
  }
  return notFoundObject;
}
