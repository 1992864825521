import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Dropdown, Icon } from "@tscore/react-components";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { removeLastSlashIfExists } from "../../helpers/remove-last-slash-if-exists";
import { generateParams } from "../../helpers/params";
import { INTERNAL_URLS } from "../../globals/internal-urls";
import { POWERBI_REPORT_ID } from "../../globals/powerbi-settings";
// ? TYPES:
import { ApplicationState } from "../../store/reducers";

interface IndicatorOverviewReportItemProps {
  siteIndex: number;
  systemDashboardId: number;
  siteId: number;
  source: string;
}

export const IndicatorOverviewReportItem: React.FC<IndicatorOverviewReportItemProps> = ({
  siteIndex,
  systemDashboardId,
  siteId,
  source,
}) => {
  const { t } = useTranslation();
  const state = useSelector(
    (state: ApplicationState) => state.systemDashboardReducer[systemDashboardId] || state.systemDashboardReducer[-1]
  );
  const unitList = state.dials[siteIndex]?.unitList || [];
  // console.log({ unitList });
  const extraStyles = unitList.length > 2 ? { minHeight: "80%", top: 0 } : {};
  const moduleUrlPrefix = useSelector((state: ApplicationState) => state.menuReducer.meta.moduleUrlPrefix);
  const generateLink = (url: string, extraParams: { [param: string]: any } = {}): string => {
    const baseUrl = `${removeLastSlashIfExists(moduleUrlPrefix)}${INTERNAL_URLS.powerbi}/${url}/`;
    const params = generateParams({
      "rp:Parameter1": systemDashboardId,
      "rp:Parameter2": siteId,
      ...extraParams,
      source: source,
    });
    return `${baseUrl}${params}`;
  };
  return (
    <li className="inception-dropdown-item">
      <a href="#!indicator-overview-report" onClick={(e) => e.preventDefault()}>
        <span className="inception-dropdown__label">
          {/* <Icon>language</Icon> */}
          <span>{t("dashboards:Indicator Overview Report")}</span>
          <span className="inception-dropdown__arrow">
            <Icon>arrow_drop_down</Icon>
          </span>
        </span>
      </a>
      <ul
        className="dropdown"
        style={{
          display: "block",
          width: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          maxHeight: "70vh",
          ...extraStyles,
        }}>
        {unitList.length === 0 && (
          <li>
            <div className="ta-center">
              <i className="l inline" />
            </div>
          </li>
        )}
        <Dropdown.Item
          label={t("dashboards:All Segments")}
          anchorComponent={({ onClick, ...props }: any) => (
            <NavLink to={generateLink(POWERBI_REPORT_ID.INDICATOR_OVERVIEW_ALL_SEGMENTS_PAGINATED_REPORT)} {...props} />
          )}
        />
        <Dropdown.Item isSpacer />
        {unitList.map((unit, i) => (
          <Fragment key={unit.unitId}>
            {i !== 0 && <Dropdown.Item isSpacer />}
            <li className="dropdown__category">
              <h3>{unit.name}</h3>
            </li>
            {unit.childUnitList.map((child) => (
              <Dropdown.Item
                key={child.unitId}
                label={child.name}
                anchorComponent={({ onClick, ...props }: any) => (
                  <NavLink
                    to={generateLink(POWERBI_REPORT_ID.INDICATOR_OVERVIEW_PAGINATED_REPORT, {
                      "rp:Parameter3": child.unitId,
                    })}
                    {...props}
                  />
                )}
              />
            ))}
          </Fragment>
        ))}
      </ul>
    </li>
  );
};
