import React from "react";

import { InlineError } from "@tscore/react-components";
import { CONTENT_HEIGHT } from "./consts";
import { withTranslation } from "react-i18next";
// ? TYPES:
import { ApiError } from "../../../store/types/api";
import { TFunction } from "i18next";

const ErrorEscalationConnectionDumb: React.FC<{ t: TFunction; title: string; error?: ApiError }> = ({
  t,
  title,
  error,
}) => {
  return (
    <InlineError
      style={{ height: CONTENT_HEIGHT }}
      className="middle"
      title={t("errors:connection")}
      description={t("escalation:errorConnectionDescription", { title, error })}
      icon="portable_wifi_off"
    />
  );
};

const ErrorEscalationEmptyDumb: React.FC<{ t: TFunction; type: string }> = ({ t, type }) => {
  return (
    <InlineError
      style={{ height: CONTENT_HEIGHT }}
      className="middle"
      icon="grid_off"
      title={t(`escalation:noTitle.${type}`)}
    />
  );
};

export const ErrorEscalationConnection = withTranslation(["errors", "escalation"])(ErrorEscalationConnectionDumb);
export const ErrorEscalationEmpty = withTranslation(["escalation"])(ErrorEscalationEmptyDumb);
