import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultApiLoading } from "../default-api-loading";
// import { defaultApiSuccess } from "../default-api-success";
import { defaultPagination } from "../default-pagination";
import { paginate } from "../../../helpers/paginate";
// ? TYPES:
import { IntegrationsListResponse, IntegrationsState, IntegrationsStateFull } from "../../types/integrations-state";
import { TIntegrationKey, TIntegrationTypeKey } from "./consts";

const applyInvalidate = (seconds: number) => {
  return Date.now() + seconds * 1000;
};

const defaultState: IntegrationsState = {
  contractName: "",
  meta: defaultPagination,
  data: [],
  isFetching: {},
  errors: {},
  invalidateTimestamp: 0,
};

const integrations = (state: IntegrationsState = defaultState, action: any): IntegrationsState => {
  switch (action.type) {
    case API_START: {
      const prevState = ["GET_TABLEAU_DASHBOARD_LIST"].includes(action.payload)
        ? { ...state, invalidateTimestamp: applyInvalidate(5) }
        : state;
      return defaultApiLoading(prevState, action, true, ["GET_TABLEAU_INTEGRATION_LIST", "GET_TABLEAU_DASHBOARD_LIST"]);
    }
    case API_ERROR: {
      return defaultApiError(state, action);
    }
    case API_END: {
      return defaultApiLoading(state, action, false, ["GET_TABLEAU_INTEGRATION_LIST", "GET_TABLEAU_DASHBOARD_LIST"]);
    }
    case "GET_TABLEAU_INTEGRATION_LIST": {
      const data: IntegrationsListResponse = action.data;
      const contract = data.meta.contractList.find((contract) => contract.contractId === action.dynamicId) || {
        name: "Reports",
      };
      return {
        ...state,
        meta: paginate(data.totalRecords, data.page, data.limit),
        data: data.results,
        contractName: contract.name,
        errors: {},
      };
    }
    case "GET_TABLEAU_DASHBOARD_LIST": {
      return {
        ...state,
        meta: paginate(action.data.totalRecords, action.data.page || 1, action.data.limit || action.data.totalRecords),
        data: action.data.results,
        invalidateTimestamp: applyInvalidate(40),
        errors: {},
      };
    }
    default:
      return state;
  }
};

const defaultCompleteState: IntegrationsStateFull = {
  TABLEAU: {
    DASHBOARD: { "-1": defaultState },
    MENU: { "-1": defaultState },
  },
};

const integrationsById = (state: IntegrationsStateFull = defaultCompleteState, action: any): any => {
  switch (action.type) {
    case API_START:
    case API_ERROR:
    case API_END:
    case "GET_TABLEAU_INTEGRATION_LIST":
    case "GET_TABLEAU_DASHBOARD_LIST": {
      const { integration, integrationType, dynamicId } = action as {
        integration: TIntegrationKey;
        integrationType: TIntegrationTypeKey;
        dynamicId: number;
      };
      if (!integration || !integrationType || !dynamicId) {
        return state;
      }
      return {
        ...state,
        [integration]: {
          ...state[integration],
          [integrationType]: {
            ...state[integration][integrationType],
            [dynamicId]: integrations(state[integration][integrationType][dynamicId], action),
          },
        },
      };
    }
    default:
      return state;
  }
};

export default integrationsById;
