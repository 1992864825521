import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchNhsrAlerts, fetchUpdateNhsrAlerts } from "../../../store/actions/alerts";
import { Button, Checkbox, InlineError } from "@tscore/react-components";
import Pagination from "../../../components/structure/pagination";
// import { decodeParams, searchUpdateQuery } from "../../helpers/params";
// import { navigateToPage } from "../../helpers/change-page";
// import debounce from "lodash/debounce";
// import { DEFAULT_DEBOUNCE_SEARCH_MS } from "../../globals/settings";
import { useTranslation } from "react-i18next";
//? TYPES:
import { ApplicationState } from "../../../store/reducers";
// import { RouteComponentProps } from "react-router-dom";

type TabNhsrAlertsProps = {};
// type TabNhsrAlertsProps = RouteComponentProps<any>;

const MAIN_GET_NAME = "GET_NHSR_ALERTS";
// const ALERT_NAME = "NHSR";

const EmptyAlertRow = () => (
  <tr className="isFetching">
    <td>
      <div className="loading-element">1234</div>
    </td>
    <td>
      <div className="loading-element">XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</div>
    </td>
    <td className="ta-center w1">
      <div className="loading-element">.</div>
    </td>
  </tr>
);

export const TabNhsrAlerts: React.FC<TabNhsrAlertsProps> = () => {
  const { t } = useTranslation();
  // const searchParams = decodeParams(location.search);
  // const page = parseInt(searchParams.page, 10) || 1;
  // const query = searchParams.query;
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchNhsrAlerts());
    }
    fetchInitial();
  }, [dispatch]);
  // const searchIndicator = (value: string | undefined) => searchUpdateQuery(value, history, location);
  // const debouncedSearch = debounce(searchIndicator, DEFAULT_DEBOUNCE_SEARCH_MS);
  const state = useSelector((state: ApplicationState) => state.alertsReducer);
  const { isFetching, nhsrAlerts, nhsrMeta } = state;
  // const changePage = (page: number) => navigateToPage(history, location.search, page);
  // const pageChanging = (page: number): boolean => {
  //   return true;
  // };
  const onAlertChange = (e: { name: "alertEnabled"; checked: boolean }, alert: (typeof nhsrAlerts)[number]) => {
    const { name, checked } = e;
    const updatedAlert = {
      ...alert,
      [name]: checked,
    };
    dispatch(fetchUpdateNhsrAlerts([updatedAlert]));
    return true;
  };
  const onAllAlertToggle = (areAllSelected: boolean) => {
    const updatedAlerts = function () {
      if (areAllSelected) {
        return nhsrAlerts.map((alert) => ({
          ...alert,
          alertEnabled: false,
        }));
      }
      return nhsrAlerts.map((alert) => ({
        ...alert,
        alertEnabled: true,
      }));
    };
    dispatch(fetchUpdateNhsrAlerts(updatedAlerts()));
  };
  const areAllSelected = nhsrAlerts.length === 0 ? false : nhsrAlerts.every((alert) => alert.alertEnabled);

  return (
    <div>
      <header className="mb12">
        {/* <h3></h3> */}
        <div className="ta-right">
          <Button
            onClick={() => onAllAlertToggle(areAllSelected)}
            disabled={nhsrAlerts.length === 0}
            colour="link"
            length="shorter">
            {areAllSelected ? t("generic:Deselect All") : t("generic:Select All")}
          </Button>
          {/* <Input
                // ref={searchInputRef}
                icon="search"
                placeholder="Search"
                id="searchInput"
                autoComplete="escalation_alerts_search"
                onChange={(e: any) => debouncedSearch(e.target.value)}
                holderStyle={{ maxWidth: "240px" }}
                defaultValue={query || ""}
                isLoading={isFetching[MAIN_GET_NAME] && query}
              /> */}
        </div>
      </header>
      {!isFetching[MAIN_GET_NAME] && nhsrAlerts.length === 0 ? (
        <InlineError
          style={{ height: "30vh" }}
          className="middle"
          icon="grid_off"
          title={t("myAccount:No NHSR Alerts")}
        />
      ) : (
        <section>
          <table className="table main no-vertical-lines bordered">
            <thead>
              <tr>
                <th className="w1">{t("myAccount:nhsrTable.0")}</th>
                <th>{t("myAccount:nhsrTable.1")}</th>
                <th className="ta-center w1">{t("myAccount:nhsrTable.2")}</th>
              </tr>
            </thead>
            <tbody>
              {isFetching[MAIN_GET_NAME] ? (
                <EmptyAlertRow />
              ) : (
                nhsrAlerts.map((alert, index) => (
                  <tr key={index}>
                    <td>{alert.areaId}</td>
                    <td>{alert.areaName}</td>
                    <td className="ta-center">
                      <Checkbox
                        isToggle
                        name="alertEnabled"
                        value={alert.areaId}
                        checked={alert.alertEnabled}
                        onCheckboxToggle={(e: any) => onAlertChange(e, alert)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <Pagination
            label={t("myAccount:alertsPaginationLabel")}
            settings={nhsrMeta}
            onChangePage={() => true}
            onChangingPage={() => true}
          />
        </section>
      )}
    </div>
  );
};
