import { PRIORITY_PHONE_COUNTRY_NAMES } from "../../globals/settings";
// ? TYPES:
import { PhoneCountryItem, PhoneCountryListWithPriority } from "../../types/phone-code";

const createPriorityList = () => ({
  label: null,
  options: [],
});

const createDefaultPhoneCodeList = (): PhoneCountryListWithPriority => [createPriorityList()];

export const DEFAULT_PHONE_CODE_LIST: PhoneCountryListWithPriority = createDefaultPhoneCodeList();

export const createPriorityPhoneList = (list: PhoneCountryItem[]) => {
  const f = createDefaultPhoneCodeList();
  return list.reduce((final: PhoneCountryListWithPriority, current) => {
    if (PRIORITY_PHONE_COUNTRY_NAMES.includes(current.country)) {
      final[0].options.push(current);
    } else {
      final.push(current);
    }
    return final;
  }, f);
};
