import React from "react";
import { ReactComponent as AppStoreEn } from "../../sass/assets/apple-store-en.svg";
import { ReactComponent as GooglePlayEn } from "../../sass/assets/google-play-en.svg";
import { BADGES } from "../../globals/CompanyMeta";
// import { useTranslation } from "react-i18next";

interface BadgesProps {
  google: string;
  apple: string;
}

export const GenericBadges: React.FC<BadgesProps> = ({ google, apple }) => {
  // const { t } = useTranslation();
  return (
    <div className="badges">
      <a href={google} rel="noopener noreferrer" target="_blank" className="enclosed">
        <GooglePlayEn />
      </a>
      <a href={apple} rel="noopener noreferrer" target="_blank" className="enclosed">
        <AppStoreEn />
      </a>
    </div>
  );
};

export const Badges: React.FC = () => {
  return <GenericBadges {...BADGES} />;
};
