import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultApiLoading } from "../default-api-loading";
// import { defaultApiSuccess } from "../default-api-success";
import { defaultPagination } from "../default-pagination";
import { paginate } from "../../../helpers/paginate";
import { SOURCE_LANGUAGE } from "../../../globals/settings";
// import { DAYS } from "../../../lang/days";
import i18n from "../../../i18n";
// ? TYPES:
import { AlertsState, EscalationAlert, NhsrAlert, WeeklyPattern } from "../../types/alerts-state";

const enT = i18n.getFixedT(SOURCE_LANGUAGE);
const days: string[] = enT("generic:days", { returnObjects: true, defaultValue: [] }); // only generated once + not really used

const defaultState: AlertsState = {
  escalationAlerts: [],
  escalationMeta: defaultPagination,
  nhsrAlerts: [],
  nhsrMeta: defaultPagination,
  availableTimeSlotList: days.map((day, i: any) => ({
    dayOfWeek: i + 1,
    name: day,
    startTime: 0,
    endTime: 24,
    isEnabled: false,
  })),
  // success: {},
  isFetching: {},
  errors: {},
  didInvalidate: {}, // not used
};

const updateSlots = (currentSlots: WeeklyPattern<string>[], data: WeeklyPattern[]): WeeklyPattern<string>[] => {
  return currentSlots.map((slot) => {
    const found = data.find((item) => item.dayOfWeek === slot.dayOfWeek);
    if (found) {
      return {
        ...found,
        name: slot.name,
      };
    }
    return slot;
  });
};

const alerts = (state: AlertsState = defaultState, action: any): AlertsState => {
  switch (action.type) {
    case API_START: {
      return defaultApiLoading(state, action, true, ["GET_ESCALATION_ALERTS", "GET_NHSR_ALERTS", "GET_WEEKLY_PATTERN"]);
    }
    case API_ERROR: {
      if (action.payload.label === "UPDATE_WEEKLY_PATTERN") {
        return defaultApiError(
          {
            ...state,
            availableTimeSlotList: [...state.availableTimeSlotList],
          },
          action
        );
      }
      return defaultApiError(state, action);
    }
    case API_END: {
      return defaultApiLoading(state, action, false, [
        "GET_ESCALATION_ALERTS",
        "GET_NHSR_ALERTS",
        "GET_WEEKLY_PATTERN",
      ]);
    }
    case "GET_ESCALATION_ALERTS": {
      return {
        ...state,
        escalationAlerts: action.data.results,
        escalationMeta: paginate(action.data.totalRecords, action.data.page, action.data.limit),
      };
    }
    case "UPDATE_ESCALATION_ALERTS": {
      return {
        ...state,
        escalationAlerts: state.escalationAlerts.map((alert) => {
          const updated = (action.data as EscalationAlert[]).find(
            (a) => a.indicatorId === alert.indicatorId && a.planId === alert.planId
          );
          if (updated) {
            return {
              ...alert,
              ...updated,
            };
          }
          return alert;
        }),
      };
    }
    case "GET_NHSR_ALERTS": {
      return {
        ...state,
        nhsrAlerts: action.data.results,
        nhsrMeta: paginate(action.data.totalRecords, 1, action.data.totalRecords),
        // ? If pagination will be added
        // nhsrMeta: paginate(action.data.totalRecords, action.data.page, action.data.limit),
      };
    }
    case "UPDATE_NHSR_ALERTS": {
      return {
        ...state,
        nhsrAlerts: state.nhsrAlerts.map((alert) => {
          const updated = (action.data as NhsrAlert[]).find((a) => a.areaId === alert.areaId);
          if (updated) {
            return {
              ...alert,
              ...updated,
            };
          }
          return alert;
        }),
      };
    }
    case "GET_WEEKLY_PATTERN":
    case "UPDATE_WEEKLY_PATTERN": {
      const data: WeeklyPattern[] = action.data.availableTimeSlots;
      // console.log({ action });
      return {
        ...state,
        availableTimeSlotList: updateSlots(state.availableTimeSlotList, data),
      };
    }
    default:
      return state;
  }
};

export default alerts;
