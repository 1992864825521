import React, { PureComponent } from "react";
import { getRandomArbitrary, getRandomWidthStyles } from "../../helpers/random-generators";

const loadingElementWidths = getRandomWidthStyles(getRandomArbitrary(3, 6) * 2);

export default class ReportEmpty extends PureComponent<any> {
  render() {
    return (
      <main>
        <div className="forms-view isFetching">
          <div className="form-main">
            <div className="form-holder" data-ft="section">
              <div className="element element-section">
                <header>
                  <h2 className="loading-element" {...loadingElementWidths[0]}>
                    h2
                  </h2>
                  <p className="loading-element" {...loadingElementWidths[1]}>
                    p
                  </p>
                </header>
              </div>
              <div className="form-children">
                {loadingElementWidths.map((item: any, index: number) => {
                  if (index === 0 || index === 1 || index % 2 === 0) {
                    return null;
                  }
                  return (
                    <div key={index} className="form-holder" data-ft="singleLine">
                      <div className="element element-singleLine">
                        <header>
                          <h3 className="loading-element" {...item}>
                            h3
                          </h3>
                          <p className="loading-element" {...loadingElementWidths[index + 1]}>
                            p
                          </p>
                        </header>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
