import React, { useState, useLayoutEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
// ? TYPES:
import { EscalationUser } from "../../../store/types/escalation-state";

const PADDING = 12;
const MARGIN = 7;
const CHAR_WIDTH = 7;
const EXTRA_WIDTH = CHAR_WIDTH * 8 + MARGIN;

export const UserList: React.FC<{
  width: number;
  usersAssigned: EscalationUser[];
  currentUserId: number;
}> = ({ width, usersAssigned, currentUserId }) => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  useLayoutEffect(() => {
    if (width === 0) {
      return;
    }
    const x = usersAssigned.slice(0).reduce(
      (final, user, _i, arr) => {
        const userWidth = PADDING + user.fullname.length * CHAR_WIDTH + MARGIN;
        // console.log({ userWidth, f: user.fullname, l: user.fullname.length, width });
        const newWidth = final.currentWidth + userWidth;
        if (newWidth >= width - EXTRA_WIDTH) {
          arr.splice(1); // break early
          return {
            ...final,
            currentWidth: newWidth,
          };
        }
        return {
          currentWidth: newWidth,
          limit: final.limit + 1,
        };
      },
      { currentWidth: 0, limit: 0 }
    );
    // console.log({ limit: x.limit });
    setLimit(x.limit === 0 ? 1 : x.limit);
  }, [setLimit, usersAssigned, width]);
  const onToggleExpand = (_event: any) => {
    return setIsExpanded((prevState) => !prevState);
  };
  return (
    <div className="escalation-user-list">
      {usersAssigned.slice(0, isExpanded ? undefined : limit).map((user) => (
        <span className={classNames("escalation-user", { current: currentUserId === user.id })} key={user.id}>
          {user.fullname}
        </span>
      ))}
      {limit !== 0 && limit < usersAssigned.length && (
        <span className={classNames("escalation-user-more", { isExpanded })} onClick={onToggleExpand}>
          {isExpanded ? t("escalation:lessUsers") : t("escalation:moreUsers", { count: usersAssigned.length - limit })}
        </span>
      )}
    </div>
  );
};
