import i18n from "i18next";
import { upperCaseSnakeCaseToReadable } from "../helpers/upper-case-snake-case-to-readable";
// ? TYPES:
import { NhsrAlert, EscalationAlertUpdate, EscalationAlert } from "../store/types/alerts-state";
import { UpdatedIndicator } from "../store/types/personalised-indicators-state";
import { IndicatorPersonalised } from "../types/indicator";

interface InputSuccess {
  payload: {
    label: string;
    method: string;
  };
  type: "API_SUCCESS";
  hasAnyErrors?: boolean;
}

type InputSuccessAny = InputSuccess & any;

interface OutputSuccess {
  title?: string;
  body: string;
}
//

// const INITIAL_TITLE = "Success!";

const BOOL_CONTEXT = (bool: boolean) => (bool ? "ON" : "OFF");

const SUCCESS_TOASTS_SPECIAL: { [label: string]: (props: InputSuccessAny, tstr: string) => OutputSuccess } = {
  TOGGLE_INDICATOR_ALERTS: (
    { indicatorList }: InputSuccess & { indicatorList: { alertEnabled: boolean }[] },
    tstr: string
  ) => {
    if (indicatorList.length !== 1) {
      return {
        body: i18n.t(tstr, {
          count: indicatorList.length,
          // returnObjects: true,
        }),
      };
    }
    return {
      body: i18n.t(tstr, {
        count: indicatorList.length,
        context: BOOL_CONTEXT(indicatorList[0].alertEnabled),
        // returnObjects: true,
      }),
    };
    // if (indicatorList.length !== 1) {
    //   return { body: "Alerts updated" };
    // }
    // return {
    //   body: `Alert ${indicatorList[0].alertEnabled ? "A" : "Dea"}ctivated`,
    // };
  },
  TOGGLE_FAVOURITE: (
    { indicator: { isFavourite } }: InputSuccess & { indicator: { isFavourite: boolean } },
    tstr: string
  ) => {
    const isFavouriteNow = !isFavourite;
    return {
      body: i18n.t(tstr, {
        context: BOOL_CONTEXT(isFavouriteNow),
        // returnObjects: true,
      }),
    };
    // return { body: isFavouriteNow ? "Added to favourites" : "Removed from favourites" };
    // return { body: isFavouriteNow ? "Favourite Selected" : "Favourite De-selected" };
  },
  UPDATE_NHSR_ALERTS: ({ values }: InputSuccess & { values: NhsrAlert[] }, tstr: string) => {
    const length = values.length;
    const firstValue = values[0].alertEnabled;
    const areAllTheSame = values.every((val) => val.alertEnabled === firstValue);
    // const prefixString = `Region Alert${length > 1 ? "s" : ""}`;
    if (areAllTheSame) {
      return {
        body: i18n.t(tstr, {
          count: length,
          context: BOOL_CONTEXT(firstValue),
          // returnObjects: true,
        }),
      };
      // return {
      //   body: `${prefixString} ${firstValue ? "A" : "Dea"}ctivated`,
      // };
    }
    return {
      body: i18n.t(tstr, {
        count: length,
        // returnObjects: true,
      }),
    };
    // return {
    //   body: `${prefixString} Updated`,
    // };
  },
  UPDATE_ESCALATION_ALERTS: (
    {
      values,
      allPreviousValues,
    }: InputSuccess & { values: EscalationAlertUpdate[]; allPreviousValues: EscalationAlert[] },
    tstr: string
  ) => {
    const changes = values.map((current) => {
      const previous = allPreviousValues.find((p) => p.planId === current.planId);
      if (!previous) {
        console.warn("error");
        return false;
      }
      if (previous.canSendWeekDayAlerts !== current.canSendWeekDayAlerts) {
        return current.canSendWeekDayAlerts;
      }
      return current.canSendWeekEndAlerts;
    });
    const length = changes.length;
    const firstValue = changes[0];
    const areAllTheSame = changes.every((val) => val === firstValue);
    if (areAllTheSame) {
      return {
        body: i18n.t(tstr, {
          count: length,
          context: BOOL_CONTEXT(firstValue),
        }),
      };
    }
    return {
      body: i18n.t(tstr, {
        count: length,
      }),
    };
  },
  ADD_NEW_INDICATOR_GROUP: (
    {
      indicatorList,
      allPreviousValues,
    }: InputSuccess & { indicatorList: UpdatedIndicator[]; allPreviousValues: IndicatorPersonalised[] },
    tstr: string
  ) => {
    const addedList = indicatorList.filter((indicator) => indicator.type === "add");
    const removedList = indicatorList.filter((indicator) => indicator.type === "remove");
    const updatedList = indicatorList.filter((indicator) => {
      if (indicator.type !== "update") {
        return false;
      }
      const found = allPreviousValues.find((previous) => previous.indicatorId === indicator.indicatorId);
      if (!found) {
        console.warn("error");
        return true;
      }
      if (found.alertEnabled === indicator.alertEnabled) {
        return false;
      }
      return true;
    });
    const editedLength = removedList.length + updatedList.length;
    const addedLength = addedList.length;
    if (editedLength === 0) {
      return {
        body: i18n.t(tstr, { count: addedLength, context: "ADDEDONLY" }),
      };
    }
    return {
      body: i18n.t(tstr, { count: editedLength, context: "EDITED" }),
    };
  },
  DELETE_DOCUMENTS: ({ documentIdList }: InputSuccess & { documentIdList: string[] }, tstr) => {
    return { body: i18n.t(tstr, { count: documentIdList.length }) };
  },
  NHSR_DELETE_DOCUMENTS: ({ documentIdList }: InputSuccess & { documentIdList: string[] }, tstr) => {
    return { body: i18n.t(tstr, { count: documentIdList.length }) };
  },
  SAVE_UNSAVED_INDICATORS: ({ hasAnyErrors }: InputSuccess, tstr) => {
    return { body: i18n.t(tstr, { context: hasAnyErrors ? "PARTIAL" : undefined }) };
  },
};

export const getSuccessToastBody = (action: InputSuccessAny): Required<OutputSuccess> => {
  const INITIAL_TITLE = i18n.t([`toastsSuccess:${action.payload.label}.title`, "toastsSuccess:default.title"]);
  const fn = SUCCESS_TOASTS_SPECIAL[action.payload.label];
  if (fn) {
    return {
      title: INITIAL_TITLE,
      ...fn(action, `toastsSuccess:${action.payload.label}.body`),
    };
  }
  return {
    title: INITIAL_TITLE,
    body: i18n.t(`toastsSuccess:${action.payload.label}.body`, {
      defaultValue: upperCaseSnakeCaseToReadable(action.payload.label),
    }),
  };
};
