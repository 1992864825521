import React from "react";
import classNames from "classnames";

import { Icon, Tippy } from "@tscore/react-components";
import { withTranslation } from "react-i18next";
// eslint-disable-next-line
import { NavLink } from "react-router-dom";
// ? TYPES:
import { TFunction } from "i18next";
import { TMyApp } from "./app-list";

interface AppItemProps {
  item: TMyApp | Required<TMyApp["children"]>[number];
  itemOnClick: (e: React.MouseEvent, name: string, isDisabled: boolean) => void;
  t: TFunction;
}

const AppItemInnerWoT: React.FC<Pick<AppItemProps, "item" | "t">> = ({ item, t }) => (
  <>
    {item.icon && (
      <div className="icon">
        <div className="svg-container">{item.icon}</div>
      </div>
    )}
    <div className="info">
      <h6>{t(`g:${item.id}`)}</h6>
      <div className="meta">{t(`productMeta.${item.id}.description`, { defaultValue: "" })}</div>
    </div>
  </>
);

const AppItemInner = withTranslation(["myApps", "g"])(AppItemInnerWoT);

const AppItemWoT: React.FC<AppItemProps> = ({ item, itemOnClick, t }) => {
  const isInternal = (item.url || "").startsWith("/") ? true : false;
  if (isInternal && !item.isDisabled) {
    return (
      <NavLink className="product-row" to={item.url!} onClick={(e) => itemOnClick(e, item.id, item.isDisabled)}>
        <AppItemInner item={item} />
      </NavLink>
    );
  }
  return (
    <a
      className={classNames("product-row", { "is-disabled": item.isDisabled })}
      href={item.url && !item.isDisabled ? item.url : "#!"}
      rel="noreferrer"
      onClick={(e) => itemOnClick(e, item.id, item.isDisabled)}
      target={item.newTab && !item.isDisabled ? "_blank" : ""}>
      <AppItemInner item={item} />
      {item.isDisabled && (
        <div className="info-icon">
          <Tippy content={t("tooltipMoreInfo")} placement="right">
            <span>
              <Icon>info</Icon>
            </span>
          </Tippy>
        </div>
      )}
    </a>
  );
};

export const AppItem = withTranslation(["myApps"])(AppItemWoT);
