import React, { useEffect, useState, useRef, useMemo, forwardRef } from "react";
// import { createPortal } from "react-dom";
import Meta from "../../components/meta";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIndicators,
  updateIndicator,
  postUnsavedIndicators,
  discardIndicators,
  updateMoveFavourite,
} from "../../store/actions/manual-updates";

import { Icon, Button, Input, Tippy, InlineError } from "@tscore/react-components";
import Pagination from "../../components/structure/pagination";
import { ManualUpdatesEmpty } from "./empty";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { DEFAULT_DEBOUNCE_SEARCH_MS } from "../../globals/settings";
import { calculateIndicatorColourByThreshold } from "../../helpers/brag-helpers";
import { decodeParams, updateParams, searchUpdateQuery } from "../../helpers/params";
import { navigateToPage } from "../../helpers/change-page";
import { positiveNegativeDecimals } from "../../helpers/filters";
import calculateTextWidth from "../../helpers/calculate-text-width";
import { useClickOutside } from "../../hooks/use-click-outside";
import { joinErrors } from "../../helpers/join-errors";
import { useTranslation } from "react-i18next";
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { IndicatorRow } from "./indicator-row";
import { PrevNextPageDroppable } from "./prev-next-page-droppable";
//? TYPES:
import { ApplicationState } from "../../store/reducers";
import { RouteComponentProps } from "react-router-dom";
import { IndicatorManual } from "../../types/indicator";
import { Active } from "@dnd-kit/core";
// import IndicatorThreshold from "../../types/indicator-threshold";

type Indicator = IndicatorManual;

interface Params {
  id: string;
  pretty?: string;
}

type ManualUpdatesPageProps = RouteComponentProps<Params>;

const indicatorValueParser = (key: string, value: any, indicator: Indicator): any => {
  switch (key) {
    case "newValue":
      if (indicator.dataType === 10) {
        return value;
      }
      return positiveNegativeDecimals(value);
    case "comment":
      return value;
    case "newValueTimestamp":
      return value; //.toISOString();
    default:
      return value;
  }
};

const SEARCH_WIDTH = {
  MIN: 20,
  MAX: 30,
};

export const CustomValueDatePicker = forwardRef(({ onClick, value, onChange, type, ...props }: any, ref: any) => (
  <div ref={ref} className={"icon-input left date"} onClick={onClick}>
    <input
      type="text"
      autoComplete={false}
      {...props}
      onChange={onChange}
      value={value}
      className={`input input-brag${value === "" ? "" : " brag-blue"}`}
    />
    <i className="icon material-icons">calendar_today</i>
  </div>
));

export const ManualUpdatesPage: React.FC<ManualUpdatesPageProps> = ({ match, location, history }) => {
  const { t } = useTranslation();
  const [refreshKey, setRefreshKey] = useState(new Date().toISOString());
  const [activeDrag, setActiveDrag] = useState<Active | null>(null);
  const [currentActiveRow, setCurrentActiveRow] = useState(-1);
  const [searchWidth, setSearchWidth] = useState(SEARCH_WIDTH.MIN);
  const [searchAreaLeft, setSearchAreaLeft] = useState(true);
  const [touched, setTouched] = useState<{ [indicatorId: string]: boolean }>({});
  // const alertNextPage = -1;
  const mainTableRef = useRef<HTMLDivElement>(null!);
  const searchInputRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null!);
  useClickOutside(mainTableRef, () => setCurrentActiveRow(-1), { capture: true });
  // const { pretty } = match.params;
  // let { id } = match.params;
  // id = parseInt(id as unknown, 10);
  const id = parseInt(match.params.id, 10);
  const searchParams = decodeParams(location.search);
  const page = parseInt(searchParams.page, 10) || 1;
  const query = searchParams.query;
  const filters = searchParams.filters ? searchParams.filters.split(",") : [];
  const SHOW_ONLY_FAVOURITES =
    filters.findIndex((filter: string) => filter === "ONLY_FAVOURITES") === -1 ? false : true;
  const dispatch = useDispatch();
  useEffect(() => {
    const isFavourite = SHOW_ONLY_FAVOURITES ? true : undefined;
    async function fetchInitial() {
      await dispatch(fetchIndicators(id, page, isFavourite, query));
    }
    fetchInitial();
  }, [dispatch, id, page, query, SHOW_ONLY_FAVOURITES, refreshKey]);
  const state = useSelector(
    (state: ApplicationState) => state.manualUpdatesReducer[id] || state.manualUpdatesReducer[-1]
  );
  const { isFetching, indicators, isSaving, unsaved, meta, label, dndMeta, lastDndAction } = state;
  const activeDragItem = useMemo(
    () => indicators.find((item) => item.indicatorId === activeDrag?.id),
    [activeDrag, indicators]
  );
  const apiErrors = state.errors["SAVE_UNSAVED_INDICATORS"];
  const fieldErrorsWithApi = joinErrors({}, apiErrors);
  const touchIndicator = (indicatorId: number | "RESET" = "RESET") => {
    setTouched((prevState) => {
      if (typeof indicatorId !== "number") {
        return {};
      }
      return {
        ...prevState,
        [indicatorId]: true,
      };
    });
  };
  const noop = () => {}; // eslint-disable-line
  // const { id } = match.props;
  const changePage = (page: number) => navigateToPage(history, location.search, page);
  const pageChanging = (_page: number): boolean => {
    // if (this.agency.unsaved.size > 0) {
    //   this.setState({ alertNextPage: page });
    //   return false;
    // }
    return true;
  };
  const increaseSearchInput = debounce(setSearchWidth, 100);
  const decreaseSearchInput = debounce(setSearchWidth, 700);
  const onChangeIndicator = (e: any, indicator: Indicator, name = "") => {
    const value: any = e.target.value || null;
    const key: any = e.target.name || name;
    const newIndicator: Indicator = {
      ...indicator,
      ...(key === "newValue" && { newValueColour: calculateIndicatorColourByThreshold(indicator, value) }),
      ...(key === "newValue" && !indicator.newValueTimestamp && { newValueTimestamp: new Date() }),
      [key]: indicatorValueParser(key, value, indicator),
    };

    dispatch(updateIndicator(id, newIndicator));
    touchIndicator(indicator.indicatorId);
  };
  const setLeftSearchArea = (hasLeftSearchArea: boolean): boolean => {
    setSearchAreaLeft(hasLeftSearchArea);
    return true;
  };
  const toggleAllFavourites = () => {
    const f = SHOW_ONLY_FAVOURITES
      ? filters.filter((item: string) => item !== "ONLY_FAVOURITES").join(",")
      : [...filters, "ONLY_FAVOURITES"].join(",");
    history.push({
      search: updateParams(location.search, {
        filters: f === "" ? undefined : f,
        page: SHOW_ONLY_FAVOURITES ? page : 1,
      }),
    });
  };
  const searchIndicator = (value: string | undefined) => searchUpdateQuery(value, history, location);
  const resetFilters = () => {
    history.push({
      search: updateParams(location.search, { filters: undefined, query: undefined }),
    });
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  };
  const debouncedSearch = debounce(searchIndicator, DEFAULT_DEBOUNCE_SEARCH_MS);
  // if (errors["GET_INDICATORS"]) {
  //   // TODO:
  //   return (
  //     <main>
  //       <div className="manual-updates-layout">
  //         <div>
  //           Ooops. Something went wrong when getting indicators.
  //           <a
  //             href="#!reload"
  //             onClick={async (e: any) => {
  //               e.preventDefault();
  //               await dispatch(fetchIndicators(id, page));
  //               return false;
  //             }}>
  //             Click here to try again
  //           </a>
  //         </div>
  //         <div>
  //           More information: <code>{errors["GET_INDICATORS"]}</code>
  //         </div>
  //       </div>
  //     </main>
  //   );
  // }
  const HEADINGS: { value: string; id: string }[] = [
    { value: "", id: "drag" },
    { value: t("manualUpdates:mainTable.0"), id: "favourite" },
    { value: t("manualUpdates:mainTable.1"), id: "id" },
    { value: t("manualUpdates:mainTable.2"), id: "name" },
    { value: t("manualUpdates:mainTable.3"), id: "brag" },
  ];
  const newValuePlaceholder = t("manualUpdates:New Value");
  const newValueWidth = useMemo(
    () => calculateTextWidth(newValuePlaceholder, "normal 400 13px sans-serif", 22),
    [newValuePlaceholder]
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  // const newValueWidth = newValuePlaceholder.length * 8;
  return (
    <main>
      <Meta
        title={label}
        isFetching={label === "" ? isFetching : false}
        breadcrumbs={t("manualUpdates:breadcrumbs", { returnObjects: true })}
      />
      <header className="content-header">
        <div
          className="ta-right"
          style={{ width: "100%" }}
          onMouseLeave={() =>
            setLeftSearchArea(true) &&
            document.activeElement!.id !== "searchInput" &&
            decreaseSearchInput(SEARCH_WIDTH.MIN)
          }
          onMouseEnter={() => setLeftSearchArea(false)}>
          {/* <form action="#!"> */}
          {/* <input type="reset" value="sdds" /> */}
          {(SHOW_ONLY_FAVOURITES || query) && (
            <Button type="reset" colour="link" onClick={() => resetFilters()}>
              {t("generic:Reset filters")}
            </Button>
          )}
          <Tippy content={t("manualUpdates:Toggle Favourites")} placement="bottom">
            <div data-tooltipped>
              <Button onClick={() => toggleAllFavourites()} colour="lightgray-outline" length="short">
                <Icon
                  className={classNames({
                    "color-orange": SHOW_ONLY_FAVOURITES,
                  })}>
                  star
                </Icon>
              </Button>
            </div>
          </Tippy>
          <Input
            ref={searchInputRef}
            icon="search"
            placeholder={t("generic:Search")}
            id="searchInput"
            autoComplete="indicator_search"
            name="indicator_search"
            onChange={(e: any) => debouncedSearch(e.target.value)}
            onFocus={() => increaseSearchInput(SEARCH_WIDTH.MAX)}
            // onMouseEnter={() => this.increaseSearchInput(SEARCH_WIDTH.MAX)}
            // onFocus={() => this.setState({ searchWidth: 60 })}
            onBlur={() => searchAreaLeft && decreaseSearchInput(SEARCH_WIDTH.MIN)}
            holderStyle={{ maxWidth: searchWidth + "%" }}
            defaultValue={query || ""}
            isLoading={isFetching && query}
          />
          {/* </form> */}
        </div>
        {Object.keys(unsaved).length > 0 && (
          <div className="follower">
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("manualUpdates:followerText", { count: Object.keys(unsaved).length }),
                }}>
                {/* {!!~alertNextPage && (
                  <span style={{ fontSize: "1.2rem", opacity: 0.8 }}>
                    <br />
                    Please save or discard to continue.
                  </span>
                )} */}
              </p>
              <Button
                colour="link"
                onClick={() => {
                  dispatch(discardIndicators(id));
                  touchIndicator();
                }}
                disabled={isSaving}>
                {t("generic:Discard")}
              </Button>
              <Button
                icon="check"
                onClick={() => {
                  dispatch(postUnsavedIndicators(id));
                  touchIndicator();
                }}
                isLoading={isSaving}
                disabled={isFetching}>
                {t("generic:Save")}
              </Button>
            </div>
          </div>
        )}
      </header>
      <div className="manual-updates-layout">
        {!isFetching && indicators.length === 0 ? (
          <InlineError
            style={{ height: "30vh" }}
            className="middle"
            title={t("manualUpdates:errorNoIndicatorsTitle")}
            description={t("manualUpdates:errorNoIndicatorsDescription")}
            icon="grid_off"
          />
        ) : (
          <div>
            <DndContext
              sensors={sensors}
              modifiers={[restrictToVerticalAxis]}
              onDragStart={({ active }) => {
                setActiveDrag(active);
              }}
              onDragEnd={({ over, active }) => {
                // setActiveId(null);
                if (over && active && over.id !== active.id) {
                  const overId = over.id as number | "next" | "prev";
                  const afterSuccess = () => {
                    if (["next", "prev"].includes(overId as string)) {
                      setRefreshKey(new Date().toISOString());
                    }
                    // reset active?
                  };
                  dispatch(updateMoveFavourite(id, active.data.current!.indicator, overId, afterSuccess));
                }
                setActiveDrag(null);
              }}
              onDragCancel={() => {
                setActiveDrag(null);
              }}>
              <SortableContext strategy={verticalListSortingStrategy} items={dndMeta.ids}>
                <div style={{ position: "relative" }}>
                  <PrevNextPageDroppable prevOrNext="prev" isAllowed={dndMeta.isPrevDropAllowed} />
                  <div
                    id="mu-table"
                    className="table flex-table bordered no-vertical-lines main shadowed"
                    ref={mainTableRef}>
                    <header className="thead">
                      <ul className="row">
                        {HEADINGS.map((cell) => (
                          <li key={cell.id} data-id={cell.id} className="th">
                            {cell.value}
                          </li>
                        ))}
                      </ul>
                    </header>
                    <div className="tbody">
                      {isFetching && !lastDndAction ? (
                        <ManualUpdatesEmpty length={indicators.length} />
                      ) : (
                        indicators.map((row: Indicator, rowindex: number) =>
                          SHOW_ONLY_FAVOURITES && !row.isFavourite ? null : (
                            <IndicatorRow
                              id={id}
                              isTouched={touched[row.indicatorId]}
                              hasError={fieldErrorsWithApi[row.indicatorId]}
                              key={row.indicatorId}
                              row={row}
                              rowindex={rowindex}
                              currentActiveRow={currentActiveRow}
                              newValuePlaceholder={newValuePlaceholder}
                              newValueWidth={newValueWidth}
                              onChangeIndicator={onChangeIndicator}
                              setCurrentActiveRow={setCurrentActiveRow}
                            />
                          )
                        )
                      )}
                      <DragOverlay className="drag-overlay">
                        {activeDragItem ? (
                          <IndicatorRow
                            id={id}
                            isTouched={touched[activeDragItem.indicatorId]}
                            hasError={fieldErrorsWithApi[activeDragItem.indicatorId]}
                            row={activeDragItem}
                            rowindex={-1}
                            currentActiveRow={currentActiveRow}
                            newValuePlaceholder={newValuePlaceholder}
                            newValueWidth={newValueWidth}
                            onChangeIndicator={noop}
                            setCurrentActiveRow={noop}
                          />
                        ) : null}
                      </DragOverlay>
                    </div>
                  </div>
                  <PrevNextPageDroppable prevOrNext="next" isAllowed={dndMeta.isNextDropAllowed} />
                </div>
              </SortableContext>
            </DndContext>
            <Pagination
              label={t("manualUpdates:indicatorsPaginationLabel")}
              settings={meta}
              onChangePage={(page: number) => changePage(page)}
              onChangingPage={(page: number) => pageChanging(page)}
            />
          </div>
        )}
      </div>
    </main>
  );
};
