import React from "react";
import { Icon } from "@tscore/react-components";
import { useSidebar } from "../../providers/sidebar.provider";
import { withTranslation } from "react-i18next";
// ? TYPES:
import { TFunction } from "i18next";

interface Props {
  showSearch: boolean;
  url: string;
  sublistRef: React.MutableRefObject<HTMLUListElement>;
  t: TFunction;
}

const SidebarSublistSearchWoT: React.FC<Props> = ({ t, showSearch, url, sublistRef }) => {
  const { onSubListSearch, searchSubList } = useSidebar();
  const handleEscape = ({ keyCode }: { keyCode: number }) => {
    if (keyCode === 27) {
      onSubListSearch(url, sublistRef)({ target: { value: "" } });
    }
  };
  if (!showSearch) {
    return null;
  }
  const v = searchSubList[url] || { value: "" };
  return (
    <li className="sublist__search">
      <Icon className="sublist__search__icon">search</Icon>
      <input
        className="sublist__search__input"
        type="text"
        value={v.value}
        onChange={onSubListSearch(url, sublistRef)}
        placeholder={t("sidebar:sublistSearchPlaceholder")}
        onKeyDown={handleEscape}
      />
      <button
        className="sublist__search__clear"
        data-visible={v.value.length > 0}
        type="button"
        onClick={onSubListSearch(url, sublistRef)}>
        <Icon>close</Icon>
      </button>
    </li>
  );
};

export const SidebarSublistSearch = withTranslation(["sidebar"])(SidebarSublistSearchWoT);
