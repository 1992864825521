// ? TYPES:
import { PhoneCodeList, PhoneCountryItem, PhoneCountryPriorityList } from "../../types/phone-code";

const findPhoneCodeSimpleList = (
  simpleList: PhoneCountryItem[],
  q: string,
  searchByKey: keyof PhoneCountryItem
): PhoneCountryItem | null => {
  return simpleList.find((item) => item[searchByKey] === q) || null;
};

function isList(item: PhoneCountryItem | PhoneCountryPriorityList): item is PhoneCountryPriorityList {
  return "options" in item;
}

export const findPhoneCode = (
  list: PhoneCodeList,
  q: string,
  searchByKey: keyof PhoneCountryItem = "country"
): PhoneCountryItem | null => {
  for (const item of list) {
    if (isList(item)) {
      const found = findPhoneCodeSimpleList(item.options, q, searchByKey);
      if (found) {
        return found;
      }
    } else if (item[searchByKey] === q) {
      return item;
    }
  }
  return null;
};
