import { apiAction } from "../api";
import { SERVICE_URLS } from "../../../globals/service-urls";
import { createPriorityPhoneList } from "../../utils/create-priority-phone-list";
import {
  CHECK_TOKEN_URL,
  LOG_IN_URL,
  REFRESH_SUFFIX,
  PRELOG_IN_URL,
  LOG_OUT_URL,
  REGISTER_TOTP_URL,
  REGISTER_SMS_URL,
  REGISTER_EMAIL_URL,
  VERIFY_TOTP_URL,
  VERIFY_SMS_URL,
  VERIFY_EMAIL_URL,
} from "./consts";
// import { generateParams } from "../../../helpers/generate-params";

import { RESET_PASSWORD_URL } from "../../../pages/login/consts";
// import { BASENAME } from "../../../globals/settings";

// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";
import { CredentialsWithDeviceId, MFA, MFAType, PreloginResponse } from "../../types/credentials-state";

const AUTHORIZATION_GENERAL_API_SETTINGS = {
  baseURL: process.env.REACT_APP_API_AUTH,
  method: "POST" as const,
  headers: {
    Authorization: `Basic ${btoa(process.env.REACT_APP_BASIC_AUTH_USER_PASS as string)}`,
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
};

const deviceType = "web";

const generateFormData = (data: { [key: string]: any }): string => {
  return Object.keys(data)
    .filter((key) => data[key] !== undefined && data[key] !== null && data[key] !== "") // maybe just undefined check needed
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
    })
    .join("&");
};

const getUserInfo = (data: { token: string | null }, callbacks: ApiCallback): ApiAction => {
  return apiAction({
    ...AUTHORIZATION_GENERAL_API_SETTINGS,
    url: CHECK_TOKEN_URL,
    data: generateFormData(data),
    ...callbacks,
  });
};

const logIn = (
  { username, password, deviceId, mfaType, mfaCode, rememberDevice }: CredentialsWithDeviceId & MFA,
  callbacks: ApiCallback
): ApiAction => {
  const data = {
    grant_type: "password",
    username,
    password,
    deviceId,
    mfa_type: mfaType,
    mfa_code: mfaCode,
    rememberDevice,
    deviceType,
  };
  return apiAction({
    ...AUTHORIZATION_GENERAL_API_SETTINGS,
    url: LOG_IN_URL,
    data: generateFormData(data),
    ...callbacks,
  });
};

const refreshToken = ({ refresh_token }: { refresh_token: string }, callbacks: ApiCallback): ApiAction => {
  const data = {
    grant_type: "refresh_token",
    refresh_token,
  };
  return apiAction({
    ...AUTHORIZATION_GENERAL_API_SETTINGS,
    url: `${SERVICE_URLS["auth-service"]}/oauth/token?${REFRESH_SUFFIX}`,
    data: generateFormData(data),
    ...callbacks,
  });
};

const preLogin = (
  { username, password, deviceId, mfaType }: CredentialsWithDeviceId & { mfaType?: MFAType },
  callbacks: ApiCallback<PreloginResponse>
) => {
  const data = {
    grant_type: "password",
    username,
    password,
    deviceId,
    mfa_type: mfaType,
    deviceType,
  };
  return apiAction({
    ...AUTHORIZATION_GENERAL_API_SETTINGS,
    url: PRELOG_IN_URL,
    data: generateFormData(data),
    transformResponse: (response: PreloginResponse) => {
      const options = (response as any).options || [];
      if (response.requirement === "REGISTER") {
        return {
          ...response,
          options,
          optionsNextMap: {},
          phoneCountryCodeList: createPriorityPhoneList(response.phoneCountryCodeList),
        };
      }
      if (response.requirement === "ENFORCE") {
        return {
          ...response,
          options,
          optionsNextMap:
            options.length <= 1
              ? {}
              : options.reduce((acc: { [k: string]: MFAType | undefined }, mfaType: MFAType, i: number) => {
                  const nextOption = options[i + 1] ? options[i + 1] : options[0];
                  return {
                    ...acc,
                    [mfaType]: nextOption,
                  };
                }, {}),
        };
      }
      return {
        ...response,
        options,
        optionsNextMap: {},
      };
    },
    ...callbacks,
  });
};

const resendVerificationCode = (
  { username, password, deviceId, mfaType }: CredentialsWithDeviceId & { mfaType?: MFAType },
  callbacks: ApiCallback<PreloginResponse>
) => {
  const data = {
    grant_type: "password",
    username,
    password,
    deviceId,
    mfa_type: mfaType,
    deviceType,
  };
  return apiAction({
    ...AUTHORIZATION_GENERAL_API_SETTINGS,
    url: PRELOG_IN_URL,
    data: generateFormData(data),
    ...callbacks,
  });
};

const logOut = (callbacks: ApiCallback): ApiAction => {
  return apiAction({
    baseURL: AUTHORIZATION_GENERAL_API_SETTINGS.baseURL,
    method: "GET" as const,
    url: LOG_OUT_URL,
    ...callbacks,
  });
};

// sends email to user with forgotpassword
const forgotPasswordMail = (values: { username: string }, callbacks: ApiCallback): ApiAction => {
  //process.env.REACT_APP_API_BASE
  const body = {
    ...values,
    redirectUrl: window.location.origin + process.env.PUBLIC_URL + RESET_PASSWORD_URL + "/",
  };
  return apiAction({
    method: "POST",
    url: `${SERVICE_URLS["user-service"]}/account/forgot-password/request-mail`,
    data: body,
    ...callbacks,
  });
};

const setForgotPassword = (
  values: { username: string; key: string; password: string; confirmPassword: string },
  callbacks: ApiCallback
): ApiAction => {
  const body = values;
  return apiAction({
    method: "POST",
    url: `${SERVICE_URLS["user-service"]}/account/forgot-password/confirm-new-password`,
    data: body,
    ...callbacks,
  });
};

const accountActivate = (
  values: { username: string; key: string; password: string; confirmPassword: string },
  callbacks: ApiCallback
): ApiAction => {
  const body = values;
  return apiAction({
    method: "POST",
    url: `${SERVICE_URLS["user-service"]}/account/activate`,
    data: body,
    ...callbacks,
  });
};

const registerMfaTotp = ({ username, password, deviceId }: CredentialsWithDeviceId, callbacks: ApiCallback) => {
  const data = {
    grant_type: "password",
    username,
    password,
    deviceId,
    preferred: true,
    deviceType,
  };
  return apiAction({
    ...AUTHORIZATION_GENERAL_API_SETTINGS,
    url: REGISTER_TOTP_URL,
    data: generateFormData(data),
    ...callbacks,
  });
};

const registerMfaSms = (
  {
    username,
    password,
    deviceId,
    mobile,
    phoneCountryCode,
  }: CredentialsWithDeviceId & { mobile?: string; phoneCountryCode?: string }, // required really
  callbacks: ApiCallback
) => {
  const cleanMobile = mobile ? mobile.replace(/\D/g, "") : mobile;
  // 94, 48 works with and without 0
  const data = {
    grant_type: "password",
    username,
    password,
    deviceId,
    mobile:
      !!cleanMobile && !cleanMobile.startsWith("0") && ["44"].includes(phoneCountryCode!)
        ? "0" + cleanMobile
        : cleanMobile,
    phoneCountryCode,
    preferred: true,
    deviceType,
  };
  return apiAction({
    ...AUTHORIZATION_GENERAL_API_SETTINGS,
    url: REGISTER_SMS_URL,
    data: generateFormData(data),
    ...callbacks,
    transformResponse: (response: string) => ({
      message: response,
    }),
  });
};

const registerMfaEmail = (
  { username, password, deviceId }: CredentialsWithDeviceId & { email?: string },
  callbacks: ApiCallback
) => {
  const data = {
    grant_type: "password",
    username,
    password,
    deviceId,
    preferred: true,
    deviceType,
  };
  return apiAction({
    ...AUTHORIZATION_GENERAL_API_SETTINGS,
    url: REGISTER_EMAIL_URL,
    data: generateFormData(data),
    ...callbacks,
  });
};

const verifyMfaBase = (
  { username, password, deviceId, mfaCode, rememberDevice }: CredentialsWithDeviceId & MFA,
  callbacks: ApiCallback,
  url: string
) => {
  const data = {
    grant_type: "password",
    username,
    password,
    deviceId,
    preferred: true,
    isToValidate: true,
    mfa_code: mfaCode,
    rememberDevice,
    deviceType,
  };
  return apiAction({
    ...AUTHORIZATION_GENERAL_API_SETTINGS,
    url: url,
    data: generateFormData(data),
    ...callbacks,
  });
};

const verifyMfaTotp = (credentials: CredentialsWithDeviceId & MFA, callbacks: ApiCallback) => {
  return verifyMfaBase(credentials, callbacks, VERIFY_TOTP_URL);
};

const verifyMfaSms = (credentials: CredentialsWithDeviceId & MFA, callbacks: ApiCallback) => {
  return verifyMfaBase(credentials, callbacks, VERIFY_SMS_URL);
};

const verifyMfaEmail = (credentials: CredentialsWithDeviceId & MFA, callbacks: ApiCallback) => {
  return verifyMfaBase(credentials, callbacks, VERIFY_EMAIL_URL);
};

const AuthService = {
  getUserInfo,
  preLogin,
  logIn,
  refreshToken,
  logOut,
  forgotPasswordMail,
  setForgotPassword,
  accountActivate,
  registerMfaTotp,
  registerMfaSms,
  registerMfaEmail,
  resendVerificationCode,
  verifyMfaTotp,
  verifyMfaSms,
  verifyMfaEmail,
};

export default AuthService;
