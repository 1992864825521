import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from "@tscore/react-components";
import { withTranslation } from "react-i18next";
// ? TYPES:
import { ModalRefMethods } from "../../../../types/modal";
import { TFunction } from "i18next";
import { MFAType } from "../../../../store/types/credentials-state";
import { ApplicationState } from "../../../../store/reducers";

export const ModalMfaSetupSuccess: React.FC = () => {
  const modalRef = useRef<ModalRefMethods>(undefined!);
  const mfaType = useSelector((state: ApplicationState) => state.credentialsReducer.mfa.setupSuccess);
  useEffect(() => {
    if (modalRef.current && mfaType) {
      // probably don't need to reset state
      modalRef.current.show();
    }
  }, [mfaType, modalRef.current]);
  return (
    <Modal className="dialog" trigger={null} ref={modalRef}>
      <ModalContent mfaType={mfaType!} />
    </Modal>
  );
};

const ModalContentWoT: React.FC<{ t: TFunction; mfaType: MFAType }> = ({ t, mfaType }) => {
  return (
    <div>
      <Modal.Header title={t("mfa.setup.successModal.title")} titleClassName="color-green" />
      <Modal.Body>
        <p>{t("mfa.setup.successModal.description", { context: mfaType })}</p>
      </Modal.Body>
      <Modal.Footer className="ta-center">
        <Button colour="link" data-close-modal>
          {t("mfa.setup.successModal.Close")}
        </Button>
      </Modal.Footer>
    </div>
  );
};

const ModalContent = withTranslation(["login"])(ModalContentWoT);
