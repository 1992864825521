import React from "react";
// import { DEFAULT_PAGE_LIMIT } from "../../globals/settings";
// ? TYPES:
import { ReportType } from "../../store/types/reports-state";
import { TModuleUrlKey } from "../../globals/internal-urls";

export const EmptyReportList = ({
  length,
  reportType,
  module,
}: {
  length: number;
  reportType: ReportType;
  module: TModuleUrlKey;
}) => {
  const list = Array.from({ length: length === 0 ? 3 : length });
  let colspan = 8;
  if (module === "Resilience") {
    colspan = colspan - 1;
  }
  if (reportType !== "winterReport") {
    colspan = colspan - 2;
  }
  const columns = Array.from({ length: colspan });
  return (
    <>
      {list.map((_r: any, r: number) => (
        <ul className="row isFetching" key={r}>
          {columns.map((_c: any, c: number) => (
            <li className="cell" key={c}>
              <div className="loading-element">{colspan}</div>
            </li>
          ))}
        </ul>
      ))}
    </>
  );
};
