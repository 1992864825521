import ManualUpdatesService from "./manual-updates.service";
import { cancelRequest } from "../../../helpers/cancel-request";

import { formatToISO } from "../../../lang/DateTimeFormats";

// ? TYPES:
import { ApiAction } from "../../types/api";
import { IndicatorManual } from "../../../types/indicator";
import { ApplicationState } from "../../../store/reducers";
type Indicator = IndicatorManual;

const getIndicators = (agencyId: number, page: number, data: any): any => ({
  type: "GET_INDICATORS",
  page,
  agencyId,
  data,
});

export const updateIndicator = (agencyId: number, indicator: Indicator): any => ({
  type: "UPDATE_INDICATOR",
  agencyId,
  indicator,
});

const toggleFavourite = (
  agencyId: number,
  indicator: Indicator,
  orderIdList: { indicatorId: number; orderId: number }[]
): any => ({
  type: "TOGGLE_FAVOURITE",
  agencyId,
  indicator,
  orderIdList,
});

const moveFavourite = (
  agencyId: number,
  indicator: Indicator,
  orderId: number,
  orderIdList: { indicatorId: number; orderId: number }[]
): any => ({
  type: "MOVE_FAVOURITE",
  agencyId,
  indicator,
  orderId,
  orderIdList,
});

const saveUnsavedIndicators = (agencyId: number, response: any): any => ({
  type: "SAVE_UNSAVED_INDICATORS",
  agencyId,
  response,
});

export const discardIndicators = (agencyId: number): any => ({
  type: "DISCARD_INDICATORS",
  agencyId,
});

export const postUnsavedIndicators = (id: number): any => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const agency = getState().manualUpdatesReducer[id];
    const values: any[] = [];
    Object.values(agency.unsaved).forEach((indicator: Indicator) => {
      const id = indicator.indicatorId;
      // const timestamp = indicator.newValueTimestamp;
      // if (typeof indicator.newValueTimestamp !== "undefined") {
      //   const secondsNow = new Date().getSeconds();
      //   timestamp!.setSeconds(secondsNow);
      // }
      values.push({
        indicatorId: id,
        value: typeof indicator.newValue === "string" ? parseFloat(indicator.newValue) : indicator.newValue,
        ...(indicator.comment && { comment: indicator.comment }),
        ...(indicator.newValueTimestamp && { valueTimestamp: formatToISO(indicator.newValueTimestamp) }),
      });
    });
    dispatch(
      ManualUpdatesService.updateIndicatorValueBatch(
        {
          id,
          values,
        },
        {
          label: "SAVE_UNSAVED_INDICATORS",
          onSuccess: (response: any) => saveUnsavedIndicators(id, response),
          other: { agencyId: id, indicatorIds: values.map((a) => a.indicatorId) },
          // onFailure: () => console.log("Error occured loading articles"),
        }
      )
    );
  };
};

export const fetchIndicators = (id: number, page = 1, isFavorite?: boolean, searchParam?: string): ApiAction => {
  cancelRequest("GET_INDICATORS");
  return ManualUpdatesService.getIndicatorList(
    {
      id,
      page,
      isFavorite,
      searchParam,
    },
    {
      label: "GET_INDICATORS",
      onSuccess: (response: any) => getIndicators(id, page, response),
      // onFailure: () => console.log("Error occured loading articles"),
      other: { agencyId: id },
    }
  );
};

export const updateToggleFavourite = (id: number, indicator: Indicator) => {
  const addOrRemove: "remove" | "add" = indicator.isFavourite ? "remove" : "add";
  return (dispatch: any, getState: () => ApplicationState) => {
    const state = getState().manualUpdatesReducer[id];
    const indicatorIndex = state.indicators.findIndex((ind) => ind.indicatorId === indicator.indicatorId);
    const length = state.indicators.length;
    const indicatorIndexReversed = length - 1 - indicatorIndex;
    // const prevFavouriteIndexReversed = [...state.indicators]
    //   .reverse()
    //   .findIndex((ind, i) => i > indicatorIndexReversed && ind.isFavourite);
    // const prevFavouriteIndex = prevFavouriteIndexReversed === -1 ? -1 : length - 1 - prevFavouriteIndexReversed;
    const prevFavourite = [...state.indicators]
      .reverse()
      .find((ind, i) => i > indicatorIndexReversed && typeof ind.orderId === "number") || {
      orderId: state.firstOrderId - 1,
    };
    const newOrderId = prevFavourite.orderId! + 1;
    dispatch(
      ManualUpdatesService.setFavouriteIndicator(
        {
          organisationId: id,
          indicatorId: indicator.indicatorId,
          type: addOrRemove,
          orderId: addOrRemove === "add" ? newOrderId : undefined,
        },
        {
          label: "TOGGLE_FAVOURITE",
          onSuccess: (response) => toggleFavourite(id, indicator, response.indicators),
          other: { agencyId: id, indicator: indicator, orderId: newOrderId },
        }
      )
    );
  };
};

export const updateMoveFavourite = (
  id: number,
  indicator: Indicator,
  overId: number | "prev" | "next",
  afterSuccess?: () => void
) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const { meta, indicators } = getState().manualUpdatesReducer[id];
    const oldIndex = indicators.findIndex((ind) => ind.indicatorId === indicator.indicatorId);
    function getOrderIdAndNewIndex() {
      if (overId === "next") {
        return {
          orderId: meta.currentPage * meta.pageSize + 1,
          newIndex: -1,
          oldIndex,
        };
      }
      const baseIndex = (meta.currentPage - 1) * meta.pageSize;
      if (overId === "prev") {
        return {
          orderId: baseIndex,
          newIndex: -1,
          oldIndex,
        };
      }
      const newIndex = indicators.findIndex((ind) => ind.indicatorId === overId);
      const newOrderId = indicators[newIndex].orderId;
      return {
        orderId: newOrderId || baseIndex + newIndex + 1,
        newIndex,
        oldIndex,
      };
    }
    const { orderId, newIndex } = getOrderIdAndNewIndex();
    dispatch(
      ManualUpdatesService.setFavouriteIndicator(
        {
          organisationId: id,
          indicatorId: indicator.indicatorId,
          type: "add",
          orderId,
        },
        {
          label: "MOVE_FAVOURITE",
          onSuccess: (response) => {
            if (afterSuccess) {
              afterSuccess();
            }
            return moveFavourite(id, indicator, orderId, response.indicators);
          },
          other: { agencyId: id, indicator: indicator, overId, orderId, newIndex, oldIndex },
        }
      )
    );
  };
};
