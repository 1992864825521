import { MODULE_URLS } from "../../../globals/internal-urls";
import history from "../../../history";
import { LOGIN_URL, SETUP_MFA_URL, ENFORCE_MFA_URL } from "../../../pages/login/consts";

const REDIRECT_MAP: any = {
  regions: MODULE_URLS["NHS Region"],
};

export const redirectAfterLogin = (pathname: string, redirectModule: null | undefined | string) => {
  if (
    (pathname === "/" || [LOGIN_URL, SETUP_MFA_URL, ENFORCE_MFA_URL].some((substr) => pathname.startsWith(substr))) &&
    redirectModule
  ) {
    if (REDIRECT_MAP[redirectModule]) {
      history.push(REDIRECT_MAP[redirectModule]);
    }
  }
};

export const redirectTo = (to: string) => {
  history.push(to);
};
