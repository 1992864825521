import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from "react";
// import Meta from "../../components/meta";
import { useDispatch } from "react-redux";
// import {
//   fetchDocuments,
//   fetchDownloadDocument,
//   fetchDeleteDocuments,
//   resetDocumentUploadProgress,
// } from "../../store/actions/documents";

import {
  Modal,
  Icon,
  Button,
  Input,
  Tippy,
  // Pagination,
  InlineError,
  Dropdown,
  Checkbox,
  Loader,
  ProgressBar,
} from "@tscore/react-components";
import Pagination from "../../components/structure/pagination";
import { DocumentsEmpty } from "./empty";
import classNames from "classnames";
import Moment from "react-moment";
import { momentTimeDateFormat } from "../../lang/DateTimeFormats";
import { ModalUpload } from "./modal-upload";
import { ModalEdit } from "./modal-edit";
import { decodeParams, searchUpdateQuery } from "../../helpers/params";
import { navigateToPage } from "../../helpers/change-page";
// import { forceRefresh } from "../../helpers/force-refresh";
import { hasClass } from "../../helpers/has-class";
import { slugify } from "../../helpers/slugify";
import { getFileIconSrc } from "../../globals/FileIcons";
// import { FILE_TYPES } from "../../globals/FileTypes";
import debounce from "lodash/debounce";
import { DEFAULT_DEBOUNCE_SEARCH_MS } from "../../globals/settings";
import { DEFAULT_DOCUMENT_SORT } from "./consts";
import { useWindowWidth } from "../../hooks/use-window-width";
import { useTranslation } from "react-i18next";
//? TYPES:
import { Document } from "../../types/document";
import { ApplicationState } from "../../store/reducers";
import { RouteComponentProps } from "react-router-dom";
// ? TYPES:
import { ModalRefMethods } from "../../types/modal";

interface Params {
  id: string;
  pretty?: string;
}

// type DocumentsPageProps = RouteComponentProps<Params>;

interface DocumentsPageProps extends RouteComponentProps<Params> {
  id: number;
  fetchDocuments: Function;
  fetchDownloadDocument: Function;
  fetchDeleteDocuments: Function;
  resetDocumentUploadProgress: Function;
  fetchUploadDocument: Function;
  updateDocumentUploadProgress: Function;
  fetchUpdateDocumentMeta: Function;
  state: ApplicationState["documentsReducer"][number];
  rba: {
    canDeleteDocument: boolean;
    canUploadDocument: boolean;
  };
}

interface ExpandList {
  [key: string]: boolean;
}

function slowEach(array: any[], interval: number, callback: Function) {
  if (!array.length) return;
  let i = 0;
  // eslint-disable-next-line
  next();
  function next() {
    if (callback(array[i], i) !== false) {
      if (++i < array.length) {
        if (i === 0) {
          setTimeout(next, 0);
        } else {
          setTimeout(next, interval);
        }
      }
    }
  }
}

const generateExpandButtonList = (refs: React.MutableRefObject<{ [key: string]: any }>): ExpandList => {
  const list: ExpandList = {};
  for (const key in refs.current) {
    if (refs.current[key]) {
      const { scrollWidth, clientWidth } = refs.current[key];
      if (scrollWidth > clientWidth || hasClass(refs.current[key].parentNode, "is-expanded")) {
        list[key] = true;
      }
    }
  }
  return list;
};

export const DocumentsInner: React.FC<DocumentsPageProps> = ({
  location,
  history,
  // match,
  fetchDocuments,
  fetchDownloadDocument,
  fetchDeleteDocuments,
  resetDocumentUploadProgress,
  fetchUploadDocument,
  updateDocumentUploadProgress,
  fetchUpdateDocumentMeta,
  state,
  id,
  rba,
}) => {
  const { t } = useTranslation();
  const editModalRef = useRef<ModalRefMethods>(undefined!);
  const [selected, setSelected] = useState<string[]>([]);
  const [expandedDocumentId, setExpandedDocumentId] = useState<string | null>(null);
  const [expandButtonShownList, setExpandButtonShownList] = useState<ExpandList>({});
  const expandRefs = useRef<{ [key: string]: any }>({});
  const windowWidth = useWindowWidth(800);
  // const id = parseInt(match.params.id, 10);
  const searchParams = decodeParams(location.search);
  const page = parseInt(searchParams.page, 10) || 1;
  const query = searchParams.query;
  const dispatch = useDispatch();
  const changePageCallback = useCallback(
    (p: number) => {
      // console.log("navigating", p);
      navigateToPage(history, location.search, p);
    },
    [history, location.search]
  );
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchDocuments(id, page, query, DEFAULT_DOCUMENT_SORT, changePageCallback));
    }
    fetchInitial();
  }, [dispatch, changePageCallback, id, page, query, fetchDocuments]);
  // const state = useSelector((state: ApplicationState) => state.documentsReducer[id] || state.documentsReducer[-1]);
  const changePage = (page: number) => navigateToPage(history, location.search, page);
  const pageChanging = (_page: number): boolean => {
    return true;
  };
  const { isFetching, documents, uploadProgress, uploadErrors, isEditing, isDeleting, meta, downloadProgress } = state;
  const isIndeterminate = selected.length > 0 && selected.length !== documents.length;
  useEffect(() => {
    setSelected((prevState) => {
      if (prevState.length > 0) {
        return prevState.filter((documentId) => documents.find((document) => document.documentId === documentId));
      }
      return prevState;
    });
  }, [documents, setSelected]);
  // useEffect(() => {
  //   if (
  //     documents.length === 0 &&
  //     page === 1 &&
  //     !isFetching &&
  //     meta.totalItems > 0 &&
  //     Object.keys(isDeleting).length === 0
  //   ) {
  //     forceRefresh(history); // location.key is key :)
  //   }
  // }, [page, documents.length, isDeleting, isFetching, meta.totalItems, history]);
  const downloadDocuments = (documentIdList: string[]) => {
    const docs = documentIdList.map((id) => documents.find((document) => document.documentId === id));
    // .filter((document) => document !== undefined);
    slowEach(docs, 1600, function (doc: Document, _index: number) {
      if (downloadProgress[doc.documentId] > 0 && downloadProgress[doc.documentId] < 100) {
        // eslint-disable-next-line
        console.log("already downloading this file");
      } else {
        dispatch(
          fetchDownloadDocument(
            id,
            doc.documentId,
            slugify(doc.documentName),
            doc.fileExtension,
            doc.versionList[0].fileSize
          )
        );
      }
    });
  };
  const deleteDocuments = (documentIdList: string[]) => {
    if (Object.keys(isDeleting).length !== 0) {
      return;
    }
    dispatch(fetchDeleteDocuments(id, documentIdList, changePageCallback));
  };
  const onDocumentSelect = (e: any) => {
    const { value } = e;
    if (selected.includes(value)) {
      setSelected(selected.filter((f) => f !== value));
      //
    } else {
      setSelected([...selected, value]);
    }
    return true;
  };
  const onAllDocumentSelect = (e: any) => {
    if (isIndeterminate || e.checked) {
      setSelected(documents.map((document) => document.documentId));
    } else {
      setSelected([]);
    }
    return true;
  };

  const searchIndicator = (value: string | undefined) => searchUpdateQuery(value, history, location);
  const debouncedSearch = debounce(searchIndicator, DEFAULT_DEBOUNCE_SEARCH_MS);

  const toggleDescription = (documentId: string, preventToggle?: boolean) => {
    if (preventToggle) {
      return false;
    }
    if (expandedDocumentId === documentId) {
      setExpandedDocumentId(null);
    } else {
      setExpandedDocumentId(documentId);
    }
  };
  useLayoutEffect(() => {
    setExpandedDocumentId(null);
    setExpandButtonShownList(generateExpandButtonList(expandRefs));
  }, [windowWidth, documents]);
  const setDescriptionRef = (element: any, key: string) => {
    expandRefs.current[key] = element;
  };
  const HEADINGS: { value: string; class?: string }[] = [
    { value: t("documents:mainTable.0"), class: "w1 h1" },
    { value: t("documents:mainTable.1"), class: "name-holder" },
    { value: t("documents:mainTable.2") },
    { value: t("documents:mainTable.3"), class: "w1" },
    { value: t("documents:mainTable.4"), class: "nowrap w1" },
    { value: t("documents:mainTable.5"), class: "nowrap w1" },
    { value: t("documents:mainTable.6"), class: "w1" },
  ];
  return (
    <main>
      <header className="content-header">
        <div style={{ width: "100%" }}>
          {selected.length > 0 && (
            <>
              <Tippy content={t("documents:tooltipDownloadSelected")} placement="bottom">
                <div data-tooltipped>
                  <Button
                    onClick={(_e: any) => downloadDocuments(selected)}
                    icon="cloud_download"
                    length="short"
                    colour="lightgray-outline"
                  />
                </div>
              </Tippy>
              {rba.canDeleteDocument && (
                <Tippy content={t("documents:tooltipDeleteSelected")} placement="bottom">
                  <div data-tooltipped>
                    <Button
                      onClick={(_e: any) => deleteDocuments(selected)}
                      icon="delete"
                      length="short"
                      colour="lightgray-outline"
                    />
                  </div>
                </Tippy>
              )}
            </>
          )}
          <Input
            // ref={searchInputRef}
            icon="search"
            placeholder={t("generic:Search")}
            id="searchInput"
            autoComplete="document_search"
            onChange={(e: any) => debouncedSearch(e.target.value)}
            // onFocus={() => increaseSearchInput(SEARCH_WIDTH.MAX)}
            // onBlur={() => searchAreaLeft && decreaseSearchInput(SEARCH_WIDTH.MIN)}
            holderStyle={{ maxWidth: "240px" }}
            defaultValue={query || ""}
            isLoading={isFetching && query}
          />
          {rba.canUploadDocument && (
            <Modal
              style={{ maxWidth: "490px", overflow: "visible" }}
              trigger={
                <Button icon="cloud_upload" length="shorter" colour="green">
                  {t("documents:Upload Document")}
                </Button>
              }
              onModalClose={() => {
                if (page !== 1 && Object.keys(uploadProgress).length > 0) {
                  navigateToPage(history, location.search, 1);
                }
                dispatch(resetDocumentUploadProgress(id));
              }}>
              <ModalUpload
                uploadProgress={uploadProgress}
                uploadErrors={uploadErrors}
                id={id}
                fetchUploadDocument={fetchUploadDocument}
                updateDocumentUploadProgress={updateDocumentUploadProgress}
              />
            </Modal>
          )}
        </div>
      </header>
      <div id="documents">
        {!isFetching && documents.length === 0 ? (
          <InlineError
            style={{ height: "30vh" }}
            className="middle"
            title={t("documents:errorNoDocuments")}
            description={t("documents:errorNoDocumentsDescription")}
            icon="grid_off"
          />
        ) : (
          <>
            <div
              className="table bordered no-vertical-lines main shadow-hover-effect shadowed"
              style={{ position: "relative" }}>
              <header className="thead">
                <ul className="row">
                  {HEADINGS.map((cell: any, index) => (
                    <li key={index} className={classNames("th", { [cell.class]: !!cell.class })}>
                      {index === 0 ? (
                        <Checkbox
                          name="allSelected"
                          value="isAllSelected"
                          onCheckboxToggle={onAllDocumentSelect}
                          checked={selected.length > 0}
                          indeterminate={isIndeterminate}
                          disabled={isFetching}
                        />
                      ) : (
                        cell.value
                      )}
                    </li>
                  ))}
                </ul>
              </header>
              <div className="tbody">
                {isFetching ? (
                  <DocumentsEmpty length={documents.length} />
                ) : (
                  documents.map((document) => {
                    // const fileType = (FILE_TYPES as any)["." + document.fileExtension];
                    // const imgSrc = fileType ? (FILE_ICONS as any)[fileType] : FILE_ICONS["default"];
                    const imgSrc = getFileIconSrc(document.fileExtension);
                    const version = document.versionList.length;
                    const uploadedOn = document.versionList[version - 1].createdAt || document.updatedAt;
                    const downloadPercentage = downloadProgress[document.documentId] || 0;
                    return (
                      <ul
                        className={classNames("row", {
                          "is-expanded": expandedDocumentId === document.documentId,
                          "is-expand-visible": expandButtonShownList[document.documentId] === true,
                        })}
                        key={document.documentId}>
                        <li className="cell checkbox-holder">
                          <Checkbox
                            name="selected[]"
                            value={document.documentId}
                            checked={selected.includes(document.documentId)}
                            onCheckboxToggle={onDocumentSelect}
                          />
                        </li>
                        <li
                          className="cell name-holder"
                          style={{ position: "relative" }}
                          onClick={() => downloadDocuments([document.documentId])}>
                          <div className="file-type">
                            <img src={imgSrc} alt={document.fileExtension} />
                          </div>
                          <div className="name">{document.documentName}</div>{" "}
                          {downloadPercentage > 0 && (
                            <Tippy
                              content={t("documents:tooltipPercentageDownloaded", { downloadPercentage })}
                              placement="bottom">
                              <div className="download-progress">
                                <ProgressBar
                                  scale="small"
                                  percentage={downloadPercentage}
                                  colour="#41a0f2"
                                  greenOnFinished
                                />
                              </div>
                            </Tippy>
                          )}
                        </li>
                        <li
                          className="cell description-holder"
                          ref={(element) => setDescriptionRef(element, document.documentId)}>
                          <div className="description">{document.description}</div>
                        </li>
                        <li
                          className="cell expand-holder"
                          onClick={() =>
                            toggleDescription(document.documentId, !expandButtonShownList[document.documentId])
                          }>
                          <Tippy
                            disabled={!expandButtonShownList[document.documentId]}
                            content={
                              expandedDocumentId === document.documentId
                                ? t("documents:Collapse Description")
                                : t("documents:Expand Description")
                            }
                            placement="bottom">
                            <span>
                              <Icon>expand_more</Icon>
                            </span>
                          </Tippy>
                        </li>
                        <li className="cell uploadedBy-holder">{document.updatedBy.fullname}</li>
                        <li className="cell uploadedOn-holder">
                          <Tippy
                            content={
                              <div style={{ fontSize: "1.1rem" }}>
                                <span>{t("documents:tooltipRelativeUploaded")} </span>
                                <Moment fromNow>{uploadedOn}</Moment>
                              </div>
                            }
                            placement="bottom"
                            delay={[800, 0]}>
                            <span>
                              <Moment format={momentTimeDateFormat}>{uploadedOn}</Moment>
                              {/* <Icon
                                className="info-icon"
                                style={{ fontSize: "1.1rem", marginTop: "-0.1rem", marginLeft: "0.4rem" }}>
                                info
                              </Icon> */}
                            </span>
                          </Tippy>
                          {/* {uploadedOn} */}
                        </li>
                        <li className="cell more-holder">
                          {isDeleting[document.documentId] ? (
                            <Loader />
                          ) : (
                            <Dropdown trigger={<Icon className="faded pointer">more_horiz</Icon>} position="right">
                              <Dropdown.Item
                                href={`#!download: ${document.documentName}`}
                                onClick={(_e) => downloadDocuments([document.documentId])}
                                label={t("documents:Download")}
                                icon="cloud_download"
                              />
                              <Modal
                                style={{ maxWidth: "560px" }}
                                ref={editModalRef}
                                trigger={
                                  <Dropdown.Item
                                    href={`#!edit: ${document.documentName}`}
                                    label={t("documents:Edit Details")}
                                    icon="edit"
                                  />
                                }>
                                <ModalEdit
                                  fetchUpdateDocumentMeta={(id: number, payload: any) => {
                                    dispatch(fetchUpdateDocumentMeta(id, payload, editModalRef.current.hide()));
                                  }}
                                  isEditing={isEditing[document.documentId]}
                                  id={id}
                                  document={document}
                                />
                              </Modal>
                              {rba.canDeleteDocument ? (
                                <Dropdown.Item
                                  href={`#!delete: ${document.documentName}`}
                                  onClick={(_e) => deleteDocuments([document.documentId])}
                                  label={t("generic:Delete")}
                                  icon="delete"
                                />
                              ) : (
                                <Dropdown.Item label="d" style={{ display: "none" }} />
                              )}
                            </Dropdown>
                          )}
                        </li>
                      </ul>
                    );
                  })
                )}
              </div>
            </div>
            <Pagination
              label={t("documents:mainPaginationLabel")}
              settings={meta}
              onChangePage={(page: number) => changePage(page)}
              onChangingPage={(page: number) => pageChanging(page)}
            />
          </>
        )}
      </div>
    </main>
  );
};
