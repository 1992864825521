import { TABLEAU_GLOBAL_REPORT_ID } from "../../../../globals/tableau-settings";
import { splitAtCapital } from "../../../../components/tableau/consts";
// ? TYPES:
import { ImportedAccessInfo, ImportedOrganisation } from "../../../types/menu-state";

type Response = Utils.PartialBy<ImportedAccessInfo, "fake-tableau-service" | "fake-report-service">;

export const transformGetMenu = (response: Response): ImportedAccessInfo => {
  const tableauGlobalList = getFakeTableauGlobalList();
  const baseDataNhsr = response["nhsr-service"] || {};
  const nhsrService = {
    ...baseDataNhsr,
    tableauGlobalList,
  };
  return {
    ...response,
    "fake-tableau-service": {
      tableauGlobalList,
    },
    "nhsr-service": nhsrService,
    "fake-report-service": {
      otherReportOrganisationList: nhsrService.otherReportOrganisationList,
      winterReportOrganisationList: nhsrService.winterReportOrganisationList,
    },
  };
};

function getFakeTableauGlobalList(): ImportedOrganisation[] {
  return Object.keys(TABLEAU_GLOBAL_REPORT_ID).map((key) => {
    return {
      name: splitAtCapital(key),
      id: key,
      description: "",
    };
  });
}
