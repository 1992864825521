// ? TYPES:
import { OpacityList, PolygonCosmeticOptions } from "../../types/google-map";

export const OPTIMIZATION_SETTINGS = {
  turnOffHoverOutOtherPolygons: true,
};

export const HOVER_OUT_DELAY = 30;
// export const HOVER_OVERHEAD = 160;

export const CHILD_SHADE_FOR_COUNTY = 0.5;

export const FACILITY_FONT_SIZE = 9;
export const FACILITY_RADIUS = 4;
export const FACILITY_ICON_SIZE: {
  STROKE_WIDTH: number;
  WIDTH: number;
  BOX_OFFSET: number;
  BOX_HEIGHT: number;
} = {
  STROKE_WIDTH: 1,
  WIDTH: 22,
  BOX_OFFSET: 6,
  BOX_HEIGHT: 18,
};

export const CHILD_POLYGON_OPACITY: OpacityList = {
  ON_HOVER: 0.9,
  DEFAULT: 0.7,
  OTHER_HOVERED: 0.5,
};

export const PARENT_POLYGON_OPACITY: OpacityList = {
  ON_HOVER: 0,
  DEFAULT: 0,
  OTHER_HOVERED: 0.5,
};

export const defaultChildPolygonOptions: PolygonCosmeticOptions = {
  // fillColor: "blue",
  // fillOpacity: 0.7,
  strokeColor: "#FFFFFF",
  strokeOpacity: 0.8,
  strokeWeight: 2,
};

export const defaultParentPolygonOptions: PolygonCosmeticOptions = {
  // fillColor: "blue",
  // fillOpacity: 0.7,
  strokeColor: "#27303B",
  strokeOpacity: 0.8,
  strokeWeight: 2,
};

// export const defaultTacticalPolygonOptions = {
//   fillColor: "transparent",
//   fillOpacity: 1,
//   strokeColor: "#4C91E3",
//   strokeOpacity: 1,
//   strokeWeight: 1
// };
