import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import { useFilterChange } from "./hooks/use-filter-change";
import { useSearchParams } from "../../hooks/use-search-params";
import { Tippy, Button } from "@tscore/react-components";
// ? TYPES:
import { TFunction } from "i18next";

interface ClearFiltersProps {
  t: TFunction;
  filters: string[];
  hasDividerAfter?: boolean;
  hideOnDisabled?: boolean;
}

const ClearFiltersWoT: React.FC<ClearFiltersProps> = ({
  t,
  filters,
  hasDividerAfter = true,
  hideOnDisabled = "true",
}) => {
  const { clearFilters } = useFilterChange();
  const searchParams = useSearchParams();
  const areAnyFiltersEnabled = useMemo(() => filters.some((filter) => searchParams[filter]), [filters, searchParams]);
  if (hideOnDisabled && !areAnyFiltersEnabled) {
    return null;
  }
  return (
    <>
      <Tippy content={t("Clear Filters")} placement="bottom">
        <div data-tooltipped>
          <Button
            disabled={!areAnyFiltersEnabled}
            onClick={clearFilters(filters)}
            colour="data-toolbar__trigger__clear"
            icon="clear"></Button>
        </div>
      </Tippy>
      {hasDividerAfter && <span className="data-toolbar__divider" />}
    </>
  );
};

export const ClearFilters = withTranslation(["generic"])(ClearFiltersWoT);
