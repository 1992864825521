import React from "react";
// import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { Wheel } from "../../components/wheel";
import { Icon, Tippy } from "@tscore/react-components";
import { useDispatch } from "react-redux";
import { fetchDialInfo } from "../../store/actions/system-dashboard";
import { useTranslation } from "react-i18next";
//? TYPES:
import { Dial } from "../../store/types/system-dashboard-state";
import { TModuleUrlKey } from "../../globals/internal-urls";

interface MiniWheelProps {
  isActive: boolean;
  dial: Dial;
  locationPathname: string;
  systemDashboardId: number; // for fetch
  module: TModuleUrlKey;
}

const SMALL_WHEEL_SETTINGS = {
  isResponsive: false,
  isStatic: true,
  showEscalation: false,
  size: 150,
  hideText: true,
};

export const MiniWheel: React.FC<MiniWheelProps> = ({
  module,
  dial,
  isActive,
  locationPathname,
  systemDashboardId,
}) => {
  const { t } = useTranslation();
  const isFetching = !dial.isFetched;
  const location = {
    pathname: locationPathname,
  };
  const dispatch = useDispatch();
  const SITE_ID_PARAM_PLACE = module === "Resilience" ? 3 : 4;
  // const loading = false;
  if (isFetching) {
    return (
      <>
        <div className="isFetching">
          <div style={{ display: "inline-block" }}>
            <div className="fake-svg-holder">
              <div className="loading-element fake-svg"></div>
            </div>
          </div>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="150"
            height="150"
            style={{ opacity: 0 }}
          /> */}
        </div>
        <div className="mini-title">
          <h5>{dial.siteName}</h5>
        </div>
      </>
    );
  }
  if (dial.unitList && dial.unitList.length > 0) {
    return (
      <>
        <div>
          <NavLink
            to={{
              pathname: Object.assign([], location.pathname.split("/"), {
                [SITE_ID_PARAM_PLACE]: dial.siteId + "",
              }).join("/"),
            }}
            onClick={(e) => isActive && e.preventDefault()}
            style={{ display: "inline-block" }}>
            <Wheel unitList={dial.unitList} wheelId={dial.siteId} callback={null} {...SMALL_WHEEL_SETTINGS} />
          </NavLink>
        </div>
        <div className="mini-title">
          <h5>{dial.siteName}</h5>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="hasErrored" onClick={() => dispatch(fetchDialInfo(systemDashboardId, dial.siteId))}>
        <div style={{ display: "inline-block" }}>
          <Tippy placement="bottom" content={t("dashboards:errorTooltipDial")}>
            <div className="fake-svg-holder">
              <div
                className="fake-svg"
                style={{
                  backgroundColor: "#ffb9b9",
                }}>
                <Icon className="svg-error-icon">refresh</Icon>
              </div>
            </div>
          </Tippy>
        </div>
      </div>
      <div className="mini-title">
        <h5>{dial.siteName}</h5>
      </div>
    </>
  );
};
