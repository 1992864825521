import { apiEnd } from "../actions/api";
import { API_SUCCESS } from "../actions/api.names";
import { ApplicationState } from "../reducers";

// const SUCCESS_LABELS: {
//   [label: string]: Exclude<keyof ApplicationState["mapsReducer"], "didInvalidate" | "isFetching" | "errors">;
// } = {
const SUCCESS_LABELS: {
  [label: string]: "vantage" | "elective";
} = {
  GET_ELECTIVE_INITIAL: "elective",
  GET_ELECTIVE_CHILD_BOUNDARY: "elective",
  UPDATE_ELECTIVE_FILTERS: "elective",
  GET_VANTAGE_INITIAL: "vantage",
  GET_VANTAGE_CHILD_BOUNDARY: "vantage",
  UPDATE_VANTAGE_FILTERS: "vantage",
};

const mapsMiddleware =
  ({ getState, dispatch }: { dispatch: any; getState: () => ApplicationState }) =>
  (next: any) =>
  (action: { type: string; [key: string]: any } = { type: "" }): any => {
    if (action.type === API_SUCCESS && SUCCESS_LABELS[action.payload.label]) {
      const mapsKey = SUCCESS_LABELS[action.payload.label];
      const mapsState = getState().mapsReducer[mapsKey][action.id];
      const markersOrHospitalsKey = typeof (mapsState as any).markers !== "undefined" ? "markers" : "hospitals";
      const markers: ApplicationState["mapsReducer"]["elective"][0]["markers"] = (mapsState as any)[
        markersOrHospitalsKey
      ];
      if (["GET_ELECTIVE_INITIAL", "GET_VANTAGE_INITIAL"].includes(action.payload.label)) {
        // ? if 0 ccgs, pretend the boundary API is loaded
        if (mapsState.ccgs.length === 0) {
          const X: { [key: string]: string } = {
            GET_VANTAGE_INITIAL: "GET_VANTAGE_CHILD_BOUNDARY",
            GET_ELECTIVE_INITIAL: "GET_ELECTIVE_CHILD_BOUNDARY",
          };
          dispatch(apiEnd(X[action.payload.label], { id: action.id }));
        }
      }
      if (mapsState.boundary.child.length > 0 && markers.length > 0) {
        dispatch({
          type: "UPDATE_MARKER_DATA_MIDDLEWARE",
          mapsKey,
          id: action.id,
          markersOrHospitalsKey,
          data: { child: mapsState.boundary.child, markers },
        });
      }
    }
    return next(action);
  };

export default mapsMiddleware;
