import { apiAction } from "../api";
import { SERVICE_URLS } from "../../../globals/service-urls";
import { generateParams } from "../../../helpers/generate-params";
import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";

// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";
import { PaginatedParams } from "../../../types/paginated-params";

const getIndicatorList = (
  {
    id,
    page = 1,
    limit = DEFAULT_PAGE_LIMIT,
    disablePagination,
    isFavorite = undefined,
    searchParam,
  }: PaginatedParams & { isFavorite?: boolean; searchParam?: string; id: number },
  callbacks: ApiCallback
): ApiAction => {
  const ver = 2;
  const params = { page, limit, disablePagination, isFavorite, searchParam };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["resilience-service"]}${ver}/manual-update/${id}${generateParams(params)}`,
    ...callbacks,
  });
};

const updateIndicatorValueBatch = (
  {
    id,
    values,
  }: {
    id: number;
    values: {
      indicatorId: number;
      value: number;
      comment: string;
      valueTimestamp: string;
    }[];
  },
  callbacks: ApiCallback
): ApiAction => {
  const ver = 2;
  return apiAction({
    method: "POST",
    data: { values: values },
    url: `${SERVICE_URLS["resilience-service"]}${ver}/manual-update/${id}/batch`,
    ...callbacks,
  });
};

const setFavouriteIndicator = (
  {
    indicatorId,
    type,
    orderId,
    organisationId,
  }: { indicatorId: number; type: "remove" | "add"; organisationId: number; orderId?: number },
  callbacks: ApiCallback<{ organisationId: number; indicators: { indicatorId: number; orderId: number }[] }>
): ApiAction => {
  const ver = 2;
  return apiAction({
    method: "PATCH",
    data: {
      type,
      indicatorId,
      orderId,
    },
    url: `${SERVICE_URLS["resilience-service"]}${ver}/manual-update/${organisationId}/favourite-indicator`,
    ...callbacks,
  });
};

// ? === SINGLE INDICATOR UPDATE ===

const ManualUpdatesService = {
  getIndicatorList,
  updateIndicatorValueBatch,
  setFavouriteIndicator,
};

export default ManualUpdatesService;
