import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
// import { accessEnv } from "../../../../access-env";
import { useDispatch, useSelector } from "react-redux";
// import { Map } from "google-maps-react";
import { Map } from "../../../../components/map/improved/index";
import GoogleApiWrapper from "../../../../components/map/improved/GoogleApiComponent";
import { LoadingContainer } from "../../../../components/map/shared/loading-container";
import facilitiesList from "../../../../components/map/shared/facilities";
import shadowsList from "../../../../components/map/shared/shadowMarkers";
import { boundaries } from "../../../../components/map/shared/boundaries";
import MapTooltip from "../../../../components/map/shared/map-tooltip";
import { HoverPopover } from "../../../../components/map/shared/hover-popover";

import { DEFAULT_MAP_STYLES, initialCenter } from "../../../../components/map/globals";
import { setVantageBounds, fetchVantageInitial, vantageCleanup } from "../../../../store/actions/maps";
import { INTERNAL_URLS } from "../../../../globals/internal-urls";
import { DEFAULT_ZOOM } from "./consts";
import { Loader } from "@tscore/react-components";
import { Filters } from "./filters";
// import { formatToString, momentTimeDateFormat } from "../../../../lang/DateTimeFormats";
// ? TYPES:
import { TFunction } from "i18next";
import { ApplicationState } from "../../../../store/reducers";
// import { MapsHospital } from "../../../../store/types/maps-state";
import { VantageMarker } from "../../../../store/types/maps-vantage";
import { ExpandedMarker } from "../../../../types/google-map";
// import { ApiError } from "../../store/types/api";

type HomeMapProps = { google: any; history: any; t: TFunction; id: number };

const HomeMap: React.FC<HomeMapProps> = ({ google, history, t, id }) => {
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [isSetupLoading, setIsSetupLoading] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [currentFacilityHover, setCurrentFacilityHover] = useState<ExpandedMarker | null>(null);
  // const [zoom, setZoom] = useState(DEFAULT_ZOOM - 1);
  const dispatch = useDispatch();
  const timer = useRef<ReturnType<typeof setTimeout>>(0 as any);
  const [strokeDashoffset, setStrokeDashoffset] = useState(0);
  const startTimer = () => {
    timer.current = setInterval(() => {
      setStrokeDashoffset((p) => p + 1);
    }, 100);
  };
  const stopTimer = () => {
    clearInterval(timer.current);
  };
  useEffect(() => {
    return () => {
      clearInterval(timer.current);
      dispatch(vantageCleanup(id));
    };
  }, []);
  useEffect(() => {
    function fetchInitial() {
      dispatch(fetchVantageInitial(id));
    }
    fetchInitial();
  }, [dispatch, id]);
  useEffect(() => {
    setIsSetupLoading(true); // recenters map
  }, [setIsSetupLoading, id]);
  const isFetching = useSelector((state: ApplicationState) => state.mapsReducer.isFetching);
  const vantageById = useSelector(
    (state: ApplicationState) => state.mapsReducer.vantage[id] || state.mapsReducer.vantage[-1]
  );
  useLayoutEffect(() => {
    if (
      isSetupLoading === true &&
      !isFetching["GET_VANTAGE_INITIAL"] &&
      !isFetching["GET_VANTAGE_CHILD_BOUNDARY"] &&
      !!google
    ) {
      dispatch(setVantageBounds(google, id));
      setIsSetupLoading(false);
    }
  }, [dispatch, isSetupLoading, setIsSetupLoading, isFetching, google, id]);
  const isLoading = isMapLoading || isFetching["GET_VANTAGE_CHILD_BOUNDARY"] || isFetching["GET_VANTAGE_INITIAL"];
  // useLayoutEffect(() => {
  //   if (vantageById.bounds === undefined) {
  //     return;
  //   }
  //   if (zoom !== vantageById.mapSetting.zoom) {
  //     console.log("update zoom ehre");
  //   }
  // }, [zoom, vantageById.mapSetting.zoom, vantageById.bounds]);
  // ? MARKERS ==========================================================
  const onHospitalClick = (marker: VantageMarker) => {
    if (!marker.dashboardId || !marker.dashboardViewAccess) {
      return false;
    }
    const siteId = marker.siteId ? marker.siteId : "primary";
    history.push({
      pathname: `${INTERNAL_URLS.vantageDashboardList}/${marker.dashboardId}/${siteId}/?origin=home`,
    });
  };
  return (
    // <>
    <div id="map" className="google-map shadowed" style={{ height: "100%" }}>
      <Map
        {...DEFAULT_MAP_STYLES}
        google={google}
        // onMouseout={this.onMapMouseOut}
        // onMousemove={this.onMapMouseMove}
        onDragstart={() => setIsDragging(true)}
        onDragend={() => setIsDragging(false)}
        onReady={() => setIsMapLoading(false)}
        // onZoomChanged={() => {
        //   const zoomCheck = vantageById.mapSetting.zoom || DEFAULT_ZOOM;
        //   if (zoom !== zoomCheck && vantageById.bounds !== undefined) {
        //     if (accessEnv("DEPLOYMENT_TYPE", "uk") === "uk") {
        //       setZoom(vantageById.mapSetting.zoom || DEFAULT_ZOOM);
        //     }
        //   }
        // }}
        // onIdle={() => setZoom(8)}
        initialCenter={initialCenter}
        {...vantageById.mapSetting}
        boundsWithZoom={vantageById.bounds}
        // bounds={vantageById.bounds}
        // zoom={zoom}
        zoom={DEFAULT_ZOOM}>
        {isLoading && <Loader placement="top left" />}
        {facilitiesList({
          facilities: vantageById.markers as any[],
          onMouseover: (facility: any, _props: any, _marker: ExpandedMarker, _MouseEvent: any) => {
            setCurrentFacilityHover(facility);
            startTimer();
          },
          onMouseout: () => {
            setCurrentFacilityHover(null);
            stopTimer();
          },
          currentFacilityHover: currentFacilityHover,
          onFacilityClick: onHospitalClick,
          hoverKeyCheckName: "idTrust",
          facilityUniqueId: "idMapMarker",
        })}
        {shadowsList({
          facilities: vantageById.markers as any[],
          currentFacilityHover: currentFacilityHover,
          hoverKeyCheckName: "idTrust",
          strokeDashoffset: strokeDashoffset,
        })}
        {boundaries({
          polygonCosmetics: {
            fillColor: "rgba(0,0,0,0.2)",
            strokeColor: "#000000",
            strokeWeight: 1,
          },
          opacityList: 1,
          children: vantageById.boundary.child,
          zIndex: 3,
          clickable: false,
        })}
        <HoverPopover
          currentFacilityHover={currentFacilityHover} // lastHover instead of current
          facilities={vantageById.markers as any[]} // todo
        />
        <MapTooltip visible={!!currentFacilityHover} isDragging={isDragging}>
          <div>
            {currentFacilityHover && (
              <div>
                {currentFacilityHover.properties.ccg19nm && (
                  <div>
                    <span style={{ textTransform: "capitalize" }}>{t("map:CCG")}: </span>
                    <strong>{currentFacilityHover.properties.ccg19nm}</strong>
                  </div>
                )}
                <div>
                  <span style={{ textTransform: "capitalize" }}>{t("map:Trust")}: </span>
                  <strong>{currentFacilityHover.trustName}</strong>
                </div>
                <div>
                  <span style={{ textTransform: "capitalize" }}>{currentFacilityHover.type}: </span>
                  <strong>{currentFacilityHover.name}</strong>
                </div>
              </div>
            )}
          </div>
        </MapTooltip>
        <Filters id={id} />
        {/* <ErrorsComponent errors={this.state.hasErrors} onClose={this.resetErrors} /> */}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_API_GOOGLE_MAP_KEY,
  LoadingContainer: LoadingContainer,
  libraries: [],
})(HomeMap);
