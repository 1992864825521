import React from "react";
import Meta from "../../components/meta";
import { MetaFooter } from "../../components/structure/meta-footer";
// import { Badges } from "../../components/structure/badges";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
// import { decodeParams } from "../../helpers/params";

// import { LOGO } from "../../globals/CompanyMeta";
//? TYPES:
import { ApplicationState } from "../../store/reducers";
import { RouteComponentProps } from "react-router-dom";

type ErrorPageProps = RouteComponentProps<{ username: string; key: string }>;

export const LoginUnauthorised: React.FC<ErrorPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const { username } = match.params;
  // const x = location.pathname;
  const loggedInUsername = useSelector((state: ApplicationState) => state.credentialsReducer.app_user_info.username);
  const moduleUrlPrefix = useSelector((state: ApplicationState) => state.menuReducer.meta.moduleUrlPrefix);
  const isTheSameUser = username === undefined || username === loggedInUsername;
  const errorType = isTheSameUser ? "VALID" : "INVALID";
  const link = isTheSameUser ? moduleUrlPrefix + "my-account/change-password" : moduleUrlPrefix;
  const error: {
    title: string;
    body: string[];
    action: string;
  } = t("login:resetUnauthorised_" + errorType, {
    returnObjects: true,
  });
  return (
    <main id="full-error">
      <Meta title={error.title} hideMeta hideFooter />
      <div className="full-error-container full-error-container--internal">
        <section>
          <div style={{ maxWidth: "764px" }}>
            <h1>{error.title}</h1>
            {error.body.map((message, i) => (
              <p key={i}>{message}</p>
            ))}
            {/* <pre className="error-debug"><code>...</code></pre> */}
            <NavLink className="btn link large short" to={link}>
              {error.action}
            </NavLink>
          </div>
          <div />
        </section>
        <footer>
          <MetaFooter />
          <div />
        </footer>
      </div>
      <style dangerouslySetInnerHTML={{ __html: "#container { min-width: 0; }" }} />
    </main>
  );
};
