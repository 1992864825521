import React from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Button } from "@tscore/react-components";
// import classNames from "classnames";
import { OTP } from "./otp";
import { ErrorField } from "./error-field";
// ? TYPES:
import { OTPProps } from "./otp";
import { TFunction } from "react-i18next";
import { SelectedFormikProps } from "../../../types/login-form";
// import { MFAType } from "../../../store/types/credentials-state";

interface Values {
  mfaCode: string;
  rememberDevice: boolean;
  [k: string]: any;
}

interface OTPPropsWithTranslation extends Omit<OTPProps, "value"> {
  t: TFunction;
  rememberDays: number;
  values: Values;
}

interface VerifyProps {
  t: TFunction;
  children: React.ReactNode;
  rememberDays: number;
  error?: string | false;
  autoFocus?: boolean;
  values: Values;
  setValue: SelectedFormikProps["setFieldValue"];
  isLoading?: boolean;
  // type?: MFAType;
}

const OTPWrapperWoT: React.FC<OTPPropsWithTranslation> = ({ autoFocus, error, setValue, values, rememberDays, t }) => {
  return (
    <div className="otp__wrapper">
      <OTP name="mfaCode" value={values.mfaCode} setValue={setValue} error={error} autoFocus={autoFocus} />
      <ErrorField error={error} />
      <div className="mt12">
        <Checkbox
          name="rememberDevice"
          checked={values.rememberDevice}
          onCheckboxToggle={({ checked, name }) => {
            setValue(name!, checked);
            return true;
          }}
          scale="large">
          {t("mfa.general.rememberDevice", { no: rememberDays })}
        </Checkbox>
      </div>
    </div>
  );
};

const OTPWrapper = withTranslation(["login"])(OTPWrapperWoT);

const VerifyWoT: React.FC<VerifyProps> = ({
  children,
  autoFocus,
  error,
  isLoading,
  values,
  rememberDays,
  setValue,
  t,
}) => {
  return (
    <>
      {children}
      <OTPWrapper values={values} setValue={setValue} autoFocus={autoFocus} error={error} rememberDays={rememberDays} />
      <div className="mt12">
        <Button isLoading={isLoading} className="bold" length="longer" type="submit">
          {t("mfa.general.Verify")}
        </Button>
      </div>
    </>
  );
};

export const Verify = withTranslation(["login"])(VerifyWoT);
