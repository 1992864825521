import React, { Fragment, forwardRef } from "react";
// import { classes } from "../../helpers/helpers";
import { Field } from "formik";

import {
  timeDateFormat,
  timeFormat,
  dateFormat,
  formatToDate,
  // formatToISO,
  formatToString,
  DATE_ICONS,
} from "../../lang/DateTimeFormats";
import DatePicker from "react-datepicker";

// ! TODO: Consume from "react-components"
const CustomDateTimePicker = forwardRef(({ onClick, value, onChange, type, _name, ...props }: any, ref: any) => (
  <div ref={ref} className={"icon-input left " + type} onClick={onClick}>
    <input
      type="text"
      autoComplete={false}
      {...props}
      name={_name}
      onChange={onChange}
      value={value}
      className="input"
    />
    <i className="icon material-icons">{DATE_ICONS[type]}</i>
  </div>
));

const getPickerType = (config: any): string => {
  if (config.date && config.time) {
    return "datetime";
  }
  if (config.date) {
    return "date";
  }
  return "time";
};

const formikPickerSettings: any = {
  date: {
    showTimeSelect: false,
    dateFormat: dateFormat,
  },
  datetime: {
    showTimeSelect: true,
    dateFormat: timeDateFormat,
    timeFormat: timeFormat,
  },
  time: {
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeFormat: timeFormat,
    dateFormat: timeFormat,
  },
};

// Formik:
export const SingleFormikDate = ({ format, _name, setFieldValue, type, props }: any): JSX.Element => {
  const handleChange = (date: Date): any => {
    setFieldValue(_name, formatToString(date, format));
  };
  return (
    <Field
      name={_name}
      render={({ field }: any) => {
        return (
          <DatePicker
            placeholderText={props.placeholder}
            selected={formatToDate(field.value, format)}
            {...formikPickerSettings[type]}
            onChange={handleChange}
            customInput={<CustomDateTimePicker type={type} {...field} _name={_name} />}
            {...props}
            // {...field}
          />
        );
      }}
    />
  );
};
export const FormikDateTime = ({ ...props }: any): JSX.Element => {
  const { setFieldValue } = props;
  const { format } = props.config;
  const names = props.config.range === true ? [props._name + ".from", props._name + ".to"] : [props._name];
  const type = getPickerType(props.config);
  // const format = "YYYY-DD-MM HH:mm";
  return (
    <Fragment>
      {names.map((item: any, index: number) => (
        <SingleFormikDate
          key={item}
          props={props}
          format={format}
          _name={names[index]}
          setFieldValue={setFieldValue}
          type={type}
        />
      ))}
    </Fragment>
  );
};
