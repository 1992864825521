import { SOURCE_LANGUAGE } from "../globals/settings";
import en from "./en";
import frCA from "./fr-CA";
// moment:
import { updateLocale } from "moment";
import "moment/locale/en-gb";
import "moment/locale/fr-ca";
import FRCA_MOMENTCUSTOM from "./fr-CA/moment-custom.json";
// date-fns (for react-datepicker):
import { registerLocale } from "@tscore/react-components";
import ENGB_DATEFNS from "date-fns/locale/en-GB";
import FRCA_DATEFNS from "date-fns/locale/fr-CA";

export const dateFnsLocale = {
  "en-GB": ENGB_DATEFNS,
  "fr-CA": FRCA_DATEFNS,
};

registerLocale("en-GB", dateFnsLocale["en-GB"]);
registerLocale("fr-CA", dateFnsLocale["fr-CA"]);

// moment updates:
updateLocale("fr-ca", {
  meridiem: function (hours, _minutes, isLowerCase) {
    if (hours < 12) {
      return isLowerCase ? FRCA_MOMENTCUSTOM.meridiem.am : FRCA_MOMENTCUSTOM.meridiem.AM;
    }
    return isLowerCase ? FRCA_MOMENTCUSTOM.meridiem.pm : FRCA_MOMENTCUSTOM.meridiem.PM;
  },
});

export default {
  [SOURCE_LANGUAGE]: en,
  "fr-CA": frCA,
};
