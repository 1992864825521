import React, { useEffect, useState, forwardRef } from "react";
import { Modal, Button, Checkbox } from "@tscore/react-components";
import { formatToISO } from "../../lang/DateTimeFormats";
import classNames from "classnames";
// import { PRIVACY_LINK } from "../../globals/CompanyMeta";

import Cookies from "js-cookie";
import { withTranslation, useTranslation, Trans } from "react-i18next";
// import cookieList from "../../globals/CookieList.json";
// ? TYPES:
import { ModalRefMethods } from "../../types/modal";
import { TFunction } from "i18next";

// interface State {
//   cookieSettings: CookieSettings | null;
//   cookiesToBeAccepted: CookieList;
// }

type ModalRef = ModalRefMethods;

interface CookieList {
  [field: string]: boolean;
}

interface CookieSettings {
  cookiesAcceptedOn?: string | null;
  cookiesAccepted?: CookieList;
}

interface CookieModalProps {
  acceptedList: CookieList;
  list: CookieList;
  onAccept: (list: CookieList) => void;
  onUpdate: (key: string) => void;
  t: TFunction;
}

// ? | The compliant cookie message displays on the website upon the user's first visit to the site, and then again,
// ? | if the user has consented to cookies, upon the user's first renewed visit once 12 months have elapsed
// ? | (the GDPR only requires the consent be "regularly renewed", the ePrivacy Directive suggests once a year).
const COOKIE_CONSENT_EXPIRY_DAYS = 365;

const CookieModalWoTranslation: React.FC<CookieModalProps> = ({ acceptedList, list, onAccept, t, onUpdate }) => (
  <div id="biscuitSettingsModal">
    <header>
      <h4>{t("modalTitle")}</h4>
      <h6>{t("modalSubTitle")}</h6>
    </header>
    <article>
      {Object.keys(list).map((key: string, _i: number) => {
        const cookie: any = list[key];
        return (
          <div key={key} className="biscuitItem">
            <div className="biscuitItemHeader">
              <Checkbox
                name={key}
                checked={cookie.isRequired || acceptedList[key]}
                disabled={cookie.isRequired}
                onClick={() => onUpdate(key)}>
                {cookie.name}
              </Checkbox>
            </div>
            <p>{cookie.description}</p>
          </div>
        );
      })}
      <div className="biscuitMeta">{t("biscuitMeta")}</div>
    </article>
    <footer>
      <div>
        <a
          href={t("meta:PRIVACY_LINK")}
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontSize: "1.2rem", textDecoration: "underline" }}>
          {t("More Information")}
        </a>
      </div>
      <div>
        <Button colour="primary" onClick={() => onAccept(acceptedList)} data-close-modal>
          {t("Save")}
        </Button>
      </div>
    </footer>
  </div>
);

const CookieModal = withTranslation(["cookies", "meta"])(CookieModalWoTranslation);

const getDefaultListToBeAccepted = (cookieList: any, onlyRequiredChecked = false): CookieList => {
  return Object.keys(cookieList).reduce((o: any, key: string) => {
    return {
      ...o,
      [key]: !onlyRequiredChecked || cookieList[key].isRequired ? true : false,
    };
  }, {});
};

export const CookieNotice = forwardRef<ModalRef, {}>((_props, cookieModalRef) => {
  const { t } = useTranslation();
  const cookieList: CookieList = t("cookies:list", { returnObjects: true, defaultValue: {} });
  // const cookieModalRef = useRef<any>();
  const [cookieSettings, setCookieSettings] = useState<CookieSettings>(Cookies.getJSON("cookieSettings") || {});
  const [cookiesToBeAccepted, setCookiesToBeAccepted] = useState<CookieList>(
    getDefaultListToBeAccepted(cookieList, true)
  );
  useEffect(() => {
    if (cookieSettings.cookiesAccepted) {
      setCookiesToBeAccepted(cookieSettings.cookiesAccepted);
    }
  }, [cookieSettings.cookiesAccepted]);

  // const onOpenCookieModal = (event?: React.MouseEvent<HTMLElement>): void => {
  //   // cookieModalRef.current!.show();
  //   console.log({ cookieModalRef });
  //   event && event.preventDefault();
  // };

  const onAccept = (list: CookieList = {}) => {
    const now: string = formatToISO(new Date());
    // Following is just an extra check in case html has been manipulated (if cookie is required, will set required as true)
    list = Object.keys(cookieList).reduce((o: any, key: string) => {
      const isAccepted: boolean = (cookieList as any)[key].isRequired === true ? true : list[key];
      return {
        ...o,
        [key]: isAccepted,
      };
    }, {});
    const settings: CookieSettings = { cookiesAcceptedOn: now, cookiesAccepted: list };
    setCookieSettings(settings);
    Cookies.set("cookieSettings", settings, { expires: COOKIE_CONSENT_EXPIRY_DAYS });
    // ? SEND ACCEPTED VIA API?
    // this.setState({ cookieSettings: settings }, () => {
    //   Cookies.set("cookieSettings", settings, { expires: COOKIE_CONSENT_EXPIRY_DAYS });
    // });
  };

  const onUpdate = (key: string) => {
    setCookiesToBeAccepted((prevState) => {
      const newList: CookieList = {
        ...prevState,
        [key]: !prevState[key],
      };
      return newList;
    });
    // this.setState({ cookiesToBeAccepted: newList });
  };

  const cookiesAccepted = cookieSettings.cookiesAccepted;
  const isHidden: boolean = cookiesAccepted
    ? Object.keys(cookieList).reduce((hiddenSoFar: boolean, key: string) => {
        if ((cookieList as any)[key].isRequired === true && cookiesAccepted[key] === true) {
          return true;
        }
        return hiddenSoFar;
      }, false)
    : false;
  return (
    <aside id="cookieNotice" className={classNames("box", { hidden: isHidden })}>
      <header>
        <h2>{t("cookies:noticeTitle")}</h2>
      </header>
      <p>
        <Trans
          i18nKey="cookies:noticeMeta"
          components={[
            <a key={0} href={t("meta:PRIVACY_LINK")} target="_blank" rel="noopener noreferrer">
              Privacy and Cookie Policy
            </a>,
          ]}></Trans>
      </p>
      {/* <button onClick={() => onOpenCookieModal()}>btn</button> */}
      <footer>
        <Modal
          style={{ maxWidth: "520px" }}
          trigger={<Button colour="link">{t("cookies:Manage Cookies")}</Button>}
          ref={cookieModalRef}>
          <CookieModal acceptedList={cookiesToBeAccepted} list={cookieList} onAccept={onAccept} onUpdate={onUpdate} />
        </Modal>
        <Button colour="primary" onClick={() => onAccept(getDefaultListToBeAccepted(cookieList))}>
          {t("cookies:Accept All")}
        </Button>
      </footer>
    </aside>
  );
});
