// export const TAB_NAMES = {
//   "edit-profile": "My Details",
//   "change-password": "Change Password",
//   "escalation-alerts": "Escalation Alerts",
//   "nhsr-alerts": "Region Alerts",
// };

// export type TTabName = keyof typeof TAB_NAMES;

// edit profile:
export const INPUT_SETTINGS = {
  username: {
    type: "text",
    icon: "block",
    iconPlacement: "right" as const,
    disabled: true,
  },
  email: {
    type: "text",
    placeholder: "Email address",
  },
  firstname: {
    type: "text",
    placeholder: "First name",
  },
  surname: { type: "text" },
  mobile: { type: "text" },
  primaryOrganisation: { type: "select" },
};
