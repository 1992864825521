import React from "react";
// import { Polygon } from "../improved/Polygon";
import { Boundary } from "../improved/Boundary";
import { getPolygonOpacity } from "../helpers/map";
import { BRAG_COLOURS, BRAG_PRESSURE_LEVEL_MAPPING } from "../consts";
import { CHILD_POLYGON_OPACITY, defaultChildPolygonOptions, HOVER_OUT_DELAY } from "../settings";
import debounce from "lodash/debounce";
// ? TYPES:
import {
  ChildFeature,
  ParentFeature,
  AreaPressureLevel,
  FilterPressureLevel,
  PolygonCosmeticOptions,
  OpacityList,
} from "../../../types/google-map";
interface Props {
  children: ChildFeature<any, any>[] | ParentFeature<any, any>[];
  childLabelKey?: string;
  childrenColours?: AreaPressureLevel<FilterPressureLevel, any>[];
  polygonCosmetics?: PolygonCosmeticOptions;
  opacityList?: OpacityList | number;
  // parentColours: AreaPressureLevel<FilterPressureLevel>[];
  currentChildHover?: ChildFeature<any, any> | ParentFeature<any, any> | null;
  // viewType: ViewType;
  onMouseout?: (e: any, b: any, MouseEvent: any) => void;
  // onPolygonMouseMove: (e: any, feature: ChildFeature, MouseEvent: any, onMousemove: Function) => void;
  onMousemove?: (feature: ChildFeature<any, any> | ParentFeature<any, any>, e: any, MouseEvent: any) => void;
  clickable?: boolean;
  zIndex?: number;
}

const noop = () => null;

const getAreaFilters = (colours: AreaPressureLevel<FilterPressureLevel, any>[], id: string) =>
  colours.find((item: AreaPressureLevel<FilterPressureLevel>) => item.id === id);

const getFillColor = (
  colours: AreaPressureLevel<FilterPressureLevel, { meta: { colorCodeBg: string } }>[],
  childLabel: string
): string | undefined => {
  if (!colours || colours.length === 0) {
    return undefined;
  }
  const areaFilters = getAreaFilters(colours, childLabel);
  const pressureLevel = areaFilters ? (areaFilters as AreaPressureLevel<FilterPressureLevel>).filters.total : 0;
  if (pressureLevel === 0 && areaFilters && areaFilters.meta?.colorCodeBg) {
    return areaFilters.meta.colorCodeBg;
  }
  return BRAG_COLOURS[BRAG_PRESSURE_LEVEL_MAPPING[pressureLevel]].colour;
};

export const boundaries = ({
  children,
  childLabelKey = "ccg19nm",
  childrenColours = [],
  polygonCosmetics = {},
  opacityList = CHILD_POLYGON_OPACITY,
  currentChildHover = null,
  onMouseout = noop,
  // onPolygonMouseMove,
  onMousemove = noop,
  clickable = true,
  zIndex = 2,
}: Props): JSX.Element[][] | null => {
  if (!children || children.length === 0) {
    return null;
  }
  return (children as any[]).map((feature: ChildFeature<any, any> | ParentFeature<any, any>) => {
    const isSingular = (feature as any).geometry ? true : false;
    // if (type !== "Polygon") {
    //   console.warn("different type than polygon");
    // }
    const id = feature.properties[childLabelKey];
    // const coordinates = mapCoordinatesToPolygon(feature.geometry.coordinates);
    const fillOpacity = getPolygonOpacity(currentChildHover, feature, opacityList);
    const fillColor = getFillColor(childrenColours, id);
    const debouncedMouseout = debounce(onMouseout, HOVER_OUT_DELAY);
    const geometries: ChildFeature["geometry"][] = isSingular
      ? [(feature as any).geometry]
      : (feature as any).geometries;
    return geometries.map((g) => {
      const coords = g.coordinates;
      const type = g.type;
      return (
        <Boundary
          type={type}
          key={id}
          id={id}
          name={feature.properties[childLabelKey]}
          zIndex={zIndex}
          paths={coords}
          {...defaultChildPolygonOptions}
          fillColor={fillColor}
          fillOpacity={fillOpacity}
          {...polygonCosmetics}
          // onMouseover={(e: any, b: any, MouseEvent: any) => this.onChildMouseOver(e, feature)}
          onMousemove={(e: any, b: any, MouseEvent: any) => {
            if (!currentChildHover || currentChildHover.properties !== feature.properties) {
              onMousemove(feature, e, MouseEvent);
            }
          }}
          onMouseout={debouncedMouseout}
          clickable={clickable}
          // onClick={(e: any) => this.handleChildClick(e)}
        />
      );
    });
  });
};
