import React from "react";
import { Field } from "formik";
import {
  // FormikInput,
  FormikTextArea,
  // FormikDateTime,
  FormikSelect,
  FormikCheckboxGroup,
  FormikRadioGroup,
  FormikIndicatorGroup,
  // IndicatorRow,
  // IndicatorTitle,
  // Icon,
} from "../../components/formik/formik-other";
import { FormikInput } from "../../components/formik/formik-input";
import { FormikDateTime } from "../../components/formik/formik-date-timer-picker";
import { FormikDuration } from "../../components/formik/formik-duration";
import { FormikRichText, ViewRichText } from "../formik/formik-richtext";
import { Icon } from "@tscore/react-components";
// import { setClasses } from "helpers/helpers";
import classNames from "classnames";
import { BRAG_PRESSURE_LEVEL_MAPPING, isBlue } from "../../helpers/brag-helpers";
import { getValueType } from "../../helpers/report-helpers";
import {
  momentTimeDateFormat,
  momentTimeFormat,
  momentDateFormat,
  // formatToString,
  formatToStringByFormat,
  DATE_ICONS,
} from "../../lang/DateTimeFormats";
import { withTranslation } from "react-i18next";
// import { TEMP_LANG } from "./temp-lang";
// ? TYPES:
import { ImportedConfigIndicator, ConfigIndicator, ImportedConfigOption } from "../../types/forms-config";

export const VALIDATE_ON = {
  touched: true,
  submitted: true,
};

// const NO_VALUE_TEXT = "NO UPDATE";

export const FieldInlineError = ({ error }: { error?: string | string[] | null }): JSX.Element | null => {
  if (!error || error.length === 0) {
    return null;
  }
  if (typeof error === "string") {
    error = [error];
  }
  return (
    <aside className="form-error-holder">
      <ul>
        {error.map((item: string) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </aside>
  );
};

const FieldTitle = ({ children, type }: any): JSX.Element => {
  if (type === "section") {
    return <h2>{children}</h2>;
  }
  return <h3>{children}</h3>;
};

const ViewDate = ({ valueType, value, inputFormat }: any): JSX.Element => {
  return (
    <div className="view-accent">
      <div className="view-date">
        <span>
          <Icon>{DATE_ICONS[valueType.toLowerCase()]}</Icon>
        </span>
        {valueType === "TIME" && <span>{formatToStringByFormat(value, inputFormat, momentTimeFormat)}</span>}
        {valueType === "DATE" && <span>{formatToStringByFormat(value, inputFormat, momentDateFormat)}</span>}
        {valueType === "DATETIME" && <span>{formatToStringByFormat(value, inputFormat, momentTimeDateFormat)}</span>}
      </div>
    </div>
  );
};

const FieldViewWoT = (props: any): JSX.Element => {
  const valueType = getValueType(props.element);
  const { t, showReadableValues } = props;
  return (
    <Field
      name={props.element.elementId}
      render={({ field }: any) => {
        if (valueType === "STRING") {
          if ((props.element.fieldType === "radio" || props.element.fieldType === "dropdown") && !!field.value) {
            const selectedOption = props.element.config.options.find(
              (option: ImportedConfigOption) => option.value === field.value
            );
            return <div className="view-accent">{selectedOption.title}</div>;
          }
          return <div className="view-accent">{field.value}</div>;
        }
        if (valueType === "ARRAY") {
          if (field.value.length === 0) {
            return <div className="view-accent" />;
          }
          return field.value.map((item: string | number) => {
            // if (props.element.fieldType === "dropdown" || props.element.fieldType === "checkbox") {
            const selectedOption = props.element.config.options.find(
              (option: ImportedConfigOption) => option.value === item
            );
            // }
            return (
              <div className="view-accent" key={item}>
                {selectedOption.title || item}
              </div>
            );
          });
        }
        const dateCheckList = ["DATE", "TIME", "DATETIME"];
        if (dateCheckList.includes(valueType)) {
          return <ViewDate valueType={valueType} value={field.value} inputFormat={props.element.config.format} />;
        }
        const underscore = valueType.split("_");
        if (underscore[1] === "RANGE") {
          return (
            <>
              <ViewDate valueType={underscore[0]} value={field.value.from} inputFormat={props.element.config.format} />
              <div className="view-accent" style={{ background: "transparent", verticalAlign: "top" }}>
                -
              </div>
              <ViewDate valueType={underscore[0]} value={field.value.to} inputFormat={props.element.config.format} />
            </>
          );
        }
        if (valueType === "INDICATOR") {
          return (
            <div style={{ width: "100%" }}>
              <div className="indicator__box">
                {props.element.config.indicators.map((option: ImportedConfigIndicator, index: number) => {
                  if (!field.value) {
                    return <div key={option.indicatorId + "_" + index}>?</div>;
                  }
                  const indicator: ConfigIndicator = field.value[index];
                  const color = isBlue(indicator as any)
                    ? "blue"
                    : BRAG_PRESSURE_LEVEL_MAPPING[indicator.pressureLevel as number];
                  const hasNoUpdateText = indicator.value === "" ? true : false;
                  const v = showReadableValues ? indicator.readableValue || indicator.value : indicator.value;
                  let value: string | number = "-";
                  if (indicator && indicator.value !== null) {
                    value = hasNoUpdateText ? t("NO UPDATE") : v;
                  }
                  return (
                    <div key={option.indicatorId + "_" + index} className="indicator__wrapper">
                      <div className="indicator__th brag-holder-padding">
                        <span>{option.title}</span>
                      </div>
                      <span
                        className={classNames(
                          "brag brag-holder-padding",
                          { ["brag-" + color]: !!color },
                          { "brag-white": !color },
                          { "no-value-indicator": hasNoUpdateText }
                        )}>
                        <span>
                          {value}
                          {option.suffix}
                        </span>
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
          // return (
          //   <div>
          //     <div className="table main bordered no-horizontal-lines" style={{ width: "auto" }}>
          //       <IndicatorTitle configIndicators={props.element.config.indicators} />
          //       <div className="tbody">
          //         <div className="row">
          //           {props.element.config.indicators.map((option: ImportedConfigIndicator, index: number) => {
          //             const indicator: ConfigIndicator = field.value[index];
          //             const color = isBlue(indicator as any)
          //               ? "blue"
          //               : BRAG_PRESSURE_LEVEL_MAPPING[indicator.pressureLevel as number];
          //             let value: string | number = "-";
          //             if (indicator && indicator.value !== null) {
          //               value = indicator.value === "" ? NO_VALUE_TEXT : indicator.value;
          //             }
          //             return (
          //               <span key={option.indicatorId + "_" + index} className="cell brag-holder">
          //                 <span
          //                   className={classNames(
          //                     "brag",
          //                     { ["brag-" + color]: !!color },
          //                     { "brag-white": !color },
          //                     { "no-value-indicator": value === NO_VALUE_TEXT }
          //                   )}>
          //                   {value}
          //                   {option.suffix}
          //                 </span>
          //               </span>
          //             );
          //           })}
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // );
        }
        if (valueType === "DURATION") {
          const entries = Object.entries(field.value);
          if (entries.every(([_key, value]) => value === "")) {
            return <div className="view-accent" />;
          }
          return (
            <div className="view-accent">
              {/* {hours} {hours === 1 ? TEMP_LANG.hour : TEMP_LANG.hour_plural} {minutes}{" "}
              {minutes === 1 ? TEMP_LANG.minute : TEMP_LANG.minute_plural} */}
              {entries.map(([key, value]) => (value || 0) + key + " ")}
            </div>
          );
        }
        if (valueType === "RICHTEXT") {
          return <ViewRichText value={field.value} />;
        }
        return <div className="view-accent">#err</div>;
      }}
    />
  );
};

const FieldView = withTranslation(["regionReports"])(FieldViewWoT);

const FieldWrapper = ({ children, element, error, isRequired, renderType, showReadableValues }: any): JSX.Element => {
  const layoutType = element.config && element.config.layoutType ? element.config.layoutType : "undefined";
  return (
    <div
      data-layout={layoutType}
      className={classNames("element", "element-" + element.fieldType, { "has-error": !!error })}>
      <header>
        <FieldTitle type={element.fieldType}>
          {element.title}
          {isRequired && <span className="isRequired">*</span>}
        </FieldTitle>
        {element.description && element.description !== "" && <p>{element.description}</p>}
      </header>
      <section className={"answer-" + renderType}>
        {(renderType !== "view" || element.fieldType === "gridLayout") && children}
        {renderType === "view" && element.fieldType !== "gridLayout" && (
          <FieldView showReadableValues={showReadableValues} element={element} />
        )}
        <FieldInlineError error={error} />
      </section>
    </div>
  );
};

export const ElementSingleLine = ({ element, error, isRequired, renderType }: any): JSX.Element => {
  return (
    <FieldWrapper element={element} error={error} isRequired={isRequired} renderType={renderType}>
      <FormikInput _name={element.elementId} placeholder={element.config.placeholder} />
    </FieldWrapper>
  );
};

export const ElementMultiLine = ({ element, error, isRequired, renderType }: any): JSX.Element => {
  return (
    <FieldWrapper element={element} error={error} isRequired={isRequired} renderType={renderType}>
      <FormikTextArea _name={element.elementId} placeholder={element.config.placeholder} />
    </FieldWrapper>
  );
};

export const ElementRichText = ({ element, error, isRequired, setFieldValue, renderType }: any): JSX.Element => {
  return (
    <FieldWrapper element={element} error={error} isRequired={isRequired} renderType={renderType}>
      <FormikRichText
        _name={element.elementId}
        placeholder={element.config.placeholder}
        setFieldValue={setFieldValue}
      />
    </FieldWrapper>
  );
};

const NUMERIC_VALIDATION: any = {
  positiveNumbers: /[0-9]/,
  positiveDecimals: /[0-9.]/,
  negativeNumbers: /[0-9-]/,
  negativeDecimals: /[0-9\-.]/,
};

const getNumericRegex = (config: any): RegExp => {
  if (config.isNegative && config.isDecimal) {
    return NUMERIC_VALIDATION["negativeDecimals"];
  }
  if (config.isDecimal) {
    return NUMERIC_VALIDATION["positiveDecimals"];
  }
  if (config.isNegative) {
    return NUMERIC_VALIDATION["negativeNumbers"];
  }
  return NUMERIC_VALIDATION["positiveNumbers"];
};

const validateNumeric = (event: any, config: any): boolean => {
  const { key } = event;
  if (!getNumericRegex(config).test(key)) {
    event.preventDefault();
    return false;
  }
  return true;
};

export const ElementNumeric = ({ element, error, isRequired, renderType, config }: any): JSX.Element => {
  const settings: any = {
    onKeyPress: (event: any) => validateNumeric(event, config),
    min: config.isNegative === true ? undefined : 0,
  };
  return (
    <FieldWrapper element={element} error={error} isRequired={isRequired} renderType={renderType}>
      <FormikInput
        _name={element.elementId}
        placeholder={element.config.placeholder}
        type="number"
        style={{ width: "132px" }}
        {...settings}
      />
    </FieldWrapper>
  );
};

export const ElementDuration = ({ element, error, isRequired, renderType, setFieldValue }: any): JSX.Element => {
  return (
    <FieldWrapper element={element} error={error} isRequired={isRequired} renderType={renderType}>
      <FormikDuration validateNumeric={validateNumeric} setFieldValue={setFieldValue} _name={element.elementId} />
    </FieldWrapper>
  );
};

export const ElementSection = ({ element }: any): JSX.Element => {
  return <FieldWrapper element={element} />;
};

export const ElementLabel = ({ element }: any): JSX.Element => {
  return <FieldWrapper element={element} />;
};

export const ElementDateTime = ({
  element,
  error,
  isRequired,
  renderType,
  setFieldValue,
  valueDataType,
}: any): JSX.Element => {
  return (
    <FieldWrapper element={element} error={error} isRequired={isRequired} renderType={renderType}>
      <FormikDateTime
        _name={element.elementId}
        placeholder={element.config.placeholder}
        setFieldValue={setFieldValue}
        valueDataType={valueDataType}
        config={element.config}
      />
    </FieldWrapper>
  );
};

export const ElementSelect = ({
  element,
  error,
  isRequired,
  renderType,
  // valueDataType,
  setFieldValue,
}: any): JSX.Element => {
  return (
    <FieldWrapper element={element} error={error} isRequired={isRequired} renderType={renderType}>
      <FormikSelect
        _name={element.elementId}
        placeholder={element.config.placeholder}
        config={element.config}
        setFieldValue={setFieldValue}
      />
    </FieldWrapper>
  );
};

export const ElementCheckbox = ({ element, error, isRequired, renderType, setFieldValue }: any): JSX.Element => {
  return (
    <FieldWrapper element={element} error={error} isRequired={isRequired} renderType={renderType}>
      <FormikCheckboxGroup _name={element.elementId} setFieldValue={setFieldValue} {...element} />
    </FieldWrapper>
  );
};

export const ElementRadio = ({ element, error, isRequired, renderType, setFieldValue }: any): JSX.Element => {
  return (
    <FieldWrapper element={element} error={error} isRequired={isRequired} renderType={renderType}>
      <FormikRadioGroup _name={element.elementId} setFieldValue={setFieldValue} {...element} />
    </FieldWrapper>
  );
};

export const ElementIndicators = ({
  element,
  error,
  isRequired,
  renderType,
  setFieldValue,
  isFetchingIndicators,
  indicators,
  showReadableValues,
}: any): JSX.Element => {
  return (
    <FieldWrapper
      showReadableValues={showReadableValues}
      element={element}
      error={error}
      isRequired={isRequired}
      renderType={renderType}>
      <FormikIndicatorGroup
        _name={element.elementId}
        setFieldValue={setFieldValue}
        isFetchingIndicators={isFetchingIndicators}
        indicators={indicators}
        showReadableValues={showReadableValues}
        {...element}
      />
    </FieldWrapper>
  );
};

export const ElementTable = ({
  element,
  renderType,
  errors,
  touched,
  setFieldValue,
  indicators,
  showReadableValues,
}: any): JSX.Element => {
  return (
    <FieldWrapper element={element} error={null} isRequired={false} renderType={renderType}>
      <table className="table bordered main" style={{ tableLayout: "fixed", borderCollapse: "collapse" }}>
        {/* // ! TEMPORARY FIX FOR NO HORIZONTAL LINES  */}
        <thead>
          <tr>
            <td colSpan={element.config.cols} style={{ padding: 0 }}></td>
          </tr>
        </thead>
        <tbody>
          {element.rowElements.map((row: any, rowindex: number) => {
            return (
              <tr key={rowindex}>
                {row.colElements.map((cell: any, cellindex: number) => {
                  if (cell.visibility === false) {
                    return null;
                  }
                  return (
                    <td
                      key={cellindex}
                      colSpan={cell.colSpan}
                      rowSpan={cell.rowSpan}
                      style={{ borderTopWidth: rowindex === 0 ? 0 : undefined }}>
                      {cell.value.map((value: any) => {
                        return (
                          <div key={value.elementId}>
                            <FormField
                              element={{
                                ...value,
                                // ...(value.fieldType !== "label" && { title: null }),
                                // ...(value.fieldType !== "label" && { description: null })
                              }}
                              renderType={renderType}
                              setFieldValue={setFieldValue}
                              indicators={indicators}
                              errors={errors}
                              touched={touched}
                              isRequired={value?.validationLogic?.isRequired}
                              showReadableValues={showReadableValues}
                            />
                          </div>
                        );
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </FieldWrapper>
  );
};

const ErrorElement = ({ type }: any) => (
  <div className="p12 color-red" style={{ background: "#fff0f2" }}>
    ERROR - ({type})
  </div>
);

const getErrorList = (
  touched: any,
  submitCount: number,
  elementId: number | string,
  errors: any
): undefined | string[] | string => {
  if ((VALIDATE_ON.touched === true && touched[elementId]) || (VALIDATE_ON.submitted === true && submitCount >= 1)) {
    return errors[elementId];
  }
  return undefined;
};

export const FormField = ({
  element,
  // error,
  setFieldValue,
  isRequired,
  isFetchingIndicators,
  indicators,
  renderType,
  errors,
  touched,
  submitCount,
  isVisible,
  showReadableValues,
}: any): JSX.Element | null => {
  const error = getErrorList(touched, submitCount, element.elementId, errors);
  const props = { element, error, isRequired, isVisible, renderType };
  if (isVisible === false) {
    return null;
  }
  switch (element.fieldType) {
    case "singleLine":
      // switch (element.valueDataType) {
      // case "string":
      return <ElementSingleLine {...props} />;
    case "numeric":
      return <ElementNumeric {...props} config={element.config} />;
    // default:
    // return <ErrorElement />;
    // }
    case "gridLayout":
      return (
        <ElementTable
          {...props}
          showReadableValues={showReadableValues}
          errors={errors}
          touched={touched}
          indicators={indicators}
          setFieldValue={setFieldValue}
          valueDataType={element.valueDataType}
        />
      );
    case "multiLine":
      return <ElementMultiLine {...props} />;
    case "richText":
      return <ElementRichText setFieldValue={setFieldValue} {...props} />;
    case "section": // ? cosmetic
      return <ElementSection {...props} />;
    case "label": // ? cosmetic
      return <ElementLabel {...props} />;
    case "datetime":
      // ? Includes datetime | date | time
      return <ElementDateTime {...props} setFieldValue={setFieldValue} valueDataType={element.valueDataType} />;
    case "dropdown":
      return <ElementSelect {...props} valueDataType={element.valueDataType} setFieldValue={setFieldValue} />;
    case "checkbox":
      return <ElementCheckbox {...props} setFieldValue={setFieldValue} valueDataType={element.valueDataType} />;
    case "radio":
      return <ElementRadio {...props} setFieldValue={setFieldValue} valueDataType={element.valueDataType} />;
    case "indicator":
      return (
        <ElementIndicators
          {...props}
          showReadableValues={showReadableValues}
          isFetchingIndicators={isFetchingIndicators}
          indicators={indicators}
          setFieldValue={setFieldValue}
          valueDataType={element.valueDataType}
        />
      );
    case "duration":
      return <ElementDuration setFieldValue={setFieldValue} {...props} />;
    default:
      return <ErrorElement type={element.fieldType} />;
  }
};
