export const generateBounds = (
  markers: { lat: number; lng: number; [anythingElse: string]: any }[],
  google: any
): google.maps.LatLngBounds | undefined => {
  if (markers.length === 0) {
    return undefined;
  }
  const bounds = new google.maps.LatLngBounds();
  for (let i = 0; i < markers.length; i += 1) {
    bounds.extend(new google.maps.LatLng(markers[i].lat, markers[i].lng));
  }
  return bounds;
};
