import { paginate } from "../../../helpers/paginate";
// import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";
//? TYPES
import { DocumentsState } from "../../types/documents-state";
import { Document } from "../../../types/document";

export const prependDocument = (
  documents: Document[],
  meta: DocumentsState["meta"],
  uploadProgress: DocumentsState["uploadProgress"],
  newDocumentInArray: Document[],
  originalFile: File
): {
  documents: Document[];
  meta: DocumentsState["meta"];
  uploadProgress: DocumentsState["uploadProgress"];
} => {
  if (!newDocumentInArray || newDocumentInArray.length === 0) {
    return {
      documents,
      meta,
      uploadProgress,
    };
  }
  const newDocument = newDocumentInArray[0];
  let isReplaced = false;
  const updatedDocuments = documents.map((document) => {
    if (document.documentId === newDocument.documentId) {
      isReplaced = true;
      return newDocument;
    }
    return document;
  });
  let newMeta = meta;
  if (!isReplaced) {
    if (meta.currentPage === 1) {
      updatedDocuments.unshift(newDocument);
      //
      updatedDocuments.splice(20);
      newMeta = paginate(meta.totalItems + 1, 1, meta.pageSize);
      //
    }
    // ! if not page 1 should reroute to page 1
  }
  return {
    documents: updatedDocuments,
    meta: newMeta,
    uploadProgress: {
      ...uploadProgress,
      [originalFile.name + originalFile.lastModified]: 100,
    },
  };
};
