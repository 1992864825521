import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchProvidersIfNeeded,
  fetchIndicators,
  fetchAddNewIndicatorGroupWithRefresh,
  fetchDeleteIndicatorsFromGroup,
} from "../../store/actions/personalised-indicators";
import { Select, Icon, Input, Button, InlineError, Tippy } from "@tscore/react-components";
import { MODAL_SIZES } from "./consts";
import { searchByKeys } from "../../helpers/search-by-keys";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
// ? TYPES:
import { ApplicationState } from "../../store/reducers";
import { UpdatedIndicator, Indicator, PersonalisedIndicator } from "../../store/types/personalised-indicators-state";

interface ModalIndicatorSetupProps {
  title: string;
  subtitle?: string;
  type?: "add" | "update";
  updateSettings?: {
    // ccgId: number;
    // agencyId: number;
    organisationId: number;
    indicators: Indicator[];
  };
}

type SelectedIndicator = { alertEnabled: boolean };
export type TSelectedIndicators = {
  [indicatorId: number]: SelectedIndicator;
};

const WARNING_INDICATOR_NUMBER = 100;

const generateInitialIndicators = (indicators: Indicator[]): TSelectedIndicators => {
  return indicators.reduce((final: { [indicatorId: number]: SelectedIndicator }, indicator) => {
    final[indicator.indicatorId] = {
      alertEnabled: indicator.alertEnabled,
    };
    return final;
  }, {});
};

interface IndicatorRowProps {
  indicator: PersonalisedIndicator;
  toggleSelectedIndicator: (indicatorId: number) => void;
  toggleAlerts: (indicatorId: number, preventUpdate?: boolean) => void;
  selected: SelectedIndicator | undefined;
}

const IndicatorRow: React.FC<IndicatorRowProps> = ({ indicator, toggleSelectedIndicator, toggleAlerts, selected }) => {
  const { t } = useTranslation();
  const isChecked = !selected ? false : true;
  const iconName = "check"; //isChecked ? "check_circle" : "check_circle_outline";
  const alertEnabled = !isChecked ? true : selected!.alertEnabled;
  const alertsIconName = alertEnabled ? "notifications" : "notifications_off";
  return (
    <ul
      key={indicator.indicatorId}
      className="row"
      style={{ backgroundColor: isChecked ? "rgba(65, 160, 242, 0.06)" : undefined }}>
      <li className="cell w1 icon-holder check-holder" onClick={() => toggleSelectedIndicator(indicator.indicatorId)}>
        <Tippy
          content={isChecked ? t("generic:Remove from list") : t("generic:Add to list")}
          placement="bottom"
          delay={[800, 0]}>
          <span>
            <Icon
              className={classNames("pointer", {
                "color-green": isChecked,
                "color-lightgray": !isChecked,
              })}>
              {iconName}
            </Icon>
          </span>
        </Tippy>
      </li>
      <li className="cell w1 id-holder pointer" onClick={() => toggleSelectedIndicator(indicator.indicatorId)}>
        {indicator.indicatorId}
      </li>
      <li className="cell normal-holder pointer" onClick={() => toggleSelectedIndicator(indicator.indicatorId)}>
        <div>{indicator.indicatorName}</div>
      </li>
      <li className="cell w1 icon-holder alert-holder" onClick={() => toggleAlerts(indicator.indicatorId, !isChecked)}>
        <Tippy
          disabled={!isChecked}
          content={alertEnabled ? t("home:Turn alerts OFF") : t("home:Turn alerts ON")}
          placement="bottom">
          <span style={{ visibility: isChecked ? undefined : "hidden" }}>
            <Icon
              className={classNames("pointer color-gray alert-icon", {
                // "alerts-enabled": alertEnabled,
                "alerts-disabled": !alertEnabled,
              })}>
              {alertsIconName}
            </Icon>
          </span>
        </Tippy>
      </li>
    </ul>
  );
};

const IndicatorRowMemo = React.memo(IndicatorRow);

export const ModalIndicatorSetup: React.FC<ModalIndicatorSetupProps> = ({
  title,
  subtitle,
  type = "add",
  updateSettings = { organisationId: -1, indicators: [] },
}) => {
  const { t } = useTranslation();
  const [selectedNo, setSelectedNo] = useState(0);
  const [providerSelected, setProviderSelected] = useState(updateSettings.organisationId);
  // const [agencySelected, setAgencySelected] = useState(updateSettings.agencyId);
  const [selectedIndicators, setSelectedIndicators] = useState<{
    [indicatorId: number]: SelectedIndicator;
  }>({});
  const [query, setQuery] = useState("");
  const inputEl = useRef<HTMLInputElement>(null!);
  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedNo(Object.keys(selectedIndicators).length);
  }, [setSelectedNo, selectedIndicators]);
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchProvidersIfNeeded());
    }
    if (type === "add") {
      fetchInitial();
    }
  }, [dispatch, type]);
  useEffect(() => {
    async function fetchInitialUpdate() {
      await dispatch(
        fetchIndicators(updateSettings.organisationId, generateInitialIndicators(updateSettings.indicators))
      );
    }
    if (type === "update") {
      fetchInitialUpdate();
      setTimeout(() => {
        inputEl.current!.focus();
      }, 0);
    }
  }, [dispatch, type, updateSettings.organisationId, updateSettings.indicators]);
  useEffect(() => {
    if (type === "update") {
      setSelectedIndicators(generateInitialIndicators(updateSettings.indicators));
    }
  }, [type, updateSettings.organisationId, updateSettings.indicators, setSelectedIndicators]);
  const state = useSelector((state: ApplicationState) => state.personalisedIndicatorsReducer);
  const { providers, indicators, isFetching } = state;
  const autoSelectProviders = providers.length === 1;
  const handleProviderChange = (e: (typeof providers)[number] | any, _action?: any) => {
    setProviderSelected(e.organisationId);
    if (e.organisationId !== providerSelected) {
      // setAgencySelected(-1);
      setSelectedIndicators([]);
      dispatch(fetchIndicators(e.organisationId));
      // dispatch(fetchAgency(e.ccgId));
    }
  };
  const stableSelectFirstProvider = useCallback(handleProviderChange, []);
  useEffect(() => {
    if (type === "add" && autoSelectProviders) {
      stableSelectFirstProvider(providers[0]);
    }
  }, [stableSelectFirstProvider, type, autoSelectProviders, providers]);
  // const handleAgencyChange = (e: typeof agencies[number][number] | any, _action?: any) => {
  //   setAgencySelected(e.agencyId);
  //   inputEl.current!.focus();
  //   if (e.agencyId !== agencySelected) {
  //     // load indicators
  //     setSelectedIndicators([]);
  //     dispatch(fetchIndicators(e.agencyId));
  //   }
  // };
  const toggleSelectedIndicator = useCallback((indicatorId: number) => {
    setSelectedIndicators((prevState) => {
      return Object.entries({
        ...prevState,
        [indicatorId]: !prevState[indicatorId] ? { alertEnabled: true } : undefined,
      }).reduce(
        (
          final: { [indicatorId: string]: SelectedIndicator },
          [indicatorId, value]: [string, SelectedIndicator | undefined]
        ) => {
          if (value) {
            final[indicatorId] = value;
          }
          return final;
        },
        {}
      );
    });
  }, []);
  const toggleAll = () => {
    setQuery("");
    if (Object.keys(selectedIndicators).length === indicators[providerSelected].length) {
      setSelectedIndicators([]);
    } else {
      setSelectedIndicators(
        indicators[providerSelected].reduce((final: { [indicatorId: string]: SelectedIndicator }, indicator) => {
          final[indicator.indicatorId as any] = { alertEnabled: true };
          return final;
        }, {})
      );
    }
  };

  const toggleAlerts = useCallback((indicatorId: number, preventUpdate = false) => {
    if (preventUpdate) {
      return false;
    }
    setSelectedIndicators((prevState) => {
      const alertsEnabledNow = prevState[indicatorId].alertEnabled;
      return {
        ...prevState,
        [indicatorId]: { alertEnabled: !alertsEnabledNow },
      };
    });
  }, []);

  const onSubmit = () => {
    const indicatorsToDelete: UpdatedIndicator[] = updateSettings.indicators
      .filter((indicator) => !selectedIndicators[indicator.indicatorId])
      .map((indicator) => ({
        indicatorId: indicator.indicatorId,
        alertEnabled: true, // not sure if needed, deleted anyway
        type: "remove",
      }));
    if (type === "update" && Object.keys(selectedIndicators).length === 0) {
      dispatch(fetchDeleteIndicatorsFromGroup(providerSelected, indicatorsToDelete));
      return;
    }
    const initialIndicators = generateInitialIndicators(updateSettings.indicators);
    const indicatorsToAddOrUpdate: UpdatedIndicator[] = Object.entries(selectedIndicators).map(
      ([indicatorId, value]) => ({
        indicatorId: parseFloat(indicatorId),
        alertEnabled: value.alertEnabled,
        type: initialIndicators[indicatorId as any] ? "update" : "add",
      })
    );
    dispatch(
      fetchAddNewIndicatorGroupWithRefresh(providerSelected, [...indicatorsToAddOrUpdate, ...indicatorsToDelete])
    );
  };
  // console.log({ agencies, indicators });
  const indicatorList: PersonalisedIndicator[] = searchByKeys(indicators[providerSelected] || [], query, [
    "indicatorId",
    "indicatorName",
  ]);
  return (
    <div id="modal-indicator-setup">
      <header>
        <h4>{title}</h4>
        {subtitle && <h6>{subtitle}</h6>}
      </header>
      <article>
        {type === "add" && (
          <section
            className="left-side"
            style={{
              marginRight: MODAL_SIZES.LEFT_SIDE_MARGIN.dimension + MODAL_SIZES.LEFT_SIDE_MARGIN.suffix,
              width: MODAL_SIZES.LEFT_SIDE_WIDTH.dimension + MODAL_SIZES.LEFT_SIDE_WIDTH.suffix,
            }}>
            <div className="mb18">
              <label>{autoSelectProviders ? t("home:Provider") : t("home:Select Provider")}</label>
              {providers.length <= 1 ? (
                <Input
                  className={!isFetching["GET_PROVIDERS"] && providers.length === 0 ? "is-invalid" : ""}
                  isLoading={isFetching["GET_PROVIDERS"]}
                  disabled
                  // icon="check"
                  scale="large"
                  value={(providers[0] || { organisationName: t("home:No Providers") }).organisationName}
                />
              ) : (
                <Select
                  className="provider-select"
                  scale="large"
                  onChange={handleProviderChange}
                  options={providers}
                  getOptionLabel={(option: any) => option.organisationName}
                  getOptionValue={(option: any) => option.organisationId}
                  placeholder={t("generic:selectPlaceholder")}
                />
              )}
            </div>
            {/* <div>
              <label>{t("home:Select Organisation")}</label>
              <Select
                scale="large"
                isDisabled={ccgSelected === -1}
                onChange={handleAgencyChange}
                options={agencies[ccgSelected] || []}
                noOptionsMessage={() => t("generic:Loading...")}
                getOptionLabel={(option: any) => option.agencyName}
                getOptionValue={(option: any) => option.agencyId}
              />
            </div> */}
          </section>
        )}
        <section className="right-side">
          <div>
            <aside style={{ position: "relative", marginTop: type === "add" ? 0 : "8px" }}>
              <div style={{ position: "absolute", right: 0, width: "100%", top: "-20px", textAlign: "right" }}>
                {/* <Button length="shorter" colour="link">
                  Enable All Alerts
                </Button> */}
                {selectedNo > WARNING_INDICATOR_NUMBER && (
                  <Tippy
                    content={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("home:tooltipWarningManyIndicatorsSelected", { count: selectedNo }),
                        }}></span>
                    }
                    placement="bottom"
                    className="tippy-warning">
                    <span
                      style={{ display: "inline-flex", height: "3.2rem", alignItems: "center", marginRight: "0.4rem" }}>
                      <Icon className="color-orange">warning</Icon>
                    </span>
                  </Tippy>
                )}
                <Button length="shorter" disabled={providerSelected === -1} colour="link" onClick={() => toggleAll()}>
                  {isFetching["GET_INDICATORS_LIST"] ||
                  providerSelected === -1 ||
                  selectedNo !== (indicators[providerSelected] || []).length
                    ? t("generic:Select All")
                    : t("generic:Deselect All")}
                </Button>
                <Input
                  holderStyle={{ width: "12vw", minWidth: "140px", verticalAlign: "top" }}
                  icon="search"
                  placeholder={t("home:Search Indicators")}
                  value={query}
                  onChange={(e: any) => setQuery(e.target.value)}
                  ref={inputEl}
                  // value="xd"
                />
              </div>
            </aside>
            <label style={{ paddingBottom: "2px", opacity: type === "add" ? undefined : 0 }}>
              {t("home:Select Indicator(s)")}
            </label>
            <div
              style={{
                marginTop: "0.4rem",
                // height: type === "add" ? "60vh" : undefined,
                // maxHeight: type !== "add" ? "60vh" : undefined,
                height: "60vh",
                overflow: "auto",
                border: "1px solid #d1d5da",
                borderRadius: "3px",
              }}>
              {type === "add" && providerSelected === -1 ? (
                <InlineError
                  className="middle"
                  title={t("home:errorNoOrganisationTitle")}
                  description={t("home:pleaseSelectOrganisationFromList")}
                  // icon="portable_wifi_off"
                />
              ) : (
                <div className="table no-vertical-lines main shadow-hover-effect">
                  <div className="no-thead" />
                  <div className="tbody">
                    {!isFetching["GET_INDICATORS_LIST"] && indicatorList.length === 0 && (
                      <ul className="row">
                        <li className="cell">{t("home:No indicators found")}</li>
                      </ul>
                    )}
                    {!isFetching["GET_INDICATORS_LIST"] &&
                      indicatorList.map((indicator) => (
                        <IndicatorRowMemo
                          key={indicator.indicatorId}
                          indicator={indicator}
                          toggleSelectedIndicator={toggleSelectedIndicator}
                          toggleAlerts={toggleAlerts}
                          selected={selectedIndicators[indicator.indicatorId]}
                        />
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </article>
      <footer className="ta-right">
        <Button colour="link" data-close-modal>
          {t("generic:Cancel")}
        </Button>
        <Button
          type="submit"
          data-close-modal
          onClick={() => onSubmit()}
          disabled={type === "add" && Object.values(selectedIndicators).length === 0}
          isLoading={isFetching["ADD_NEW_INDICATOR_GROUP"]}>
          {type === "add" ? t("generic:Confirm") : t("generic:Update")}
        </Button>
      </footer>
    </div>
  );
};
