import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button } from "@tscore/react-components";
import { ErrorField } from "../error-field";
import { MobileInput } from "./mobile-input";
import { fetchRegisterMfa, updateMfaMobileLocal } from "../../../../store/actions/auth";
import { Step } from "./../step";
import { AnotherMethod } from "./another-method";
import { Verify } from "./../verify";
import { joinErrors } from "../../../../helpers/join-errors";
// ? TYPES:
import { SetupStageProps } from "../../../../types/login-form";
import { ApplicationState } from "../../../../store/reducers";

const StageSmsWoT: React.FC<SetupStageProps> = ({
  handleSubmit,
  values,
  setValue,
  rememberDays,
  isLoadingVerify,
  errors,
  mfaState,
  t,
}) => {
  const dispatch = useDispatch();
  const updateMobileValue = (field: string, value: any) => {
    dispatch(updateMfaMobileLocal({ [field]: value })); // clears api errors too
  };
  const onSendCode = async (e: any) => {
    e.preventDefault();
    await dispatch(fetchRegisterMfa("SMS"));
  };
  const isFetchingCode = useSelector((state: ApplicationState) => state.credentialsReducer.isFetching["REGISTER_MFA"]);
  const apiErrors = useSelector((state: ApplicationState) => state.credentialsReducer.errors);
  const fieldErrorsWithApi = joinErrors(
    errors as { [k: string]: string },
    apiErrors["VERIFY_REGISTER_MFA"] ? [{ field: "mfaCode", error: apiErrors["VERIFY_REGISTER_MFA"] }] : []
  );
  return (
    <div className="login-form">
      <div className="box">
        <Step no={1}>
          <form onSubmit={onSendCode}>
            <p>{t("mfa.setup.SMS.steps.0")}</p>
            <div className="account-section mt18">
              <MobileInput
                mobilePlaceholder={t("mfa.setup.SMS.mobilePlaceholder")}
                values={mfaState.smsDetails.mobileValues}
                setFieldValue={updateMobileValue}
                phoneCodeList={mfaState.info.phoneCountryCodeList}
              />
              <ErrorField error={apiErrors["REGISTER_MFA"] as string} />
            </div>
            <div className="mt18" style={{ display: "flex", alignItems: "center" }}>
              {mfaState.smsDetails.sent ? (
                <span className="mfa__sent mfa__sent--success">{mfaState.smsDetails.sent}</span>
              ) : (
                <Button type="submit" className="bold" isLoading={isFetchingCode} length="longer">
                  {t("mfa.setup.SMS.sendButton")}
                </Button>
              )}
              <Button type="button" tabIndex={-1} aria-hidden="true" style={{ visibility: "hidden" }}></Button>
            </div>
          </form>
        </Step>
        <hr />
        <Step no={2}>
          <form onSubmit={handleSubmit}>
            <Verify
              isLoading={isLoadingVerify}
              error={fieldErrorsWithApi["mfaCode"]}
              values={values}
              setValue={setValue}
              rememberDays={rememberDays}
              autoFocus={false}>
              <p>{t("mfa.setup.SMS.steps.1")}</p>
            </Verify>
          </form>
        </Step>
      </div>
      <AnotherMethod />
    </div>
  );
};

export const StageSms = withTranslation(["login"])(StageSmsWoT);
