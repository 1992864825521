// https://github.com/Sanjagh/use-custom-compare-effect

import { useRef, useEffect } from "react";

type MaybeCleanUpFn = void | (() => void);

function useCustomCompareMemo<T>(value: T, equal: (T1: T, T2: T) => boolean): T {
  const ref = useRef<T>(value);

  if (!equal(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useCustomCompareEffect<T>(create: () => MaybeCleanUpFn, input: T, equal: (T1: T, T2: T) => boolean) {
  useEffect(create, [useCustomCompareMemo(input, equal)]);
}

export default useCustomCompareEffect;
