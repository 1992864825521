import { apiAction } from "../../api";
import { SERVICE_URLS } from "../../../../globals/service-urls";
import { generateParams } from "../../../../helpers/generate-params";
// import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";
// ? TYPES:
// import { ElectiveData } from "../../types/maps-elective";
import { ApiCallback, ApiAction } from "../../../types/api";

// import { PaginatedParams } from "../../../types/paginated-params";

const VERSION = 1;

export const getNhsrParentBoundary = (callbacks: ApiCallback): ApiAction => {
  const params = {
    disablePagination: true,
  };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["nhsr-service"]}${VERSION}/map/parent-boundary${generateParams(params)}`,
    ...callbacks,
  });
};

export const getNhsrChildBoundary = (callbacks: ApiCallback): ApiAction => {
  const params = {
    disablePagination: true,
  };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["nhsr-service"]}${VERSION}/map/child-boundary${generateParams(params)}`,
    ...callbacks,
  });
};

export const getNhsrHospitals = (callbacks: ApiCallback): ApiAction => {
  const params = {
    disablePagination: true,
  };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["nhsr-service"]}${VERSION}/map/hospitals${generateParams(params)}`,
    ...callbacks,
  });
};
