// ? TYPES:
import { TMyApp } from "../../../components/structure/my-apps/app-list";
import { ImportedAccessInfo } from "../../types/menu-state";
import { BeProductName } from "../../../types/user-info";

export const getMyAppsAccess = (
  rawMenu: ImportedAccessInfo,
  apps: TMyApp[],
  productList: BeProductName[] = []
): TMyApp[] => {
  function updateApp(a: TMyApp | Omit<TMyApp, "children">, isDisabled: boolean): TMyApp | Omit<TMyApp, "children"> {
    return {
      ...a,
      //eslint-disable-next-line
      ...((a as TMyApp).children && (a as TMyApp).children.length > 0 && { children: updateShallowApps((a as TMyApp).children) }),
      isDisabled,
    };
  }
  const updateShallowApps = (apps: (TMyApp | Omit<TMyApp, "children">)[]) => {
    return apps.map((a: TMyApp | Omit<TMyApp, "children">) => {
      if (a.id === "Resilience") {
        return updateApp(a, false);
      }
      if (a.id === "Region") {
        // const isDisabled = rawMenu["nhsr-service"].hasNHSAccess ? false : true;
        // const isDisabled = Object.values(rawMenu["nhsr-service"]).every((item: any[] | any) => {
        //   if (Array.isArray(item) && item.length !== 0) {
        //     return false;
        //   }
        //   return true;
        // });
        const isDisabled = !productList.includes("Region");
        return updateApp(a, isDisabled);
      }
      if (a.id === "Elective") {
        const isDisabled = !productList.includes("Elective");
        return updateApp(a, isDisabled);
      }
      if (a.id === "Vantage") {
        const isDisabled = !productList.includes("Vantage");
        return updateApp(a, isDisabled);
      }

      if (a.id === "WaitLess") {
        const isDisabled = !productList.includes("Waitless");
        return updateApp(a, isDisabled);
      }
      // if (a.id === "WaitLess Admin") {
      //   const isDisabled = !productList.includes("Waitless");
      //   return updateApp(a, isDisabled);
      // }
      if (a.id === "Escalation") {
        const isDisabled =
          !rawMenu["escalation-service"] ||
          !rawMenu["escalation-service"].escalationEnabledSystemDashboardList ||
          rawMenu["escalation-service"].escalationEnabledSystemDashboardList.length === 0
            ? true
            : false;
        return updateApp(a, isDisabled);
      }
      return a;
    });
  };
  return updateShallowApps(apps) as TMyApp[];
};
