import React, { useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  fetchProfile,
  fetchOrganisationList,
  fetchUpdateProfile,
  clearMyAccountErrors,
} from "../../store/actions/my-account";

import { withFormik } from "formik";
import { FormikMobile } from "../../components/formik/formik-mobile";
import { FormikInput, FormikSelect } from "../../components/formik";
import { profileSchema } from "../../schemas/my-account.schema";

import { INPUT_SETTINGS } from "./consts";
import { Button, Loader } from "@tscore/react-components";
import { EditProfileEmpty } from "./empty-tab-edit-profile";
// import classNames from "classnames";
// import { TAB_NAMES } from "./consts";
// import startCase from "lodash/startCase";
import { joinErrors } from "../../helpers/join-errors";
import { useTranslation, withTranslation } from "react-i18next";
//? TYPES:
import { ApplicationState } from "../../store/reducers";
import { ProfileDetails } from "../../schemas/my-account.schema";
// import { RouteComponentProps } from "react-router-dom";
import { Organisation } from "../../store/types/my-account-state";
import { TFunction } from "i18next";
interface TabEditProfileBasicProps {
  values: ProfileDetails;
  [key: string]: any; // formik other
}

// type Organisation = any;

const TabEditProfileBasic: React.FC<TabEditProfileBasicProps> = ({
  isSubmitting,
  handleSubmit,
  setFieldValue,
  values,
  errors,
  resetForm,
  clearMyAccountErrors: dispatchClearMyAccountErrors,
  ..._props
}) => {
  // console.log(props);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchProfile());
      await dispatch(fetchOrganisationList());
    }
    fetchInitial();
    return () => {
      dispatchClearMyAccountErrors();
    };
  }, [dispatch, dispatchClearMyAccountErrors]);
  const organisationList = useSelector((state: ApplicationState) => state.myAccountReducer.organisationList);
  const phoneCodeList = useSelector((state: ApplicationState) => state.myAccountReducer.phoneCodeList);
  // .map((organisation) => ({
  //   label: organisation.name,
  //   value: organisation.organisationId,
  // }))

  const isFetching = useSelector((state: ApplicationState) => state.myAccountReducer.isFetching);
  const apiErrors = useSelector((state: ApplicationState) => state.myAccountReducer.errors["UPDATE_PROFILE"]);
  const fieldErrorsWithApi = joinErrors(errors, apiErrors);
  const onKeyDown = ({ keyCode }: any): boolean | void =>
    keyCode === 13 && !isFetching["UPDATE_PROFILE"] && handleSubmit;
  return (
    <div className="box">
      <h2>{t("myAccount:My Details")}</h2>
      <div className="account-section">
        {/* <h3>Personal Information</h3> */}
        <hr />
        <section>
          <aside>
            <p>{t("myAccount:myDetailsAside")}</p>
          </aside>
          {isFetching["GET_PROFILE"] ? (
            <EditProfileEmpty />
          ) : (
            <form className="account-form" onKeyDown={onKeyDown} onSubmit={handleSubmit}>
              {Object.entries(INPUT_SETTINGS).map(([key, props]) => {
                // const placeholder = (props as any).placeholder || startCase(key);
                const placeholder = t("myAccount:" + key);
                return (
                  <div key={key}>
                    <label>
                      {placeholder}
                      {key === "primaryOrganisation" && isFetching["GET_ORGANISATION_LIST"] && (
                        <Loader style={{ float: "right" }} isInline />
                      )}
                    </label>
                    {(() => {
                      if (key === "mobile") {
                        return (
                          <FormikMobile
                            mobilePlaceholder={t("myAccount:phoneCountryCode")}
                            values={values}
                            setFieldValue={setFieldValue}
                            phoneCodeList={phoneCodeList}
                            {...props}
                          />
                        );
                      }
                      if (["text", "number"].includes(props.type)) {
                        return (
                          <FormikInput
                            name={key}
                            autoComplete="off"
                            value={(values as any)[key]}
                            {...props}
                            placeholder={placeholder}
                          />
                        );
                      }
                      if (props.type === "select") {
                        return (
                          <FormikSelect
                            menuPlacement="top"
                            placeholder={placeholder}
                            getOptionLabel={(option: Organisation) => option.name}
                            getOptionValue={(option: Organisation) => option.organisationId}
                            options={organisationList}
                            isClearable
                            setFieldValue={setFieldValue}
                            value={(values as any)[key]}
                            name={key}
                          />
                        );
                      }
                      return <span>{key}</span>;
                    })()}
                    <div className="form-error is-visible">
                      {fieldErrorsWithApi[key]}
                      {key === "mobile" && fieldErrorsWithApi["phoneCountryCode"]}
                    </div>
                  </div>
                );
              })}
              <footer>
                <Button type="submit" isLoading={isSubmitting || isFetching["UPDATE_PROFILE"]}>
                  {t("generic:Save")}
                </Button>
              </footer>
            </form>
          )}
        </section>
      </div>
    </div>
  );
};

const formikEnhancer = withFormik({
  validationSchema: ({ t }: { t: TFunction }) => profileSchema(t),
  mapPropsToValues: ({ details }: { details: ProfileDetails; [key: string]: any }) => details,
  handleSubmit: (values, { setSubmitting, props }) => {
    const { lastUpdatedDateTime, userPhoneCountryCodeList, ...payload } = values;

    props.fetchUpdateProfile({ ...payload, phoneCountryCode: payload.phoneCountryCode?.code || null });
    setSubmitting(false);
  },
  enableReinitialize: true,
  validate: (values: ProfileDetails, { clearMyAccountErrors }: any) => {
    // hack to remove api errors on form changes
    clearMyAccountErrors();
    return {};
  },
  displayName: "EditProfilePage",
})(TabEditProfileBasic);

const mapStateToProps = (state: ApplicationState, _ownProps: any) => ({
  details: state.myAccountReducer.details,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUpdateProfile: (values: ProfileDetails) => dispatch(fetchUpdateProfile(values)),
  clearMyAccountErrors: () => dispatch(clearMyAccountErrors()),
});

export const TabEditProfile = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(formikEnhancer));
