import i18n from "i18next";
import { upperCaseSnakeCaseToReadable } from "../helpers/upper-case-snake-case-to-readable";
// ? TYPES:
import { AxiosError } from "axios";
import { ApiError } from "../store/types/api";
// import { NhsrAlert, EscalationAlertUpdate, EscalationAlert } from "../store/types/alerts-state";
// import { UpdatedIndicator } from "../store/types/personalised-indicators-state";
// import { IndicatorPersonalised } from "../types/indicator";

interface InputError {
  payload: {
    label: string;
    errorMessage: ApiError;
    error: AxiosError<any>;
  };
  type: "API_ERROR";
}

// interface InputErrorAny extends InputError {
//   [key: string]: any;
// }

type InputErrorAny = InputError & any;

interface OutputError {
  title?: string;
  body: string;
}

// const BOOL_CONTEXT = (bool: boolean) => (bool ? "ON" : "OFF");
// const UNKNOWN_CONTEXT = (message: ApiError) => (typeof message === "object" ? "UNKNOWN" : undefined);

const ERROR_TOASTS_SPECIAL: {
  [label: string]: (props: InputErrorAny, tstr: string | string[], universalError: string | undefined) => OutputError;
} = {
  UPDATE_PROFILE: (_props: InputError, tstr, universalError) => {
    if (universalError) {
      return { body: universalError };
    }
    return { body: i18n.t(tstr) };
  },
  REQUEST_REPORT: (action: InputErrorAny, tstr, _universalError) => {
    if (action.payload.errorMessage) {
      return { body: action.payload.errorMessage };
    }
    return { body: i18n.t(tstr) };
  },
  UPDATE_NHSR_ALERTS: (action: InputErrorAny, tstr, universalError) => {
    // pmw-119, not sure if should be here, but yolo
    if (action.payload.errorMessage) {
      return { body: action.payload.errorMessage };
    }
    if (universalError) {
      return { body: universalError };
    }
    return { body: i18n.t(tstr) };
  },
  REQUEST_MORE_INFO_WINTER_REPORT: (action: InputErrorAny, tstr, universalError) => {
    // pmw-126, same as above
    if (action.payload.errorMessage) {
      return { body: action.payload.errorMessage };
    }
    if (universalError) {
      return { body: universalError };
    }
    return { body: i18n.t(tstr) };
  },
  SAVE_UNSAVED_INDICATORS: (action: InputErrorAny, tstr, universalError) => {
    if (action.payload.error.request.status === 400) {
      const count = action.payload.errorMessage.length;
      return { body: i18n.t(tstr, { count }) };
    }
    if (universalError) {
      return { body: universalError };
    }
    return { body: "/" };
  },
};

export const getErrorToastBody = (action: InputErrorAny): Required<OutputError> => {
  const readableLabel = upperCaseSnakeCaseToReadable(action.payload.label.replace(/^SIMPLE_/, ""));
  const CONTEXT = typeof action.payload.errorMessage === "object" ? "UNKNOWN" : undefined;
  // console.log({ action, readableLabel, CONTEXT });
  const INITIAL_TITLE = i18n.t([`toastsError:${action.payload.label}.title`, "toastsError:default.title"], {
    name: readableLabel,
  });
  const GET_DEFAULT_BODY = (tstring: string | string[]) =>
    i18n.t(
      tstring,
      CONTEXT
        ? { context: CONTEXT }
        : {
            errorMessage: i18n.t(`httpStatusCodes:${action.payload.error.request?.status}.title`, {
              defaultValue: action.payload.errorMessage,
            }),
          }
    );
  const fn = ERROR_TOASTS_SPECIAL[action.payload.label];
  if (fn) {
    return {
      title: INITIAL_TITLE,
      ...fn(
        action,
        `toastsError:${action.payload.label}.body`,
        CONTEXT ? undefined : GET_DEFAULT_BODY(["toastsError:default.body"])
      ),
    };
  }
  return {
    title: INITIAL_TITLE,
    body: GET_DEFAULT_BODY([`toastsError:${action.payload.label}.body`, "toastsError:default.body"]),
  };
};
