import { apiAction } from "../api";
// import jsonpAdapter from "axios-jsonp";
import { POWERBI_REPORT_PARAMS, GET_EMBED_TYPE, TILE_DELIMITER } from "../../../globals/powerbi-settings";
import { generateParams } from "../../../helpers/generate-params";

// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";
// import { IndicatorServiceRequestParams } from "../../types/indicator-history-state";

const POWERBI_CODE = process.env.REACT_APP_API_POWERBICODE;
const POWERBI_API_SETTINGS = {
  baseURL: process.env.REACT_APP_API_POWERBI,
  headers: {
    Authorization: undefined,
    // "Content-Type:": "application/json",
  },
};

// JSONP:
// const getReportEmbedToken = (
//   { id, code = POWERBI_CODE }: { id: string; code?: string },
//   callbackName: string,
//   callbacks: ApiCallback
// ): ApiAction => {
//   const params = { id, code };
//   return apiAction({
//     ...POWERBI_API_SETTINGS,
//     adapter: jsonpAdapter,
//     callbackParamName: callbackName,
//     method: "GET",
//     // url: `/api/HttpTriggerCSharppbieEmbedSponsor${generateParams(params)}`,
//     url: `${generateParams(params)}`,
//     ...callbacks,
//   });
// };

const getReportEmbedToken = (
  { id, lng = "", code = POWERBI_CODE }: { id: string; lng?: string; code?: string },
  _callbackName: string,
  callbacks: ApiCallback
): ApiAction => {
  const [reportId, tId] = id.split(TILE_DELIMITER);
  const { language: languageParams, ...extraParams } = POWERBI_REPORT_PARAMS[id] || { language: {} };
  const extraParamsLanguageBased = languageParams ? languageParams[lng] || {} : {};
  const params = {
    id: reportId,
    tId: tId as string | undefined,
    code,
    embedType: GET_EMBED_TYPE(id),
    ...extraParams,
    ...extraParamsLanguageBased,
  };
  return apiAction({
    ...POWERBI_API_SETTINGS,
    method: "GET",
    url: `${generateParams(params)}`,
    ...callbacks,
  });
};

const PowerBIService = {
  getReportEmbedToken,
};

export default PowerBIService;
