import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers";
import cacheMiddleware from "./middleware/cache";
import apiMiddleware from "./middleware/api";
import errorMiddleware from "./middleware/error";
import successMiddleware from "./middleware/success";
import mapsMiddleware from "./middleware/maps";
import deleteCacheMiddleware from "./middleware/delete-cache";
//
import { fetchDeviceId } from "./actions/indexed-db";
import { fetchAuthorizeIfNeeded } from "./actions/auth";

const logoutMiddleware =
  ({ _getState, dispatch }: any) =>
  (next: any) =>
  (action: { type: string; [key: string]: any } = { type: "" }): any => {
    if (action.type === "LOG_OUT") {
      const result = next(action);
      dispatch(fetchDeviceId());
      return result;
    }
    return next(action);
  };

const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware, // lets us dispatch() functions
    apiMiddleware,
    cacheMiddleware,
    errorMiddleware,
    mapsMiddleware,
    successMiddleware,
    deleteCacheMiddleware,
    logoutMiddleware // must be after errorMiddleware
  )
);
store.dispatch(fetchDeviceId());
store.dispatch(fetchAuthorizeIfNeeded());

export default store;
