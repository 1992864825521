import { getRandomArbitrary } from "../../../helpers/random-generators";
//? TYPES
import { ManualUpdatesState } from "../../types/manual-updates-state";

export function generateDndMeta(
  indicators: ManualUpdatesState["indicators"],
  meta: ManualUpdatesState["meta"],
  totalFavourites: number
) {
  const maxNumber = meta.currentPage * meta.pageSize;
  // const isPrevDropAllowed = maxNumber % totalFavourites < meta.pageSize && meta.currentPage > 1;
  const isPrevDropAllowed = meta.currentPage > 1;
  const isNextDropAllowed = totalFavourites > maxNumber;
  return {
    isPrevDropAllowed,
    isNextDropAllowed,
    // indexFixer: isPrevDropAllowed ? -1 : 0,
    ids: [
      ...(isPrevDropAllowed ? ["prev"] : []),
      ...indicators.map((a) => a.indicatorId),
      ...(isNextDropAllowed ? ["next"] : []),
    ] as ("prev" | "next" | number)[],
  };
}

export function getFirstOrderId(
  { pageSize, currentPage }: ManualUpdatesState["meta"],
  totalFavourites: number
): number {
  const maxNumber = currentPage * pageSize;
  const prevPageMaxNumber = maxNumber - pageSize;
  if (prevPageMaxNumber <= totalFavourites) {
    return prevPageMaxNumber + 1;
  }
  return totalFavourites + 1;
}

export function moveItem<T>(
  arr: T[],
  fromIndex: number,
  toIndex: number,
  { defaultItem, overId }: { defaultItem?: T; overId?: number | "next" | "prev" } = {}
): T[] {
  const elem = arr[fromIndex];
  if (fromIndex > -1) {
    arr.splice(fromIndex, 1);
  }
  if (toIndex > -1) {
    arr.splice(toIndex, 0, elem);
  } else if (defaultItem) {
    const tempIndex = overId === "prev" ? 0 : arr.length;
    arr.splice(tempIndex, 0, defaultItem);
  }
  return arr;
}

export const GENERATE_EMPTY_INDICATOR = () =>
  ({
    isTemporary: true,
    indicatorId: -1 * getRandomArbitrary(1, 99),
    readableValue: ".",
    greyOut: false,
    indicatorDescription: "",
    isFavourite: true,
    organisationId: -1,
    organisationName: ".",
    indicatorName: ".",
    value: 0,
    valueTimestamp: new Date().toISOString(),
    valuePressureLevel: 1,
    thresholdList: [],
    updatedBy: {
      id: -1,
      username: "",
      fullname: "",
    },
    dataType: 1,
    valueColorName: "blue",
    valueColorCodeBg: "",
    valueColorCodeFg: "",
    // comment?: string;
    // newValue?: number | string | "";
    // newValueTimestamp?: Date; //TODO
    // newValueColour?: BRAG; // ? UI value only
  } as ManualUpdatesState["indicators"][0]);
