export const FILE_TYPES = {
  ".doc": "word",
  ".docx": "word",
  ".txt": "text",
  ".md": "text",
  ".rtf": "text",
  ".xls": "excel",
  ".xlsx": "excel",
  ".csv": "table",
  ".ppt": "powerpoint",
  ".pptx": "powerpoint",
  ".pdf": "acrobat",
  ".gif": "image",
  ".png": "image",
  ".jpg": "image",
  ".jpeg": "image",
  ".mp4": "video",
  ".mov": "video",
  ".avi": "video",
  ".mkv": "video",
  ".flv": "video",
  ".wmv": "video",
  ".html": "html",
  ".zip": "zipe",
  ".rar": "zipe",
  ".7z": "zipe",
  ".gz": "zipe",
  ".mp3": "sound",
  ".wav": "sound",
  ".m4a": "sound",
  ".wma": "sound",
};
