import React from "react";
import { Marker } from "../improved/Marker";
// import { markerShadowAsDataUri } from "../icons/MarkerShadow.component";
import { markerOutlineAsDataUri } from "../icons/MarkerOutline.component";
// import { BRAG_COLOURS, FACILITY_TYPE_TO_TEXT_MAPPING, BRAG_PRESSURE_LEVEL_MAPPING } from "../globals";
// import { FACILITY_ICON_SIZE, FACILITY_RADIUS } from "../settings";
import { DEFAULT_CONFIG, generateConfigWithDefaultValues } from "./facilities";
import { FACILITY_TYPE_TO_TEXT_MAPPING } from "../consts";

// ? TYPES:
import { ExpandedMarker } from "../../../types/google-map";
import { MarkerRequired } from "../../../store/types/maps-state";
import { TConfig } from "./facilities";
// type FacilityMarkerOptions = MapsHospital;

const DEFAULT_ZINDEX = 4;
interface Props {
  facilities: MarkerRequired[];
  currentFacilityHover: ExpandedMarker | null;
  strokeDashoffset?: number;
  // ieVersion?: { type: "ie" | "edge" | null; version: number };
  hoverKeyCheckName?: string;
  extra?: number;
  newStrokeWidth?: number;
  config?: { default: TConfig; [typeAcronym: string]: TConfig };
  facilityUniqueId?: string;
}

const shadowsList = ({
  facilities,
  currentFacilityHover,
  // ieVersion = (window as any).browser || { type: null, version: 0 },
  hoverKeyCheckName = "organisationName",
  strokeDashoffset = 0,
  extra = 20,
  newStrokeWidth = 4,
  config = {
    default: DEFAULT_CONFIG,
  },
  facilityUniqueId = "idMapMarker",
}: Props): JSX.Element[] => {
  const configs = generateConfigWithDefaultValues(config, DEFAULT_CONFIG);
  return facilities.map((facility: MarkerRequired) => {
    // console.log(facility);
    const isHovered =
      currentFacilityHover && currentFacilityHover[hoverKeyCheckName] === (facility as any)[hoverKeyCheckName] ? 1 : 0;
    const getConfigValue = (value: [number] | [number, number], isHovered: number): number => {
      return value[isHovered] || value[0];
    };
    const zIndex = DEFAULT_ZINDEX;
    const typeAcronym =
      facility.markerText ||
      (facility.type
        ? (FACILITY_TYPE_TO_TEXT_MAPPING as any)[facility.type] ||
          (FACILITY_TYPE_TO_TEXT_MAPPING as any)["idMarkerType_" + facility.idMarkerType] ||
          "default"
        : "default");
    const cBackup = configs[typeAcronym] || configs["default"];
    const markerSetting = facility.markerSetting || {};
    const c = {
      ...cBackup,
      ...markerSetting,
    };
    // const colour = BRAG_PRESSURE_LEVEL_MAPPING[facility.pressureLevel];
    // const bragOptions = BRAG_COLOURS[colour];
    const s = getConfigValue(c.strokeWidth, isHovered);
    const w = getConfigValue(c.width, isHovered) + extra;
    const o = getConfigValue(c.offset, isHovered);
    const b = getConfigValue(c.boxHeight, isHovered) + extra;
    const iconSettings = {
      strokeDashoffset: isHovered ? strokeDashoffset : 0,
      config: {
        strokeWidth: newStrokeWidth,
        width: w,
        offset: o,
        boxHeight: b,
        radius: getConfigValue(c.radius, isHovered),
      },
    };
    const ICON_WIDTH = (w + s * 2) * 1;
    const ICON_HEIGHT = (b + o + s * 2) * 1;
    return (
      <Marker
        // opacity={1}
        visible={!!isHovered}
        key={facility[facilityUniqueId]}
        name={facility.organisationName}
        position={{ lat: facility.latitude, lng: facility.longitude }}
        optimized={false}
        clickable={false}
        icon={{
          url: markerOutlineAsDataUri(iconSettings),
          size: [ICON_WIDTH, ICON_HEIGHT], // ie 11
          scaledSize: [ICON_WIDTH, ICON_HEIGHT], // ie 11
          origin: [0, 0],
          anchor: [ICON_WIDTH / 2, ICON_HEIGHT - extra / 2],
          // anchor: [ICON_WIDTH / 2, ICON_HEIGHT]
        }}
        zIndex={zIndex}
      />
    );
  });
};

export default shadowsList;
