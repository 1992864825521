import { apiAction } from "../../api";
import { SERVICE_URLS } from "../../../../globals/service-urls";
import { generateParams } from "../../../../helpers/generate-params";
// import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";
// ? TYPES:
import { ElectiveData } from "../../../types/maps-elective";
import { ApiCallback, ApiAction } from "../../../types/api";

// import { PaginatedParams } from "../../../types/paginated-params";

const VERSION = 1;

export const getElectiveInitial = ({ vecId }: { vecId: number }, callbacks: ApiCallback): ApiAction => {
  const params = { idVec: vecId };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["elective-service"]}${VERSION}/elective/data${generateParams(params)}`,
    ...callbacks,
  });
};

export const getElectiveMarkers = (
  { vecId }: { vecId: number },
  { filters }: { filters: Omit<ElectiveData, "ccgs" | "markers" | "mapSetting"> },
  callbacks: ApiCallback
): ApiAction => {
  const params = { idVec: vecId };
  return apiAction({
    method: "POST",
    data: filters,
    url: `${SERVICE_URLS["elective-service"]}${VERSION}/elective/data${generateParams(params)}`,
    ...callbacks,
  });
};
