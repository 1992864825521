import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiLoading } from "../default-api-loading";
import { defaultApiError } from "../default-api-error";
// import { LOCAL_updateGreyIndicatorBatchDeep } from "../../utils/local-update-grey-indicators";
// ? TYPES:
import {
  PersonalisedIndicatorsState,
  UpdatedIndicator,
  PersonalisedIndicator,
} from "../../types/personalised-indicators-state";
import { TSelectedIndicators } from "../../../pages/home/modal-indicator-setup";

const defaultState: PersonalisedIndicatorsState = {
  indicatorGroups: [],
  // ccgs: [],
  // agencies: {},
  providers: [],
  indicators: {},
  isFetching: {},
  didInvalidate: {
    GET_PERSONALISED_INDICATORS: true,
    // GET_CCGS: true,
    GET_PROVIDERS: true,
  },
  errors: {},
};

const mapUpdatedIndicatorsToObject = (
  indicatorList: UpdatedIndicator[]
): { [indicatorId: number]: UpdatedIndicator } => {
  return indicatorList.reduce((final: { [indicatorId: number]: UpdatedIndicator }, current: UpdatedIndicator) => {
    final[current.indicatorId] = current;
    return final;
  }, {});
};

const personalisedIndicatorsReducer = (
  state: PersonalisedIndicatorsState = defaultState,
  action: any
): PersonalisedIndicatorsState => {
  switch (action.type) {
    case API_START: {
      return defaultApiLoading(state, action, true, [
        "GET_PERSONALISED_INDICATORS",
        "GET_PROVIDERS",
        // "GET_CCGS",
        // "GET_AGENCY",
        "GET_INDICATORS_LIST",
        "ADD_NEW_INDICATOR_GROUP",
        "TOGGLE_INDICATOR_ALERTS",
      ]);
    }
    case API_ERROR: {
      return defaultApiError(state, action);
    }
    case API_END: {
      return defaultApiLoading(
        state,
        action,
        false,
        [
          "GET_PERSONALISED_INDICATORS",
          "GET_PROVIDERS",
          // "GET_CCGS",
          // "GET_AGENCY",
          "GET_INDICATORS_LIST",
          "ADD_NEW_INDICATOR_GROUP",
          "TOGGLE_INDICATOR_ALERTS",
        ],
        true
      );
    }
    case "GET_PERSONALISED_INDICATORS": {
      // console.log(action.data.results);
      return {
        ...state,
        indicatorGroups: action.data.results,
      };
    }
    case "GET_PROVIDERS": {
      // console.log({ action });
      return {
        ...state,
        providers: action.data.results,
      };
    }
    // case "GET_CCGS": {
    //   return {
    //     ...state,
    //     ccgs: action.data.results,
    //   };
    // }
    // case "GET_AGENCY": {
    //   return {
    //     ...state,
    //     agencies: {
    //       ...state.agencies,
    //       [action.ccgId]: action.data.results,
    //     },
    //   };
    // }
    case "GET_INDICATORS_LIST": {
      const initialIndicators: TSelectedIndicators = action.initialIndicators || {};
      const indicatorsSorted =
        Object.keys(initialIndicators).length > 0
          ? action.data.results.sort((a: PersonalisedIndicator, b: PersonalisedIndicator) => {
              if (initialIndicators[a.indicatorId] && !initialIndicators[b.indicatorId]) {
                return -1;
              }
              if (initialIndicators[a.indicatorId] && initialIndicators[b.indicatorId]) {
                return 1;
              }
              return 0;
            })
          : action.data.results;
      return {
        ...state,
        indicators: {
          ...state.indicators,
          [action.organisationId]: indicatorsSorted,
        },
      };
    }
    case "ADD_NEW_INDICATOR_GROUP": {
      // console.log({ action });
      // ? no way to know indicator values after success, so return state and update ui somewhere else later
      return state;
    }
    case "TOGGLE_INDICATOR_ALERTS": {
      const organisationId = action.data.organisationId;
      const changedIndicators = mapUpdatedIndicatorsToObject(action.data.indicatorList);
      return {
        ...state,
        indicatorGroups: state.indicatorGroups.map((group) => {
          if (group.organisationId === organisationId) {
            return {
              ...group,
              indicatorList: group.indicatorList.map((indicator) => {
                const updatedIndicator = changedIndicators[indicator.indicatorId];
                if (updatedIndicator) {
                  return {
                    ...indicator,
                    alertEnabled: updatedIndicator.alertEnabled,
                  };
                }
                return indicator;
              }),
            };
          }
          return group;
        }),
      };
    }
    case "DELETE_INDICATORS_FROM_GROUP": {
      const organisationId = action.data.organisationId;
      const currentIndicatorGroup = state.indicatorGroups.find((group) => group.organisationId === organisationId);
      if (currentIndicatorGroup) {
        const changedIndicators = mapUpdatedIndicatorsToObject(action.data.indicatorList);
        const newList: any = currentIndicatorGroup.indicatorList
          .map((indicator) => {
            const updatedIndicator = changedIndicators[indicator.indicatorId];
            if (updatedIndicator) {
              return undefined;
            }
            return indicator;
          })
          .filter((indicator) => indicator !== undefined);
        return {
          ...state,
          indicatorGroups: state.indicatorGroups
            .map((group) => {
              if (group.organisationId === organisationId) {
                return {
                  ...group,
                  indicatorList: newList,
                };
              }
              return group;
            })
            .filter((group) => group.indicatorList.length !== 0),
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default personalisedIndicatorsReducer;
