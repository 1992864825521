export const IntegrationPlot = {
  TABLEAU: 2,
} as const;

export const IntegrationTypePlot = {
  DASHBOARD: 1,
  MENU: 3,
} as const;

type ValueOf<T> = T[keyof T];
export type TIntegrationId = ValueOf<typeof IntegrationPlot>;
export type TIntegrationTypeId = ValueOf<typeof IntegrationTypePlot>;
export type TIntegrationKey = keyof typeof IntegrationPlot;
export type TIntegrationTypeKey = keyof typeof IntegrationTypePlot;
