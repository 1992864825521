import React from "react";
import { connect } from "react-redux";

// import { fetchUpdateDocumentMeta } from "../../store/actions/documents";

import { withFormik } from "formik";
import { FormikInput } from "../../components/formik";
import { documentEditSchema } from "../../schemas/document-edit.schema";

import { Button } from "@tscore/react-components";
import { withTranslation } from "react-i18next";
// import { joinErrors } from "../../helpers/join-errors";

// ? TYPES:
import { TFunction } from "i18next";
// import { Document } from "../../types/document";
import { EditDocumentMeta } from "../../schemas/document-edit.schema";
// import { ApplicationState } from "../../store/reducers";
// import { RouteComponentProps } from "react-router-dom";
interface ModalEditBasicProps {
  values: EditDocumentMeta;
  fetchUpdateDocumentMeta: Function;
  t: TFunction;
  // id: number;
  // document: Document;
  [key: string]: any; // formik other
}

const ModalEditBasic: React.FC<ModalEditBasicProps> = ({
  id,
  document,
  isEditing,
  isSubmitting,
  handleSubmit,
  values,
  errors,
  fetchUpdateDocumentMeta,
  t,
  ..._props
}) => {
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <header>
          <h4>{t("documents:Edit Details")}</h4>
          <h6>{t("documents:Edit / View document properties")}</h6>
        </header>
        <article>
          <div className="form">
            <section className="form-row">
              <div className="form-field">
                <label>{t("documents:Document Name")}</label>
                <div className="labeled-input right">
                  <FormikInput
                    name="documentName"
                    autoComplete="off"
                    value={values.documentName}
                    placeholder={t("documents:Document Name")}
                    maxLength={250}
                  />
                  <span className="label doc-extension">.{document.fileExtension}</span>
                </div>
                <div className="form-error is-visible">{errors["documentName"]}</div>
              </div>
            </section>
            <section className="form-row">
              <div className="form-field">
                <label>{t("documents:Description")}</label>
                <FormikInput
                  name="description"
                  autoComplete="off"
                  value={values.description}
                  isTextarea
                  placeholder={t("documents:Description")}
                  style={{ minHeight: "96px" }}
                />
                <div className="form-error is-visible">{errors["description"]}</div>
              </div>
            </section>
          </div>
        </article>
        <footer className="ta-right">
          <Button colour="link" data-close-modal>
            {t("generic:Cancel")}
          </Button>
          <Button disabled={Object.keys(errors).length > 0} type="submit" isLoading={isSubmitting || isEditing}>
            {t("generic:Save")}
          </Button>
        </footer>
      </form>
    </div>
  );
};

const formikEnhancer = withFormik({
  validationSchema: ({ t }: { t: TFunction }) => documentEditSchema(t),
  mapPropsToValues: ({ document }: { document: EditDocumentMeta; [key: string]: any }) => ({
    documentId: document.documentId,
    documentName: document.documentName,
    description: document.description,
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { documentId, documentName, description } = values;
    const payload = {
      documentId,
      documentName,
      description,
    };
    // console.log(props.id, payload);
    setSubmitting(false);
    props.fetchUpdateDocumentMeta(props.id, payload);
  },
  enableReinitialize: true,
  validate: (_values: EditDocumentMeta, _obj: any) => {
    // hack to remove api errors on form changes
    // _obj.clearMyAccountErrors();
    return {};
  },
  displayName: "ModalEdit",
})(ModalEditBasic as any);

// const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
//   details: state.myAccountReducer.details,
// });

// const mapDispatchToProps = (dispatch: any) => ({
//   fetchUpdateDocumentMeta: (id: number, values: EditDocumentMeta) => dispatch(fetchUpdateDocumentMeta(id, values)),
//   // clearMyAccountErrors: () => dispatch(clearMyAccountErrors()),
// });

export const ModalEdit = connect(null, null)(withTranslation()(formikEnhancer));
