import React, { useState } from "react";
import { useSimpleApi } from "../../hooks/use-simple-api";
import { Redirect } from "react-router-dom";
import { Button } from "@tscore/react-components";
import { accessEnv } from "../../access-env";
import { SERVICE_URLS } from "../../globals/service-urls";
import { WINTER_REPORT_SERVER_NAME } from "./consts";
// ? TYPES:
// import { RouteComponentProps } from "react-router-dom";

const AREAS_WITH_NARRATIVE: { [key: string]: true } = {
  78: true, // Nottinghamshire
};

interface Props {
  id: "all" | number;
  to: string;
}

export const SpecialNarrative: React.FC<Props> = ({ id, to }) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const hasNarrative = accessEnv("DEPLOYMENT_TYPE", "uk") === "uk" && AREAS_WITH_NARRATIVE[id];
  const { response, status } = useSimpleApi(
    {
      method: "GET",
      url: `${SERVICE_URLS["nhsr-service"]}2/${WINTER_REPORT_SERVER_NAME}/narrative`,
      label: "SIMPLE_SAVE_WINTER_REPORT_NARRATIVE",
    },
    { enabled: isEnabled }
  );
  if (!hasNarrative) {
    return null;
  }
  if (status !== "success") {
    return (
      <Button isLoading={status === "loading"} colour="green-outline" onClick={() => setIsEnabled(true)}>
        New Report from narrative
      </Button>
    );
  }
  return <Redirect to={to.replace("$REPORT_ID", response.reportId)} />;
};
