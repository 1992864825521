import { ImportedOtherReportOrganisation } from "../../types/menu-state";

export const generateOtherReportMenu = (
  finalOne: any,
  one: any,
  url: string,
  twoMenu: any[],
  isResilience: boolean
) => {
  const groupedReports = twoMenu!.reduce(
    (finalG: { [key: string]: ImportedOtherReportOrganisation[] }, report: any) => {
      const n = (report as ImportedOtherReportOrganisation).reportTypeId;
      if (!finalG[n]) {
        finalG[n] = [];
      }
      finalG[n].push(report);
      return finalG;
    },
    {}
  );
  Object.entries(groupedReports).forEach(([key, value]) => {
    if (isResilience === false && value.filter((v) => v.feature?.OTHER_REPORT_REQUESTER === true).length === 0) {
      // ? rba check for nhsr
      return finalOne;
    }
    const topUrl = isResilience ? url + "/any/" + key : url + "/all/" + key;
    const reportName = value[0].reportTypeName;
    const reportNameSearchParam = `?reportName=${encodeURIComponent(reportName)}`;
    finalOne!.push({
      name: value[0].reportTypeName,
      url: topUrl + reportNameSearchParam,
      inverted: one.inverted,
      menu: isResilience ? value.map((item: any) => ({ ...item, url: item.url + reportNameSearchParam })) : null,
    });
  });
  return finalOne;
};
