import React, { useEffect } from "react";
import { withFormik } from "formik";
import { Redirect } from "react-router-dom";
import { Toast } from "@tscore/react-components";
import { Step } from "./../step";
import { Verify } from "./../verify";
import { ResendCode } from "../resend-code";
// import { FormikInput } from "../../../components/formik/formik-input";
import history from "../../../../history";
// import classNames from "classnames";
import { MFAIcons } from "./../icons";
import { setupMfaSchemaDefault } from "../../../../schemas/login.schema";
import { fetchLogIn, fetchResendCodeIfNeeded, fetchResendCode } from "../../../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { decodeParams } from "../../../../helpers/params";
import { joinErrors } from "../../../../helpers/join-errors";
import { LOGIN_URL } from "../../consts";
// ? TYPES:
import { ApplicationState } from "../../../../store/reducers";
import { TFunction } from "i18next";
import { SelectedFormikProps } from "../../../../types/login-form";
import { MFAState, MFAType, PreloginEnforce } from "../../../../store/types/credentials-state";
import { BackupMethod } from "./backup-method";

const mfaEnforceState = {
  mfaCode: "",
  rememberDevice: false,
};

interface Props extends SelectedFormikProps<typeof mfaEnforceState> {
  url: string;
  searchParams: { type?: MFAType; [key: string]: any };
  onInputChange: () => any;
  isFetching: boolean;
  match: any;
  listOfErrors: string[];
  mfaState: MFAState<PreloginEnforce>;
  // fieldErrorsWithApi: { [error: string]: string };
  // [k: string]: any;
}

export const FormMfaEnforce = ({
  searchParams,
  resetForm,
  values,
  handleSubmit,
  setFieldValue,
  onInputChange,
  isFetching,
  isSubmitting,
  mfaState,
  setFieldError,
  errors,
}: // fieldErrorsWithApi,
Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("login");
  const apiErrors = useSelector((state: ApplicationState) => state.credentialsReducer.errors);
  useEffect(() => {
    resetForm();
    const type = searchParams.type;
    if (type && mfaState.info.options.includes(type)) {
      // setResent(false);
      dispatch(fetchResendCodeIfNeeded(type));
    }
  }, [searchParams.type, dispatch, resetForm, mfaState.info.options]);
  const mfaType = searchParams.type || mfaState.info.options[0];
  const recipient = mfaType === "SMS" ? mfaState.info.obscuredMobile : mfaState.info.obscuredEmail;
  const fieldErrorsWithApi = joinErrors(
    errors as { [k: string]: string },
    apiErrors["LOG_IN"] ? [{ field: "mfaCode", error: apiErrors["LOG_IN"] }] : []
  );
  const anotherMethod: MFAType | undefined = mfaState.info.optionsNextMap[mfaType];
  const hasResendCode = mfaType !== "TOTP";
  const expiryTimestamp = mfaState.resend[mfaType];
  const onResendCode = () => {
    // setResent(true);
    dispatch(
      fetchResendCode(mfaType, true, () => {
        Toast(t("mfa.general.resendCodeSuccess", { returnObjects: true, defaultValue: {} }), "success", {
          autoClose: 2000,
        });
      })
    );
    // setTimeout(() => {
    //   setResent(false);
    // }, 20000);
  };
  const setValue: SelectedFormikProps["setFieldValue"] = (field, value, shouldValidate) => {
    onInputChange();
    setFieldValue(field, value, shouldValidate);
    setFieldError(field, undefined);
  };
  if (mfaState.info.requirement !== "ENFORCE" || !mfaState.info.options.includes(mfaType)) {
    return <Redirect to={LOGIN_URL} />;
  }
  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="box">
        <Step no={MFAIcons[mfaType]}>
          <Verify
            isLoading={isSubmitting || isFetching}
            error={fieldErrorsWithApi["mfaCode"]}
            values={values}
            setValue={setValue}
            rememberDays={7}
            autoFocus={true}>
            <p>
              <Trans ns="login" i18nKey={`mfa.enforce.${mfaType}.step`} values={{ recipient }}></Trans>
            </p>
          </Verify>
        </Step>
      </div>
      <div className="ta-center">
        <p className="bold">
          {hasResendCode && (
            <ResendCode
              textKey="login:mfa.general.resendCode"
              disabledTextKey="login:mfa.general.resendCodeDisabled"
              onSend={onResendCode}
              expiryTimestamp={expiryTimestamp}
            />
          )}
          {anotherMethod && <BackupMethod mfaType={anotherMethod} lowercase={hasResendCode} />}
        </p>
      </div>
    </form>
  );
};

export const formikEnhancerMfaEnforce = withFormik({
  validationSchema: ({ t }: { t: TFunction }) => setupMfaSchemaDefault(t),
  mapPropsToValues: (_props: any) => mfaEnforceState,
  handleSubmit: (values, { setSubmitting, props }) => {
    const payload = values;
    const searchParams = decodeParams(props.location.search);
    props.fetchLogIn(undefined, payload, searchParams.type, history.location.pathname);
    setSubmitting(false);
  },
  validateOnChange: false,
  displayName: "MfaEnforcePage",
});

export const mapDispatchToPropsMfaEnforce = (dispatch: any) => ({
  fetchLogIn: (_: undefined, values: typeof mfaEnforceState, mfaType: MFAType | undefined, pathname = "") =>
    dispatch(fetchLogIn(_, values, mfaType, pathname)),
});
