import React from "react";
import Meta from "../../../../components/meta";
import HomeMap from "./home-map";
// import { Loader } from "@tscore/react-components";
import { useTranslation } from "react-i18next";

//? TYPES:
import { RouteComponentProps } from "react-router-dom";

type HomePageProps = RouteComponentProps<any>;

// const HomeMap = (_props: any) => <div>elective_home</div>;

export const MapPage: React.FC<HomePageProps> = ({ history, match }) => {
  const { t } = useTranslation();
  const id = parseInt(match.params.id, 10) || -1;
  // const dispatch = useDispatch();
  return (
    <main id="elective-home" className="google-map-holder" style={{ padding: 0 }}>
      <Meta title={t("electiveHome:title")} hideMeta hideFooter />
      <HomeMap history={history} id={id} />
      {/* <style dangerouslySetInnerHTML={{ __html: `#nhsr-home > div {height:100%;}` }} /> */}
    </main>
  );
};
