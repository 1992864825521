import { getDeploymentConfig } from "../access-env";
import { AdvancedFilter } from "powerbi-models";
// ? TYPES:
import { IEmbedSettings } from "powerbi-client";
import { IAdvancedFilter } from "powerbi-models";
type EmbedType = "report" | "dashboard" | "tile";

// const importPowerBiModels = async () => {
//   return await import(/* webpackChunkName: "pbi-models" */ "powerbi-models");
// };

export const TILE_DELIMITER = "_";

const REPORT_IDS = {
  uk: {
    ESCALATION: "54638f1a-70b2-4ce0-a84e-cea6125d3d45",
    SAFER: "74397822-4f1b-4f51-9a70-0727f09692c0",
    INDICATOR_NOT_UPDATED: "b26888a9-d905-4ad2-b956-4b7b881bfee9",
    WINTER_REPORT: "ce95d9ce-ea5f-43ae-b96c-cbfa3d69abaa",
    TILE_SAMPLE: "bd98ab14-2117-47ec-8374-a718ab8b39be" + TILE_DELIMITER + "a249862b-cb19-4ed9-a7f8-db3b8f93a97f",
    X_SAMPLE: "6037d3ae-9f2a-4194-8f9e-0682306dbf6c",
    DASHBOARD_SAMPLE: "bd98ab14-2117-47ec-8374-a718ab8b39be",
    INDICATOR_OVERVIEW_PAGINATED_REPORT: "ca13b486-c5d9-45e1-9802-08ac4724a9d8",
    INDICATOR_OVERVIEW_ALL_SEGMENTS_PAGINATED_REPORT: "59ad09fc-d219-488a-b0fd-aff5e6cbde05",
  },
  ca: {
    ESCALATION: "dfe6bd9e-9fef-41f6-aaf5-1b8b1330a065",
    SAFER: "N/A",
    INDICATOR_NOT_UPDATED: "be2945dc-75b6-45c6-a51d-4a9aa9dbaec6",
    WINTER_REPORT: "95627447-c151-4552-9965-ea097b6be51f",
    TILE_SAMPLE: "N/A",
    X_SAMPLE: "6f31b369-e7a2-4168-88ce-37c84c0283ee",
    DASHBOARD_SAMPLE: "bd98ab14-2117-47ec-8374-a718ab8b39be",
    INDICATOR_OVERVIEW_PAGINATED_REPORT: "ed0fac3c-a13b-4402-b65b-6c0bc09b93ae",
    INDICATOR_OVERVIEW_ALL_SEGMENTS_PAGINATED_REPORT: "27603e5a-5e74-415c-9fd5-137f2f1c905a",
  },
};

export const POWERBI_REPORT_ID = getDeploymentConfig(REPORT_IDS);

const REPORT_PARAMS = {
  uk: {
    [POWERBI_REPORT_ID.ESCALATION]: {
      ws: "0a62a140-a3b5-4141-a9e3-a2954f7d3bcc",
    },
    [POWERBI_REPORT_ID.SAFER]: {
      ws: "0a62a140-a3b5-4141-a9e3-a2954f7d3bcc",
    },
    [POWERBI_REPORT_ID.INDICATOR_NOT_UPDATED]: {
      ws: "0a62a140-a3b5-4141-a9e3-a2954f7d3bcc",
    },
    [POWERBI_REPORT_ID.WINTER_REPORT]: {
      ws: "0a62a140-a3b5-4141-a9e3-a2954f7d3bcc",
    },
    [POWERBI_REPORT_ID.TILE_SAMPLE]: {
      ws: "a9417425-43b5-4084-ad79-9cf14284d9fb",
      // tId: "a249862b-cb19-4ed9-a7f8-db3b8f93a97f",
    },
    [POWERBI_REPORT_ID.X_SAMPLE]: {
      ws: "a9417425-43b5-4084-ad79-9cf14284d9fb",
    },
    [POWERBI_REPORT_ID.DASHBOARD_SAMPLE]: {
      ws: "a9417425-43b5-4084-ad79-9cf14284d9fb",
    },
    [POWERBI_REPORT_ID.INDICATOR_OVERVIEW_PAGINATED_REPORT]: {
      ws: "0a62a140-a3b5-4141-a9e3-a2954f7d3bcc",
    },
    [POWERBI_REPORT_ID.INDICATOR_OVERVIEW_ALL_SEGMENTS_PAGINATED_REPORT]: {
      ws: "0a62a140-a3b5-4141-a9e3-a2954f7d3bcc",
    },
  },
  ca: {
    [POWERBI_REPORT_ID.ESCALATION]: {
      ws: "097aa066-7803-47ce-b379-c19519ab37c5",
    },
    [POWERBI_REPORT_ID.SAFER]: {},
    [POWERBI_REPORT_ID.INDICATOR_NOT_UPDATED]: {
      ws: "097aa066-7803-47ce-b379-c19519ab37c5",
    },
    [POWERBI_REPORT_ID.WINTER_REPORT]: {
      ws: "097aa066-7803-47ce-b379-c19519ab37c5",
    },
    [POWERBI_REPORT_ID.TILE_SAMPLE]: {},
    [POWERBI_REPORT_ID.X_SAMPLE]: {
      ws: "a9417425-43b5-4084-ad79-9cf14284d9fb",
    },
    [POWERBI_REPORT_ID.DASHBOARD_SAMPLE]: {
      ws: "a9417425-43b5-4084-ad79-9cf14284d9fb",
    },
    [POWERBI_REPORT_ID.INDICATOR_OVERVIEW_PAGINATED_REPORT]: {
      ws: "097aa066-7803-47ce-b379-c19519ab37c5",
    },
    [POWERBI_REPORT_ID.INDICATOR_OVERVIEW_ALL_SEGMENTS_PAGINATED_REPORT]: {
      ws: "097aa066-7803-47ce-b379-c19519ab37c5",
    },
  },
};

export const POWERBI_REPORT_PARAMS: {
  [key: string]: { ws?: string; language?: { [key: string]: string }; [key: string]: any };
} = getDeploymentConfig(REPORT_PARAMS);

//

const REPORT_EMBED_PARAMS = {
  uk: {
    [POWERBI_REPORT_ID.ESCALATION]: {
      language: {
        "en-GB": {
          pageName: "ReportSectionc3c588dbaaca36501351",
        },
        "fr-CA": {
          pageName: "ReportSection587d064380d0099052a2",
        },
      },
    },
    [POWERBI_REPORT_ID.INDICATOR_NOT_UPDATED]: {
      language: {
        "en-GB": {
          pageName: "ReportSectionefad255a4588c1b3920f",
        },
        "fr-CA": {
          pageName: "ReportSection2aeb9c6981376b82b0bb",
        },
      },
    },
    [POWERBI_REPORT_ID.WINTER_REPORT]: {
      language: {
        "en-GB": {
          pageName: "ReportSection9d7603a8370bd40e4ddc",
        },
        "fr-CA": {
          pageName: "ReportSection908d11c4ee3461c5888e",
        },
      },
    },
  },
  ca: {
    [POWERBI_REPORT_ID.ESCALATION]: {
      language: {
        "en-GB": {
          pageName: "ReportSection3fe9eb54029625352880",
        },
        "fr-CA": {
          pageName: "ReportSectionc3c588dbaaca36501351",
        },
      },
    },
    [POWERBI_REPORT_ID.INDICATOR_NOT_UPDATED]: {
      language: {
        "en-GB": {
          pageName: "ReportSectionc0e61aed8669cc404001",
        },
        "fr-CA": {
          pageName: "ReportSectionefad255a4588c1b3920f",
        },
      },
    },
    [POWERBI_REPORT_ID.WINTER_REPORT]: {
      language: {
        "en-GB": {
          pageName: "ReportSection9d7603a8370bd40e4ddc",
        },
        "fr-CA": {
          pageName: "ReportSection908d11c4ee3461c5888e",
        },
      },
    },
  },
};

export const POWERBI_REPORT_EMBED_PARAMS = getDeploymentConfig(REPORT_EMBED_PARAMS);

//

export const GET_EMBED_TYPE = (id: string): EmbedType => {
  const REPORT_TYPES: { [id: string]: EmbedType } = {
    [POWERBI_REPORT_ID.TILE_SAMPLE]: "tile",
    [POWERBI_REPORT_ID.DASHBOARD_SAMPLE]: "dashboard",
  };
  return REPORT_TYPES[id] || "report";
};

// const ADVANCED_SCHEMA_URL = "http://powerbi.com/product/schema#advanced";
const BASIC_SCHEMA_URL = "http://powerbi.com/product/schema#basic";

export const POWERBI_FILTERS: { [reportId: string]: (props: any) => IAdvancedFilter | any } = {
  [POWERBI_REPORT_ID.ESCALATION]: ({ dashboardId }: { dashboardId: number }) => {
    //   return {
    //     $schema: AdvancedSchemaUrl,
    //     target: {
    //       table: "core escalationActionFact",
    //       column: "idSystemDashboard",
    //     },
    //     logicalOperator: "And",
    //     conditions: [
    //       {
    //         operator: "Is",
    //         value: dashboardId,
    //       },
    //     ],
    //   };
    // const { AdvancedFilter } = await importPowerBiModels();
    return new AdvancedFilter(
      {
        table: "dw actionStatusFact",
        column: "idPdDashboard",
      },
      "And",
      [
        {
          operator: "Is",
          value: dashboardId,
        },
      ]
    );
  },
  // [POWERBI_REPORT_ID.SAFER]: ({ labelId }: { labelId: number }) => {
  //   return new models.AdvancedFilter(
  //     {
  //       table: "saferUpdate",
  //       column: "idOrganisation",
  //     },
  //     "And",
  //     [
  //       {
  //         operator: "Is",
  //         value: labelId,
  //       },
  //     ]
  //   );
  // },
  [POWERBI_REPORT_ID.INDICATOR_NOT_UPDATED]: ({ siteId }: { siteId: number }) => {
    return {
      $schema: BASIC_SCHEMA_URL,
      target: {
        table: "dw pdSite",
        column: "idPdSite",
      },
      operator: "In",
      values: [siteId],
      displaySettings: {
        isLockedInViewMode: true,
        displayName: "ID",
      },
    };
  },
  [POWERBI_REPORT_ID.WINTER_REPORT]: ({ siteId }: { siteId: number }) => {
    return {
      $schema: BASIC_SCHEMA_URL,
      target: {
        table: "dw pdSite",
        column: "idPdSite",
      },
      operator: "In",
      values: [siteId],
      displaySettings: {
        isLockedInViewMode: true,
        displayName: "ID",
      },
    };
  },
};

export const POWERBI_SETTINGS: { [reportId: string]: IEmbedSettings } = {
  [POWERBI_REPORT_ID.ESCALATION]: {
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
    panes: {
      filters: {
        expanded: false,
        visible: false,
      },
    },
    // background: models.BackgroundType.Transparent,
  },
  [POWERBI_REPORT_ID.SAFER]: {
    filterPaneEnabled: false,
    navContentPaneEnabled: true,
  },
  [POWERBI_REPORT_ID.INDICATOR_NOT_UPDATED]: {
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
    //pageName: 'en-GB', // https://stackoverflow.com/questions/42774398/how-to-set-default-page-in-power-bi-embedded-report
  },
  [POWERBI_REPORT_ID.WINTER_REPORT]: {
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
  },
};

export const encodePowerBIParams = (params: { [key: string]: any }) => {
  return encodeURIComponent(btoa(JSON.stringify(params)));
};

export const decodePowerBIParams = (urlValue: string): { [key: string]: any } => {
  const decodedURI = decodeURIComponent(urlValue);
  const stringified = atob(decodedURI);
  try {
    return JSON.parse(stringified);
  } catch {
    return {};
  }
};

export const HIDE_ALL_ICONS: { [reportId: string]: boolean } = {
  [POWERBI_REPORT_ID.INDICATOR_OVERVIEW_PAGINATED_REPORT]: true,
  [POWERBI_REPORT_ID.INDICATOR_OVERVIEW_ALL_SEGMENTS_PAGINATED_REPORT]: true,
};

// ! POWERBI_META now in src/locales/LANGUAGE/components/powerbi.json
// ! LANGUAGE = en/fr-CA
