import React from "react";
import { Icon, Brag, Loader } from "@tscore/react-components";
// ? TYPES:
// import { SystemDashboardTable } from "../../types/system-dashboard-table";

interface EmptyIndicatorTableProps {
  title: string;
}

export const EmptyIndicatorTable: React.FC<EmptyIndicatorTableProps> = ({ title }) => {
  const list = Array.from({ length: 3 });
  return (
    <div>
      <div className="isFetching">
        <div className="table bordered no-vertical-lines main shadowed">
          <header className="thead">
            <ul className="row">
              <li className="th">
                {title}
                <Loader isInline style={{ marginLeft: "12px" }} />
              </li>
              <li className="th ta-center"></li>
              <li className="th ta-center"></li>
              <li className="th" />
            </ul>
          </header>
          <div className="tbody">
            {list.map((e: any, i: number) => (
              <ul key={i} className="row">
                <li className="cell normal-holder">
                  <div className="loading-element">.</div>
                </li>
                <li className="cell brag-holder">
                  <Brag className="loading-element">.</Brag>
                </li>
                <li className="cell brag-holder">
                  <Brag className="loading-element">.</Brag>
                </li>
                <li className="cell graph-holder">
                  <Icon>bar_chart</Icon>
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
