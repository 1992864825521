import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchPersonalisedIndicators,
  fetchToggleIndicatorAlerts,
  fetchDeleteIndicatorsFromGroup,
} from "../../store/actions/personalised-indicators";
import { Loader, InlineError, Dropdown, Icon, Modal, Tippy } from "@tscore/react-components";
import { BragWithTag } from "../../components/brag-with-tag";
import Moment from "react-moment";
import { momentTimeDateFormat } from "../../lang/DateTimeFormats";
import { MODAL_INDICATOR_SETUP_STYLES, MODAL_INDICATOR_SETUP_STYLES_UPDATE } from "./consts";
import { ModalIndicatorSetup } from "./modal-indicator-setup";
// import classNames from "classnames";

import { generateBragColour, isGray } from "../../helpers/brag-helpers";
// From sys dashboard:
import { IndicatorModal } from "../whole-system-resilience/indicator-modal";
import { INDICATOR_MODAL_GENERAL_PROPS } from "../whole-system-resilience/consts";
import { useTranslation, Trans } from "react-i18next";
//? TYPES:
import { ApplicationState } from "../../store/reducers";
import { UpdatedIndicator } from "../../store/types/personalised-indicators-state";

// import { RouteComponentProps } from "react-router-dom";

type PersonalisedIndicatorsProps = any;

export const getToggleUpdatedAlerts = (
  indicators: ApplicationState["personalisedIndicatorsReducer"]["indicatorGroups"][number]["indicatorList"]
): UpdatedIndicator[] => {
  return indicators.map((item) => ({
    indicatorId: item.indicatorId,
    alertEnabled: !item.alertEnabled,
    type: "update",
  }));
};

export const PersonalisedIndicators: React.FC<PersonalisedIndicatorsProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchPersonalisedIndicators());
    }
    // setTimeout(() => {
    fetchInitial();
    // }, 2330); // after login hack
  }, [dispatch]);
  const state = useSelector((state: ApplicationState) => state.personalisedIndicatorsReducer);
  const { isFetching, errors, indicatorGroups } = state;
  const toggleAlerts = (
    e: any,
    organisationId: number,
    indicators: (typeof indicatorGroups)[number]["indicatorList"]
  ) => {
    dispatch(fetchToggleIndicatorAlerts(organisationId, getToggleUpdatedAlerts(indicators)));
    e.preventDefault();
  };
  const deleteIndicators = (
    e: any,
    organisationId: number,
    indicators: (typeof indicatorGroups)[number]["indicatorList"]
  ) => {
    const deletedIndicators: UpdatedIndicator[] = indicators.map((item) => ({
      indicatorId: item.indicatorId,
      alertEnabled: item.alertEnabled,
      type: "remove",
    }));
    dispatch(fetchDeleteIndicatorsFromGroup(organisationId, deletedIndicators));
    e.preventDefault();
  };
  if (indicatorGroups.length === 0 && isFetching["GET_PERSONALISED_INDICATORS"]) {
    return (
      <section className="personalized-dashboard">
        <Loader scale="xl" />
      </section>
    );
  }
  if (errors["GET_PERSONALISED_INDICATORS"]) {
    return (
      <InlineError
        title={t("errors:connection")}
        description={t("home:errorPersonalisedIndicatorsConnectionDescription", {
          error: errors["GET_PERSONALISED_INDICATORS"],
        })}
        icon="portable_wifi_off"
      />
    );
  }
  if (indicatorGroups.length === 0) {
    return (
      <Modal
        style={MODAL_INDICATOR_SETUP_STYLES}
        trigger={
          <InlineError
            style={{ padding: "24px 0" }}
            className="pointer"
            title={t("home:No Personalized Indicators")}
            description={
              <Trans
                i18nKey="home:noPersonalisedIndicatorsDescription"
                components={[
                  <a key={0} href="#!add-indicators">
                    here
                  </a>,
                ]}></Trans>
            }
            icon="dashboard"
          />
        }>
        <ModalIndicatorSetup title={t("home:Set Personalised Indicators")} />
      </Modal>
    );
  }
  return (
    <section className="personalized-dashboard personalized-dashboard--has-items">
      <div className="personalized-container">
        <div className="organisation">
          <aside className="organisation-content add-new">
            <header>
              <h3 className="pointer">{t("home:Add New")}</h3>
            </header>
            <ul className="indicator-list">
              <li className="drop-area edit-area">
                <Modal style={MODAL_INDICATOR_SETUP_STYLES} trigger={<div>{t("home:Add Indicators")}</div>}>
                  <ModalIndicatorSetup title={t("home:Set Personalised Indicators")} />
                </Modal>
              </li>
            </ul>
          </aside>
        </div>
        {indicatorGroups.map((item) => (
          <div className="organisation" key={item.organisationId}>
            <div className="organisation-content">
              <header>
                <h3>{item.organisationName}</h3>
                <Dropdown position="right" trigger={<Icon className="pointer more">more_vert</Icon>}>
                  <li style={{ display: "none" }} />
                  {/* <li>
                    <a href="#!">
                      <Icon>phone_iphone</Icon>
                      <span>Configure Alerts</span>
                    </a>
                  </li> */}
                  <Dropdown.Item
                    href="#!delete-group"
                    onClick={(e) => deleteIndicators(e, item.organisationId, item.indicatorList)}
                    icon="delete_sweep"
                    label={t("generic:Delete All")}
                  />
                </Dropdown>
              </header>
              <ul className="indicator-list">
                {item.indicatorList.map((indicator) => (
                  <li className="indicator-item" key={indicator.indicatorId}>
                    <div>
                      <Modal
                        {...INDICATOR_MODAL_GENERAL_PROPS}
                        trigger={
                          <>
                            <span className="name">{indicator.indicatorName}</span>

                            <div className="meta">
                              {t("generic:Updated")}:{" "}
                              <Tippy
                                content={
                                  <div style={{ fontSize: "1.1rem" }}>
                                    <Moment fromNow>{indicator.valueTimestamp}</Moment>
                                  </div>
                                }
                                placement="right"
                                delay={[800, 0]}>
                                <span>
                                  <Moment format={momentTimeDateFormat}>{indicator.valueTimestamp}</Moment>
                                </span>
                              </Tippy>
                            </div>
                          </>
                        }>
                        <IndicatorModal indicator={indicator} extraISHInfo={{ organisationId: item.organisationId }} />
                      </Modal>
                    </div>
                    <div>
                      <Modal
                        {...INDICATOR_MODAL_GENERAL_PROPS}
                        trigger={
                          <BragWithTag
                            className="pointer"
                            {...generateBragColour(indicator, false)}
                            isGreyWithTag={isGray(indicator)}
                            updatedAtText={<Moment fromNow>{indicator.valueTimestamp}</Moment>}>
                            {indicator.readableValue ?? indicator.value}
                          </BragWithTag>
                          // <Brag className="pointer" {...generateBragColour(indicator, true)}>
                          //   {indicator.readableValue ?? indicator.value}
                          // </Brag>
                        }>
                        <IndicatorModal indicator={indicator} extraISHInfo={{ organisationId: item.organisationId }} />
                      </Modal>
                    </div>
                    <aside className="row-mobile">
                      <div>
                        <a href="#!toggle-alert" onClick={(e) => toggleAlerts(e, item.organisationId, [indicator])}>
                          <Trans
                            i18nKey="home:mobileAppAlert"
                            tOptions={{ context: indicator.alertEnabled ? "ON" : "OFF" }}
                            components={[
                              <span key={0} className={indicator.alertEnabled ? "mobile-on" : "mobile-off"}>
                                on_or_off
                              </span>,
                            ]}></Trans>
                        </a>
                      </div>
                      <div />
                    </aside>
                  </li>
                ))}
                <li className="drop-area edit-area">
                  <Modal style={MODAL_INDICATOR_SETUP_STYLES_UPDATE} trigger={<div>{t("home:Edit Indicators")}</div>}>
                    <ModalIndicatorSetup
                      title={t("home:Edit Personalised Indicators")}
                      subtitle={item.organisationName}
                      type="update"
                      updateSettings={{ organisationId: item.organisationId, indicators: item.indicatorList }}
                    />
                  </Modal>
                </li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
