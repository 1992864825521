import { loadStorage } from "./helpers/localstorage";

interface GetTokensResponse {
  access_token: string | undefined;
  refresh_token: string | undefined;
}

export function getTokens(): GetTokensResponse {
  const { access_token, refresh_token } = (loadStorage("credentialsReducer") || { data: {} }).data;
  return {
    access_token,
    refresh_token,
  };
}
