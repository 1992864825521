import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultApiLoading } from "../default-api-loading";
import { updateDial } from "./update-dial";
import { updateWeight } from "./update-weight";
// import { defaultPagination } from "../default-pagination";
// import { paginate } from "../../../helpers/paginate";
import { downloadFile } from "../../../helpers/download-file";

//? TYPES
import { SystemDashboardState } from "../../types/system-dashboard-state";

const defaultState: SystemDashboardState = {
  isFetchingDials: true,
  didInvalidateDials: false,
  dials: [], // unitlist?
  errors: {},
  status: {
    "-1": {},
  },
  isSavingWeights: {},
  isFetching: {},
  isForcingColour: {},
};

const dials = (state: SystemDashboardState = defaultState, action: any): any => {
  // console.log(action);
  switch (action.type) {
    case API_START: {
      if (action.payload === "GET_SITE_DIALS") {
        return {
          ...state,
          isFetchingDials: true,
          didInvalidateDials: false,
        };
      }
      if (action.payload === "GET_DIAL_INFO") {
        return {
          ...state,
          dials: updateDial(state.dials, action.siteId, { isFetched: false }),
        };
      }
      if (action.payload === "GET_TABLES") {
        return {
          ...state,
          isFetching: {
            ...state.isFetching,
            [action.payload + "_" + action.unitId]: true,
          },
        };
      }
      if (action.payload === "FORCE_DIALS_REFRESH") {
        // const now = new Date().toISOString();
        const siteIdList: number[] = action.siteIdList;
        const defaultApiLoadingState = defaultApiLoading(state, action, true, ["FORCE_DIALS_REFRESH"]);
        return {
          ...defaultApiLoadingState,
          isForcingColour: {
            ...defaultApiLoadingState.isForcingColour,
            ...siteIdList.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
          },
        };
      }
      return defaultApiLoading(state, action, true, ["DOWNLOAD_INDICATOR_OVERVIEW_REPORT"]);
      // return state;
    }
    case API_ERROR: {
      const defaultErrorState = defaultApiError(state, action);
      if (action.payload.label === "GET_DIAL_INFO") {
        return {
          ...defaultErrorState,
          dials: updateDial(state.dials, action.siteId, { hasErrored: true }),
        };
      }
      if (action.payload.label === "UPDATE_WEIGHT") {
        return {
          ...defaultErrorState,
          status: {
            ...state.status,
            [action.siteId]: updateWeight(
              state.status[action.siteId],
              (state.isSavingWeights[action.indicatorId] || { oldWeight: -1 }).oldWeight,
              action
            ),
          },
        };
      }
      if (action.payload.label === "FORCE_DIALS_REFRESH") {
        // ? if has action.data it means 200 response, but errored dials; otherwise error all of them as API failed
        const siteIdList: number[] = action.data ? action.data.error.map((err: any) => err.siteId) : action.siteIdList;
        return {
          ...defaultErrorState,
          isForcingColour: {
            ...defaultErrorState.isForcingColour,
            ...siteIdList.reduce((acc, key) => ({ ...acc, [key]: false }), {}),
          },
        };
      }
      return defaultErrorState;
    }
    case API_END: {
      if (action.payload === "GET_SITE_DIALS") {
        return {
          ...state,
          isFetchingDials: false,
        };
      }
      if (action.payload === "GET_DIAL_INFO") {
        const now = new Date().toISOString();
        return {
          ...state,
          dials: updateDial(state.dials, action.siteId, { isFetched: true }),
          isForcingColour: {
            ...state.isForcingColour,
            [action.siteId]: now,
          },
        };
      }
      if (action.payload === "UPDATE_WEIGHT") {
        return {
          ...state,
          isSavingWeights: {
            ...state.isSavingWeights,
            [action.indicatorId]: undefined,
          },
        };
      }
      if (action.payload === "GET_TABLES") {
        return {
          ...state,
          isFetching: {
            ...state.isFetching,
            [action.payload + "_" + action.unitId]: false,
          },
        };
      }
      // if (action.payload === "FORCE_DIALS_REFRESH") {
      //   return state;
      // }
      return defaultApiLoading(state, action, false, ["DOWNLOAD_INDICATOR_OVERVIEW_REPORT"]);
    }
    case "GET_SITE_DIALS":
      // console.log(action);
      return {
        ...state,
        dials: action.data.unitList,
        didInvalidateDials: false,
      };

    case "GET_DIAL_INFO": {
      return {
        ...state,
        dials: updateDial(
          state.dials,
          action.siteId,
          { unitList: action.data.unitList, isFetched: true },
          action.preserveEscalation
        ),
      };
    }
    case "UPDATE_STATUS": {
      return {
        ...state,
        status: {
          ...state.status,
          [action.siteId]: action.status,
        },
      };
    }
    case "UPDATE_WEIGHT": {
      return state;
    }
    case "EDIT_WEIGHT": {
      return {
        ...state,
        isSavingWeights: {
          ...state.isSavingWeights,
          ...(action.isValid &&
            state.isSavingWeights[action.indicatorId] === undefined && {
              [action.indicatorId]: { oldWeight: action.oldWeight },
            }),
        },
        status: {
          ...state.status,
          [action.siteId]: updateWeight(state.status[action.siteId], action.weight, action),
        },
      };
    }
    case "GET_TABLES": {
      const [currentKey]: [string, any] = Object.entries(state.status[action.siteId]).find(
        ([_key, value]) => value.unitId === action.unitId
      ) || ["-1", null];
      // console.log(current, action.unitId);
      return {
        ...state,
        status: {
          ...state.status,
          [action.siteId]: {
            ...state.status[action.siteId],
            [currentKey]: {
              ...(state.status as any)[action.siteId][currentKey],
              isFetched: true,
              data: action.data.tableList,
            },
          },
        },
        // status:
      };
    }
    case "GET_LIVE_SYSTEM_ACTIONS": {
      // escalation
      return {
        ...state,
        dials: state.dials.map((dial) => {
          if (dial.siteId === action.pdSiteId) {
            return {
              ...dial,
              unitList: dial.unitList?.map((outer) => ({
                ...outer,
                childUnitList: outer.childUnitList.map((inner) => {
                  if (action.data.escalatedPdUnitIds.indexOf(inner.unitId) !== -1) {
                    return {
                      ...inner,
                      escalated: true,
                    };
                  }
                  return inner;
                }),
              })),
            };
          }
          return dial;
        }),
      };
    }
    case "DOWNLOAD_INDICATOR_OVERVIEW_REPORT": {
      downloadFile(action.data, action.fileName + ".xlsx");
      return state;
    }
    case "FORCE_DIALS_REFRESH": {
      // console.log({ action });
      return state;
    }
    default:
      return state;
  }
};

const dialsByDashboard = (
  state: { [systemDashboardId: string]: SystemDashboardState } = { "-1": defaultState },
  action: any
): any => {
  switch (action.type) {
    case API_START:
    case API_ERROR:
    case API_END:
    case "GET_SITE_DIALS":
    case "GET_DIAL_INFO":
    case "UPDATE_STATUS":
    case "GET_TABLES":
    case "UPDATE_WEIGHT":
    case "EDIT_WEIGHT":
    case "GET_LIVE_SYSTEM_ACTIONS": // escalation
    case "DOWNLOAD_INDICATOR_OVERVIEW_REPORT":
    case "FORCE_DIALS_REFRESH":
      if (!action.systemDashboardId) {
        return state;
      }
      return {
        ...state,
        [action.systemDashboardId]: dials(state[action.systemDashboardId], action),
      };
    default:
      return state;
  }
};

export default dialsByDashboard;
