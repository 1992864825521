import React from "react";
import { Dropdown, Button, Icon, SVGIcon } from "@tscore/react-components";
import { withTranslation } from "react-i18next";
// import { Chevron } from "./chevron";
import { useFilterChange } from "./hooks/use-filter-change";
import { ReactComponent as SVGArrowDown } from "../../sass/assets/sidebar-icons/arrow-down.svg";
import classNames from "classnames";
// ? TYPES:
import { TFunction } from "i18next";
import { SearchParams } from "../../types/paginated-params";

type SortType = "date" | "number" | "string";

export type SortItem = { value: string; sortName?: string; sortLabel?: string; sortType?: SortType };

const getIconFactory = (type: SortType) => {
  if (type === "number") {
    return "numbers";
  }
  if (type === "date") {
    return "calendar_month";
  }
  return "sort_by_alpha";
};

const SortListItemWoT: React.FC<{
  label: string;
  order: "ASC" | "DESC" | null | undefined;
  type?: SortType;
  toggleSort: () => void;
  t: TFunction;
}> = ({ label, order, type = "string", toggleSort, t }) => {
  const icon = getIconFactory(type);
  return (
    <button className="bare-btn data-toolbar__sort__item" onClick={toggleSort} data-order={order} data-type={type}>
      <Icon className="data-toolbar__sort__type__icon">{icon}</Icon>
      <span className="data-toolbar__sort__label">{label}</span>
      <span className="data-toolbar__sort__description" aria-hidden={typeof order !== "string"}>
        <span aria-hidden={order !== "DESC"}>{t("sort." + type, { context: "DESC" })}</span>
        <span aria-hidden={order === "DESC"}>{t("sort." + type, { context: "ASC" })}</span>
      </span>
      <span className="data-toolbar__sort__icon__wrapper">
        <SVGIcon as="span" svg={SVGArrowDown} />
      </span>
    </button>
  );
};

const SortListItem = withTranslation(["generic"])(SortListItemWoT);

interface SortDropdownProps {
  t: TFunction;
  searchParams: SearchParams;
  list: SortItem[];
}

const SortDropdownWoT: React.FC<SortDropdownProps> = ({ t, list, searchParams }) => {
  const { toggleSort } = useFilterChange();
  const filtered = list.filter((item) => !!item.sortName) as Utils.WithRequired<SortItem, "sortName">[];
  if (filtered.length === 0) {
    return null;
  }
  return (
    <Dropdown
      as="div"
      position="right"
      className="data-toolbar__dropdown data-toolbar__dropdown--sort"
      trigger={
        <Button
          className={classNames({
            "is-active": searchParams.sort,
          })}
          colour="data-toolbar__trigger">
          <Icon className="data-toolbar__trigger__icon">sort</Icon>
          <span>{t("sortLabel")}</span>
          {/* <Chevron /> */}
        </Button>
      }>
      {filtered.map((item) => (
        <SortListItem
          key={item.sortName}
          label={item.sortLabel || item.value}
          type={item.sortType}
          order={searchParams.parsedSort[item.sortName]}
          toggleSort={toggleSort(item.sortName)}
        />
      ))}
    </Dropdown>
  );
};

// label, type, value

export const SortDropdown = withTranslation(["generic"])(SortDropdownWoT);
