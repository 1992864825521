import IndicatorHistoryService from "./indicator-history.service";
import { cancelRequest } from "../../../helpers/cancel-request";

// ? TYPES:
import { ApiAction } from "../../types/api";
import { AnyIndicator } from "../../../types/indicator";
import { Omit } from "../../../types/generic";
import {
  IndicatorServiceRequestParams,
  IndicatorHistoryBulkResponse,
  RawGraphConfig,
  ISHExtraParams,
} from "../../types/indicator-history-state";
import { ApplicationState } from "../../reducers";
import { LocalDispatch } from "../../../components/indicator-history/reducer";
// import { ImportedAccessInfo } from "../../types/menu-state";

export const getIndicatorsHistory = (
  comparisonKey: string,
  indicators: AnyIndicator[],
  data: IndicatorHistoryBulkResponse["data"],
  params: IndicatorServiceRequestParams,
  localDispatch?: LocalDispatch
) => ({
  type: "GET_INDICATORS_HISTORY",
  comparisonKey,
  indicators,
  data,
  params,
  localDispatch,
});

const downloadIndicatorGraphUserValueReport = (comparisonKey: string, data: any, fileName: string) => ({
  type: "DOWNLOAD_INDICATOR_GRAPH_USER_VALUE_REPORT",
  data,
  fileName,
  comparisonKey,
});

export const fetchDownloadIndicatorGraphUserValueReport = (
  comparisonKey: string,
  indicators: AnyIndicator[],
  { freq, agg, start, end }: Omit<IndicatorServiceRequestParams, "ids" | "timezone" | "originator">,
  fileName = "report"
) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const ids = indicators.map((indicator) => indicator.indicatorId);
    const timezone = getState().credentialsReducer.localTimeZone;
    const params = { ids, freq, agg, start, end, timezone };
    dispatch(
      IndicatorHistoryService.downloadIndicatorGraphUserValueReport(params, {
        label: "DOWNLOAD_INDICATOR_GRAPH_USER_VALUE_REPORT",
        onSuccess: (response: IndicatorHistoryBulkResponse) =>
          downloadIndicatorGraphUserValueReport(comparisonKey, response, fileName),
        other: { comparisonKey, indicators },
      })
    );
  };
};

export const cleanupIndicatorsHistory = (comparisonKey: string) => ({
  type: "CLEANUP_INDICATORS_HISTORY",
  comparisonKey,
});

const getIndicatorsHistoryEnd = () => ({
  type: "GET_INDICATORS_HISTORY_END",
});

export const fetchIndicatorsHistory = (
  comparisonKey: string,
  indicators: AnyIndicator[],
  { freq, agg, start, end }: Omit<IndicatorServiceRequestParams, "ids">,
  extraParams: ISHExtraParams,
  localDispatch?: LocalDispatch
) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    cancelRequest("GET_INDICATORS_HISTORY");
    const ids = indicators.map((indicator) => indicator.indicatorId);
    const state = getState();
    const timezone = state.credentialsReducer.localTimeZone;
    const params = { ids, freq, agg, start, end, timezone };
    const product = state.menuReducer.meta.moduleIdServer;
    dispatch(
      IndicatorHistoryService.getIndicators(
        params,
        { ...extraParams, product },
        {
          label: "GET_INDICATORS_HISTORY",
          onSuccess: (response) =>
            getIndicatorsHistory(comparisonKey, indicators, response.data, params, localDispatch),
          other: { comparisonKey, indicators, errorKey: "" + freq + agg + start + end },
          onEnd: () => {
            if (localDispatch) {
              localDispatch({ type: "ON_UPDATE_CHART" });
            }
            return getIndicatorsHistoryEnd();
          },
          // onFailure: () => console.log("Error occured loading articles"),
        }
      )
    );
  };
};

export const fetchIndicatorsHistoryIfNeeded = (
  comparisonKey: string,
  indicators: AnyIndicator[],
  { freq, agg, start, end }: Omit<IndicatorServiceRequestParams, "ids">,
  extraParams: ISHExtraParams,
  localDispatch?: LocalDispatch
) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const state = getState().indicatorHistoryReducer;
    const { errorKey } = state[comparisonKey] || state[-1];
    // const errorKey = errors["GET_INDICATORS_HISTORY"];
    if (errorKey !== "" + freq + agg + start + end) {
      dispatch(
        fetchIndicatorsHistory(comparisonKey, indicators, { freq, agg, start, end }, extraParams, localDispatch)
      );
    }
  };
};

const getGraphConfig = (comparisonKey: string, data: RawGraphConfig) => ({
  type: "GET_GRAPH_CONFIG",
  comparisonKey,
  data,
});

export const fetchGraphConfig = (
  comparisonKey: string,
  id: number,
  type: "INDICATOR" | "INDICATOR_GROUP"
): ApiAction => {
  const params = { id, type };
  return IndicatorHistoryService.getGraphConfig(params, {
    label: "GET_GRAPH_CONFIG",
    onSuccess: (response) => getGraphConfig(comparisonKey, response),
    other: { comparisonKey, id },
  });
};

export const fetchGraphConfigIfNeeded = (comparisonKey: string, id: number, type: "INDICATOR" | "INDICATOR_GROUP") => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const state = getState().indicatorHistoryReducer;
    const { errors } = state[comparisonKey] || state[-1];
    // console.log(getState().indicatorHistoryReducer);
    if (!errors["GET_GRAPH_CONFIG"]) {
      dispatch(fetchGraphConfig(comparisonKey, id, type));
    }
  };
};
