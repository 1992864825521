import { useEffect } from "react";
// ? TYPES:
import { RefObject } from "react";

export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  callback: Function,
  options: EventListenerOptions | boolean = false
) => {
  const handleClickOut = (e: Event): any => {
    if (ref && ref.current && !ref.current.contains(e.target as Node)) {
      // console.log("outside go close");
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOut, options);
    return () => {
      document.removeEventListener("mousedown", handleClickOut, options);
    };
  });
};
