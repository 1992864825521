import { accessEnv } from "../access-env";

export const DEFAULT_PAGE_LIMIT = 20;
export const DEFAULT_DEBOUNCE_SEARCH_MS = 800;
export const DEFAULT_DEBOUNCE_LOCAL_SEARCH_MS = 400;
export const SHOULD_CACHE_MENU = true;
export const SOURCE_LANGUAGE = "en-GB";
export const SUPPORTED_LANGUAGES = accessEnv("LANGUAGES_LIST")
  ? accessEnv("LANGUAGES_LIST").split(",")
  : [SOURCE_LANGUAGE];
export const FALLBACK_LANGUAGE = SUPPORTED_LANGUAGES[0]; // first in the list is default
export const PREFER_24_HOUR_FORMAT_LIST = ["fr-CA"];
export const LANGUAGE_SWITCHER = SUPPORTED_LANGUAGES.length > 1 ? true : false;
export const PRIORITY_PHONE_COUNTRY_NAMES = accessEnv("PRIORITY_PHONE_COUNTRY_NAMES")
  ? accessEnv("PRIORITY_PHONE_COUNTRY_NAMES").split(",")
  : [];
export const DEFAULT_PHONE_COUNTRY = PRIORITY_PHONE_COUNTRY_NAMES[0]; // first in list is selected
