import { RefObject, useState, useLayoutEffect, useCallback } from "react";

import useEventListener from "./use-event-listener";
import useOnScreen from "./use-on-screen";

interface Size {
  width: number;
  height: number;
}

function useElementSize<T extends HTMLElement = HTMLDivElement>(
  elementRef: RefObject<T>,
  _checkIfVisible = true
): Size {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });
  const isVisible = useOnScreen(elementRef);
  // Prevent too many rendering using useCallback
  const updateSize = useCallback(() => {
    const node = elementRef?.current;
    if (node) {
      setSize({
        width: node.offsetWidth || 0,
        height: node.offsetHeight || 0,
      });
    }
  }, [elementRef]);

  // Initial size on mount
  useLayoutEffect(() => {
    if (isVisible) {
      updateSize();
    }
  }, [updateSize, isVisible]);

  useEventListener("resize", updateSize);

  return size;
}

export default useElementSize;
