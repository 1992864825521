import React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
//? TYPES:
import { TFunction } from "i18next";

export const modalSaveSuccessSettings = {
  trigger: null,
  className: "dialog",
  backdropStatic: true,
};

const ModalSaveSuccessWoT: React.FC<{ redirectURL: string; t: TFunction }> = ({ redirectURL = "/", t }) => (
  <div>
    <header>
      <h4 className="color-green">{t("modalSuccess.title")}</h4>
    </header>
    <article>
      <p>{t("modalSuccess.body")}</p>
    </article>
    <footer className="ta-center">
      {/* <button className="btn link" data-close-modal>
        Cancel
      </button> */}
      <NavLink to={redirectURL} className="btn green">
        <i className="material-icons">arrow_right_alt</i> {t("modalSuccess.confirm")}
      </NavLink>
    </footer>
  </div>
);

export const ModalSaveSuccess = withTranslation("regionReports")(ModalSaveSuccessWoT);
