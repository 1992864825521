import { setLocale } from "yup";
// ? TYPES:
import { TFunction } from "i18next";

type Params = any;
type ExtendedParams = Params & { label: string };
type Type = "Capitalize" | "lowercase";

// https://github.com/jquense/yup/blob/master/src/locale.ts

const DEFAULT_TYPE: Type = "Capitalize";
// const NS = "yupErrors:";

function decamelise(str: string, type: Type = DEFAULT_TYPE): string {
  const result = str.replace(/([A-Z])/g, " $1");
  if (type === "lowercase") {
    return result.toLowerCase();
  }
  return result.charAt(0).toUpperCase() + result.slice(1);
}

function updateParams(params: Params, type: Type = DEFAULT_TYPE): ExtendedParams {
  return {
    ...params,
    label: params.label ? params.label : decamelise(params.path, type),
  };
}

const translate =
  (t: TFunction) =>
  (key: string | string[], type: Type = DEFAULT_TYPE) =>
  (params: Params): string =>
    t("yupErrors:" + key, updateParams(params, type));

export function buildYupLocale(_error: unknown, originalT: TFunction): void {
  const t = translate(originalT);
  setLocale({
    mixed: {
      default: t("mixed.default"),
      required: t("mixed.required"),
      // 4 more?
    },
    string: {
      length: t("string.length"),
      min: t("string.min"),
      max: t("string.max"),
      email: t("string.email"),
      // 6 more?
    },
    number: {
      min: t("number.min"),
      max: t("number.max"),
      lessThan: t("number.lessThan"),
      moreThan: t("number.moreThan"),
      positive: t("number.positive"),
      negative: t("number.negative"),
      integer: t("number.integer"),
    },
  });
}
