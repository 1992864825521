import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { usePrevious } from "../../../hooks/use-previous";
import { fetchEscalationPlans, fetchDownloadEscalationPlanReport } from "../../../store/actions/escalation";

import { Loader, Button, Icon, Tippy, Accordion } from "@tscore/react-components";

// import classNames from "classnames";
import { BRAG_PRESSURE_LEVEL_MAPPING } from "../../../helpers/brag-helpers";
import { CONTENT_HEIGHT, HEADER_HEIGHT_PX } from "./consts";
import useElementSize from "../../../hooks/use-element-size";
import { UserList } from "./user-list";
import { capitalize } from "../../../helpers/capitalize";
import { ErrorEscalationConnection, ErrorEscalationEmpty } from "./error-escalation";
import { useTranslation } from "react-i18next";
// ? TYPES:
import { ApplicationState } from "../../../store/reducers";
import { EscalationPlan } from "../../../store/types/escalation-state";
import { WheelInput } from "@tscore/wheel-builder/dts/types";
import UserInfo from "../../../types/user-info";

const GET_DATA_ACTION_NAME = "GET_ESCALATION_PLANS";

const PlanTable: React.FC<{
  plan: EscalationPlan;
  credentials: UserInfo;
}> = ({ plan, credentials }) => {
  const { t } = useTranslation();
  const descriptionRef = useRef(null);
  const { width } = useElementSize(descriptionRef);
  const length = plan.escalation.length;
  return (
    <div>
      <table className="table bordered escalation-plans-table">
        <thead>
          <tr>
            {plan.escalation.map((esc) => {
              const c = (BRAG_PRESSURE_LEVEL_MAPPING as any)[esc.pressureLevel];
              const colour = t("generic:colours." + capitalize(c), { defaultValue: c, count: 99 });
              return (
                <th
                  style={{ width: `${100 / length}%`, display: "table-cell" }}
                  className={`brag brag-${c}`}
                  key={esc.pressureLevel}>
                  {t("escalation:actionPlanHeaderTitle", { colour: colour })}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr ref={descriptionRef}>
            {plan.escalation.map((esc) => (
              <td key={esc.pressureLevel} style={{ verticalAlign: "top" }}>
                <ul className="list bulleted">
                  {esc.actions.map((action) => (
                    <li key={action.description}>
                      <span>{action.description}</span>
                      <UserList usersAssigned={action.users} currentUserId={credentials.userId} width={width / 3} />
                    </li>
                  ))}
                </ul>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const TabEscalationPlans: React.FC<{
  systemDashboardId: number;
  pdSiteId: number;
  credentials: UserInfo;
  title: string;
}> = ({ pdSiteId, credentials, systemDashboardId, title }) => {
  const { t } = useTranslation();
  const [deactivatedUnitIds, setDeactivatedUnitIds] = useState<number[]>([]);
  // const prevSelectedUnitIds = usePrevious(selectedUnitIds);
  const dispatch = useDispatch();
  const dials = useSelector((appState: ApplicationState) => appState.systemDashboardReducer[systemDashboardId].dials);
  useEffect(() => {
    async function fetchInitial(unitIds: number[]) {
      await dispatch(fetchEscalationPlans(pdSiteId, unitIds));
    }
    const dial = dials.find((dial) => dial.siteId === pdSiteId);
    if (dial && dial.unitList) {
      const opts = dial.unitList!.reduce((final: number[], current: WheelInput) => {
        for (let i = 0; i < current.childUnitList.length; i += 1) {
          final.push(current.childUnitList[i].unitId);
        }
        return final;
      }, []);
      fetchInitial(opts);
    }
    // document.getElementById("template")!.innerHTML = "Loading";
  }, [dispatch, pdSiteId, dials]);
  const state = useSelector(
    (state: ApplicationState) => state.escalationReducer[pdSiteId] || state.escalationReducer[-1]
  );
  const { isFetching, plans, errors, plansFilters } = state;
  const toggleDeactivatedUnit = (id: number, isMoreThanOneEnabled: boolean) => {
    const index = deactivatedUnitIds.indexOf(id);
    if (index === -1) {
      if (isMoreThanOneEnabled) {
        setDeactivatedUnitIds([...deactivatedUnitIds, id]);
      }
      // add
    } else {
      // delete
      const newArray = [...deactivatedUnitIds];
      newArray.splice(index, 1);
      setDeactivatedUnitIds(newArray);
    }
  };
  if (plans.length === 0 && isFetching[GET_DATA_ACTION_NAME]) {
    return (
      <div style={{ height: CONTENT_HEIGHT }}>
        <Loader scale="xl" />
      </div>
    );
  }
  if (errors[GET_DATA_ACTION_NAME]) {
    return <ErrorEscalationConnection title={title} error={errors[GET_DATA_ACTION_NAME]} />;
  }
  if (plans.length === 0) {
    return <ErrorEscalationEmpty type="ACTION_PLANS" />;
  }
  const filters = Object.values(plansFilters);
  return (
    <div>
      <aside className="escalation-header" style={{ height: `${HEADER_HEIGHT_PX}px` }}>
        <div style={{ display: "inline-block" }}>
          <div className="btn label" style={{ marginRight: "12px" }}>
            {t("escalation:Filter by Segments")}
          </div>
          <div className="group" style={{ display: "inline-block" }}>
            {filters.map((option) => {
              const isChecked = !deactivatedUnitIds.includes(option.value);
              const isMoreThanOneEnabled = deactivatedUnitIds.length < filters.length - 1;
              return (
                <Button
                  onClick={() => toggleDeactivatedUnit(option.value, isMoreThanOneEnabled)}
                  colour={isChecked ? "green-outline" : "lightgray-outline"}
                  key={option.value}>
                  <Tippy
                    content={
                      <span style={{ fontSize: "1.1rem" }}>{t("escalation:tooltipAtLeastOneSegmentRequired")}</span>
                    }
                    placement="bottom"
                    disabled={isMoreThanOneEnabled || !isChecked}>
                    <div data-tooltipped>
                      <Icon style={{ marginRight: "0.8rem" }}>
                        {isChecked ? "check_circle_outline" : "radio_button_unchecked"}
                      </Icon>
                      <span>{option.label}</span>
                    </div>
                  </Tippy>
                </Button>
              );
            })}
          </div>

          {/* {JSON.stringify(options)} */}
        </div>
        {/* <Select
          className="auto-width"
          styles={{ control: (styles) => ({ ...styles, minWidth: "20vw" }) }}
          options={options}
          placeholder="Search by Segment"
          isMulti
        /> */}
        <div style={{ display: "inline-block", float: "right" }} className="ta-right">
          <Button
            isLoading={isFetching["DOWNLOAD_ESCALATION_PLAN_REPORT"]}
            colour="blue-outline"
            icon="get_app"
            onClick={() =>
              dispatch(
                fetchDownloadEscalationPlanReport(
                  systemDashboardId,
                  pdSiteId,
                  `Escalation-Action-Plan-${systemDashboardId}-${pdSiteId}`
                )
              )
            }>
            {t("escalation:Download Report")}
          </Button>
        </div>
      </aside>
      <div className="scrollbar-content" style={{ height: `calc(${CONTENT_HEIGHT} - ${HEADER_HEIGHT_PX}px)` }}>
        <Accordion allowZeroExpanded>
          {plans.map((plan) => {
            const isHidden = plan.pdUnitList.some((unitMeta) => deactivatedUnitIds.includes(unitMeta.pdUnitId));
            if (isHidden) {
              return null;
            }
            return (
              <Accordion.Item key={plan.planId}>
                <Accordion.ItemHeading>
                  <Accordion.ItemButton>
                    {plan.indicators.map((indicator, index) => (
                      <span key={index}>{indicator.indicatorName}</span>
                    ))}
                  </Accordion.ItemButton>
                </Accordion.ItemHeading>
                <Accordion.ItemPanel>
                  <PlanTable plan={plan} credentials={credentials} />
                </Accordion.ItemPanel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};
