/*eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { wrappedPromise } from "../helpers/wrappedPromise";
// import { arePathsEqual } from "google-maps-react/dist/lib/arePathsEqual";
import { camelize } from "google-maps-react/dist/lib/String";
const evtNames = ["click", "mouseout", "mouseover", "mousemove"];

const COSMETIC_KEYS = ["fillColor" , "fillOpacity", "strokeColor", "strokeOpacity", "strokeWeight"];

export class Polygon extends React.Component {
  componentDidMount() {
    this.polygonPromise = wrappedPromise();
    this.renderPolygon();
  }

  componentDidUpdate(prevProps) {
    // ? Updating polygon styles, will just update Options
    let newOptions = {};
    COSMETIC_KEYS.forEach((value) => {
      if (this.props[value] !== prevProps[value]) {
        newOptions = {...newOptions, ...{[value]: this.props[value]}};
      }
    });
    if (Object.keys(newOptions).length !== 0 && newOptions.constructor === Object) {
      this.updateOptions(newOptions);
    }
    if (this.props.map !== prevProps.map
      //|| !arePathsEqual(this.props.paths, prevProps.paths)
      ) {
      if (this.polygon) {
        this.polygon.setMap(null);
      }
      this.renderPolygon();
    }
  }

  componentWillUnmount() {
    if (this.polygon) {
      this.polygon.setMap(null);
    }
  }

  updateOptions(options) {
    this.polygon.setOptions(options);
  }

  renderPolygon() {
    const {
      map,
      google,
      paths,
      strokeColor,
      strokeOpacity,
      strokeWeight,
      fillColor,
      fillOpacity,
      ...props
    } = this.props;

    if (!google) {
      return null;
    }

    const params = {
      map,
      paths,
      strokeColor,
      strokeOpacity,
      strokeWeight,
      fillColor,
      fillOpacity,
      ...props
    };

    this.polygon = new google.maps.Polygon(params);

    evtNames.forEach((e) => {
      const evtName = `on${camelize(e)}`;
      if (this.props[evtName]) {
        this.polygon.addListener(e, this.handleEvent(e));
      }
    });

    this.polygonPromise.resolve(this.polygon);
  }

  getPolygon() {
    return this.polygonPromise;
  }

  handleEvent(evt) {
    return (e) => {
      const evtName = `on${camelize(evt)}`;
      // if (this.props[evtName]) {
        this.props[evtName](this.props, this.polygon, e);
      // }
    };
  }

  render() {
    return null;
  }
}

Polygon.propTypes = {
  paths: PropTypes.array,
  strokeColor: PropTypes.string,
  strokeOpacity: PropTypes.number,
  strokeWeight: PropTypes.number,
  fillColor: PropTypes.string,
  fillOpacity: PropTypes.number
};

// evtNames.forEach((e) => (Polygon.propTypes[e] = PropTypes.func));

Polygon.defaultProps = {
  name: "Polygon"
};

export default Polygon;
