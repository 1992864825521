import React, { useState, useEffect, useMemo } from "react";
// import classNames from "classnames";
import { useTranslation } from "react-i18next";
// import { BRAG_BG_COLOUR_MAPPING, BRAG_PRESSURE_LEVEL_MAPPING } from "../../../helpers/brag-helpers";
import { BragWithTag } from "../../../components/brag-with-tag";
import { generateBragColour, isGray } from "../../../helpers/brag-helpers";
import Moment from "react-moment";
// ? TYPES:
import {
  ExpandedMarker,
  ChildFeature,
  FilterPressureLevel,
  AreaPressureLevel,
  FacilityMarkerOptions,
} from "../../../types/google-map";
// import { MapsHospital } from "../../../store/types/maps-state";

import { IndicatorInfo, IndicatorInfoCCG } from "../../../types/map-indicator-info";
// import { AnyIndicator } from "../../../types/indicator";
// type FacilityMarkerOptions = MapsHospital;
interface AreaPressureLevelCCG<T> extends AreaPressureLevel<T> {
  indicatorInfoDtos: IndicatorInfoCCG[];
}

interface Props {
  currentFacilityHover: ExpandedMarker | null;
  childrenColours?: AreaPressureLevelCCG<FilterPressureLevel>[];
  currentCCGHover?: ChildFeature | null;
  facilities: FacilityMarkerOptions<IndicatorInfo>[];
  // isMapTooltip?: boolean;
  onFacilityClose?: (event: any) => void;
}

const TRENDS: { [key: string]: { colour: string; icon: string } } = {
  "-1": {
    // colour: "red",
    colour: "blue",
    icon: "trending_down",
  },
  "0": {
    // colour: "gray",
    colour: "blue",
    icon: "trending_flat",
  },
  "1": {
    // colour: "green",
    colour: "blue",
    icon: "trending_up",
  },
};

// const getBragColourClassesOrStyle = (
//   defaultClasses: string,
//   indicator: IndicatorInfo
// ): { className: string; style?: any } => {
//   if (indicator.colorCodeBg && BRAG_BG_COLOUR_MAPPING[indicator.colorCodeBg.toLowerCase()]) {
//     return { className: `${defaultClasses} brag-${BRAG_BG_COLOUR_MAPPING[indicator.colorCodeBg.toLowerCase()]}` };
//   }
//   const defaultClass = `${defaultClasses} brag-${BRAG_PRESSURE_LEVEL_MAPPING["-1"]}`;
//   if (indicator.colorCodeBg) {
//     return {
//       style: { backgroundColor: indicator.colorCodeBg },
//       className: defaultClass,
//     };
//   }
//   if (indicator.pressureLevel !== null) {
//     // ? this will also gray out, as pressureLevel 0 === gray
//     return { className: `${defaultClasses} brag-${BRAG_PRESSURE_LEVEL_MAPPING[indicator.pressureLevel]}` };
//   }
//   return { className: defaultClass };
// };

interface IndicatorListProps {
  indicatorInfoDtos: any[];
}

const IndicatorList: React.FC<IndicatorListProps> = ({ indicatorInfoDtos }) => {
  return (
    <div className="tbody">
      {indicatorInfoDtos.map((indicator: IndicatorInfo | IndicatorInfoCCG, i: number) => (
        <ul className="row" key={i}>
          <li className="cell">{indicator.name || (indicator as IndicatorInfoCCG).nameAcronym}</li>
          <li className="cell">
            <BragWithTag
              className="popover-brag-mini"
              extraTagStyles={{ height: "2.4rem", width: "12px", clipPath: "polygon(100% 0, 0 0, 100% 50%)" }}
              {...generateBragColour(indicator as any, false, {
                valueColorCodeBg: "colorCodeBg" as "valueColorCodeBg",
                valueColorCodeFg: "colorCodeFg" as "valueColorCodeFg",
              })}
              isGreyWithTag={isGray(indicator as any)}
              // tooltipDisabled={true}
              updatedAtText={<Moment fromNow>{indicator.dateData}</Moment>}>
              {indicator.readableValue ?? indicator.value}
            </BragWithTag>
            {/* <span
        {...getBragColourClassesOrStyle("brag popover-brag-mini", indicator)}
        // className={`brag corona-brag-mini brag-${
        //   indicator.pressureLevel === null
        //     ? BRAG_PRESSURE_LEVEL_MAPPING["-1"]
        //     : BRAG_PRESSURE_LEVEL_MAPPING[indicator.pressureLevel]
        // }`}>
      >
        {indicator.readableValue ?? indicator.value}
      </span> */}
          </li>
          {indicator.trend !== null && (
            <li className="cell">
              <i className={`material-icons color-${TRENDS[indicator.trend].colour}`}>{TRENDS[indicator.trend].icon}</i>
            </li>
          )}
        </ul>
      ))}
    </div>
  );
};

const IndicatorListMemo = React.memo(IndicatorList);

export const HoverPopover: React.FC<Props> = ({
  currentFacilityHover,
  facilities,
  onFacilityClose,
  currentCCGHover = null,
  childrenColours = [],
}) => {
  const { t } = useTranslation();
  const [maximized, setMaximized] = useState(true);
  const [lastFacilityHover, setLastFacilityHover] = useState<ExpandedMarker | null>(null);
  const [lastCCGHover, setLastCCGHover] = useState<ChildFeature | null>(null);
  useEffect(() => {
    if (currentFacilityHover) {
      setLastFacilityHover(currentFacilityHover);
    }
  }, [currentFacilityHover, setLastFacilityHover]);
  useEffect(() => {
    if (currentCCGHover) {
      setLastCCGHover(currentCCGHover);
    }
  }, [currentCCGHover, setLastCCGHover]);
  const a = useMemo(() => {
    if (lastFacilityHover) {
      return facilities.find((f: FacilityMarkerOptions) => f.title === lastFacilityHover.name);
    }
    return null;
  }, [lastFacilityHover, facilities]);
  const b = useMemo(() => {
    if (lastCCGHover) {
      return childrenColours.find(
        (c: AreaPressureLevelCCG<FilterPressureLevel>) => c.id === lastCCGHover.properties.ccgName
      );
    }
    return null;
  }, [lastCCGHover, childrenColours]);
  const facility = useMemo(() => {
    return a ? a : b;
  }, [a, b]);

  if (!facility) {
    return null;
  }
  return (
    <aside className={`map-overlay hover-popover box ${maximized ? "maximized" : "minimized"}`}>
      <header>
        <h3>
          <span>
            {(facility as any).trustName ||
              (facility as FacilityMarkerOptions).title ||
              (facility as AreaPressureLevelCCG<FilterPressureLevel>).id}
          </span>
          <span style={{ whiteSpace: "nowrap" }}>
            <i onClick={() => setMaximized(!maximized)} className="hospital-close material-icons pointer">
              {maximized ? "expand_less" : "expand_more"}
            </i>
            <i
              onClick={(e) => {
                if (onFacilityClose) {
                  onFacilityClose(e);
                }
                setLastFacilityHover(null);
                setLastCCGHover(null);
                setMaximized(true);
              }}
              className="hospital-close material-icons pointer">
              close
            </i>
          </span>
        </h3>
      </header>
      <section>
        {/* <h4>{facility.title}</h4> */}
        {!facility.indicatorInfoDtos || facility.indicatorInfoDtos.length === 0 ? (
          <div style={{ margin: "0.6rem 0" }}>{t("map:No indicators available")}</div>
        ) : (
          <div className="table popover-brag-table condensed">
            <IndicatorListMemo indicatorInfoDtos={facility.indicatorInfoDtos} />
          </div>
        )}
      </section>
    </aside>
  );
};
