import React from "react";
import { Button } from "@tscore/react-components";
import { useTranslation } from "react-i18next";
import { INPUT_SETTINGS } from "./consts";
// import startCase from "lodash/startCase";

export const EditProfileEmpty: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="account-form">
      {Object.entries(INPUT_SETTINGS).map(([key, _props]) => {
        // const placeholder = (props as any).placeholder || startCase(key);
        const placeholder = t("myAccount:" + key);
        return (
          <div className="isFetching" key={key}>
            <label>{placeholder}</label>
            <div className="loading-element" style={{ height: "3.2rem" }}>
              x
            </div>
          </div>
        );
      })}
      <footer>
        <Button disabled>{t("generic:Save")}</Button>
      </footer>
    </div>
  );
};
