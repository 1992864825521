import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchEscalationAlerts, fetchUpdateEscalationAlerts } from "../../../store/actions/alerts";
import { Button, Input, Checkbox, InlineError } from "@tscore/react-components";
import Pagination from "../../../components/structure/pagination";
import { decodeParams, searchUpdateQuery } from "../../../helpers/params";
import { navigateToPage } from "../../../helpers/change-page";
import debounce from "lodash/debounce";
import { DEFAULT_DEBOUNCE_SEARCH_MS } from "../../../globals/settings";
// import { TabWeeklyPattern } from "./tab-weekly-pattern";
import { useTranslation } from "react-i18next";
//? TYPES:
import { ApplicationState } from "../../../store/reducers";
import { RouteComponentProps } from "react-router-dom";

// type TabEscalationAlertsProps = {};
type TabEscalationAlertsProps = RouteComponentProps<any>;

const MAIN_GET_NAME = "GET_ESCALATION_ALERTS";
// const ALERT_NAME = "Escalation";

const EmptyAlertRow = () => (
  <tr className="isFetching">
    <td>
      <div className="loading-element">1234</div>
    </td>
    <td>
      <div className="loading-element">XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</div>
    </td>
    <td>
      <div className="loading-element">XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</div>
    </td>
    <td className="ta-center">
      <div className="loading-element">123</div>
    </td>
    <td className="ta-center w1">
      <div className="loading-element">.</div>
    </td>
    <td className="ta-center w1">
      <div className="loading-element">.</div>
    </td>
  </tr>
);

export const TabEscalationAlerts: React.FC<TabEscalationAlertsProps> = ({ history, location }) => {
  const { t } = useTranslation();
  const searchParams = decodeParams(location.search);
  const page = parseInt(searchParams.page, 10) || 1;
  const query = searchParams.query;
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchEscalationAlerts(page, query));
    }
    fetchInitial();
  }, [dispatch, page, query]);
  const searchIndicator = (value: string | undefined) => searchUpdateQuery(value, history, location);
  const debouncedSearch = debounce(searchIndicator, DEFAULT_DEBOUNCE_SEARCH_MS);
  const state = useSelector((state: ApplicationState) => state.alertsReducer);
  const { isFetching, escalationAlerts, escalationMeta } = state;
  const changePage = (page: number) => navigateToPage(history, location.search, page);
  const pageChanging = (_page: number): boolean => {
    return true;
  };
  const onAlertChange = (
    e: { name: "canSendWeekDayAlerts" | "canSendWeekEndAlerts"; checked: boolean },
    alert: (typeof escalationAlerts)[number]
  ) => {
    const { name, checked } = e;
    const updatedAlert = {
      ...alert,
      [name]: checked,
    };
    dispatch(
      fetchUpdateEscalationAlerts([
        {
          canSendWeekDayAlerts: updatedAlert.canSendWeekDayAlerts,
          canSendWeekEndAlerts: updatedAlert.canSendWeekEndAlerts,
          indicatorId: updatedAlert.indicatorId,
          planId: updatedAlert.planId,
          // userId: updatedAlert.userId,
        },
      ])
    );
    return true;
  };
  const onAllAlertToggle = (areAllSelected: boolean) => {
    const updatedAlerts = function () {
      if (areAllSelected) {
        return escalationAlerts.map((alert) => ({
          canSendWeekDayAlerts: false,
          canSendWeekEndAlerts: false,
          indicatorId: alert.indicatorId,
          planId: alert.planId,
          // userId: alert.userId,
        }));
      }
      return escalationAlerts.map((alert) => ({
        canSendWeekDayAlerts: true,
        canSendWeekEndAlerts: true,
        indicatorId: alert.indicatorId,
        planId: alert.planId,
        // userId: alert.userId,
      }));
    };
    dispatch(fetchUpdateEscalationAlerts(updatedAlerts()));
  };
  const areAllSelected =
    escalationAlerts.length === 0
      ? false
      : escalationAlerts.every((alert) => alert.canSendWeekDayAlerts && alert.canSendWeekEndAlerts);

  return (
    <div>
      <header className="mb12">
        {/* <h3></h3> */}
        <div className="alert-table-header">
          <span
            className="constrain-text"
            dangerouslySetInnerHTML={{
              __html: t("myAccount:constrainMessage"),
            }}
          />
          <Button
            onClick={() => onAllAlertToggle(areAllSelected)}
            disabled={escalationAlerts.length === 0}
            colour="link"
            length="shorter">
            {areAllSelected ? t("generic:Deselect All") : t("generic:Select All")}
          </Button>
          <Input
            // ref={searchInputRef}
            icon="search"
            placeholder={t("generic:Search")}
            id="searchInput"
            autoComplete="escalation_alerts_search"
            onChange={(e: any) => debouncedSearch(e.target.value)}
            holderStyle={{ maxWidth: "240px" }}
            defaultValue={query || ""}
            isLoading={isFetching[MAIN_GET_NAME] && query}
          />
        </div>
      </header>
      {!isFetching[MAIN_GET_NAME] && escalationAlerts.length === 0 ? (
        <InlineError
          style={{ height: "30vh" }}
          className="middle"
          icon="grid_off"
          title={t("myAccount:No Escalation Alerts")}
        />
      ) : (
        <section>
          <table className="table main no-vertical-lines bordered">
            <thead>
              <tr>
                <th>{t("myAccount:escalationTable.0")}</th>
                <th>{t("myAccount:escalationTable.1")}</th>
                <th>{t("myAccount:escalationTable.2")}</th>
                <th className="ta-center w1 nowrap">{t("myAccount:escalationTable.3")}</th>
                <th className="ta-center">{t("myAccount:escalationTable.4")}</th>
                <th className="ta-center">{t("myAccount:escalationTable.5")}</th>
              </tr>
            </thead>
            <tbody>
              {isFetching[MAIN_GET_NAME] ? (
                <EmptyAlertRow />
              ) : (
                escalationAlerts.map((alert, index) => (
                  <tr key={index}>
                    <td>{alert.indicatorId}</td>
                    <td>{alert.indicatorName}</td>
                    <td>{alert.organisationName}</td>
                    <td className="ta-center">{alert.planId}</td>
                    <td className="ta-center">
                      <Checkbox
                        isToggle
                        name="canSendWeekDayAlerts"
                        value={alert.id}
                        checked={alert.canSendWeekDayAlerts}
                        onCheckboxToggle={(e: any) => onAlertChange(e, alert)}
                      />
                    </td>
                    <td className="ta-center">
                      <Checkbox
                        isToggle
                        name="canSendWeekEndAlerts"
                        value={alert.id}
                        checked={alert.canSendWeekEndAlerts}
                        onCheckboxToggle={(e: any) => onAlertChange(e, alert)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <Pagination
            label={t("myAccount:alertsPaginationLabel")}
            settings={escalationMeta}
            onChangePage={(page: number) => changePage(page)}
            onChangingPage={(page: number) => pageChanging(page)}
          />
        </section>
      )}
    </div>
  );
};
