export function downloadCleanup(id: number | string) {
  const $iframe = document.querySelector("iframe#download_" + id);
  if ($iframe) {
    $iframe.parentNode!.removeChild($iframe);
  }
}

export const generateIframe = (src: string, id: string | number, dispatch: Function, callback: Function) => {
  const $iframe = document.createElement("iframe");
  $iframe.src = src;
  $iframe.id = "download_" + id;
  $iframe.style.display = "none !important";
  $iframe.style.position = "absolute";
  $iframe.style.top = "0";
  $iframe.style.width = "1px";
  // $iframe.onload = function (_event) {};
  if (typeof (window as any).onDownloadReport !== "function") {
    (window as any).onDownloadReport = function (id: "all" | number, reportId: number | string, content: string) {
      dispatch(callback(id, reportId, content));
    };
  }
  document.body.appendChild($iframe);
};
