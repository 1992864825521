import React from "react";
import { STATUS_COLOUR_MAPPING } from "./modal-escalation-actions/consts";
import { withTranslation } from "react-i18next";

import { Tippy } from "@tscore/react-components";
import { TFunction } from "i18next";

const EscalationBarWoT: React.FC<{ dataset: { [status: string]: number }; totalActions: number; t: TFunction }> = ({
  dataset,
  totalActions,
  t,
}) => {
  return (
    <section className="escalation-bar">
      {Object.entries(dataset).map(([key, number]: [string, number]) => {
        const width = (number / totalActions) * 100;
        if (width === 0) {
          return null;
        }
        const colour = STATUS_COLOUR_MAPPING.getColour(key);
        const percentageReadable = width.toFixed(2) + "%";
        return (
          <Tippy key={key} content={`${t("statuses." + key)}: ${percentageReadable}`} placement="top">
            <div style={{ ...colour, width: `${width}%` }}>{width >= 7 && percentageReadable}</div>
          </Tippy>
        );
      })}
    </section>
  );
};

export const EscalationBar = withTranslation(["escalation"])(EscalationBarWoT);
