import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { fetchRegisterMfa } from "../../../../store/actions/auth";
import { Step } from "./../step";
import { AnotherMethod } from "./another-method";
import { Verify } from "./../verify";
import { ResendCode } from "./../resend-code";
import { ErrorField } from "../error-field";
import { joinErrors } from "../../../../helpers/join-errors";
// ? TYPES:
import { SetupStageProps } from "../../../../types/login-form";
import { ApplicationState } from "../../../../store/reducers";

const StageEmailWoT: React.FC<SetupStageProps> = ({
  handleSubmit,
  values,
  setValue,
  rememberDays,
  isLoadingVerify,
  errors,
  mfaState,
  t,
}) => {
  const dispatch = useDispatch();
  const apiErrors = useSelector((state: ApplicationState) => state.credentialsReducer.errors);
  const fieldErrorsWithApi = joinErrors(
    errors as { [k: string]: string },
    apiErrors["VERIFY_REGISTER_MFA"] ? [{ field: "mfaCode", error: apiErrors["VERIFY_REGISTER_MFA"] }] : []
  );
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchRegisterMfa("EMAIL"));
    }
    fetchInitial();
  }, [dispatch]);
  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="box">
        <Step no={1}>
          <p>
            <Trans
              ns="login"
              i18nKey={`mfa.setup.EMAIL.steps.0`}
              values={{ recipient: mfaState.info.obscuredEmail }}></Trans>{" "}
            <ResendCode
              onSend={() => {
                dispatch(fetchRegisterMfa("EMAIL", true));
              }}
              textKey="login:mfa.setup.EMAIL.resendCode"
              disabledTextKey="login:mfa.setup.EMAIL.resendCodeDisabled"
              expiryTimestamp={mfaState.resend["EMAIL"]}
            />
          </p>
          {apiErrors["REGISTER_MFA"] && <ErrorField error={apiErrors["REGISTER_MFA"] as string} />}
        </Step>
        <hr />
        <Step no={2}>
          <Verify
            isLoading={isLoadingVerify}
            error={fieldErrorsWithApi["mfaCode"]}
            values={values}
            setValue={setValue}
            rememberDays={rememberDays}
            autoFocus={true}>
            <p>{t("mfa.setup.EMAIL.steps.1")}</p>
          </Verify>
        </Step>
      </div>
      <AnotherMethod />
    </form>
  );
};

export const StageEmail = withTranslation(["login"])(StageEmailWoT);
