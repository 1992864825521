import React from "react";
// import { DEFAULT_PAGE_LIMIT } from "../../globals/settings";
import { getRandomArbitrary, getRandomWidthStyles } from "../../helpers/random-generators";
import { Checkbox } from "@tscore/react-components";

export const DocumentsEmpty: React.FC<{ length: number }> = ({ length }) => {
  // const list = Array.apply(null, { length: 10 });
  // const list = Array.from({ length: getRandomArbitrary(3, 7) });
  const loadingElementWidths = getRandomWidthStyles((length === 0 ? getRandomArbitrary(3, 7) : length) * 2);
  return (
    <>
      {loadingElementWidths.map((e: any, i: number) => {
        if (i % 2 === 0) {
          return null;
        }
        const hasDescription = Math.round(getRandomArbitrary(0, 1)) === 1 ? true : false;
        return (
          <ul key={i} className="row isFetching">
            <li className="cell checkbox-holder">
              <Checkbox disabled />
            </li>
            <li className="cell name-holder" style={{ textOverflow: "clip" }}>
              {/* <div className="file-type loading-element">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg=="
                alt=""
              />
            </div> */}
              <div className="name loading-element" style={{ width: "22px", marginRight: "4px" }}>
                .
              </div>
              <div className="name loading-element" {...e}>
                .
              </div>
              <div className="name" style={{ visibility: "hidden" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et porta mi.
              </div>
            </li>
            <li className="cell description-holder">
              {hasDescription && (
                <div className="description loading-element" {...loadingElementWidths[i + 1]}>
                  ...
                </div>
              )}
            </li>
            <li className="cell expand-holder"></li>
            <li className="cell uploadedBy-holder">
              <div className="loading-element" style={{ width: "132px" }}>
                ...
              </div>
            </li>
            <li className="cell uploadedOn-holder">
              <div className="loading-element" style={{ width: "97px" }}>
                00/00/0000 00:00
              </div>
            </li>
            <li className="cell more-holder">
              <div className="dropdown-container right" style={{ opacity: 0 }}>
                {/* <span className="dropdown-trigger">
                <i className="material-icons faded pointer">more_horiz</i>
              </span> */}
              </div>
              {/* <div style={{ width: "24px", height: "24px", display: "inline-block" }}></div> */}
            </li>
          </ul>
        );
      })}
    </>
  );
};
