import React, { forwardRef } from "react";
import { Field } from "formik";
import { Input, Checkbox, Select as ReactSelect, Icon, Tippy, DatePicker } from "@tscore/react-components";
import { SimpleRadio as Radio } from "./simple-radio";
// import { setClasses } from "helpers/helpers";
import classNames from "classnames";
import { getIndicator } from "../../helpers/report-helpers";
import {
  // generateBragStylesOrClasses,
  calculateNewIndicatorColourByThreshold,
  PRESSURE_LEVEL_BRAG_MAPPING,
} from "../../helpers/brag-helpers";
import { withTranslation } from "react-i18next";
// ? TYPES:
import { ImportedConfigIndicator, ConfigIndicator, ImportedConfigOption, ConfigOption } from "../../types/forms-config";
import IndicatorNew from "../../types/indicator-new";
import { CurrentAndPastIndicatorStatus } from "../../types/forms/general";
import { TFunction } from "i18next";
import { formatDateToUnixMs, selectedUnixUTCMsToLocalTimeZoneDate } from "../../lang/DateTimeFormats";

// const NEW_BRAG_TOOLTIP_TEXT = "Please enter new indicator values in the fields provided";
// const INDICATOR_VALUE_MISSING_TOOLTIP_TEXT = "Indicator value unavailable";

const generateOptions = (options: ImportedConfigOption[]): ConfigOption[] => {
  return options.reduce((final: ConfigOption[], item: ImportedConfigOption, index: number) => {
    final[index] = {
      value: item.value,
      label: item.title,
    };
    return final;
  }, []);
};

export const FormikInput = (props: any): JSX.Element => {
  return (
    <Field
      name={props._name || props.name}
      render={({ field }: any) => <Input {...field} {...props} _name={undefined} />}
    />
  );
};

export const FormikTextArea = (props: any): JSX.Element => {
  return (
    <Field
      name={props._name}
      render={({ field }: any) => (
        <textarea placeholder={props.placeholder} className="input" style={{ minHeight: "5.2rem" }} {...field} />
      )}
    />
  );
};

export const FormikSelect = (props: any): JSX.Element => {
  const options: ConfigOption[] = generateOptions(props.config.options);
  const settings: any = {
    isMulti: props.config.allowMultiSelect ? true : false,
  };
  const getValue = (
    value: string | string[],
    isMulti: boolean,
    options: ConfigOption[]
  ): ConfigOption | ConfigOption[] | undefined => {
    if (!isMulti) {
      return options.find((v: ConfigOption) => v.value === value[0]) || [];
    }
    return options.reduce((final: any[], v: ConfigOption) => {
      if (!!value && value.includes(v.value as string)) {
        final.push(v);
      }
      return final;
    }, []);
  };
  const handleChange = (selectedOption: any | any[]): void => {
    if (!settings.isMulti) {
      // props.setFieldValue(props._name, selectedOption.value);
      props.setFieldValue(props._name, [selectedOption.value]);
    } else {
      // array
      props.setFieldValue(
        props._name,
        selectedOption === null ? [] : selectedOption.map((option: ConfigOption) => option.value)
      );
    }
  };
  return (
    <Field
      name={props._name}
      render={({ field }: any) => (
        <ReactSelect
          value={getValue(field.value, settings.isMulti, options)}
          classNamePrefix="select"
          className="select"
          options={options}
          onChange={handleChange}
          placeholder={props.placeholder}
          {...settings}
        />
      )}
    />
  );
};

const FormikCheckbox = (props: any): JSX.Element => {
  const { setFieldValue } = props;
  const handleChange = (value: string[]): void => {
    // value can be number also
    if (value.includes(props.value)) {
      const nextValue = value.filter((v: string) => v !== props.value);
      setFieldValue(props._name, nextValue);
    } else {
      const nextValue = value.concat(props.value);
      setFieldValue(props._name, nextValue);
    }
  };
  return (
    <Field
      name={props._name}
      render={({ field }: any) => {
        return (
          <Checkbox
            name={props._name + "_" + props.value}
            checked={field.value.includes(props.value)}
            onChange={() => handleChange(field.value)}>
            {props.label}
          </Checkbox>
        );
      }}
    />
  );
};

export const FormikCheckboxGroup = (props: any): JSX.Element => {
  return props.config.options.map((option: any) => (
    <FormikCheckbox
      key={props._name + "_" + option.value}
      name={props._name}
      label={option.title}
      value={option.value}
      {...props}
    />
  ));
};

const FormikRadio = (props: any): JSX.Element => {
  const { setFieldValue } = props;
  const handleChange = (value: string | number): void => {
    setFieldValue(props._name, [value]);
  };
  return (
    <Field
      name={props._name}
      render={({ field }: any) => {
        return (
          <Radio
            data-name={props._name + "." + props.value}
            checked={field.value.includes(props.value)}
            onChange={() => handleChange(props.value)}>
            {props.label}
          </Radio>
        );
      }}
    />
  );
};

export const FormikRadioGroup = (props: any): JSX.Element => {
  return props.config.options.map((option: any) => (
    <FormikRadio
      key={props._name + "_" + option.value}
      name={props._name}
      label={option.title}
      value={option.value}
      {...props}
    />
  ));
};

export const CustomValueDatePicker = forwardRef(({ onClick, value, onChange, type, ...props }: any, ref: any) => (
  <div ref={ref} className={"icon-input report-date-input"} onClick={onClick}>
    <input
      type="text"
      autoComplete={false}
      {...props}
      onChange={onChange}
      value={value}
      aria-autocomplete="none"
      className={`input input-brag${value === "" ? "" : " brag-blue"}`}
    />
    {/* <i style={{ transform: "translate(-50%,-50%)", left: "50%" }} className="icon material-icons">
      calendar_today
    </i> */}
  </div>
));

const IndicatorDateField = ({
  _name,
  value,
  index,
  handleChange,
  option,
}: {
  _name: string;
  value: any[];
  index: number;
  handleChange: Function;
  option: any;
}): JSX.Element => {
  return (
    <div>
      <DatePicker
        // popperPlacement="top-end"
        selected={selectedUnixUTCMsToLocalTimeZoneDate((value[index] || {}).value)}
        onChange={(date: any) => {
          const hack = date.getHours() === 0 ? new Date(date.getTime() - date.getTimezoneOffset() * 60000) : date;
          handleChange(value, option, formatDateToUnixMs(hack), index);
        }}
        // filterDate={(date: any) => {
        //   return new Date() > date;
        // }}
        customInput={
          <CustomValueDatePicker
            style={{
              maxWidth: "60px",
              padding: "0 0.1rem",
              fontSize: "10px",
            }}
          />
        }
        name={_name + "_" + index}
        placeholderText=" "
      />
    </div>
  );
};

const FormikIndicatorWoT = (props: any): JSX.Element => {
  const { handleChange, isEditable, shouldAddTooltip, indicator, t } = props;
  const isDateIndicator = indicator.dataLabel === 10;
  return (
    <Field
      name={props._name}
      render={({ field }: any) => {
        // console.log(field.value);
        const inputColor =
          isEditable &&
          indicator &&
          calculateNewIndicatorColourByThreshold(indicator, (field.value[props.index] || { value: "" }).value);
        return (
          <span
            className={classNames("cell", "brag-holder", { "empty-indicator": !isEditable })}
            style={{ position: shouldAddTooltip ? "relative" : undefined }}>
            {isEditable && !isDateIndicator && (
              <input
                aria-autocomplete="none"
                name={props._name + "_" + props.index}
                type="number"
                onChange={(event: any) => handleChange(field.value, props.option, event.target.value, props.index)}
                placeholder=""
                value={(field.value[props.index] || { value: "" }).value}
                className={classNames("input", "input-brag", { ["brag-" + inputColor]: !!inputColor })}
                style={{ maxWidth: "60px" }}
              />
            )}
            {isEditable && isDateIndicator && (
              <IndicatorDateField
                _name={props._name}
                index={props.index}
                value={field.value}
                handleChange={handleChange}
                option={props.option}
              />
            )}
            {shouldAddTooltip && (
              <Tippy content={t("tooltipNewBragText")} placement="bottom">
                <span className="tooltip-for-brags">
                  <i className="material-icons color-blue">info</i>
                </span>
              </Tippy>
            )}
          </span>
        );
      }}
    />
  );
};

const FormikIndicator = withTranslation(["regionReports"])(FormikIndicatorWoT);

export const IndicatorTitle = ({ configIndicators }: { configIndicators: ImportedConfigIndicator[] }): JSX.Element => {
  return (
    <header className="thead">
      <div className="row">
        {configIndicators.map((option: ImportedConfigIndicator, index: number) => (
          <span key={option.indicatorId + "_" + index} className="th brag-holder ta-center">
            {option.title}
          </span>
        ))}
      </div>
    </header>
  );
};

export const IndicatorRowWot = ({
  configIndicators,
  indicators,
  showReadableValues,
  t,
}: {
  configIndicators: ImportedConfigIndicator[];
  indicators: CurrentAndPastIndicatorStatus;
  showReadableValues: boolean;
  t: TFunction;
}): JSX.Element => {
  return (
    <div className="row">
      {configIndicators.map((option: ImportedConfigIndicator, index: number) => {
        const found: IndicatorNew | undefined = getIndicator(option, indicators);
        const indicator = found || {
          value: "-",
          readableValue: "-",
        };
        // let bragProps: any = {};
        let color = null;
        if (found) {
          // bragProps = generateBragStylesOrClasses(indicator.colorCodeFg, indicator.colorCodeBg, "brag");
          color = calculateNewIndicatorColourByThreshold(indicator as IndicatorNew, indicator.value);
        }
        const v = showReadableValues ? indicator.readableValue || indicator.value : indicator.value;
        // const isPlainNumber = showReadableValues ? /^[0-9,.]*$/.test(indicator.readableValue || "") : false;
        const value = indicator.value !== null ? v : "-";
        return (
          <span key={option.indicatorId + "_" + index} className="cell brag-holder" style={{ position: "relative" }}>
            <span className={classNames("brag", { ["brag-" + color]: !!color }, { "brag-white": !color })}>
              {value}
              {option.suffix}
            </span>
            {value === "-" && (
              <Tippy content={t("tooltipIndicatorValueMissingText")} placement="bottom">
                <span className="tooltip-inside-brag">
                  <Icon className="color-blue">help</Icon>
                </span>
              </Tippy>
            )}
          </span>
        );
      })}
    </div>
  );
};

export const IndicatorRow = withTranslation(["regionReports"])(IndicatorRowWot);

export const FormikIndicatorGroup = (props: any): JSX.Element => {
  const areAnyEditable = props.config.indicators.some(
    (indicator: ImportedConfigIndicator) => indicator.isEditable === true
  );
  // const indicator = getIndicator(option, props.indicators);
  const handleChange = (
    value: ConfigIndicator[],
    option: ImportedConfigIndicator,
    updatedValue: string,
    index: number
  ): void => {
    const indicator = getIndicator(option, props.indicators);
    if (!indicator) {
      return;
    }
    const color = calculateNewIndicatorColourByThreshold(indicator, updatedValue);
    // is Data type needed?
    const newValue: ConfigIndicator = {
      indicatorId: indicator.indicatorId,
      value: updatedValue !== "" ? parseFloat(updatedValue) : "",
      pressureLevel: PRESSURE_LEVEL_BRAG_MAPPING[color as string] ? PRESSURE_LEVEL_BRAG_MAPPING[color as string] : null,
      displayOption: indicator.displayOption,
      dataLabel: indicator.dataLabel,
      valueType: indicator.valueType,
    };
    props.setFieldValue(props._name, Object.assign([...value], { [index]: newValue }));
    // setFieldValue(props._name, value);
  };
  const lastIndex = props.config.indicators.length - 1;
  return (
    <div className="table main bordered no-horizontal-lines" style={{ width: "auto" }}>
      <IndicatorTitle configIndicators={props.config.indicators} />
      <div className="tbody">
        <IndicatorRow
          showReadableValues={props.showReadableValues}
          configIndicators={props.config.indicators}
          indicators={props.indicators}
        />
        {areAnyEditable && (
          <div className="row">
            {/* {areAnyEditable && <span className="test">new value</span>} */}
            {props.config.indicators.map((option: ImportedConfigIndicator, index: number) => {
              // console.log(option);
              return (
                <FormikIndicator
                  shouldAddTooltip={areAnyEditable && index === lastIndex}
                  key={props._name + "_" + index}
                  index={index}
                  name={props._name}
                  label={option.title}
                  indicator={getIndicator(option, props.indicators)}
                  indicatorId={+option.indicatorId}
                  option={option}
                  isEditable={option.isEditable}
                  handleChange={handleChange}
                  {...props}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
