import React, { useEffect } from "react";
import { withFormik } from "formik";
import { Redirect } from "react-router-dom";
// import { FormikInput } from "../../../components/formik/formik-input";
import history from "../../../../history";
// import classNames from "classnames";
import { setupMfaSchemaDefault } from "../../../../schemas/login.schema";
import { fetchVerifyMfa, resetMfaDetails } from "../../../../store/actions/auth";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { decodeParams } from "../../../../helpers/params";
import { WhichStage } from "./which-stage";
import { WelcomeOption } from "./welcome-option";
import { LOGIN_URL } from "../../consts";
import { accessEnv } from "../../../../access-env";
// ? TYPES:
// import { ApplicationState } from "../../../../store/reducers";
import { TFunction } from "i18next";
import { SelectedFormikProps } from "../../../../types/login-form";
import { MFAState, MFAType, PreloginRegister } from "../../../../store/types/credentials-state";

const mfaSetupState = {
  mfaCode: "",
  rememberDevice: false,
};

interface Props extends SelectedFormikProps<typeof mfaSetupState> {
  url: string;
  searchParams: { type?: MFAType; [key: string]: any };
  onInputChange: () => any;
  isFetching: boolean;
  match: any;
  listOfErrors: string[];
  mfaState: MFAState<PreloginRegister>;
  // fieldErrorsWithApi: { [error: string]: string };
  // [k: string]: any;
}

export const FormMfaSetup = ({
  searchParams,
  resetForm,
  values,
  handleSubmit,
  setFieldValue,
  onInputChange,
  isFetching,
  isSubmitting,
  mfaState,
  setFieldError,
  errors,
}: // fieldErrorsWithApi,
Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("login");
  useEffect(() => {
    resetForm();
    dispatch(resetMfaDetails({ SMS: true }));
  }, [searchParams.type, dispatch, resetForm]);
  const setValue: SelectedFormikProps["setFieldValue"] = (field, value, shouldValidate) => {
    onInputChange();
    setFieldValue(field, value, shouldValidate);
    setFieldError(field, undefined);
  };
  if (mfaState.info.requirement !== "REGISTER") {
    return <Redirect to={LOGIN_URL} />;
  }
  if (["TOTP", "SMS", "EMAIL"].includes(searchParams.type as MFAType)) {
    return (
      <WhichStage
        mfaType={searchParams.type as MFAType}
        mfaState={mfaState}
        isLoadingVerify={isSubmitting || isFetching}
        errors={errors}
        handleSubmit={handleSubmit}
        values={values}
        setValue={setValue}
        rememberDays={7}
      />
    );
  }
  return (
    <form className="login-form">
      <div className="box">
        <div>
          <h5>{t("mfa.setup.init.subtitle")}</h5>
          <p>{t("mfa.setup.init.description", { context: accessEnv("DEPLOYMENT_TYPE") })}</p>
        </div>
        <div className="mt24">
          <div className="mfa__options">
            <WelcomeOption mfaType="TOTP" />
            <WelcomeOption mfaType="SMS" />
            <WelcomeOption mfaType="EMAIL" />
          </div>
        </div>
      </div>
    </form>
  );
};

export const formikEnhancerMfaSetup = withFormik({
  validationSchema: ({ t }: { t: TFunction }) => setupMfaSchemaDefault(t),
  mapPropsToValues: (_props: any) => mfaSetupState,
  handleSubmit: (values, { setSubmitting, props }) => {
    // maybe there is a better way to get mfaType
    const searchParams = decodeParams(props.location.search);
    const payload = values;
    props.fetchVerifyMfa(payload, searchParams.type, history.location.pathname);
    setSubmitting(false);
  },
  validateOnChange: false,
  displayName: "MfaSetupPage",
});

export const mapDispatchToPropsMfaSetup = (dispatch: any) => ({
  fetchVerifyMfa: (values: typeof mfaSetupState, mfaType: MFAType, pathname = "") =>
    dispatch(fetchVerifyMfa(values, mfaType, pathname)),
});
