// import React from "react";
import { ELECTIVE_INTERNAL_URLS } from "../../globals/internal-urls";
//
import { MapPage } from "./pages/home";
import { HomePage } from "./pages/home-reroute";
import { WholeSystemResiliencePage } from "./pages/elective-dashboards";

// ? TYPES:
import { RouteProps } from "react-router";

// const SamplePage = () => null;

export const electiveRoutes: RouteProps[] = [
  {
    exact: true,
    path: ELECTIVE_INTERNAL_URLS.electivehome,
    component: HomePage,
  },
  {
    path: `${ELECTIVE_INTERNAL_URLS.electiveMenuList}/:id/:pretty?`,
    component: MapPage,
  },
  {
    path: `${ELECTIVE_INTERNAL_URLS.electiveDashboardList}/:id/:siteId/:pretty?`,
    component: WholeSystemResiliencePage,
  },
];
