// import { TokenType } from "powerbi-models";
import i18n from "../../../i18n";
// ? TYPES:
import { IEmbedConfiguration } from "powerbi-client";

enum TokenType {
  Aad = 0,
  Embed = 1,
}

const getCurrentLang = () => i18n.language;

export const GENERATE_LOCALE_SETTINGS = (lng = getCurrentLang()) => ({
  localeSettings: {
    language: lng.slice(0, 2),
    formatLocale: lng,
  },
});

export const DEFAULT_REPORT_CONFIG = (): IEmbedConfiguration => ({
  type: "report",
  embedUrl: undefined,
  tokenType: TokenType.Embed,
  accessToken: undefined,
  settings: {
    ...GENERATE_LOCALE_SETTINGS(),
  },
});

export const simpleParamParser = (stringifiedParams: {
  [key: string]: string;
}): { [key: string]: boolean | string | number } => {
  return Object.entries(stringifiedParams as { [key: string]: string }).reduce(
    (final: { [key: string]: boolean | string | number }, [key, value]: [string, string]) => {
      const converted = Number(value);
      if (!isNaN(converted)) {
        final[key] = converted;
        return final;
      }
      const boolOrString: string | boolean = value === "true" ? true : value === "false" ? false : value;
      final[key] = boolOrString;
      return final;
    },
    {}
  );
};
