import { FILE_TYPES } from "./FileTypes";

import WordPNG from "../sass/assets/file-types/icon-wordFile@2x.png";
import ExcelPNG from "../sass/assets/file-types/icon-excelFile@2x.png";
import PdfPNG from "../sass/assets/file-types/icon-acrobatFile@2x.png";
import TablePNG from "../sass/assets/file-types/icon-tableFile@2x.png";
import PowerpointPNG from "../sass/assets/file-types/icon-tableFile@2x.png";

import defaultPNG from "../sass/assets/file-types/icon-defaultFile@2x.png";

export const FILE_ICONS = {
  word: WordPNG,
  excel: ExcelPNG,
  acrobat: PdfPNG,
  table: TablePNG,
  powerpoint: PowerpointPNG,
  default: defaultPNG,
};

export const getFileIconSrc = (extension: string): string => {
  const fileType = (FILE_TYPES as any)["." + extension];
  return fileType ? (FILE_ICONS as any)[fileType] || FILE_ICONS["default"] : FILE_ICONS["default"];
};
