/* eslint-disable @typescript-eslint/no-explicit-any */

export default function generateObjectWithDefaultValue(
  obj: { [key: string]: any },
  {
    defaultValue = undefined,
    transformDefaultProperty = undefined,
  }: { defaultValue?: any; transformDefaultProperty?: (key: string, obj: { [key: string]: any }) => any }
): { [key: string]: any } {
  const defaultValueHandler = {
    get: (obj: { [key: string]: any }, property: string) => {
      if (property in obj) {
        return obj[property];
      }
      if (typeof transformDefaultProperty === "function") {
        return transformDefaultProperty(property, obj);
      }
      return defaultValue;
    },
  };

  return new Proxy(obj, defaultValueHandler);
}
