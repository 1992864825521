import React from "react";
import Meta from "../../components/meta";
import { PersonalisedIndicators } from "./personalised-indicators";
import { ActionsSummary } from "./actions-summary";
import HomeMap from "./home-map";
import { useTranslation } from "react-i18next";
// import { Loader } from "@tscore/react-components";

//? TYPES:
import { RouteComponentProps } from "react-router-dom";

type HomePageProps = RouteComponentProps<any>;

export const HomePage: React.FC<HomePageProps> = ({ history }) => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  return (
    <main id="home">
      <Meta title={t("home:title")} hideMeta />
      <div className="home-holder">
        <section>
          <div className="home_top">
            <div className="actions-holder home_left">
              <ActionsSummary />
            </div>
            <div className="home_right google-map-holder">
              <HomeMap history={history} />
            </div>
          </div>
        </section>
        <PersonalisedIndicators />
      </div>
    </main>
  );
};
