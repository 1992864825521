import React, { useMemo } from "react";
import Meta from "../../components/meta";
import { Icon, Tippy } from "@tscore/react-components";
import { LanguageSwitcherList } from "../../components/structure/language-switcher-list";
import { MetaFooter } from "../../components/structure/meta-footer";
import { Badges } from "../../components/structure/badges";
// import { LOGIN_ERRORS } from "../../lang/error";
import { LOGO } from "../../globals/CompanyMeta";
// import { LANGUAGE_SWITCHER } from "../../globals/settings";
import { useSelector, useDispatch } from "react-redux";
import { clearAuthErrors } from "../../store/actions/auth";
import {
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  LOGIN_URL,
  ACTIVATE_ACCOUNT_URL,
  SETUP_MFA_URL,
  ENFORCE_MFA_URL,
} from "./consts";
import { decodeParams } from "../../helpers/params";
// import { connect } from "react-redux";
// import { FormLogin, formikEnhancerLogin, mapDispatchToPropsLogin } from "./form-login";
// import { FormForgot, formikEnhancerForgot, mapDispatchToPropsForgot } from "./form-forgot";
// import { FormReset, formikEnhancerReset, mapDispatchToPropsReset } from "./form-reset";

import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// ? TYPES:
import { ApplicationState } from "../../store/reducers";
import { FormikProps } from "formik";
import { RouteComponentProps } from "react-router-dom";
import { ModalRefMethods } from "../../types/modal";
import { RefObject } from "react";
// import { TFunction } from "i18next";

type Params = null;

type ExtendedLoginPageProps = RouteComponentProps<Params> & FormikProps<any>;

interface LoginPageProps extends ExtendedLoginPageProps {
  Form: React.FC<any>;
  cookieModalRef: RefObject<ModalRefMethods>;
  // languageModalRef: RefObject<ModalRefMethods>;
}

const getActionLabel = (url: string): string => {
  const labels: any = {
    [LOGIN_URL]: "PRE_LOGIN",
    [FORGOT_PASSWORD_URL]: "FORGOT_PASSWORD_MAIL",
    [RESET_PASSWORD_URL]: "FORGOT_SET_PASSWORD",
    [ACTIVATE_ACCOUNT_URL]: "ACCOUNT_ACTIVATE",
    [SETUP_MFA_URL]: "VERIFY_REGISTER_MFA",
    [ENFORCE_MFA_URL]: "LOG_IN",
  };
  return labels[url] || labels["/login"];
};

// const Form = ({ url, ...props }: any) => {
//   switch (url) {
//     case LOGIN_URL:
//     default:
//       return <FormLogin {...props} />;
//     case FORGOT_PASSWORD_URL:
//       return <FormForgot {...props} />;
//     case RESET_PASSWORD_URL:
//       return <FormReset {...props} />;
//   }
// };

export const LoginPageWrapper: React.FC<LoginPageProps> = ({
  // onKeyDown,
  // receivedErrors,
  // values,
  // touched,
  errors,
  // dirty,
  isSubmitting,
  handleSubmit,
  submitCount,
  match,
  values,
  Form,
  cookieModalRef,
  setFieldValue,
  setFieldError,
  // languageModalRef,
  resetForm,
  // ...props
}) => {
  const { t } = useTranslation();
  const searchParams = useMemo(() => decodeParams(location.search), [location.search]);
  const openCookieModal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (cookieModalRef.current) {
      cookieModalRef.current.show();
    }
  };
  // const openLanguageModal = (e?: any) => {
  //   if (languageModalRef.current) {
  //     languageModalRef.current.show();
  //   }
  //   e.preventDefault();
  // };
  // const {
  //   // onKeyDown,
  //   // receivedErrors,
  //   // values,
  //   // touched,
  //   errors,
  //   // dirty,
  //   isSubmitting,
  //   handleSubmit,
  //   submitCount,
  //   match,
  //   values,
  // } = props;
  // console.log(match);
  // const onKeyDown = ({ keyCode }: any): boolean | void => keyCode === 13 && handleSubmit;
  // const listOfErrors: string[] = [...Object.values(errors), ...receivedErrors];
  // console.log(errors);
  // const [loginForm, setLoginForm] = useState({
  //   username: "karan",
  //   password: "test",
  // });
  // const onInputChange = (e: any) => {
  //   const { value, name } = e.target;
  //   setLoginForm({
  //     ...loginForm,
  //     [name]: value,
  //   });
  // };
  const url = "/" + match.url.split("/")[1];
  const apiErrors = useSelector((state: ApplicationState) => state.credentialsReducer.errors);
  const isFetching = useSelector((state: ApplicationState) => state.credentialsReducer.isFetching);
  const mfaState = useSelector((state: ApplicationState) => state.credentialsReducer.mfa);
  // const forgotMailUsernames = useSelector((state: ApplicationState) => state.credentialsReducer.mailSent);
  const label: string = getActionLabel(url);
  const dispatch = useDispatch();
  const onInputChange = () => dispatch(clearAuthErrors());
  const fieldErrorsWithApi: { [error: string]: string } = {
    ...errors,
    ...(apiErrors[label] && { general: apiErrors[label] as string }),
  };
  const listOfErrors: string[] = Object.values(fieldErrorsWithApi).flat(1) as string[];
  return (
    <div className="login" data-label={label}>
      <Meta title={t("login:mainTitle", { context: searchParams.type })} />
      <div className="left side">
        <section>
          <div className="middle">
            <div className="branding__container">
              <div className="logo__wrapper">
                <LOGO data-reversed={true} />
              </div>
              <p>{t("meta:motto")}</p>
            </div>
          </div>
          <footer>
            <MetaFooter>
              <li>
                <a href="#privacy-and-cookies" onClick={openCookieModal}>
                  {t("footer:Privacy and cookies")}
                </a>
                {/* {LANGUAGE_SWITCHER && (
                  <>
                    {" | "}
                    <a href="#!change-language" onClick={openLanguageModal}>
                      {t("footer:Change Language", { language: i18n.language })}
                    </a>
                  </>
                )} */}
              </li>
            </MetaFooter>
          </footer>
        </section>
      </div>
      <div className="right side">
        <section>
          <header>
            <div>
              {label !== "PRE_LOGIN" && (
                <NavLink to={LOGIN_URL} className="login-back-btn">
                  <Tippy content={t("login:tooltipBackToLogin")} placement="right">
                    <span>
                      <Icon className="pointer">arrow_back</Icon>
                    </span>
                  </Tippy>
                </NavLink>
              )}
              {/* <a href={t("meta:URL")} rel="noopener noreferrer" target="_blank" className="logo"> */}
              <span className="logo__wrapper logo">
                <LOGO />
              </span>
            </div>
          </header>
          <div className="middle">
            <div>
              <h3>
                {/* {TITLES[label]} */}
                {t("login:title_" + label, { context: searchParams.type || mfaState.info.options[0] })}
              </h3>
              {/* <Formik
                initialValues={loginForm}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  dispatch(fetchLogIn(values.username, values.password));
                  setSubmitting(false);
                  // console.log(values);
                  // this.authenticate(values.username, values.password).then(() => {
                  //   if (this.state.errors.length >= 1) {
                  //     setErrors({ username: this.state.errors[0] });
                  //   }
                  //   setSubmitting(false);
                  // });
                }}
                validationSchema={loginSchema}>
                {({ ...props }) => <FormLogin isFetching={isFetching} apiErrors={apiErrors} {...props} />}
              </Formik> */}
              <Form
                url={url}
                setFieldValue={setFieldValue}
                searchParams={searchParams}
                mfaState={mfaState}
                resetForm={resetForm}
                // forgotMailUsernames={forgotMailUsernames}
                setFieldError={setFieldError}
                onInputChange={onInputChange}
                isFetching={isFetching[label]}
                listOfErrors={listOfErrors}
                errors={errors}
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                submitCount={submitCount}
                values={values}
                match={match}
              />
              {/* <form className="login-form" onKeyDown={onKeyDown} onSubmit={handleSubmit}>
                <ul
                  className={classNames("form-error", { "is-visible": submitCount >= 1 && listOfErrors.length >= 1 })}>
                  {listOfErrors.map((error: string) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
                <div className="login-input">
                  <label>Username</label>
                  <FormikInput
                    onKeyDown={onInputChange}
                    name="username"
                    placeholder="Username"
                    autoComplete="username"
                    scale="large"
                  />
                </div>
                <div className="login-input">
                  <label>Password</label>
                  <FormikInput
                    onKeyDown={onInputChange}
                    name="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    type="password"
                    scale="large"
                  />
                </div>
                <div>
                  <Button type="submit" isLoading={isSubmitting || isFetching} scale="large" length="fullwidth">
                    SIGN IN
                  </Button>
                </div>
                <div className="ta-center">
                  <a href="#!">Forgot Password?</a>
                </div>
              </form> */}
            </div>
          </div>
          <footer>
            <div>
              <div className="footer-split">
                <LanguageSwitcherList position="left" />
                {!["VERIFY_REGISTER_MFA", "LOG_IN"].includes(label) && <Badges />}
              </div>
            </div>
          </footer>
        </section>
      </div>
    </div>
  );
  // return (
  //   <div className="m24">
  //     login page:
  //     <input type="text" name="username" value={loginForm.username} onChange={onInputChange} />
  //     <input type="password" name="password" value={loginForm.password} onChange={onInputChange} />
  //     <button style={{ color: "blue" }} onClick={() => dispatch(fetchLogIn(loginForm.username, loginForm.password))}>
  //       login
  //     </button>
  //     {JSON.stringify(errors)}
  //   </div>
  // );
};

// const Lol = connect(null, { Form: FormForgot })(LoginPageWrapper);
