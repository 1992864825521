import React, { useCallback } from "react";
import { LANGUAGE_SWITCHER } from "../../globals/settings";
import { Button } from "@tscore/react-components";
import { Dropdown, Icon } from "@tscore/react-components";
import { useTranslation } from "react-i18next";
import { onChangeLocale } from "../../i18n";
import list from "../../locales/list.json";
// import classNames from "classnames";
// ? TYPES:
// import { ModalRefMethods } from "../../types/modal";

interface LanguageSwitcherListProps {
  tag?: keyof JSX.IntrinsicElements;
  position?: "left" | "right";
}

export const LanguageSwitcherList: React.FC<LanguageSwitcherListProps> = ({ tag = "div", position = "right" }) => {
  const { i18n, t } = useTranslation();
  const changeLanguage = useCallback(
    (lng: string): void => {
      if (i18n.isInitialized && i18n.language !== lng) {
        i18n.changeLanguage(lng);
        onChangeLocale(null, t);
      }
    },
    [t, i18n]
  );
  if (!LANGUAGE_SWITCHER) {
    return null;
  }
  const currentLanguage = list.find((l) => l.language === i18n.language) || { native_language_name: i18n.language };
  // console.log({ i18n });
  return (
    <Dropdown
      tag={tag}
      position={position}
      className="user-profile language-switcher"
      trigger={
        <Button colour="link" scale="large" className="nowrap">
          <Icon style={{ paddingRight: "0.4rem" }}>language</Icon>
          <span>{currentLanguage.native_language_name}</span>
          {/* <span style={{ paddingLeft: "0.4rem" }}></span> */}
          {/* <span className="arrow_down">
            <Icon>arrow_drop_down</Icon>
          </span> */}
        </Button>
      }>
      {list
        .filter((l) => l.language !== i18n.language)
        .map((l) => (
          <Dropdown.Item
            key={l.language}
            label={l.native_language_name}
            onItemClick={() => changeLanguage(l.language)}
          />
        ))}
    </Dropdown>
  );
};

export const LanguageSwitcherItem: React.FC<any> = () => {
  const { i18n, t } = useTranslation();
  const changeLanguage = useCallback(
    (lng: string): void => {
      if (i18n.isInitialized && i18n.language !== lng) {
        i18n.changeLanguage(lng);
        onChangeLocale(null, t);
      }
    },
    [t, i18n]
  );
  if (!LANGUAGE_SWITCHER) {
    return null;
  }
  const currentLanguage = list.find((l) => l.language === i18n.language) || { native_language_name: i18n.language };
  return (
    <li className="inception-dropdown-item">
      <a href="#!change-language" onClick={(e) => e.preventDefault()}>
        <span className="inception-dropdown__label">
          <Icon className="icon-left">language</Icon>
          <span>{currentLanguage.native_language_name}</span>
          <span className="inception-dropdown__arrow">
            <Icon>arrow_drop_down</Icon>
          </span>
        </span>
      </a>
      <ul className="dropdown" style={{ display: "block" }}>
        {list
          .filter((l) => l.language !== i18n.language)
          .map((l) => (
            <Dropdown.Item
              key={l.language}
              label={l.native_language_name}
              onItemClick={() => changeLanguage(l.language)}
            />
          ))}
      </ul>
    </li>
  );
};
