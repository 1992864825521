import React, { useEffect, useState } from "react";
import Meta from "../../../components/meta";
import { useSelector } from "react-redux";
import { DocumentsInner } from "../../../pages/documents/documents-inner";
import {
  fetchDocuments,
  fetchDownloadDocument,
  fetchDeleteDocuments,
  resetDocumentUploadProgress,
  fetchUploadDocument,
  updateDocumentUploadProgress,
  fetchUpdateDocumentMeta,
} from "../../../store/actions/nhsr-documents";
import { findTitleByNameAndId } from "../../../helpers/menu-helpers";

//? TYPES:
// import { Document } from "../../types/document";
import { ApplicationState } from "../../../store/reducers";
import { RouteComponentProps } from "react-router-dom";

interface Params {
  id: string;
  pretty?: string;
}

type DocumentsPageProps = RouteComponentProps<Params>;

export const DocumentsPage: React.FC<DocumentsPageProps> = (props) => {
  const [title, setTitle] = useState("");
  const id = parseInt(props.match.params.id, 10);
  const state = useSelector(
    (state: ApplicationState) => state.nhsrDocumentsReducer[id] || state.nhsrDocumentsReducer[-1]
  );
  const rba = useSelector(
    (state: ApplicationState) => state.menuReducer.rba["NHS Region"][id] || state.menuReducer.rba["NHS Region"][-1]
  );
  const menuState = useSelector((state: ApplicationState) => state.menuReducer);
  const { isFetching, menu } = menuState;
  useEffect(() => {
    if (isFetching === false) {
      setTitle(findTitleByNameAndId(menu["NHS Region"], "Surge & Escalation Plans", id));
    }
  }, [isFetching, id, setTitle, menu]);
  return (
    <>
      <Meta isFetching={isFetching} title={title} breadcrumbs={["Surge & Escalation Plans"]} />
      <DocumentsInner
        id={id}
        fetchDocuments={fetchDocuments}
        fetchDownloadDocument={fetchDownloadDocument}
        fetchDeleteDocuments={fetchDeleteDocuments}
        resetDocumentUploadProgress={resetDocumentUploadProgress}
        fetchUploadDocument={fetchUploadDocument}
        updateDocumentUploadProgress={updateDocumentUploadProgress}
        fetchUpdateDocumentMeta={fetchUpdateDocumentMeta}
        state={state}
        rba={{
          canDeleteDocument: !!rba.DOCUMENTS_DELETE,
          canUploadDocument: !!rba.DOCUMENTS_UPLOAD,
        }}
        {...props}
      />
    </>
  );
};
