import ProfileService from "./profile.service";
import { cancelRequest } from "../../../helpers/cancel-request";

// ? TYPES:
import { ApiAction } from "../../types/api";
// import { ApplicationState } from "../../../store/reducers";
import { ProfileDetails, NewPassword } from "../../../schemas/my-account.schema";

const getProfile = (data: any) => ({
  type: "GET_PROFILE",
  data,
});

const getOrganisationList = (data: any) => ({
  type: "GET_ORGANISATION_LIST",
  data,
});

const updateProfile = (data: ProfileDetails) => ({
  type: "UPDATE_PROFILE",
  data,
});

const updatePassword = (data: NewPassword) => ({
  type: "UPDATE_PASSWORD",
  data,
});

export const clearMyAccountErrors = () => ({
  type: "CLEAR_MY_ACCOUNT_ERRORS",
});

export const fetchProfile = (): ApiAction => {
  cancelRequest("GET_PROFILE");
  return ProfileService.getProfile({
    label: "GET_PROFILE",
    onSuccess: getProfile,
  });
};

export const fetchOrganisationList = (): ApiAction => {
  cancelRequest("GET_ORGANISATION_LIST");
  return ProfileService.getOrganisationList({
    label: "GET_ORGANISATION_LIST",
    onSuccess: getOrganisationList,
  });
};

export const fetchUpdateProfile = (values: ProfileDetails): ApiAction => {
  cancelRequest("UPDATE_PROFILE");
  return ProfileService.updateProfile(values, {
    label: "UPDATE_PROFILE",
    onSuccess: updateProfile,
  });
};

export const fetchUpdatePassword = (values: NewPassword): ApiAction => {
  cancelRequest("UPDATE_PASSWORD");
  return ProfileService.updatePassword(values, {
    label: "UPDATE_PASSWORD",
    onSuccess: updatePassword,
  });
};
