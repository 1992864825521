import { multiLineSplitter } from "./multiLineSplitter";
import { generateDyOffset } from "./generateDyOffset";
import { pxToEm } from "./pxEmConverter";

// import map from "lodash/map";
import { UnitsData, OuterCircleData, InnerCircleData, WheelInput } from "../types/index";

export const LINE_HEIGHT = 1.1; // ems
const MAX_LENGTH_LINE = 12; // letter length

const generateWheelData = (
  data: WheelInput[],
  radius: number,
  spacing_inner_text: number,
  innerFontSizePx: number,
): {
  innerCircleData: InnerCircleData[];
  outerCircleData: OuterCircleData[];
  unitsData: UnitsData[];
  single_angle: number;
  radialPoints: number[][];
} => {
  let child_index = 0;
  const innerCircleData: InnerCircleData[] = [];
  const outerCircleData: OuterCircleData[] = [];
  const unitsData: UnitsData[] = [];
  const segmentIdList = [];
  const segmentTitles = {};
  const radialPoints = [];

  for (let i = 0; i < data.length; i += 1) {
    const obj = data[i];

    //add parent json eg {id:0, sections: 4, color: "#ff00ff", title: "Attendances"}
    outerCircleData.push({
      id: i,
      unitId: obj.unitId,
      sections: obj.childUnitList.length,
      colorFg: obj.colorCodeFg,
      colorBg: obj.colorCodeBg,
      title: obj.name,
      childIds: obj.childUnitList.map((item) => {
        return { id: item.unitId, title: item.name };
      })
    });
    segmentTitles[obj.unitId] = obj.name;
    for (let j = 0; j < obj.childUnitList.length; j += 1) {
      const unit = obj.childUnitList[j];
      segmentIdList.push({ id: unit.unitId, name: unit.name });
      segmentTitles[unit.unitId] = unit.name;
      //add unit json eg {id:0, parent_section: 1, sections: 1, color: "#ff00ff", title: "A&E Attendances"}
      const multiTitle = multiLineSplitter(unit.name, MAX_LENGTH_LINE, 0);
      innerCircleData.push({
        id: child_index,
        unitId: unit.unitId,
        parent_section_id: i,
        parent_name: obj.name,
        sections: 1,
        title: unit.name,
        idLabel: unit.unitId,
        isEscalated: unit.escalated,
        multiTitle: multiTitle,
        multiTitleDy: generateDyOffset(multiTitle.length, LINE_HEIGHT, pxToEm(innerFontSizePx))
      });

      unitsData.push({
        id: child_index,
        unitId: unit.unitId,
        title: unit.name,
        parent_section_id: i,
        level: unit.currentLevel,
        colorFg: unit.colorCodeFg,
        colorBg: unit.colorCodeBg,
        isEscalated: unit.escalated
      });
      child_index += 1;
    }
  }
  const single_angle = (Math.PI * 2) / child_index;
  for (let k = 0; k < child_index; k += 1) {
    //                var x2=radius*Math.cos(single_angle*k);
    //                var y2=radius*Math.sin(single_angle*k);
    const x2 = -Math.sin(single_angle * k) * (radius + spacing_inner_text / 3);
    const y2 = -Math.cos(single_angle * k) * (radius + spacing_inner_text / 3);
    radialPoints.push([x2, y2]);
  }
  return {
    innerCircleData: innerCircleData,
    outerCircleData: outerCircleData,
    unitsData: unitsData,
    single_angle: single_angle,
    radialPoints: radialPoints
  };
};

export default generateWheelData;
