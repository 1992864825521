import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultPagination } from "../default-pagination";
import { paginate } from "../../../helpers/paginate";
import { downloadFile } from "../../../helpers/download-file";
import { addUploadError } from "./add-upload-error";
import { prependDocument } from "./prepend-document";
import { NAMES } from "../../actions/documents/NAMES";
//? TYPES
import { DocumentsState } from "../../types/documents-state";
import { Document } from "../../../types/document";

const defaultState: DocumentsState = {
  isFetching: true,
  isUploading: false,
  isDeleting: {},
  isEditing: {},
  didInvalidate: false,
  meta: defaultPagination,
  documents: [],
  errors: {},
  uploadProgress: {},
  uploadErrors: {},
  downloadProgress: {},
};

const documents = (state: DocumentsState = defaultState, action: any): any => {
  // console.log(action);
  switch (action.type) {
    case API_START: {
      if (action.payload === NAMES["GET_DOCUMENTS"]) {
        return {
          ...state,
          isFetching: true,
          didInvalidate: false,
        };
      }
      if (action.payload === NAMES["UPLOAD_MULTIPLE_DOCUMENTS"]) {
        return {
          ...state,
          isUploading: true,
        };
      }
      if (action.payload === NAMES["DELETE_DOCUMENTS"]) {
        const deletingList = action.documentIdList.reduce((final: { [documentId: string]: true }, current: string) => {
          return {
            ...final,
            [current]: true,
          };
        }, {});
        return {
          ...state,
          isDeleting: deletingList,
        };
      }
      if (action.payload === NAMES["UPDATE_DOCUMENT_META"]) {
        return {
          ...state,
          isEditing: {
            ...state.isEditing,
            [action.documentId]: true,
          },
        };
      }
      return state;
    }
    case API_ERROR: {
      return defaultApiError(state, action);
    }
    case API_END: {
      if (action.payload === NAMES["GET_DOCUMENTS"]) {
        return {
          ...state,
          isFetching: false,
        };
      }
      if (action.payload === NAMES["UPLOAD_MULTIPLE_DOCUMENTS"]) {
        return {
          ...state,
          isUploading: false,
        };
      }
      if (action.payload === NAMES["DELETE_DOCUMENTS"]) {
        return {
          ...state,
          isDeleting: {},
        };
      }
      if (action.payload === NAMES["UPDATE_DOCUMENT_META"]) {
        return {
          ...state,
          isEditing: {},
        };
      }
      return state;
    }
    case NAMES["GET_DOCUMENTS"]:
      // console.log(action);
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        meta: paginate(action.data.totalRecords, action.page, action.data.limit),
        documents: action.data.results,
        errors: {},
      };
    case NAMES["UPDATE_DOCUMENT_UPLOAD_PROGRESS"]: {
      return {
        ...state,
        uploadProgress: {
          ...state.uploadProgress,
          [action.fileName]: action.percentage,
        },
      };
    }
    case NAMES["UPDATE_DOCUMENT_DOWNLOAD_PROGRESS"]: {
      return {
        ...state,
        downloadProgress: {
          ...state.downloadProgress,
          [action.documentId]: action.percentage,
        },
      };
    }
    case NAMES["DOWNLOAD_DOCUMENT"]: {
      downloadFile(action.data, action.fileName + "." + action.extension);
      return {
        ...state,
        downloadProgress: {
          ...state.downloadProgress,
          [action.documentId]: 100,
        },
      };
    }
    case NAMES["UPLOAD_DOCUMENT"]: {
      return {
        ...state,
        ...prependDocument(
          state.documents,
          state.meta,
          state.uploadProgress,
          action.data.documentUploadList,
          action.originalFile
        ),
        uploadErrors: addUploadError(state.uploadErrors, action.data.documentUploadExceptionList),
      };
    }
    case NAMES["RESET_DOCUMENT_UPLOAD_PROGRESS"]: {
      return {
        ...state,
        uploadProgress: {},
        uploadErrors: {},
      };
    }
    case NAMES["UPLOAD_MULTIPLE_DOCUMENTS"]: {
      // not used atm
      // TODO: replace uploadded documents
      return state;
    }
    case NAMES["DELETE_DOCUMENTS"]: {
      const idList = action.data.map((d: Document) => d.documentId);
      //filter
      return {
        ...state,
        documents: state.documents.filter((document) => !idList.includes(document.documentId)),
        meta: paginate(
          state.meta.totalItems - idList.length,
          state.meta.currentPage,
          state.meta.pageSize - idList.length
        ),
      };
    }
    case NAMES["UPDATE_DOCUMENT_META"]: {
      return {
        ...state,
        documents: state.documents.map((document: Document) => {
          if (document.documentId === action.data.documentId) {
            return action.data;
          }
          return document;
        }),
      };
    }
    default:
      return state;
  }
};

const documentsByAgency = (
  state: { [agencyId: string]: DocumentsState } = { "-1": defaultState },
  action: any
): any => {
  switch (action.type) {
    case API_START:
    case API_ERROR:
    case API_END:
    case NAMES["GET_DOCUMENTS"]:
    case NAMES["UPLOAD_MULTIPLE_DOCUMENTS"]:
    case NAMES["DELETE_DOCUMENTS"]:
    case NAMES["UPDATE_DOCUMENT_META"]:
    case NAMES["UPLOAD_DOCUMENT"]:
    case NAMES["UPDATE_DOCUMENT_UPLOAD_PROGRESS"]:
    case NAMES["RESET_DOCUMENT_UPLOAD_PROGRESS"]:
    case NAMES["UPDATE_DOCUMENT_DOWNLOAD_PROGRESS"]:
    case NAMES["DOWNLOAD_DOCUMENT"]:
      if (!action.agencyId) {
        return state;
      }
      return {
        ...state,
        [action.agencyId]: documents(state[action.agencyId], action),
      };
    default:
      return state;
  }
};

export default documentsByAgency;
