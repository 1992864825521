import { DEFAULT_PAGE_LIMIT } from "../../globals/settings";

import { PaginationMeta } from "../../types/pagination-meta";

export const defaultPagination: PaginationMeta = {
  currentPage: 1,
  endPage: 1,
  pageSize: DEFAULT_PAGE_LIMIT,
  pages: [1],
  showingFrom: () => 1,
  showingTo: () => DEFAULT_PAGE_LIMIT,
  startPage: 1,
  totalItems: DEFAULT_PAGE_LIMIT,
  totalPages: 1,
};
