// import React from "react";
// import { renderToStaticMarkup } from "react-dom/server";
// import { LOGO } from "./CompanyMeta";
// import { SVGNHSEngland } from "./product-icons";

export const requiredCSSForDownload = [
  "https://fonts.googleapis.com/icon?family=Material+Icons&amp;display=block",
  "https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Roboto:300,400,500,700&amp;display=swap",
];

// const getStaticSvgString = (Component: any) => {
//   return renderToStaticMarkup(<Component />);
// };

// const LOGO_ASSETS = {
//   TS: () => getStaticSvgString(LOGO),
//   // SHREWD: () => getStaticSvgString(SVGResilience),
//   REGIONS: () => getStaticSvgString(SVGNHSEngland),
// };

const LOGOS = {
  TS: {
    link: "https://s3.eu-west-2.amazonaws.com/static.e-shrewd.com/public/files/logos/png/shrewd-logo_width240_dpichrome.png",
    width: 120,
  },
  REGIONS: {
    link: "https://s3.eu-west-2.amazonaws.com/static.e-shrewd.com/public/files/logos/png/icon-region_width190_dpichromepng.png",
    width: 95,
  },
  // SHREWD: {
  //   link: "",
  //   width: 95,
  // },
};

export const GLOBAL_PDF_SETTINGS = { cssLinks: requiredCSSForDownload };

interface PDFHeader {
  title?: string | null;
  subtitle?: string | null;
  meta?: {
    title?: string | null;
    subtitle?: string | null;
  };
}

//    <img src="${LOGO_ASSETS.TS()}" alt="Transforming Systems" style="width:${TS_LOGO_WIDTH}px;"/>

export function generatePdfWrapper(content: string, header: PDFHeader): string {
  let html = "";
  html += `<style>
    html {font-size: 44% !important;}
    main {display:block;height:auto;padding:0 !important;}
    #header-meta-pdf {padding-bottom: 4px; padding-top:4px; padding-left:8px; background:rgba(0,0,0,0.02);}
    #header-meta-pdf h2 {letter-spacing:0;}
    #header-meta-pdf h3 {letter-spacing:0; color:#60738d;font-weight:400 !important;}
    .svg-pdf {
      width: 100%;
      height: 0;
      padding-top: 100%;
      position: relative;
    }
    .svg-pdf svg {
      position: absolute;
      top: 0;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .logos {
      margin-top: -2.4rem;
      display: -webkit-box; 
      display: -webkit-flex; 
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      width: 100%;
    }
    .content-header .dropdown-container { display: none; }
  </style>
  <div class="logos" style="min-height:120px;">
    <div><img src="${LOGOS.REGIONS.link}" style="width:${LOGOS.REGIONS.width}px;" /></div>
    <div>`;
  if (header.title) {
    html += `<h1>${header.title}</h1>
      ${header.subtitle ? `<h2>${header.subtitle}</h2>` : ""}`;
  }
  html += `</div>
    <div><img src="${LOGOS.TS.link}" style="width:${LOGOS.TS.width}px;" /></div>
  </div>
  <div class="theme-default">`;

  if (header.meta) {
    html += `<hr class="meta-divider-pdf mt0 mb0"/>
    <aside id="header-meta-pdf">
    ${header.meta.title ? `<h2 class="mb0">${header.meta.title}</h2>` : ""}
    ${header.meta.subtitle ? `<h3 class="mb0">${header.meta.subtitle}</h3>` : ""}
    </aside>`;
  }
  if (!!header.title || !!header.meta) {
    html += '<hr class="mb24 mt0"/>';
  }

  html += content;
  html += "</div>";
  return html;
}
