import { apiAction } from "../api";
import { SERVICE_URLS } from "../../../globals/service-urls";
import { transformGetMenu } from "./transforms/get-menu.transform";

// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";

const getMenu = (callbacks: ApiCallback): ApiAction => {
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["user-service"]}/account/menu`,
    transformResponse: transformGetMenu,
    ...callbacks,
  });
};

const MenuService = {
  getMenu,
};

export default MenuService;
