import { deleteStorage } from "../../helpers/localstorage";
import { SHOULD_CACHE_MENU } from "../../globals/settings";

const CREDENTIALS_NAME = "credentialsReducer";
const MENU_NAME = "menuReducer";

const DELETE_STORE: any = {
  LOG_OUT: [CREDENTIALS_NAME, SHOULD_CACHE_MENU ? MENU_NAME : null].filter(Boolean),
};

const deleteCacheMiddleware =
  (_store: any) =>
  (next: any) =>
  (action: { type: string; [key: string]: any } = { type: "" }): any => {
    const { type } = action;
    if (DELETE_STORE[type]) {
      deleteStorage(DELETE_STORE[type]);
    }
    return next(action);
  };

export default deleteCacheMiddleware;
