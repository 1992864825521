import React from "react";
import { withTranslation, Trans } from "react-i18next";
import { Button, Modal } from "@tscore/react-components";
//? TYPES:
import { WithTranslation } from "react-i18next";

interface ModalDeletePropsBase {
  onDelete: () => void;
  id: number | string;
  areaName: string;
}

type ModalDeleteProps = ModalDeletePropsBase & WithTranslation<"regionReports">;

const ModalDeleteWoT: React.FC<ModalDeleteProps> = ({ onDelete, id, areaName, t }) => (
  <div id="modal-delete-report">
    <Modal.Header title={t("modalDeleteReport.title")} titleClassName="color-red" />
    <Modal.Body>
      <p>
        <Trans
          i18nKey="regionReports:modalDeleteReport.body"
          defaults=""
          values={{ id, areaName }}
          components={{ b: <strong /> }}
        />
      </p>
      {/* <p>{t("modalDeleteReport.body", { id: -1, areaName: "Sample" })}</p> */}
    </Modal.Body>
    <Modal.Footer>
      <Button colour="link" data-close-modal>
        {t("modalDeleteReport.cancel")}
      </Button>
      <Button colour="red" onClick={onDelete} data-close-modal>
        {t("modalDeleteReport.confirm")}
      </Button>
    </Modal.Footer>
  </div>
);

export const ModalDelete = withTranslation("regionReports")(ModalDeleteWoT);
