import { useEffect } from "react";
// ? TYPES:
import { RouteComponentProps } from "react-router-dom";

export const useNewVersionHistoryCheck = (history: RouteComponentProps["history"]) => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
      history.listen(() => {
        navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
      });
    }
  }, []);
};
