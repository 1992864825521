import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultPagination } from "../default-pagination";
import { paginate } from "../../../helpers/paginate";
import { updateIndicator, updateUnsaved } from "./update-indicator";
import { saveIndicators, resetIndicator } from "./save-indicators";
import { arrayToObject } from "../../../helpers/array-to-object";
import { moveItem, generateDndMeta, getFirstOrderId, GENERATE_EMPTY_INDICATOR } from "./dnd";
// import { LOCAL_updateGreyIndicatorBatch } from "../../utils/local-update-grey-indicators";

//? TYPES
import { ManualUpdatesState, UnsavedIndicators } from "../../types/manual-updates-state";

const defaultState: ManualUpdatesState = {
  isFetching: true,
  isSaving: false,
  didInvalidate: false,
  // indicatorsRaw: [],
  label: "",
  meta: defaultPagination,
  // totalRecords: 0,
  indicators: [],
  // filters: [],
  indicatorsRaw: [],
  unsaved: {}, // null,
  dndMeta: {
    isPrevDropAllowed: false,
    isNextDropAllowed: false,
    ids: [],
  },
  firstOrderId: 1,
  lastDndAction: undefined,
  apiMeta: {
    totalFavourites: 0,
  },
  errors: {},
};

const indicators = (state: ManualUpdatesState = defaultState, action: any): any => {
  // console.log(action);
  switch (action.type) {
    case API_START: {
      if (action.payload === "GET_INDICATORS") {
        return {
          ...state,
          isFetching: true,
          didInvalidate: false,
          unsaved: {}, // resets the unsaved when getting indicators, maybe no reset?
        };
      }
      if (action.payload === "SAVE_UNSAVED_INDICATORS") {
        return {
          ...state,
          isSaving: true,
        };
      }
      if (action.payload === "TOGGLE_FAVOURITE") {
        // ? Update favourite indicator immediately (before api success)
        const indicator: UnsavedIndicators[0] = action.indicator;
        const isFavourite = !indicator.isFavourite;
        const newIndicator = {
          ...action.indicator,
          isFavourite,
          orderId: isFavourite ? indicator.orderId : undefined,
        };
        const totalFavourites = state.apiMeta.totalFavourites;
        const newTotalFavourites = isFavourite ? totalFavourites + 1 : totalFavourites - 1;
        return {
          ...state,
          indicators: updateIndicator(state.indicators, newIndicator),
          // firstOrderId: getFirstOrderId(state.meta, newTotalFavourites),
          apiMeta: {
            ...state.apiMeta,
            totalFavourites: newTotalFavourites,
          },
        };
      }
      if (action.payload === "MOVE_FAVOURITE") {
        const { newIndex, oldIndex, overId } = action;
        const indicators = moveItem(state.indicators, oldIndex, newIndex, {
          defaultItem: GENERATE_EMPTY_INDICATOR(),
          overId,
        });
        return {
          ...state,
          indicators,
          dndMeta: generateDndMeta(indicators, state.meta, state.apiMeta.totalFavourites),
          lastDndAction: ["prev", "next"].includes(action.overId) ? action.overId : undefined,
        };
      }
      return state;
    }
    case API_ERROR: {
      if (action.payload.label === "TOGGLE_FAVOURITE") {
        // ? when toggle favourite fails --> bring back the old indicator
        const indicator: UnsavedIndicators[0] = action.indicator;
        const isFavourite = indicator.isFavourite;
        const newIndicator = indicator;
        const stateWithError = defaultApiError(state, action);
        const totalFavourites = state.apiMeta.totalFavourites;
        const newTotalFavourites = isFavourite ? totalFavourites + 1 : totalFavourites - 1;
        return {
          ...stateWithError,
          indicators: updateIndicator(state.indicators, newIndicator),
          // firstOrderId: getFirstOrderId(state.meta, newTotalFavourites),
          apiMeta: {
            ...state.apiMeta,
            totalFavourites: newTotalFavourites,
          },
        };
      }
      if (action.payload.label === "MOVE_FAVOURITE") {
        const stateWithError = defaultApiError(state, action);
        const { newIndex, oldIndex, overId } = action;
        const indicators = moveItem(state.indicators, newIndex, oldIndex, {
          defaultItem: GENERATE_EMPTY_INDICATOR(),
          overId,
        });
        return {
          ...stateWithError,
          indicators,
          dndMeta: generateDndMeta(indicators, state.meta, state.apiMeta.totalFavourites),
        };
      }
      return defaultApiError(state, action);
    }
    case API_END: {
      if (action.payload === "GET_INDICATORS") {
        return {
          ...state,
          isFetching: false,
        };
      }
      if (action.payload === "SAVE_UNSAVED_INDICATORS") {
        return {
          ...state,
          isSaving: false,
        };
      }
      //"TOGGLE_FAVOURITE"
      return state;
    }
    case "GET_INDICATORS": {
      const indicators = action.data.results;
      const meta = paginate(action.data.totalRecords, action.page, action.data.limit);
      const totalFavourites = action.data.meta.totalFavourites;
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        unsaved: Object.keys(state.unsaved).length === 0 ? {} : state.unsaved,
        meta: paginate(action.data.totalRecords, action.page, action.data.limit),
        // totalRecords: action.response.totalRecords,
        //TODO: Find a better way to get label
        label: action.data.results.length >= 1 ? action.data.results[0].organisationName : state.label,
        indicators: action.data.results,
        indicatorsRaw: action.data.results,
        dndMeta: generateDndMeta(indicators, meta, totalFavourites),
        firstOrderId: getFirstOrderId(meta, totalFavourites),
        errors: {},
        lastDndAction: undefined,
        apiMeta: {
          totalFavourites,
        },
      };
    }
    case "UPDATE_INDICATOR": {
      return {
        ...state,
        indicators: updateIndicator(state.indicators, action.indicator),
        // indicatorsRaw: updateIndicator(state.indicatorsRaw, action.indicator),
        unsaved: updateUnsaved(state.unsaved, action.indicator),
      };
    }
    case "SAVE_UNSAVED_INDICATORS": {
      const [successResponse, erroredResponse]: [any, { indicatorId: number; [key: string]: any }[]] = action.response;
      const newUpdatedIndicators = saveIndicators(state.indicators, state.unsaved, successResponse);
      const newUnsaved: UnsavedIndicators = erroredResponse.reduce(
        (acc, curr) => updateUnsaved(acc, state.unsaved[curr.indicatorId]),
        {}
      );
      return {
        ...state,
        // isSaving: false,
        indicators: newUpdatedIndicators,
        indicatorsRaw: newUpdatedIndicators,
        unsaved: newUnsaved,
        errors: {},
      };
    }
    case "DISCARD_INDICATORS": {
      return {
        ...state,
        indicators: state.indicatorsRaw,
        unsaved: {},
        errors: {},
      };
    }
    case "TOGGLE_FAVOURITE": {
      const orderIds = arrayToObject<{ indicatorId: number; orderId: number }>(action.orderIdList, "indicatorId");
      const updatedIndicators = state.indicators.map((indicator) => {
        const found = orderIds[indicator.indicatorId];
        if (found) {
          return {
            ...indicator,
            orderId: found.orderId,
          };
        }
        return indicator;
      });
      return {
        ...state,
        indicators: updatedIndicators,
        indicatorsRaw: updatedIndicators.map(resetIndicator),
      };
    }
    case "MOVE_FAVOURITE": {
      const orderIds = arrayToObject<{ indicatorId: number; orderId: number }>(action.orderIdList, "indicatorId");
      const updatedIndicators = state.indicators.map((indicator) => {
        const found = orderIds[indicator.indicatorId];
        if (found) {
          return {
            ...indicator,
            orderId: found.orderId,
          };
        }
        return indicator;
      });
      return {
        ...state,
        indicators: updatedIndicators,
        indicatorsRaw: updatedIndicators.map(resetIndicator),
      };
    }
    default:
      return state;
  }
};

const indicatorsByAgency = (
  state: { [agencyId: string]: ManualUpdatesState } = { "-1": defaultState },
  action: any
): any => {
  switch (action.type) {
    case API_START:
    case API_ERROR:
    case API_END:
    case "GET_INDICATORS":
    case "UPDATE_INDICATOR":
    case "SAVE_UNSAVED_INDICATORS":
    case "DISCARD_INDICATORS":
    case "TOGGLE_FAVOURITE":
    case "MOVE_FAVOURITE":
      if (!action.agencyId) {
        return state;
      }
      return {
        ...state,
        [action.agencyId]: indicators(state[action.agencyId], action),
      };
    default:
      return state;
  }
};

export default indicatorsByAgency;
