import { paginate } from "../../helpers/paginate";
// ? TYPES:
import { PaginatedResponse } from "../types/api";

export function changeToLastPageIfNeeded(
  changePageCallback: ((page: number) => void) | undefined,
  page: number,
  response: PaginatedResponse
) {
  if (changePageCallback && page > 1 && response.totalRecords > 0 && response.results.length === 0) {
    // if page is too high navigate to endPage
    const { endPage } = paginate(response.totalRecords, page, response.limit);
    changePageCallback(endPage);
  }
}
