import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfig } from "../../providers/config.provider";
import { fetchTableauList } from "../../store/actions/integrations";
import Meta from "../../components/meta";
import { Pagination, InlineError } from "@tscore/react-components";
import { ViewSwitcher } from "../../components/view-switcher";
import { withTranslation } from "react-i18next";
// import classNames from "classnames";
import { useSearchParams } from "../../hooks/use-search-params";
import { NavLink } from "react-router-dom";
import { TableauRepositoryTableEmpty, TableauRepositoryGridEmpty } from "./empty";
import { useChangePage } from "../../hooks/impure/use-change-page";
import { SearchInput } from "../../components/search-input";
import { SortHeading } from "../../components/sort/sort-heading";
import { ClearFilters } from "../../components/sort/clear-filters";
//? TYPES:
import { ApplicationState } from "../../store/reducers";
import { RouteComponentProps } from "react-router-dom";
import { TFunction } from "i18next";
import { IntegrationListItem } from "../../store/types/integrations-state";
import { SortDropdown } from "../../components/sort/sort-dropdown";

interface Params {
  id: string;
  pretty?: string;
}

interface TableauRepositoryProps extends RouteComponentProps<Params> {
  t: TFunction;
}

interface ViewPropsBase {
  isFetching?: boolean;
  params: Params;
  data: IntegrationListItem[];
}
const TableauRepositoryTableWoT: React.FC<
  ViewPropsBase & {
    t: TFunction;
    searchParams: ReturnType<typeof useSearchParams>;
  }
> = ({ isFetching, data, params, searchParams, t }) => {
  const headings: { value: string; className?: string; sortName?: string; min: string; max: string }[] = [
    { value: t("tableau:mainTable.0"), className: "name-holder", min: "200px", max: "1fr", sortName: "name" },
    { value: t("tableau:mainTable.1"), min: "150px", max: "2fr" },
  ];
  const gridTemplateColumns = headings.map(({ min, max }) => `minmax(${min}, ${max})`).join(" ");
  return (
    <div
      role="table"
      className="table grid-table bordered no-vertical-lines main shadow-hover-effect shadowed"
      style={{ position: "relative", gridTemplateColumns }}>
      <header className="thead" role="rowgroup">
        <ul className="row" role="row">
          {headings.map((cell) => (
            <SortHeading key={cell.value} cell={cell} parsedSort={searchParams.parsedSort} />
          ))}
        </ul>
      </header>
      <div className="tbody" role="rowgroup">
        {isFetching ? (
          <TableauRepositoryTableEmpty length={data.length} />
        ) : (
          data.map((item) => {
            const to = params.pretty ? `./${params.pretty}/tableau/${item.linkId}` : `./tableau/${item.linkId}`;
            return (
              <ul key={item.integrationLinkId} className="row" role="row">
                <li className="cell name-holder" role="cell">
                  <NavLink to={item.linkId === "" ? "/error/404" : to} className="report__name">
                    {item.name}
                  </NavLink>
                </li>
                <li className="cell">{item.description}</li>
              </ul>
            );
          })
        )}
      </div>
    </div>
  );
};

const TableauRepositoryGrid: React.FC<ViewPropsBase> = ({ isFetching, data, params }) => {
  const style = { "--grid-meta-height": "auto", "--grid-meta-min-height": "50px" } as React.CSSProperties;
  if (isFetching) {
    return <TableauRepositoryGridEmpty style={style} length={data.length} />;
  }
  return (
    <div className="grid__container grid__container--hoverable tableau__grid__container" style={style}>
      {data.map((item) => {
        const to = params.pretty ? `./${params.pretty}/tableau/${item.linkId}` : `./tableau/${item.linkId}`;
        const thumbnail = false;
        const hasThumbnail = !!thumbnail;
        const description = item.description || "";
        const hasPossibilityToExpand = description.length >= 55;
        return (
          <div className="grid__item" key={item.integrationLinkId}>
            <NavLink to={item.linkId === "" ? "/error/404" : to} className="grid__content">
              <span
                className="grid__thumbnail"
                data-thumbnail={hasThumbnail}
                style={{
                  backgroundImage: hasThumbnail ? `url(${thumbnail})` : undefined,
                }}
              />
              <span className="grid__meta">
                <span className="h3">{item.name}</span>
                {description && (
                  <span className="relative block">
                    <span className="p grid__description" data-possible-expansion={hasPossibilityToExpand}>
                      {description}
                    </span>
                    {hasPossibilityToExpand && (
                      <span className="p grid__description__full" aria-hidden={true}>
                        <span>{description}</span>
                      </span>
                    )}
                  </span>
                )}
              </span>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};

const TableauRepositoryTable = withTranslation()(TableauRepositoryTableWoT);

const TableauRepositoryWoT: React.FC<TableauRepositoryProps> = ({ t, match: { params } }) => {
  const searchParams = useSearchParams();
  const { changePage, pageChanging } = useChangePage();
  const dispatch = useDispatch();
  const { viewTableauRepository } = useConfig();
  const page = parseInt(searchParams.page as string, 10) || 1;
  const query = searchParams.query;
  const id = parseInt(params.id, 10);
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchTableauList(id, "MENU", page, query, searchParams.parsedSort, changePage));
    }
    fetchInitial();
  }, [id, page, query, searchParams.parsedSort]);
  const {
    meta,
    contractName,
    data,
    isFetching: { GET_TABLEAU_INTEGRATION_LIST: isFetching },
  } = useSelector(
    (state: ApplicationState) =>
      state.integrationsReducer.TABLEAU.MENU[id] || state.integrationsReducer.TABLEAU.MENU[-1]
  );
  return (
    <main>
      <Meta
        isFetching={isFetching && !contractName}
        title={contractName}
        breadcrumbs={t("tableau:breadcrumbs", { returnObjects: true, defaultValue: [] })}
      />
      <header className="content__header">
        <div>
          <SearchInput query={query} autoComplete="tableau-repository-search" isFetching={isFetching} />
        </div>
        <ViewSwitcher name="viewTableauRepository" />
      </header>
      <div id="tableau-repository">
        <header className="data-toolbar mb12">
          <div>
            <ClearFilters filters={["sort", "query"]} />
            <SortDropdown
              searchParams={searchParams}
              list={[
                { value: "Date Added", sortName: "createdAt", sortType: "date" },
                { value: "Name", sortName: "name" },
                // { value: "ID", sortName: "id", sortType: "number" },
              ]}
            />
          </div>
        </header>
        {!isFetching && data.length === 0 ? (
          <InlineError
            style={{ height: "30vh", width: "100%" }}
            className="middle w100"
            title={t("tableau:errorNoItems")}
            description={t("tableau:errorNoItemsDescription")}
            icon="grid_off"
          />
        ) : (
          <>
            {viewTableauRepository === "table" ? (
              <TableauRepositoryTable data={data} isFetching={isFetching} params={params} searchParams={searchParams} />
            ) : (
              <TableauRepositoryGrid data={data} isFetching={isFetching} params={params} />
            )}
            <Pagination
              label={t("tableau:mainPaginationLabel")}
              settings={meta}
              onChangePage={changePage}
              onChangingPage={pageChanging}
            />
          </>
        )}
      </div>
    </main>
  );
};

export const TableauRepository = withTranslation()(TableauRepositoryWoT);
