import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOutLazy } from "../../store/actions/auth";
import { Dropdown, Icon } from "@tscore/react-components";
import { LanguageSwitcherItem } from "./language-switcher-list";
import history from "../../history";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import { BrowserRouter as Router, NavLink } from "react-router-dom";

// ? TYPES:

import { ApplicationState } from "../../store/reducers";

// interface HeaderProps {}

const Header: React.FC<{}> = () => {
  const credentials = useSelector((state: ApplicationState) => state.credentialsReducer.app_user_info);
  const moduleUrlPrefix = useSelector((state: ApplicationState) => state.menuReducer.meta.moduleUrlPrefix);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const PROFILE_LIST: any[] = [
    {
      icon: "settings",
      label: t("header:Edit Profile"),
      url: moduleUrlPrefix + "my-account/edit-profile",
    },
    {
      icon: "lock_open",
      label: t("header:Change Password"),
      url: moduleUrlPrefix + "my-account/change-password",
    },
    {
      label: "spacer1",
      spacer: true,
    },
    {
      icon: "notifications_none",
      label: t("header:Notification Settings"),
      url: moduleUrlPrefix + "my-account/notification-settings",
    },
    // {
    //   icon: "notifications_none",
    //   label: t("header:NHS Region Alerts"),
    //   url: moduleUrlPrefix + "my-account/nhsr-alerts",
    // },
    {
      icon: "",
      label: "CHANGE_LANGUAGE",
      url: "",
    },
    {
      label: "spacer2",
      spacer: true,
    },
    // {
    //   icon: "settings_backup_restore",
    //   label: "Change theme",
    //   // url: "#!theme-switch",
    //   onItemClick: this.switchTheme.bind(this),
    // },
    // {
    //   label: "spacer2",
    //   spacer: true,
    // },
    {
      icon: "exit_to_app",
      label: t("header:Logout"),
      url: "#!logout",
      onItemClick: (_e: any) => {
        dispatch(logOutLazy());
        history.push("/login?action=logout");
      },
    },
  ];
  return (
    <div id="header-holder">
      <div id="header-viewport-container">
        <ul className="head">
          {/* <li className="notification-tag">
            <a href="#!">
              <Icon>notifications</Icon>
              <span className="tag rounded bg-red">23</span>
            </a>
          </li> */}
          <Dropdown
            tag="li"
            position="right"
            className="user-profile"
            trigger={
              <>
                <span className="user-info">
                  <div>{credentials.fullname}</div>
                  <div>{credentials.username}</div>
                </span>
                <span>
                  <div data-ui-email={credentials.username} className="user-emblem big">
                    {credentials.initials}
                  </div>
                </span>
                <span className="arrow_down">
                  <Icon>arrow_drop_down</Icon>
                </span>
              </>
            }>
            {PROFILE_LIST.map((item) => {
              if (item.label === "CHANGE_LANGUAGE") {
                return <LanguageSwitcherItem key={item.label} />;
              }
              return (
                <Dropdown.Item
                  label={item.spacer ? "" : item.label}
                  key={item.label}
                  icon={item.icon}
                  anchorComponent={
                    item.url && !item.url.startsWith("#!")
                      ? (props: any) => <NavLink to={item.url} {...props} />
                      : undefined
                  }
                  href={item.url}
                  className={item.spacer ? "spacer" : ""}
                  onItemClick={item.onItemClick}
                />
              );
            })}
          </Dropdown>
        </ul>
      </div>
    </div>
  );
};

export default Header;
