import { BRAG_PRESSURE_LEVEL_MAPPING, BRAG_COLOURS } from "../../../components/map/consts";
// ? TYPES:
import { PressureLevel } from "../../../types/google-map";

export const generateMarkersWithColours = (markers: { pressureLevel: PressureLevel; [key: string]: any }[]) => {
  return markers.map((m) => {
    const brag = BRAG_PRESSURE_LEVEL_MAPPING[m.pressureLevel] || "white";
    const colours: { colour: string; textColour: string } = BRAG_COLOURS[brag];
    return {
      properties: {
        ccg19nm: undefined,
      },
      ...m,
      colorCodeBg: colours.colour,
      colorCodeFg: colours.textColour,
    };
  });
};
