import PersonalisedIndicatorsService from "./personalised-indicators.service";

// ? TYPES:
import { ApiAction, ResponseOf } from "../../types/api";
import { ApplicationState } from "../../../store/reducers";
import {
  PersonalisedIndicatorsState,
  UpdatedIndicator,
  UpdatedIndicatorGroup,
} from "../../types/personalised-indicators-state";
import { TSelectedIndicators } from "../../../pages/home/modal-indicator-setup";

const getPersonalisedIndicators = (
  data: ResponseOf<typeof PersonalisedIndicatorsService.getPersonalisedIndicators>
) => ({
  type: "GET_PERSONALISED_INDICATORS",
  data,
});

const getProviders = (data: { results: PersonalisedIndicatorsState["providers"] }) => ({
  type: "GET_PROVIDERS",
  data,
});

// const getCCGs = (data: { results: PersonalisedIndicatorsState["ccgs"] }) => ({
//   type: "GET_CCGS",
//   data,
// });

// const getAgency = (ccgId: number, data: { results: PersonalisedIndicatorsState["agencies"][number] }) => ({
//   type: "GET_AGENCY",
//   ccgId,
//   data,
// });

const getIndicatorsList = (
  organisationId: number,
  data: { results: PersonalisedIndicatorsState["indicators"][number] },
  initialIndicators?: TSelectedIndicators
) => ({
  type: "GET_INDICATORS_LIST",
  organisationId,
  data,
  initialIndicators,
});

const addNewIndicatorGroup = (organisationId: number, data: UpdatedIndicatorGroup) => ({
  type: "ADD_NEW_INDICATOR_GROUP",
  // ccgId,
  organisationId,
  data,
});

const toggleIndicatorAlerts = (organisationId: number, data: UpdatedIndicatorGroup) => ({
  type: "TOGGLE_INDICATOR_ALERTS",
  // ccgId,
  organisationId,
  data,
});

const deleteIndicatorsFromGroup = (organisationId: number, data: UpdatedIndicatorGroup) => ({
  type: "DELETE_INDICATORS_FROM_GROUP",
  // ccgId,
  organisationId,
  data,
});

export const fetchPersonalisedIndicators = (page = 1, limit = 9999, disablePagination = true): ApiAction => {
  return PersonalisedIndicatorsService.getPersonalisedIndicators(
    { page, limit, disablePagination },
    {
      label: "GET_PERSONALISED_INDICATORS",
      onSuccess: getPersonalisedIndicators,
    }
  );
};

const fetchProviders = (): ApiAction => {
  return PersonalisedIndicatorsService.getProviders({}, { label: "GET_PROVIDERS", onSuccess: getProviders });
};

export const fetchProvidersIfNeeded = () => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const state = getState().personalisedIndicatorsReducer;
    const { didInvalidate, providers } = state;
    if (didInvalidate["GET_PROVIDERS"] || providers.length === 0) {
      dispatch(fetchProviders());
    }
  };
};

// const fetchCCGs = (): ApiAction => {
//   return PersonalisedIndicatorsService.getCCGs({}, { label: "GET_CCGS", onSuccess: getCCGs });
// };

// export const fetchCCGsIfNeeded = () => {
//   return (dispatch: any, getState: () => ApplicationState) => {
//     const state = getState().personalisedIndicatorsReducer;
//     const { didInvalidate, ccgs } = state;
//     if (didInvalidate["GET_CCGS"] || ccgs.length === 0) {
//       dispatch(fetchCCGs());
//     }
//   };
// };

// export const fetchAgency = (ccgId: number) => {
//   return PersonalisedIndicatorsService.getAgenciesByCCGs(
//     { ccgIdList: [ccgId] },
//     { label: "GET_AGENCY", onSuccess: (response: any) => getAgency(ccgId, response) }
//   );
// };

// fetch agency if needed?

export const fetchIndicators = (organisationId: number, initialIndicators?: TSelectedIndicators) => {
  return PersonalisedIndicatorsService.getIndicatorsByAgencies(
    { organisationIdList: [organisationId] },
    {
      label: "GET_INDICATORS_LIST",
      onSuccess: (response: any) => getIndicatorsList(organisationId, response, initialIndicators),
    }
  );
};

// fetch indicators if needed?

// const fetchUpdateIndicatorGroupGeneral = (
//   // ccgId: number,
//   organisationId: number,
//   indicatorList: UpdatedIndicator[],
//   label: string,
//   onSuccess: Function
// ) => {
//   return PersonalisedIndicatorsService.updateIndicatorGroup(
//     {
//       // ccgId,
//       organisationId,
//       indicatorList,
//     },
//     {
//       label,
//       onSuccess: (response: UpdatedIndicatorGroup) => onSuccess(organisationId, response),
//       other: { indicatorList },
//     }
//   );
// };

const fetchUpdateIndicatorGroupGeneral = (
  // ccgId: number,
  organisationId: number,
  indicatorList: UpdatedIndicator[],
  label: string,
  onSuccess: Function
) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const previousIndicatorGroups = getState().personalisedIndicatorsReducer.indicatorGroups;
    const previousFound = previousIndicatorGroups.find((ig) => ig.organisationId === organisationId) || {
      indicatorList: [],
    };
    const allPreviousValuesForSuccessToast = previousFound.indicatorList;
    dispatch(
      PersonalisedIndicatorsService.updateIndicatorGroup(
        {
          // ccgId,
          organisationId,
          indicatorList,
        },
        {
          label,
          onSuccess: (response: UpdatedIndicatorGroup) => onSuccess(organisationId, response),
          other: { indicatorList, allPreviousValues: allPreviousValuesForSuccessToast },
        }
      )
    );
  };
};

const fetchAddNewIndicatorGroupCallback = (organisationId: number, data: UpdatedIndicatorGroup) => {
  return (dispatch: any, _getState: () => ApplicationState) => {
    dispatch(addNewIndicatorGroup(organisationId, data));
    dispatch(fetchPersonalisedIndicators());
  };
};

export const fetchAddNewIndicatorGroupWithRefresh = (
  // ccgId: number,
  organisationId: number,
  indicatorList: UpdatedIndicator[]
) => {
  return fetchUpdateIndicatorGroupGeneral(
    // ccgId,
    organisationId,
    indicatorList,
    "ADD_NEW_INDICATOR_GROUP",
    fetchAddNewIndicatorGroupCallback
  );
};

export const fetchToggleIndicatorAlerts = (
  // ccgId: number,
  organisationId: number,
  indicatorList: UpdatedIndicator[]
) => {
  return fetchUpdateIndicatorGroupGeneral(
    // ccgId,
    organisationId,
    indicatorList,
    "TOGGLE_INDICATOR_ALERTS",
    toggleIndicatorAlerts
  );
};

export const fetchDeleteIndicatorsFromGroup = (
  // ccgId: number,
  organisationId: number,
  indicatorList: UpdatedIndicator[]
) => {
  return fetchUpdateIndicatorGroupGeneral(
    // ccgId,
    organisationId,
    indicatorList,
    "DELETE_INDICATORS_FROM_GROUP",
    deleteIndicatorsFromGroup
  );
};
