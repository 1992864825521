import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input } from "@tscore/react-components";
import { fetchRequestMoreInfoWinterReport } from "../../store/actions/reports";
import { withTranslation } from "react-i18next";
// ? TYPES:
import { TFunction } from "i18next";
// import { ApplicationState } from "../../store/reducers";

const ModalAdditionalQuestionsWoT: React.FC<{ id: number | "all"; reportId: number; t: TFunction }> = ({
  id,
  reportId,
  t,
}) => {
  const [questions, setQuestions] = useState<{ question: string }[]>([{ question: "" }]);
  const dispatch = useDispatch();
  const onRequest = () => {
    const filteredQuestions = questions.filter((value) => !!value.question && value.question !== "");
    dispatch(fetchRequestMoreInfoWinterReport(id, reportId, filteredQuestions));
  };
  const onQuestionChange = (e: any, index: number) => {
    const { value } = e.target;
    setQuestions((prevState) => {
      // const additionalQuestion = { [index + 1]: { question: "" } };
      return Object.assign([], prevState, { [index]: { question: value } });
    });
  };
  const onQuestionDelete = (index: number) => {
    setQuestions((prevState) => prevState.filter((_item, i) => i !== index));
  };
  return (
    <div>
      <header>
        <h4>{t("Request More Info")}</h4>
        {/* <h6>{name}</h6> */}
      </header>
      <article>
        <div className="form">
          {questions.map((value, i) => (
            <section className="form-row auto-width" key={i}>
              <div className="form-field">
                <label>{t("questionCount", { count: i + 1 })}</label>
                <Input value={value.question} onChange={(e: any) => onQuestionChange(e, i)} />
              </div>
              {i !== 0 && (
                <div className="form-field w1">
                  <Button colour="red-outline" icon="delete" onClick={() => onQuestionDelete(i)}></Button>
                </div>
              )}
            </section>
          ))}
          <section className="form-row ta-right" style={{ marginTop: "1.2rem" }}>
            <div className="form-field">
              <Button
                disabled={questions[questions.length - 1].question === ""}
                onClick={() => onQuestionChange({ target: { value: "" } }, questions.length)}
                icon="add"
                colour="green-outline"
                scale="small">
                {t("Add question")}
              </Button>
            </div>
          </section>
        </div>
      </article>
      <footer className="ta-right">
        <Button disabled={questions.length === 0 || questions[0].question === ""} onClick={onRequest} data-close-modal>
          {t("Request")}
        </Button>
      </footer>
    </div>
  );
};

export const ModalAdditionalQuestions = withTranslation("regionReports")(ModalAdditionalQuestionsWoT);
