import React, { useState, useEffect } from "react";

import { Icon, Button, Tippy } from "@tscore/react-components";
import { SCALE_LEVEL } from "./consts";
import { withTranslation } from "react-i18next";
import { ReactComponent as SVGApple } from "../../../sass/assets/other/apple-icon.svg";
// ? TYPES:
import { TFunction } from "i18next";
import { TMyApp } from "./app-list";

interface ModalDisabledProps {
  item: TMyApp | Required<TMyApp["children"]>[number];
  t: TFunction;
}

const getTransformValues = (event: any): { transformX: number; transformY: number } => {
  // event.persist();
  const {
    clientX,
    clientY,
    target: { width, height },
  } = event;
  const { left, top } = event.target.getBoundingClientRect();
  const transformX: number = ((clientX - left) / (width * SCALE_LEVEL)) * 100;
  const transformY: number = ((clientY - top) / (height * SCALE_LEVEL)) * 100;
  return {
    transformX,
    transformY,
  };
};

const ModalDisabledWoT: React.FC<ModalDisabledProps> = ({ item, t }) => {
  const [picTransformOrigin, setPicTransformOrigin] = useState<string | undefined>(undefined);
  const [picTransformScale, setPicTransformScale] = useState<number>(1);
  const [isHovered, setIsHovered] = useState(false);
  // const hoverTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    const scale = isHovered ? SCALE_LEVEL : 1;
    const delay = isHovered ? 200 : 0;
    const hoverTimer = setTimeout(() => setPicTransformScale(scale), delay);
    return () => {
      hoverTimer && clearTimeout(hoverTimer);
    };
  }, [picTransformOrigin, isHovered]);
  const onPicHover = (event: React.MouseEvent<HTMLImageElement, MouseEvent>): void => {
    event.persist();
    const valuesNumber: any = getTransformValues(event);
    const values: any = {
      transformX: valuesNumber.transformX >= 50 ? "right" : "left",
      transformY: valuesNumber.transformY >= 50 ? "bottom" : "top",
    };
    setPicTransformOrigin(`${values.transformX} ${values.transformY}`);
    setIsHovered(true);
  };
  const onPicHoverOut = (_event: React.MouseEvent<HTMLImageElement, MouseEvent>): void => {
    setIsHovered(false);
  };
  return (
    <div>
      <header className="ta-center">
        <h4>{t(`g:${item.id}`)}</h4>
      </header>
      <article>
        <div className="pic">
          {/* <div className="photo" style={{ transformOrigin: transformOrigin, backgroundImage: `url(${item.disabledMeta.img})` }} /> */}
          <img
            src={item.disabledMeta.img}
            alt="Preview"
            style={{ transformOrigin: picTransformOrigin, transform: `scale(${picTransformScale})` }}
            onMouseMove={onPicHover}
            onMouseEnter={onPicHover}
            onMouseOut={onPicHoverOut}
          />
        </div>
        <p>{t(`productMeta.${item.id}.longDescription`, { defaultValue: "" })}</p>
        <div className="platforms">
          {item.disabledMeta.platforms.map((platform) => (
            <div className="platform" key={platform.icon}>
              <Tippy
                content={t("tooltipAvailableOn", { platform: t("platforms." + platform.name) })}
                placement="bottom">
                <span>{platform.name !== "iOS" ? <Icon>{platform.icon}</Icon> : <SVGApple />}</span>
              </Tippy>
            </div>
          ))}
        </div>
      </article>
      <footer>
        {/* <button className="btn link" data-close-modal>
        Cancel
      </button> */}
        <Button
          onClick={() => window.open(t("meta:CONTACT_LINK"), "_blank")}
          colour="green"
          scale="xl"
          length="fullwidth"
          data-close-modal>
          {t("Contact Sales")}
        </Button>
      </footer>
    </div>
  );
};

export const ModalDisabled = withTranslation(["myApps", "meta", "g"])(ModalDisabledWoT);
