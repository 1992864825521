import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultApiLoading } from "../default-api-loading";
import { generateParams } from "../../../helpers/params";
// import { defaultApiSuccess } from "../default-api-success";
import { DEFAULT_REPORT_CONFIG, GENERATE_LOCALE_SETTINGS, simpleParamParser } from "./consts";
import {
  POWERBI_SETTINGS,
  POWERBI_FILTERS,
  GET_EMBED_TYPE,
  decodePowerBIParams,
  POWERBI_REPORT_EMBED_PARAMS,
} from "../../../globals/powerbi-settings";
// ? TYPES:
import { PowerBIState, PowerBIEmbedTokenResponse } from "../../types/powerbi-state";

const defaultState: PowerBIState = {
  settings: {},
  // searchParams: {},
  config: DEFAULT_REPORT_CONFIG(),
  isFetching: {},
  errors: {},
  didInvalidate: {}, // not used
};

const report = (state: PowerBIState = defaultState, action: any): PowerBIState => {
  switch (action.type) {
    case API_START: {
      return defaultApiLoading(state, action, true, ["GET_POWERBI_REPORT_EMBED_DATA"]);
    }
    case API_ERROR: {
      return defaultApiError(state, action);
    }
    case API_END: {
      return defaultApiLoading(state, action, false, ["GET_POWERBI_REPORT_EMBED_DATA"]);
    }
    case "GET_POWERBI_REPORT_EMBED_DATA": {
      const data = action.data as PowerBIEmbedTokenResponse;
      const settingsWithoutLocale = POWERBI_SETTINGS[action.id] || {};
      const settings: PowerBIState["settings"] = {
        ...settingsWithoutLocale,
        ...GENERATE_LOCALE_SETTINGS(),
      };
      const lng = settings.localeSettings!.formatLocale as "en-GB";
      const { source: ignore1, eup, ...parsedSearchParams } = simpleParamParser(action.searchParams);
      const embedUrlFiltersDecoded = eup ? decodePowerBIParams(eup as string) : {};
      const embedUrlFilters = Object.entries(parsedSearchParams)
        .filter(([key]) => key.includes(":"))
        .reduce(
          (final, [key, value]) => ({
            ...final,
            [key]: value,
          }),
          { ...embedUrlFiltersDecoded }
        );
      const embedUrlFiltersWithFakeRpLanguageParams = {
        "rp:lang": settings.localeSettings!.language,
        "rp:fmtLocale": lng,
      };
      const { language: languageParams, ...extraParams } = POWERBI_REPORT_EMBED_PARAMS[action.id] || { language: {} };
      const extraParamsLanguageBased = languageParams ? languageParams[lng] || {} : {};
      const params = {
        ...embedUrlFiltersWithFakeRpLanguageParams,
        ...extraParams,
        ...extraParamsLanguageBased,
        ...embedUrlFilters,
      };
      const filter =
        typeof POWERBI_FILTERS[action.id] === "function" ? POWERBI_FILTERS[action.id](parsedSearchParams) : null;
      const filters = filter ? [filter] : undefined;
      const embedType = GET_EMBED_TYPE(action.id);
      return {
        ...state,
        // searchParams: parsedSearchParams,
        settings: settings,
        config: {
          ...state.config,
          ...(embedType === "tile" && { dashboardId: data.embedItem[0].id }),
          type: embedType,
          settings: settings,
          filters,
          embedUrl: data.embedItem[0].embedUrl + generateParams(params, "&"),
          accessToken: data.embedToken.token,
        },
        errors: {},
      };
    }
    case "RESET_POWERBI_REPORT_EMBED_DATA": {
      return {
        ...state,
        // searchParams: {},
        settings: {},
        config: DEFAULT_REPORT_CONFIG(),
        errors: {},
      };
    }
    default:
      return state;
  }
};

const reportsById = (state: { [wardId: string]: PowerBIState } = { "-1": defaultState }, action: any): any => {
  switch (action.type) {
    case API_START:
    case API_ERROR:
    case API_END:
    case "GET_POWERBI_REPORT_EMBED_DATA":
    case "RESET_POWERBI_REPORT_EMBED_DATA":
      if (!action.id) {
        return state;
      }
      return {
        ...state,
        [action.id]: report(state[action.id], action),
      };
    default:
      return state;
  }
};

export default reportsById;
