import i18n from "i18next";
import {
  LANGUAGE_SWITCHER,
  SUPPORTED_LANGUAGES,
  FALLBACK_LANGUAGE,
  PREFER_24_HOUR_FORMAT_LIST,
} from "./globals/settings";
// import { initReactI18next } from "react-i18next";
import LngDetector from "i18next-browser-languagedetector";
import { buildYupLocale } from "./locales/build-yup-locale";
import { locale } from "moment";
import { setDefaultLocale } from "@tscore/react-components";
import translations, { dateFnsLocale } from "./locales";

// ? TYPES:
import { ThirdPartyModule, LanguageDetectorModule, TFunction } from "i18next";

export function onChangeLocale(error: unknown, originalT: TFunction): void {
  // runs on init as well
  buildYupLocale(error, originalT);
  const newLanguage = i18n.language;
  locale(newLanguage.toLowerCase());
  setDefaultLocale(newLanguage);
}

export function is24Hour(currentLanguage: string): boolean {
  if (PREFER_24_HOUR_FORMAT_LIST.includes(currentLanguage)) {
    return true;
  }
  return false;
}

const undefinedModule: ThirdPartyModule = {
  type: "3rdParty", // "languageDetector", //
  init: () => undefined,
};

const LanguageDetector = LANGUAGE_SWITCHER ? (LngDetector as unknown as LanguageDetectorModule) : undefinedModule;

i18n
  .use(LanguageDetector)
  // .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      // detection: detectionOptions, // https://github.com/i18next/i18next-browser-languageDetector
      resources: translations,
      // lng: "en",
      fallbackLng: FALLBACK_LANGUAGE,
      supportedLngs: SUPPORTED_LANGUAGES, //Object.keys(translations),
      nonExplicitSupportedLngs: false, // false default
      // keySeparator: false,
      interpolation: {
        escapeValue: false, // react already safes from xss
        format: function (value, format, _lng) {
          if (format === "lowercase") {
            return value.toLowerCase();
          }
          if (format === "uppercase") {
            return value.toUpperCase();
          }
          return value;
        },
      },
      react: {
        // wait: true,
        transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i", "p"],
      },
    },
    onChangeLocale
  );

export const getCurrentLang = () => i18n.language;

export const getDateFnsLocaleOptions = () => {
  return { locale: dateFnsLocale[getCurrentLang() as "en-GB"] };
};

export default i18n;
