import moment from "moment";
// import Moment from "react-moment";
// ? TYPES:
import {
  RawGraphConfig,
  GraphConfig,
  GraphFrequencyValue,
  ISHAggregation,
  StartEndValue,
  GraphTypeValue,
} from "../../types/indicator-history-state";

const now = () => moment(); //.utcOffset(0);

const nowDate = () => now().startOf("day"); //.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

// export const MOMENT_ISH_API_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

// export const MOMENT_ISH_API_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ:Z";

export const CUSTOM_LABEL = "Custom" as const;

// const f = (d: moment.Moment) => d.format(MOMENT_ISH_API_DATE_TIME_FORMAT);
const g = ([start, end]: [moment.Moment, moment.Moment]): StartEndValue => ({
  start: start.format(),
  end: end.format(),
});

const GET_PERIOD_MAP = () => ({
  "Last 30 days": g([nowDate().subtract(30, "d"), nowDate().endOf("day")]),
  Today: g([nowDate(), nowDate().endOf("day")]), // midnight - midnight
  Yesterday: g([nowDate().subtract(1, "d"), nowDate().subtract(1, "d").endOf("day")]),
  "Since Yesterday": g([nowDate().subtract(1, "d"), nowDate().endOf("day")]), // since yesterday midnight
  "This Week": g([nowDate().startOf("isoWeek"), nowDate().endOf("isoWeek")]),
  "Last Week": g([nowDate().startOf("isoWeek").subtract(1, "w"), nowDate().endOf("isoWeek").subtract(1, "w")]),
  "This Month": g([nowDate().startOf("month"), nowDate().endOf("month")]),
  "Last Month": g([nowDate().subtract(1, "month").startOf("month"), nowDate().subtract(1, "month").endOf("month")]),
  "Last 90 days": g([nowDate().subtract(90, "d"), nowDate().endOf("day")]),
  [CUSTOM_LABEL]: {
    start: null,
    end: null,
  },
});

const DEFAULT_LENGTH_ZERO_AGGREGATION = { id: 1, label: "Maximum", value: "max", defaultSelected: true };

const automaticValueMapper = (
  name: string,
  setValues: { [name: string]: string | StartEndValue } = {}
): string | StartEndValue => {
  if (setValues[name]) {
    return setValues[name];
  }
  return name.toLowerCase();
};

const generateFrequency = (item: RawGraphConfig["defaultsPerFrequencyDtos"][number]) => ({
  id: item.idFrequency,
  label: item.frequency,
  value: automaticValueMapper(item.frequency, {}) as GraphFrequencyValue,
  defaultSelected: item.defaultSelected,
});

const generateAggregation = (item: RawGraphConfig["defaultsPerFrequencyDtos"][number]["aggregation"][number]) => ({
  id: item.id,
  label: item.name,
  value: automaticValueMapper(item.name, {
    Maximum: "max",
    Latest: "last",
    Average: "avg",
    // "Median": "median",
    // "Sum": "sum"
  }) as ISHAggregation,
  defaultSelected: item.defaultSelected, // ?idk
});

const generatePeriod = (
  item: RawGraphConfig["defaultsPerFrequencyDtos"][number]["period"][number],
  periodMap: ReturnType<typeof GET_PERIOD_MAP>
) => {
  const value = periodMap[item.name] || { start: null, end: null };
  return {
    id: item.id,
    label: item.name,
    value: value,
    defaultSelected: item.defaultSelected, // ?idk
  };
};

const generateGraphType = (item: RawGraphConfig["defaultsPerFrequencyDtos"][number]["graphTypeList"][number]) => ({
  id: item.id,
  label: item.name,
  value: automaticValueMapper(item.name, {}) as GraphTypeValue,
  defaultSelected: item.defaultSelected, // ?idk
});

export const generateConfig = (rawSettings: RawGraphConfig): GraphConfig => {
  const list = rawSettings.defaultsPerFrequencyDtos;
  const selectedFrequency = list.find((item) => item.defaultSelected === true) || list[0];
  const periodMap = GET_PERIOD_MAP();
  return {
    freqOptions: list.map(generateFrequency),
    aggregation: list.reduce((final, current) => {
      return {
        ...final,
        [current.frequency]:
          current.aggregation.length === 0
            ? [DEFAULT_LENGTH_ZERO_AGGREGATION]
            : current.aggregation.map(generateAggregation),
      };
    }, {}),
    period: list.reduce((final, current) => {
      return {
        ...final,
        [current.frequency]: current.period.map((p) => generatePeriod(p, periodMap)),
      };
    }, {}),
    graphType: list.reduce((final, current) => {
      return {
        ...final,
        [current.frequency]: current.graphTypeList.map(generateGraphType),
      };
    }, {}),
    defaults: {
      freqOptions: generateFrequency(selectedFrequency),
      aggregation: list.reduce((final, current) => {
        return {
          ...final,
          [current.frequency]:
            current.aggregation.length === 0
              ? DEFAULT_LENGTH_ZERO_AGGREGATION
              : generateAggregation(
                  current.aggregation.find((item) => item.defaultSelected === true) || current.aggregation[0]
                ),
        };
      }, {}),
      period: list.reduce((final, current) => {
        return {
          ...final,
          [current.frequency]: generatePeriod(
            current.period.find((item) => item.defaultSelected === true) || current.period[0],
            periodMap
          ),
        };
      }, {}),
      graphType: list.reduce((final, current) => {
        return {
          ...final,
          [current.frequency]: generateGraphType(
            current.graphTypeList.find((item) => item.defaultSelected === true) || current.graphTypeList[0]
          ),
        };
      }, {}),
      // aggregation: generateAggregation(
      //   selectedFrequency.aggregation.find((item) => item.defaultSelected === true) || selectedFrequency.aggregation[0]
      // ),
    },
  };
};

export type TFrequencyItem = ReturnType<typeof generateFrequency>;
export type TAggregationItem = ReturnType<typeof generateAggregation>;
export type TPeriodItem = ReturnType<typeof generatePeriod>;
export type TBarItem = ReturnType<typeof generateGraphType>;
