import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchForceWholeSystemDashboardRefresh } from "../../store/actions/system-dashboard";
import { useActiveTabTimerKey } from "../../hooks/use-active-tab-timer-key";

interface Props {
  id: number;
  siteId: number;
}

export const IntervalRefreshDials: React.FC<Props> = ({ id, siteId }) => {
  const dispatch = useDispatch();
  const [refreshKey] = useActiveTabTimerKey({ interval: 10 * 60000 });
  const siteIdRef = useRef<number>(-1);
  useEffect(() => {
    siteIdRef.current = siteId;
  }, [siteId]);
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchForceWholeSystemDashboardRefresh(id, siteIdRef.current));
    }
    if (refreshKey > 0) {
      fetchInitial();
      // console.log("do force refresh of stuff", refreshKey, id, siteIdRef.current);
    }
  }, [dispatch, id, refreshKey]);

  return null;
};
