import React from "react";
import { Route } from "react-router-dom";
import Switch from "./Switch";

// ? TYPES:
// import { RouteProps } from "react-router";
import { SpecialRouteProps } from "../types/special-route-props";

interface RoutesListProps {
  routes: SpecialRouteProps[];
  extraProps?: any;
}

export const RoutesList = ({ routes, extraProps = {} }: RoutesListProps): JSX.Element => (
  <Switch>
    {routes.map(({ ComponentWithExtraProps, component, ...routeProps }, i: number) => {
      if (ComponentWithExtraProps) {
        return (
          <Route key={i} render={(props) => <ComponentWithExtraProps {...props} {...extraProps} />} {...routeProps} />
        );
      }
      return <Route key={i} component={component} {...routeProps} />;
    })}
  </Switch>
);
