import React from "react";
import { getRandomArbitrary } from "../../helpers/random-generators";
// import { DEFAULT_PAGE_LIMIT } from "../../globals/settings";

export const ManualUpdatesEmptyRow: React.FC = () => (
  <div className="row__holder isFetching">
    <ul className="row">
      <li className="cell" data-id="drag"></li>
      <li className="cell" data-id="favourite">
        <div className="loading-element" style={{ minWidth: "16px" }}>
          x
        </div>
      </li>
      <li className="cell" data-id="id">
        <div className="loading-element" style={{ width: "100%", minWidth: "35px" }}>
          9999
        </div>
      </li>
      <li className="cell" data-id="name">
        <div className="loading-element" style={{ width: "60%" }}>
          x
        </div>
        <div className="meta loading-element" style={{ width: "50%" }}>
          x
        </div>
      </li>
      <li className="cell" data-id="brag">
        <div className="brag-tag__holder">
          <span className="brag-white brag longer loading-element">XX</span>
        </div>
        <span
          className="loading-element"
          style={{
            width: "84px",
            display: "inline-block",
            height: "32px",
            border: "1px solid #d4ddf0",
            verticalAlign: "top",
          }}>
          x
        </span>
        {/* <input type="text" className="input input-brag loading-element" autoComplete="off" value="" disabled /> */}
        {/* <button
    type="button"
    className="btn lightgray-outline shorter loading-element"
    disabled
    style={{ width: "40px" }}>
    <i className="material-icons">visibility_off</i>
  </button> */}
      </li>
    </ul>
  </div>
);

export const ManualUpdatesEmpty: React.FC<{ length: number }> = ({ length }) => {
  // const list = Array.apply(null, { length: 10 });
  // const list = Array.from({ length: DEFAULT_PAGE_LIMIT });
  const list = Array.from({ length: length > 0 ? length : getRandomArbitrary(3, 7) });
  return (
    <>
      {list.map((_e: any, i: number) => (
        <ManualUpdatesEmptyRow key={i} />
      ))}
    </>
  );
};
