import { Switch } from "react-router-dom";
import React, { Fragment } from "react";

// ? https://github.com/ReactTraining/react-router/issues/5785
// ? react-router doesn't have support for React fragments in <Switch />. This component
// ? wraps react-router's <Switch /> so that it gets fragment support.

const flatten = (target: React.ReactNode[], children: React.ReactNode): void => {
  React.Children.forEach(children, (child: React.ReactNode) => {
    if (React.isValidElement(child)) {
      if (child.type === Fragment) {
        flatten(target, child.props.children);
      } else {
        target.push(child);
      }
    }
  });
};

const FragmentSupportingSwitch = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const flattenedChildren: React.ReactNode[] = [];
  flatten(flattenedChildren, children);
  //eslint-disable-next-line
  return React.createElement.apply(React, ([Switch, null] as any).concat(flattenedChildren));
};

export default FragmentSupportingSwitch;
