import d3 from "../cherryPickD3";
// const d3 = Object.assign({}, d3Cherry, require("d3-selection-multi"));
import { InnerCircleData, D3DataWrapper } from "../types/index";




const wrap = (text: any): void => {
  text.each(function(d: D3DataWrapper<InnerCircleData>) {
    const text = d3.select(this);
    text.text(null); // empty original text
    const lines = d.data.multiTitle;
    const dy = d.data.multiTitleDy;
    const length = lines.length;
    for (let i = 0; i < length; i += 1) {
      const tspan = text
        // .attr("y", -updatedDy + "em")
        .append("tspan")
        .attrs({
          x: 0,
          y: 0,
          dy: dy[i] + "em",
          // "text-anchor": "middle",
        })
        .text(lines[i]);
    }
  });
};

export default wrap;
