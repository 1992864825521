import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { decodeParams } from "../helpers/params";
import { parseSort } from "../helpers/generate-params";
// ? TYPES:
import { SearchParams } from "../types/paginated-params";

export function useSearchParams() {
  const location = useLocation();
  const searchParams: SearchParams = useMemo(() => {
    const decoded = decodeParams(location.search);
    const parsedSort = parseSort(decoded.sort);
    return {
      ...decoded,
      parsedSort,
    };
  }, [location.search]);
  return searchParams;
}
