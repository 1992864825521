// ? TYPES:
import { ResourceLanguage } from "i18next";
// e.g. my-apps.json ==> myApps
function requireAll(requireContext: any): ResourceLanguage {
  function camelize(s: string) {
    return s.replace(/-./g, (x: string) => x[1].toUpperCase());
  }
  return requireContext.keys().reduce((acc: ResourceLanguage, key: string) => {
    const k = camelize(key.split("/").pop()!.replace(".json", ""));
    return { ...acc, [k]: requireContext(key) };
  }, {});
}

export function requireAllList(requireContext: any, extras: any[]): ResourceLanguage {
  return extras.reduce((acc, item) => {
    return {
      ...acc,
      ...item,
    };
  }, requireAll(requireContext));
}
