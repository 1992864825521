// ? TYPES:
import { TModuleUrlKey } from "../../../globals/internal-urls";

const NEW_TIMESTAMPS: Partial<Record<TModuleUrlKey, Record<string, number>>> = {
  Resilience: {
    // Benchmarking: new Date("2023-11-13T09:00:00Z").getTime(),
  },
  "NHS Region": {
    // Benchmarking: new Date("2023-11-13T09:00:00Z").getTime(),
  },
};

const unixNow = new Date().getTime();

export const isNewFeature = (module: TModuleUrlKey, name: string) => {
  const timestamps = NEW_TIMESTAMPS[module];
  if (!timestamps || !timestamps[name]) {
    return false;
  }
  return timestamps[name] > unixNow;
};
