import React from "react";

import { generateBragStyles } from "../../helpers/brag-helpers";
import { showThresholdValue } from "../../helpers/show-threshold-value";
import { useTranslation } from "react-i18next";
// ? TYPES:
import IndicatorThreshold from "../../types/indicator-threshold";

export const ModalTrigger: React.FC<{ threshold: IndicatorThreshold[]; name: string }> = ({ threshold, name }) => {
  const { t } = useTranslation();
  return (
    <div>
      <header>
        <h4>{t("manualUpdates:Current Trigger Details")}</h4>
        <h6>{name}</h6>
      </header>
      <article>
        <table className="table bordered no-vertical-lines main">
          <thead>
            <tr>
              <th>{t("manualUpdates:modalTriggerTable.0")}</th>
              <th>{t("manualUpdates:modalTriggerTable.1")}</th>
              <th>{t("manualUpdates:modalTriggerTable.2")}</th>
              <th>{t("manualUpdates:modalTriggerTable.3")}</th>
              <th>{t("manualUpdates:modalTriggerTable.4")}</th>
            </tr>
          </thead>
          <tbody>
            {threshold.map((row: IndicatorThreshold, rowindex: number) => (
              <tr key={rowindex}>
                <td className="w1 ta-center">{row.pressureLevel}</td>
                <td>{row.description !== "" ? row.description : "---"}</td>
                <td className="w1">{showThresholdValue(row.beginValue)}</td>
                <td className="w1">{showThresholdValue(row.endValue, true)}</td>
                <td className="w1 brag-holder">
                  <span className="brag longer" style={generateBragStyles(row.colorCodeFg, row.colorCodeBg)}>
                    {t("generic:colours." + row.colorName, { defaultValue: row.colorName })}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </article>
      <footer className="ta-right" style={{ fontSize: "1.2rem" }}>
        {/* (Monday - Hourly BRAGs) */}
      </footer>
    </div>
  );
};
