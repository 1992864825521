import { polygonsContainLatLng } from "../../../components/map/helpers/polygonContainsLatLng";
import merge from "lodash/merge";
// ? TYPES:
import { ChildFeature } from "../../../types/google-map";

export const addPropsToMarkersWithinBoundary = (
  markers: { longitude: string | number; latitude: string | number; [key: string]: any }[],
  boundary: ChildFeature<any, any>[],
  notFoundObject: { [key: string]: any } = {
    properties: {
      ccg19nm: undefined,
    },
  }
) => {
  return markers.map((h) => {
    // remove geometry and type
    const { geometry, type, ...addedHospitalProperties } = polygonsContainLatLng(
      boundary,
      [parseFloat(h.longitude as string), parseFloat(h.latitude as string)],
      notFoundObject
    );
    return merge(h, addedHospitalProperties);
  });
};
