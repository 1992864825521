import * as yup from "yup";
// ? TYPES:
import { TFunction } from "i18next";
// import { LOGIN_ERRORS } from "../lang/error";

export const loginSchema = (t: TFunction) =>
  yup.object().shape({
    username: yup.string().required(t("yupErrors:custom.noUsername")),
    password: yup.string().required(t("yupErrors:custom.noPassword")),
  });

export const forgotSchema = (t: TFunction) =>
  yup.object().shape({
    username: yup.string().required(t("yupErrors:custom.noUsername")),
  });

export const resetSchema = (t: TFunction) =>
  yup.object().shape({
    username: yup.string().required(t("yupErrors:custom.noUsername")),
    password: yup.string().required().label(t("login:password")),
    confirmPassword: yup.mixed().test("match", t("yupErrors:custom.Passwords do not match"), function (c) {
      // console.log(this, c);
      return c === (this as any).parent.password;
    }),
    key: yup.string().required(),
  });

export const setupMfaSchemaDefault = (t: TFunction) =>
  yup.object().shape({
    mfaCode: yup.string().required(t("login:mfa.yupErrors.mfaCode")).min(6, t("login:mfa.yupErrors.mfaCode")),
    rememberDevice: yup.bool(),
  });
