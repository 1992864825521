import { apiAction } from "../api";
import { SERVICE_URLS } from "../../../globals/service-urls";
import { generateParams } from "../../../helpers/generate-params";
import { LIVE_ACTION_STATUSES, MY_LIVE_ACTION_STATUSES } from "./consts";
// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";

const ESCALATION_HISTORY_STATUSES = ["Completed", "Disregarded", "Archived", "Deescalated"];

const getLiveSystemActions = ({ pdSiteId }: { pdSiteId: number }, callbacks: ApiCallback): ApiAction => {
  const params = {
    pdSiteId,
    statuses: LIVE_ACTION_STATUSES,
  };
  // my-live/actions?
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["escalation-service"]}/site/actions${generateParams(params)}`,
    ...callbacks,
  });
};

const getLiveSystemBySegments = ({ pdUnitIds }: { pdUnitIds: number[] }, callbacks: ApiCallback): ApiAction => {
  const params = {
    pdUnitIds,
    statuses: LIVE_ACTION_STATUSES,
  };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["escalation-service"]}/segment/actions${generateParams(params)}`,
    ...callbacks,
  });
};

const getMyLiveActions = ({ pdSiteId }: { pdSiteId?: number }, callbacks: ApiCallback): ApiAction => {
  const params = {
    pdSiteId,
    statuses: MY_LIVE_ACTION_STATUSES,
  };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["escalation-service"]}/my-live/actions${generateParams(params)}`,
    ...callbacks,
  });
};

const getEscalationHistory = (
  { pdSiteId, startDate, endDate }: { pdSiteId: number; startDate: string; endDate: string },
  callbacks: ApiCallback
): ApiAction => {
  const params = {
    pdSiteId,
    startDate,
    endDate,
    statuses: ESCALATION_HISTORY_STATUSES,
  };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["escalation-service"]}/actions/log${generateParams(params)}`,
    ...callbacks,
  });
};

const getEscalationPlans = (
  { pdSiteId, pdUnitIds }: { pdSiteId: number; pdUnitIds?: number[] },
  callbacks: ApiCallback
): ApiAction => {
  const params = {
    pdSiteId,
    pdUnitIds,
  };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["escalation-service"]}/plans${generateParams(params)}`,
    ...callbacks,
  });
};

const downloadEscalationPlanReport = (
  { systemDashboardId, pdSiteId }: { systemDashboardId: number; pdSiteId: number },
  callbacks: ApiCallback,
  onDownloadProgress?: Function
): ApiAction => {
  const params = { dashBoardId: systemDashboardId, pdSiteId, statuses: ESCALATION_HISTORY_STATUSES };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["escalation-service"]}/plans/report${generateParams(params)}`,
    responseType: "blob",
    ...(onDownloadProgress && { onDownloadProgress: (e: any) => onDownloadProgress(e) }),
    ...callbacks,
  });
};

const downloadEscalationHistoryReport = (
  { pdSiteId, startDate, endDate }: { pdSiteId: number; startDate: string; endDate: string },
  callbacks: ApiCallback,
  onDownloadProgress?: Function
): ApiAction => {
  const params = { pdSiteId, startDate, endDate, statuses: ESCALATION_HISTORY_STATUSES };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["escalation-service"]}/actions/history/report${generateParams(params)}`,
    responseType: "blob",
    ...(onDownloadProgress && { onDownloadProgress: (e: any) => onDownloadProgress(e) }),
    ...callbacks,
  });
};

const updateActionBatch = (
  { list }: { list: { id: number; status: string; comment?: string }[] },
  callbacks: ApiCallback
): ApiAction => {
  return apiAction({
    method: "PUT",
    data: {
      values: list,
    },
    url: `${SERVICE_URLS["escalation-service"]}/actions/batch`,
    ...callbacks,
  });
};

const EscalationService = {
  getLiveSystemActions,
  getMyLiveActions,
  getEscalationHistory,
  getEscalationPlans,
  downloadEscalationPlanReport,
  downloadEscalationHistoryReport,
  updateActionBatch,
  getLiveSystemBySegments,
};

export default EscalationService;
