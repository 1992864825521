import { withFormik } from "formik";
import { resetSchema } from "../../schemas/login.schema";
import { fetchAccountActivate } from "../../store/actions/auth";
import { TFunction } from "i18next";

const resetPasswordFormState = {
  key: "",
  username: "",
  password: "",
  confirmPassword: "",
};

export const formikEnhancerActivate = withFormik({
  validationSchema: ({ t }: { t: TFunction }) => resetSchema(t),
  mapPropsToValues: ({ match }: any) => {
    return {
      ...resetPasswordFormState,
      key: match.params.key || "",
      username: match.params.username || "",
    };
  },
  handleSubmit: (values, { setSubmitting, props }) => {
    const payload = {
      ...values,
    };
    props.fetchAccountActivate(payload);
    setSubmitting(false);
  },
  displayName: "ActivatePage",
});

export const mapDispatchToPropsActivate = (dispatch: any) => ({
  fetchAccountActivate: (values: typeof resetPasswordFormState) => dispatch(fetchAccountActivate(values)),
});
