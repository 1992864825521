import React, { useState, useEffect, useLayoutEffect } from "react";
import { accessEnv } from "../../../access-env";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "google-maps-react";
import GoogleApiWrapper from "../../../components/map/improved/GoogleApiComponent";
import { LoadingContainer } from "../../../components/map/shared/loading-container";
import facilitiesList from "../../../components/map/shared/facilities";
import { boundaries } from "../../../components/map/shared/boundaries";
import MapTooltip from "../../../components/map/shared/map-tooltip";
import { DEFAULT_MAP_STYLES, initialCenter } from "../../../components/map/globals";
import { fetchResilienceBoundary, fetchResilienceHospitals, setResilienceBounds } from "../../../store/actions/maps";
import { INTERNAL_URLS } from "../../../globals/internal-urls";
import { homeMapSettings, DEFAULT_ZOOM } from "./consts";
import { Loader } from "@tscore/react-components";
// ? TYPES:
import { TFunction } from "i18next";
import { ApplicationState } from "../../../store/reducers";
import { MapsHospital } from "../../../store/types/maps-state";
import { ExpandedMarker } from "../../../types/google-map";
// import { ApiError } from "../../store/types/api";
import { RouteComponentProps } from "react-router-dom";

interface HomeMapProps extends RouteComponentProps<{}> {
  google: any;
  t: TFunction;
}

// type HomeMapProps = { google: any; history: any };

const HomeMap: React.FC<HomeMapProps> = ({ google, history, t }) => {
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [isSetupLoading, setIsSetupLoading] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [currentFacilityHover, setCurrentFacilityHover] = useState<ExpandedMarker | null>(null);
  const [currentChildHover, setCurrentChildHover] = useState<any | null>(null);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM - 1);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchInitial() {
      // for (const item of [fetchResilienceBoundary, fetchResilienceHospitals]) {
      //   await dispatch(item());
      // }
      await dispatch(fetchResilienceBoundary());
      await dispatch(fetchResilienceHospitals());
    }
    fetchInitial();
  }, [dispatch]);
  const state = useSelector((state: ApplicationState) => state.mapsReducer);
  const { isFetching, resilienceHome } = state;
  useLayoutEffect(() => {
    if (
      isSetupLoading === true &&
      !isFetching["GET_RESILIENCE_HOSPITALS"] &&
      !isFetching["GET_RESILIENCE_BOUNDARY"] &&
      !!google
    ) {
      dispatch(setResilienceBounds(google));
      setIsSetupLoading(false);
    }
  }, [dispatch, isSetupLoading, setIsSetupLoading, isFetching, google]);
  // console.log({ resilienceHome });
  const isLoading = isMapLoading || isFetching["GET_RESILIENCE_BOUNDARY"] || isFetching["GET_RESILIENCE_HOSPITALS"];

  // ? MARKERS ==========================================================
  const onHospitalClick = (marker: MapsHospital) => {
    if (!marker.dashboardId) {
      return false;
    }
    const siteId = marker.siteId ? marker.siteId : "primary";
    history.push({
      pathname: `${INTERNAL_URLS.systemDashboardList}/${marker.dashboardId}/${siteId}/?origin=home`,
    });
  };
  return (
    // <>
    <div id="map" className="google-map shadowed">
      <Map
        {...DEFAULT_MAP_STYLES}
        google={google}
        // onMouseout={this.onMapMouseOut}
        // onMousemove={this.onMapMouseMove}
        onDragstart={() => setIsDragging(true)}
        onDragend={() => setIsDragging(false)}
        onReady={() => setIsMapLoading(false)}
        onZoomChanged={() => {
          if (zoom !== DEFAULT_ZOOM && resilienceHome.bounds !== undefined) {
            if (accessEnv("DEPLOYMENT_TYPE", "uk") === "uk") {
              setZoom(DEFAULT_ZOOM);
            }
          }
        }}
        // onIdle={() => setZoom(8)}
        initialCenter={initialCenter}
        {...homeMapSettings}
        bounds={!isLoading ? resilienceHome.bounds : undefined}
        zoom={zoom}>
        {isLoading && <Loader placement="top left" />}
        {facilitiesList({
          facilities: resilienceHome.hospitals,
          onMouseover: (facility: any, _props: any, _marker: ExpandedMarker, _MouseEvent: any) =>
            setCurrentFacilityHover(facility),
          onMouseout: () => setCurrentFacilityHover(null),
          currentFacilityHover: currentFacilityHover,
          onFacilityClick: onHospitalClick,
        })}
        {boundaries({
          polygonCosmetics: {
            fillColor: "#4D77C9",
            strokeColor: "#4D77C9",
            strokeWeight: 1,
          },
          opacityList: {
            ON_HOVER: 0.9,
            DEFAULT: 0.3,
            OTHER_HOVERED: 0.2,
          },
          children: resilienceHome.boundary.child,
          currentChildHover: currentChildHover,
          onMouseout: () => setCurrentChildHover(null),
          onMousemove: (feature) => setCurrentChildHover(feature),
        })}
        <MapTooltip visible={!!currentFacilityHover || !!currentChildHover} isDragging={isDragging}>
          <div>
            {currentFacilityHover && (
              <div>
                <span>{t("map:Hospital")}: </span>
                <strong>{currentFacilityHover.organisationName}</strong>
              </div>
            )}
            {(currentChildHover?.properties || currentFacilityHover?.ccg19nm) && (
              <div>
                <span>{t("map:CCG")}: </span>
                <strong>{currentChildHover?.properties.ccg19nm || currentFacilityHover?.ccg19nm}</strong>
              </div>
            )}
          </div>
        </MapTooltip>
        {/* <ErrorsComponent errors={this.state.hasErrors} onClose={this.resetErrors} /> */}
      </Map>
    </div>
  );

  /* {isLoading && <Loader scale="large" />}
      <span style={{ position: "absolute" }}>todo</span>
      <div id="map" className="shadowed" />
      <div id="map-overlay" className="map-overlay" />
    </> */

  // );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_API_GOOGLE_MAP_KEY,
  LoadingContainer: LoadingContainer,
  libraries: [],
})(HomeMap);
