export function generateDyOffset(length: number, lineHeightEm: number, heightEm = 0): number[] {
  const halfHeightEm = heightEm / 4;
  const z = lineHeightEm / 2;
  const arr = length % 2 === 0 ? [z * -1 + halfHeightEm, z + halfHeightEm] : [0 + halfHeightEm];
  if (length <= 2) {
    return arr;
  }
  let i: number;
  for (i = 1; i < length / 2; i += 1) {
    const b = arr[arr.length - 1] + lineHeightEm;
    const a = arr[0] - lineHeightEm;
    arr.unshift(a);
    arr.push(b);
  }
  return arr;
}