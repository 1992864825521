import { openDatabase, readFromDatabase, saveToDatabase } from "./database";
import { generateRandomId } from "../../utils/generate-random-id";
// ? TYPES:
import { Dispatch } from "redux";

const DB_NAME = "shrewd-db";

export const SET_DB_VALUE = "SET_DB_VALUE";
export const SET_DB_LOADING = "SET_DB_LOADING";
export const SET_DB_ERROR = "SET_DB_ERROR";

type Key = "deviceId";

interface SetValueAction {
  type: typeof SET_DB_VALUE;
  key: Key;
  value: any;
}

interface SetLoadingAction {
  type: typeof SET_DB_LOADING;
  key: Key;
  isLoading: boolean;
}

interface SetErrorAction {
  type: typeof SET_DB_ERROR;
  key: Key;
  error: string | null;
}

export type IndexedDBActionTypes = SetValueAction | SetLoadingAction | SetErrorAction;

export const setDbValue = (key: Key, value: string): SetValueAction => ({
  type: SET_DB_VALUE,
  key,
  value,
});

export const setDbLoading = (key: Key, isLoading: boolean): SetLoadingAction => ({
  type: SET_DB_LOADING,
  key,
  isLoading,
});

export const setDbError = (key: Key, error: string | null): SetErrorAction => ({
  type: SET_DB_ERROR,
  key,
  error,
});

export const fetchDeviceId = () => async (dispatch: Dispatch) => {
  const dbName = DB_NAME;
  const storeName = "config";
  const key = "deviceId";
  dispatch(setDbLoading(key, true));
  try {
    const db = await openDatabase(dbName, storeName);
    let dbValue = await readFromDatabase(db, storeName, key);
    if (dbValue === undefined) {
      dbValue = generateRandomId();
      await saveToDatabase(db, storeName, key, dbValue);
    }
    dispatch(setDbValue(key, dbValue));
  } catch (error: any) {
    console.error("@@Device ID retrieval failed@@");
    dispatch(setDbValue(key, "errored-" + generateRandomId()));
    dispatch(setDbError(key, error.toString()));
  }
};
