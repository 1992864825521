import { FOLDER_ID_DELIMINATOR } from "../../globals/tableau-settings";
import { generateParams } from "../../helpers/params";

const DEFAULT_REFRESH_INTERVAL = 240000;

export function splitAtCapital(str: string) {
  const s = str.split(FOLDER_ID_DELIMINATOR).pop() || "";
  return (s.match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g) || []).join(" ");
}
// vizCreate options
export const DEFAULT_OPTIONS: tableau.VizCreateOptions & { interval?: number } = {
  // height: 100,
  // width: "100%",
  hideTabs: true,
  hideToolbar: false,
  interval: DEFAULT_REFRESH_INTERVAL,
  // added interval support - an integer can be passed as milliseconds in the options object and refreshDataAsync() will refresh the data automatically on your preferred interval.
  // All other vizCreate options are supported here, too
  // They are listed here: https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#vizcreateoptions_record
};

// defaults to '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes'
const DEFAULT_QUERY_OBJECT = {
  ":embed": "y", // required
  ":render": "n", // fonts server side
  ":jsdebug": "n", // hide js debug
  ":showVizHome": "n",
  ":comments": "n",
  ":showShareOptions": "n", // hide non working share button
  ":refresh": "y", //  auto refresh data every DEFAULT_REFRESH_INTERVAL
};

export const DEFAULT_QUERY = generateParams(DEFAULT_QUERY_OBJECT);
