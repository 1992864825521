import React, { Component } from "react";
import Meta from "../components/meta";
import { withTranslation } from "react-i18next";
import { MetaFooter } from "../components/structure/meta-footer";
// ? TYPES:
import { TFunction } from "i18next";
import { Button } from "@tscore/react-components";

interface Props {
  t: TFunction;
}
export class ErrorCatcher extends Component<Props, { error: null | string }> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  reloadPage(e: any) {
    if (e) {
      e.preventDefault();
    }
    window.location.reload();
  }

  static getDerivedStateFromError(error: { message: string }) {
    // Update state so the next render will show the fallback UI.
    // eslint-disable-next-line
    console.log({ error });
    const message = typeof error.message === "string" ? error.message : JSON.stringify(error.message);
    return { error: message };
  }

  componentDidCatch(_error: any, _errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <main id="full-error">
          <Meta title="Error" hideMeta hideFooter />
          <div className="full-error-container full-error-container--internal">
            <section>
              <div>
                <h1>{this.props.t("errors:unhandledError.title")}</h1>
                <p>{this.props.t("errors:unhandledError.body")}</p>
                <pre className="error-debug">
                  <code>
                    Error message:
                    <br />
                    {this.state.error}
                  </code>
                </pre>
                <Button onClick={this.reloadPage} colour="link" scale="large" length="short">
                  {this.props.t("errors:unhandledError.action")}
                </Button>
              </div>
              <div />
            </section>
            <footer>
              <MetaFooter />
              <div />
            </footer>
          </div>
          <style dangerouslySetInnerHTML={{ __html: "#container { min-width: 0; }" }} />
        </main>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorCatcher);
