import React from "react";
import { buildWheel, generateWheelScripts } from "@tscore/wheel-builder";
import ReactFauxDOM from "react-faux-dom";
// ? Types
import { WheelInput, WheelQuery } from "@tscore/wheel-builder/dts/types";
// import { wheelData } from "../sample/SAMPLE_WHEEL_DATA";

type WheelExtendedProps = React.HTMLProps<HTMLElement> & React.HTMLAttributes<HTMLElement>;

interface WheelProps extends WheelQuery {
  unitList: WheelInput[];
}

export const Wheel = ({
  unitList,
  wheelId = -1,
  isResponsive = true,
  callback = null,
  isStatic = false,
  showEscalation = false,
  size = 600,
  hideText = false,
  ...other
}: WheelProps & WheelExtendedProps): React.ReactElement => {
  // const settings = {
  //   wheelId,
  //   isResponsive,
  //   size,
  //   isStatic,
  //   callback,
  //   showEscalation,
  //   hideText
  // };
  const output = buildWheel(
    ReactFauxDOM.createElement("svg"),
    unitList,
    {
      ...other,
      wheelId,
      isResponsive,
      callback,
      isStatic,
      showEscalation,
      size,
      hideText,
    },
    true,
    undefined,
    false
  );
  // ! IF WHEEL NOT STATIC:
  if (!isStatic) {
    generateWheelScripts(output.scripts);
  }
  const o = (output.svg as ReactFauxDOM.Element).toReact();
  return <div key={wheelId}>{o}</div>;
};

// Wheel.defaultProps = {
//   wheelId: -1,
//   isResponsive: true,
//   callback: null,
//   isStatic: false,
//   showEscalation: false,
//   size: 600,
//   hideText: false,
// };
