import React, { useEffect, useRef } from "react";
// import { BRAG_COLOURS, BRAG_PRESSURE_LEVEL_MAPPING } from "../globals";
// ? TYPES:
// import { AreaPressureLevel, FilterPressureLevel } from "../../../types/google-map";

const TOOLTIP_WIDTH_MIN = 280;
const TOOLTIP_Y_MARGIN = 24;
const ARROW_SIZE = 8;

interface Props {
  children: any;
  isDragging: boolean;
  visible: boolean;
}

const getTooltipTranslate = (
  xy: number[],
  dimensions: any,
  tooltipHeight: number,
  tooltipWidth: number,
  yMargin = 0
): {
  tooltipTranslate: string;
  arrowLeft: string;
} => {
  const TOOLTIP_WIDTH = tooltipWidth;
  let arrowLeft = "";
  let x = xy[0] - TOOLTIP_WIDTH / 2;
  // console.log(dimensions, xy, x);
  if (dimensions.width - TOOLTIP_WIDTH / 2 <= xy[0]) {
    x = dimensions.width - TOOLTIP_WIDTH;
    // ? Arrow position
    const arrowPosition = xy[0] - x - ARROW_SIZE;
    const left = TOOLTIP_WIDTH - 26 >= arrowPosition ? arrowPosition : TOOLTIP_WIDTH - 22;
    arrowLeft = `${left}px`;
  }
  // TODO: Check left side for X
  // const y = xy[1] - tooltipHeight - yMargin;
  const y = window.innerHeight - xy[1] + yMargin;
  // TODO: Maybe flip tooltip depending on position
  return {
    tooltipTranslate: `translate3d(${x}px,-${y}px,0)`,
    arrowLeft: arrowLeft,
  };
};

const MapTooltip = ({ children, isDragging = false, visible }: Props): JSX.Element | null => {
  const tooltipRef = useRef(null);
  const arrowRef = useRef(null);
  // const [xy, setXy] = useState([0, 0]);
  //updateValue([event.clientX, event.clientY]);
  useEffect(() => {
    // const updateValue = throttle((value: number[]) => {
    //   setXy(value);
    // }, 1);

    const handleScroll = (event: any) => {
      const xy = [event.clientX, event.clientY];
      // updateValue(xy);
      if (tooltipRef.current !== null) {
        const positions = getTooltipTranslate(
          xy,
          {
            width: window.innerWidth,
            height: window.innerHeight,
          },
          (tooltipRef.current as any).offsetHeight,
          (tooltipRef.current as any).offsetWidth,
          TOOLTIP_Y_MARGIN
        );
        (tooltipRef.current as any).style.transform = positions.tooltipTranslate;
        (arrowRef.current as any).style.left = positions.arrowLeft;
      }
    };

    window.addEventListener("mousemove", handleScroll, true);

    return () => {
      window.removeEventListener("mousemove", handleScroll);
    };
  }, []);
  // if (isDragging) {
  //   return null;
  // }
  // if (!visible) {
  //   return null;
  // }
  return (
    <div
      ref={tooltipRef}
      className="box map-tooltip"
      style={{
        bottom: 0,
        left: 0,
        minWidth: `${TOOLTIP_WIDTH_MIN}px`,
        width: "auto",
        pointerEvents: "none",
        visibility: visible && !isDragging ? "visible" : "hidden",
        // transform: getTooltipTranslate(xy, TOOLTIP_Y_MARGIN)
      }}>
      <div className="arrow-bottom" ref={arrowRef} />
      {children}
    </div>
  );
};

export default MapTooltip;
