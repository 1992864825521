import { getTokens } from "../../../get-tokens";

export const SERVICE_NAME = "nhsr-service";

export const DEFAULT_REPORT_SETTINGS = () => ({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getTokens().access_token}`,
  },
});
