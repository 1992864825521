import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultApiLoading } from "../default-api-loading";
// import { defaultApiSuccess } from "../default-api-success";
// ? TYPES:
import { PredictionState } from "../../types/prediction-state";

const defaultState: PredictionState = {
  data: [],
  chartData: {},
  isFetching: {},
  errors: {},
  didInvalidate: {}, // not used
};

const prediction = (state: PredictionState = defaultState, action: any): PredictionState => {
  switch (action.type) {
    case API_START: {
      return defaultApiLoading(state, action, true, ["GET_DASHBOARD_PREDICTION", "GET_PREDICTION_CHART"]);
    }
    case API_ERROR: {
      return defaultApiError(state, action);
    }
    case API_END: {
      return defaultApiLoading(state, action, false, ["GET_DASHBOARD_PREDICTION", "GET_PREDICTION_CHART"]);
    }
    case "GET_DASHBOARD_PREDICTION": {
      return {
        ...state,
        data: action.data.indicatorGroupDtos,
      };
    }
    case "GET_PREDICTION_CHART": {
      return {
        ...state,
        chartData: {
          ...state.chartData,
          [action.idIndicator]: action.data,
        },
      };
    }
    default:
      return state;
  }
};

const predictionById = (
  state: { [dashboardId: string]: PredictionState } = { "-1": defaultState },
  action: any
): any => {
  switch (action.type) {
    case API_START:
    case API_ERROR:
    case API_END:
    case "GET_DASHBOARD_PREDICTION":
    case "GET_PREDICTION_CHART":
      if (!action.id) {
        return state;
      }
      return {
        ...state,
        [action.id]: prediction(state[action.id], action),
      };
    default:
      return state;
  }
};

export default predictionById;
