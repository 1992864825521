import { select, selectAll } from "d3-selection";
import { pie, arc } from "d3-shape";
import "d3-selection-multi";

// import {
//   scaleLinear,
//   scaleTime,
//   scaleOrdinal,
// } from "d3-scale";

// import { axisTop } from "d3-axis";

export default {
  select,
  selectAll,
  pie,
  arc,
};