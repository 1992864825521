import { PaginatedSort } from "../types/paginated-params";

type SingleParam = string | number | null | boolean | undefined;
type MultiParam = string[] | number[] | null[] | boolean[];

interface GenerateParamsSettings {
  addTrailingQuestionMark?: boolean;
  encodeValues?: boolean;
}

export const generateParams = (
  params: { [key: string]: SingleParam | MultiParam } | null,
  { addTrailingQuestionMark = true, encodeValues = false }: GenerateParamsSettings = {}
): string => {
  if (params === null) {
    return "";
  }
  const paramKeys = Object.keys(params);
  const keys = paramKeys.filter((key: string) => params[key] !== undefined && !Array.isArray(params[key]));
  const arrayKeys = paramKeys.filter((key: string) => Array.isArray(params[key]));
  if (keys.length === 0 && arrayKeys.length === 0) {
    return "";
  }
  const arrayOutput: string[] = arrayKeys.map((key: string) =>
    (params as any)[key].map((item: any) => key + "=" + item).join("&")
  );
  const singleOutput: string[] = keys.map(
    (key: string) => key + "=" + (encodeValues ? encodeURIComponent(params[key] as string) : params[key])
  );
  return (addTrailingQuestionMark ? "?" : "") + [...arrayOutput, ...singleOutput].join("&");
};

export const generateSortApiArray = (sort?: PaginatedSort): string[] | undefined => {
  if (!sort || Object.keys(sort).length === 0) {
    return undefined;
  }
  return Object.entries(sort).reduce((final: string[], [key, value]) => {
    final.push(key + "," + value);
    return final;
  }, []);
};

export const parseSort = (sortStr: string | undefined): PaginatedSort => {
  if (!sortStr) {
    return {};
  }
  return sortStr.split(",").reduce((acc, curr) => {
    const order = curr.startsWith("-") ? "DESC" : "ASC";
    const key = order === "DESC" ? curr.substring(1) : curr;
    return {
      ...acc,
      [key]: order,
    };
  }, {});
};

export const generateSortFieldParams = (sort?: Partial<PaginatedSort>): string | undefined => {
  if (!sort) {
    return undefined;
  }
  const entries = Object.entries(sort).filter(([_key, order]) => order !== undefined);
  if (entries.length === 0) {
    return undefined;
  }
  return entries
    .map(([key, order]) => {
      return order === "DESC" ? "-" + key : key;
    })
    .join(",");
};
