export const wrappedPromise = function () {
  const _wrappedPromise: any = {};
  const promise = new Promise(function (resolve, reject) {
    _wrappedPromise.resolve = resolve;
    _wrappedPromise.reject = reject;
  });
  _wrappedPromise.then = promise.then.bind(promise);
  _wrappedPromise.catch = promise.catch.bind(promise);
  _wrappedPromise.promise = promise;

  return _wrappedPromise;
};
