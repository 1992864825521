import { apiAction } from "../api";
// import { SERVICE_URLS } from "../../../globals/service-urls";
import { generateParams } from "../../../helpers/generate-params";
// import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";
import { getResilienceBoundary, getResilienceHospitals } from "./services/resilience.service";
import { getNhsrParentBoundary, getNhsrChildBoundary, getNhsrHospitals } from "./services/region.service";
import { getElectiveInitial, getElectiveMarkers } from "./services/elective.service";
import { getVantageInitial, getVantageMarkers } from "./services/vantage.service";
// ? TYPES:
// import { ElectiveData } from "../../types/maps-elective";
import { ApiCallback, ApiAction } from "../../types/api";

// import { PaginatedParams } from "../../../types/paginated-params";

// const VERSION = 1;

// === SLAMDUNK
const GJ_API_VER = "api/v2";
const GJ_PARTIAL = `/slamdunk-geojson/${GJ_API_VER}`;

const getSlamdunkCcgBoundaries = (
  { ccgNames, isCombined = false }: { ccgNames: string[]; isCombined: boolean },
  callbacks: ApiCallback
): ApiAction => {
  const params = { isCombined, ccgs: ccgNames.join(",") };
  return apiAction({
    headers: {
      Authorization: undefined,
    },
    url: `${GJ_PARTIAL}/ccg/boundaries${generateParams(params)}`,
    method: "GET",
    ...callbacks,
  });
};
// ? children = white boundaries
// const getSlamdunkChildrenGeoJson = ({ featureName }: { featureName: string }, callbacks: ApiCallback): ApiAction => {
//   const params = {
//     feature_name: encodeURIComponent(featureName),
//   };
//   return apiAction({
//     headers: {
//       Authorization: undefined,
//     },
//     url: `${GJ_PARTIAL}/feature/children${generateParams(params)}`,
//     method: "GET",
//     ...callbacks,
//   });
// };

// ? Parent
// - maybe needed later

// Elective

const MapsService = {
  getResilienceBoundary,
  getResilienceHospitals,
  getNhsrParentBoundary,
  getNhsrChildBoundary,
  getNhsrHospitals,
  getElectiveInitial,
  getElectiveMarkers,
  // getSlamdunkChildrenGeoJson,
  getSlamdunkCcgBoundaries,
  getVantageInitial,
  getVantageMarkers,
};

export default MapsService;
