import React from "react";
import { getRandomArbitrary, getRandomWidthStyles } from "../../helpers/random-generators";

export const TableauRepositoryTableEmpty: React.FC<{ length: number }> = ({ length }) => {
  const loadingElementWidths = getRandomWidthStyles((length === 0 ? getRandomArbitrary(3, 7) : length) * 2);
  return (
    <>
      {loadingElementWidths.map((e: any, i: number) => {
        if (i % 2 === 0) {
          return null;
        }
        const hasDescription = Math.round(getRandomArbitrary(0, 1)) === 1 ? true : false;
        return (
          <ul key={i} className="row isFetching" aria-label="Loading">
            <li aria-hidden="true" className="cell" style={{ textOverflow: "clip" }}>
              <div className="loading-element no-margin">.</div>
            </li>
            <li aria-hidden="true" className="cell description-holder">
              {hasDescription && (
                <div className="description loading-element no-margin" {...loadingElementWidths[i + 1]}>
                  ...
                </div>
              )}
            </li>
          </ul>
        );
      })}
    </>
  );
};

export const TableauRepositoryGridEmpty: React.FC<{ length: number; style?: React.CSSProperties }> = ({
  length,
  style,
}) => {
  const loadingElementWidths = getRandomWidthStyles((length === 0 ? getRandomArbitrary(3, 7) : length) * 2);
  return (
    <div className="grid__container tableau__grid__container isFetching" style={style} aria-label="Loading">
      {loadingElementWidths.map((e: any, i: number) => {
        if (i % 2 === 0) {
          return null;
        }
        const hasDescription = Math.round(getRandomArbitrary(0, 1)) === 1 ? true : false;
        return (
          <div key={i} className="grid__item" aria-hidden="true">
            <div className="grid__content" style={{ cursor: "default" }}>
              <div className="grid__thumbnail" data-thumbnail={false}></div>
              <div className="grid__meta">
                <span className="loading-element no-margin" {...loadingElementWidths[i]}>
                  .
                </span>
                {hasDescription && (
                  <span className="relative block mt4">
                    <span
                      className="p grid__description loading-element no-margin"
                      data-possible-expansion={false}
                      {...loadingElementWidths[i + 1]}>
                      ...
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
