import apiMiddleware from "../store/middleware/api";
import { apiAction } from "../store/actions/api";
import store from "../store";

// ? TYPES:
import { ApiActionPayload } from "../store/types/api";
export const simpleApi = (simpleAction: ApiActionPayload, { withAuthorization = true, skipStore = false } = {}) => {
  const action = apiAction({ ...simpleAction, skipAuthorization: !withAuthorization });
  function dispatch(_: any): void {
    //
  }
  function next(_: any): void {
    //
  }
  function getState() {
    //
  }
  const _store = skipStore ? { dispatch, getState } : store;
  apiMiddleware(_store)(next)(action);
};
