import IntegrationsService from "./integrations.service";
import { cancelRequest } from "../../../helpers/cancel-request";
import { changeToLastPageIfNeeded } from "../../utils/change-to-last-page-if-needed";
import { IntegrationPlot, IntegrationTypePlot } from "../../reducers/integrations/consts";
// ? TYPES:
import { ApiAction } from "../../types/api";
import { PaginatedSort } from "../../../types/paginated-params";
import { TIntegrationKey, TIntegrationTypeKey } from "../../reducers/integrations/consts";
import { IntegrationsListResponse } from "../../types/integrations-state";
import { ApplicationState } from "../../reducers";

const getIntegrationIds = (integration: TIntegrationKey, integrationType: TIntegrationTypeKey) => ({
  integration,
  integrationType,
  integrationId: IntegrationPlot[integration],
  integrationTypeId: IntegrationTypePlot[integrationType],
});

const EMPTY_GET_RESPONSE = {
  meta: { contractList: [] },
  page: 1,
  limit: 1,
  results: [],
  totalRecords: 0,
};

const getTableauDashboardList = (
  integration: TIntegrationKey,
  integrationType: TIntegrationTypeKey,
  dynamicId: number,
  data: IntegrationsListResponse
) => ({
  type: "GET_TABLEAU_DASHBOARD_LIST",
  integration,
  integrationType,
  dynamicId,
  data,
});

const getTableauList = (
  integration: TIntegrationKey,
  integrationType: TIntegrationTypeKey,
  dynamicId: number,
  data: IntegrationsListResponse
) => ({
  type: "GET_TABLEAU_INTEGRATION_LIST",
  integration,
  integrationType,
  dynamicId,
  data,
});

export const fetchTableauList = (
  contractId: number,
  integrationType: TIntegrationTypeKey,
  page = 1,
  query?: string,
  sort?: PaginatedSort,
  lastPageCallback?: (page: number) => void
): ApiAction => {
  cancelRequest("GET_TABLEAU_INTEGRATION_LIST");
  const { integration, integrationId, integrationTypeId } = getIntegrationIds("TABLEAU", integrationType);
  const contractIds = [contractId];
  return IntegrationsService.getIntegrationList(
    { integrationId, integrationTypeId, page, search: query, sort, contractIds },
    {
      label: "GET_TABLEAU_INTEGRATION_LIST",
      onSuccess: (response) => {
        changeToLastPageIfNeeded(lastPageCallback, page, response);
        return getTableauList(integration, integrationType, contractId, response);
      },
      // onFailure: () => console.log("Error occured loading articles"),
      other: { integration, integrationType, dynamicId: contractId },
    }
  );
};

const fetchTableauDashboardList = (dashboardId: number, integrationType: TIntegrationTypeKey): ApiAction => {
  cancelRequest("GET_TABLEAU_DASHBOARD_LIST");
  const { integration, integrationId, integrationTypeId } = getIntegrationIds("TABLEAU", integrationType);
  const dashboardIds = [dashboardId];
  return IntegrationsService.getIntegrationList(
    {
      integrationId,
      integrationTypeId,
      dashboardIds,
      disablePagination: true,
    },
    {
      label: "GET_TABLEAU_DASHBOARD_LIST",
      onSuccess: (response) => getTableauDashboardList(integration, integrationType, dashboardId, response),
      onFailure: (_err, code) => {
        if (code === 403) {
          return getTableauDashboardList(integration, integrationType, dashboardId, EMPTY_GET_RESPONSE);
        }
      },
      other: { integration, integrationType, dynamicId: dashboardId },
    }
  );
};

export const fetchTableauDashboardListIfNeeded = (dashboardId: number, integrationType: TIntegrationTypeKey) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const { integration } = getIntegrationIds("TABLEAU", integrationType);
    const state = getState().integrationsReducer[integration][integrationType];
    const dashboardState = state[dashboardId] || state[-1];
    if (Date.now() >= dashboardState.invalidateTimestamp) {
      dispatch(fetchTableauDashboardList(dashboardId, integrationType));
    }
  };
};
