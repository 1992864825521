import React from "react";
import { NavLink } from "react-router-dom";
import { Icon, SVGIcon } from "@tscore/react-components";
import { MFAIcons } from "./../icons";
import { Trans, withTranslation } from "react-i18next";
// ? TYPES:
import { TFunction } from "react-i18next";
import { MFAType } from "../../../../store/types/credentials-state";

interface Props {
  mfaType: MFAType;
  isRegistered?: boolean;
  t: TFunction;
}

const WelcomeOptionWoT: React.FC<Props> = ({ mfaType, isRegistered, t }) => {
  const WrapperComponent = isRegistered ? "div" : NavLink;
  const to = isRegistered ? undefined : { search: `?type=${mfaType}` };
  return (
    <WrapperComponent to={to!} className="mfa__options__item drop-area">
      <div className="mfa__options__icon">
        <SVGIcon svg={MFAIcons[mfaType]} />
      </div>
      <div>
        <h4 className="mfa__options__title">
          <Trans
            ns="login"
            i18nKey={`mfa.setup.init.options.${mfaType}.title`}
            components={[
              <span key={0} style={{ opacity: 0.46 }}>
                eg
              </span>,
            ]}></Trans>
        </h4>
        <p>{t(`mfa.setup.init.options.${mfaType}.description`)}</p>
      </div>
      <div>
        {!isRegistered ? (
          <Icon className="mfa__options__arrow">east</Icon>
        ) : (
          <div className="welcome__option__enabled">
            <Icon>check</Icon>
            <span>Enabled</span>
            {/* // ! TODO add translation */}
          </div>
        )}
      </div>
    </WrapperComponent>
  );
};

export const WelcomeOption = withTranslation(["login"])(WelcomeOptionWoT);
