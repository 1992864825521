import { removeUserDuplicates } from "./remove-user-duplicates";
import { GENERATE_STATUSES } from "../../actions/escalation/consts";
// ? TYPES:
import { EscalationState, Action, ActionList } from "../../types/escalation-state";
const STATUSES = GENERATE_STATUSES((status: any) => status);

// after all calcs is done
export const generateDataset = (indicatorActionList: ActionList[string]["indicators"]) => {
  const xAxis = STATUSES.reduce((final: { [name: string]: 0 }, status: (typeof STATUSES)[number]) => {
    final[status.value] = 0;
    return final;
  }, {});
  return Object.values(indicatorActionList).reduce((final: { [status: string]: number }, current) => {
    for (let i = 0; i < current.actions.length; i += 1) {
      const action = current.actions[i];
      const previousNumber = final[action.status] || 0;
      final[action.status] = previousNumber + 1;
    }
    return final;
  }, xAxis);
};

export const recalculateDataset = (output: EscalationState["liveActionList" | "myLiveActionList"]) => {
  return Object.keys(output).reduce((final: any, key: string) => {
    final[key] = {
      ...final[key],
      dataset: generateDataset(final[key].indicators),
    };
    return final;
  }, output);
};

export const generateActionList = (actionList: Action[]) => {
  const output = actionList.reduce((final: EscalationState["liveActionList" | "myLiveActionList"], current: Action) => {
    const prev = final[current.labelId];
    const prevIndicators = (prev || { indicators: {} }).indicators;
    const usersAssigned = [
      ...(prevIndicators[current.indicatorId] || { usersAssigned: [] }).usersAssigned,
      ...current.usersAssigned,
    ];
    final[current.labelId] = {
      labelName: current.labelName,
      labelId: current.labelId,
      indicators: {
        ...prevIndicators,
        [current.indicatorId]: {
          indicatorId: current.indicatorId,
          indicatorName: current.indicatorName,
          actions: [...(prevIndicators[current.indicatorId] || { actions: [] }).actions, current],
          usersAssigned: removeUserDuplicates(usersAssigned),
        },
      },
      // list: [...(prev || { list: [] }).list, current],
      dataset: {},
      totalActions: prev ? (prev.totalActions += 1) : 1,
      completedActions: 0,
    };
    return final;
  }, {});
  return recalculateDataset(output);
  // console.log({ withDs });
  // return output;
};
