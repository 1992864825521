// ? TYPES:
import { DeploymentType } from "./types/deployment-type";

const cache: { [key: string]: string } = {};

export const accessEnv = <T = string>(k: string, defaultValue?: T): T => {
  const key = "REACT_APP_" + k;
  const env = process.env;
  if (!(key in env) || typeof env[key] === undefined) {
    if (defaultValue) return defaultValue;
    throw new Error(`${key} not found in process.env!`);
  }

  if (!(key in cache)) {
    cache[key] = env[key] as string;
  }

  return cache[key] as T;
};

export function getDeploymentConfig<T = any>(deploymentObject: { [key: string]: T }) {
  const DEPLOYMENT = accessEnv<"uk" | "ca">("DEPLOYMENT_TYPE", "uk");
  return deploymentObject[DEPLOYMENT as DeploymentType] || deploymentObject["uk"];
}
