import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultApiLoading } from "../default-api-loading";
// import { defaultApiSuccess } from "../default-api-success";
// import groupBy from "lodash/groupBy";
import { downloadFile } from "../../../helpers/download-file";
import { removeUserDuplicates } from "./remove-user-duplicates";
import { generateActionList, recalculateDataset } from "./generate-action-list";
import { LIVE_ACTION_STATUSES } from "../../actions/escalation/consts";

// import cloneDeep from "lodash/cloneDeep";
// ? TYPES:
import { EscalationState, Action, ActionHistory, ActionList, EscalationPlan } from "../../types/escalation-state";

const defaultState: EscalationState = {
  isFetching: {},
  errors: {},
  didInvalidate: {}, // not used
  liveActionList: {},
  escalatedPdUnitIds: [],
  plans: [],
  plansFilters: {},
  escalationHistory: {},
  myLiveActionList: {},
  unitToLabelMap: {},
  fetchingEscalationUnits: {},
};

const updateActions = (
  action: any,
  actionList: EscalationState["myLiveActionList"],
  newActions: Action[],
  addOrRemoveFromTotal = 0
): EscalationState["myLiveActionList"] => {
  const newIndicators = {
    ...actionList[action.labelId].indicators,
    [action.indicatorId]: {
      ...actionList[action.labelId].indicators[action.indicatorId],
      actions: newActions,
    },
  };
  const updated: EscalationState["myLiveActionList"] = {
    ...actionList,
    [action.labelId]: {
      ...actionList[action.labelId],
      indicators: newIndicators,
      // totalActions: actionList[action.labelId].totalActions + addOrRemoveFromTotal,
      completedActions: actionList[action.labelId].completedActions + addOrRemoveFromTotal,
      // dataset: generateDataset(newIndicators),
    },
  };
  const z = recalculateDataset(updated);
  return z;
  // if (newActions.length === 0 && Object.values(updated[action.labelId].indicators).length === 1) {
  //   const { [action.labelId]: ignore, ...rest1 } = updated;
  //   return rest1;
  // }
  // if (newActions.length === 0) {
  //   const { [action.indicatorId]: ignore, ...rest2 } = updated[action.labelId].indicators;
  //   return {
  //     ...updated,
  //     [action.labelId]: {
  //       ...updated[action.labelId],
  //       indicators: rest2,
  //     },
  //   };
  // }
  // return updated;
};

const generateNewActions = (action: any, oldActionList: ActionList) => {
  let addOrRemoveFromTotal = 0;
  const previousIndicator = { ...oldActionList[action.labelId].indicators[action.indicatorId] };
  const newActions = previousIndicator.actions.map((a) => {
    const updatedAction = (action.data as Action[]).find((updatedA) => updatedA.id === a.id);
    if (updatedAction) {
      if (!LIVE_ACTION_STATUSES.includes(updatedAction.status) && LIVE_ACTION_STATUSES.includes(a.status)) {
        addOrRemoveFromTotal -= 1;
      } else if (LIVE_ACTION_STATUSES.includes(updatedAction.status) && !LIVE_ACTION_STATUSES.includes(a.status)) {
        addOrRemoveFromTotal += 1;
      }
      return {
        ...a,
        // ...updatedAction,
        status: updatedAction.status,
        comment: updatedAction.comment,
        updatedAt: updatedAction.updatedAt,
        updatedBy: updatedAction.updatedBy,
      };
    }
    return a;
  });
  // .filter((a) => {
  //   if (LIVE_ACTION_STATUSES.includes(a.status)) {
  //     return true;
  //   }
  //   removeFromTotal += 1;
  //   return false;
  // });
  return {
    newActions,
    addOrRemoveFromTotal,
  };
};

const escalation = (state: EscalationState = defaultState, action: any): EscalationState => {
  switch (action.type) {
    case API_START: {
      let prevState = state;
      if (action.payload === "SHOW_ESCALATION_BAR") {
        prevState = {
          ...prevState,
          fetchingEscalationUnits: {
            ...prevState.fetchingEscalationUnits,
            [action.unitId]: true,
          },
        };
      }
      return defaultApiLoading(prevState, action, true, undefined, true, true);
    }
    case API_ERROR: {
      return defaultApiError(state, action);
    }
    case API_END: {
      let prevState = state;
      if (action.payload === "SHOW_ESCALATION_BAR") {
        prevState = {
          ...prevState,
          fetchingEscalationUnits: {
            ...prevState.fetchingEscalationUnits,
            [action.unitId]: false,
          },
        };
      }
      return defaultApiLoading(prevState, action, false, undefined, true);
    }
    case "GET_ESCALATION_PLANS": {
      const results: EscalationPlan[] = action.data.results;
      const filtersObject = results.reduce((final: EscalationState["plansFilters"], plan) => {
        for (let i = 0; i < plan.pdUnitList.length; i += 1) {
          const filter = plan.pdUnitList[i];
          final[filter.pdUnitId] = {
            label: filter.pdUnitName,
            value: filter.pdUnitId,
          };
        }
        return final;
      }, {});
      return {
        ...state,
        plans: results,
        plansFilters: filtersObject,
      };
    }
    case "GET_LIVE_SYSTEM_ACTIONS": {
      const liveActionList = generateActionList(action.data.actionList as Action[]);
      // const liveActionList = groupBy(action.data.actionList as Action[], "labelName");
      // console.log({ liveActionList });
      return {
        ...state,
        liveActionList: liveActionList,
        escalatedPdUnitIds: action.data.escalatedPdUnitIds,
      };
    }
    case "GET_MY_LIVE_ACTIONS": {
      const myLiveActionList = generateActionList(action.data.results as Action[]);
      return {
        ...state,
        myLiveActionList: myLiveActionList,
      };
    }
    case "GET_ESCALATION_HISTORY": {
      const escalationHistory = (action.data.results as ActionHistory[]).reduce(
        (final: EscalationState["escalationHistory"], current: ActionHistory) => {
          const prevIndicator = final[current.indicatorId];
          const usersAssigned = [...(prevIndicator || { usersAssigned: [] }).usersAssigned, ...current.usersAssigned];
          final[current.indicatorId] = {
            indicatorId: current.indicatorId,
            indicatorName: current.indicatorName,
            actions: [...(prevIndicator || { actions: [] }).actions, current],
            usersAssigned: removeUserDuplicates(usersAssigned),
          };
          return final;
        },
        {}
      );
      return {
        ...state,
        escalationHistory: escalationHistory,
      };
    }
    case "UPDATE_ESCALATION_ACTIONS": {
      const newMyLiveActions = generateNewActions(action, state.myLiveActionList);
      const updatedMyLiveActionList: EscalationState["myLiveActionList"] = updateActions(
        action,
        state.myLiveActionList,
        newMyLiveActions.newActions,
        newMyLiveActions.addOrRemoveFromTotal
      );
      const newSystemLiveActions = generateNewActions(action, state.liveActionList);
      const updatedSystemLiveActionList: EscalationState["liveActionList"] = updateActions(
        action,
        state.liveActionList,
        newSystemLiveActions.newActions,
        newSystemLiveActions.addOrRemoveFromTotal
      );
      return {
        ...state,
        myLiveActionList: updatedMyLiveActionList,
        liveActionList: updatedSystemLiveActionList,
      };
    }
    case "DOWNLOAD_ESCALATION_PLAN_REPORT": {
      downloadFile(action.data, action.fileName + ".pdf");
      return state;
    }
    case "DOWNLOAD_ESCALATION_HISTORY_REPORT": {
      downloadFile(action.data, action.fileName + ".xlsx");
      return state;
    }
    case "SHOW_ESCALATION_BAR": {
      const mapping = (action.data.results as Action[]).reduce((final: { [labelId: string]: true }, current) => {
        final[current.labelId] = true;
        return final;
      }, {});
      // todo: update at the same time values?
      return {
        ...state,
        unitToLabelMap: {
          ...state.unitToLabelMap,
          [action.unitId]: Object.keys(mapping),
        },
      };
    }
    default:
      return state;
  }
};

const escalationBySiteId = (
  state: { [pdSiteId: string]: EscalationState } = { "-1": defaultState },
  action: any
): any => {
  switch (action.type) {
    case API_START:
    case API_ERROR:
    case API_END:
    case "GET_LIVE_SYSTEM_ACTIONS":
    case "GET_ESCALATION_PLANS":
    case "DOWNLOAD_ESCALATION_PLAN_REPORT":
    case "DOWNLOAD_ESCALATION_HISTORY_REPORT":
    case "GET_ESCALATION_HISTORY":
    case "GET_MY_LIVE_ACTIONS":
    case "UPDATE_ESCALATION_ACTIONS":
    case "SHOW_ESCALATION_BAR":
      if (!action.pdSiteId) {
        return state;
      }
      return {
        ...state,
        [action.pdSiteId]: escalation(state[action.pdSiteId], action),
      };
    case "GET_MENU": {
      // console.log(action.data["escalation-service"].escalationEnabledSystemDashboardList);
      return state;
    }
    default:
      return state;
  }
};

export default escalationBySiteId;
