import PowerBIService from "./powerbi.service";
// import { apiError, FAKE_AXIOS_ERROR } from "../api";
// import { cancelRequest } from "../../../helpers/cancel-request";
import i18n from "i18next";
// ? TYPES:
// import { ApiAction } from "../../types/api";
import { PowerBIEmbedTokenResponse } from "../../types/powerbi-state";
// import { PaginatedSort } from "../../../types/paginated-params";
import { ApplicationState } from "../../../store/reducers";

const getCurrentLang = () => i18n.language;

const getPowerBIReportEmbedData = (id: string, data: any, searchParams: { [key: string]: any } = {}) => ({
  type: "GET_POWERBI_REPORT_EMBED_DATA",
  id,
  searchParams,
  data,
});

export const fetchReportEmbedToken = (id: string, searchParams: { [key: string]: any } = {}) => {
  const callbackName = "callback" + Math.random().toString(36).substr(2, 9);
  return (dispatch: any, _getState: () => ApplicationState) => {
    // (window as any)[callbackName] = function (response: PowerBIEmbedTokenResponse) {
    //   if (response.EmbedUrl && !response.ErrorMessage) {
    //     dispatch(getPowerBIReportEmbedData(id, response, searchParams));
    //   }
    //   if (response.ErrorMessage) {
    //     const numbers = response.ErrorMessage.match(/\d+/g) || ["403"];
    //     const code = parseFloat(numbers[0]);
    //     dispatch(apiError("GET_POWERBI_REPORT_EMBED_DATA", response.ErrorMessage, FAKE_AXIOS_ERROR({ code }), { id }));
    //     // dispatch(getPowerBIReportEmbedDataError(id, response.ErrorMessage, searchParams));
    //   }
    //   (window as any)[callbackName] = undefined;
    // };
    // dispatch(
    //   PowerBIService.getReportEmbedToken({ id }, callbackName, {
    //     label: "GET_POWERBI_REPORT_EMBED_DATA",
    //     onSuccess: (response: PowerBIEmbedTokenResponse) => {
    //       if (response.EmbedUrl && !response.ErrorMessage) {
    //         return getPowerBIReportEmbedData(id, response, searchParams);
    //       }
    //       const numbers = (response.ErrorMessage || "403").match(/\d+/g) || ["403"];
    //       const code = parseFloat(numbers[0]);
    //       return apiError(
    //         "GET_POWERBI_REPORT_EMBED_DATA",
    //         response.ErrorMessage || "Unknown",
    //         FAKE_AXIOS_ERROR({ code }),
    //         { id }
    //       );
    //     },
    //     other: { id },
    //     // onFailure: () => console.log("Error occured loading articles"),
    //   })
    // );
    dispatch(
      PowerBIService.getReportEmbedToken({ id, lng: getCurrentLang() }, callbackName, {
        label: "GET_POWERBI_REPORT_EMBED_DATA",
        onSuccess: (response: PowerBIEmbedTokenResponse) => getPowerBIReportEmbedData(id, response, searchParams),
        other: { id },
        // onFailure: () => console.log("Error occured loading articles"),
      })
    );
  };
};

export const resetReportEmbedToken = (id: string, searchParams: { [key: string]: any } = {}) => ({
  type: "RESET_POWERBI_REPORT_EMBED_DATA",
  id,
  searchParams,
});
