import { getDeploymentConfig } from "../access-env";
import objWithDefault from "../helpers/generate-object-with-default-value";
// ? TYPES:
// import { DeploymentType } from "../types/deployment-type";
type Params = { [key: string]: string };

export const FOLDER_ID_DELIMINATOR = "~";

const GLOBAL_REPORT_IDS = {
  uk: {
    Discharges: "NationalBenchmarking-Discharge_16938205507360/Discharges",
    ED: "NationalBenchmarking-EDV2/ED",
    RTT: "NationalBenchmarking-RTT_16938206037420/RTTNHSTrusts",
    Ambulance: "AmbulanceHandover-NationalBenchmarking/AmbulanceHandovers",
    Cancer: "CancerWaitingTimes-NationalBenchmarking_17122231140200/Cancer",
    HealthIndex: "HealthIndex-NationalBenchmarking/NationalHealthIndex",
    // Cancer: "NationalBenchmarking-Cancer/Cancer",
    // ED: "NationalBenchmarking-ED/ED",
    // Maternity: "NationalBenchmarking-Maternity/Maternity",
    // NationalHealthIndex: "NationalBenchmarking-HealthIndex/NationalHealthIndex",
    // RTT: "NationalBenchmarking-RTT/RTT",
  },
  ca: {},
};

export const TABLEAU_GLOBAL_REPORT_ID: { [key: string]: string } = getDeploymentConfig(GLOBAL_REPORT_IDS);

const REPORT_IDS = {
  uk: {
    ...GLOBAL_REPORT_IDS.uk,
    // JointResponseUnit: "CommunityInterventionsDashboard/JointResponseUnit",
    // UrgentResponseUnit: "CommunityInterventionsDashboard/UrgentResponseUnit",
    // SECAMB: "CommunityInterventionsDashboard/SECAMB",
    // OPELICBDashboard: "KentMedwayOPELSummaryReport_16806177736000/OPELICBDashboard",
    // Scorecard: "MedwayScorecardDashboard/Scorecard",
    // History: "MedwayScorecardDashboard/History",
    // Variation: "MedwayScorecardDashboard/Variation",
    // Correlation: "MedwayScorecardDashboard/Correlation",
    // OrganisationComparison: "MedwayScorecardDashboard/OrganisationComparison",
    // DailySystemOverviewDashboard: "MedwaySystemOverviewDashboard/DailySystemOverviewDashboard",
    // WeeklySystemOverviewDashboard: "MedwaySystemOverviewDashboard/WeeklySystemOverviewDashboard",
    // "SHREWDDailyOPELICBDashboard-SouthEast":
    //   "OPELRegionalSummaryReportICB-SouthEast_16819821752100/SHREWDDailyOPELICBDashboard-SouthEast",
  },
  ca: {
    ...GLOBAL_REPORT_IDS.ca,
    // Dashboard1: "MVPTestReport/Dashboard1",
  },
};

export const TABLEAU_REPORT_ID: { [key: string]: string } = objWithDefault(getDeploymentConfig(REPORT_IDS), {
  transformDefaultProperty: (key: string) => key.replace(FOLDER_ID_DELIMINATOR, "/"),
});

const FILTERS: { [tid: string]: (params: Params, searchParams: Params) => { [key: string]: any } } = {
  [TABLEAU_REPORT_ID.Dashboard1]: (_params, _searchParams) => {
    return {
      // Colors: ["Blue", "Red"],
    };
  },
};

const PARAMETERS: { [tid: string]: (params: Params, searchParams: Params) => { [key: string]: any } } = {
  [TABLEAU_REPORT_ID.Dashboard1]: (_params, _searchParams) => {
    return {
      // Param1: "Value",
    };
  },
};

export const TABLEAU_FILTERS = objWithDefault(FILTERS, { defaultValue: () => ({}) });

export const TABLEAU_PARAMETERS = objWithDefault(PARAMETERS, { defaultValue: () => ({}) });
