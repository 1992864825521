import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyLiveActions } from "../../store/actions/escalation";
import { InlineError } from "@tscore/react-components";
import { momentDateFormat, formatToString } from "../../lang/DateTimeFormats";
import { useTranslation } from "react-i18next";
// ? TYPES:
import { ApplicationState } from "../../store/reducers";
import { Action } from "../../store/types/escalation-state";
import { ApiError } from "../../store/types/api";
type ActionsSummaryProps = any;

const GET_DATA_ACTION_NAME = "GET_MY_LIVE_ACTIONS";
const ACTIONS_TO_DISPLAY = 10;

const EmptyActionRow = ({ widths }: { widths: [number, number] }) => (
  <ul className="row isFetching">
    <li className="cell">
      <div className="loading-element" style={{ width: `${widths[0]}%` }}>
        ...
      </div>
      <div className="meta loading-element" style={{ width: `${widths[1]}%` }}>
        ...
      </div>
    </li>
  </ul>
);

const ActionsSummaryList: React.FC<{
  isFetching?: boolean;
  error?: ApiError;
  actions: Action[];
}> = ({ isFetching, error, actions }) => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(ACTIONS_TO_DISPLAY);
  const showMore = (e: any) => {
    setLimit((prevState) => prevState + ACTIONS_TO_DISPLAY);
    e.preventDefault();
  };
  if (isFetching && actions.length === 0) {
    return (
      <>
        <EmptyActionRow widths={[44, 64]} />
        <EmptyActionRow widths={[49, 41]} />
      </>
    );
  }
  if (error) {
    return (
      <InlineError
        title={t("errors:connection")}
        description={t("home:errorActionsConnectionDescription")}
        className="middle"
      />
    );
  }
  if (actions.length === 0) {
    return (
      <InlineError
        title={t("home:errorNoActionsTitle")}
        description={t("home:errorNoActionsDescription")}
        className="middle"
      />
    );
  }
  return (
    <>
      {actions.slice(0, limit).map((action) => (
        <ul className="row" key={action.id}>
          <li className="cell">
            <div>{action.description}</div>
            <div className="meta">
              {t("home:Escalation Action")} | {formatToString(action.updatedAt, momentDateFormat)} | {t("home:Status")}:{" "}
              {t("escalation:statuses." + action.status)}
            </div>
          </li>
        </ul>
      ))}
      {actions.length > limit && (
        <ul className="row">
          <li className="cell showmore-cell">
            <a href="#!show-more" onClick={showMore}>
              {t("home:Show more")}
            </a>
          </li>
        </ul>
      )}
    </>
  );
};

export const ActionsSummary: React.FC<ActionsSummaryProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchMyLiveActions("all"));
    }
    fetchInitial();
    // document.getElementById("template")!.innerHTML = "Loading";
  }, [dispatch]);
  const state = useSelector((state: ApplicationState) => state.escalationReducer["all"] || state.escalationReducer[-1]);
  const { isFetching, myLiveActionList, errors } = state;
  const actions = Object.values(myLiveActionList).reduce((final: Action[], current) => {
    const actionsViaIndicator = Object.values(current.indicators).map((indicator) => indicator.actions);
    const merged = ([] as Action[]).concat(...actionsViaIndicator);
    return [...final, ...merged];
  }, []);
  return (
    <div className="table main no-vertical-lines independent-cells shadowed bordered">
      <header className="thead">
        <ul className="row">
          <li className="th nowrap">
            {t("home:My Actions")} (<span id="actionCount">{actions.length || "-"}</span>)
          </li>
          {/* <li className="th nowrap ta-right">
            <a href="#!">View All</a>
          </li> */}
        </ul>
      </header>
      <div className="tbody" id="actionContent">
        <ActionsSummaryList
          isFetching={isFetching[GET_DATA_ACTION_NAME]}
          error={errors[GET_DATA_ACTION_NAME]}
          actions={actions}
        />
      </div>
    </div>
  );
};
