// ? TYPES:
import { TFunction } from "i18next";

const ALL_STATUSES = ["Not Responded", "Acknowledged", "In Progress", "Completed", "Disregarded"];
export const LIVE_ACTION_STATUSES = ALL_STATUSES;
export const MY_LIVE_ACTION_STATUSES = ["Not Responded", "Acknowledged", "In Progress"];

export const GENERATE_STATUSES = (t: TFunction): { value: string; label: string }[] =>
  ALL_STATUSES.map((status) => ({
    value: status,
    label: t("escalation:statuses." + status, { defaultValue: status }),
  }));

// export const STATUSES: { value: string; label: string }[] = [
//   { value: "Not Responded", label: "Not Responded" },
//   { value: "Acknowledged", label: "Acknowledged" },
//   { value: "In Progress", label: "In Progress" },
//   { value: "Completed", label: "Completed" },
//   { value: "Disregarded", label: "Disregarded" },
// ];
