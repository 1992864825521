import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";

// import { fetchUploadDocument, updateDocumentUploadProgress } from "../../store/actions/documents";

import { Icon, Button, Tippy, ProgressBar } from "@tscore/react-components";
import { getFileIconSrc } from "../../globals/FileIcons";
import { formatBytes } from "../../helpers/format-bytes";

import classNames from "classnames";
import { useTranslation, Trans } from "react-i18next";
// ? TYPES:
// import { ApplicationState } from "../../store/reducers";
import { DocumentsState } from "../../store/types/documents-state";

interface ExtendedFile extends File {
  lastModifiedDate: Date;
  path: string;
  size: number;
  type: string;
  preview: string;
}

// const FileThumb: React.FC<{ preview: string; extension?: string }> = ({ preview, extension }) => {
//   return <img src={preview} alt={extension} />;
// };

const FileItem: React.FC<{
  file: ExtendedFile;
  percentage: number;
  error: string | undefined;
  onDelete: () => void;
}> = ({ file, percentage, error, onDelete }) => {
  const { t } = useTranslation();
  const extension = file.name.split(".").pop();
  const size = formatBytes(file.size);
  // console.log(error);
  return (
    <li className={classNames("document", { success: percentage === 100, error: typeof error === "string" })}>
      <div className="file-type">
        {/* <FileThumb preview={file.preview} extension={extension} /> */}
        <img src={getFileIconSrc(extension as string)} alt={extension} />
      </div>
      <div>
        <h6>{file.name}</h6>
        <ProgressBar
          colour="#41a0f2"
          scale="small"
          percentage={percentage}
          greenOnFinished
          type={error ? "error" : undefined}
        />
        <div className={classNames("meta", { "form-error is-visible": !!error })}>
          {error ? error : percentage < 100 ? t("documents:percentageUploaded", { percentage, size }) : size}
        </div>
      </div>
      <div>
        {percentage < 100 ? (
          <Tippy content={t("generic:Delete")} placement="bottom">
            <span>
              <Icon onClick={onDelete} className="delete-icon faded">
                delete
              </Icon>
            </span>
          </Tippy>
        ) : (
          <Icon className="color-green">check</Icon>
        )}
      </div>
    </li>
  );
};

interface ModalUploadProps {
  id: number;
  uploadProgress: DocumentsState["uploadProgress"];
  uploadErrors: DocumentsState["uploadErrors"];
  fetchUploadDocument: Function;
  updateDocumentUploadProgress: Function;
}

export const ModalUpload: React.FC<ModalUploadProps> = ({
  id,
  uploadErrors,
  uploadProgress,
  fetchUploadDocument,
  updateDocumentUploadProgress,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<ExtendedFile[]>([]);
  const [queue, setQueue] = useState<ExtendedFile[]>([]);
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles) => {
      // const newFiles = acceptedFiles.map((file: ExtendedFile) =>
      //   Object.assign(file, {
      //     preview: URL.createObjectURL(file),
      //   })
      // );
      const newFiles = [...acceptedFiles].filter((af) => {
        const foundFile = files.find((file) => af.name === file.name && af.lastModified === file.lastModified);
        if (foundFile) {
          return false;
        }
        return true;
      });
      for (let i = 0; i < acceptedFiles.length; i += 1) {
        if (uploadProgress[acceptedFiles[i].name + acceptedFiles[i].lastModified] <= 1) {
          dispatch(updateDocumentUploadProgress(id, acceptedFiles[i].name + acceptedFiles[i].lastModified));
        }
      }
      setFiles([...newFiles, ...files]);
    },
    [files, uploadProgress, dispatch, id, updateDocumentUploadProgress]
  );
  const { getRootProps, getInputProps, isDragReject, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    // accept: "image/jpeg, image/png",
  });
  useEffect(() => {
    const q = [...files].filter((file) => {
      if (uploadProgress[file.name + file.lastModified] >= 1) {
        return false;
      }
      if (uploadErrors[file.name]) {
        return false;
      }
      return true;
    });
    setQueue(q);
  }, [files, uploadProgress, dispatch, id, uploadErrors]);
  // useEffect(
  //   () => () => {
  //     // Make sure to revoke the data uris to avoid memory leaks
  //     files.forEach((file) => URL.revokeObjectURL(file.preview));
  //   },
  //   [files]
  // );
  const ON_FILE_OVER_CLASS = "on-file-over";
  useEffect(() => {
    document.documentElement.classList.add(ON_FILE_OVER_CLASS);
    if (isDragActive) {
      document.documentElement.classList.add(ON_FILE_OVER_CLASS + "-visible");
    } else {
      document.documentElement.classList.remove(ON_FILE_OVER_CLASS + "-visible");
    }
    return () => {
      document.documentElement.classList.remove(ON_FILE_OVER_CLASS);
    };
  }, [isDragActive]);
  const onSubmit = () => {
    for (let i = 0; i < queue.length; i += 1) {
      dispatch(fetchUploadDocument(id, queue[i]));
    }
  };
  const onDelete = (i: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(i, 1);
    setFiles(updatedFiles);
  };

  return (
    <div>
      <div
        {...getRootProps({
          style: {
            position: "fixed",
            zIndex: -1,
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "100vw",
            height: "100vh",
            outline: "none",
          },
          onClick: (event) => event.stopPropagation(),
        })}
      />
      <header>
        <h4>{t("documents:Upload Documents")}</h4>
        {/* <h6>xx</h6> */}
      </header>
      <article>
        <div
          {...getRootProps({
            style: { outline: "none", opacity: isDragReject ? 0.3 : 1 },
            className: classNames("drop-area document-drop", { "drag-active": isDragActive }),
          })}>
          <input {...getInputProps()} />
          <div>
            <div>
              <Icon>cloud_upload</Icon>
            </div>
            <Trans
              i18nKey="documents:dragAndDropOrBrowse"
              components={[
                <a key={0} href="#!" onClick={(e) => e.preventDefault()}>
                  browse
                </a>,
              ]}></Trans>
          </div>
        </div>
        <ul className="uploaded-documents">
          {files.map((file: any, i) => {
            return (
              <FileItem
                onDelete={() => onDelete(i)}
                file={file}
                percentage={uploadProgress[file.name + file.lastModified] || 0}
                error={uploadErrors[file.name]}
                key={file.path + file.lastModified}
              />
            );
          })}
        </ul>
      </article>
      <footer className="ta-right">
        <Button colour="link" data-close-modal>
          {t("generic:Cancel")}
        </Button>
        <Button disabled={queue.length === 0} onClick={() => onSubmit()}>
          {queue.length === 0
            ? t("documents:confirmUpload_zero")
            : t("documents:confirmUpload", { count: queue.length })}
        </Button>
      </footer>
    </div>
  );
};
