import AlertsService from "./alerts.service";
import { cancelRequest } from "../../../helpers/cancel-request";
// ? TYPES:
import { ApiAction, PaginatedResponse } from "../../types/api";
import {
  NhsrAlert,
  EscalationAlertUpdate,
  EscalationAlert,
  WeeklyPatternResponse,
  WeeklyPattern,
} from "../../types/alerts-state";
import { ApplicationState } from "../../reducers";

// import { PaginatedSort } from "../../../types/paginated-params";
// import { ApplicationState } from "../../../store/reducers";

const getEscalationAlerts = (page: number, data: PaginatedResponse<EscalationAlert>) => ({
  type: "GET_ESCALATION_ALERTS",
  page,
  data,
});

const updateEscalationAlerts = (data: EscalationAlertUpdate[]) => ({
  type: "UPDATE_ESCALATION_ALERTS",
  data,
});

const getNhsrAlerts = (page: number, data: PaginatedResponse<NhsrAlert>) => ({
  type: "GET_NHSR_ALERTS",
  page,
  data,
});

const updateNhsrAlerts = (data: NhsrAlert[]) => ({
  type: "UPDATE_NHSR_ALERTS",
  data,
});

const getWeeklyPattern = (data: WeeklyPatternResponse) => ({
  type: "GET_WEEKLY_PATTERN",
  data,
});

const updateWeeklyPattern = (data: WeeklyPatternResponse) => ({
  type: "UPDATE_WEEKLY_PATTERN",
  data,
});

export const fetchEscalationAlerts = (page = 1, query?: string): ApiAction => {
  cancelRequest("GET_ESCALATION_ALERTS");
  return AlertsService.getEscalationAlerts(
    {
      page,
      indicatorSearchParam: query,
    },
    {
      label: "GET_ESCALATION_ALERTS",
      onSuccess: (response: PaginatedResponse<EscalationAlert>) => getEscalationAlerts(page, response),
      // onFailure: () => console.log("Error occured loading articles"),
    }
  );
};

// export const fetchUpdateEscalationAlerts = (values: EscalationAlertUpdate[]): ApiAction => {
//   return AlertsService.updateEscalationAlertsBatch(
//     { values },
//     {
//       label: "UPDATE_ESCALATION_ALERTS",
//       onSuccess: updateEscalationAlerts,
//       other: { values },
//     }
//   );
// };

export const fetchUpdateEscalationAlerts = (values: EscalationAlertUpdate[]) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const allPreviousValuesForSuccessToast = getState().alertsReducer.escalationAlerts;
    dispatch(
      AlertsService.updateEscalationAlertsBatch(
        { values },
        {
          label: "UPDATE_ESCALATION_ALERTS",
          onSuccess: updateEscalationAlerts,
          other: { values, allPreviousValues: allPreviousValuesForSuccessToast },
        }
      )
    );
  };
};

export const fetchNhsrAlerts = (page = 1): ApiAction => {
  cancelRequest("GET_NHSR_ALERTS");
  return AlertsService.getNhsrAlerts(
    {
      page,
    },
    {
      label: "GET_NHSR_ALERTS",
      onSuccess: (response: PaginatedResponse<NhsrAlert>) => getNhsrAlerts(page, response),
      // onFailure: () => console.log("Error occured loading articles"),
    }
  );
};

export const fetchUpdateNhsrAlerts = (values: NhsrAlert[]): ApiAction => {
  return AlertsService.updateNhsrAlertsBatch(
    { values },
    {
      label: "UPDATE_NHSR_ALERTS",
      onSuccess: (_response: boolean) => updateNhsrAlerts(values),
      other: { values },
    }
  );
};

export const fetchWeeklyPattern = (): ApiAction => {
  cancelRequest("GET_WEEKLY_PATTERN");
  return AlertsService.getWeeklyPattern({
    label: "GET_WEEKLY_PATTERN",
    onSuccess: getWeeklyPattern,
  });
};

export const fetchUpdateWeeklyPattern = (values: WeeklyPattern<undefined | string>[]): ApiAction => {
  cancelRequest("UPDATE_WEEKLY_PATTERN");
  return AlertsService.updateWeeklyPattern(
    { values },
    {
      label: "UPDATE_WEEKLY_PATTERN",
      onSuccess: updateWeeklyPattern,
    }
  );
};
