import React, { useState, useEffect, useMemo } from "react";
import { Input } from "@tscore/react-components";
import { withTranslation } from "react-i18next";
import { DEFAULT_DEBOUNCE_SEARCH_MS } from "../globals/settings";
import debounce from "lodash/debounce";
import { searchUpdateQuery } from "../helpers/params";
import { useHistory, useLocation } from "react-router";
// ? TYPES:
import { TFunction } from "i18next";

type InputProps = React.ComponentProps<typeof Input>;

interface SearchInputProps extends InputProps {
  t: TFunction;
  query: string | undefined;
  isFetching: boolean | undefined;
}

const SearchInputWoT: React.FC<SearchInputProps> = ({
  t,
  query,
  isFetching,
  autoComplete = "main_search",
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();
  const [inputValue, setInputValue] = useState(query || "");
  useEffect(() => {
    setInputValue(query || "");
  }, [query]);
  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        searchUpdateQuery(value, history, location);
      }, DEFAULT_DEBOUNCE_SEARCH_MS),
    [history, location]
  );
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  const handleChange: InputProps["onChange"] = (e) => {
    const newValue = e.currentTarget.value;
    setInputValue(newValue);
    debouncedSearch(newValue);
  };

  const handleClear = () => {
    setInputValue("");
    debouncedSearch.cancel();
    searchUpdateQuery("", history, location);
  };

  const handleKeyDown: InputProps["onKeyDown"] = (e) => {
    if (e.key === "Escape") {
      handleClear();
    }
  };
  return (
    <Input
      icon="search"
      placeholder={t("generic:Search")}
      id={`search-input-${autoComplete}`}
      autoComplete={autoComplete}
      onChange={handleChange}
      onClear={handleClear}
      onKeyDown={handleKeyDown}
      holderStyle={{ minWidth: "14vw" }}
      value={inputValue}
      isLoading={!!query && isFetching}
      {...rest}
    />
  );
};

export const SearchInput = withTranslation(["generic"])(SearchInputWoT);
