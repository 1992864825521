import { saveAs } from "file-saver";

// function s2ab(s: string) {
//   const buf = new ArrayBuffer(s.length);
//   const view = new Uint8Array(buf);
//   for (let i = 0; i !== s.length; ++i) {
//     view[i] = s.charCodeAt(i) & 0xff;
//   }
//   return buf;
// }

export function getFileName(disposition: string): string {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/;
  const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/;

  // let fileName: string = null;
  if (utf8FilenameRegex.test(disposition)) {
    return decodeURIComponent(utf8FilenameRegex.exec(disposition)![1]);
  } else {
    const matches = asciiFilenameRegex.exec(disposition);
    if (matches != null && matches[2]) {
      return matches[2];
    }
  }
  return "undefined";
}

export function dataURItoAb(dataURI: string): ArrayBuffer {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  // const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return ab;
}

export const downloadFile = (
  data: string | ArrayBuffer,
  fileNameWithExtension: string,
  type = "application/octet-stream"
) => {
  try {
    // eslint-disable-next-line
    const isFileSaverSupported = !!new Blob();
    saveAs(new Blob([data], { type: type }), fileNameWithExtension);
  } catch (e) {
    // eslint-disable-next-line
    console.log("download not supported");
  }
};

export const downloadFileFromUrl = (
  url: string,
  fileName: string,
  mime: string | undefined = "application/octet-stream",
  event: any | null = null
): void => {
  const nameAndExtension = fileName.split(/\.(?=[^.]+$)/);
  const name = nameAndExtension[0].replace(/[^a-z0-9.]/gi, "-");
  const extension = nameAndExtension[1] ? nameAndExtension[1] : "unknown-extension";
  // if (browser && browser.type === "ie") {
  const x = new XMLHttpRequest();
  x.open("GET", url, true);
  x.responseType = "blob";
  x.onload = (_e: any) => {
    downloadFile(x.response, name + "." + extension, mime);
  };
  x.send();
  // } else {
  // var t = $('<a href="' + url + '" download="' + name + '" style="display:none;">.</a>');
  // $("#link-trash").append(t);
  // t[0].click();
  // }
  event && event.preventDefault();
};
