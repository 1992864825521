import * as yup from "yup";
// ? TYPES:
import { TFunction } from "i18next";

export const profileSchema = (t: TFunction) =>
  yup
    .object()
    .required()
    .shape({
      email: yup.string().required(),
      firstname: yup.string().required().label(t("myAccount:firstname")),
      lastUpdatedDateTime: yup.string(), // ignored, iso
      userPhoneCountryCodeList: yup.array(), // ignored
      phoneCountryCode: yup
        .object()
        .shape({
          code: yup.string(),
          country: yup.string(),
        })
        .nullable()
        .label(t("myAccount:phoneCountryCode")),
      mobile: yup.string().nullable().label(t("myAccount:mobile")),
      primaryOrganisation: yup.object().nullable(), // todo
      surname: yup.string().required().label(t("myAccount:surname")),
      username: yup.string().required(),
    });

export type ProfileDetails = yup.InferType<ReturnType<typeof profileSchema>>;

export const newPasswordSchema = (t: TFunction) =>
  yup
    .object()
    .required()
    .shape({
      currentPassword: yup.string().required().label(t("myAccount:currentPassword")),
      newPassword: yup.string().required().label(t("myAccount:newPassword")),
      confirmPassword: yup.mixed().test("match", t("yupErrors:custom.Passwords do not match"), function (c) {
        // console.log(this, c);
        return c === (this as any).parent.newPassword;
      }),
    });

export type NewPassword = yup.InferType<ReturnType<typeof newPasswordSchema>>;
