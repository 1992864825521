import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tippy } from "@tscore/react-components";
import { showThresholdValue } from "../helpers/show-threshold-value";
import { formatToLocalString, getLocalDateStr } from "../lang/date-fns";
// ? TYPES:
import { IndicatorExtendedSmart } from "../types/indicator";

interface IndicatorAdvancedTriggersProps {
  thresholdDetails: IndicatorExtendedSmart["thresholdDetailsSmart"];
  toggleAdvancedTrigger: (e?: React.MouseEvent) => void;
  // dimensions?: Partial<DOMRect>;
  triggerElemRef: React.MutableRefObject<HTMLDivElement>;
}

export const IndicatorAdvancedTriggers: React.FC<IndicatorAdvancedTriggersProps> = ({
  thresholdDetails,
  toggleAdvancedTrigger,
  triggerElemRef,
}) => {
  const { t } = useTranslation();
  const advancedElemRef = useRef<HTMLDivElement>(undefined!);
  const todayDate = getLocalDateStr();
  const style = useMemo(() => {
    if (triggerElemRef.current) {
      const dimensions = triggerElemRef.current.getBoundingClientRect();
      const height = dimensions.height || 0;
      const top = dimensions.top || 0;
      const offset = 16;
      const maxHeight = top + height - offset;
      if (!advancedElemRef.current) {
        return { maxHeight: `${maxHeight}px` };
      }
      if (advancedElemRef.current.offsetHeight > maxHeight - 2) {
        return { maxHeight: "100%" };
      }
      return { bottom: 0, minHeight: height };
    }
    return {};
  }, [triggerElemRef.current, advancedElemRef.current]);
  return (
    <div
      ref={advancedElemRef}
      className="trigger-details__advanced"
      style={style}
      data-position={style.bottom === 0 ? "bottom" : "top"}>
      {Object.entries(thresholdDetails).map(([triggerDate, { showThresholdDetails, thresholdGroups }]) => {
        if (!showThresholdDetails) {
          return null;
        }
        const firstItem = thresholdGroups[0] || { thresholds: [] };
        const headings = Array.from({ length: firstItem.thresholds.length });
        const date = formatToLocalString(triggerDate, "dd LLL yyyy");
        return (
          <div key={triggerDate}>
            <header className="trigger-details__header">
              <div>
                <h4>{t("dashboards:All Trigger Details")}</h4>
                {todayDate === triggerDate ? (
                  <p>
                    {t("dashboards:dateToday")} ({date})
                  </p>
                ) : (
                  <p>{date}</p>
                )}
              </div>
              <div style={{ alignSelf: "flex-start" }}>
                <Tippy offset={[0, -2]} content={t("dashboards:tooltipCloseAllTriggerDetails")} placement="bottom">
                  <a className="trigger-details__advanced__hide" href="#!toggle" onClick={toggleAdvancedTrigger}>
                    <i className="material-icons">menu_open</i>
                  </a>
                </Tippy>
              </div>
            </header>
            <article>
              <table className="table bordered no-vertical-lines main">
                <thead>
                  <tr>
                    <th className="w1 nowrap ta-left">{t("dashboards:allTriggerDetailsHeadings.0")}</th>
                    {headings.map((_, i) => (
                      <th className="trigger-details__advanced__cell" key={i}>
                        <div>
                          <span>{t("dashboards:allTriggerDetailsHeadings.1")}</span>
                          <span>{t("dashboards:allTriggerDetailsHeadings.2")}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {thresholdGroups.map((tg) => (
                    <tr key={tg.startTime}>
                      <td className="w1 nowrap">
                        {tg.startTime} - {tg.endTime}
                      </td>
                      {tg.thresholds.map((thr, i) => {
                        const c = `brag brag-${thr.colorName?.toLowerCase()}`;
                        return (
                          <td className="trigger-details__advanced__cell" key={i}>
                            <div>
                              <span className={c}>{showThresholdValue(thr.beginValue)}</span>
                              <span className={c}>{showThresholdValue(thr.endValue, true)}</span>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </article>
          </div>
        );
      })}
    </div>
  );
};
