import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultApiLoading } from "../default-api-loading";
// import { defaultApiSuccess } from "../default-api-success";
import { findPhoneCode } from "../../utils/find-phone-code";
import { createPriorityPhoneList, DEFAULT_PHONE_CODE_LIST } from "../../utils/create-priority-phone-list";
import { mapValuesInObject } from "../../../helpers/map-values-in-object";
// ? TYPES:
import { MyAccountState } from "../../types/my-account-state";
import { PhoneCountryItem } from "../../../types/phone-code";

const defaultState: MyAccountState = {
  details: {
    email: "",
    firstname: "",
    mobile: "",
    phoneCountryCode: null,
    userPhoneCountryCodeList: [],
    primaryOrganisation: null,
    surname: "",
    username: "",
  },
  phoneCodeList: DEFAULT_PHONE_CODE_LIST,
  organisationList: [],
  success: {},
  isFetching: {},
  errors: {},
};

const myAccountReducer = (state: MyAccountState = defaultState, action: any): MyAccountState => {
  switch (action.type) {
    case API_START: {
      return defaultApiLoading(state, action, true, ["GET_PROFILE"]);
    }
    case API_ERROR: {
      return defaultApiError(state, action);
    }
    case API_END: {
      return defaultApiLoading(state, action, false, ["GET_PROFILE"]);
    }
    case "CLEAR_MY_ACCOUNT_ERRORS": {
      return {
        ...state,
        errors: {},
      };
    }
    case "GET_PROFILE": {
      const DONT_CHANGE_TO_EMPTY = ["primaryOrganisation", "phoneCountryCode"];
      const originalPhoneCountryCodeList: PhoneCountryItem[] = action.data.userPhoneCountryCodeList;
      const phoneCodeList = createPriorityPhoneList(originalPhoneCountryCodeList);
      return {
        ...state,
        phoneCodeList,
        details: mapValuesInObject(
          {
            ...action.data,
            phoneCountryCode: findPhoneCode(originalPhoneCountryCodeList, action.data.phoneCountryCode, "code"),
          },
          DONT_CHANGE_TO_EMPTY
        ),
      };
    }
    case "GET_ORGANISATION_LIST": {
      return {
        ...state,
        organisationList: action.data.results,
      };
    }
    case "UPDATE_PROFILE": {
      const originalPhoneCountryCodeList = state.details.userPhoneCountryCodeList as PhoneCountryItem[];
      const { userPhoneCountryCodeList: _ignore1, phoneCountryCode: _ignore2, ...newData } = action.data;
      return {
        ...state,
        details: {
          ...state.details,
          ...newData,
          phoneCountryCode: findPhoneCode(originalPhoneCountryCodeList, action.data.phoneCountryCode, "code"),
        },
      };
    }
    case "UPDATE_PASSWORD": {
      return {
        ...state,
        success: {
          ...state.success,
          UPDATE_PASSWORD: new Date().toISOString(),
        },
      };
    }
    default:
      return state;
  }
};

export default myAccountReducer;
