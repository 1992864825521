// import { CONTACT_LINK } from "../../../globals/CompanyMeta";

export const PLATFORMS = {
  Android: {
    icon: "android",
    name: "Android",
  },
  iOS: {
    icon: "cloud",
    name: "iOS",
  },
  Desktop: {
    icon: "laptop",
    name: "Desktop",
  },
};

// export const CONTACT_SALES_URL = CONTACT_LINK;
export const SIDEBAR_DIM_CLASS = "sidebar-dim";
export const SCALE_LEVEL = 1.2;
