import { PureComponent } from "react";
import debounce from "lodash/debounce";

// ! TODO; should be rewritten
let efficientScrollCheck: EventListener | EventListenerObject; // = null;

const HEADER_BAR_HEIGHT = 72;
const HEADER_HEIGHT = HEADER_BAR_HEIGHT + 72; //72;

const START_SHOWING_HEADER_AT = HEADER_HEIGHT * 0.77;

enum Direction {
  Up = "UP",
  Down = "DOWN",
  Left = "LEFT",
  Right = "RIGHT",
}

const getTranslate = (value: number | string, isMinus = false, type: "Y" | "X" = "Y"): string => {
  // var isMinus = arguments[1] ? arguments[1] : false;
  const plusMinus = isMinus ? "-" : "";
  return "translate" + type + "(" + plusMinus + value + "px)";
};

export class ScrollEvents extends PureComponent {
  state = {
    lastScrollTop: 0,
    isTitleInHeader: false,
    runStickyHeader: false,
    lastScrollLeft: 0,
  };

  componentWillUnmount() {
    document.removeEventListener("scroll", efficientScrollCheck, false);
  }

  componentDidMount() {
    //TODO: Don't run for IE
    this.setState({
      runStickyHeader: (" " + document.body.className + " ").indexOf(" sticky ") > -1 ? true : false,
    });
    efficientScrollCheck = debounce(() => {
      this.scrollDirectionOffset();
    }, 0);
    document.addEventListener("scroll", efficientScrollCheck, false);
    window.scrollTo(0, 0);
  }

  scrollDirectionOffset = () => {
    const st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    const sl = window.pageXOffset || document.documentElement.scrollLeft;
    // const isAtBottom = st + window.innerHeight === document.getElementById("container").offsetHeight ? true : false;
    if (st > this.state.lastScrollTop) {
      this.scroll(st, Direction.Down);
    } else {
      //TODO: maybe check if different?
      this.scroll(st, Direction.Up);
    }
    if (sl > this.state.lastScrollLeft) {
      this.scrollHorizontal(sl, Direction.Right);
    } else if (sl !== this.state.lastScrollLeft) {
      this.scrollHorizontal(sl, Direction.Left);
    }
    this.setState({
      lastScrollTop: st <= 0 ? 0 : st,
      lastScrollLeft: sl <= 0 ? 0 : sl,
    });
  };

  scroll = (offset: number, direction: Direction) => {
    if (this.state.runStickyHeader) {
      this.handleHeader(offset, direction);
    }
  };

  scrollHorizontal = (offset: number, direction: Direction) => {
    if (this.state.runStickyHeader) {
      this.handleTitle(offset, direction);
    }
  };

  handleTitle = (offset: number, direction: Direction): boolean => {
    const titleEl = document.getElementById("title");
    const sidebarEl = document.getElementById("sidebar");
    if (!titleEl || !sidebarEl) {
      return false;
    }
    if (!this.state.isTitleInHeader) {
      titleEl.style.transform = getTranslate(0, false, "X");
      return false;
    }
    const SIDEBAR_WIDTH = sidebarEl.offsetWidth;
    if (direction === "RIGHT" && offset > 0) {
      const marginVal = offset >= SIDEBAR_WIDTH ? SIDEBAR_WIDTH : offset;
      titleEl.style.transform = getTranslate(marginVal, true, "X");
      return false;
    }
    if (direction === "LEFT" && offset <= SIDEBAR_WIDTH) {
      const marginVal = offset > 0 ? offset : 0;
      titleEl.style.transform = getTranslate(marginVal, true, "X");
      return false;
    }
    return false;
  };

  handleHeader = (offset: number, direction: Direction) => {
    const headerEl = document.getElementById("header");
    if (!headerEl) {
      return false;
    }
    if (!this.state.isTitleInHeader && direction === "DOWN" && offset >= START_SHOWING_HEADER_AT) {
      document.body.classList.add("title-hidden");
      setTimeout(() => {
        document.body.classList.add("title-in-header");
      }, 70);
      this.setState({ isTitleInHeader: true });
    }
    if (this.state.isTitleInHeader && direction === "UP" && offset <= START_SHOWING_HEADER_AT) {
      document.body.classList.remove("title-hidden");
      this.setState({ isTitleInHeader: false });
      setTimeout(() => {
        document.body.classList.remove("title-in-header");
      }, 120);
    }
  };

  render() {
    return null;
  }
}
