import { SERVICE_URLS } from "../../../globals/service-urls";

export const LOG_OUT_URL = `${SERVICE_URLS["auth-service"]}/oauth/logout`;
export const LOG_IN_URL = `${SERVICE_URLS["auth-service"]}/oauth/token?meta=login`;
export const CHECK_TOKEN_URL = `${SERVICE_URLS["auth-service"]}/oauth/check_token`;
export const REFRESH_SUFFIX = "meta=refresh";
export const PRELOG_IN_URL = `${SERVICE_URLS["auth-service"]}/mfa/pre-login`;
export const REGISTER_TOTP_URL = `${SERVICE_URLS["auth-service"]}/mfa/register-totp`;
export const REGISTER_SMS_URL = `${SERVICE_URLS["auth-service"]}/mfa/register-sms`;
export const REGISTER_EMAIL_URL = `${SERVICE_URLS["auth-service"]}/mfa/register-email`;
export const VERIFY_TOTP_URL = `${SERVICE_URLS["auth-service"]}/mfa/totp-auth`;
export const VERIFY_SMS_URL = `${SERVICE_URLS["auth-service"]}/mfa/sms-auth`;
export const VERIFY_EMAIL_URL = `${SERVICE_URLS["auth-service"]}/mfa/email-auth`;

export const AUTHENTICATION_401_IGNORE = [
  LOG_OUT_URL,
  LOG_IN_URL,
  PRELOG_IN_URL,
  VERIFY_TOTP_URL,
  VERIFY_SMS_URL,
  VERIFY_EMAIL_URL,
  REGISTER_TOTP_URL,
  REGISTER_SMS_URL,
  REGISTER_EMAIL_URL,
];
