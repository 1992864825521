import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
// ? TYPES:
import { TFunction } from "i18next";
import { MFAType } from "../../../../store/types/credentials-state";

interface BackupMethodProps {
  mfaType: MFAType;
  lowercase?: boolean;
  t: TFunction;
}

const BackupMethodWoT: React.FC<BackupMethodProps> = ({ t, mfaType, lowercase }) => {
  return (
    <span>
      {lowercase && t("mfa.general.resendCodeTryAnotherWayDelimiter")}
      <NavLink style={{ textTransform: lowercase ? "lowercase" : undefined }} to={{ search: `?type=${mfaType}` }}>
        {t("mfa.general.tryAnotherWay", { context: mfaType })}
      </NavLink>
    </span>
  );
};

export const BackupMethod = withTranslation(["login"])(BackupMethodWoT);
