export const HEADER_HEIGHT_PX = 44;
export const CONTENT_HEIGHT = "60vh";

export { GENERATE_STATUSES } from "../../../store/actions/escalation/consts";

export const STATUS_COLOUR_MAPPING = {
  archived: {
    color: "white",
    backgroundColor: "#2a92e9",
  },
  disregarded: {
    color: "white",
    backgroundColor: "#8a97a3",
  },
  "not responded": {
    color: "white",
    backgroundColor: "black",
  },
  "in progress": {
    color: "white",
    backgroundColor: "#ff883f",
  },
  acknowledged: {
    color: "white",
    backgroundColor: "#e63c34",
  },
  completed: {
    color: "white",
    backgroundColor: "#368e48",
  },
  undefined: {
    color: "black",
    backgroundColor: "transparent",
  },
  getColour: function (status: string) {
    return (this as any)[status.toLowerCase()] || this["undefined"];
  },
};
