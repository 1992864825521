import Indicator, { IndicatorPersonalised, AnyIndicator } from "../types/indicator";
import IndicatorThreshold, { BRAG } from "../types/indicator-threshold";
import IndicatorNew from "../types/indicator-new";
import { IndicatorDisplayOption } from "../types/generic";

export const BRAG_BG_COLOUR_MAPPING: any = {
  "#132e3d": "black",
  "#212a41": "black",
  "#43d682": "green",
  "#00ca72": "green",
  "#fda31f": "amber",
  "#fda136": "amber",
  "#e91b4d": "red",
  "#e0394e": "red",
  "#2272bb": "blue",
  "#eee": "white", // error (looks gray)
  "#eeeeee": "white", // error alternative
  "#fff": "white",
  "#ffffff": "white",
  white: "white",
  green: "green",
  amber: "amber",
  red: "red",
  black: "black",
  blue: "blue",
};

export const BRAG_PRESSURE_LEVEL_MAPPING: any = {
  "-1": "white",
  0: "gray",
  1: "green",
  2: "amber",
  3: "red",
  4: "black",
  5: "white", // just in case
};

export const PRESSURE_LEVEL_BRAG_MAPPING: any = {
  gray: 0,
  green: 1,
  amber: 2,
  red: 3,
  black: 4,
  white: -1,
};

export const isGray = (indicator: Pick<IndicatorPersonalised, "greyOut"> & { [key: string]: any }) => {
  if (indicator.greyOut === true) {
    return true;
  }
  return false;
};

export const getColourByBg = (bg = "white"): string | undefined => {
  return BRAG_BG_COLOUR_MAPPING[bg.toLowerCase()];
};

export const generateBragStyles = (color = "black", bg = "transparent"): any => {
  return { color: color, backgroundColor: bg };
};

export const isBlue = (
  indicator: {
    displayOption?: IndicatorDisplayOption;
    [key: string]: any;
  },
  colorCodeBg?: string | undefined
) => {
  if (indicator.displayOption === 1 || BRAG_BG_COLOUR_MAPPING[(colorCodeBg || "").toLowerCase()] === "blue") {
    return true;
  }
  return false;
};

export const generateBragStylesOrClasses = (
  color: string | null = null,
  bg: string | null = null,
  extraClasses = ""
): any => {
  if (bg && getColourByBg(bg)) {
    return {
      className: `brag-${getColourByBg(bg)} ${extraClasses}`,
    };
  }
  return {
    className: extraClasses,
    //TODO: extraStyles ?
    style: generateBragStyles(color || undefined, bg || undefined),
  };
};

export const generateBragColour = (
  indicator: AnyIndicator,
  shouldGrayOut = false,
  keys: {
    valueColorCodeBg: keyof AnyIndicator;
    valueColorCodeFg: keyof AnyIndicator;
  } = {
    valueColorCodeBg: "valueColorCodeBg",
    valueColorCodeFg: "valueColorCodeFg",
  }
): { colour: string } | { textColour: string; backgroundColour: string } => {
  if (shouldGrayOut === true && isGray(indicator)) {
    return { colour: "gray" };
  }
  const bg = indicator[keys.valueColorCodeBg] as string | undefined;
  if (isBlue(indicator, bg)) {
    return { colour: "blue" };
  }
  const colour = getColourByBg(bg || "");
  if (colour) {
    return { colour };
  }
  if (indicator[keys.valueColorCodeFg] as string | undefined) {
    return { textColour: indicator[keys.valueColorCodeFg] as string, backgroundColour: bg as string };
  }
  return { colour: "white" };
};

// export const calculateIndicatorColourByThresholdOld = (
//   indicator: Indicator,
//   input: string | null,
//   checkIfGrayedOut = false
// ): IndicatorThreshold["name"] => {
//   if (input === null || input === undefined || input === "") {
//     return null;
//   }
//   if (checkIfGrayedOut) {
//     // TODO:
//     // check if indicator is grayedOut
//   }
//   if (!indicator.thresholdList || indicator.thresholdList.length === 0) {
//     return "white";
//     // TODO: check by current colour (e.g. if blue)
//   }
//   const value: number = parseFloat(input);
//   return indicator.thresholdList
//     .filter((threshold: IndicatorThreshold) => {
//       return value >= threshold.beginValue && value <= threshold.endValue;
//     })
//     .reduce((a: any, threshold: IndicatorThreshold) => {
//       return (threshold.name || "white").toLowerCase() as IndicatorThreshold["name"];
//     }, null);
//   //return "gray";
//   // TODO: return white / null - gray only for system dashboard
// };

export const calculateIndicatorColourByThreshold = (
  indicator: Indicator,
  input: string | null,
  checkIfGrayedOut = false
): BRAG => {
  if (input === null || input === undefined || input === "") {
    return null;
  }
  if (checkIfGrayedOut) {
    // TODO:
    // check if indicator is grayedOut
  }
  if (isBlue(indicator, indicator.valueColorCodeBg)) {
    return "blue";
  }
  if (!indicator.thresholdList || indicator.thresholdList.length === 0) {
    return "white";
    // TODO: check by current colour (e.g. if blue)
  }
  const value: number = parseFloat(input);
  return indicator.thresholdList
    .filter((threshold: IndicatorThreshold) => {
      const b = threshold.beginValue === null || threshold.beginValue === undefined ? -Infinity : threshold.beginValue;
      const e = threshold.endValue === null || threshold.endValue === undefined ? Infinity : threshold.endValue;
      return value >= b && value <= e;
    })
    .reduce((a: any, threshold: IndicatorThreshold) => {
      return (threshold.colorName || "white").toLowerCase() as BRAG;
    }, null);
  //return "gray";
  // TODO: return white / null - gray only for system dashboard
};

// ! same as above but different dto
// ! Used in: Reports
export const calculateNewIndicatorColourByThreshold = (
  indicator: IndicatorNew,
  input: string | number | null,
  checkIfGrayedOut = false
): BRAG => {
  if (input === null || input === undefined || input === "") {
    return null;
  }
  if (checkIfGrayedOut) {
    // TODO:
    // check if indicator is grayedOut
  }
  if (isBlue(indicator, indicator.colorCodeBg || (indicator as any).valueColorCodeBg)) {
    return "blue";
  }
  if (!indicator.thresholdList || indicator.thresholdList.length === 0) {
    return "white";
  }
  const value: number = typeof input === "number" ? input : parseFloat(input);
  return indicator.thresholdList
    .filter((threshold: any) => {
      const beginValue =
        threshold.activationBeginValue === undefined ? threshold.beginValue : threshold.activationBeginValue;
      const endValue = threshold.activationEndValue === undefined ? threshold.endValue : threshold.activationEndValue;
      const b = beginValue === null || beginValue === undefined ? -Infinity : beginValue;
      const e = endValue === null || endValue === undefined ? Infinity : endValue;
      return value >= b && value <= e;
    })
    .reduce((a: any, threshold: any) => {
      return (threshold.idColorCode?.name || threshold.colorName || "white").toLowerCase() as BRAG;
    }, null);
  //return "gray";
  // TODO: return white / null - gray only for system dashboard
};
