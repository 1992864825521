import { WINTER_REPORT_SERVER_NAME } from "../../../pages/reports/consts";
// ? TYPES:
import { ReportType } from "../../types/reports-state";

// temporary I guess?
// organisationId = areaId

export const GET_API_VERSION = (reportType: ReportType) => {
  if (reportType === "winterReport") {
    return 2;
  }
  return 1;
};

export const GET_REPORT_TYPE = (reportType: ReportType) => {
  return reportType === "winterReport" ? WINTER_REPORT_SERVER_NAME : "other-report";
};

export const GET_ID_STRING = (organisationId: number | string, reportType: ReportType) => {
  return reportType === "winterReport" ? organisationId : `${organisationId}/${reportType}`;
};

export const GET_REPORT_TYPE_WITH_ID_STRING = (organisationId: number | string, reportType: ReportType) => {
  return `${GET_REPORT_TYPE(reportType)}/${GET_ID_STRING(organisationId, reportType)}`;
};
