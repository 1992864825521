import { apiAction } from "../api";
// import { generateParams } from "../../../helpers/generate-params";
// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";
// import { PaginatedParams } from "../../../types/paginated-params";

const DEFAULT_LOCAL_SETTINGS = {
  baseURL: undefined,
  headers: {
    Authorization: undefined,
    "Cache-Control": "no-cache",
    pragma: "no-cache",
  },
};

const getManifest = (callbacks: ApiCallback): ApiAction => {
  return apiAction({
    method: "GET",
    ...DEFAULT_LOCAL_SETTINGS,
    url: "/resilience/asset-manifest.json",
    ...callbacks,
  });
};

const LocalService = {
  getManifest,
};

export default LocalService;
