import AuthService from "./auth.service";
import { redirectAfterLogin, redirectTo } from "./redirect-login";
import { getTokens } from "../../../get-tokens";
import { LOGIN_INTERNAL_URLS } from "../../../globals/internal-urls";
// ? TYPES:
import { ApiAction } from "../../types/api";
import { ApplicationState } from "../../reducers";
import { Credentials, MFAType, MFA, PreloginResponse, MobileValues } from "../../types/credentials-state";
// import { AxiosError } from "axios";

function getDeviceId(getState: () => ApplicationState): string {
  return getState().indexedDbReducer.deviceId.value;
}

export const logIn = (data: any, pathname = "", setupSuccess: MFAType | null = null): any => ({
  type: "LOG_IN",
  data,
  pathname,
  setupSuccess,
});

const prelogIn = (credentials: Credentials, data: PreloginResponse): any => ({
  type: "PRE_LOGIN",
  credentials,
  data,
});

const registerMfa = (credentials: Credentials, data: any, mfaType: MFAType, restartTimer: boolean) => ({
  type: "REGISTER_MFA",
  credentials,
  data,
  mfaType,
  restartTimer,
});

export const updateMfaMobileLocal = (mobileValues: Partial<MobileValues>) => ({
  type: "UPDATE_MFA_MOBILE_LOCAL",
  mobileValues,
});

const resendMfaCode = (mfaType: MFAType, restartTimer: boolean) => ({
  type: "RESEND_MFA_CODE",
  mfaType,
  restartTimer,
});

export const resetMfaDetails = (resetMap: { [MFAType: string]: boolean }) => ({
  type: "RESET_MFA_DETAILS",
  resetMap,
});

export const logOut = (): any => ({
  type: "LOG_OUT",
});

export const readStorage = (): any => ({
  type: "READ_STORAGE",
});

const authorize = (data: any) => ({
  type: "AUTHORIZE",
  data,
});

const refreshToken = (data: any) => ({
  type: "REFRESH_TOKEN",
  data,
});

export const clearAuthErrors = (): any => ({
  type: "CLEAR_AUTH_ERRORS",
});

export const forgotPasswordMail = (data: any, username: string): any => ({
  type: "FORGOT_PASSWORD_MAIL",
  data,
  username,
});

export const forgotSetPassword = (data: any, username: string) => ({
  type: "FORGOT_SET_PASSWORD",
  data,
  username,
});

export const accountActivate = (data: any, username: string) => ({
  type: "ACCOUNT_ACTIVATE",
  data,
  username,
});

const clearAllSuccess = () => ({
  type: "CLEAR_ALL_SUCCESS",
});

const delayedClearAllSuccess = () => async (dispatch: any) =>
  setTimeout(() => dispatch(clearAllSuccess()), 20 * 60 * 1000);

export const fetchAuthorize = (token: string | null): ApiAction => {
  return AuthService.getUserInfo(
    {
      token: token,
    },
    {
      label: "AUTHORIZE",
      onSuccess: authorize,
      onFailure: logOut,
    }
  );
};

export const fetchAuthorizeIfNeeded = (shouldReadStorage = true): Function | any => {
  return function (dispatch: any, _getState: any) {
    if (shouldReadStorage) {
      dispatch(readStorage());
    }
    const token = getTokens().access_token;
    if (token) {
      dispatch(fetchAuthorize(token));
    }
  };
};

export const fetchPreLogin = (credentials: Credentials, pathname = "") => {
  return function (dispatch: any, getState: () => ApplicationState) {
    dispatch(
      AuthService.preLogin(
        {
          ...credentials,
          deviceId: getDeviceId(getState),
        },
        {
          label: "PRE_LOGIN",
          onSuccess: (data) => {
            if (data.requirement === "SKIP") {
              dispatch(fetchLogIn(credentials, undefined, undefined, pathname));
            }
            return prelogIn(credentials, data);
          },
          onEnd: () => {
            const requirement = getState().credentialsReducer.mfa.info.requirement;
            if (requirement !== "SKIP") {
              redirectTo(requirement === "ENFORCE" ? LOGIN_INTERNAL_URLS.enforceMfa : LOGIN_INTERNAL_URLS.setupMfa);
            }
          },
        }
      )
    );
  };
};

//((dispatch: any, getState?: any) => Promise<void>)
export const fetchLogIn = (
  credentialsOverwrite: Credentials | undefined,
  values: MFA | undefined = {},
  mfaTypeOverwrite: MFAType | undefined = undefined,
  pathname = ""
) => {
  return function (dispatch: any, getState: () => ApplicationState) {
    const mfaState = getState().credentialsReducer.mfa;
    const credentials = credentialsOverwrite || mfaState.credentials;
    const mfaType = mfaTypeOverwrite || mfaState.info.options[0];
    dispatch(
      AuthService.logIn(
        {
          ...credentials,
          ...values,
          deviceId: getDeviceId(getState),
          mfaType,
        },
        {
          label: "LOG_IN",
          onSuccess: (data: any) => {
            redirectAfterLogin(pathname, data.app_user_info.redirectModule);
            return logIn(data, pathname);
          },
          other: { pathname },
          // onFailure: () => console.log("Error occured loading articles"),
        }
      )
    );
  };
};

export const fetchRegisterMfa = (mfaType: MFAType, restartTimer = false) => {
  return function (dispatch: any, getState: () => ApplicationState) {
    const mfaState = getState().credentialsReducer.mfa;
    const registerMfaEndpoint =
      mfaType === "TOTP"
        ? AuthService.registerMfaTotp
        : mfaType === "SMS"
        ? AuthService.registerMfaSms
        : AuthService.registerMfaEmail;

    const mobileInfo =
      mfaType === "SMS"
        ? {
            mobile: mfaState.smsDetails.mobileValues.mobile!,
            phoneCountryCode: mfaState.smsDetails.mobileValues.phoneCountryCode!.code,
          }
        : {};
    dispatch(
      registerMfaEndpoint(
        {
          ...mfaState.credentials,
          ...mobileInfo,
          deviceId: getDeviceId(getState),
        },
        {
          label: "REGISTER_MFA",
          onSuccess: (data: any) => registerMfa(mfaState.credentials, data, mfaType, restartTimer),
          other: { mfaType, restartTimer },
        }
      )
    );
  };
};

export const fetchVerifyMfa = (values: MFA, mfaType: MFAType, pathname = "") => {
  return function (dispatch: any, getState: () => ApplicationState) {
    const credentials = getState().credentialsReducer.mfa.credentials;
    const verifyMfaEndpoint =
      mfaType === "TOTP"
        ? AuthService.verifyMfaTotp
        : mfaType === "SMS"
        ? AuthService.verifyMfaSms
        : AuthService.verifyMfaEmail;
    dispatch(
      verifyMfaEndpoint(
        {
          ...credentials,
          ...values,
          deviceId: getDeviceId(getState),
          mfaType,
        },
        {
          label: "VERIFY_REGISTER_MFA",
          onSuccess: (data: any) => {
            if (!data) {
              return; // just in case
            }
            redirectAfterLogin(pathname, data.app_user_info.redirectModule);
            return logIn(data, pathname, mfaType);
          },
          other: { pathname },
        }
      )
    );
  };
};

export const fetchResendCodeIfNeeded = (mfaType: MFAType, restartTimer = false) => {
  return function (dispatch: any, getState: () => ApplicationState) {
    const mfaState = getState().credentialsReducer.mfa;
    if (new Date() >= mfaState.canSendAfter[mfaType]) {
      dispatch(dispatch(fetchResendCode(mfaType, restartTimer)));
    }
  };
};

export const fetchResendCode = (mfaType: MFAType, restartTimer = false, afterSuccess?: () => void) => {
  return function (dispatch: any, getState: () => ApplicationState) {
    const mfaState = getState().credentialsReducer.mfa;
    const credentials = mfaState.credentials;
    // const mfaType = mfaTypeOverwrite || mfaState.info.options[0];
    if (mfaType !== "TOTP") {
      dispatch(
        AuthService.resendVerificationCode(
          {
            ...credentials,
            deviceId: getDeviceId(getState),
            mfaType,
          },
          {
            label: "RESEND_MFA_CODE",
            onSuccess: () => {
              if (afterSuccess) {
                afterSuccess();
              }
              return resendMfaCode(mfaType, restartTimer);
            },
            other: { restartTimer, mfaType },
          }
        )
      );
    }
  };
};

export const fetchRefreshToken = () => {
  const refresh_token = getTokens().refresh_token!;
  return AuthService.refreshToken({ refresh_token }, { label: "REFRESH_TOKEN", onSuccess: refreshToken });
};

const fetchLogOut = (): ApiAction => {
  return AuthService.logOut({
    label: "LOG_OUT",
    // onSuccess: logOut,
    // onFailure: () => console.log("Error occured loading articles"),
  });
};

export const logOutLazy = (): Function | any => {
  return function (dispatch: any, _getState: any) {
    dispatch(fetchLogOut());
    dispatch(logOut());
  };
};

////

export const fetchForgotPasswordMail = (username: string): ApiAction => {
  return AuthService.forgotPasswordMail(
    { username },
    {
      label: "FORGOT_PASSWORD_MAIL",
      onSuccess: (response: any) => forgotPasswordMail(response, username),
      onEnd: () => delayedClearAllSuccess() as any,
      other: { username },
    }
  );
};

export const fetchSetForgotPassword = (values: {
  username: string;
  key: string;
  password: string;
  confirmPassword: string;
}): ApiAction => {
  return AuthService.setForgotPassword(values, {
    label: "FORGOT_SET_PASSWORD",
    onSuccess: (response: any) => forgotSetPassword(response, values.username),
    other: { username: values.username },
  });
};

export const fetchAccountActivate = (values: {
  username: string;
  key: string;
  password: string;
  confirmPassword: string;
}): ApiAction => {
  return AuthService.accountActivate(values, {
    label: "ACCOUNT_ACTIVATE",
    onSuccess: (response: any) => accountActivate(response, values.username),
    other: { username: values.username },
  });
};
