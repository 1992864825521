export const NAMES = {
  GET_DOCUMENTS: "GET_DOCUMENTS",
  UPLOAD_MULTIPLE_DOCUMENTS: "UPLOAD_MULTIPLE_DOCUMENTS",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  UPDATE_DOCUMENT_UPLOAD_PROGRESS: "UPDATE_DOCUMENT_UPLOAD_PROGRESS",
  UPDATE_DOCUMENT_DOWNLOAD_PROGRESS: "UPDATE_DOCUMENT_DOWNLOAD_PROGRESS",
  RESET_DOCUMENT_UPLOAD_PROGRESS: "RESET_DOCUMENT_UPLOAD_PROGRESS",
  DOWNLOAD_DOCUMENT: "DOWNLOAD_DOCUMENT",
  DELETE_DOCUMENTS: "DELETE_DOCUMENTS",
  UPDATE_DOCUMENT_META: "UPDATE_DOCUMENT_META",
};
