/*eslint-disable */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { wrappedPromise } from "../helpers/wrappedPromise";
import isEqual from "lodash/isEqual";
import { getPixelFromLatLng } from "../helpers/map";
import { camelize } from "google-maps-react/dist/lib/String";

const evtNames = ["click", "dblclick", "dragend", "mousedown", "mouseout", "mouseover", "mouseup", "recenter"];


export class Marker extends React.Component {
  componentDidMount() {
    this.markerPromise = wrappedPromise();
    this.renderMarker();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.map !== prevProps.map
    ) {
      if (this.marker) {
        this.marker.setMap(null);
      }
      this.renderMarker();
    }
    if (this.props.visible !== prevProps.visible) {
      this.marker.setVisible(this.props.visible);
    }
    if (this.props.zIndex !== prevProps.zIndex) {
      this.marker.setZIndex(this.props.zIndex);
    }
    if (this.props.animation !== prevProps.animation) {
      this.animate(google, this.props.animation);
    }
    if (typeof this.props.icon === "string" && this.props.icon !== prevProps.icon || typeof this.props.icon !== "string" && this.props.icon.url !== prevProps.icon.url) {
      this.marker.setIcon(this.generateIcon(this.props.icon));
    }
    if (!isEqual(this.props.position, prevProps.position)) {
      this.marker.setPosition(this.props.position);
    }
    if (this.props.opacity !== prevProps.opacity) {
      this.marker.setOpacity(this.props.opacity);
    }
  }

  componentWillUnmount() {
    if (this.marker) {
      this.marker.setMap(null);
    }
  }

  renderMarker() {
    const { map, google, position, mapCenter, icon, label, draggable, title, animation, ...props } = this.props;
    if (!google) {
      return null;
    }

    let pos = position || mapCenter;
    if (!(pos instanceof google.maps.LatLng)) {
      pos = new google.maps.LatLng(pos.lat, pos.lng);
    }
    const getPixelPosition = getPixelFromLatLng.bind(this, pos, map);
    const newIcon = this.generateIcon(icon);

    const pref = {
      map,
      position: pos,
      icon: newIcon,
      label,
      title,
      draggable,
      getPixelPosition,
      ...props
    };
    this.marker = new google.maps.Marker(pref);
    this.animate(google, animation);
    evtNames.forEach((e) => {
      const evtName = `on${camelize(e)}`;
      if (this.props[evtName]) {
        this.marker.addListener(e, this.handleEvent(e));
      }
    });

    this.markerPromise.resolve(this.marker);
  }

  generateIcon = (icon) => {
    if (typeof icon !== "string") {
      return {
        url: icon.url,
        ...(icon.size && {size: new google.maps.Size(icon.size[0], icon.size[1])}),
        ...(icon.scaledSize && {scaledSize: new google.maps.Size(icon.scaledSize[0], icon.scaledSize[1])}),
        ...(icon.origin && {origin: new google.maps.Point(icon.origin[0], icon.origin[1])}),
        ...(icon.anchor && {anchor: new google.maps.Point(icon.anchor[0], icon.anchor[1])}),
      }
    }
    return icon;
  }

  animate = (google, animation) => {
    if (!animation) {
      return;
    }
    this.marker.setAnimation(google.maps.Animation[animation]);
  }

  getMarker() {
    return this.markerPromise;
  }

  handleEvent(evt) {
    return (e) => {
      const evtName = `on${camelize(evt)}`;
      // if (this.props[evtName]) {
        this.props[evtName](this.props, this.marker, e);
      // }
    };
  }

  render() {
    return (
      <Fragment>
        {this.props.children && this.marker
          ? React.Children.only(
              React.cloneElement(this.props.children, {
                marker: this.marker,
                google: this.props.google,
                map: this.props.map
              })
            )
          : null}
      </Fragment>
    );
  }
}

Marker.propTypes = {
  position: PropTypes.object,
  map: PropTypes.object
};

// evtNames.forEach((e) => (Marker.propTypes[e] = PropTypes.func));

Marker.defaultProps = {
  name: "Marker"
};

export default Marker;
