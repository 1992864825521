import { RBA } from "../../types/menu-state";

export const createRba = (
  previousRba: RBA,
  organisationLists: { [key: string]: any | any[] },
  ignoreList: string[] = []
) => {
  return Object.entries(organisationLists).reduce((final, [key, current]) => {
    if (Array.isArray(current) && !ignoreList.includes(key)) {
      for (let i = 0; i < current.length; i += 1) {
        const item = current[i];
        const id = item.id || item.areaId; // whatever is used for id
        if (item.feature && id) {
          final[id] = {
            ...final[id],
            ...item.feature,
          };
        }
      }
      //
    }
    return final;
  }, previousRba);
};
