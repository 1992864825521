export const encrypt = (text: string | number, salt = "default") =>
  btoa(
    Array.from(text.toString())
      .map((c, i) => String.fromCharCode(c.charCodeAt(0) ^ salt.charCodeAt(i % salt.length)))
      .join("")
  ).replace(/=/g, "");

export const decrypt = (text: string, salt = "default") =>
  Array.from(atob(text + "===".slice((text.length + 3) % 4)))
    .map((c, i) => String.fromCharCode(c.charCodeAt(0) ^ salt.charCodeAt(i % salt.length)))
    .join("");
