import React from "react";
import { useDispatch } from "react-redux";
import { editAndFetchUpdateWeight, fetchTables } from "../../store/actions/system-dashboard";
import { Icon, Tippy, Modal } from "@tscore/react-components";
import { BragWithTag } from "../../components/brag-with-tag";
import { WeightInput } from "./weight-input";
import classNames from "classnames";
import { IndicatorModal } from "./indicator-modal";
import { IndicatorGraph } from "../../components/indicator-history/indicator-graph";
import { generateBragColour, isGray } from "../../helpers/brag-helpers";
import { formatToLocalString } from "../../lang/date-fns";
import { UPDATED_ON_DATE_TIME_FORMAT, INDICATOR_MODAL_GENERAL_PROPS } from "./consts";
import { useTranslation } from "react-i18next";

// ? TYPES:
import { SystemDashboardTable } from "../../types/system-dashboard-table";
import { IndicatorExtendedSmart, IndicatorSystem } from "../../types/indicator";
import { RBA } from "../../store/types/menu-state";

type Indicator = IndicatorSystem;

interface IndicatorTableProps {
  rba: RBA[0];
  // canEditWeights?: boolean;
  data: SystemDashboardTable<IndicatorExtendedSmart<IndicatorSystem>>[];
  title: string;
  unitId: number;
  showWeights: boolean;
  systemDashboardId: number; // weight update
  siteId: number; // weight update
  isSavingWeights: { [indicatorId: string]: undefined | { oldWeight: number } };
  isFetchingTable: boolean;
}

export const IndicatorTable: React.FC<IndicatorTableProps> = ({
  data,
  rba,
  // title,
  unitId,
  showWeights,
  systemDashboardId,
  siteId,
  isSavingWeights,
  isFetchingTable = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const canEditWeights = rba.WEIGHT_EDIT;
  const onWeightChange =
    (tableId: number, tableRowId: number) =>
    (e: any, indicator: Indicator): void => {
      if (!canEditWeights) {
        return;
      }
      const { value } = e.target;
      const isValid = /^\d+$/.test(value);
      dispatch(
        editAndFetchUpdateWeight(
          systemDashboardId,
          siteId,
          unitId,
          indicator.indicatorId,
          value,
          tableId,
          tableRowId,
          isValid
        )
      );
      // console.log(value);
    };

  const onRefreshData = (unitId: number) => {
    if (isFetchingTable) {
      return;
    }
    dispatch(fetchTables(systemDashboardId, siteId, unitId));
  };

  return (
    <>
      {data.map((table) => (
        <div key={table.tableId}>
          <div>
            <div className="table bordered no-vertical-lines main shadowed" data-unit-id={unitId}>
              <header className="thead">
                <ul className="row">
                  <li className="th">{table.title}</li>
                  {table.columnList.map((heading) => (
                    <li className="th ta-center" key={heading.tableColumnId}>
                      {heading.title}
                    </li>
                  ))}
                  <li className="th graph-holder refresh-button-holder">
                    <Tippy content={t("dashboards:tooltipUpdateTableData")} placement="bottom">
                      <i
                        className={classNames("material-icons pointer table-refresh-icon", {
                          isRefreshing: isFetchingTable,
                        })}
                        onClick={() => onRefreshData(unitId)}>
                        cached
                      </i>
                    </Tippy>
                  </li>
                </ul>
              </header>
              <div className="tbody">
                {table.rowList.map((row) => {
                  const graphIndicatorList = row.indicatorList.filter(
                    (indicator) => Boolean(indicator) && indicator!.dataType !== 10
                  ) as IndicatorExtendedSmart<IndicatorSystem>[];
                  return (
                    <ul key={row.tableRowId} className="row">
                      <li className="cell normal-holder">{row.groupName}</li>
                      {row.indicatorList.map((indicator, index) => {
                        if (!indicator) {
                          return <li key={index} className="cell brag-holder" />;
                        }
                        const isLoadingWeight = isSavingWeights[indicator.indicatorId] !== undefined ? true : false;
                        return (
                          <li className="cell brag-holder" key={index}>
                            <Modal
                              {...INDICATOR_MODAL_GENERAL_PROPS}
                              trigger={
                                <Tippy
                                  placement="bottom"
                                  content={
                                    <div className="ta-center">
                                      <span style={{ fontSize: "1.2rem" }}>{t("dashboards:tooltipUpdatedOn")}</span>
                                      <br />
                                      <span>
                                        {formatToLocalString(indicator.valueTimestamp, UPDATED_ON_DATE_TIME_FORMAT)}
                                      </span>
                                    </div>
                                  }>
                                  <span>
                                    <BragWithTag
                                      className="pointer"
                                      {...generateBragColour(indicator, false)}
                                      isGreyWithTag={isGray(indicator)}
                                      tooltipDisabled={true}>
                                      {indicator.readableValue ?? indicator.value}
                                    </BragWithTag>
                                    {/* <Brag className="pointer" {...generateBragColour(indicator, true)}>
                                    {indicator.readableValue ?? indicator.value}
                                  </Brag> */}
                                  </span>
                                </Tippy>
                              }>
                              <IndicatorModal extraISHInfo={{ systemDashboardId, unitId }} indicator={indicator} />
                            </Modal>
                            <WeightInput
                              showWeights={(rba.WEIGHT_VIEW || rba.WEIGHT_EDIT) && showWeights}
                              indicator={indicator}
                              isLoading={isLoadingWeight}
                              canEdit={canEditWeights}
                              onWeightChange={onWeightChange(table.tableId, row.tableRowId)}
                            />
                          </li>
                        );
                      })}
                      <li className="cell graph-holder">
                        {graphIndicatorList.length > 0 && (
                          <Modal
                            {...INDICATOR_MODAL_GENERAL_PROPS}
                            trigger={<Icon className="pointer">bar_chart</Icon>}>
                            <IndicatorGraph
                              extraISHInfo={{ systemDashboardId, unitId }}
                              indicators={graphIndicatorList}
                              comparisonKey={row.comparisonGraphKey}
                              indicatorGroupId={row.indicatorGroupId}
                            />
                          </Modal>
                        )}
                      </li>
                      {/* {row.map((cell: any, index: number) => (
                      <li
                        key={index}
                        className={classes({
                          cell: true,
                          [cell.type + "-holder"]: !!cell.type,
                        })}>
                        {cell.type === "normal" && cell.value}
                        {cell.type === "graph" && <i className="material-icons pointer">bar_chart</i>}
                        {cell.type === "brag" && (
                          <span
                            className={classes({
                              "brag pointer": true,
                              ["brag-" + cell.color]: true,
                            })}>
                            {cell.value}
                          </span>
                        )}
                      </li>
                    ))} */}
                    </ul>
                  );
                })}
              </div>
            </div>
            {/* <aside className="table-footer ta-right">
              <a href="#!">Show Weighting</a>
            </aside> */}
          </div>
        </div>
      ))}
    </>
  );
};
