import { isNewFeature } from "./is-new-feature";
// ? TYPES:
import { ExtendedLevelOneMenu, ImportedMenu, MenuState } from "../../types/menu-state";
import { MODULE_IDS, TModuleUrlKey } from "../../../globals/internal-urls";

const isDropdownCheck = (list: null | undefined | any[]): boolean => {
  return list && list.length >= 0 ? true : false;
};

const createExtendedMenu = (
  currentMenu: ImportedMenu[],
  module: TModuleUrlKey
): ImportedMenu<ExtendedLevelOneMenu>[] => {
  return currentMenu.map((category) => ({
    ...category,
    menu: !category.menu
      ? null
      : category.menu.map((item) => {
          const isDropdown = isDropdownCheck(item.menu);
          const subListLength = (isDropdown && item.menu!.length) || 0;
          const showSearch = subListLength >= 10;
          const url = item.url || "";
          return {
            ...item,
            url,
            isNew: isNewFeature(module, item.name),
            isDropdown,
            subListLength,
            isEmpty: isDropdown && subListLength === 0 ? true : false,
            showSearch,
          };
        }),
  }));
};

export const createExtendedMenus = (menus: {
  [key in keyof typeof MODULE_IDS]: ImportedMenu[];
}): MenuState["menu"] => {
  return Object.entries(menus).reduce((acc, [moduleName, menu]) => {
    return {
      ...acc,
      [moduleName]: createExtendedMenu(menu, moduleName as TModuleUrlKey),
    };
  }, {} as MenuState["menu"]);
};
