import { WINTER_REPORT_COSMETIC_NAME } from "../../../pages/reports/consts";
// ? TYPES:
import { MenuMap, NHSRMenuMap, ElectiveMenuMap, VantageMenuMap } from "../../types/menu-state";

const CATEGORY_MAP = {
  EMPTY: "",
  DASHBOARDS: "Dashboards",
  ADMINISTRATION: "Administration",
  REPORTS: "Reports",
} as const;

export const MENU_MAP: MenuMap = {
  [CATEGORY_MAP.EMPTY]: {
    home: {
      name: "Home",
      menu: null,
      inverted: false,
    },
    covidSingle: {
      name: "COVID Heatmap", // will get overridden
      menu: null,
      inverted: false,
      serviceName: "covid-service",
      urlSettings: {
        dontSlugify: true,
        paramNameList: [],
        searchParamNameList: ["contracts"],
      },
    },
  },
  [CATEGORY_MAP.DASHBOARDS]: {
    systemDashboardList: {
      name: "Dashboards",
      menu: [],
      serviceName: "system-dashboard-service",
      urlSettings: {
        extraParams: ["primary"],
      },
    },
  },
  [CATEGORY_MAP.ADMINISTRATION]: {
    manualUpdateOrganisationList: {
      name: "Manual Updates",
      menu: [],
      serviceName: "resilience-service",
    },
    documentOrganisationList: {
      name: "Documents",
      menu: [],
      serviceName: "resilience-service",
    },
  },
  [CATEGORY_MAP.REPORTS]: {
    winterReportOrganisationList: {
      name: "Regional Report",
      menu: [],
      serviceName: "fake-report-service",
      urlSettings: {
        extraParams: [WINTER_REPORT_COSMETIC_NAME],
      },
    },
    otherReportOrganisationList: {
      name: "Other Report",
      menu: [],
      serviceName: "fake-report-service",
      urlSettings: {
        paramNameList: ["areaId", "reportTypeId"],
        slugName: "areaName",
      },
    },
    tableauGlobalList: {
      name: "Benchmarking",
      menu: [],
      serviceName: "fake-tableau-service",
      urlSettings: {
        dontSlugify: true,
      },
    },
    tableauMenuList: {
      name: "Tableau Reports",
      menu: [],
      serviceName: "integration-service",
      inverted: true,
    },
  },
};

export type TCategoryName = (typeof CATEGORY_MAP)[keyof typeof CATEGORY_MAP];

// NHSR:

const NHSR_CATEGORY_MAP = {
  EMPTY: "",
  DASHBOARDS: "Dashboards",
  ADMINISTRATION: "Administration",
  REPORTS: "Reports",
} as const;

export const NHSR_MENU_MAP: NHSRMenuMap = {
  [NHSR_CATEGORY_MAP.EMPTY]: {
    nhsrhome: {
      name: "Home",
      menu: null,
      inverted: false,
    },
  },
  [NHSR_CATEGORY_MAP.DASHBOARDS]: {
    nhsrsystemDashboardList: {
      name: "Dashboards",
      menu: [],
      serviceName: "nhsr-service",
      urlSettings: {
        extraParams: ["primary"],
      },
    },
  },
  [NHSR_CATEGORY_MAP.ADMINISTRATION]: {
    nhsrdocumentOrganisationList: {
      name: "Surge & Escalation Plans",
      menu: [],
      serviceName: "nhsr-service",
    },
  },
  [NHSR_CATEGORY_MAP.REPORTS]: {
    nhsrregionalReport: {
      name: "Regional Report",
      menu: null,
      serviceName: "nhsr-service",
    },
    nhsrotherReportOrganisationList: {
      name: "other_report_custom",
      menu: [],
      serviceName: "nhsr-service",
      urlSettings: {
        paramNameList: ["areaId", "reportTypeId"],
        slugName: "areaName",
      },
    },
    nhsrtableauGlobalList: {
      name: "Benchmarking",
      menu: [],
      serviceName: "nhsr-service",
      urlSettings: {
        dontSlugify: true,
      },
    },
  },
};

export type TNHSRCategoryName = (typeof NHSR_CATEGORY_MAP)[keyof typeof NHSR_CATEGORY_MAP];

// Elective:

const ELECTIVE_CATEGORY_MAP = {
  EMPTY: "",
  DASHBOARDS: "Dashboards",
} as const;

export const ELECTIVE_MENU_MAP: ElectiveMenuMap = {
  [ELECTIVE_CATEGORY_MAP.EMPTY]: {
    // electivehome: {
    //   name: "Home",
    //   menu: null,
    //   inverted: false,
    // },
    electiveMenuList: {
      name: "elective_list_custom",
      menu: [],
      serviceName: "elective-service",
    },
  },
  [ELECTIVE_CATEGORY_MAP.DASHBOARDS]: {
    electiveDashboardList: {
      name: "Dashboards",
      menu: [],
      serviceName: "elective-service",
      urlSettings: {
        extraParams: ["primary"],
      },
    },
  },
};

export type TElectiveCategoryName = (typeof ELECTIVE_CATEGORY_MAP)[keyof typeof ELECTIVE_CATEGORY_MAP];

// Vantage:

const VANTAGE_CATEGORY_MAP = {
  EMPTY: "",
  DASHBOARDS: "Dashboards",
} as const;

export const VANTAGE_MENU_MAP: VantageMenuMap = {
  [VANTAGE_CATEGORY_MAP.EMPTY]: {
    // vantagehome: {
    //   name: "Home",
    //   menu: null,
    //   inverted: false,
    // },
    vantageMenuList: {
      name: "vantage_list_custom",
      menu: [],
      serviceName: "vantage-service",
    },
  },
  [VANTAGE_CATEGORY_MAP.DASHBOARDS]: {
    vantageDashboardList: {
      name: "Dashboards",
      menu: [],
      serviceName: "vantage-service",
      urlSettings: {
        extraParams: ["primary"],
      },
    },
  },
};

export type TVantageCategoryName = (typeof VANTAGE_CATEGORY_MAP)[keyof typeof VANTAGE_CATEGORY_MAP];

//

export const DEFAULT_RBA_LIST = {
  DASHBOARD_VIEW: true, // ignored
  DOCUMENTS_VIEW: true, // ignored
  TABLEAU_SYSTEM_DASHBOARD_DROPDOWN_VIEW: false,
  TABLEAU_MAIN_MENU_VIEW: false,
  WEIGHT_VIEW: false,
  WEIGHT_EDIT: false,
  PTL_VIEW: false,
  WINTER_REPORT_SUBMITTER: false,
  WINTER_REPORT_REQUESTER: false,
  DOCUMENTS_UPLOAD: false,
  DOCUMENTS_DELETE: false,
  OTHER_REPORT_SUBMITTER: false,
  OTHER_REPORT_REQUESTER: false,
  WINTER_REPORT_VIEW: false,
  OTHER_REPORT_VIEW: false,
  WINTER_REPORT_FILLER: false,
  OTHER_REPORT_FILLER: false,
  WINTER_REPORT_DELETE: false,
  OTHER_REPORT_DELETE: false,
  // canDeleteDocument: false,
  // canUploadDocument: false,
  // isWeekendPlanReportRequester: false,
  // isWeekendPlanReportSubmitter: false,
  // isWinterReportRequester: false,
  // isWinterReportSubmitter: false,
} as const;

export type TKnownRbaNames = keyof typeof DEFAULT_RBA_LIST;
