import React from "react";
import { Select } from "@tscore/react-components";

import { useField } from "formik";

// ? TYPES
import { ComponentProps } from "../../types/generic";

// const generateOptions = (options: ImportedConfigOption[]): ConfigOption[] => {
//   return options.reduce((final: ConfigOption[], item: ImportedConfigOption, index: number) => {
//     final[index] = {
//       value: item.value,
//       label: item.title,
//     };
//     return final;
//   }, []);
// };

// ! TODO different wrapper for Forms module
export const FormikSelect = ({
  _name,
  placeholder,
  setFieldValue,
  ...props
}: any & ComponentProps<typeof Select>): JSX.Element => {
  // const options: ConfigOption[] = config.options;
  // const settings = {
  //   isMulti: config?.allowMultiSelect ? true : false,
  // };
  const [{ onChange, ...field }] = useField(props);
  // const getValue = (
  //   value: string | string[] | null,
  //   isMulti: boolean,
  //   options: ConfigOption[]
  // ): ConfigOption | ConfigOption[] | undefined | null => {
  //   if (!isMulti) {
  //     return options.find((v: ConfigOption) => v.value === value) || null;
  //   }
  //   return options.reduce((final: any[], v: ConfigOption) => {
  //     if (!!value && value.includes(v.value as string)) {
  //       final.push(v);
  //     }
  //     return final;
  //   }, []);
  // };
  const handleChange = (selectedOption: any | any[]): void => {
    setFieldValue(props.name, selectedOption);
  };
  // console.log({ field });
  return (
    <Select
      classNamePrefix="select"
      className="select"
      // options={options}
      placeholder={placeholder}
      // {...settings}
      {...field}
      {...props}
      // value={getValue(value, settings.isMulti, options)}
      onChange={handleChange}
    />
  );
};
