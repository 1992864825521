import { apiAction } from "../api";
import { SERVICE_URLS, PDF_SERVICE_URLS } from "../../../globals/service-urls";
import { generateParams, generateSortApiArray } from "../../../helpers/generate-params";
import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";
import { GET_API_VERSION, GET_REPORT_TYPE } from "./get-api-version";
// import { formatToISO } from "../../../lang/DateTimeFormats";
// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";
import { PaginatedParams, PaginatedSort } from "../../../types/paginated-params";
import { ReportType, ReportFilters } from "../../types/reports-state";

export type SystemOrigin = "REGIONS" | "RESILIENCE" | "MOBILEAPP";

const SERVICE_NAME = "nhsr-service";

const getReportList = (
  {
    id,
    page = 1,
    limit = DEFAULT_PAGE_LIMIT,
    disablePagination,
    reportType,
    // reportTypeName = "",
    filters = {},
    sort = {},
  }: PaginatedParams & {
    id: "all" | number;
    reportType: ReportType;
    // reportTypeName?: string;
    filters: ReportFilters;
    sort: PaginatedSort;
  },
  callbacks: ApiCallback
): ApiAction => {
  const systemOrigin: SystemOrigin = id === "all" ? "REGIONS" : "RESILIENCE";
  const sortList = generateSortApiArray(sort);
  const params = { page, limit, disablePagination, systemOrigin, ...filters, sort: sortList };
  const type = GET_REPORT_TYPE(reportType);
  const getOrganisationIdString = () => {
    if (id === "all") {
      if (reportType === "winterReport") {
        return "";
      }
      return `reportType/${reportType}`;
    }
    if (reportType === "winterReport") {
      return `area/${id}`;
    }
    return `${id}/${reportType}`;
  };
  const organisationId = getOrganisationIdString();
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS[SERVICE_NAME]}${GET_API_VERSION(reportType)}/${type}/${organisationId}${generateParams(
      params,
      { encodeValues: true }
    )}`,
    ...callbacks,
  });
};
const getRequestReport = (
  { reportType, organisationId }: { reportType: ReportType; organisationId: number },
  callbacks: ApiCallback
): ApiAction => {
  const type = GET_REPORT_TYPE(reportType);
  const idString = reportType === "winterReport" ? organisationId : `${organisationId}/${reportType}`;
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS[SERVICE_NAME]}${GET_API_VERSION(reportType)}/${type}/${idString}/request`,
    ...callbacks,
  });
};

const deleteReport = (
  { reportType, reportId }: { reportType: ReportType; reportId: number | string },
  callbacks: ApiCallback
): ApiAction => {
  const type = GET_REPORT_TYPE(reportType);
  return apiAction({
    method: "DELETE",
    url: `${SERVICE_URLS[SERVICE_NAME]}${GET_API_VERSION(reportType)}/${type}/${reportId}`,
    ...callbacks,
  });
};

// winter only:
const requestMoreInfo = (
  { reportId, questions }: { reportId: number | string; questions: { question: string }[] },
  callbacks: ApiCallback
): ApiAction => {
  const type = GET_REPORT_TYPE("winterReport");
  const body = {
    additionalQuestionList: questions,
  };
  return apiAction({
    method: "PUT",
    url: `${SERVICE_URLS[SERVICE_NAME]}${GET_API_VERSION("winterReport")}/${type}/${reportId}/requestInfo`,
    data: body,
    ...callbacks,
  });
};

const downloadNHSReport = (
  { body }: { body: { value: string; cssLinks?: string[] } },
  callbacks: ApiCallback,
  onDownloadProgress?: Function
): ApiAction => {
  return apiAction({
    method: "POST",
    url: `${PDF_SERVICE_URLS["shrewd-pdf-generator"]}/v2/generate`,
    headers: {
      Authorization: undefined,
    },
    responseType: "blob",
    data: body,
    ...(onDownloadProgress && { onDownloadProgress: (e: any) => onDownloadProgress(e) }),
    ...callbacks,
  });
};

const downloadNHSReportTokenCheck = (
  { id, reportType }: { id: "all" | number; reportType: ReportType },
  callbacks: ApiCallback<any>
): ApiAction => {
  return getReportList({ id, limit: 1, page: 1, reportType, filters: {}, sort: {} }, callbacks);
};

const ReportsService = {
  getReportList,
  getRequestReport,
  deleteReport,
  requestMoreInfo,
  downloadNHSReport,
  downloadNHSReportTokenCheck,
};

export default ReportsService;
