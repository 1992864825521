import { apiAction } from "../../api";
import { SERVICE_URLS } from "../../../../globals/service-urls";
import { generateParams } from "../../../../helpers/generate-params";
// import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";
// ? TYPES:
import { VantageData } from "../../../types/maps-vantage";
import { ApiCallback, ApiAction } from "../../../types/api";

// import { PaginatedParams } from "../../../types/paginated-params";

const VERSION = 1;

export const getVantageInitial = ({ vecId }: { vecId: number }, callbacks: ApiCallback): ApiAction => {
  const params = { idVec: vecId };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["vantage-service"]}${VERSION}/vantage/data${generateParams(params)}`,
    ...callbacks,
  });
};

export const getVantageMarkers = (
  { vecId }: { vecId: number },
  { filters }: { filters: Omit<VantageData, "ccgs" | "markers"> },
  callbacks: ApiCallback
): ApiAction => {
  const params = { idVec: vecId };
  return apiAction({
    method: "POST",
    data: filters,
    url: `${SERVICE_URLS["vantage-service"]}${VERSION}/vantage/data${generateParams(params)}`,
    ...callbacks,
  });
};
