import { ImportedMenu, LevelOneMenu } from "../store/types/menu-state";

export const flattenMenu = (menu: ImportedMenu[]): LevelOneMenu[] => {
  return menu.reduce((final: LevelOneMenu[], item: ImportedMenu) => {
    const menu = item.menu || [];
    return [...final, ...menu];
  }, []);
};

export const findTitleByNameAndId = (menu: ImportedMenu[], name: string, id: number): string => {
  const flatMenu = flattenMenu(menu);
  const organisationList = flatMenu.find((item) => item.name === name);
  if (!organisationList || !organisationList.menu) {
    return "-";
  }
  return (organisationList.menu.find((organisation) => organisation.id === id) || { name: "-" }).name;
};
