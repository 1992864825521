import React from "react";
import { Marker } from "../improved/Marker";
import { hospitalIconAsDataUri } from "../icons/HospitalIcon.component";
// import { BRAG_COLOURS, FACILITY_TYPE_TO_TEXT_MAPPING, BRAG_PRESSURE_LEVEL_MAPPING } from "../globals";
import { FACILITY_ICON_SIZE, FACILITY_FONT_SIZE, FACILITY_RADIUS } from "../settings";
import { FACILITY_TYPE_TO_TEXT_MAPPING } from "../consts";

// ? TYPES:
import { ExpandedMarker } from "../../../types/google-map";
import { MarkerRequired } from "../../../store/types/maps-state";

// type FacilityMarkerOptions = MapsHospital;
export type TConfigValue = [number] | [number, number];
export interface TConfig {
  fontSize?: TConfigValue;
  strokeWidth?: TConfigValue;
  width?: TConfigValue;
  offset?: TConfigValue;
  boxHeight?: TConfigValue;
  radius?: TConfigValue;
}

export const generateConfigWithDefaultValues = (
  config: { default: TConfig; [typeAcronym: string]: TConfig },
  defaultConfig: Required<TConfig>
): { [typeAcronym: string]: Required<TConfig> } => {
  return Object.entries(config).reduce((final, [key, current]) => {
    return {
      ...final,
      [key]: {
        ...defaultConfig,
        ...current,
      },
    };
  }, {});
};

const DEFAULT_ZINDEX = 4;
interface Props {
  facilities: MarkerRequired[];
  onMouseover: (facility: any, props: any, marker: ExpandedMarker, MouseEvent: any) => void;
  onMouseout: Function;
  currentFacilityHover: ExpandedMarker | null;
  onFacilityClick?: (facility: MarkerRequired | any) => void;
  // ieVersion?: { type: "ie" | "edge" | null; version: number };
  hoverKeyCheckName?: string;
  backupType?: string;
  config?: { default: TConfig; [typeAcronym: string]: TConfig };
  facilityUniqueId?: string;
}

export const DEFAULT_CONFIG: Required<TConfig> = {
  fontSize: [FACILITY_FONT_SIZE],
  strokeWidth: [FACILITY_ICON_SIZE.STROKE_WIDTH],
  width: [FACILITY_ICON_SIZE.WIDTH],
  offset: [FACILITY_ICON_SIZE.BOX_OFFSET],
  boxHeight: [FACILITY_ICON_SIZE.BOX_HEIGHT],
  radius: [FACILITY_RADIUS],
};

const facilitiesList = ({
  facilities,
  onMouseover,
  onMouseout,
  currentFacilityHover,
  onFacilityClick,
  // ieVersion = (window as any).browser || { type: null, version: 0 },
  hoverKeyCheckName = "organisationName",
  backupType = "H",
  config = {
    default: DEFAULT_CONFIG,
  },
  facilityUniqueId = "organisationId",
}: Props): JSX.Element[] => {
  const configs = generateConfigWithDefaultValues(config, DEFAULT_CONFIG);
  return facilities.map((facility: MarkerRequired) => {
    // console.log(facility);
    const isHovered =
      currentFacilityHover && currentFacilityHover[hoverKeyCheckName] === (facility as any)[hoverKeyCheckName] ? 1 : 0;
    const getConfigValue = (value: [number] | [number, number], isHovered: number): number => {
      return value[isHovered] || value[0];
    };
    const zIndex = isHovered === 1 ? 100 : DEFAULT_ZINDEX;
    // const colour = BRAG_PRESSURE_LEVEL_MAPPING[facility.pressureLevel];
    // const bragOptions = BRAG_COLOURS[colour];
    const typeAcronym =
      facility.markerText ||
      (facility.type
        ? (FACILITY_TYPE_TO_TEXT_MAPPING as any)[facility.type] ||
          (FACILITY_TYPE_TO_TEXT_MAPPING as any)["idMarkerType_" + facility.idMarkerType] ||
          backupType
        : backupType);
    const cBackup = configs[typeAcronym] || configs["default"];
    const markerSetting = facility.markerSetting || {};
    const c = {
      ...cBackup,
      ...markerSetting,
    };
    const iconSettings = {
      text: typeAcronym,
      backgroundColour: facility.colorCodeBg || "#ddd",
      colour: facility.colorCodeFg || "#00112b",
      // ieVersion: ieVersion,
      config: {
        fontSize: getConfigValue(c.fontSize, isHovered),
        strokeWidth: getConfigValue(c.strokeWidth, isHovered),
        width: getConfigValue(c.width, isHovered),
        offset: getConfigValue(c.offset, isHovered),
        boxHeight: getConfigValue(c.boxHeight, isHovered),
        radius: getConfigValue(c.radius, isHovered),
      },
    };
    const ICON_WIDTH = iconSettings.config.width + iconSettings.config.strokeWidth * 2;
    const ICON_HEIGHT =
      iconSettings.config.boxHeight + iconSettings.config.offset + iconSettings.config.strokeWidth * 2;
    return (
      <Marker
        key={facility[facilityUniqueId]}
        name={facility.organisationName}
        type={typeAcronym}
        onMouseover={(props: any, marker: ExpandedMarker, MouseEvent: any) =>
          onMouseover(facility, props, marker, MouseEvent)
        }
        onMouseout={onMouseout}
        onClick={() => !!onFacilityClick && onFacilityClick(facility)}
        position={{ lat: facility.latitude, lng: facility.longitude }}
        optimized={false}
        icon={{
          url: hospitalIconAsDataUri(iconSettings),
          size: [ICON_WIDTH, ICON_HEIGHT], // ie 11
          scaledSize: [ICON_WIDTH, ICON_HEIGHT], // ie 11
          // origin: [0, 0],
          // anchor: [ICON_WIDTH / 2, ICON_HEIGHT]
        }}
        zIndex={zIndex}
      />
    );
  });
};

export default facilitiesList;
