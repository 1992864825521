import React, { useState, useEffect, useLayoutEffect } from "react";
import { accessEnv } from "../../../../access-env";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "google-maps-react";
import GoogleApiWrapper from "../../../../components/map/improved/GoogleApiComponent";
import { LoadingContainer } from "../../../../components/map/shared/loading-container";
import facilitiesList from "../../../../components/map/shared/facilities";
import { boundaries } from "../../../../components/map/shared/boundaries";
import MapTooltip from "../../../../components/map/shared/map-tooltip";
import { DEFAULT_MAP_STYLES, initialCenter } from "../../../../components/map/globals";
import {
  fetchNhsrChildBoundary,
  fetchNhsrParentBoundary,
  fetchNhsrHospitals,
  setNhsrBounds,
} from "../../../../store/actions/maps";
import { INTERNAL_URLS } from "../../../../globals/internal-urls";
import { homeMapSettings, DEFAULT_ZOOM } from "./consts";
import { Loader } from "@tscore/react-components";
import { MapLegend } from "./map-legend";
import { formatToString, momentTimeDateFormat } from "../../../../lang/DateTimeFormats";
// ? TYPES:
import { TFunction } from "i18next";
import { ApplicationState } from "../../../../store/reducers";
import { MapsHospital } from "../../../../store/types/maps-state";
import { ExpandedMarker, ParentFeature } from "../../../../types/google-map";
// import { ApiError } from "../../store/types/api";

type HomeMapProps = { google: any; history: any; t: TFunction };

const HomeMap: React.FC<HomeMapProps> = ({ google, history, t }) => {
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [isSetupLoading, setIsSetupLoading] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [currentFacilityHover, setCurrentFacilityHover] = useState<ExpandedMarker | null>(null);
  const [currentParentHover, setCurrentParentHover] = useState<ParentFeature<any, any> | null>(null);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM - 1);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchInitial() {
      // for (const item of [fetchResilienceBoundary, fetchResilienceHospitals]) {
      //   await dispatch(item());
      // }
      await dispatch(fetchNhsrChildBoundary());
      await dispatch(fetchNhsrParentBoundary());
      await dispatch(fetchNhsrHospitals());
    }
    fetchInitial();
  }, [dispatch]);
  const state = useSelector((state: ApplicationState) => state.mapsReducer);
  const { isFetching, nhsrHome } = state;
  useLayoutEffect(() => {
    // if (isSetupLoading === true && !isFetching["GET_NHSR_HOSPITALS"] && !isFetching["GET_NHSR_CHILD_BOUNDARY"]) {
    if (isSetupLoading === true && !isFetching["GET_NHSR_HOSPITALS"] && !!google) {
      dispatch(setNhsrBounds(google));
      setIsSetupLoading(false);
    }
  }, [dispatch, isSetupLoading, setIsSetupLoading, isFetching, google]);
  // console.log({ resilienceHome });
  const isLoading =
    isMapLoading ||
    isFetching["GET_NHSR_PARENT_BOUNDARY"] ||
    isFetching["GET_NHSR_CHILD_BOUNDARY"] ||
    isFetching["GET_NHSR_HOSPITALS"];

  // ? MARKERS ==========================================================
  const onHospitalClick = (marker: MapsHospital) => {
    if (!marker.dashboardId || !marker.dashboardViewAccess) {
      return false;
    }
    const siteId = marker.siteId ? marker.siteId : "primary";
    history.push({
      pathname: `${INTERNAL_URLS.nhsrsystemDashboardList}/${marker.dashboardId}/${siteId}/?origin=home`,
    });
  };
  return (
    // <>
    <div id="map" className="google-map shadowed" style={{ height: "100%" }}>
      <Map
        {...DEFAULT_MAP_STYLES}
        google={google}
        // onMouseout={this.onMapMouseOut}
        // onMousemove={this.onMapMouseMove}
        onDragstart={() => setIsDragging(true)}
        onDragend={() => setIsDragging(false)}
        onReady={() => setIsMapLoading(false)}
        onZoomChanged={() => {
          if (zoom !== DEFAULT_ZOOM && nhsrHome.bounds !== undefined) {
            if (accessEnv("DEPLOYMENT_TYPE", "uk") === "uk") {
              setZoom(DEFAULT_ZOOM);
            }
          }
        }}
        // onIdle={() => setZoom(8)}
        initialCenter={initialCenter}
        {...homeMapSettings}
        bounds={!isLoading ? nhsrHome.bounds : undefined}
        zoom={zoom}>
        {isLoading && <Loader placement="top left" />}
        {facilitiesList({
          facilities: nhsrHome.hospitals,
          onMouseover: (facility: any, _props: any, _marker: ExpandedMarker, _MouseEvent: any) =>
            setCurrentFacilityHover(facility),
          onMouseout: () => setCurrentFacilityHover(null),
          currentFacilityHover: currentFacilityHover,
          onFacilityClick: onHospitalClick,
        })}
        {/* {boundaries({
          polygonCosmetics: {
            // fillColor: "#4D77C9",
            // strokeColor: "#4D77C9",
            strokeWeight: 1,
          },
          // opacityList: {
          //   ON_HOVER: 0.9,
          //   DEFAULT: 0.3,
          //   OTHER_HOVERED: 0.2,
          // },
          childrenColours: nhsrHome.colours.child,
          children: nhsrHome.boundary.child,
          currentChildHover: currentChildHover,
          onMouseout: () => setCurrentChildHover(null),
          onMousemove: (feature) => setCurrentChildHover(feature),
        })} */}
        {boundaries({
          childLabelKey: "parentName",
          polygonCosmetics: {
            // fillColor: "transparent",
            strokeColor: "#000000",
            strokeWeight: 1,
          },
          // opacityList: 1,
          childrenColours: Object.values(nhsrHome.colours.parent),
          children: nhsrHome.boundary.parent,
          // zIndex: 3,
          // clickable: true,
          currentChildHover: currentParentHover,
          onMousemove: (feature) => setCurrentParentHover(feature as ParentFeature<any, any>),
          onMouseout: () => setCurrentParentHover(null),
        })}
        <MapTooltip visible={!!currentFacilityHover || !!currentParentHover} isDragging={isDragging}>
          <div>
            {currentFacilityHover && (
              <>
                <div>
                  <span>{t("map:Hospital")}: </span>
                  <strong>{currentFacilityHover.organisationName}</strong>
                </div>
              </>
            )}
            {currentFacilityHover && currentParentHover && <hr className="mt8 mb8" />}
            {currentParentHover?.properties && (
              <>
                <div>
                  <span></span>
                  <strong>{currentParentHover.properties.parentName}</strong>
                </div>
                {/* <div>
                  <span>{t("map:CCG")}: </span>
                  <strong>{currentChildHover?.properties.ccg19nm || currentFacilityHover?.ccg19nm}</strong>
                </div> */}
                <div>
                  <span>{t("map:Last Updated")}: </span>
                  <span>
                    {formatToString(
                      nhsrHome.colours.parent[currentParentHover.properties.parentName]?.meta?.currentValueTimestamp,
                      momentTimeDateFormat,
                      "N/A"
                    )}
                    {/* {currentChildHover?.meta.currentValueTimestamp
                      ? formatToString(currentChildHover?.meta.currentValueTimestamp, momentTimeDateFormat)
                      : "N/A"} */}
                  </span>
                </div>
              </>
            )}
          </div>
        </MapTooltip>
        <MapLegend />
        {/* <ErrorsComponent errors={this.state.hasErrors} onClose={this.resetErrors} /> */}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_API_GOOGLE_MAP_KEY,
  LoadingContainer: LoadingContainer,
  libraries: [],
})(HomeMap);
