import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { FACILITY_ICON_SIZE, FACILITY_FONT_SIZE, FACILITY_RADIUS } from "../settings";
// import { detectIE } from "../../../helpers/detect-ie";
interface Props {
  backgroundColour: string;
  text: string;
  colour: string;
  // ieVersion?: { type: "ie" | "edge" | null; version: number };
  config: {
    fontSize?: number;
    strokeWidth?: number;
    width?: number;
    offset?: number;
    boxHeight?: number;
    radius?: number;
  };
}

// const FONT_SIZE = 9;
// const STROKE_WIDTH = FACILITY_ICON_SIZE.STROKE_WIDTH;
const textStyles = (fontSize = FACILITY_FONT_SIZE) => ({
  fontFamily: "sans-serif",
  fontSize: `${fontSize}px`,
  textAnchor: "middle" as const,
  dominantBaseline: "hanging" as const,
});

// const getFontHeight = (fontSize: number): number => {
//   return Math.floor(fontSize * 1.5);
// };

export const topBoxPath = (params: { width: number; height: number; offset: number; radius: number }): string => {
  const top = -params.offset - params.height;
  return `M 0,0
L ${-params.offset},${-params.offset} 
H ${-params.width / 2 + params.radius} 
Q ${-params.width / 2},${-params.offset}  
${-params.width / 2},${-params.offset - params.radius} 
V ${top + params.radius} 
Q ${-params.width / 2},${top}  
${-params.width / 2 + params.radius},${top}
H ${params.width / 2 - params.radius}
Q ${params.width / 2},${top}  
${params.width / 2},${top + params.radius} 
V ${-params.offset - params.radius}
Q ${params.width / 2},${-params.offset}  
${params.width / 2 - params.radius},${-params.offset}
H ${params.offset} z`;
};

export const HospitalIcon = ({
  backgroundColour = "#232A3F",
  text = "?",
  colour = "white",
  // ieVersion = { type: null, version: 0 },
  config: {
    fontSize = FACILITY_FONT_SIZE,
    strokeWidth = FACILITY_ICON_SIZE.STROKE_WIDTH,
    width = FACILITY_ICON_SIZE.WIDTH,
    offset = FACILITY_ICON_SIZE.BOX_OFFSET,
    boxHeight = FACILITY_ICON_SIZE.BOX_HEIGHT,
    radius = FACILITY_RADIUS,
  },
}: Props): JSX.Element => {
  const height = offset + boxHeight;
  const d = topBoxPath({
    width: width,
    height: boxHeight,
    offset: offset,
    radius: radius,
  });
  const textY = (boxHeight + strokeWidth * 2 - fontSize / 2) / 2;
  // const additionalIETextYOffset = ((window as any).browser || detectIE(false)).version >= 1 ? textY : undefined;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width + strokeWidth * 2} ${height + strokeWidth * 2}`}
      width={width + strokeWidth * 2}
      height={height + strokeWidth * 2}>
      <g>
        <path
          transform={`translate(${width / 2 + strokeWidth},${height + strokeWidth})`}
          d={d}
          fill={backgroundColour}
          stroke={colour}
          strokeWidth={strokeWidth}></path>
        <text
          x={width / 2 + strokeWidth}
          y={textY}
          // dy={additionalIETextYOffset}
          style={textStyles(fontSize)}
          fill={colour}>
          {text}
        </text>
      </g>
    </svg>
  );
};

export const hospitalIconAsDataUri = (props: Props): string => {
  //   encodeURIComponent(
  //     renderToStaticMarkup(<HospitalIcon {...props} />)
  //       .replace(/"/g, "'")
  //       .replace(/(\r\n|\n|\r)/gm, "")
  //   )
  const svgString = encodeURIComponent(renderToStaticMarkup(<HospitalIcon {...props} />));
  return `data:image/svg+xml,${svgString}`;
};
