import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { navigateToPage } from "../../helpers/change-page";

export function useChangePage() {
  const location = useLocation();
  const history = useHistory();

  const changePage = useCallback(
    (p: number) => {
      navigateToPage(history, location.search, p);
    },
    [history, location.search]
  );
  const pageChanging = (_page: number): boolean => {
    return true;
  };
  return { changePage, pageChanging };
}
