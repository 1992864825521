import { WheelInput } from "../types/index";

export const emptyWheel: WheelInput[] = [
  {
    unitId: -1,
    siteId: -1,
    name: "Error",
    description: "",
    siteName: "Unknown",
    // idPdSite: -1,
    // idLabel: -1,
    // shared: false,
    // hasAlert: false,
    // weightCalculation: 1,
    weightage: null,
    // alertLevel: 4,
    // orderId: 1,
    currentLevel: 0,
    colorCodeFg: "#000000",
    colorCodeBg: "#ffffff",
    childUnitList: [
      {
        unitId: -2,
        siteId: -1,
        name: "Something went wrong",
        description: "",
        siteName: "Unknown",
        // idPdSite: -1,
        // idLabel: -1,
        // shared: false,
        // hasAlert: false,
        // weightCalculation: 0,
        weightage: 1,
        // alertLevel: 4,
        // orderId: 3,
        currentLevel: 0,
        colorCodeFg: "#000000",
        colorCodeBg: "#ffffff",
        childUnitList: null,
        escalated: false
      }
    ],
    escalated: false
  }
];
