import { IndicatorManual } from "../../../types/indicator";
import { UnsavedIndicators } from "../../types/manual-updates-state";

type Indicator = IndicatorManual;

export const updateIndicator = (array: Indicator[], indicator: Indicator): Indicator[] => {
  return array.map((item) => {
    if (item.indicatorId !== indicator.indicatorId) {
      // ? This isn't the item we care about - keep it as-is
      return item;
    }

    // ? Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...indicator,
    };
  });
};

export const updateUnsaved = (unsaved: UnsavedIndicators, indicator: Indicator): UnsavedIndicators => {
  // if (!unsaved) {
  //   unsaved = new Map();
  // }
  const isItInMap = !!unsaved[indicator.indicatorId];
  if (indicator.newValue !== "" && typeof indicator.newValue !== "undefined") {
    // ? add to unsaved
    // unsaved.set(indicator.indicatorId, indicator);
    return { ...unsaved, [indicator.indicatorId]: indicator };
  } else if ((typeof indicator.newValue === "undefined" || indicator.newValue === "") && isItInMap) {
    // ? remove from unsaved
    // unsaved.delete(indicator.indicatorId);
    const { [indicator.indicatorId]: _, ...rest } = unsaved;
    return rest;
    // return unsaved.filter((item) => item !== indicator.indicatorId);
  }
  return unsaved;
};
