import React from "react";
import { selectComponents, Select, Input } from "@tscore/react-components";
// ? TYPES:
import { FormikProps } from "formik";
import { PhoneCountryItem, PhoneCodeList } from "../../../../types/phone-code";

interface FormikMobileProps {
  mobilePlaceholder?: string;
  codeName?: string;
  mobileName?: string;
  codePlaceholder?: string;
  phoneCodeList: PhoneCodeList;
  maxMenuHeight?: number;
  setFieldValue: FormikProps<any>["setFieldValue"];
  // onKeyDown?: (e: any) => void;
  values: {
    phoneCountryCode: Partial<PhoneCountryItem> | null;
    mobile?: string | null;
    [k: string]: any;
  };
  [k: string]: any;
}

const SingleValuePhoneCode = (props: any) => (
  <selectComponents.SingleValue {...props}>{props.data.code}</selectComponents.SingleValue>
);

export const MobileInput: React.FC<FormikMobileProps> = ({
  mobilePlaceholder,
  codeName = "phoneCountryCode",
  mobileName = "mobile",
  codePlaceholder = "Code",
  maxMenuHeight = 200,
  phoneCodeList,
  values,
  setFieldValue,
  // onKeyDown,
  // ...mobileProps
}) => {
  return (
    <div className="mobile__holder">
      <Select
        menuPlacement="auto"
        maxMenuHeight={maxMenuHeight}
        placeholder={codePlaceholder}
        getOptionLabel={(option: PhoneCountryItem) => `(${option.code}) ${option.country}`}
        getOptionValue={(option: PhoneCountryItem) => option.code}
        components={{ SingleValue: SingleValuePhoneCode }}
        options={phoneCodeList}
        // isClearable
        // setFieldValue={setFieldValue}
        onChange={(selectedOption: PhoneCountryItem) => setFieldValue(codeName, selectedOption)}
        value={values.phoneCountryCode}
        name={codeName}
      />
      <Input
        name={mobileName}
        autoComplete="off"
        value={values.mobile ?? ""}
        onChange={(e: any) => setFieldValue(mobileName, e.target.value)}
        // {...mobileProps}
        placeholder={mobilePlaceholder}
      />
    </div>
  );
};
