import { mapSettings } from "../../../../components/map/globals";
// ? TYPES:
import { MapOptions } from "../../../../types/google-map";
import { TConfig } from "../../../../components/map/shared/facilities";

export const DEFAULT_ZOOM = 8;

export const homeMapSettings: MapOptions = {
  ...mapSettings,
  controlSize: 12,
  disableDefaultUI: true,
  fullscreenControl: false,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
};

export const MARKER_CONFIG: { default: TConfig; [typeAcronym: string]: TConfig } = {
  default: { fontSize: [19], width: [50], boxHeight: [34], strokeWidth: [2, 4], offset: [13] },
  I: { fontSize: [19], width: [50], boxHeight: [36], strokeWidth: [2, 4], offset: [10], radius: [16] },
};

export const REROUTE_CONFIG = {
  SERVICE_NAME: "vantage-service",
  LIST_NAME: "vantageMenuList",
} as const;
