import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ActionTable } from "./modal-escalation-actions/action-table";
import { useTranslation } from "react-i18next";
// ? TYPES:
import { ActionList, Action } from "../../store/types/escalation-state";
import { ApplicationState } from "../../store/reducers";
import { EscalationUser } from "../../store/types/escalation-state";

interface ModalEscalationUsersProps {
  organisation: ActionList[string];
  pdSiteId: number;
}

export const ModalEscalationUsers: React.FC<ModalEscalationUsersProps> = ({ organisation, pdSiteId }) => {
  const { t } = useTranslation();
  const [selectedAction, setSelectedAction] = useState<{
    [indicatorId: string]:
      | Action
      | {
          usersAssigned: EscalationUser[];
          id: number;
          indicatorId: number;
          [other: string]: any;
        };
  }>({});
  const onSelect = (action: Action) => {
    setSelectedAction((prevState) => {
      return {
        ...prevState,
        [action.indicatorId]: action,
      };
    });
  };
  const credentials = useSelector((state: ApplicationState) => state.credentialsReducer.app_user_info);
  return (
    <div>
      <header>
        <h4>
          {t("dashboards:escalationBarTitlePrefix")} {organisation.labelName}
        </h4>
      </header>
      <article style={{ display: "block" }}>
        {Object.values(organisation.indicators).map((indicator) => (
          <div className="escalation-users-row" key={indicator.indicatorId}>
            <div className="hide-4-5-column escalation-users-table__holder" style={{ width: "60%" }}>
              <ActionTable
                selectedFns={{
                  onSelect: onSelect,
                  selectedAction: selectedAction[indicator.indicatorId],
                }}
                pdSiteId={pdSiteId}
                credentials={credentials}
                // actions={indicator.actions}
                actionSelectorKeys={`liveActionList.${organisation.labelId}.indicators.${indicator.indicatorId}.actions`}
                // usersAssigned={[]}
                showUsersAssigned={false}
              />
            </div>
            <div className="escalation-users-user-table__holder" style={{ width: "40%" }}>
              {selectedAction[indicator.indicatorId] && (
                <>
                  <span
                    className="user-table-vertical-line"
                    style={{ maxHeight: `${indicator.actions.length * 100 + 39}px` }}
                  />
                  <table className="table bordered main">
                    <thead>
                      <tr>
                        <th>{t("dashboards:escalationUsersTable.0")}</th>
                        <th>{t("dashboards:escalationUsersTable.1")}</th>
                        <th className="nowrap">{t("dashboards:escalationUsersTable.2")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedAction[indicator.indicatorId].usersAssigned.map((user) => (
                        <tr key={user.id} className={user.id === credentials.userId ? "active-user-row" : undefined}>
                          <td>{user.fullname}</td>
                          <td>{user.mobileNumber}</td>
                          <td>{user.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        ))}
      </article>
    </div>
  );
};
