import { apiAction } from "../api";
import { SERVICE_URLS } from "../../../globals/service-urls";
import { generateParams } from "../../../helpers/generate-params";
import SystemDashboardTransform from "./system-dashboard.transform";
import { getLocalDateStr } from "../../../lang/date-fns";
// import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";

// ? TYPES:
import { ColorCalculation, GetTablesResponse } from "../../types/system-dashboard-state";
import { ApiCallback, ApiAction } from "../../types/api";
// import { PaginatedParams } from "../../../types/paginated-params";

type ProductName = "Resilience" | "Region";

const getSiteDials = (
  { systemDashboardId, product }: { systemDashboardId: number; product?: ProductName },
  callbacks: ApiCallback,
  apiVersion = 1
): ApiAction => {
  const params = { product };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["system-dashboard-service"]}${apiVersion}/systemDashboard/${systemDashboardId}${generateParams(
      params
    )}`,
    ...callbacks,
  });
};

const getDialInfo = (
  { systemDashboardId, siteId, product }: { systemDashboardId: number; siteId: number; product?: ProductName },
  callbacks: ApiCallback,
  apiVersion = 1
): ApiAction => {
  const params = { product };
  return apiAction({
    method: "GET",
    url: `${
      SERVICE_URLS["system-dashboard-service"]
    }${apiVersion}/systemDashboard/${systemDashboardId}/site/${siteId}${generateParams(params)}`,
    ...callbacks,
  });
};

const getTables = (
  {
    systemDashboardId,
    siteId,
    innerUnitId,
    product,
  }: { systemDashboardId: number; siteId: number; innerUnitId: number; product?: ProductName },
  callbacks: ApiCallback<GetTablesResponse>,
  apiVersion = 1
): ApiAction => {
  const triggerDateList = [getLocalDateStr()];
  const params = { product, extended: true, triggerDate: triggerDateList };
  return apiAction({
    method: "GET",
    url: `${
      SERVICE_URLS["system-dashboard-service"]
    }${apiVersion}/systemDashboard/${systemDashboardId}/site/${siteId}/unit/${innerUnitId}${generateParams(params)}`,
    transformResponse: SystemDashboardTransform.getTables,
    ...callbacks,
  });
};

const putUpdateIndicatorWeight = (
  {
    systemDashboardId,
    values,
    product,
  }: {
    systemDashboardId: number;
    values: {
      indicatorId: number;
      type: "indicator";
      unitId: number;
      weight: number;
    };
    product?: ProductName;
  },
  callbacks: ApiCallback,
  apiVersion = 1
): ApiAction => {
  const params = { product };
  return apiAction({
    method: "PUT",
    url: `${
      SERVICE_URLS["system-dashboard-service"]
    }${apiVersion}/systemDashboard/${systemDashboardId}/changeIndicatorWeight${generateParams(params)}`,
    data: values,
    ...callbacks,
  });
};

const downloadIndicatorOverviewReport = (
  { pdUnitIdList }: { pdUnitIdList: number[] },
  callbacks: ApiCallback,
  onDownloadProgress?: Function,
  apiVersion = 1
): ApiAction => {
  const params = { pdUnitIdList };
  return apiAction({
    method: "GET",
    url: `${
      SERVICE_URLS["system-dashboard-service"]
    }${apiVersion}/systemDashboard/indicator-overview-report${generateParams(params)}`,
    responseType: "blob",
    ...(onDownloadProgress && { onDownloadProgress: (e: any) => onDownloadProgress(e) }),
    ...callbacks,
  });
};

const forceColorCalculationBatch = (
  { siteIdList }: { siteIdList: number[] },
  callbacks: ApiCallback<ColorCalculation[]>,
  apiVersion = 1
): ApiAction => {
  const params = { siteIdList };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["system-dashboard-service"]}${apiVersion}/systemDashboard/color-calculation${generateParams(
      params
    )}`,
    ...callbacks,
  });
};

const SystemDashboardService = {
  getSiteDials,
  getDialInfo,
  getTables,
  putUpdateIndicatorWeight,
  downloadIndicatorOverviewReport,
  forceColorCalculationBatch,
};

export default SystemDashboardService;
