import EscalationService from "./escalation.service";
import { cancelRequest } from "../../../helpers/cancel-request";
// ? TYPES:
import { ApiAction } from "../../types/api";
import { Action } from "../../types/escalation-state";
import { ApplicationState } from "../../../store/reducers";
import { DashboardStatus } from "../../types/system-dashboard-state";

const getLiveSystemActions = (
  pdSiteId: number,
  data: { actionList: Action[]; escalatedPdUnitIds: number[] },
  systemDashboardId: number | undefined
) => ({
  type: "GET_LIVE_SYSTEM_ACTIONS",
  pdSiteId,
  systemDashboardId,
  data,
});

const getMyLiveActions = (pdSiteId: number | "all", data: any, systemDashboardId: number | undefined) => ({
  type: "GET_MY_LIVE_ACTIONS",
  pdSiteId,
  systemDashboardId,
  data,
});

const getEscalationPlans = (pdSiteId: number, data: any) => ({
  type: "GET_ESCALATION_PLANS",
  pdSiteId,
  data,
});

const downloadEscalationPlanReport = (systemDashboardId: number, pdSiteId: number, fileName: string, data: string) => ({
  type: "DOWNLOAD_ESCALATION_PLAN_REPORT",
  systemDashboardId,
  pdSiteId,
  fileName,
  data,
});

const downloadEscalationHistoryReport = (
  pdSiteId: number,
  startDate: string,
  endDate: string,
  fileName: string,
  data: string
) => ({
  type: "DOWNLOAD_ESCALATION_HISTORY_REPORT",
  pdSiteId,
  startDate,
  endDate,
  fileName,
  data,
});

const getEscalationHistory = (pdSiteId: number, data: any) => ({
  type: "GET_ESCALATION_HISTORY",
  pdSiteId,
  data,
});

const showEscalationBar = (pdSiteId: number, data: any, unitId: number) => ({
  type: "SHOW_ESCALATION_BAR",
  pdSiteId,
  unitId,
  data,
});

const fetchShowEscalationBar = (pdSiteId: number, unitId: number) => {
  return EscalationService.getLiveSystemBySegments(
    { pdUnitIds: [unitId] },
    {
      label: "SHOW_ESCALATION_BAR",
      onSuccess: (response: any) => showEscalationBar(pdSiteId, response, unitId),
      // onFailure: () => console.log("Error occured loading articles"),
      other: { pdSiteId, unitId },
    }
  );
};

export const fetchShowEscalationBarIfNeeded = (pdSiteId: number, status: { [name: string]: DashboardStatus }) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const escalationState = getState().escalationReducer[pdSiteId] || getState().escalationReducer[-1];
    const escalatedUnits = escalationState.escalatedPdUnitIds;
    // const activeUnitIds = Object.values(status).reduce(
    //   (final: number[], item: DashboardStatusImported<DashboardStatusImported>) => {
    //     const children = Object.values(item.children);
    //     for (let i = 0; i < children.length; i += 1) {
    //       const unit = children[i];
    //       if (unit.active && escalatedUnits.includes(unit.unitId)) {
    //         final = [unit.unitId, ...final];
    //       }
    //     }
    //     return final;
    //   },
    //   []
    // );
    const activeUnitIds = Object.values(status)
      .reduce((final: number[], unit) => {
        const id = unit.unitId || -1;
        if (unit.active && escalatedUnits.includes(id)) {
          final = [id, ...final];
        }
        return final;
      }, [])
      // const activeUnitIds = activeSegmentIds
      //   .filter((id) => escalatedUnits.includes(id))
      .filter((id) =>
        escalationState.unitToLabelMap[id] || escalationState.fetchingEscalationUnits[id] ? false : true
      );
    for (let i = 0; i < activeUnitIds.length; i += 1) {
      const id = activeUnitIds[i];
      dispatch(fetchShowEscalationBar(pdSiteId, id));
    }
    //
  };
};

const updateEscalationActions = (
  pdSiteId: number,
  data: any,
  labelId: number,
  indicatorId: number,
  inputValues: any
) => ({
  type: "UPDATE_ESCALATION_ACTIONS",
  pdSiteId,
  data,
  labelId,
  indicatorId,
  inputValues,
});

export const fetchUpdateActions = (
  pdSiteId: number,
  values: { id: number; status: string; comment?: string }[],
  labelId: number,
  indicatorId: number
): ApiAction => {
  return EscalationService.updateActionBatch(
    { list: values },
    {
      label: "UPDATE_ESCALATION_ACTIONS",
      onSuccess: (response: any) => updateEscalationActions(pdSiteId, response, labelId, indicatorId, values),
      other: { pdSiteId },
    }
  );
};

export const fetchLiveSystemActions = (pdSiteId: number, systemDashboardId?: number): ApiAction => {
  return EscalationService.getLiveSystemActions(
    { pdSiteId },
    {
      label: "GET_LIVE_SYSTEM_ACTIONS",
      onSuccess: (response: any) => getLiveSystemActions(pdSiteId, response, systemDashboardId),
      // onFailure: () => console.log("Error occured loading articles"),
      other: { pdSiteId },
    }
  );
};

export const fetchMyLiveActions = (pdSiteId: number | "all", systemDashboardId?: number): ApiAction => {
  cancelRequest("GET_MY_LIVE_ACTIONS");
  return EscalationService.getMyLiveActions(
    { pdSiteId: pdSiteId === "all" ? undefined : pdSiteId },
    {
      label: "GET_MY_LIVE_ACTIONS",
      onSuccess: (response: any) => getMyLiveActions(pdSiteId, response, systemDashboardId),
      // onFailure: () => console.log("Error occured loading articles"),
      other: { pdSiteId },
    }
  );
};

export const fetchEscalationPlans = (pdSiteId: number, pdUnitIds?: number[]): ApiAction => {
  cancelRequest("GET_ESCALATION_PLANS");
  return EscalationService.getEscalationPlans(
    {
      pdSiteId,
      pdUnitIds,
    },
    {
      label: "GET_ESCALATION_PLANS",
      onSuccess: (response: any) => getEscalationPlans(pdSiteId, response),
      other: { pdSiteId, pdUnitIds },
    }
  );
};

export const fetchDownloadEscalationPlanReport = (
  systemDashboardId: number,
  pdSiteId: number,
  fileName: string
): ApiAction => {
  return EscalationService.downloadEscalationPlanReport(
    { systemDashboardId, pdSiteId },
    {
      label: "DOWNLOAD_ESCALATION_PLAN_REPORT",
      onSuccess: (response: any) => downloadEscalationPlanReport(systemDashboardId, pdSiteId, fileName, response),
      other: { systemDashboardId, pdSiteId },
    },
    undefined
  );
};

export const fetchEscalationHistory = (pdSiteId: number, startDate: string, endDate: string): ApiAction => {
  return EscalationService.getEscalationHistory(
    { pdSiteId, startDate, endDate },
    {
      label: "GET_ESCALATION_HISTORY",
      onSuccess: (response: any) => getEscalationHistory(pdSiteId, response),
      // onFailure: () => console.log("Error occured loading articles"),
      other: { pdSiteId },
    }
  );
};

export const fetchDownloadEscalationHistoryReport = (
  pdSiteId: number,
  startDate: string,
  endDate: string,
  fileName: string
): ApiAction => {
  return EscalationService.downloadEscalationHistoryReport(
    { pdSiteId, startDate, endDate },
    {
      label: "DOWNLOAD_ESCALATION_HISTORY_REPORT",
      onSuccess: (response: any) => downloadEscalationHistoryReport(pdSiteId, startDate, endDate, fileName, response),
      other: { pdSiteId },
    },
    undefined
  );
};
