// const d3 = Object.assign({}, require("d3"), require("d3-selection-multi")); //TODO: import?
// import * as d3 from "d3";
import d3 from "../cherryPickD3";
// import "d3-selection-multi";
// import * as jsdom from "jsdom";
import { wrap, generateWheelData, LINE_HEIGHT, setClasses, generateId, emToPx } from "../helpers";
import { emptyWheel } from "./emptyWheel";
// import map from "lodash/map";

// import { DEBUG_saveToFile } from "../helpers/debugSaveToFile";

import { WheelQuery, UnitsData, OuterCircleData, ChildSegment, InnerCircleData, WheelInput, D3DataWrapper } from "../types/index";
type D3Selection = any; //d3.Selection<SVGGElement | any, unknown, null, undefined>;


// import css from "../sass/main.scss";
// const css = require.resolve("../sass/main.scss").toString();

import css from '../helpers/css.js';

import wheelScripts from "../raw/wheelScripts.raw.js";
import polyfills from "../raw/polyfills.raw.js";

const js = (callbackFn, isReactOutput, scriptPrefixSuffix, includeIEPollyfills) => `${!isReactOutput ? scriptPrefixSuffix.prefix : ""}
  ${includeIEPollyfills ? polyfills : ""}
  ${wheelScripts}
  ${callbackFn}
${!isReactOutput ? scriptPrefixSuffix.suffix : ""}
`;

const outer_text_spacing = 20; //spacing for fitting the outermost text
const levels = [20, 40, 68, 100]; //levels for the segments
const border_color = "#D2DFEA";
const border_width = 1;

const BASE_SPACING_INNER_TEXT = 65;
const BASE_OUTER_FONT_SIZE = 14; // px
const BASE_INNER_FONT_SIZE = 16; // px
const BASE_SIZE = 600; // px
// const MAX_LENGTH_LINE = 12; // letter length
// const ESCALATION_ICON_Y_OFFSET = -34; // px

const CHILDREN_JOIN = "^%!";

const pie = d3
  .pie()
  .sort(null)
  .value(({ sections }: any) => sections);
const pie_with_gap = d3
  .pie()
  .sort(null)
  //            .padAngle(.1)
  .value(({ sections }: any) => sections);

const isValid = (data: WheelInput[]): boolean => {
  // TODO: different checks
  if (!data || data.constructor !== Array || data.length === 0) {
    return false;
  }
  return true;
};

const buildWheel = (el: HTMLElement, data: WheelInput[], query: WheelQuery, isReactOutput: boolean = false, scriptPrefixSuffix = {
  prefix: "//<![CDATA[",
  suffix: "//]]>"
}, includeIEPollyfills = true): {
  svg: HTMLElement,
  id: string,
  scripts?: string
} => {
  const callbackFn: string = query.callback ? "function TS_WHEEL_CALLBACK(status) {window." + query.callback + "(status);}" : "";
  // var el = ReactFauxDOM.createElement('svg')
  // const d3dom = d3.select(document);
  const siteName = (data[0] || { siteName: "Unknown" }).siteName;
  const id = generateId();
  const size = query.size;
  const radius_outer = size / 2; //radius of outer circle
  const radius = radius_outer / 2; //radius of inner circle
  const radius_outer_thickness = radius_outer * 0.1; //spacing outside the outer band for text
  const spacing_inner_text = (size / BASE_SIZE) * BASE_SPACING_INNER_TEXT;
  const innerFontSize = (size / BASE_SIZE) * BASE_INNER_FONT_SIZE;
  if (!isValid(data)) {
    data = emptyWheel;
    query.isStatic = true;
  }
  const xlmnsXlinkKey = isReactOutput ? "xmlnsXlink" : "xmlns:xlink";
  const svgOuter = d3.select(el)
    .attrs({
      xmlns: "http://www.w3.org/2000/svg",
      [xlmnsXlinkKey]: "http://www.w3.org/1999/xlink",
      width: size,
      height: size,
      "data-site-name": siteName,
      "data-id": id,
      "data-wheel-id": query.wheelId
    });
  svgOuter.append("style").text(css.styles);
  if (query.isStatic === false && isReactOutput === false) {
    svgOuter
      .append("script")
      .attr("type", "text/javascript")
      .text(js(callbackFn, isReactOutput, scriptPrefixSuffix, includeIEPollyfills));
  }
  let svg = svgOuter.append("g").attrs({
    transform: "translate(" + size / 2 + "," + size / 2 + ")",
    "data-static": query.isStatic
  });
  const D = generateWheelData(data, radius, spacing_inner_text, innerFontSize);
  // ? DRAW Initial:
  svg = drawInnerArcs(svg, D.innerCircleData, radius_outer, radius_outer_thickness);
  svg = drawOuterArcs(svg, D.outerCircleData, radius_outer, radius_outer_thickness);
  svg = addData(svg, D.unitsData, D.single_angle, radius);
  svg = drawBorders(svg, D.radialPoints, radius);
  // ? DRAW TEXT:
  if (!query.hideText) {
    svg = drawInnerText(svg, D.innerCircleData, radius, spacing_inner_text, size, innerFontSize);
    if (query.showEscalation) {
      svg = drawEscalationIcons(svg, D.innerCircleData, radius, spacing_inner_text, size);
    }
    svg = drawOuterText(svg, D.outerCircleData, radius_outer, radius_outer_thickness, size);
  }
  // ? DRAWING HOVERS/CLICK EVENTS:
  svg = drawInnerHoverArcs(svg, D.innerCircleData, radius_outer, radius_outer_thickness, query.isStatic, isReactOutput);
  svg = drawOuterHoverArcs(svg, D.outerCircleData, radius_outer, radius_outer_thickness, query.isStatic, isReactOutput);

  // ? Return svg object:
  // const $svg = d3dom.select("body").html();
  // DEBUG_saveToFile("debug/test.svg", $svg);
  return {
    svg: el,
    id: id,
    ...(isReactOutput && !query.isStatic && { scripts: js(callbackFn, isReactOutput, scriptPrefixSuffix, includeIEPollyfills)})
  };
};

const drawInnerArcs = (svg: D3Selection, innerCircleData: InnerCircleData[], radius_outer: number, radius_outer_thickness: number): D3Selection => {
  const innerArcBg = d3
    .arc()
    .outerRadius(radius_outer - radius_outer_thickness - outer_text_spacing)
    .innerRadius(0);
  const g_innerArcs = svg
    .append("g")
    .attr("class", "innerArcs")
    .selectAll(".section")
    .data(pie(innerCircleData as any))
    .enter()
    .append("g")
    .attrs({
      class: "section"
    });

  g_innerArcs.append("path").attrs({
    d: innerArcBg,
    "data-inner-id": (d: D3DataWrapper<InnerCircleData>) => d.data.unitId,
    "data-inner-title": (d: D3DataWrapper<InnerCircleData>) => d.data.title,
    "data-title": (d: D3DataWrapper<InnerCircleData>) => d.data.parent_name,
    "data-unit-id": (d: D3DataWrapper<InnerCircleData>) => d.data.unitId
  });
  return svg;
};

const drawInnerText = (svg: D3Selection, innerCircleData: InnerCircleData[], radius: number, spacing_inner_text: number, size: number, fontSize: number): D3Selection => {
  const fullArc = d3.arc().outerRadius(radius);
  // const textHeightPx = fontSize + 3;
  // const textHeightPoint = (textHeightPx * 3) / 4;
  // const textHeightEm = textHeightPoint / 12;
  svg
    .append("g")
    .attr("class", "inner_labels")
    .selectAll("text")
    .data(pie(innerCircleData as any))
    .enter()
    .append("text")
    .attrs({
      "data-inner-id": (d: D3DataWrapper<InnerCircleData>) => d.data.unitId,
      "data-inner-title": (d: D3DataWrapper<InnerCircleData>) => d.data.title,
      "text-anchor": "middle",
      // dy: textHeightEm + "em",
      dy: 0,
      y: 0,
      transform: (d: D3DataWrapper<InnerCircleData>) => "translate(" + generateTransformLabelsOriginCenter(d, fullArc, radius, spacing_inner_text) + ")",
      style: "font-size:" + fontSize + "px;"
    })
    .text((d: D3DataWrapper<InnerCircleData>) => d.data.title);

  svg
    .select("g.inner_labels")
    .selectAll("text")
    .call(wrap);
  return svg;
};

const drawEscalationIcons = (svg: D3Selection, innerCircleData: InnerCircleData[], radius: number, spacing_inner_text: number, size: number): D3Selection => {
  const fullArc = d3.arc().outerRadius(radius);
  svg
    .append("g")
    .attr("class", "escalation_icons")
    .selectAll("text")
    .data(pie(innerCircleData as any))
    .enter()
    .append("path")
    .attrs({
      d: "M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z",
      transform: (d: D3DataWrapper<InnerCircleData>) => {
        const values: number[] = generateTransformLabelsOriginCenter(d, fullArc, radius, spacing_inner_text);
        // const values: number[] = translate.substring(translate.lastIndexOf("(") + 1, translate.lastIndexOf(")")).split(",").map((value: string) => {
        //   return parseInt(value, 10);
        // });
        // 22px icon size (base 17.6px)
        // const lines = d.data.multiTitle;
        const textDy = d.data.multiTitleDy;
        const iconDyPx = emToPx(textDy[0] - LINE_HEIGHT * 2); // not sure why x2
        const scale = size / BASE_SIZE - 0.2;
        const offsetIconY = -3; // (size / BASE_SIZE) * ESCALATION_ICON_Y_OFFSET;
        const offsetIconX = (size / BASE_SIZE) * -10.5;
        return "translate(" + (values[0] + offsetIconX) + "," + (values[1] + offsetIconY + iconDyPx) + ") scale(" + scale + ")";
      },
      opacity: (d: D3DataWrapper<InnerCircleData>) => (d.data.isEscalated ? 1 : 0)
    });
  return svg;
};

const drawOuterArcs = (svg: D3Selection, outerCircleData: OuterCircleData[], radius_outer: number, radius_outer_thickness: number): D3Selection => {
  const _data: D3DataWrapper<OuterCircleData>[] = updatePieWithGap(outerCircleData);
  //drawing outer arcs
  const outerArc = d3
    .arc()
    .outerRadius(radius_outer - outer_text_spacing)
    .innerRadius(radius_outer - outer_text_spacing - radius_outer_thickness);

  const g_outerArcs = svg
    .append("g")
    .attr("class", "outerArcs")
    .selectAll(".arc")
    .data(_data)
    .enter()
    .append("path")
    .attrs({
      "data-title": (d: D3DataWrapper<OuterCircleData>) => d.data.title,
      "data-unit-id": (d: D3DataWrapper<OuterCircleData>) => d.data.unitId,
      "data-children": (d: D3DataWrapper<OuterCircleData>) => {
        return d.data.childIds
          .map((child: ChildSegment) => {
            return child.title;
          })
          .join(CHILDREN_JOIN);
      },
      "data-children-id": (d: D3DataWrapper<OuterCircleData>) => {
        return d.data.childIds
          .map((child: ChildSegment) => {
            return child.id;
          })
          .join(",");
      },
      class: "parent_section",
      d: outerArc,
      fill: (d: D3DataWrapper<OuterCircleData>) => d.data.colorBg,
      stroke: (d: D3DataWrapper<OuterCircleData>) => {
        if (d.data.colorBg === "#ffffff") {
          return "#cccccc";
        } else {
          return "rgba(0,0,0,0.2)";
        }
      }
    });
  return svg;
};

const drawOuterText = (
  svg: D3Selection,
  outerCircleData: OuterCircleData[],
  radius_outer: number,
  radius_outer_thickness: number,
  size: number
): D3Selection => {
  const _data: D3DataWrapper<OuterCircleData>[] = updatePieWithGap(outerCircleData);
  const outerArc1: any = d3.arc().outerRadius(radius_outer - outer_text_spacing - radius_outer_thickness * 0.7);
  const outerArc2: any = d3.arc().outerRadius(radius_outer - outer_text_spacing - radius_outer_thickness * 0.3);
  const g_outerArcsTextPath = svg.append("g").attr("class", "outerArcsTextPath");
  g_outerArcsTextPath
    .selectAll(".arc")
    .data(_data)
    .enter()
    .append("defs")
    .append("path")
    .attrs({
      class: "outerArcsTextPath",
      d: (d: D3DataWrapper<OuterCircleData>) => {
        const _centerAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        if ((_centerAngle > 0 && _centerAngle <= 1.8) || _centerAngle > 4.3) {
          return outerArc1(d).replace("LNaN,NaNZ", "");
          // !
          // return outerArc1(d).replace("L0,0Z", "");
        } else {
          const temp = d.endAngle;
          d.endAngle = d.startAngle;
          d.startAngle = temp;
          return outerArc2(d).replace("LNaN,NaNZ", "");
          // !
          // return outerArc2(d).replace("L0,0Z", "");
        }
      },
      fill: "none",
      stroke: "none",
      id: (d: D3DataWrapper<OuterCircleData>, i: number, j: any) => "arc-label-" + i
    });

  svg
    .select("g.outerArcsTextPath")
    .selectAll("text")
    .data(_data)
    .enter()
    .append("text")
    .attrs({
      class: (d: D3DataWrapper<OuterCircleData>) => setClasses("svglabel", "label_parent_" + d.data.id, "notactive"),
      "text-anchor": "middle"
    })
    .append("textPath")
    .attrs({
      startOffset: "50%",
      "xlink:href": (d: D3DataWrapper<OuterCircleData>, i: number) => "#arc-label-" + i,
      fill: (d: D3DataWrapper<OuterCircleData>) => d.data.colorFg,
      "data-title": (d: D3DataWrapper<OuterCircleData>) => d.data.title,
      style: "font-size:" + (size / BASE_SIZE) * BASE_OUTER_FONT_SIZE + "px;"
    })
    .text((d: D3DataWrapper<OuterCircleData>) => d.data.title.toUpperCase());

  return svg;
};

const addData = (svg: D3Selection, unitsData: UnitsData[], single_angle: number, radius: number): D3Selection => {
  const innerArc = d3
    .arc()
    .startAngle((data: any) => data.id * single_angle)
    .endAngle((data: any) => (data.id + 1) * single_angle)
    .outerRadius((data: any) => (radius * levels[data.level - 1]) / 100)
    .innerRadius((data: any) => {
      if (data.level == 1) return 0;
      else return (radius * levels[data.level - 2]) / 100;
    });
  svg = generateDataFillers(svg, "data_highlight", true, unitsData, innerArc);
  svg = generateDataFillers(svg, "data", false, unitsData, innerArc);

  return svg;
};

const drawBorders = (svg: D3Selection, radialPoints: number[][], radius: number): D3Selection => {
  const borders = svg.append("g").attr("class", "borders");
  const g_circles = borders
    .append("g")
    .attr("class", "circles")
    .selectAll("circle")
    .data(levels)
    .enter()
    .append("circle")
    .attrs({
      cx: 0,
      cy: 0,
      r: (d: number) => (radius * d) / 100,
      fill: "none",
      "stroke-width": border_width,
      stroke: border_color
    });

  borders
    .selectAll("line") //---an empty selection---
    .data(radialPoints)
    .enter()
    .append("svg:line")
    .attrs({
      class: "line",
      x2: (p) => p[0],
      y2: (p) => p[1],
      stroke: border_color
      // x1: center_x,
      // y1: center_y
    });

  return svg;
};

const drawInnerHoverArcs = (
  svg: D3Selection,
  innerCircleData: InnerCircleData[],
  radius_outer: number,
  radius_outer_thickness: number,
  queryStatic: boolean,
  isReactOutput: boolean
): D3Selection => {
  const innerArc = d3
    .arc()
    .outerRadius(radius_outer - radius_outer_thickness)
    .innerRadius(0);

  const g_innerArcs = svg
    .append("g")
    .attr("class", "innerHoverArcs")
    .selectAll(".pie")
    .data(pie(innerCircleData as any))
    .enter()
    .append("path")
    .attrs({
      d: innerArc,
      opacity: 0,
      "data-inner-id": (d: D3DataWrapper<InnerCircleData>) => d.data.unitId,
      "data-inner-title": (d: D3DataWrapper<InnerCircleData>) => d.data.title,
      ...(isReactOutput && !queryStatic && reactInnerInteraction),
      ...(!isReactOutput && !queryStatic && normalInnerInteraction)
    });
  return svg;
};

const drawOuterHoverArcs = (
  svg: D3Selection,
  outerCircleData: OuterCircleData[],
  radius_outer: number,
  radius_outer_thickness: number,
  queryStatic: boolean,
  isReactOutput: boolean
): D3Selection => {
  const outerArc = d3
    .arc()
    .outerRadius(radius_outer)
    .innerRadius(radius_outer - radius_outer_thickness - outer_text_spacing);

  const g_innerArcs = svg
    .append("g")
    .attr("class", "outerHoverArcs")
    .selectAll(".pie")
    .data(pie(outerCircleData as any))
    .enter()
    .append("path")
    .attrs({
      d: outerArc,      opacity: 0,
      "data-title": (d: D3DataWrapper<OuterCircleData>) => d.data.title,
      ...(isReactOutput && !queryStatic && reactOuterInteraction),
      ...(!isReactOutput && !queryStatic && normalOuterInteraction)
    });
  return svg;
};

// ! HELPERS TO BE ORGANISED:

const reactOuterInteraction: any = {
  onClick: (d: D3DataWrapper<OuterCircleData>) => (e: any) => typeof (window as any).outerArcOnClick === 'function' && (window as any).outerArcOnClick(d.data.title,e.target),
  onMouseOver: (d: D3DataWrapper<OuterCircleData>) => (e: any) => typeof (window as any).outerArcOnHover === 'function' && (window as any).outerArcOnHover(d.data.title,e.target),
  onMouseOut: (d: D3DataWrapper<OuterCircleData>) => (e: any) => typeof (window as any).outerArcOnHoverOut === 'function' && (window as any).outerArcOnHoverOut(d.data.title,e.target)
};

const reactInnerInteraction: any = {
  onClick: (d: D3DataWrapper<InnerCircleData>) => (e: any) => typeof (window as any).innerArcOnClick === 'function' && (window as any).innerArcOnClick(d.data.unitId,e.target),
  onMouseOver: (d: D3DataWrapper<InnerCircleData>) => (e: any) => typeof (window as any).innerArcOnHover === 'function' && (window as any).innerArcOnHover(d.data.unitId,e.target),
  onMouseOut: (d: D3DataWrapper<InnerCircleData>) => (e: any) => typeof (window as any).innerArcOnHoverOut === 'function' && (window as any).innerArcOnHoverOut(d.data.unitId,e.target)
};

const normalOuterInteraction: any = {
  onclick: (d: D3DataWrapper<OuterCircleData>) => `typeof outerArcOnClick === 'function' && outerArcOnClick('${d.data.title}',this);`,
  onmouseover: (d: D3DataWrapper<OuterCircleData>) => `typeof outerArcOnHover === 'function' && outerArcOnHover('${d.data.title}',this);`,
  onmouseout: (d: D3DataWrapper<OuterCircleData>) => `typeof outerArcOnHoverOut === 'function' && outerArcOnHoverOut('${d.data.title}',this);`
};

const normalInnerInteraction: any = {
  onclick: (d: D3DataWrapper<InnerCircleData>) => `typeof innerArcOnClick === 'function' && innerArcOnClick('${d.data.unitId}',this);`,
  onmouseover: (d: D3DataWrapper<InnerCircleData>) => `typeof innerArcOnHover === 'function' && innerArcOnHover('${d.data.unitId}',this);`,
  onmouseout: (d: D3DataWrapper<InnerCircleData>) => `typeof innerArcOnHoverOut === 'function' && innerArcOnHoverOut('${d.data.unitId}',this);`
};

const generateTransformLabelsOriginCenter = (
  d: D3DataWrapper<InnerCircleData | OuterCircleData> & {
    outerRadius?: number;
    innerRadius?: number;
  },
  fullArc: any,
  radius: number,
  spacing_inner_text: number // 65
): number[] => {
  // set the label's origin to the center of the arc
  // we have to make sure to set these before calling arc.centroid
  const extraOffset = 30;
  d.outerRadius = radius + spacing_inner_text + extraOffset; // Set Outer Coordinate
  d.innerRadius = radius + spacing_inner_text + extraOffset; // Set Inner Coordinate
  const centroid: number[] = fullArc.centroid(d);
  // console.log({centroid});
  return centroid;
};

const updatePieWithGap = (outerCircleData: OuterCircleData[]): D3DataWrapper<OuterCircleData>[] => {
  return pie_with_gap(outerCircleData as any[]).map((item: any) => {
    return Object.assign(item, {
      startAngle: item.startAngle + 0.008,
      endAngle: item.endAngle - 0.008
    });
  });
};

const generateDataFillers = (svg: D3Selection, className: string, isHidden: boolean, unitsData: UnitsData[], innerArc: any): D3Selection => {
  svg
    .append("g")
    .attr("class", className)
    .selectAll("path")
    .data(unitsData)
    .enter()
    .append("path")
    .attrs({
      "data-level": (d: UnitsData) => d.level,
      "data-inner-id": (d: UnitsData) => d.unitId,
      "data-inner-title": (d: UnitsData) => d.title,
      d: innerArc,
      fill: (d: UnitsData) => d.colorBg,
      opacity: () => (isHidden ? 0 : null)
    });
  return svg;
};

export default buildWheel;
