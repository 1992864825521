import SystemDashboardService from "./system-dashboard.service";
import { cancelRequest } from "../../../helpers/cancel-request";
import debounce from "lodash/debounce";
// ? TYPES:
import { ApiAction, ResponseOf } from "../../types/api";
import { ApplicationState } from "../../../store/reducers";
import {
  DashboardStatus,
  DashboardStatusImported,
  SystemDashboardState,
  ColorCalculation,
} from "../../types/system-dashboard-state";
import { SystemDashboardTable } from "../../../types/system-dashboard-table";
import { Cancelable } from "../../../types/generic";
import { apiError, FAKE_AXIOS_ERROR } from "../api";

// interface DashboardStatusImported {
//   unitId: number;
//   active: boolean;
// }

const findDifference = (
  currentStatus: { [segmentName: string]: DashboardStatus },
  prettyStatus: { [segmentName: string]: DashboardStatus }
) => {
  return Object.entries(prettyStatus).reduce((final: number[], [key, newItem]) => {
    const item = currentStatus[key];
    // console.log({ item });
    if (!item) {
      if (newItem.requestedOn === 0) {
        return final;
      }
      // console.log("pushing");
      final.push(newItem.unitId!);
      return final;
    }
    if (newItem.requestedOn !== item.requestedOn && newItem.requestedOn !== 0) {
      // console.log("its different", newItem.unitId);
      final.push(newItem.unitId!);
    }
    return final;
  }, []);
};

const getProductName = (getState: () => ApplicationState): "Region" | undefined =>
  getState().menuReducer.meta.module === "NHS Region" ? "Region" : undefined;

const DEBOUNCE_TIME_UPDATE_WEIGHT = 2000;

const getSiteDials = (systemDashboardId: number, data: any) => ({
  type: "GET_SITE_DIALS",
  systemDashboardId,
  data,
});

const getDialInfo = (systemDashboardId: number, siteId: number, data: any, preserveEscalation = false) => ({
  type: "GET_DIAL_INFO",
  systemDashboardId,
  siteId,
  data,
  preserveEscalation,
});

const getTables = (
  systemDashboardId: number,
  siteId: number,
  unitId: number,
  data: ResponseOf<typeof SystemDashboardService.getTables>
) => ({
  type: "GET_TABLES",
  systemDashboardId,
  siteId,
  unitId,
  data,
});

export const updateStatus = (systemDashboardId: number, siteId: number, status: DashboardStatus) => ({
  type: "UPDATE_STATUS",
  systemDashboardId,
  siteId,
  status,
});

const updateWeight = (
  systemDashboardId: number,
  unitId: number,
  indicatorId: number,
  weight: number
  // siteId: number,
  // tableId: number,
  // tableRowId: number
) => ({
  type: "UPDATE_WEIGHT",
  systemDashboardId,
  unitId,
  indicatorId,
  weight,
  // siteId,
  // tableId,
  // tableRowId,
});

export const editWeight = (
  systemDashboardId: number,
  siteId: number,
  unitId: number,
  indicatorId: number,
  weight: number,
  tableId: number,
  tableRowId: number,
  oldWeight: number,
  isValid: boolean
) => ({
  type: "EDIT_WEIGHT",
  systemDashboardId,
  siteId,
  unitId,
  indicatorId,
  weight,
  tableId,
  tableRowId,
  oldWeight,
  isValid,
});

const fetchUpdateWeight = (
  systemDashboardId: number,
  indicatorId: number,
  unitId: number,
  weight: number,
  siteId: number,
  tableId: number,
  tableRowId: number,
  forceRefreshDial = true
) => {
  return (dispatch: Function, getState: () => ApplicationState) => {
    dispatch(
      SystemDashboardService.putUpdateIndicatorWeight(
        {
          systemDashboardId,
          values: {
            indicatorId,
            type: "indicator",
            unitId,
            weight,
          },
          product: getProductName(getState),
        },
        {
          label: "UPDATE_WEIGHT",
          onSuccess: (_response: any) => {
            if (forceRefreshDial) {
              dispatch(fetchForceDialsRefreshAndGetDialInfo(systemDashboardId, [siteId]));
            }
            return updateWeight(systemDashboardId, unitId, indicatorId, weight);
          },
          other: { systemDashboardId, unitId, indicatorId, weight, siteId, tableId, tableRowId },
        }
      )
    );
  };
};

const debouncedUpdateWeights: { [indicatorId: string]: () => any & Cancelable } = {};

export const editAndFetchUpdateWeight = (
  systemDashboardId: number,
  siteId: number,
  unitId: number,
  indicatorId: number,
  weight: number,
  tableId: number,
  tableRowId: number,
  isValid: boolean
) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    function getDashboardState() {
      return getState().systemDashboardReducer[systemDashboardId];
    }
    function getSiteStatus(s: SystemDashboardState, siteId: number) {
      return s.status[siteId];
    }
    function findOldWeight(siteStatus: { [unitName: string]: DashboardStatus }, siteName: string) {
      return (siteStatus[siteName] as any)
        .data!.find((table: SystemDashboardTable) => table.tableId === tableId)
        .rowList.find((row: any) => row.tableRowId === tableRowId)
        .indicatorList.find((indicator: any | null) => indicator?.indicatorId === indicatorId).weight;
    }
    const state = getDashboardState();
    const siteStatus = getSiteStatus(state, siteId);
    const [siteName]: [string, DashboardStatus] = Object.entries({ ...siteStatus }).find(
      ([_key, value]) => value.unitId === unitId
    ) || ["-1", {}];

    const oldWeight = findOldWeight(siteStatus, siteName);
    // console.log(oldWeight);
    dispatch(
      editWeight(systemDashboardId, siteId, unitId, indicatorId, weight, tableId, tableRowId, oldWeight, isValid)
    );
    const fnExists = typeof debouncedUpdateWeights[indicatorId] !== undefined ? true : false;
    if (isValid) {
      if (!state.isSavingWeights[indicatorId]) {
        debouncedUpdateWeights[indicatorId] = debounce(
          () => {
            const weightToUpdate = findOldWeight(getSiteStatus(getDashboardState(), siteId), siteName);
            dispatch(
              fetchUpdateWeight(systemDashboardId, indicatorId, unitId, weightToUpdate, siteId, tableId, tableRowId)
            );
          },
          DEBOUNCE_TIME_UPDATE_WEIGHT,
          { leading: false, trailing: true }
        );
      }
      fnExists && debouncedUpdateWeights[indicatorId]();
    } else {
      if (state.isSavingWeights[indicatorId]) {
        fnExists && (debouncedUpdateWeights[indicatorId] as any).cancel();
      }
    }
  };
};

export const fetchDialInfo = (systemDashboardId: number, siteId: number, preserveEscalation = false) => {
  return (dispatch: Function, getState: () => ApplicationState) => {
    dispatch(
      SystemDashboardService.getDialInfo(
        {
          systemDashboardId,
          siteId,
          product: getProductName(getState),
        },
        {
          label: "GET_DIAL_INFO",
          onSuccess: (response: any) => getDialInfo(systemDashboardId, siteId, response, preserveEscalation),
          // onFailure,
          other: { systemDashboardId: systemDashboardId, siteId: siteId },
        }
      )
    );
  };
};

const getAllDials = (systemDashboardId: number, data: any) => {
  return (dispatch: any, _getState: () => ApplicationState) => {
    //
    dispatch(getSiteDials(systemDashboardId, data));
    // ? Dispatch API call, doesn't matter which wheel returns first
    if (data.unitList && data.unitList.length > 0) {
      for (let i = 0; i < data.unitList.length; i += 1) {
        dispatch(fetchDialInfo(systemDashboardId, data.unitList[i].siteId));
      }
    }
    // dispatch(fetchDialInfo(systemDashboardId, data.unitList[0].siteId));
  };
};

export const fetchSiteDials = (systemDashboardId: number) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    cancelRequest("GET_SITE_DIALS");
    dispatch(
      SystemDashboardService.getSiteDials(
        {
          systemDashboardId,
          product: getProductName(getState),
        },
        {
          label: "GET_SITE_DIALS",
          // onSuccess: (response: any) => getSiteDials(systemDashboardId, response),
          onSuccess: (response: any) => getAllDials(systemDashboardId, response),
          // onFailure: () => console.log("Error occured loading articles"),
          other: { systemDashboardId: systemDashboardId },
        }
      )
    );
  };
};

export const fetchTables = (systemDashboardId: number, siteId: number, unitId: number) => {
  return (dispatch: Function, getState: () => ApplicationState) => {
    dispatch(
      SystemDashboardService.getTables(
        {
          systemDashboardId,
          siteId,
          innerUnitId: unitId,
          product: getProductName(getState),
        },
        {
          label: "GET_TABLES",
          onSuccess: (response) => getTables(systemDashboardId, siteId, unitId, response),
          other: { systemDashboardId, siteId, unitId },
        }
      )
    );
  };
};

export const fetchTablesIfNeeded = (systemDashboardId: number, siteId: number, status: DashboardStatusImported) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    const currentState = getState().systemDashboardReducer[systemDashboardId];
    const currentStatus: { [segmentName: string]: DashboardStatus } = currentState.status[siteId];
    const prettyStatus: { [segmentName: string]: DashboardStatus } = Object.entries(status).reduce(
      (final: { [segmentName: string]: DashboardStatus }, [_key, value]) => {
        const children = Object.entries(value.children).reduce((finalc: any, [keyc, _valuec]: [string, any]) => {
          const getRequestedOn = (finalChild: any, currentChild: any | undefined): number => {
            if (finalChild && finalChild.active !== true) {
              return 0;
            }
            if (currentChild && currentChild.requestedOn) {
              return currentChild.requestedOn;
            }
            return new Date().getTime();
          };
          finalc[keyc] = {
            ...currentStatus[keyc],
            ...finalc[keyc],
            requestedOn: getRequestedOn(finalc[keyc], currentStatus[keyc]),
          };
          // console.log(final[key]);
          return finalc;
        }, value.children);
        // console.log(children);
        // console.log(children);
        return {
          ...final,
          ...children,
        };
        // return final;
      },
      {}
    );
    const listToFetch = findDifference(currentStatus, prettyStatus);
    dispatch(updateStatus(systemDashboardId, siteId, prettyStatus));
    for (let i = 0; i < listToFetch.length; i += 1) {
      const unitId = listToFetch[i];
      // console.log("IM FETCHING: ", unitId);
      dispatch(fetchTables(systemDashboardId, siteId, unitId));
    }
    // const list = Object.entries(prettyStatus);
    // for (let i = 0; i < list.length; i += 1) {
    //   const [key, value] = (list as [keyof typeof prettyStatus, any][])[i];
    //   if (value.active && (!currentStatus[key] || !(currentStatus[key] as any).isFetched)) {
    //     console.log("IM FETCHING: ", value.unitId);
    //     dispatch(fetchTables(systemDashboardId, siteId, value.unitId));
    //   }
    // }
    // console.log(prettyStatus);
    // dispatch
  };
};

const downloadIndicatorOverviewReport = (
  systemDashboardId: number,
  pdUnitIdList: number[],
  data: any,
  fileName: string
) => ({
  type: "DOWNLOAD_INDICATOR_OVERVIEW_REPORT",
  systemDashboardId,
  pdUnitIdList,
  data,
  fileName,
});

export const fetchDownloadIndicatorOverviewReport = (
  systemDashboardId: number,
  pdUnitIdList: number[],
  afterSuccess?: () => void,
  fileName = "indicator-overview-report-" + new Date().toISOString()
): ApiAction => {
  return SystemDashboardService.downloadIndicatorOverviewReport(
    { pdUnitIdList },
    {
      label: "DOWNLOAD_INDICATOR_OVERVIEW_REPORT",
      onSuccess: (response: any) => {
        if (afterSuccess) {
          afterSuccess();
        }
        return downloadIndicatorOverviewReport(systemDashboardId, pdUnitIdList, response, fileName);
      },
      other: { systemDashboardId },
    },
    undefined
  );
};

const forceDialsRefresh = (
  systemDashboardId: number,
  siteIdList: number[],
  data: {
    success: ColorCalculation[];
    error: ColorCalculation[];
  }
) => ({
  type: "FORCE_DIALS_REFRESH",
  systemDashboardId,
  siteIdList,
  data,
});

export const fetchForceDialsRefreshAndGetDialInfo = (systemDashboardId: number, siteIdList: number[]) => {
  return (dispatch: Function, _getState: () => ApplicationState) => {
    dispatch(
      SystemDashboardService.forceColorCalculationBatch(
        {
          siteIdList,
        },
        {
          label: "FORCE_DIALS_REFRESH",
          onSuccess: (response) => {
            const data = response.reduce(
              (
                final: {
                  success: ColorCalculation[];
                  error: ColorCalculation[];
                },
                current
              ) => {
                const arrName = current.status === true ? "success" : "error";
                final[arrName].push(current);
                return final;
              },
              {
                success: [],
                error: [],
              }
            );
            for (let i = 0; i < data.error.length; i += 1) {
              const error = data.error[i];
              dispatch(
                apiError("FORCE_DIALS_REFRESH", error.message, FAKE_AXIOS_ERROR({ code: 500, method: "post" }), {
                  systemDashboardId: systemDashboardId,
                  siteIdList: siteIdList,
                  data: data,
                })
              );
            }
            // console.log({ data });
            const WAIT_FOR_MS = 1400;
            const throttleArray = (arr: any[], callback: (item: any) => void, ms = 1000, i = 0) => {
              if (i < arr.length) {
                callback(arr[i]);
                i++;
                setTimeout(throttleArray, ms, arr, callback, ms, i);
              }
            };
            setTimeout(() => {
              throttleArray(
                data.success,
                (item: ColorCalculation) => {
                  dispatch(fetchDialInfo(systemDashboardId, item.siteId, true));
                },
                WAIT_FOR_MS
              );
            }, WAIT_FOR_MS);

            return forceDialsRefresh(systemDashboardId, siteIdList, data);
          },
          // onFailure,
          other: { systemDashboardId: systemDashboardId, siteIdList: siteIdList },
        }
      )
    );
  };
};

export const fetchForceWholeSystemDashboardRefresh = (systemDashboardId: number, currentSiteId: number) => {
  return (dispatch: Function, getState: () => ApplicationState) => {
    const state = getState().systemDashboardReducer[systemDashboardId];
    // ? Get force refresh + refresh for all dials:
    dispatch(
      fetchForceDialsRefreshAndGetDialInfo(
        systemDashboardId,
        state.dials.map((dial) => dial.siteId)
      )
    );
    // ? Refresh open tables:
    const activeUnitIds = Object.values(state.status[currentSiteId] || {})
      .filter((unit) => unit.active)
      .map((unit) => unit.unitId!);
    for (let i = 0; i < activeUnitIds.length; i += 1) {
      const unitId = activeUnitIds[i];
      dispatch(fetchTables(systemDashboardId, currentSiteId, unitId));
    }
    // console.log({ state, activeUnitIds });
  };
};
