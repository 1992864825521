import React from "react";
import Meta from "../meta";

const IFRAME_ERROR_HEIGHT = 97;

export const dangerCSS = `
#tableau-report iframe {min-height: ${IFRAME_ERROR_HEIGHT}px;}
#footer {
  position: absolute;
  z-index: 1;
  bottom: 5px;
  left: 510px;
  font-size: 12px;
  font-family: "Benton Sans", Arial, Helvetica, sans-serif;
}
#footer > div { padding:0; }
`;

export const TableauLoading: React.FC<{ breadcrumbs?: string[] }> = ({ breadcrumbs = [] }) => (
  <main id="tableau-report">
    <Meta hideMeta title="-" breadcrumbs={breadcrumbs} isFetching />
    <div className="tableau__holder">
      <aside className="tableau-loader-holder">
        <i className="l xl tableau-custom" />
      </aside>
    </div>
    <style dangerouslySetInnerHTML={{ __html: dangerCSS }} />
  </main>
);
