import React from "react";
import { useThirdPartyCookieTest } from "../../hooks/use-third-party-cookie-test";

export const ThirdPartyCookieNotice: React.FC<{ enabled: boolean }> = ({ enabled }) => {
  const thirdPartyCookiesEnabled = useThirdPartyCookieTest({ enabled });
  const onRefresh = (e?: any) => {
    e && e.preventDefault();
    window.location.reload();
  };
  if (!enabled || thirdPartyCookiesEnabled) {
    return null;
  }
  return (
    <aside className="third-party-cookie-notice">
      <p>
        Third-party cookies are disabled in your browser settings. In order to view this report, please enable
        third-party cookies.
      </p>
      <p>
        Once you{`'`}ve enabled third-party cookies, please{" "}
        <a href="!refresh" onClick={onRefresh}>
          click here
        </a>{" "}
        to refresh this page and access the report.
      </p>
    </aside>
  );
};
