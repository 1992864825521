export const getRandomArbitrary = (min: number, max: number): number => {
  return Math.random() * (max - min) + min;
};

export const getRandomWidthStyles = (
  numberOfElements: number,
  withClassName = false
): { style: { width: string } }[] => {
  const widths = [];
  for (let i = 0; i < numberOfElements; i += 1) {
    widths.push({
      ...(withClassName && { className: "loading-element" }),
      style: { width: getRandomArbitrary(12, i % 2 === 0 ? 78 : 47) + "%" },
    });
  }
  return widths;
};
