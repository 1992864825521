import React from "react";
// import { Polygon } from "google-maps-react";
import { Polygon } from "./Polygon";
import { mapCoordinatesToPolygon } from "../helpers/map";

interface BoundaryProps {
  type: "Polygon" | "MultiPolygon" | string;
  paths: number[][][][] | number[][][];
  [key: string]: any;
}

export const Boundary = ({ type, paths, ...props }: BoundaryProps): JSX.Element => {
  if (type === "Polygon") {
    const coordinates = mapCoordinatesToPolygon(paths as number[][][]);
    return <Polygon {...props} paths={coordinates} />;
  }
  if (!["Polygon", "MultiPolygon"].includes(type)) {
    console.warn("different type than polygon", type);
  }
  // const paths: any[][][][] = props.paths;
  return (
    <>
      {(paths as number[][][][]).map((path: number[][][], index: number) => {
        const coordinates = mapCoordinatesToPolygon(path);
        return <Polygon {...props} key={index} paths={coordinates} />;
      })}
    </>
  );
};
