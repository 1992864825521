import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
// ? TYPES:
import { TFunction } from "i18next";

interface Props {
  t: TFunction;
}

const AnotherMethodWoT: React.FC<Props> = ({ t }) => {
  return (
    <aside className="ta-right" style={{ marginTop: "1.4rem" }}>
      <NavLink className="btn link bold" to={{ search: undefined }}>
        {t("mfa.general.Try another method")}
      </NavLink>
    </aside>
  );
};

export const AnotherMethod = withTranslation(["login"])(AnotherMethodWoT);
