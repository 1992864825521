import React from "react";
import { Input } from "@tscore/react-components";
import classNames from "classnames";
// ? TYPES:
import { IndicatorSystem } from "../../types/indicator";

type Indicator = IndicatorSystem;

interface Props {
  indicator: Indicator;
  isLoading: boolean;
  canEdit: undefined | boolean;
  showWeights: boolean | undefined;
  onWeightChange: (e: any, indicator: Indicator) => void;
}

export const WeightInput: React.FC<Props> = ({ indicator, isLoading, canEdit, showWeights, onWeightChange }) => {
  if (!showWeights) {
    return null;
  }
  if (indicator.dataType === 10) {
    // ? If dataType 10 (date indicator dont allow to change weights)
    return (
      <div className="weight__holder weight__holder--date input-loading-holder">
        <Input disabled />
      </div>
    );
  }
  return (
    <div
      className={classNames("weight__holder input-loading-holder", {
        "input-loading": isLoading,
      })}>
      <Input
        data-can-edit={canEdit}
        disabled={!canEdit}
        type="number"
        value={indicator.weight}
        min={0}
        max={9999}
        onChange={(e: any) => onWeightChange(e, indicator)}
      />
    </div>
  );
};
