import { DocumentsState, DocumentUploadResponse } from "../../types/documents-state";

export const addUploadError = (
  currentUploadErrors: DocumentsState["uploadErrors"],
  exceptionList: DocumentUploadResponse["documentUploadExceptionList"]
): DocumentsState["uploadErrors"] => {
  const errors =
    exceptionList.length > 0
      ? {
          [exceptionList[0].documentName]: exceptionList[0].message,
        }
      : {};

  return {
    ...currentUploadErrors,
    ...errors,
  };
};
