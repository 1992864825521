import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEscalationHistory, fetchDownloadEscalationHistoryReport } from "../../../store/actions/escalation";

import { Loader, DatePicker, Accordion, Button } from "@tscore/react-components";
import { CONTENT_HEIGHT, HEADER_HEIGHT_PX } from "./consts";
import { ActionTable } from "./action-table";
import moment from "moment";
import { ErrorEscalationConnection, ErrorEscalationEmpty } from "./error-escalation";
import { useTranslation } from "react-i18next";
// ? TYPES:
import { ApplicationState } from "../../../store/reducers";
// import { Action } from "../../../store/types/escalation-state";
import UserInfo from "../../../types/user-info";

const GET_DATA_ACTION_NAME = "GET_ESCALATION_HISTORY";

const DATEPICKER_SETTINGS: any = {
  popperPlacement: "bottom-start",
  // popperModifiers: {
  //   preventOverflow: {
  //     enabled: true,
  //     escapeWithReference: false,
  //     boundariesElement: "document",
  //   },
  // },
};

const setDate = (date: Date, isEnd = false) => {
  const time = isEnd ? { hour: 23, min: 59, s: 59 } : { hour: 0, min: 0, s: 0 };
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.hour, time.min, time.s);
};

// console.log(moment(d).toISOString(true));
const dateToIsoLocal = (date: Date) => {
  // "Z" added, to remove error from the server as it removes UTC offset anyway. Should be sending without or with user offset e.g. +05:30
  return moment(date).format("YYYY-MM-DDTHH:mm:ss") + "Z";
};

export const TabEscalationHistory: React.FC<{ pdSiteId: number; credentials: UserInfo; title: string }> = ({
  pdSiteId,
  credentials,
  title,
}) => {
  const { t } = useTranslation();
  const [start, setStart] = useState<Date>(setDate(new Date()));
  const [end, setEnd] = useState<Date>(setDate(new Date(), true));
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchInitial() {
      const startDate = dateToIsoLocal(setDate(start));
      const endDate = dateToIsoLocal(setDate(end, true));
      await dispatch(fetchEscalationHistory(pdSiteId, startDate, endDate));
    }
    fetchInitial();
    // document.getElementById("template")!.innerHTML = "Loading";
  }, [dispatch, pdSiteId, start, end]);
  const state = useSelector(
    (state: ApplicationState) => state.escalationReducer[pdSiteId] || state.escalationReducer[-1]
  );
  const { isFetching, escalationHistory, errors } = state;
  const indicators = Object.values(escalationHistory);
  if (indicators.length === 0 && isFetching[GET_DATA_ACTION_NAME]) {
    return (
      <div style={{ height: CONTENT_HEIGHT }}>
        <Loader scale="xl" />
      </div>
    );
  }
  if (errors[GET_DATA_ACTION_NAME]) {
    return <ErrorEscalationConnection title={title} error={errors[GET_DATA_ACTION_NAME]} />;
  }
  return (
    <div>
      <aside className="escalation-header" style={{ height: `${HEADER_HEIGHT_PX}px` }}>
        {/* <div style={{ display: "inline-block" }}></div> */}

        <div style={{ display: "inline-block" }}>
          <div style={{ whiteSpace: "nowrap", display: "inline-block" }}>
            <DatePicker
              selected={start}
              onChange={(date: Date) => setStart(setDate(date))}
              filterDate={(date: Date) => {
                // if (!end) {
                //   return new Date() > date;
                // }
                return moment(end).toDate() > date;
              }}
              placeholderText={t("generic:dateFromLabel")}
              {...DATEPICKER_SETTINGS}
            />
            <div className="btn label ml8 mr8" style={{ textTransform: "lowercase" }}>
              {t("generic:dateToLabel")}
            </div>
            <DatePicker
              selected={end}
              onChange={(date: Date) => setEnd(setDate(date, true))}
              filterDate={(date: Date) => {
                // if (!start) {
                //   return new Date() >= date;
                // }
                return moment(start).toDate() <= date && new Date() >= date;
                // return (start ? moment(start).toDate() : new Date()) <= date && new Date() >= date;
              }}
              placeholderText={t("generic:dateToLabel")}
              {...DATEPICKER_SETTINGS}
            />
          </div>
          {isFetching[GET_DATA_ACTION_NAME] && (
            <Loader style={{ verticalAlign: "-7px", marginLeft: "0.8rem" }} isInline />
          )}
        </div>
        <div style={{ display: "inline-block", float: "right" }} className="ta-right">
          <Button
            isLoading={isFetching["DOWNLOAD_ESCALATION_HISTORY_REPORT"]}
            colour="blue-outline"
            icon="get_app"
            onClick={() =>
              dispatch(
                fetchDownloadEscalationHistoryReport(
                  pdSiteId,
                  dateToIsoLocal(start),
                  dateToIsoLocal(end),
                  `Escalation-History-${pdSiteId}`
                )
              )
            }>
            {t("escalation:Download Escalation History")}
          </Button>
        </div>
      </aside>
      <div className="scrollbar-content" style={{ height: `calc(${CONTENT_HEIGHT} - ${HEADER_HEIGHT_PX}px)` }}>
        {indicators.length === 0 ? (
          <ErrorEscalationEmpty type="ESCALATION_HISTORY" />
        ) : (
          <Accordion allowZeroExpanded>
            {indicators.map((indicator) => (
              <Accordion.Item key={indicator.indicatorId}>
                <Accordion.ItemHeading>
                  <Accordion.ItemButton>{indicator.indicatorName}</Accordion.ItemButton>
                </Accordion.ItemHeading>
                <Accordion.ItemPanel>
                  <ActionTable
                    credentials={credentials}
                    pdSiteId={pdSiteId}
                    // actions={indicator.actions}
                    actionSelectorKeys={`escalationHistory.${indicator.indicatorId}.actions`}
                    // usersAssigned={indicator.usersAssigned}
                  />
                </Accordion.ItemPanel>
              </Accordion.Item>
            ))}
          </Accordion>
        )}
      </div>
    </div>
  );
};
