import { makeSmartExtendedIndicator } from "../system-dashboard/system-dashboard.transform";
// ? TYPES:
import { IndicatorExtended, IndicatorExtendedSmart, IndicatorPersonalised } from "../../../types/indicator";
import { IndicatorGroup } from "../../../store/types/personalised-indicators-state";
interface Response<T = IndicatorExtendedSmart<IndicatorPersonalised>> {
  results: IndicatorGroup<T>[];
}

const getPersonalisedIndicators = (og: Response<IndicatorExtended<IndicatorPersonalised>>): Response => {
  return {
    ...og,
    results: og.results.map((item) => ({
      ...item,
      indicatorList: item.indicatorList.map(makeSmartExtendedIndicator) as any[],
    })),
  };
};

const PersonalisedIndicatorsTransform = {
  getPersonalisedIndicators,
};

export default PersonalisedIndicatorsTransform;
