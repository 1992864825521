import format from "date-fns/format";
import { getDateFnsLocaleOptions } from "../i18n";

export const dateFnsTimeFormat = "p";
export const dateFnsDateFormat = "P";
export const dateFnsTimeDateFormat = `${dateFnsDateFormat} ${dateFnsTimeFormat}`;
export const dateFnsUpdatedTimeDateFormat = `HH:mm, dd LLL yyyy`;

export const formatToLocalString = (d: Date | string | number | undefined | null, formatStr: string) => {
  if (!d) {
    return "";
  }
  const dt = typeof d === "string" ? new Date(d) : d;
  return format(dt, formatStr, getDateFnsLocaleOptions());
};

export const getLocalDateStr = (d = new Date()) => {
  return formatToLocalString(d, "yyyy-MM-dd");
};
