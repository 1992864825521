import { matchPath } from "react-router-dom";
import { INTERNAL_URLS, SYSTEM_DASHBOARD_URL_PATHS } from "../../globals/internal-urls";
// ? TYPES:
import { RouteComponentProps } from "react-router";
type Match<T extends { [K in keyof T]?: string | undefined }> = RouteComponentProps<T>["match"];
type ExtraCheck = [(pathname: string, url: string) => boolean, (pathname: string, url: string, match?: any) => boolean];
type SystemDashboardMatch = Match<{ id: string; siteId: string | "primary"; pretty?: string }> | null;

const checkIfReportsAreActive = (pathname: string, url: string): boolean => {
  const matchProfile = matchPath(url, {
    path: `/reports/:reportType/:id`,
  });
  const id = ((matchProfile?.params as any) || { id: "" }).id.replace(/\?.*$/, "");
  const matchCurrent = matchPath(pathname, {
    path: `/reports/:reportType/${id}`,
  });
  if (matchCurrent) {
    return true;
  }
  return false;
};

const checkIfNonPrimaryDashboardAreActive = (pathname: string, url: string, match?: any) => {
  if (match) {
    return true;
  }
  const paths = SYSTEM_DASHBOARD_URL_PATHS.map((path) => `${path}/:id/:siteId/:pretty?`);
  const matchProfile: SystemDashboardMatch = matchPath(url, {
    path: paths,
  });
  if (!matchProfile) {
    return false;
  }
  const matchCurrent: SystemDashboardMatch = matchPath(pathname, {
    path: paths.map((path) => path.replace(":id", matchProfile.params.id)),
  });
  if (matchCurrent) {
    return true;
  }
  return false;
};

//

export const extraChecksDropdown: ExtraCheck[] = [
  [
    (pathname, url) => {
      for (let i = 0; i < SYSTEM_DASHBOARD_URL_PATHS.length; i += 1) {
        if (pathname.startsWith(SYSTEM_DASHBOARD_URL_PATHS[i]) && url.startsWith(SYSTEM_DASHBOARD_URL_PATHS[i])) {
          return true;
        }
      }
      // if (
      //   (pathname.startsWith(INTERNAL_URLS.systemDashboardList) ||
      //     pathname.startsWith(INTERNAL_URLS.nhsrsystemDashboardList) ||
      //     pathname.startsWith(INTERNAL_URLS.electiveDashboardList)) &&
      //   (url.startsWith(INTERNAL_URLS.systemDashboardList) ||
      //     url.startsWith(INTERNAL_URLS.nhsrsystemDashboardList) ||
      //     url.startsWith(INTERNAL_URLS.electiveDashboardList))
      // ) {
      //   return true;
      // }
      return false;
    },
    (pathname, url, match) => checkIfNonPrimaryDashboardAreActive(pathname, url, match),
  ],
];

// ? Extra checks for Primary Items (including dropdowns):
export const extraChecks: ExtraCheck[] = [
  [
    (pathname, url) =>
      pathname.startsWith(INTERNAL_URLS.winterReportOrganisationList) &&
      url.startsWith(INTERNAL_URLS.winterReportOrganisationList),
    (pathname, url, _isDropdown) => checkIfReportsAreActive(pathname, url),
  ],
  // [extraChecksDropdown[0][0], extraChecksDropdown[0][1]],
];
