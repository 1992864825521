import React, { useState, forwardRef, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom"; // ! Won't be needed from REACT 18
import { accessEnv } from "../../access-env";
import axios, { AxiosPromise } from "axios";
import { loadStorage, saveStorage } from "../../helpers/localstorage";
import { Button } from "@tscore/react-components";
import { useTranslation } from "react-i18next";
import { useInterval } from "../../hooks/use-interval";
import { useTabActive } from "../../hooks/use-tab-active";
// ? TYPES:
// import { TFunction } from "i18next";

interface MessageText {
  title: string;
  message: string;
}

interface MessageInput {
  id: number;
  locales: {
    "en-GB": MessageText;
    "fr-CA": MessageText;
  };
  remindInDays: number;
  type?: "private" | "guest";
}

interface Message extends MessageInput {
  lastRefreshedUnix?: number;
  // isHidden?: boolean;
}

// const test: Message = {
//   "id": 1,
//   "locales": {
//     "en-GB": {
//       "title": "Adastra test 2",
//       "message": "Sample paragraph"
//     },
//     "fr-CA": {
//       "title": "FR_Adastra test",
//       "message": "FR_Sample paragraph"
//     }
//   },
//   "remindInDays": 3
// };
const CHECK_MESSAGES_EVERY_MINUTES = 30;
const OVERHEAD_IN_MILLIS = 4 * 60 * 1000;
const JSON_URL = accessEnv("GLOBAL_MESSAGES_URL");
const STORAGE_NAME = "shrewd-global-messages";
const DAY_IN_MILLIS = 86400000;
const CHECK_MESSAGES_EVERY_MILLIS = CHECK_MESSAGES_EVERY_MINUTES * 60 * 1000;

const getData = (): AxiosPromise<{ uk: Message[]; ca: Message[] }> => {
  return axios({
    headers: {
      "Content-Type": "application/json",
      Authorization: "",
    },
    url: JSON_URL,
    method: "GET",
    transformRequest: (data, headers) => {
      delete headers["Authorization"];
      delete headers.common["Authorization"];
      return data;
    },
  });
};

export const GlobalMessages = forwardRef<HTMLElement, { type: "private" | "guest" }>(({ type }, containerRef) => {
  const { t, i18n } = useTranslation();
  const isTabActive = useTabActive();
  const [lastFetched, setLastFetched] = useState(0);
  const [nowTime, setNowTime] = useState(new Date().getTime());
  const [isRunning] = useState(true);
  const [data, setData] = useState<Message[]>(loadStorage(STORAGE_NAME) || []);
  const onSetData = useCallback((d: Message[]) => {
    unstable_batchedUpdates(() => {
      setData((prevState) => {
        const updatedData = d.map((message) => {
          const found = prevState.find((prevMessage) => prevMessage.id === message.id);
          if (found) {
            // console.log(
            //   nowTime - found.lastRefreshedUnix!,
            //   OVERHEAD_IN_MILLIS + found.remindInDays * DAY_IN_MILLIS - OVERHEAD_IN_MILLIS,
            //   nowTime - found.lastRefreshedUnix! - found.remindInDays * DAY_IN_MILLIS - OVERHEAD_IN_MILLIS,
            //   nowTime - found.lastRefreshedUnix! < found.remindInDays * DAY_IN_MILLIS - OVERHEAD_IN_MILLIS
            // );
            // console.log({ isHidden, x: found.lastRefreshedUnix });
            return {
              ...found,
              ...message,
            };
          }
          return message;
        });
        saveStorage(STORAGE_NAME, updatedData);
        return updatedData;
      });
      setNowTime(new Date().getTime());
      setLastFetched(new Date().getTime());
    });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getData();
        // console.log({ date: new Date().toISOString(), response });
        onSetData(response.data[accessEnv("DEPLOYMENT_TYPE", "uk") as "uk"]);
      } catch (e) {
        // error
      }
    };
    const now = new Date().getTime();
    const waitMilisBeforeFetching = 60000 * 10;
    if (isTabActive && now - lastFetched >= waitMilisBeforeFetching) {
      fetchData();
    }
  }, [onSetData, isTabActive, lastFetched]);
  useInterval(
    () => {
      setLastFetched(new Date().getTime());
    },
    isRunning ? CHECK_MESSAGES_EVERY_MILLIS : null,
    false
  );

  const onAccept = (message: Message) => {
    const now: number = new Date().getTime();
    setData((prevState) => {
      const updatedData = prevState.map((a) => {
        if (a.id === message.id) {
          return {
            ...a,
            lastRefreshedUnix: now,
            // isHidden: true,
          };
        }
        return a;
      });
      saveStorage(STORAGE_NAME, updatedData);
      return updatedData;
    });
  };

  return (
    <aside ref={containerRef} style={{ position: "fixed", bottom: "3.2rem", right: "3.2rem", zIndex: 1 }}>
      {data.map((a) => {
        const isHiddenForGuests = type === "guest" ? a.type === "private" : false;
        if (isHiddenForGuests) {
          return null;
        }
        const isHidden = a.lastRefreshedUnix
          ? nowTime - a.lastRefreshedUnix < a.remindInDays * DAY_IN_MILLIS - OVERHEAD_IN_MILLIS
          : false;
        if (isHidden) {
          return null;
        }
        const lang = a.locales[i18n.language as "en-GB"] || a.locales["en-GB"];
        return (
          <div
            key={a.id}
            className="box"
            style={{
              maxWidth: "30vw",
              minWidth: "420px",
              boxShadow: "0 4px 20px 4px rgb(0 20 60 / 10%), 0 4px 80px -8px rgb(0 20 60 / 20%)",
              marginTop: "8px",
            }}>
            <header style={{ marginBottom: "1.8rem" }}>
              <h2>{lang.title}</h2>
            </header>
            <p style={{ whiteSpace: "pre-line" }}>{lang.message}</p>
            <footer className="ta-right" style={{ marginTop: "2.4rem" }}>
              <Button colour="link" onClick={() => onAccept(a)}>
                {t("generic:Close")}
              </Button>
            </footer>
          </div>
        );
      })}
    </aside>
  );
});
