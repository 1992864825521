// import GooglePlayBadge from "../sass/assets/google-play-badge.png";
// import { ReactComponent as AppStoreBadge } from "../sass/assets/app-store-badge.svg";
// import { ReactComponent as AppStoreEn } from "../sass/assets/apple-store-en.svg";
// import { ReactComponent as GooglePlayEn } from "../sass/assets/google-play-en.svg";
import { ReactComponent as LOGO } from "../sass/assets/shrewd-logo.svg";
// import { ReactComponent as LOGO_REVERSED } from "../sass/assets/vh-logo-reversed.svg";

export const BADGES = {
  apple: "https://itunes.apple.com/gb/app/shrewd-resilience/id1111220820?mt=8",
  google: "https://play.google.com/store/apps/details?id=com.ts.shrewd&hl=en_GB",
};

export { LOGO };

// export const BADGE_URLS: any = {
//   apple: "https://apple.com/",
//   google: "https://google.com/"
// };

const currentYear = () => {
  return new Date().getFullYear();
};

export const CURRENT_YEAR = currentYear();

// export const META = {
//   phone: "02033 976 626", // ! should be used fro language file
//   email: "shrewdsupport@vitalhub.com", // ! should be used fro language file
//   copyright: `${currentYear()} Transforming Systems, a VitalHub company`, // ! should be used from language file
//   url: "https://transformingsystems.com",
//   motto: "Transforming intelligence into actions.", // ! should be used from language file
//   name: "Transforming Systems",
// };

// export const CONTACT_LINK = "https://www.transformingsystems.com/contact/";
// export const PRIVACY_LINK = "https://www.transformingsystems.com/wp-content/uploads/2019/08/TS_Cookie_Policy.pdf";
