const ELEMENTS_TO_CALCULATE_WIDTH = [
  {
    selector: "#x",
    type: "offsetWidth",
    calculateOver: 0,
  },
  {
    selector: ".system-dashboard-layout .left",
    type: "offsetWidth",
    calculateOver: 1080,
  },
  {
    selector: "#system-dashboard",
    type: "padding-left",
    calculateOver: 0,
  },
  {
    selector: "#system-dashboard",
    type: "padding-right",
    calculateOver: 0,
  },
  {
    selector: ".system-dashboard-layout .table-holder",
    type: "padding-left",
    calculateOver: 1080,
  },
];

export function getSpecialScrollerMaxWidth(): number | "auto" {
  const SCROLLBAR_VERTICAL_WIDTH = 12;
  const windowWidth = window.innerWidth;
  if (windowWidth >= 1921) {
    return "auto";
  }
  const sizes = ELEMENTS_TO_CALCULATE_WIDTH.reduce(function (final, item) {
    if (windowWidth <= item.calculateOver) {
      return final;
    }
    const element = document.querySelector(item.selector);
    if (!element) {
      // eslint-disable-next-line
      console.warn("Couldnt calculate " + item.selector);
      return final;
    }
    if (item.type === "offsetWidth") {
      return final + (element as HTMLElement).offsetWidth;
    }
    //(item.element as any).currentStyle ||
    const style = window.getComputedStyle(element as HTMLElement);
    const stringifiedValue = style[item.type as any] as any;
    return final + parseFloat(stringifiedValue) || 0;
  }, SCROLLBAR_VERTICAL_WIDTH);
  return windowWidth - parseInt(sizes as unknown as string, 10);
  // return "auto";
}
