import React from "react";
import { Modal } from "@tscore/react-components";
import { withTranslation } from "react-i18next";
import { GenericBadges } from "../../../../components/structure/badges";
import { AppMetaIcons } from "./popover-app-meta-icons";
// ? TYPES:
import { TFunction } from "i18next";

export const AppMetaTrigger: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Modal
    style={{ maxWidth: "780px" }}
    trigger={
      <a role="button" href="#!more-info" onClick={(e) => e.preventDefault()}>
        {children}
      </a>
    }>
    <AuthenticatorPopover />
  </Modal>
);

const APPLE_BASE = "https://apps.apple.com/gb/app";
const GOOGLE_BASE = "https://play.google.com/store/apps/details";

const APP_DETAILS = [
  {
    id: "google",
    img: AppMetaIcons.google,
    storeLinks: {
      google: `${GOOGLE_BASE}?id=com.google.android.apps.authenticator2`,
      apple: `${APPLE_BASE}/google-authenticator/id388497605`,
    },
  },
  {
    id: "microsoft",
    img: AppMetaIcons.microsoft,
    storeLinks: {
      google: `${GOOGLE_BASE}?id=com.azure.authenticator`,
      apple: `${APPLE_BASE}/microsoft-authenticator/id983156458`,
    },
  },
  {
    id: "authy",
    img: AppMetaIcons.authy,
    storeLinks: {
      google: `${GOOGLE_BASE}?id=com.authy.authy`,
      apple: `${APPLE_BASE}/twilio-authy/id494168017`,
    },
  },
];

const AuthenticatorPopoverWoT: React.FC<{ t: TFunction }> = ({ t }) => (
  <div className="m24">
    {APP_DETAILS.map(({ id, img, storeLinks }, i) => (
      <React.Fragment key={id}>
        {i !== 0 && <hr />}
        <div className="authenticator__popover">
          <div className="authenticator__popover__header">
            <div>
              <img src={img} alt="" />
            </div>
            <h2>{t(`mfa.general.appMeta.${id}.name`)}</h2>
            <GenericBadges {...storeLinks} />
          </div>
          <div>
            <p>{t(`mfa.general.appMeta.${id}.description`)}</p>
          </div>
        </div>
      </React.Fragment>
    ))}
  </div>
);

const AuthenticatorPopover = withTranslation(["login"])(AuthenticatorPopoverWoT);
