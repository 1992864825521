import React from "react";
import { OTPInput } from "input-otp";
import classNames from "classnames";
// ? TYPES:
import { SlotProps } from "input-otp";
import { SelectedFormikProps } from "../../../types/login-form";

export interface OTPProps {
  autoFocus?: boolean;
  error?: string | false;
  setValue: SelectedFormikProps["setFieldValue"];
  value: string;
  name?: string;
}

const FakeCaret = () => (
  <div className="otp__caret">
    <div />
  </div>
);

const Slot = (props: SlotProps) => (
  <div className={classNames("otp__slot", { "otp__slot--active": props.isActive })}>
    {props.char !== null && <div>{props.char}</div>}
    {props.hasFakeCaret && <FakeCaret />}
  </div>
);

export const OTP: React.FC<OTPProps> = ({ autoFocus, error, setValue, value, name = "code" }) => {
  return (
    <OTPInput
      value={value}
      onChange={(newValue) => setValue(name, newValue)}
      autoFocus={autoFocus}
      maxLength={6}
      containerClassName={classNames("otp__container", { "otp__container--error": !!error })}
      render={({ slots }) => slots.map((slot, idx) => <Slot key={idx} {...slot} />)}
    />
  );
};
