import { PaginationMeta } from "../types/pagination-meta";

// ? pageSize = limit
// ? totalItems = totalRecords
// ? currentPage = page

export const paginate = (totalItems: number, currentPage: number, pageSize: number, maxPages = 10): PaginationMeta => {
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);
  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  }
  // else if (currentPage > totalPages) {
  //   currentPage = totalPages;
  // }
  let startPage, endPage;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }
  // create an array of pages to ng-repeat in the pager control
  let i;
  const pages = [];
  for (i = startPage; i <= endPage; i += 1) {
    pages.push(i);
  }
  // return object with all pager properties required by the view
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    pages: pages,
    showingTo: function () {
      if (this.currentPage === this.totalPages) {
        return this.totalItems;
      }
      return this.currentPage * this.pageSize;
    },
    showingFrom: function () {
      if (this.currentPage === 1) {
        return 1;
      }
      return (this.currentPage - 1) * this.pageSize + 1;
    },
  };
};
