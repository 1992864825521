// export * from "./login-wrapper";
import { connect } from "react-redux";
import { FormLogin, formikEnhancerLogin, mapDispatchToPropsLogin } from "./form-login";
import { FormForgot, formikEnhancerForgot, mapDispatchToPropsForgot } from "./form-forgot";
import { FormReset, formikEnhancerReset, mapDispatchToPropsReset } from "./form-reset";
import { formikEnhancerActivate, mapDispatchToPropsActivate } from "./form-activate";
import { FormMfaSetup, formikEnhancerMfaSetup, mapDispatchToPropsMfaSetup } from "./mfa/setup";
import { FormMfaEnforce, formikEnhancerMfaEnforce, mapDispatchToPropsMfaEnforce } from "./mfa/enforce";
import { withTranslation } from "react-i18next";
import { LoginPageWrapper } from "./login-wrapper";

export const LoginPage = connect(
  null,
  mapDispatchToPropsLogin,
  (stateProps: any, dispatchProps: any, ownProps: any) => {
    return { Form: FormLogin, ...ownProps, ...stateProps, ...dispatchProps };
  }
)(withTranslation()(formikEnhancerLogin(LoginPageWrapper)));

export const ForgotPage = connect(
  null,
  mapDispatchToPropsForgot,
  (stateProps: any, dispatchProps: any, ownProps: any) => {
    return { Form: FormForgot, ...ownProps, ...stateProps, ...dispatchProps };
  }
)(withTranslation()(formikEnhancerForgot(LoginPageWrapper)));

export const ResetPage = connect(
  null,
  mapDispatchToPropsReset,
  (stateProps: any, dispatchProps: any, ownProps: any) => {
    return { Form: FormReset, ...ownProps, ...stateProps, ...dispatchProps };
  }
)(withTranslation()(formikEnhancerReset(LoginPageWrapper)));

export const ActivatePage = connect(
  null,
  mapDispatchToPropsActivate,
  (stateProps: any, dispatchProps: any, ownProps: any) => {
    return { Form: FormReset, ...ownProps, ...stateProps, ...dispatchProps };
  }
)(withTranslation()(formikEnhancerActivate(LoginPageWrapper)));

export const MfaSetupPage = connect(
  null,
  mapDispatchToPropsMfaSetup,
  (stateProps: any, dispatchProps: any, ownProps: any) => {
    return { Form: FormMfaSetup, ...ownProps, ...stateProps, ...dispatchProps };
  }
)(withTranslation()(formikEnhancerMfaSetup(LoginPageWrapper)));

export const MfaEnforcePage = connect(
  null,
  mapDispatchToPropsMfaEnforce,
  (stateProps: any, dispatchProps: any, ownProps: any) => {
    return { Form: FormMfaEnforce, ...ownProps, ...stateProps, ...dispatchProps };
  }
)(withTranslation()(formikEnhancerMfaEnforce(LoginPageWrapper)));
