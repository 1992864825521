import { urlGenerator } from "./list-generator";

export const generateVecMenu = (finalOne: any, one: any, url: string, twoMenu: any[]) => {
  twoMenu.forEach((value) => {
    const name = value.name;
    const topUrl = urlGenerator(url, [value.id], name);
    finalOne!.push({
      name: name,
      url: topUrl,
      inverted: one.inverted,
      menu: null,
    });
  });
  return finalOne;
};
