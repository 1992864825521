export { BRAG_PRESSURE_LEVEL_MAPPING } from "../../helpers/brag-helpers";

const COLOUR_WHITE = "#ffffff";
export const BRAG_COLOURS: any = {
  green: {
    colour: "#00ca72",
    textColour: COLOUR_WHITE,
  },
  amber: {
    colour: "#fda136",
    textColour: COLOUR_WHITE,
  },
  red: {
    colour: "#e0394e",
    textColour: COLOUR_WHITE,
  },
  black: {
    colour: "#212a41",
    textColour: COLOUR_WHITE,
  },
  gray: {
    colour: "#9fa3a9",
    textColour: "#00112b",
  },
  white: {
    colour: COLOUR_WHITE,
    textColour: "#00112b",
  },
};

export enum FACILITY_TYPE_TO_TEXT_MAPPING {
  Hospital = "H",
  hospital = "H",
  MIU = "MIU",
  WIC = "WIC",
  UTC = "UTC",
  Independent = "I",
  independent = "I",
  private = "H",
  Private = "H",
  "Acute Hospital" = "H",
  "acute hospital" = "H",
  "Private Hospital" = "I",
  "private hospital" = "I",
  "idMarkerType_8" = "H",
  "idMarkerType_9" = "I",
}
