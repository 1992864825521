export function hyphenateLongWords(strings: string[], maxLength: number): string[] {
  if (maxLength < 1) {
    return strings;
  }
  return strings.reduce((final, current) => {
    if (current.length > maxLength) {
      const currentSplit = current.match(new RegExp('.{1,' + maxLength + '}', 'g')).map((s, i) => i === 0 ? s : "-" + s);
      return [...final, ...currentSplit];
    }
    final.push(current);
    return final;
  }, []);
}

export function multiLineSplitter(str: string, maxLength: number, hyphenLongerWordsAtChar = 0, treatSlashAsWord = true): string[] {
  const originalString = treatSlashAsWord ? str.replace(/(\S)\/(\S)/g, "$1 / $2") : str;
  const splitted = hyphenateLongWords(originalString.split(" "), hyphenLongerWordsAtChar);
  return splitted.reduce((final, current, i) => {
    if (i === 0) {
      final.push(current);
      return final;
    }
    const n = final.length - 1;
    const prevValue = final[n];
    if (prevValue.length <= maxLength) {
      const newTempValue = prevValue + " " + current;
      if (newTempValue.length <= maxLength) {
        final[n] = newTempValue;
      } else {
        final.push(current);
      }
    } else {
      final.push(current);
    }
    return final;
  }, []);
}