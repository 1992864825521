import React from "react";
import { CURRENT_YEAR } from "../../globals/CompanyMeta";
import { Icon } from "@tscore/react-components";
// import { LANGUAGE_SWITCHER } from "../../globals/settings";
import { useTranslation, Trans } from "react-i18next";

// ? TYPES:
// import { ModalRefMethods } from "../../types/modal";
// import { RefObject } from "react";

interface MetaFooterProps {
  titleKey?: string;
  includeCopyrights?: boolean;
  children?: any;
}

export const MetaFooter: React.FC<MetaFooterProps> = ({
  children,
  titleKey = "login:Contact Us",
  includeCopyrights = true,
}) => {
  const { t } = useTranslation();
  return (
    <div className="meta">
      <h6>{t(titleKey)}</h6>
      <ul>
        <li>
          <Icon>phone</Icon>
          {t("meta:SUPPORT_PHONE")}
        </li>
        <li>
          <Icon>alternate_email</Icon>
          {t("meta:SUPPORT_EMAIL")}
        </li>
        {includeCopyrights && (
          <li>
            <Icon>copyright</Icon>
            <span className="copyrights__wrapper">
              <Trans
                i18nKey="meta:copyrightsSuffix"
                values={{ year: CURRENT_YEAR }}
                components={[
                  <a key={0} href={t("meta:URL")} target="_blank" rel="noopener noreferrer">
                    VH
                  </a>,
                ]}></Trans>
            </span>
            {/* <span dangerouslySetInnerHTML={{ __html: t("meta:copyrightsSuffix", { year: CURRENT_YEAR }) }} /> */}
          </li>
        )}
        {children}
      </ul>
    </div>
  );
};
