import { SET_DB_VALUE, SET_DB_LOADING, SET_DB_ERROR } from "../../actions/indexed-db";
// ? TYPES:
import { IndexedDBActionTypes } from "../../actions/indexed-db";
import { IndexedDBState } from "../../types/indexed-db-state";

const initialState: IndexedDBState = {
  deviceId: {
    value: "",
    isLoading: true,
    error: null,
  },
};

const reducer = (state = initialState, action: IndexedDBActionTypes): IndexedDBState => {
  switch (action.type) {
    case SET_DB_VALUE: {
      const { key, value } = action;
      return {
        ...state,
        [key]: {
          ...state[key],
          value,
          isLoading: false,
        },
      };
    }
    case SET_DB_LOADING: {
      const { key, isLoading } = action;
      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading,
        },
      };
    }
    case SET_DB_ERROR: {
      const { key, error } = action;
      return {
        ...state,
        [key]: {
          ...state[key],
          error,
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
