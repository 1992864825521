import { DEFAULT_CHART_OPTIONS } from "../../../components/indicator-history/graph-options";
// ? TYPES:
import { LocalDispatch } from "../../../components/indicator-history/reducer";
import { GraphFrequencyValue } from "../../types/indicator-history-state";
import { ChartOptionsExtended } from "../../../components/indicator-history/graph-options";

export const generateOptions = (
  { localDispatch, params }: { params: { freq: GraphFrequencyValue }; localDispatch: LocalDispatch },
  {
    showLegend,
    xAxes,
    disableAnimation,
  }: {
    showLegend: boolean;
    xAxes: any;
    disableAnimation: boolean;
    is24HourLanguage: boolean;
    maxRecordsPerDataset: number;
  }
): ChartOptionsExtended => {
  const zoomWithCallback = {
    ...DEFAULT_CHART_OPTIONS.plugins!.zoom,
    zoom: {
      ...DEFAULT_CHART_OPTIONS.plugins!.zoom!.zoom,
      onZoomComplete: () => {
        localDispatch({ type: "SET_IS_ZOOMED", isZoomed: true });
      },
    },
  };
  const trendBarSettings = {
    barPercentage: 1,
    categoryPercentage: 1,
  };
  return {
    ...DEFAULT_CHART_OPTIONS,
    interaction: {
      ...DEFAULT_CHART_OPTIONS.interaction,
      // ...(params.freq === "trend" && { mode: "x" }), // ? good for trend, but doesnt work in bar...
    },
    responsive: true, // chartLoading ? false : true,
    // onResize: (chart, size) => {
    //   console.log(size, chart);
    // },
    plugins: {
      ...DEFAULT_CHART_OPTIONS.plugins,
      onComplete: {
        afterRender: () => {
          localDispatch({ type: "SET_CHART_LOADING", chartLoading: false });
        },
      },
      zoom: zoomWithCallback,
      legend: {
        ...DEFAULT_CHART_OPTIONS.plugins!.legend,
        display: showLegend,
      },
    },
    scales: {
      ...DEFAULT_CHART_OPTIONS.scales,
      x: xAxes,
    },
    datasets: {
      bar: params.freq === "trend" ? trendBarSettings : {},
      line: params.freq === "trend" ? { pointRadius: 1, pointHoverRadius: 3, borderWidth: 2 } : {},
    },
    ...(disableAnimation && { animation: false }),
  };
};
