import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";

import { fetchUpdateCurrentElectiveFilters, updateElectiveFiltersLocal } from "../../../../store/actions/maps";
// import { Select } from "@tscore/react-components";
import { FilterSelect } from "../../../../components/map/shared/filter-select";
import { useTranslation } from "react-i18next";
import { useActiveTabTimerKey } from "../../../../hooks/use-active-tab-timer-key";
// ? TYPES:
import { ApplicationState } from "../../../../store/reducers";
import {
  ElectiveIndicatorGroup,
  ElectiveSpeciality,
  ElectiveSubSpeciality,
} from "../../../../store/types/maps-elective";

export type TFilterName = "specialities" | "segments" | "indicatorGroups" | "autorefresh";

const FILTER_UPDATE_DEBOUNCE_MS = 1400;
const AUTO_REFRESH_MINS = 10;

const SelectLoading = () => <div style={{ height: "3.2rem", margin: 0 }} className="loading-element"></div>;
const FiltersLoading = ({
  isFiltersLoading,
  lastUpdatedName,
  currentName,
}: {
  isFiltersLoading: boolean;
  currentName: TFilterName | undefined;
  lastUpdatedName: TFilterName | undefined;
}) =>
  isFiltersLoading && lastUpdatedName !== currentName ? (
    <i className="l inline" style={{ marginLeft: "0.8rem" }} />
  ) : null;

const generateSpecialSpecialities = (
  specialities: ElectiveSpeciality[]
): ((ElectiveSpeciality & { id: string }) | (ElectiveSubSpeciality & { id: string }))[] => {
  return specialities.reduce((final: any[], current) => {
    if (current.idSpecialty === null) {
      // ! TODO remove this later, but wont hurt if it stays :)
      return final;
    }
    final.push({
      ...current,
      id: "speciality_" + current.idSpecialty,
    });
    if (current.subSpecialities && current.subSpecialities.length > 0) {
      for (let i = 0; i < current.subSpecialities.length; i += 1) {
        const sub = current.subSpecialities[i];
        final.push({
          ...sub,
          // id: "sub_" + sub.idSubSpecialty,
          id: "sub_" + current.idSpecialty + "_" + sub.idSubSpecialty,
        });
      }
    }
    return final;
  }, []);
};

export const Filters: React.FC<{ id: number }> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [refreshKey, [id]] = useActiveTabTimerKey<[number]>({
    interval: AUTO_REFRESH_MINS * 60000,
    resetKey: [props.id],
  });
  const [isFiltersLoading, setIsFiltersLoading] = useState<boolean>(false);
  const [specialSpecialities, setSpecialSpecialities] = useState<any[]>([]);
  const state = useSelector((state: ApplicationState) => state.mapsReducer);
  const { isFetching, elective } = state;
  const { segments, specialities, indicatorGroups, lastUpdatedName } = elective[id] || elective[-1];
  useEffect(() => {
    if (isFetching["UPDATE_ELECTIVE_FILTERS"] === false) {
      setIsFiltersLoading(false);
    }
  }, [setIsFiltersLoading, isFetching]);
  useEffect(() => {
    setSpecialSpecialities(generateSpecialSpecialities(specialities));
  }, [setSpecialSpecialities, specialities]);
  const isFetchingInitial = isFetching["GET_ELECTIVE_INITIAL"];
  const debouncedUpdate = useCallback(
    debounce((name: TFilterName) => dispatch(fetchUpdateCurrentElectiveFilters(name, id)), FILTER_UPDATE_DEBOUNCE_MS),
    []
  );
  useEffect(() => {
    if (refreshKey > 0) {
      debouncedUpdate("autorefresh");
    }
  }, [debouncedUpdate, refreshKey]);
  const onSelectChange = (selected: any | ElectiveIndicatorGroup[] | null, action: any) => {
    dispatch(updateElectiveFiltersLocal(selected, action.name, action, id));
    if (selected) {
      setIsFiltersLoading(true);
      debouncedUpdate(action.name);
    } else {
      setIsFiltersLoading(false);
      debouncedUpdate.cancel();
    }
  };
  const onMenuClose = () => {
    // maybe update if needed etc
  };
  const segmentsValue = segments.filter((item) => item.selected === true) as any;
  const specialSpecialitiesValue = specialSpecialities.filter((item) => item.selected === true) as any;
  const indicatorGroupsValue = indicatorGroups.filter((item) => item.selected === true) as any;
  return (
    <aside className="box map-overlay" style={{ top: "24px", right: "24px" }}>
      <header>
        <h3>
          <span>{t("map:Filter options")}</span>
          <span>
            {isFetching["UPDATE_ELECTIVE_FILTERS"] && !isFiltersLoading && <i className="l inline" />}
            {/* {(isFetching["UPDATE_ELECTIVE_FILTERS"] || isFiltersLoading) && <i className="l inline" />} */}
          </span>
        </h3>
      </header>
      <section className={isFetchingInitial ? "isFetching" : ""} style={{ width: "44vw", maxWidth: "700px" }}>
        <div className="form">
          <div className="form-row">
            <div className="form-field">
              <h4>
                {t("map:Segments")}
                <FiltersLoading
                  isFiltersLoading={isFiltersLoading}
                  lastUpdatedName={lastUpdatedName}
                  currentName="segments"
                />
              </h4>
            </div>
            <div className="form-field">
              <h4>
                {t("map:Specialities / Sub Specialities")}
                <FiltersLoading
                  isFiltersLoading={isFiltersLoading}
                  lastUpdatedName={lastUpdatedName}
                  currentName="specialities"
                />
              </h4>
            </div>
            {/* <div className="form-field">
              <h4>Sub Specialities</h4>
            </div> */}
            <div className="form-field">
              <h4>
                {t("map:Indicator Groups")}
                <FiltersLoading
                  isFiltersLoading={isFiltersLoading}
                  lastUpdatedName={lastUpdatedName}
                  currentName="indicatorGroups"
                />
              </h4>
            </div>
          </div>
          <div className="form-row" style={{ marginTop: 0 }}>
            <div className="form-field">
              {isFetchingInitial ? (
                <SelectLoading />
              ) : (
                <FilterSelect
                  name={"segments"}
                  maxOptionsCollapsed={1}
                  placeholder={t("map:numberSelected_zero")}
                  placeholderKey=""
                  options={segments}
                  onChange={onSelectChange}
                  optionValueName="idSegment"
                  value={segmentsValue}
                  className={!isFiltersLoading && segmentsValue.length === 0 ? "has-error" : ""}
                  tabIndex="2"
                  onMenuClose={onMenuClose}
                />
              )}
              {/* {segments.map((s) => (
                <div style={{ color: s.selected ? "green" : "black" }} key={s.name}>
                  {s.name}
                </div>
              ))} */}
            </div>
            <div className="form-field">
              {isFetchingInitial ? (
                <SelectLoading />
              ) : (
                <FilterSelect
                  // className="specialities-select"
                  name={"specialities"}
                  placeholder={t("map:numberSelected_zero")}
                  placeholderKey=""
                  placeholderKeyOne="speciality"
                  options={specialSpecialities}
                  onChange={onSelectChange}
                  optionValueName="id"
                  value={specialSpecialitiesValue}
                  className={
                    !isFiltersLoading && specialSpecialitiesValue.length === 0
                      ? "specialities-select has-error"
                      : "specialities-select"
                  }
                  maxMenuHeight={500}
                  tabIndex="1"
                  onMenuClose={onMenuClose}
                />
              )}
              {/* {specialities.map((s) => (
                <div style={{ color: s.selected ? "green" : "black" }} key={s.name}>
                  {s.name}
                  <div>
                    {(s.subSpecialities || []).map((x) => (
                      <div style={{ color: x.selected ? "green" : "black" }} key={x.name}>
                        {" "}
                        --- {x.name}
                      </div>
                    ))}
                  </div>
                </div>
              ))} */}
            </div>
            {/* <div className="form-field">
              <Select options={[]} />
            </div> */}
            <div className="form-field">
              {isFetchingInitial ? (
                <SelectLoading />
              ) : (
                <FilterSelect
                  name={"indicatorGroups"}
                  placeholder={t("map:numberSelected_zero")}
                  placeholderKey=""
                  options={indicatorGroups}
                  onChange={onSelectChange}
                  optionValueName="idIndicatorGroup"
                  value={indicatorGroupsValue}
                  className={!isFiltersLoading && indicatorGroupsValue.length === 0 ? "has-error" : ""}
                  tabIndex="3"
                  onMenuClose={onMenuClose}
                />
              )}
              {/* {indicatorGroups.map((s) => (
                <div style={{ color: s.selected ? "green" : "black" }} key={s.name}>
                  {s.name}
                </div>
              ))} */}
            </div>
          </div>
        </div>
      </section>
    </aside>
  );
};
