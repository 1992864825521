import React from "react";
import Moment from "react-moment";
import { momentTimeDateFormat } from "../../lang/DateTimeFormats";
import { withTranslation } from "react-i18next";
import { Button, Tippy } from "@tscore/react-components";
//? TYPES:
import { TFunction } from "i18next";
import { RecentReport } from "../../types/forms/recent-report";

const ModalPrefillWoT: React.FC<{ recentList: RecentReport[]; onFill: (reportId: string) => void; t: TFunction }> = ({
  recentList,
  onFill,
  t,
}) => (
  <div id="modal-prefill">
    <header>
      <h4>{t("modalPrefill.title")}</h4>
      <h6>{t("modalPrefill.subtitle")}</h6>
    </header>
    <article>
      {recentList.length > 0 && (
        <table className="table bordered no-vertical-lines main shadow-hover-effect">
          <thead>
            <tr>
              <th className="w1">{t("modalPrefill.table.0")}</th>
              <th>{t("modalPrefill.table.1")}</th>
              {/* <th>Submitted By</th> */}
              <th>{t("modalPrefill.table.2")}</th>
              <th className="w1">{t("modalPrefill.table.3")}</th>
            </tr>
          </thead>
          <tbody>
            {recentList.map((item: RecentReport) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{t(`status.${item.status}`, { defaultValue: item.status })}</td>
                  {/* <td>SUBMITTED_BY</td> */}
                  <td>
                    <Tippy
                      content={
                        <span>
                          <Moment format={momentTimeDateFormat}>{item.submittedDatetime}</Moment>
                        </span>
                      }
                      placement="bottom">
                      <span>
                        <Moment fromNow interval={30000}>
                          {item.submittedDatetime}
                        </Moment>
                      </span>
                    </Tippy>
                  </td>
                  <td>
                    <Button scale="small" onClick={() => onFill(item.id as string)} data-close-modal>
                      {t("modalPrefill.Apply")}
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {recentList.length === 0 && <p>{t("modalPrefill.noReportsAvailable")}</p>}
    </article>
    <footer>
      <button className="btn link" data-close-modal>
        {t("modalPrefill.Cancel")}
      </button>
    </footer>
  </div>
);

export const ModalPrefill = withTranslation("regionReports")(ModalPrefillWoT);
