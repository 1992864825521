import React from "react";
import Meta from "../components/meta";
import { MetaFooter } from "../components/structure/meta-footer";
import { Badges } from "../components/structure/badges";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import { decodeParams } from "../helpers/params";

import { ReactComponent as Loupe } from "../sass/assets/other/errors/loupe.svg";
import { ReactComponent as Error500 } from "../sass/assets/other/errors/500.svg";
import { ReactComponent as Padlock } from "../sass/assets/other/errors/padlock.svg";

// import { MAIN_ERRORS } from "../lang/error";
import { LOGO } from "../globals/CompanyMeta";

//? TYPES:
import { RouteComponentProps } from "react-router-dom";

// type ErrorCodes = 400 | 401 | 403 | 404 | 500 | 503;
type ErrorCodes = "400" | "401" | "403" | "404" | "500" | "503" | "unknown";
interface ErrorPageParams {
  code?: ErrorCodes;
}

// interface ErrorPageProps
//   extends RouteComponentProps<ErrorPageParams> {
//   code?: ErrorCodes;
// }

type ErrorPageProps = RouteComponentProps<ErrorPageParams>;

const ERROR_IMAGES = {
  404: Loupe,
  400: Loupe,
  401: Padlock,
  403: Padlock,
  500: Error500,
  503: Error500,
  unknown: Error500,
};

// TODO: Redirect url to homepage when in nhsr should go to nhsr.
export const ErrorPage: React.FC<ErrorPageProps> = ({ location, match }) => {
  const { t } = useTranslation();
  const code: ErrorCodes = Object.keys(ERROR_IMAGES).includes(match.params.code as string)
    ? (match.params.code as ErrorCodes)
    : "404";
  const searchParams = decodeParams(location.search);
  const redirectURL = searchParams.redirectURL || "/";
  const error: {
    title: string;
    body: string;
  } = t("httpStatusCodes:" + code, { returnObjects: true });
  // const error = (MAIN_ERRORS as any)[code || "unknown"] || MAIN_ERRORS["unknown"];
  const ErrorPicture = ERROR_IMAGES[code] || <div />;
  return (
    <main id="full-error">
      <Meta title="Error" hideMeta hideFooter />
      <div className="full-error-container">
        <section>
          <div>
            <h1>
              {error.title} - {code}
            </h1>
            <p>{error.body}</p>
            <NavLink className="btn primary large longer" to={redirectURL}>
              {redirectURL === "/" ? t("errors:BACK TO HOMEPAGE") : t("errors:RELOAD")}
            </NavLink>
            {/* {history.location.state && (history.location.state as any).error && (
              <div className="full-error-details">
                <code>{JSON.parse((history.location.state as any).error)}</code>
              </div>
            )} */}
          </div>
          <div>
            <a href={t("meta:URL")} rel="noopener noreferrer" target="_blank" className="logo">
              <LOGO data-reversed={true} />
            </a>
            <div className="image-holder">
              <div className="svg-container">
                <ErrorPicture />
              </div>
            </div>
          </div>
        </section>
        <footer>
          <MetaFooter />
          <Badges />
        </footer>
      </div>
      <style dangerouslySetInnerHTML={{ __html: "#container { min-width: 0; }" }} />
      {/* <style dangerouslySetInnerHTML={{ __html: htmlResets }} /> */}
    </main>
  );
};
