// ? TYPES:
import { DashboardStatus } from "../../types/system-dashboard-state";

export const updateWeight = (
  siteStatus: { [unitName: string]: DashboardStatus },
  weight: number | string | "",
  {
    unitId,
    indicatorId,
    tableId,
    tableRowId,
  }: { unitId: number; indicatorId: number; tableId: number; tableRowId: number }
): { [unitName: string]: DashboardStatus } => {
  const [unitName] = Object.entries(siteStatus).find(([_key, value]) => value.unitId === unitId) || [-1, -1];
  const newData = siteStatus[unitName as keyof typeof siteStatus].data!.map((table: any) => {
    if (table.tableId === tableId) {
      return {
        ...table,
        rowList: table.rowList.map((row: any) => {
          if (row.tableRowId === tableRowId) {
            return {
              ...row,
              indicatorList: row.indicatorList.map((indicator: any | null) => {
                if (indicator?.indicatorId === indicatorId) {
                  return {
                    ...indicator,
                    weight: weight,
                  };
                }
                return indicator;
              }),
            };
          }
          return row;
        }),
      };
    }
    return table;
  });
  return {
    ...siteStatus,
    [unitName]: {
      ...siteStatus[unitName as keyof typeof siteStatus],
      data: newData,
    },
  };
};
