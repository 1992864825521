import React from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "@tscore/react-components";
import { NavLink } from "react-router-dom";
// ? TYPES:
import { ApplicationState } from "../../store/reducers";
import { RouteComponentProps } from "react-router-dom";

interface Props extends Pick<RouteComponentProps<{ pretty?: string }>, "match"> {
  id: number;
}

export const TableauDropdownItems: React.FC<Props> = ({ id, match }) => {
  const { data: tableauReports } = useSelector(
    (state: ApplicationState) =>
      state.integrationsReducer.TABLEAU.DASHBOARD[id] || state.integrationsReducer.TABLEAU.DASHBOARD[-1]
  );
  return (
    <>
      {tableauReports.map((item) => {
        const to = match.params.pretty ? `./${match.params.pretty}/tableau/${item.linkId}` : `./tableau/${item.linkId}`;
        return (
          <Dropdown.Item
            key={item.integrationLinkId}
            label={item.name}
            anchorComponent={({ onClick, ...props }: any) => (
              <NavLink to={item.linkId === "" ? "/error/404" : to} {...props} />
            )}
          />
        );
      })}
    </>
  );
};
