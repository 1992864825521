import { API_START, API_END, API_ERROR } from "../../actions/api.names";
import { defaultApiError } from "../default-api-error";
import { defaultApiLoading } from "../default-api-loading";
import { generateBounds } from "./generate-bounds";
// import { polygonContainsLatLng } from "../../../components/map/helpers/polygonContainsLatLng";
// import pointInPolygon from "point-in-polygon";
// import { polygonsContainLatLng } from "../../../components/map/helpers/polygonContainsLatLng";
// import { defaultApiSuccess } from "../default-api-success";
// import { defaultPagination } from "../default-pagination";
// import { paginate } from "../../../helpers/paginate";
import { BRAG_BG_COLOUR_MAPPING, PRESSURE_LEVEL_BRAG_MAPPING } from "../../../helpers/brag-helpers";
// import { BRAG_PRESSURE_LEVEL_MAPPING, BRAG_COLOURS } from "../../../components/map/consts";
import { generateMarkersWithColours } from "./generate-markers-with-colours";
import { addPropsToMarkersWithinBoundary } from "./add-props-to-markers-within-boundary";
import { homeMapSettings as vantageHomeMapSettings } from "../../../modules/vantage/pages/home/consts";
import { homeMapSettings as electiveHomeMapSettings } from "../../../modules/elective/pages/home/consts";
// ? TYPES:
import { MapsState, OpelColour, NhsrChildResponse, NhsrParentResponse } from "../../types/maps-state";
import { ElectiveMarker, ElectiveSpeciality } from "../../types/maps-elective";
import { AreaPressureLevel, FilterPressureLevel } from "../../../types/google-map";
import { VantageMarker } from "../../types/maps-vantage";

const VANTAGE_INITIAL = {
  idVantage: -1,
  // future proof
  boundary: {
    child: [],
  },
  indicatorGroups: [],
  markers: [],
  ccgs: [],
  bounds: undefined,
  mapSetting: {
    ...vantageHomeMapSettings,
    zoom: undefined,
    minZoom: undefined,
    maxZoom: undefined, // ! would need to rewrite Map component from library to update these values on the fly
  },
  // markerSetting: VANTAGE_MARKER_CONFIG,
};

const ELECTIVE_INITIAL = {
  idVec: -1,
  boundary: {
    child: [],
  },
  segments: [],
  specialities: [],
  indicatorGroups: [],
  markers: [],
  ccgs: [],
  bounds: undefined,
  lastUpdatedName: undefined,
  mapSetting: {
    ...electiveHomeMapSettings,
    zoom: undefined,
    minZoom: undefined,
    maxZoom: undefined, // ! would need to rewrite Map component from library to update these values on the fly
  },
  // isFetchingChildBoundary: {},
};

const defaultState: MapsState = {
  resilienceHome: {
    boundary: {
      child: [],
    },
    hospitals: [],
    bounds: undefined,
  },
  nhsrHome: {
    boundary: {
      child: [],
      parent: [],
    },
    colours: {
      parent: {},
      // child: [],
    },
    hospitals: [],
    bounds: undefined,
  },
  elective: {
    "-1": ELECTIVE_INITIAL,
  },
  vantage: {
    "-1": VANTAGE_INITIAL,
  },
  // success: {},
  isFetching: {
    GET_RESILIENCE_BOUNDARY: true,
    GET_RESILIENCE_HOSPITALS: true,
    GET_NHSR_CHILD_BOUNDARY: true,
    GET_NHSR_HOSPITALS: true,
    GET_ELECTIVE_CHILD_BOUNDARY: true,
    GET_ELECTIVE_INITIAL: true,
    GET_VANTAGE_CHILD_BOUNDARY: true,
    GET_VANTAGE_INITIAL: true,
  },
  errors: {},
  didInvalidate: {
    GET_RESILIENCE_BOUNDARY: true,
    GET_RESILIENCE_HOSPITALS: true,
    GET_NHSR_PARENT_BOUNDARY: true,
    GET_NHSR_CHILD_BOUNDARY: true,
    GET_NHSR_HOSPITALS: true,
    GET_ELECTIVE_INITIAL: true,
    GET_ELECTIVE_CHILD_BOUNDARY: true,
    GET_VANTAGE_INITIAL: true,
    GET_VANTAGE_CHILD_BOUNDARY: true,
  },
};

const DEFAULT_ADDED_MARKER_PROPERTIES = {
  properties: {
    ccg19nm: undefined,
  },
};

const generateLatLngArray = (arr: { latitude: string | number; longitude: string | number; [key: string]: any }[]) => {
  return arr.map((h) => {
    if (h.latitude === 0 && h.longitude === 0) {
      console.warn(
        "Marker's lat & lng; wrongly set to 0, 0.",
        `[idMapMarker: ${h.idMapMarker} (organisation ID)]`,
        `[name: ${h.name}]`
      );
    }
    return {
      lat: parseFloat(h.latitude as string),
      lng: parseFloat(h.longitude as string),
    };
  });
};

const API_LOADER_LIST = [
  "GET_RESILIENCE_BOUNDARY",
  "GET_RESILIENCE_HOSPITALS",
  "GET_NHSR_PARENT_BOUNDARY",
  "GET_NHSR_CHILD_BOUNDARY",
  "GET_NHSR_HOSPITALS",
  "GET_ELECTIVE_INITIAL",
  "GET_ELECTIVE_CHILD_BOUNDARY",
  "UPDATE_ELECTIVE_FILTERS",
  "GET_VANTAGE_INITIAL",
  "GET_VANTAGE_CHILD_BOUNDARY",
  "UPDATE_VANTAGE_FILTERS",
];

const maps = (state: MapsState = defaultState, action: any): MapsState => {
  switch (action.type) {
    case API_START: {
      return defaultApiLoading(state, action, true, API_LOADER_LIST, true);
    }
    case API_ERROR: {
      return defaultApiError(state, action);
    }
    case API_END: {
      return defaultApiLoading(state, action, false, API_LOADER_LIST, true);
    }
    case "GET_RESILIENCE_BOUNDARY": {
      return {
        ...state,
        resilienceHome: {
          ...state.resilienceHome,
          boundary: {
            ...state.resilienceHome.boundary,
            child: action.data.results,
          },
        },
      };
    }
    case "GET_RESILIENCE_HOSPITALS": {
      //
      return {
        ...state,
        resilienceHome: {
          ...state.resilienceHome,
          hospitals: action.data.results,
        },
      };
    }
    case "GET_NHSR_PARENT_BOUNDARY": {
      const parent = (action.data.results as NhsrParentResponse[]).reduce((final: any[], p) => {
        // can be map, unless filtering
        final.push({
          geometries: p.parentGeojson.geometries,
          properties: {
            parentName: p.parentName,
          },
        });
        return final;
      }, []);
      const tempColours = parent.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.properties.parentName]: {
            id: curr.properties.parentName,
            filters: { total: 0 },
            meta: {
              colorCodeBg: "transparent",
            },
          },
        }),
        {}
      );
      const coloursStillFetching = Object.keys(state.nhsrHome.colours.parent).length === 0;
      return {
        ...state,
        nhsrHome: {
          ...state.nhsrHome,
          boundary: {
            ...state.nhsrHome.boundary,
            parent: parent,
          },
          colours: {
            ...state.nhsrHome.colours,
            parent: coloursStillFetching ? tempColours : state.nhsrHome.colours.parent,
          },
        },
      };
    }
    case "GET_NHSR_CHILD_BOUNDARY": {
      const parentColours = (action.data.results as NhsrChildResponse[]).reduce((final, current) => {
        if (!current.color || !current.parentName) {
          return final;
        }
        const colorName = BRAG_BG_COLOUR_MAPPING[(current.color.colorCodeBg || "black").toLowerCase()];
        const colorNumber = PRESSURE_LEVEL_BRAG_MAPPING[colorName];
        const id = current.parentName;
        if (final[id] && final[id].meta && current.color.id !== final[id].meta!.id) {
          console.warn(";;;parent boundary colour mismatch");
        }
        return {
          ...final,
          [id]: {
            id: current.parentName,
            filters: {
              total: colorNumber,
            },
            meta: current.color,
          },
        };
      }, {} as { [id: string]: AreaPressureLevel<FilterPressureLevel, OpelColour> });
      return {
        ...state,
        nhsrHome: {
          ...state.nhsrHome,
          colours: {
            ...state.nhsrHome.colours,
            parent: parentColours,
          },
        },
      };
    }
    case "GET_NHSR_HOSPITALS": {
      return {
        ...state,
        nhsrHome: {
          ...state.nhsrHome,
          hospitals: action.data.results,
        },
      };
    }
    case "SET_RESILIENCE_BOUNDS": {
      const google = action.google;
      // add CCG names to hospital markers by checking if they reside within polygons
      const hospitalsWithCCGName = addPropsToMarkersWithinBoundary(
        state.resilienceHome.hospitals,
        state.resilienceHome.boundary.child,
        DEFAULT_ADDED_MARKER_PROPERTIES
      );
      return {
        ...state,
        resilienceHome: {
          ...state.resilienceHome,
          bounds: generateBounds(generateLatLngArray(state.resilienceHome.hospitals), google),
          hospitals: hospitalsWithCCGName,
        },
      };
    }
    case "SET_NHSR_BOUNDS": {
      const google = action.google;
      // const hospitalsWithCCGName = addPropsToMarkersWithinBoundary(
      //   state.nhsrHome.hospitals,
      //   state.nhsrHome.boundary.child,
      //   DEFAULT_ADDED_MARKER_PROPERTIES
      // );
      return {
        ...state,
        nhsrHome: {
          ...state.nhsrHome,
          bounds: generateBounds(generateLatLngArray(state.nhsrHome.hospitals), google),
          // hospitals: hospitalsWithCCGName,
        },
      };
    }
    case "SET_ELECTIVE_BOUNDS": {
      const id = action.id;
      // const hospitalsWithCCGName = addPropsToMarkersWithinBoundary(
      //   state.electiveHome.markers as any[],
      //   state.electiveHome.boundary.child,
      //   DEFAULT_ADDED_MARKER_PROPERTIES
      // );
      return {
        ...state,
        elective: {
          ...state.elective,
          [id]: {
            ...state.elective[id],
            // markers: hospitalsWithCCGName,
            bounds: generateBounds(generateLatLngArray(state.elective[id].markers as any[]), action.google),
          },
        },
      };
    }
    case "GET_ELECTIVE_INITIAL": {
      const markers = generateMarkersWithColours(action.data.markers) as unknown[] as ElectiveMarker[];
      const id = action.id;
      const dataMs = action.data.mapSetting || {};
      const mapSetting = {
        ...electiveHomeMapSettings,
        ...dataMs,
        zoom: dataMs.initialZoom,
      };
      const initialValues = state.elective[id] ? state.elective[id] : ELECTIVE_INITIAL;
      return {
        ...state,
        elective: {
          ...state.elective,
          [id]: {
            ...initialValues,
            idVec: id,
            ...action.data,
            mapSetting: mapSetting,
            markers,
          },
        },
      };
    }
    case "GET_ELECTIVE_CHILD_BOUNDARY": {
      const id = action.id;
      return {
        ...state,
        elective: {
          ...state.elective,
          [id]: {
            ...state.elective[id],
            boundary: {
              child: action.data,
            },
          },
        },
      };
    }
    case "UPDATE_ELECTIVE_FILTERS": {
      const id = action.id;
      const markers = generateMarkersWithColours(action.data.markers) as unknown[] as ElectiveMarker[];
      // const markers = addPropsToMarkersWithinBoundary(
      //   generateMarkersWithColours(action.data.markers) as any[],
      //   state.electiveHome.boundary.child,
      //   DEFAULT_ADDED_MARKER_PROPERTIES
      // ) as VantageMarker[];
      return {
        ...state,
        elective: {
          ...state.elective,
          [id]: {
            ...state.elective[id],
            // ? Update indicators list, when other filters are changed
            indicatorGroups:
              action.name === "indicatorGroups" ? state.elective[id].indicatorGroups : action.data.indicatorGroups,
            specialities: action.data.specialities,
            segments: action.data.segments,
            markers,
          },
        },
      };
    }
    case "UPDATE_ELECTIVE_FILTERS_LOCAL": {
      const id = action.id;
      const name: "specialities" | "segments" | "indicatorGroups" = action.name;
      const oldValues: any[] = state.elective[id][name];
      if (name === "specialities") {
        const type: "deselect-option" | "select-option" | "clear" | "set-value" = action.action.action;
        const getNewSpecialities = () => {
          if (type === "clear" || type === "set-value") {
            const isSelected = type === "clear" ? false : true;
            return oldValues.map((s: ElectiveSpeciality) => ({
              ...s,
              selected: isSelected,
              subSpecialities:
                s.subSpecialities === null ? null : s.subSpecialities.map((sub) => ({ ...sub, selected: isSelected })),
            }));
          }
          const isParent = typeof action.action.option.idSubSpecialty === "number" ? false : true;
          const isSelected = type === "deselect-option" ? false : true;
          if (isParent) {
            const idSpecialty = action.action.option.idSpecialty;
            // const isSelected = type === "deselect-option" ? false : true;
            return oldValues.map((s: ElectiveSpeciality) => {
              if (s.idSpecialty === idSpecialty) {
                return {
                  ...s,
                  selected: isSelected,
                  subSpecialities:
                    s.subSpecialities === null
                      ? null
                      : s.subSpecialities.map((sub) => ({ ...sub, selected: isSelected })),
                };
              }
              return s;
            });
          }
          const [idSpecialtyStr, idSubSpecialtyStr] = action.action.option.id.match(/\d+/g);
          const idSpecialty = parseFloat(idSpecialtyStr);
          const idSubSpecialty = parseFloat(idSubSpecialtyStr);
          return oldValues.map((s: ElectiveSpeciality) => {
            if (s.idSpecialty === idSpecialty) {
              const isZeroSelected = s.subSpecialities!.filter((s) => s.selected).length <= 1 ? true : false;
              return {
                ...s,
                selected: type === "select-option" ? true : !isZeroSelected,
                subSpecialities: s.subSpecialities!.map((sub) => {
                  if (sub.idSubSpecialty === idSubSpecialty) {
                    return { ...sub, selected: isSelected };
                  }
                  return sub;
                }),
              };
            }
            return s;
          });
        };
        return {
          ...state,
          elective: {
            ...state.elective,
            [id]: {
              ...state.elective[id],
              lastUpdatedName: name,
              specialities: getNewSpecialities(),
            },
          },
        };
      }
      const ID_NAMES = {
        segments: "idSegment",
        specialities: "_not_needed_",
        indicatorGroups: "idIndicatorGroup",
      };
      const idFilter = ID_NAMES[name];
      const ids: number[] = action.selected.map((s: any) => s[idFilter]);
      return {
        ...state,
        elective: {
          ...state.elective,
          [id]: {
            ...state.elective[id],
            lastUpdatedName: name,
            [name]: oldValues.map((item: any) => {
              return {
                ...item,
                selected: ids.includes(item[idFilter]) ? true : false,
              };
            }),
          },
        },
      };
    }
    case "VANTAGE_CLEANUP": {
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          GET_VANTAGE_INITIAL: true,
        },
      };
    }
    case "GET_VANTAGE_INITIAL": {
      const markers = generateMarkersWithColours(action.data.markers) as unknown[] as VantageMarker[];
      const id = action.id;
      const dataMs = action.data.mapSetting || {};
      const mapSetting = {
        ...vantageHomeMapSettings,
        ...dataMs,
        zoom: dataMs.initialZoom,
      };
      const initialValues = state.vantage[id] ? state.vantage[id] : VANTAGE_INITIAL;
      return {
        ...state,
        vantage: {
          ...state.vantage,
          [id]: {
            ...initialValues,
            idVantage: id,
            ...action.data,
            markers,
            mapSetting: mapSetting,
          },
        },
      };
    }
    case "GET_VANTAGE_CHILD_BOUNDARY": {
      const id = action.id;
      return {
        ...state,
        vantage: {
          ...state.vantage,
          [id]: {
            ...state.vantage[id],
            boundary: {
              child: action.data,
            },
          },
        },
      };
    }
    case "SET_VANTAGE_BOUNDS": {
      const id = action.id;
      const prevVantage = state.vantage[id] || {};
      if (!prevVantage.markers) {
        return state;
      }
      // const hospitalsWithCCGName = addPropsToMarkersWithinBoundary(
      //   state.vantage[id].markers as any[],
      //   state.vantage[id].boundary.child,
      //   DEFAULT_ADDED_MARKER_PROPERTIES
      // );
      return {
        ...state,
        vantage: {
          ...state.vantage,
          [id]: {
            ...state.vantage[id],
            // markers: hospitalsWithCCGName,
            bounds: generateBounds(generateLatLngArray(prevVantage.markers as any[]), action.google),
          },
        },
      };
    }
    case "UPDATE_VANTAGE_FILTERS": {
      const id = action.id;
      const markers = generateMarkersWithColours(action.data.markers) as unknown[] as VantageMarker[];
      // const markers = addPropsToMarkersWithinBoundary(
      //   generateMarkersWithColours(action.data.markers) as any[],
      //   state.vantage[id].boundary.child,
      //   DEFAULT_ADDED_MARKER_PROPERTIES
      // ) as VantageMarker[];
      if (!["indicatorGroups", "autorefresh"].includes(action.name)) {
        return state;
      }
      // probably don't need to update as it's only 1 filter
      return {
        ...state,
        vantage: {
          ...state.vantage,
          [id]: {
            ...state.vantage[id],
            // indicatorGroups: action.data.indicatorGroups,
            markers,
          },
        },
      };
    }
    case "UPDATE_VANTAGE_FILTERS_LOCAL": {
      const id = action.id;
      const name: "indicatorGroups" = action.name;
      if (name !== "indicatorGroups") {
        return state;
      }
      const oldValues = state.vantage[id][name];
      const ID_NAMES = {
        indicatorGroups: "idIndicatorGroup",
      };
      const idName = ID_NAMES[name];
      const ids: number[] = action.selected.map((s: any) => s[idName]);
      return {
        ...state,
        vantage: {
          ...state.vantage,
          [id]: {
            ...state.vantage[id],
            [name]: oldValues.map((item: any) => {
              return {
                ...item,
                selected: ids.includes(item[idName]) ? true : false,
              };
            }),
          },
        },
      };
    }
    case "UPDATE_MARKER_DATA_MIDDLEWARE": {
      // ? Will be called twice, when navigating back (can be improved with more business logic using isFetching, but not big deal)
      const id = action.id;
      const mapsKey: Exclude<keyof MapsState, "didInvalidate" | "isFetching" | "errors"> = action.mapsKey;
      // const mapsState = mapsKey !== "vantage" ? state[mapsKey] : state[mapsKey][id];
      const markers = addPropsToMarkersWithinBoundary(
        generateMarkersWithColours(action.data.markers) as any[],
        action.data.child,
        DEFAULT_ADDED_MARKER_PROPERTIES
      ) as ElectiveMarker[];
      if (mapsKey === "vantage") {
        return {
          ...state,
          [mapsKey]: {
            ...state[mapsKey],
            [id]: {
              ...state[mapsKey][id],
              [action.markersOrHospitalsKey]: markers,
            },
          },
        };
      }
      return {
        ...state,
        [mapsKey]: {
          ...state[mapsKey],
          [action.markersOrHospitalsKey]: markers,
        },
      };
    }
    default:
      return state;
  }
};

export default maps;
