import React from "react";
// eslint-disable-next-line
import { NavLink } from "react-router-dom";

export const Anchor: React.FC<any> = ({ to, target, exact, children, ...props }) => {
  if (to.startsWith("http")) {
    return (
      <a href={to} target={target || "_blank"} {...props}>
        {children}
      </a>
    );
  }
  return (
    <NavLink to={to} exact={exact} {...props}>
      {children}
    </NavLink>
  );
};
