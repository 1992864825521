import React from "react";
import { selectComponents } from "@tscore/react-components";
import { FormikSelect, FormikInput } from ".";
// ? TYPES:
import { FormikProps } from "formik";
import { PhoneCountryItem, PhoneCodeList } from "../../types/phone-code";

interface FormikMobileProps {
  mobilePlaceholder?: string;
  codeName?: string;
  mobileName?: string;
  codePlaceholder?: string;
  phoneCodeList: PhoneCodeList;
  maxMenuHeight?: number;
  setFieldValue: FormikProps<any>["setFieldValue"];
  // onKeyDown?: (e: any) => void;
  values: {
    phoneCountryCode: Partial<PhoneCountryItem> | null;
    mobile?: string | null;
    [k: string]: any;
  };
  [k: string]: any;
}

const SingleValuePhoneCode = (props: any) => (
  <selectComponents.SingleValue {...props}>{props.data.code}</selectComponents.SingleValue>
);

export const FormikMobile: React.FC<FormikMobileProps> = ({
  mobilePlaceholder,
  codeName = "phoneCountryCode",
  mobileName = "mobile",
  codePlaceholder = "Code",
  maxMenuHeight = 200,
  phoneCodeList,
  values,
  setFieldValue,
  // onKeyDown,
  ...mobileProps
}) => {
  return (
    <div className="mobile__holder">
      <FormikSelect
        menuPlacement="auto"
        maxMenuHeight={maxMenuHeight}
        placeholder={codePlaceholder}
        getOptionLabel={(option: PhoneCountryItem) => `(${option.code}) ${option.country}`}
        getOptionValue={(option: PhoneCountryItem) => option.code}
        components={{ SingleValue: SingleValuePhoneCode }}
        options={phoneCodeList}
        // isClearable
        setFieldValue={setFieldValue}
        value={values.phoneCountryCode}
        name={codeName}
      />
      <FormikInput
        name={mobileName}
        autoComplete="off"
        value={values.mobile ?? ""}
        {...mobileProps}
        placeholder={mobilePlaceholder}
      />
    </div>
  );
};
