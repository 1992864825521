import React, { useState, useEffect } from "react";
// import { toDataURL } from "qrcode";

interface Props {
  size: number;
  url: string;
  theme?: "light" | "dark"; // can be added
}

const lazyToDataURL = async () => {
  const { toDataURL } = await import(/* webpackChunkName: "qrcode" */ "qrcode");
  return toDataURL;
};

const QrCodeImage: React.FC<Props> = ({ size, url }) => {
  const [{ data, state }, setData] = useState<{
    data: { uri: string };
    state: "idle" | "loading" | "success" | "error";
    error: null | any;
  }>({
    data: { uri: "" },
    state: "loading",
    error: null,
  });
  useEffect(() => {
    async function fetchCode() {
      try {
        const toDataURL = await lazyToDataURL();
        const uri = await toDataURL(url, {
          width: size * 2,
          margin: 0,
          color: { dark: "#00112b", light: "#ffffff" },
        });
        setData((prev) => ({ ...prev, data: { ...prev.data, uri }, state: "success" }));
      } catch (err) {
        setData((prev) => ({ ...prev, state: "error", error: err }));
      }
    }
    if (url) {
      fetchCode();
    }
  }, [url, size, setData]);
  return (
    <div className="qr__code__image" style={{ height: `${size}px`, width: `${size}px` }}>
      {state === "success" ? (
        <img src={data.uri} alt="QR Code" />
      ) : (
        <div className="isFetching">
          <div className="loading-element" style={{ margin: 0 }}></div>
        </div>
      )}
    </div>
  );
};

export default QrCodeImage;
