// import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
// import "@tscore/react-components/dist/sass/styles.scss";
import "./sass/tsMain.scss";
import { Router, Route } from "react-router-dom";
import history from "./history";
import App from "./App";

import { ToastContainer } from "@tscore/react-components";
import NewVersionNotification from "./components/new-version-notification";
import { ModalMfaSetupSuccess } from "./pages/login/mfa/setup/modal-mfa-setup-success";
// import IECheckComponent from "./components/ie-check";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
// import * as serviceWorker from "./serviceWorker";
import { ConfigProvider } from "./providers/config.provider";
import "./info";

//Redux:
import { Provider } from "react-redux";
import store from "./store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ConfigProvider>
          <Router history={history}>
            <Route path="/" render={(props) => <App {...props} />} />
          </Router>
          <NewVersionNotification />
          <ModalMfaSetupSuccess />
          <ToastContainer />
        </ConfigProvider>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
