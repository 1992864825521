import MenuService from "./menu.service";

// ? TYPES:
// import { ApiAction } from "../../types/api";
import { ImportedAccessInfo } from "../../types/menu-state";
import { Breadcrumb } from "../../.../../../types/generic";
import { ApplicationState } from "../../reducers";

export const getMenu = (data: ImportedAccessInfo, cached = false, productList?: string[]) => ({
  type: "GET_MENU",
  data,
  cached,
  productList,
});

export const setSiteModule = (pathname: string) => ({
  type: "SET_SITE_MODULE",
  pathname,
});

export const setSiteMeta = (
  title: string,
  breadcrumbs: Breadcrumb[] = [],
  isFetching = false,
  hideModuleName?: boolean
) => ({
  type: "SET_SITE_META",
  title,
  breadcrumbs,
  isFetching,
  hideModuleName,
});

// export const fetchMenu = (): ApiAction => {
//   return MenuService.getMenu({
//     label: "GET_MENU",
//     onSuccess: getMenu,
//   });
// };

export const fetchMenu = (): Function | any => {
  return function (dispatch: any, getState: () => ApplicationState) {
    const productList = getState().credentialsReducer.app_user_info.productList;
    dispatch(
      MenuService.getMenu({
        label: "GET_MENU",
        onSuccess: (response: any) => getMenu(response, false, productList),
      })
    );
  };
};
