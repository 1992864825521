import { LOGIN_INTERNAL_URLS } from "../../globals/internal-urls";

export const FORGOT_PASSWORD_URL = LOGIN_INTERNAL_URLS.forgotPassword;
export const RESET_PASSWORD_URL = LOGIN_INTERNAL_URLS.resetPassword;
export const LOGIN_URL = LOGIN_INTERNAL_URLS.login;
export const ACTIVATE_ACCOUNT_URL = LOGIN_INTERNAL_URLS.activateAccount;
export const SETUP_MFA_URL = LOGIN_INTERNAL_URLS.setupMfa;
export const ENFORCE_MFA_URL = LOGIN_INTERNAL_URLS.enforceMfa;

// export const TITLES = {
//   LOG_IN: "Sign In",
//   FORGOT_PASSWORD_MAIL: "Forgot Password?",
//   FORGOT_SET_PASSWORD: "Reset Password",
//   ACCOUNT_ACTIVATE: "Activate Account",
// };
