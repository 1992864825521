import React, { useState, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Icon } from "@tscore/react-components";
import MyApps from "./my-apps";
import { SidebarList } from "./sidebar-list";
import { accessEnv } from "../../access-env";
import { useConfig } from "../../providers/config.provider";
//
// import { CONTACT_LINK } from "../../globals/CompanyMeta";
// import { SIDEBAR_FOOTER_ICONS } from "../../globals/SidebarIcons";
import { SidebarResizer } from "../../hooks/sidebar-resizer";
import { Anchor } from "../anchor";
import { useTranslation } from "react-i18next";
import { SidebarProvider } from "../../providers/sidebar.provider";
// ? TYPES:
import { ApplicationState } from "../../store/reducers";
// import { RouteComponentProps } from "react-router-dom";
import { LazyComponent } from "../../types/lazy-component";

const SidebarIconLazy = lazy(
  async () => import(/* webpackChunkName: "sidebar-icons" */ "./sidebar-icon") as unknown as LazyComponent
);

type SidebarProps = any; // RouteComponentProps<any>;

const Sidebar: React.FC<SidebarProps> = () => {
  const { t } = useTranslation();
  const { sidebarCollapsed, toggleSidebarCollapsed } = useConfig();
  const [staticCollapseHovered, setStaticCollapseHovered] = useState(false);
  const isAnyAdmin = useSelector((state: ApplicationState) => !!state.credentialsReducer.genericRoles.ANY_ADMIN);
  const moduleUrlPrefix = useSelector((state: ApplicationState) => state.menuReducer.meta.moduleUrlPrefix);
  const footerList = [
    ...(isAnyAdmin
      ? [
          {
            label: "ADMIN",
            icon: "Admin",
            inverted: false,
            url: document.location.origin + "/admin?origin=resilience",
            className: "any-admin-only",
          },
          {
            label: "STAGING",
            icon: "Staging",
            inverted: false,
            url: "https://staging.e-shrewd.com" + accessEnv("BASENAME"),
            className: "any-admin-only",
          },
        ]
      : []),
    {
      label: "Help",
      icon: "Help",
      inverted: false,
      url: moduleUrlPrefix + "help",
    },
    {
      label: "Contact Us",
      icon: "Contact",
      inverted: false,
      url: t("meta:CONTACT_LINK"),
    },
  ];
  return (
    <>
      <nav
        id="sidebar"
        className={classNames({ "is-collapsed": sidebarCollapsed, "static-collapse-hovered": staticCollapseHovered })}>
        <span className="sidebar-collapse">
          <a href="#!toggle-collapse" onClick={toggleSidebarCollapsed}>
            <span>
              <Icon>arrow_right_alt</Icon>
            </span>
          </a>
        </span>
        <SidebarResizer />
        <div>
          {/* <div className="XXdisplay-header-group"> */}
          <header>
            <MyApps />
          </header>
          {/* </div> */}
          {/* <div className="XXdisplay-row-group"> */}
          <div id="navigation">
            <div>
              <SidebarProvider>
                <SidebarList />
              </SidebarProvider>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="XXdisplay-footer-group footer"> */}
          <footer className="footer">
            <div>
              <ul>
                {footerList.map((item) => (
                  <li className={item.className} key={item.label}>
                    <Anchor exact={true} to={item.url}>
                      <div
                        className={classNames("ico", {
                          invert: item.inverted,
                        })}>
                        <Suspense fallback={<div className="svg-container" />}>
                          <SidebarIconLazy name={item.icon} namespace="SIDEBAR_FOOTER" url={""} />
                        </Suspense>
                        {/* <SVGIcon svg={SIDEBAR_FOOTER_ICONS[item.icon]} /> */}
                      </div>
                      <span>{t("sidebar:footer." + item.label, { defaultValue: item.label })}</span>
                    </Anchor>
                  </li>
                ))}
              </ul>
            </div>
          </footer>
          {/* </div> */}
        </div>
      </nav>
      <div id="x">
        <span
          className="sidebar-collapse-static"
          onMouseEnter={() => setStaticCollapseHovered(true)}
          onMouseLeave={() => setStaticCollapseHovered(false)}>
          <a href="#!toggle-collapse" onClick={toggleSidebarCollapsed}></a>
        </span>
      </div>
    </>
  );
};

export default Sidebar;
