// import jsPDF from "jspdf";

const lazyJsPDF = async () => {
  const { default: jsPDF } = await import(/* webpackChunkName: "jspdf" */ "jspdf");
  return jsPDF;
};

const getPdfDimensions = (
  format: string,
  orientation: "portrait" | "landscape",
  bleed: number,
  width: number,
  height: number
) => {
  const DIMENSIONS_MM = {
    a4: {
      longer: 297,
      shorter: 210,
    },
  };
  if (format === "a4") {
    if (orientation === "landscape") {
      const aspectRatio = width / height;
      const w = DIMENSIONS_MM.a4.longer - bleed * 2;
      const h = w / aspectRatio;
      const heightBleed = (DIMENSIONS_MM.a4.shorter - h) / 2;
      return {
        horizontalBleed: bleed,
        verticalBleed: heightBleed,
        w,
        h,
      };
      //
    } else {
      //
    }
  }
  return {
    horizontalBleed: 0,
    verticalBleed: 0,
    w: 0,
    h: 0,
  };
};

export const downloadImageAsPdf = async (
  base64: string,
  fileName = "unknown.pdf",
  { width, height }: { width: number; height: number }, // in px
  bleed = 20,
  options: {
    orientation: "portrait" | "landscape";
    unit: string;
    format: string;
  } = {
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  }
) => {
  const dimensions = getPdfDimensions(options.format, options.orientation, bleed, width, height);
  // a4: 210 × 297
  // bleed 20
  const jsPDF = await lazyJsPDF();
  const pdf = new jsPDF(options);
  pdf.addImage(base64, "png", dimensions.horizontalBleed, dimensions.verticalBleed, dimensions.w, dimensions.h);
  pdf.save(fileName);
};
