import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Select } from "@tscore/react-components";
import { fetchRequestReport } from "../../store/actions/reports";
import { withTranslation } from "react-i18next";
// import { RBA_PARTIAL } from "./consts";
// ? TYPES:
import { ReportType } from "../../store/types/reports-state";
import { ApplicationState } from "../../store/reducers";
import { TFunction } from "i18next";

const ModalRequestWoT: React.FC<{ reportType: ReportType; t: TFunction }> = ({ reportType, t }) => {
  const [option, setOption] = useState<{
    id: number;
    name: string;
    active?: boolean;
  } | null>(null);
  const dispatch = useDispatch();
  const onRequest = () => {
    dispatch(fetchRequestReport(option!.id, reportType));
  };
  const areaList = useSelector((state: ApplicationState) =>
    state.reportsReducer["all"].apiMeta.areaList
      .filter((a) => a.active)
      .map((a) => ({
        ...a,
        value: a.id,
        label: a.name,
      }))
  );
  const handleChange = (selectedOption: any | any[]): void => {
    setOption(selectedOption);
  };
  useEffect(() => {
    if (areaList.length === 1) {
      setOption(areaList[0]);
    }
  }, [areaList, setOption]);
  return (
    <div>
      <header>
        <h4>{t("Request Report")}</h4>
        {/* <h6>{name}</h6> */}
      </header>
      <article>
        <div className="form">
          <section className="form-row">
            <div className="form-field">
              {/* <label>{list.length <= 1 ? "" : "Select "}Area:</label> */}
              <label>{t("requestModalLabel")}</label>
              <Select options={areaList} onChange={handleChange} value={option} />
            </div>
          </section>
        </div>
      </article>
      <footer className="ta-right">
        <Button disabled={!option} onClick={onRequest} data-close-modal>
          {t("Request")}
        </Button>
      </footer>
    </div>
  );
};

export const ModalRequest = withTranslation("regionReports")(ModalRequestWoT);
