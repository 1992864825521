import React, { useEffect, useReducer, useRef, useState, useCallback, useMemo } from "react";
import useCustomCompareEffect from "../../hooks/use-custom-compare-effect";
import { useEffectOnce } from "../../hooks/use-effect-once";
import { useDispatch, useSelector } from "react-redux";
// import { usePrevious } from "../../hooks/use-previous";
import {
  fetchIndicatorsHistoryIfNeeded,
  cleanupIndicatorsHistory,
  fetchDownloadIndicatorGraphUserValueReport,
  fetchGraphConfigIfNeeded,
} from "../../store/actions/indicator-history";
// import ChartComponent from "react-chartjs-2";
import { Chart as ChartComponent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  BarController,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";
// import "chart.js/auto";
import { Button, Icon, Tippy, Dropdown, Select, Loader, DatePicker, InlineError } from "@tscore/react-components";
import classNames from "classnames";
import moment from "moment";
import { downloadFile, dataURItoAb } from "../../helpers/download-file";
import { slugify } from "../../helpers/slugify";
import { downloadImageAsPdf } from "../../helpers/download-pdf-image";
import { CUSTOM_LABEL } from "../../store/reducers/indicator-history/generate-config";
import { useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";
import { reducer, createInitialState } from "./reducer";
//? TYPES:
import { ApplicationState } from "../../store/reducers";
import { AnyIndicator, IndicatorExtendedSmart } from "../../types/indicator";
import {
  TAggregationItem,
  TFrequencyItem,
  TPeriodItem,
  TBarItem,
  // GraphConfig,
  IndicatorServiceRequestParams,
  ISHExtraParams,
} from "../../store/types/indicator-history-state";

interface IndicatorGraphProps {
  indicators: IndicatorExtendedSmart<AnyIndicator>[];
  // ids: number[];
  comparisonKey?: string;
  title?: string;
  indicatorGroupId?: number;
  extraISHInfo: ISHExtraParams;
}

type ChartRef = any;

const onCompletePlugin = {
  id: "onComplete",
  version: 1,
  defaults: {
    afterRender: () => void 0,
  },
  afterRender: (chart: any, args: any, options: any) => {
    const afterRender = options["afterRender"];
    if (afterRender) {
      afterRender(chart, args, options);
    }
  },
};

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  LinearScale,
  BarController,
  BarElement,
  Tooltip,
  Legend,
  zoomPlugin,
  onCompletePlugin
);

const GRAPH_MIN_HEIGHT = 418;

const DOWNLOAD_SELECT_ITEM_STYLES = { minWidth: "90px" };

const indicatorGraphCSS = `
.ts-chart-wrapper, .ts-chart-wrapper .l-holder, .ts-chart-wrapper .inline-error {
  min-height: ${GRAPH_MIN_HEIGHT}px;
}
.ts-chart-wrapper .l-holder {
  position: absolute;
}
.ts-chart-wrapper .inline-error {
  display: table;
  width: 100%;
}
`;

const GRAPH_TYPE_SWITCHER_VISIBLE = true;

// const aggOptions: { value: ISHAggregation; label: string }[] = Object.entries(AGGREGATION_LANG).map(
//   ([value, label]) => ({
//     value: value as ISHAggregation,
//     label: label as string,
//   })
// );

// const DEFAULT_AGG = aggOptions[0];

function filterDateEnd(date: Date, { start }: { start: string | null; end: string | null }) {
  const isLaterOrEqualThanToday = new Date() >= date;
  if (!start) {
    return isLaterOrEqualThanToday;
  }
  // if (c.startEnd.value.start) {
  const maxDate = moment(start).add(3, "months");
  const minDate = moment(start);
  return minDate.toDate() <= date && maxDate.toDate() >= date && isLaterOrEqualThanToday;
  // }
  // return (
  //   (c.startEnd.value.start ? moment(c.startEnd.value.start).toDate() : new Date()) <= date &&
  //   isLaterOrEqualThanToday
  // );
}

function filterDateStart(date: Date, { end }: { start: string | null; end: string | null }) {
  const isLaterThanToday = new Date() > date;
  if (!end) {
    return isLaterThanToday;
  }
  const minDate = moment(end).subtract(3, "months").subtract(1, "day");
  const maxDate = moment(end);
  return maxDate.toDate() >= date && minDate.toDate() <= date; // && isLaterThanToday;
  // return moment(c.startEnd.value.end).toDate() > date;
}

export const IndicatorGraph: React.FC<IndicatorGraphProps> = ({
  indicators,
  comparisonKey: _initComparisonKey,
  title,
  indicatorGroupId,
  extraISHInfo,
}) => {
  const { t } = useTranslation();
  // ? for now: comparison key with _IG means its indicatorGroupId comparison key
  const [comparisonKey] = useState(() => {
    return _initComparisonKey
      ? _initComparisonKey + (indicatorGroupId ? "_IG" : "")
      : indicators.map((indicator) => indicator.indicatorId).join("-") + (indicatorGroupId ? "_IG" : "");
  });
  const state = useSelector(
    (state: ApplicationState) => state.indicatorHistoryReducer[comparisonKey!] || state.indicatorHistoryReducer[-1]
  );
  const { chartData, options, isFetching, noData, errors, disableAnimation, config } = state;
  const [{ chartLoading, isZoomed, c, chartType, prevChartType, dateRangeState }, localDispatch] = useReducer(
    reducer,
    {
      config,
    },
    createInitialState
  );
  const indicatorsRef = useRef<AnyIndicator[]>(indicators);
  const extraISHInfoRef = useRef(extraISHInfo);
  const chart = useRef<ChartRef>();
  const dispatch = useDispatch();
  const type = useMemo(() => {
    return prevChartType.value || chartType.value;
  }, [prevChartType.value, chartType.value]);
  const updateChart = (option?: TBarItem) => {
    localDispatch({ type: "ON_UPDATE_CHART", chartType: option });
  };
  const resetZoom = useCallback(() => {
    const instance = chart.current;
    if (instance) {
      instance.resetZoom(disableAnimation ? "none" : "active");
      localDispatch({ type: "SET_IS_ZOOMED", isZoomed: false });
    }
  }, [localDispatch, disableAnimation]);
  const fetchInitial = useCallback(
    (props: Omit<IndicatorServiceRequestParams, "ids">) => {
      dispatch(
        fetchIndicatorsHistoryIfNeeded(
          comparisonKey,
          indicatorsRef.current,
          props,
          extraISHInfoRef.current,
          localDispatch
        )
      );
    },
    [dispatch, comparisonKey, indicatorsRef, extraISHInfoRef]
  );
  const fetchGraphConfig = useCallback(() => {
    const typeAndId = indicatorGroupId
      ? { type: "INDICATOR_GROUP" as const, id: indicatorGroupId }
      : { type: "INDICATOR" as const, id: indicators[0].indicatorId };
    dispatch(fetchGraphConfigIfNeeded(comparisonKey, typeAndId.id, typeAndId.type));
  }, [dispatch, comparisonKey, indicators, indicatorGroupId]);
  useCustomCompareEffect(
    () => {
      if (isFetching.GET_GRAPH_CONFIG === true) {
        return;
      }
      if (c.startEnd.value.start && c.startEnd.value.end) {
        localDispatch({ type: "SET_CHART_LOADING", chartLoading: true });
        fetchInitial({
          freq: c.freq.value,
          agg: c.agg.value,
          start: c.startEnd.value.start!,
          end: c.startEnd.value.end!,
          // start: "2019-07-20 15:33:05",
          // end: "2020-07-21 15:33:05",
        });
      }
    },
    [localDispatch, fetchInitial, c, isFetching.GET_GRAPH_CONFIG],
    isEqual
  );
  useCustomCompareEffect(
    () => {
      if (isFetching.GET_GRAPH_CONFIG === false) {
        const freq = config.defaults.freqOptions;
        localDispatch({ type: "ON_FREQ_CHANGE", freq, config });
      }
    },
    [localDispatch, config, isFetching.GET_GRAPH_CONFIG],
    isEqual
  );
  useEffect(() => {
    return () => {
      dispatch(cleanupIndicatorsHistory(comparisonKey));
    };
  }, [dispatch, comparisonKey]);
  useEffectOnce(() => {
    fetchGraphConfig();
  }, [isFetching.GET_GRAPH_CONFIG]);

  const onFreqChange = (option: TFrequencyItem | any, _action: any) => {
    localDispatch({ type: "ON_FREQ_CHANGE", freq: option, config });
  };

  const onRangeChange = (option: TPeriodItem, _action: any) => {
    localDispatch({ type: "ON_RANGE_CHANGE", startEnd: option });
  };

  const onAggChange = (option: TAggregationItem, _action: any) => {
    localDispatch({ type: "ON_AGG_CHANGE", agg: option });
  };

  const onCustomDateRangeChange = (payload: { [startOrEnd: string]: Date | null }, buttonClicked?: string) => {
    localDispatch({ type: "ON_CUSTOM_DATE_RANGE_CHANGE", payload, buttonClicked });
  };

  const downloadRawData = () => {
    const start = c.startEnd.value.start || "ERR";
    const end = c.startEnd.value.end || "ERR";
    dispatch(
      fetchDownloadIndicatorGraphUserValueReport(
        comparisonKey,
        indicators,
        {
          freq: c.freq.value,
          agg: c.agg.value,
          start: start,
          end: end,
        },
        `IndicatorValuesHistory-${comparisonKey}-${c.freq.label}-${c.agg.label}-${slugify(
          `${start.substring(0, 10)}-to-${end.substring(0, 10)}`
        )}`
      )
    );
  };

  const downloadAs = async (type: string) => {
    if (!chart.current) {
      return;
    }
    // .csv / .svg needed?
    // const mime = type !== "pdf" ? "application/octet-stream" : "application/pdf";
    const dateFormat = "DD-MM-YYYY";
    const [start, end] = [
      moment(c.startEnd.value.start).format(dateFormat),
      moment(c.startEnd.value.end).format(dateFormat),
    ];
    const name = `[${comparisonKey}]-${c.freq.label}-graph-${start}-to-${end}`;
    const base64 = chart.current.toBase64Image();
    if (type === "pdf") {
      const { width, height } = chart.current;
      await downloadImageAsPdf(base64, name + ".pdf", { width, height });
    } else {
      await Promise.resolve(downloadFile(dataURItoAb(base64), name + "." + type));
    }
  };

  return (
    <div className="indicator-graph">
      <header>
        <h4>
          {title ||
            t([`ishGraph:title.${c.freq.label}`, "ishGraph:title.default"], {
              frequency: t("ishGraph:config.frequency." + c.freq.label, { defaultValue: c.freq.label }),
              period: t(
                [
                  `ishGraph:config.special.${c.freq.label}.period.${c.startEnd.label}`,
                  `ishGraph:config.default.period.${c.startEnd.label}`,
                ],
                { defaultValue: c.startEnd.label }
              ),
            })}
          {isFetching["GET_INDICATORS_HISTORY"] && <Loader style={{ marginLeft: "12px" }} isInline />}
        </h4>
      </header>
      <article style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        {isFetching["GET_GRAPH_CONFIG"] || errors["GET_GRAPH_CONFIG"] ? (
          <div className="ts-chart-header" style={{ visibility: "hidden" }}>
            <div>
              <input type="text" className="input" placeholder="" />
            </div>
          </div>
        ) : (
          <div className="ts-chart-header">
            <div>
              <Select
                getOptionLabel={(option: TFrequencyItem) =>
                  t("ishGraph:config.frequency." + option.label, { defaultValue: option.label })
                }
                className="ts-chart-header-select"
                onChange={onFreqChange}
                options={config.freqOptions}
                value={c.freq}
                isDisabled={(config.freqOptions || []).length <= 1}
                // defaultValue={config.defaults.freqOptions}
              />
              <Select
                getOptionLabel={(option: TAggregationItem) => {
                  return t(
                    [
                      `ishGraph:config.special.${c.freq.label}.aggregation.${option.label}`,
                      `ishGraph:config.default.aggregation.${option.label}`,
                    ],
                    { defaultValue: option.label }
                  );
                  // if (c.freq.value === "trend" && option.label === "Maximum") {
                  //   return "None";
                  // }
                  // return option.label;
                }}
                className="ts-chart-header-select ts-chart-agg-select"
                onChange={onAggChange}
                options={config.aggregation[c.freq.label]}
                // defaultValue={config.defaults.aggregation[config.defaults.freqOptions.label]}
                value={c.agg}
                isDisabled={(config.aggregation[c.freq.label] || []).length <= 1}
              />
              <Select
                getOptionLabel={(option: TPeriodItem) => {
                  return t(
                    [
                      `ishGraph:config.special.${c.freq.label}.period.${option.label}`,
                      `ishGraph:config.default.period.${option.label}`,
                    ],
                    { defaultValue: option.label }
                  );
                  // if (c.freq.value === "trend") {
                  //   if (option.label === "Today") {
                  //     return "Midnight - Midnight";
                  //   }
                  //   if (option.label === "Since Yesterday") {
                  //     return "Since Yesterday Midnight";
                  //   }
                  // }
                  // return option.label;
                }}
                className="ts-chart-header-select start-end-select mr8"
                onChange={onRangeChange}
                options={config.period[c.freq.label]}
                value={c.startEnd}
                maxMenuHeight={350}
                isDisabled={(config.period[c.freq.label] || []).length <= 1}
              />
              {GRAPH_TYPE_SWITCHER_VISIBLE && (
                <Select
                  getOptionLabel={(option: TBarItem) =>
                    t("ishGraph:config.graphType." + option.label, { defaultValue: option.label })
                  }
                  className="ts-chart-header-select mr8"
                  onChange={updateChart}
                  options={config.graphType[c.freq.label]}
                  // defaultValue={config.graphType[c.freq.label]}
                  value={chartType}
                  isDisabled={(config.graphType[c.freq.label] || []).length <= 1}
                />
              )}
              {/* <div style={{ display: "inline-block", verticalAlign: "top", marginLeft: "12px" }}> */}
              {c.startEnd.label === CUSTOM_LABEL && (
                <div className="react-daterange__wrapper react-daterange__wrapper--has-right-buttons react-daterange__wrapper--has-left-buttons">
                  <Tippy
                    content={t("ishGraph:prevRange", {
                      context: dateRangeState.tooltipContext,
                      count: dateRangeState.tooltipCount,
                    })}
                    placement="bottom">
                    <button
                      disabled={dateRangeState.prev.isDisabled}
                      onClick={() => {
                        onCustomDateRangeChange(dateRangeState.prev.value, "prev");
                      }}
                      style={{ padding: "0 12px" }}
                      className={classNames("react-daterange__button react-daterange__button--no-right-border", {
                        "values-loading": isFetching["GET_INDICATORS_HISTORY"],
                        "last-clicked": dateRangeState.prev.lastButtonClicked,
                      })}>
                      <i className="l inline small"></i>
                      <Icon className="icon">west</Icon>
                    </button>
                  </Tippy>
                  <DatePicker
                    className="ts-chart-header-select"
                    selected={dateRangeState.startAsDate}
                    selectsStart
                    startDate={dateRangeState.startAsDate}
                    endDate={dateRangeState.endAsDate}
                    onChange={(date: Date) => onCustomDateRangeChange({ start: date })}
                    filterDate={(date: Date) => filterDateStart(date, c.startEnd.value)}
                    placeholderText={t("generic:dateFromLabel")}
                    selectInputOnClick
                    strictParsing
                  />
                  <span className="react-daterange__deliminator">-</span>
                  <DatePicker
                    className="ts-chart-header-select"
                    selected={dateRangeState.endAsDate}
                    selectsEnd
                    startDate={dateRangeState.startAsDate}
                    endDate={dateRangeState.endAsDate}
                    onChange={(date: Date) => onCustomDateRangeChange({ end: date })}
                    filterDate={(date: Date) => filterDateEnd(date, c.startEnd.value)}
                    placeholderText={t("generic:dateToLabel")}
                    selectInputOnClick
                    strictParsing
                  />
                  <Tippy content={t("generic:Clear dates")} placement="bottom">
                    <button
                      disabled={dateRangeState.isClearDisabled}
                      className="react-daterange__button react-daterange__button--no-left-border"
                      onClick={() => {
                        onCustomDateRangeChange({ start: null, end: null });
                      }}>
                      <Icon>clear</Icon>
                    </button>
                  </Tippy>
                  <Tippy
                    content={t("ishGraph:nextRange", {
                      context: dateRangeState.tooltipContext,
                      count: dateRangeState.tooltipCount,
                    })}
                    placement="bottom">
                    <button
                      disabled={dateRangeState.next.isDisabled}
                      onClick={() => {
                        onCustomDateRangeChange(dateRangeState.next.value, "next");
                      }}
                      style={{ padding: "0 12px" }}
                      className={classNames("react-daterange__button", {
                        "values-loading": isFetching["GET_INDICATORS_HISTORY"],
                        "last-clicked": dateRangeState.next.lastButtonClicked,
                      })}>
                      <i className="l inline small"></i>
                      <Icon className="icon">east</Icon>
                    </button>
                  </Tippy>
                </div>
              )}
            </div>
            <div>
              {isZoomed && (
                <Button colour="blue-outline" onClick={resetZoom}>
                  {t("ishGraph:Reset Zoom")}
                </Button>
              )}
              <Dropdown
                position="right"
                trigger={
                  <Button
                    hasChevron
                    style={{ minWidth: "150px" }}
                    disabled={chartLoading || !c.startEnd.value.start || !c.startEnd.value.end}
                    colour="lightgray-outline"
                    icon="save_alt"
                    isLoading={isFetching["DOWNLOAD_INDICATOR_GRAPH_USER_VALUE_REPORT"]}>
                    {t("ishGraph:Download as")}
                  </Button>
                }>
                <Dropdown.Item
                  style={DOWNLOAD_SELECT_ITEM_STYLES}
                  label={t("ishGraph:downloadList.png")}
                  onClick={() => downloadAs("png")}
                />
                <Dropdown.Item
                  style={DOWNLOAD_SELECT_ITEM_STYLES}
                  label={t("ishGraph:downloadList.pdf")}
                  onClick={() => downloadAs("pdf")}
                />
                <Dropdown.Item
                  style={DOWNLOAD_SELECT_ITEM_STYLES}
                  label={t("ishGraph:downloadList.xlsx")}
                  onClick={() => downloadRawData()}
                />
              </Dropdown>
            </div>
          </div>
        )}
        <div className="ts-chart-wrapper" style={{ flexGrow: 1 }}>
          {(() => {
            if (errors["GET_GRAPH_CONFIG"] || errors["GET_INDICATORS_HISTORY"]) {
              return (
                <InlineError
                  icon="error_outline"
                  className="middle"
                  title={t("errors:Error")}
                  description={errors["GET_GRAPH_CONFIG"] || errors["GET_INDICATORS_HISTORY"]}
                />
              );
            } else if (
              (chartData.datasets.length === 0 || noData) &&
              (isFetching["GET_INDICATORS_HISTORY"] || isFetching["GET_GRAPH_CONFIG"])
            ) {
              return <Loader scale="large" />;
            } else if (noData) {
              return (
                <InlineError
                  icon="grid_off"
                  className="middle"
                  title={t("ishGraph:noDataTitle")}
                  description={t("ishGraph:noDataDescription", {
                    period: t(
                      [
                        `ishGraph:config.special.${c.freq.label}.period.${c.startEnd.label}`,
                        `ishGraph:config.default.period.${c.startEnd.label}`,
                      ],
                      { defaultValue: c.startEnd.label }
                    ),
                  })}
                />
              );
            } else {
              return (
                <ChartComponent
                  height={GRAPH_MIN_HEIGHT}
                  datasetIdKey="id"
                  // key={key}
                  type={type}
                  ref={chart}
                  data={chartData}
                  options={options}
                  updateMode="show"
                  // getDatasetAtEvent={(e: any) => console.log(e)}
                />
              );
            }
          })()}
        </div>
        <style dangerouslySetInnerHTML={{ __html: indicatorGraphCSS }} />
      </article>
    </div>
  );
};
