import React from "react";
import { Icon } from "@tscore/react-components";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { useDroppable } from "@dnd-kit/core";
//? TYPES:
import { TFunction } from "i18next";

interface PrevNextPageDroppableProps {
  prevOrNext: "prev" | "next";
  isAllowed: boolean;
  t: TFunction;
}

const PrevNextPageDroppableWoT: React.FC<PrevNextPageDroppableProps> = ({ prevOrNext, isAllowed, t }) => {
  if (!isAllowed) {
    return null;
  }
  const { setNodeRef, isOver, active } = useDroppable({
    id: prevOrNext,
    data: {},
  });
  // const isOver2 = (over || {}).id === prevOrNext;
  return (
    <aside
      className={classNames("droppable-proximate-page", { "is-dragging": active })}
      data-type={prevOrNext}
      ref={setNodeRef}>
      <div className={classNames("drop-area indicator-drop", { "drag-active": isOver })}>
        <div>
          <Icon>play_for_work</Icon>
          {t("favouriteDropPrevNextPageMessage", { context: prevOrNext.toUpperCase() })}
        </div>
      </div>
    </aside>
  );
};

export const PrevNextPageDroppable = withTranslation(["manualUpdates"])(PrevNextPageDroppableWoT);
