import React from "react";
// import ChartComponent from "react-chartjs-2";
// import "chart.js/auto";
import { Chart as ChartComponent } from "react-chartjs-2";
import { Chart as ChartJS, BarController, BarElement, CategoryScale } from "chart.js";
import { STATUS_COLOUR_MAPPING } from "./modal-escalation-actions/consts";
import { useTranslation } from "react-i18next";
// ? TYPES:
// import { IndicatorSystem, IndicatorPersonalised } from "../../types/indicator";

ChartJS.register(BarController, BarElement, CategoryScale);
interface ModalEscalationActionsOverviewProps {
  dataset: { [status: string]: number }[];
  title: string;
  datasetLabels: string[];
}

export const ModalEscalationActionsOverview: React.FC<ModalEscalationActionsOverviewProps> = ({
  dataset,
  title,
  datasetLabels,
}) => {
  const { t } = useTranslation();
  const labels = Object.keys(dataset[0] || { a: -1 });
  const colours = labels.map((key: string) => STATUS_COLOUR_MAPPING.getColour(key).backgroundColor);
  const chartData = {
    labels: labels.map((label) => t("escalation:statuses." + label, { defaultValue: "?" })),
    datasets: dataset.map((d, i) => ({
      label: datasetLabels[i] || "?",
      data: Object.values(d),
      backgroundColor: colours,
    })),
  };

  return (
    <div>
      <header>
        <h4>{t("dashboards:modalEscalationActionsOverview", { title: title, context: title })}</h4>
        {/* <h6>{name}</h6> */}
      </header>
      <article style={{ display: "block", position: "relative" }}>
        <div style={{ minHeight: "300px" }}>
          <ChartComponent
            type="bar"
            // ref={chart}
            data={chartData}
            options={{
              // ...DEFAULT_CHART_OPTIONS,
              plugins: {
                legend: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  ticks: {
                    callback: function (value: number, _index: number, _values: any) {
                      return t("dashboards:action", { count: value, defaultValue: "" }) as string;
                    } as any,
                    stepSize: 1,
                  },
                  beginAtZero: true,
                },
              },
              // ...onChartLoaded,
            }}
          />
        </div>
      </article>
    </div>
  );
};
