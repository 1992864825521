import { apiAction } from "../api";
import { SERVICE_URLS } from "../../../globals/service-urls";
import { generateParams } from "../../../helpers/generate-params";
import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";

// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";
import { PaginatedParams } from "../../../types/paginated-params";
import { EditDocumentMeta } from "../../../schemas/document-edit.schema";

const SERVICE_NAME = "resilience-service";
const VERSION = 1;

const getDocumentList = (
  {
    id,
    page = 1,
    limit = DEFAULT_PAGE_LIMIT,
    disablePagination,
    documentName,
    sort,
    //  = {
    //   updatedAt: "ASC",
    // },
    ...other
  }: PaginatedParams & { id: number; documentName?: string },
  callbacks: ApiCallback
): ApiAction => {
  // ! TODO update when sort implementation changes
  const params = {
    page,
    limit,
    disablePagination,
    documentName,
    // sort: generateSort(sort)
    sort: ["-updatedAt", "-documentName"],
    ...other,
  };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS[SERVICE_NAME]}${VERSION}/document/${id}${generateParams(params)}`,
    ...callbacks,
  });
};

const uploadDocuments = (
  { id, files }: { id: number; files: File[] },
  callbacks: ApiCallback,
  onUploadProgress?: Function
): ApiAction => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i += 1) {
    formData.append("files", files[i]);
  }
  // console.log(files);
  return apiAction({
    method: "POST",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
    url: `${SERVICE_URLS[SERVICE_NAME]}${VERSION}/document/${id}`,
    ...(onUploadProgress && { onUploadProgress: (e: any) => onUploadProgress(e) }),
    ...callbacks,
  });
  //"X-Requested-With": "XMLHttpRequest"
};

const downloadDocument = (
  { id, documentId, fileName }: { id: number; documentId: string; fileName: string },
  callbacks: ApiCallback,
  onDownloadProgress?: Function
): ApiAction => {
  const params = { documentId, documentName: fileName };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS[SERVICE_NAME]}${VERSION}/document/${id}/download${generateParams(params)}`,
    responseType: "blob",
    ...(onDownloadProgress && { onDownloadProgress: (e: any) => onDownloadProgress(e) }),
    ...callbacks,
  });
};

const deleteDocument = (
  { id, documentIdList }: { id: number; documentIdList: string[] },
  callbacks: ApiCallback
): ApiAction => {
  const params = { documentIdList };
  return apiAction({
    method: "DELETE",
    url: `${SERVICE_URLS[SERVICE_NAME]}${VERSION}/document/${id}${generateParams(params)}`,
    ...callbacks,
  });
};

const updateDocumentMeta = (
  {
    id,
    values,
  }: {
    id: number;
    values: EditDocumentMeta;
  },
  callbacks: ApiCallback
): ApiAction => {
  return apiAction({
    method: "PUT",
    data: values,
    url: `${SERVICE_URLS[SERVICE_NAME]}${VERSION}/document/${id}`,
    ...callbacks,
  });
};

const DocumentsService = {
  getDocumentList,
  uploadDocuments,
  downloadDocument,
  deleteDocument,
  updateDocumentMeta,
};

export default DocumentsService;
