import isEqual from "lodash/isEqual";
// ? TYPES:

import { AnyIndicator, IndicatorExtended, IndicatorExtendedSmart, IndicatorSystem } from "../../../types/indicator";
import { GetTablesResponse } from "../../types/system-dashboard-state";

// export enum BRAG_TYPE_MAP {
//   STATIC = 1,
//   TIMED = 2,
//   OPEL = 3,
// }

// export type BragType = keyof typeof BRAG_TYPE_MAP;
// export type IdThresholdGroupType =
//   | (typeof BRAG_TYPE_MAP)["STATIC"]
//   | (typeof BRAG_TYPE_MAP)["TIMED"]
//   | (typeof BRAG_TYPE_MAP)["OPEL"];

// ? Joins the same thresholds:
export const makeSmartExtendedIndicator = <T = AnyIndicator>(
  ind: IndicatorExtended<T> | null
): IndicatorExtendedSmart<T> | null => {
  if (!ind) {
    return ind;
  }
  const thresholdDetailsList = ind.thresholdDetailsList ? ind.thresholdDetailsList.filter(Boolean) : []; // in case its null or undefined
  let showAnyThresholdDetails = false;
  const thresholdDetailsSmart: IndicatorExtendedSmart["thresholdDetailsSmart"] = thresholdDetailsList.reduce(
    (acc, currentGroup) => {
      const thresholdGroups = currentGroup.thresholdGroups.reduce((acc, { thresholds, ...times }) => {
        const { startTime, endTime } = {
          startTime: times.startTime.slice(0, 5),
          endTime: times.endTime.slice(0, 5),
        };
        const currentAccIndex = acc.length;
        const prevIndex = currentAccIndex - 1;
        const prev = acc[prevIndex];
        if (prev && isEqual(prev.thresholds, thresholds)) {
          prev.endTime = endTime;
          return acc;
        }
        acc.push({
          startTime,
          endTime,
          thresholds,
        });
        return acc;
      }, [] as IndicatorExtended["thresholdDetailsList"][0]["thresholdGroups"]);
      const showThresholdDetails = currentGroup.idIndicatorThresholdGroupType === 2 && thresholdGroups.length > 0;
      if (showThresholdDetails) {
        showAnyThresholdDetails = true;
      }
      return {
        ...acc,
        [currentGroup.triggerDate.slice(0, 10)]: {
          ...currentGroup,
          thresholdGroups,
          showThresholdDetails,
        },
      };
    },
    {}
  );
  return {
    ...ind,
    thresholdDetailsList,
    thresholdDetailsSmart,
    showAnyThresholdDetails,
  };
};

const getTables = (og: GetTablesResponse<IndicatorExtended<IndicatorSystem>>): GetTablesResponse => {
  return {
    ...og,
    tableList: og.tableList.map((tableItem) => ({
      ...tableItem,
      rowList: tableItem.rowList.map((rowItem) => ({
        ...rowItem,
        indicatorList: rowItem.indicatorList.map(makeSmartExtendedIndicator),
      })),
    })),
  };
};

const SystemDashboardTransform = {
  getTables,
};

export default SystemDashboardTransform;
