import { useEffect, useRef } from "react";

export function useEffectOnce(cb: (conditions: boolean[]) => void, conditions = [true]) {
  const isCalledRef = useRef(false);

  useEffect(() => {
    if (conditions.some((c) => c === true) && !isCalledRef.current) {
      isCalledRef.current = true;
      cb(conditions);
    }
  }, [cb, conditions]);
}
