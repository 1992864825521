import React, { useEffect, useState } from "react";
import Meta from "../../components/meta";
import { useSelector } from "react-redux";
import { DocumentsInner } from "./documents-inner";
import {
  fetchDocuments,
  fetchDownloadDocument,
  fetchDeleteDocuments,
  resetDocumentUploadProgress,
  fetchUploadDocument,
  updateDocumentUploadProgress,
  fetchUpdateDocumentMeta,
} from "../../store/actions/documents";
import { findTitleByNameAndId } from "../../helpers/menu-helpers";
import { useTranslation } from "react-i18next";

//? TYPES:
// import { Document } from "../../types/document";
import { ApplicationState } from "../../store/reducers";
import { RouteComponentProps } from "react-router-dom";

interface Params {
  id: string;
  pretty?: string;
}

type DocumentsPageProps = RouteComponentProps<Params>;

export const DocumentsPage: React.FC<DocumentsPageProps> = (props) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const id = parseInt(props.match.params.id, 10);
  const state = useSelector((state: ApplicationState) => state.documentsReducer[id] || state.documentsReducer[-1]);
  const rba = useSelector(
    (state: ApplicationState) => state.menuReducer.rba["Resilience"][id] || state.menuReducer.rba["Resilience"][-1]
  );
  const menuState = useSelector((state: ApplicationState) => state.menuReducer);
  const { isFetching, menu } = menuState;
  useEffect(() => {
    if (isFetching === false) {
      setTitle(findTitleByNameAndId(menu["Resilience"], "Documents", id));
    }
  }, [isFetching, id, setTitle, menu]);
  return (
    <>
      <Meta isFetching={isFetching} title={title} breadcrumbs={t("documents:breadcrumbs", { returnObjects: true })} />
      <DocumentsInner
        id={id}
        fetchDocuments={fetchDocuments}
        fetchDownloadDocument={fetchDownloadDocument}
        fetchDeleteDocuments={fetchDeleteDocuments}
        resetDocumentUploadProgress={resetDocumentUploadProgress}
        fetchUploadDocument={fetchUploadDocument}
        updateDocumentUploadProgress={updateDocumentUploadProgress}
        fetchUpdateDocumentMeta={fetchUpdateDocumentMeta}
        state={state}
        rba={{
          canDeleteDocument: !!rba.DOCUMENTS_DELETE,
          canUploadDocument: !!rba.DOCUMENTS_UPLOAD,
        }}
        {...props}
      />
    </>
  );
};
