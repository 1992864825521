// import React from "react";
import { VANTAGE_INTERNAL_URLS } from "../../globals/internal-urls";
//
import { MapPage } from "./pages/home";
import { HomePage } from "./pages/home-reroute";
import { WholeSystemResiliencePage } from "./pages/vantage-dashboards";

// ? TYPES:
import { RouteProps } from "react-router";

// const SamplePage = () => null;

export const vantageRoutes: RouteProps[] = [
  {
    exact: true,
    path: VANTAGE_INTERNAL_URLS.vantagehome,
    component: HomePage,
  },
  {
    path: `${VANTAGE_INTERNAL_URLS.vantageMenuList}/:id/:pretty?`,
    component: MapPage,
  },
  {
    path: `${VANTAGE_INTERNAL_URLS.vantageDashboardList}/:id/:siteId/:pretty?`,
    component: WholeSystemResiliencePage,
  },
];
