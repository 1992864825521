import { apiAction } from "../api";
import { SERVICE_URLS } from "../../../globals/service-urls";
import { generateParams } from "../../../helpers/generate-params";
import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";

// ? TYPES:
import { ApiCallback, ApiAction } from "../../types/api";
import { PaginatedParams } from "../../../types/paginated-params";
import { NhsrAlert, EscalationAlertUpdate, WeeklyPattern } from "../../types/alerts-state";

const VERSION = 2;

const getEscalationAlerts = (
  {
    page = 1,
    limit = DEFAULT_PAGE_LIMIT,
    disablePagination,
    indicatorSearchParam,
    userSearchParam = undefined,
  }: PaginatedParams & { indicatorSearchParam?: string; userSearchParam?: string },
  callbacks: ApiCallback
): ApiAction => {
  const type = "resilience";
  const params = {
    page,
    limit,
    disablePagination,
    indicatorSearchParam,
    userSearchParam,
  };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["escalation-service"]}/alert-settings/${type}${generateParams(params)}`,
    ...callbacks,
  });
};

const updateEscalationAlertsBatch = (
  {
    values,
  }: {
    values: EscalationAlertUpdate[];
  },
  callbacks: ApiCallback
): ApiAction => {
  return apiAction({
    method: "PUT",
    data: { values: values },
    url: `${SERVICE_URLS["escalation-service"]}/alert-settings/batch`,
    ...callbacks,
  });
};

const getNhsrAlerts = (
  { page = 1, limit = DEFAULT_PAGE_LIMIT, disablePagination = true }: PaginatedParams,
  callbacks: ApiCallback
): ApiAction => {
  const params = {
    page,
    limit,
    disablePagination,
  };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["nhsr-service"]}${VERSION}/user/alertSettings${generateParams(params)}`,
    ...callbacks,
  });
};

const updateNhsrAlertsBatch = (
  {
    values,
  }: {
    values: NhsrAlert[];
  },
  callbacks: ApiCallback
): ApiAction => {
  return apiAction({
    method: "PUT",
    data: { values: values },
    // data: values,
    url: `${SERVICE_URLS["nhsr-service"]}${VERSION}/user/alertSettings`,
    ...callbacks,
  });
};

const getWeeklyPattern = (callbacks: ApiCallback): ApiAction => {
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["user-service"]}/weekly-pattern`,
    ...callbacks,
  });
};

const updateWeeklyPattern = (
  { values }: { values: WeeklyPattern<undefined | string>[] },
  callbacks: ApiCallback
): ApiAction => {
  return apiAction({
    method: "PUT",
    data: { availableTimeSlotList: values },
    url: `${SERVICE_URLS["user-service"]}/weekly-pattern`,
    ...callbacks,
  });
};

const AlertsService = {
  getEscalationAlerts,
  updateEscalationAlertsBatch,
  getNhsrAlerts,
  updateNhsrAlertsBatch,
  getWeeklyPattern,
  updateWeeklyPattern,
};

export default AlertsService;
