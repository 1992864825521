export const defaultApiLoading = (
  state: any,
  action: any,
  isApiStart: boolean,
  labelsToCheck?: string[],
  withInvalidate = false,
  clearErrors = false
): any => {
  if (!labelsToCheck || labelsToCheck.includes(action.payload)) {
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        [action.payload]: isApiStart,
      },
      ...(clearErrors && {
        errors: {
          ...state.errors,
          [action.payload]: false,
        },
      }),
      ...(withInvalidate &&
        !isApiStart && {
          didInvalidate: {
            ...state.didInvalidate,
            [action.payload]: false,
          },
        }),
    };
  }
  return state;
};
