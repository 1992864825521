import React from "react";
import { accessEnv } from "../../../access-env";
import { PLATFORMS } from "./consts";
//
import { SVGResilience, SVGNHSEngland, SVGElective, SVGVantage, SVGWaitless } from "../../../globals/product-icons";

// import { ReactComponent as SVGICS } from "sass/assets/product-icons/puzzle.svg";

// import { ReactComponent as SVGApple } from "../../../sass/assets/other/apple-icon.svg";

import waitlessPreview from "../../../sass/assets/app-previews/waitless_preview.png";
// import saferPreview from "../../../sass/assets/app-previews/safer_preview.png";
import NHSEPreview from "../../../sass/assets/app-previews/NHSE_preview.png";
import escalationPreview from "../../../sass/assets/app-previews/escalation_preview.png";
import electivePreview from "../../../sass/assets/app-previews/elective_preview.png";
import vantagePreview from "../../../sass/assets/app-previews/vantage_preview.png";
// import naPreview from "../../../sass/assets/app-previews/na_preview.png";
import { MODULE_URLS, MODULE_IDS, WAITLESS_ADMIN_URL } from "../../../globals/internal-urls";

const APP_IDS = [
  MODULE_IDS.Resilience,
  // "SAFER",
  "Escalation",
  MODULE_IDS["NHS Region"],
  // "WaitLess",
  MODULE_IDS.Elective,
  MODULE_IDS.Vantage,
  MODULE_IDS.WaitLess,
  "WaitLess Admin",
] as const;
type TAppId = (typeof APP_IDS)[number];

export interface TMyApp {
  id: TAppId; // used for locale mapping
  // name: TAppName;
  // description?: string; // 34 chars max
  icon?: any | React.ReactNode;
  url?: string;
  newTab?: boolean;
  isDisabled: boolean;
  disabledMeta: {
    img: string;
    // text: string;
    platforms: { icon: string; name: string }[];
  };
  children: Omit<TMyApp, "children">[]; // | undefined;
}

export const APPS: TMyApp[] = [
  {
    id: MODULE_IDS["Resilience"],
    // name: MODULE_NAMES["Resilience"],
    // description: "Live view of system pressure",
    icon: <SVGResilience />,
    url: "/", //process.env.PUBLIC_URL,
    newTab: false,
    isDisabled: false,
    disabledMeta: {
      img: "",
      // text: "",
      platforms: [],
    },
    children: [
      // {
      //   name: "SAFER",
      //   isDisabled: true,
      //   disabledMeta: {
      //     img: saferPreview,
      //     text:
      //       "The SAFER tool within SHREWD Resilience provides configurable functionality to easily capture and display the five element of the SAFER patient flow bundle and Red2Green. The tools enables data to be collected and analysed at both hospital and ward level to help users easily recognise reasons for delay and the issues affecting flow.",
      //     platforms: [PLATFORMS.Desktop],
      //   },
      // },
      {
        id: "Escalation",
        // name: "Escalation",
        isDisabled: true,
        disabledMeta: {
          img: escalationPreview,
          // text: "The Escalation tool within SHREWD Resilience provides a digital solution for facilitating a whole system, real time response to growing pressure across a whole health economy. As pressure builds in the health and social care system, SHREWD Escalation pushes an alert along with a pre-planned action to front line teams - in real time – using high impact, auditable actions.",
          platforms: [PLATFORMS.Desktop],
        },
      },
    ],
  },
  {
    id: MODULE_IDS["NHS Region"],
    // name: MODULE_NAMES["NHS Region"],
    // description: "Oversight dashboards and reporting",
    icon: <SVGNHSEngland />,
    url: MODULE_URLS["NHS Region"],
    newTab: false,
    isDisabled: false,
    disabledMeta: {
      img: NHSEPreview,
      // text: "The NHSE Reporting tool helps improve oversight and automated reporting of pressure across a NHSE region. It provides the ability for NHSE Regional Teams to see pressure by OPEL rating and drill into the detail of pressure to automatically generate relevant reports from operational information provided by Health Systems, without the time consuming need to ask for this information.",
      platforms: [PLATFORMS.Desktop],
    },
    children: [],
  },
  {
    id: MODULE_IDS["Elective"],
    // name: MODULE_NAMES["Elective"],
    // description: "Pressure in planned care pathways",
    icon: <SVGElective />,
    url: MODULE_URLS["Elective"],
    newTab: false,
    isDisabled: true,
    disabledMeta: {
      img: electivePreview,
      // text: "SHREWD Elective transforms live data from multiple systems and providers to provide a clear view of the pressure in planned care pathways. This overview allows users to focus on where support is required to improve patient waiting times. SHREWD Elective displays real-time data in a simple heatmap, making it easy to quickly identify areas of pressure and capacity available.",
      platforms: [PLATFORMS.Desktop],
    },
    children: [],
  },
  {
    id: MODULE_IDS["Vantage"],
    // name: MODULE_NAMES["Vantage"],
    // description: "Localised heat map view of pressure",
    icon: <SVGVantage />,
    url: MODULE_URLS["Vantage"],
    newTab: false,
    isDisabled: true,
    disabledMeta: {
      img: vantagePreview,
      // text: "SHREWD Vantage buys time, by allowing decision-makers to quickly view demand and resource levels, and take action to alleviate pressure across multiple sites.",
      platforms: [PLATFORMS.Desktop],
    },
    children: [],
  },
  // {
  //   name: "Integrated Care Systems",
  //   description: "Process and project management",
  //   icon: <SVGICS />,
  //   url: "http://google.co.uk",
  //   newTab: true,
  //   isDisabled: false
  // },
  ...(accessEnv("DEPLOYMENT_TYPE") === "uk"
    ? [
        {
          id: MODULE_IDS["WaitLess"],
          icon: <SVGWaitless />,
          url: WAITLESS_ADMIN_URL,
          newTab: true,
          isDisabled: false,
          disabledMeta: {
            // img: "",
            img: waitlessPreview,
            platforms: [PLATFORMS.Desktop, PLATFORMS.Android, PLATFORMS.iOS],
          },
          children: [
            // {
            //   id: "WaitLess Admin",
            //   isDisabled: true,
            //   disabledMeta: {
            //     img: waitlessPreview,
            //     platforms: [PLATFORMS.Desktop],
            //   },
            // },
          ],
        } as TMyApp,
      ]
    : []),
];
