import React from "react";
import { Dropdown } from "@tscore/react-components";

interface Props {
  ptlView?: boolean;
}

const PTL_LINK = "https://sussex-ptl.beautifulinformation.org/PTL.Models.WLMDS/index?origin=shrewd";

export const PtlDropdownItem: React.FC<Props> = ({ ptlView }) => {
  if (!ptlView) {
    return null;
  }
  return <Dropdown.Item target="_blank" iconRight="launch" label="Patient Tracking List (PTL)" href={PTL_LINK} />;
};
