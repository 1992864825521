import { updateParams } from "../../../helpers/params";
import { generateSortFieldParams } from "../../../helpers/generate-params";
import { useHistory, useLocation } from "react-router-dom";
import { useSearchParams } from "../../../hooks/use-search-params";
// ? TYPES:
import { PaginatedSort } from "../../../types/paginated-params";

export function useFilterChange() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useSearchParams();
  const updateFilters = (newValues: { [key: string]: string | number | boolean | undefined }) => {
    history.push({
      search: updateParams(location.search, {
        ...newValues,
        page: location.search.indexOf("page=") > 0 ? 1 : undefined,
      }),
    });
  };
  const clearFilters = (filters: string[]) => () => {
    const filtersToClear = filters.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: undefined,
      };
    }, {});
    updateFilters(filtersToClear);
  };
  const toggleSort = (sortName: string | undefined) => () => {
    if (!sortName) {
      return;
    }
    const currentValue = searchParams.parsedSort[sortName];
    const newValue = currentValue === undefined ? "DESC" : currentValue === "DESC" ? "ASC" : undefined;
    const newSort = {
      ...searchParams.parsedSort,
      [sortName]: newValue,
    } as Partial<PaginatedSort>;
    history.push({
      search: updateParams(location.search, {
        sort: generateSortFieldParams(newSort),
      }),
    });
  };
  return { updateFilters, clearFilters, toggleSort };
}
