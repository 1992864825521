export const NAMES = {
  GET_DOCUMENTS: "NHSR_GET_DOCUMENTS",
  UPLOAD_MULTIPLE_DOCUMENTS: "NHSR_UPLOAD_MULTIPLE_DOCUMENTS",
  UPLOAD_DOCUMENT: "NHSR_UPLOAD_DOCUMENT",
  UPDATE_DOCUMENT_UPLOAD_PROGRESS: "NHSR_UPDATE_DOCUMENT_UPLOAD_PROGRESS",
  UPDATE_DOCUMENT_DOWNLOAD_PROGRESS: "NHSR_UPDATE_DOCUMENT_DOWNLOAD_PROGRESS",
  RESET_DOCUMENT_UPLOAD_PROGRESS: "NHSR_RESET_DOCUMENT_UPLOAD_PROGRESS",
  DOWNLOAD_DOCUMENT: "NHSR_DOWNLOAD_DOCUMENT",
  DELETE_DOCUMENTS: "NHSR_DELETE_DOCUMENTS",
  UPDATE_DOCUMENT_META: "NHSR_UPDATE_DOCUMENT_META",
};
