import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";

export const hoursMinutesNow = () => {
  return setHours(setMinutes(new Date(), new Date().getMinutes()), new Date().getHours());
};

export const hoursMinutesMidnight = () => {
  return setHours(setMinutes(new Date(), 59), 23);
};

export const isToday = (someDate: Date): boolean => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export { setMinutes, setHours };
