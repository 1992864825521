import React, { useEffect } from "react";
import debounce from "lodash/debounce";
import { resizeSidebar } from "../helpers/resize-sidebar";

// ? Component, not hook atm
// ! To be rewritten as a hook

export const SidebarResizer: React.FC<{}> = () => {
  useEffect(() => {
    resizeSidebar();
    const efficientResizeCheck: EventListener | EventListenerObject = debounce(() => {
      resizeSidebar();
    }, 250);
    window.addEventListener("resize", efficientResizeCheck, true);
    // Specify how to clean up after this effect:
    return () => window.removeEventListener("resize", efficientResizeCheck);
  });
  return null;
};
