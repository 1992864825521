import React from "react";
import { Button } from "@tscore/react-components";
import { FieldInlineError } from "../../components/forms";
import { Field } from "formik";
import { COMPLETED_STATUS } from "./consts";
import { withTranslation } from "react-i18next";
//? TYPES:
// import { TFunction } from "i18next";

const AdditionalQuestionListFormWoT: React.FC<any> = ({ t, ...props }) => {
  const {
    isFetching,
    renderType,
    // indicators,
    // onKeyDown,
    // receivedErrors,
    // touched,
    errors,
    // dirty,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    // submitCount,
    reportStatus,
    values,
  } = props;
  const handleChange = (value: any, index: number, newValue: string) => {
    const updatedValue = { ...value, answer: newValue };
    setFieldValue(`additionalQuestionList.${index}`, updatedValue);
  };
  return (
    <form className="test-form" autoComplete="off" onSubmit={handleSubmit}>
      <div className="form-main">
        <div className="form-holder" data-ft="section">
          <div className="element element-section">
            <header>
              <h2>{t("additionalQuestionListForm.title")}</h2>
              <p>{t("additionalQuestionListForm.subtitle")}</p>
            </header>
            {/* <section className="answer-undefined"></section> */}
          </div>
          <div className="form-children">
            {values.additionalQuestionList.map((item: { question: string; answer: string }, index: number) => {
              return (
                <div key={item.question} className="form-holder" data-ft="multiLine" data-vdt="string">
                  <div className="element element-multiLine">
                    <header>
                      <h3>
                        {item.question}
                        <span className="isRequired">*</span>
                      </h3>
                    </header>
                    <section className={"answer-" + renderType}>
                      <Field
                        name={`additionalQuestionList.${index}`}
                        render={({ field }: any) => {
                          if (reportStatus !== COMPLETED_STATUS) {
                            return (
                              <>
                                <textarea
                                  className="input"
                                  style={{ minHeight: "5.2rem" }}
                                  onChange={(e) => handleChange(field.value, index, e.target.value)}
                                  value={field.value.answer}
                                />
                                <FieldInlineError error={errors[index]} />
                              </>
                            );
                          }
                          return <div className="view-accent">{field.value.answer}</div>;
                        }}
                      />
                    </section>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* <OptionsList options={elements} {...props} /> */}
      {reportStatus !== COMPLETED_STATUS && (
        <footer className="ta-right mt24">
          <Button type="submit" length="longer" isLoading={isSubmitting} disabled={isFetching}>
            {t("additionalQuestionListForm.confirm")}
          </Button>
        </footer>
      )}
    </form>
  );
};

export const AdditionalQuestionListForm = withTranslation("regionReports")(AdditionalQuestionListFormWoT);
