import { fetchAuthorizeIfNeeded } from "../actions/auth";
import { CHECK_TOKEN_URL } from "../actions/auth/consts";

export const refreshInfo = {
  UNAUTHORISED_EXEMPT_LIST: ["sentry.io", "googleapis.com", "amazonaws.com", "static.e-shrewd.com"],
  isExempt: function (baseUrl: string | undefined) {
    if (!baseUrl) {
      return false;
    }
    if (this.UNAUTHORISED_EXEMPT_LIST.some((item) => baseUrl.includes(item))) {
      return true;
    }
    return false;
  },
  reset: function () {
    // this.requestUnauthorisedQueue = [];
    // this.lastUnauthorisedAction = null;
    this.setIsRefreshing(false);
    return true;
  },
  // resetTimeout: null as NodeJS.Timeout | null,
  // delayedReset: function (timeout = 3000) {
  //   if (this.resetTimeout) {
  //     clearTimeout(this.resetTimeout);
  //   }
  //   this.resetTimeout = setTimeout(() => {
  //     this.reset();
  //     this.resetTimeout = null;
  //   }, timeout);
  // },
  replayInProgress: false,
  replay: function (dispatch: Function) {
    if (this.replayInProgress) {
      return;
    }
    if (this.requestUnauthorisedQueue.length === 0) {
      return;
    }
    this.replayInProgress = true;

    // const lastUnauthorisedAction = this.requestUnauthorisedQueue.at(-1);
    // console.log([...this.requestUnauthorisedQueue]);
    while (this.requestUnauthorisedQueue.length > 0) {
      const act = this.requestUnauthorisedQueue.shift();
      // console.log({ act });
      act.payload.url === CHECK_TOKEN_URL ? dispatch(fetchAuthorizeIfNeeded(false)) : dispatch(act);
    }

    this.replayInProgress = false;

    if (this.requestUnauthorisedQueue.length > 0) {
      // not sure if this check is needed with while loop
      this.replay(dispatch);
    }
    // else {
    //   this.lastUnauthorisedAction = lastUnauthorisedAction;
    // }
  },
  replayIfNeeded: function (dispatch: Function) {
    if (this.requestUnauthorisedQueue.length > 0 && !refreshInfo.isRefreshingAccessToken) {
      this.replay(dispatch);
    }
  },
  // resetIfNeeded: function (action: any) {
  //   if (refreshInfo.lastUnauthorisedAction === action) {
  //     this.reset();
  //     return true;
  //   }
  //   return false;
  // },
  // resetOrReplay: function (action: any, dispatch: Function) {
  //   if (!this.isRefreshingAccessToken && this.requestUnauthorisedQueue.length > 0) {
  //     this.replay(dispatch);
  //   } else {
  //     this.reset();
  //   }
  // },
  // checkTimeout: null as NodeJS.Timeout | null,
  // delayedCheck: function (action: any, dispatch: Function, timeout = 5000) {
  //   if (this.checkTimeout) {
  //     clearTimeout(this.checkTimeout);
  //   }
  //   this.checkTimeout = setTimeout(() => {
  //     this.resetOrReplay(action, dispatch);
  //     this.checkTimeout = null;
  //   }, timeout);
  // },
  // lastUnauthorisedAction: null,
  requestUnauthorisedQueue: [] as any[],
  isRefreshingAccessToken: false,
  lastRefreshedAt: 0,
  getTimestamp: function () {
    return new Date().getTime();
  },
  getLastRefreshedAtDiff: function () {
    return this.getTimestamp() - this.lastRefreshedAt;
  },
  setIsRefreshing: function (isRefreshing: boolean) {
    this.isRefreshingAccessToken = isRefreshing;
    if (isRefreshing) {
      this.lastRefreshedAt = this.getTimestamp();
    }
  },
};
