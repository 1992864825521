import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { fetchRegisterMfa } from "../../../../store/actions/auth";
import { AppMetaTrigger } from "./popover-app-meta";
import QRCodeImage from "./qrcode-image";
import { Step } from "./../step";
import { AnotherMethod } from "./another-method";
import { Verify } from "./../verify";
import { joinErrors } from "../../../../helpers/join-errors";
// ? TYPES:
import { SetupStageProps } from "../../../../types/login-form";
import { ApplicationState } from "../../../../store/reducers";

const Key: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <kbd>
    <samp>{children}</samp>
  </kbd>
);

const StageTotpWoT: React.FC<SetupStageProps> = ({
  handleSubmit,
  values,
  setValue,
  rememberDays,
  isLoadingVerify,
  errors,
  mfaState,
  t,
}) => {
  const [showCode, setShowCode] = useState(false);
  const dispatch = useDispatch();
  const apiErrors = useSelector((state: ApplicationState) => state.credentialsReducer.errors);
  const fieldErrorsWithApi = joinErrors(
    errors as { [k: string]: string },
    apiErrors["VERIFY_REGISTER_MFA"] ? [{ field: "mfaCode", error: apiErrors["VERIFY_REGISTER_MFA"] }] : []
  );
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchRegisterMfa("TOTP"));
    }
    if (!mfaState.totpDetails.otpauthUri) {
      fetchInitial();
    }
  }, [dispatch, mfaState.totpDetails.otpauthUri]);
  const toggleCode = (_e: any) => {
    setShowCode((prev) => !prev);
  };
  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="box">
        <Step no={1}>
          <p>
            <Trans
              ns="login"
              i18nKey={`mfa.setup.TOTP.steps.0`}
              components={[<AppMetaTrigger key={0}>modal</AppMetaTrigger>]}></Trans>
          </p>
        </Step>
        <hr />
        <Step no={2}>
          <p>
            <Trans
              ns="login"
              i18nKey={`mfa.setup.TOTP.steps.1`}
              components={[<Key key={0}>+</Key>, <Key key={1}>qr</Key>]}></Trans>
          </p>
          <div className="qr__code">
            <QRCodeImage size={132} url={mfaState.totpDetails.otpauthUri} />
          </div>
          <div className="qr__code__wrapper qr__code__wrapper--info" data-expanded={showCode}>
            <button
              className="as-link"
              type="button"
              aria-expanded={showCode}
              aria-controls="qr__code__accordion"
              onClick={toggleCode}>
              {t("mfa.setup.TOTP.cantScanQrButton")}
            </button>
            <div id="qr__code__accordion" className="qr__code__accordion" aria-hidden={!showCode}>
              {/* onTransitionEnd={(e) => {
                if (e.propertyName === "grid-template-columns") {
                  console.log("transition end", e.propertyName);
                }
              }}> */}
              <div>
                <p className="mb8">{t("mfa.setup.TOTP.cantScanDescription")}</p>
                <div className="qr__code__manual">
                  <div>
                    <label>{t("mfa.setup.TOTP.Account")}</label>
                    <code style={{ wordBreak: "break-all" }}>{mfaState.totpDetails.label}</code>
                  </div>
                  <div>
                    <label>{t("mfa.setup.TOTP.Key")}</label>
                    <code style={{ letterSpacing: "2px" }}>{mfaState.totpDetails.passcodePretty}</code>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Step>
        <hr />
        <Step no={3}>
          <Verify
            isLoading={isLoadingVerify}
            error={fieldErrorsWithApi["mfaCode"]}
            values={values}
            setValue={setValue}
            rememberDays={rememberDays}>
            <p>{t("mfa.setup.TOTP.steps.2")}</p>
          </Verify>
        </Step>
      </div>
      <AnotherMethod />
    </form>
  );
};

export const StageTotp = withTranslation(["login"])(StageTotpWoT);
