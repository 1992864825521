import { WINTER_REPORT_COSMETIC_NAME } from "../../../pages/reports/consts";
import { listGenerator } from "./list-generator";
import { INTERNAL_URLS } from "../../../globals/internal-urls";
import { generateOtherReportMenu } from "./generate-other-report-menu";
import { generateVecMenu } from "./generate-vec-report-menu";
// ? TYPES:
import {
  ImportedAccessInfo,
  ImportedMenu,
  MenuMap,
  NHSRMenuMap,
  ElectiveMenuMap,
  ListGeneratorSettings,
  // ImportedOtherReportOrganisation,
} from "../../types/menu-state";
import { TInternalUrlKey } from "../../../globals/internal-urls";

type ServiceNames = keyof ImportedAccessInfo;
export const generateMenu = (
  menuMap: MenuMap | NHSRMenuMap | ElectiveMenuMap,
  data: ImportedAccessInfo
): ImportedMenu[] => {
  const extractList = (
    serviceName: ServiceNames | undefined,
    listName: string | undefined,
    url: string,
    settings: ListGeneratorSettings = {}
  ) => {
    if (!serviceName || !listName) {
      return [];
    }
    if (serviceName === "nhsr-service") {
      listName = listName.substring(4); // ! remove 'nhsr' from start
      // do nhsr service menu
      // return [];
    }
    if (!data[serviceName] || !(data as any)[serviceName][listName]) {
      return [];
    }
    if ((data as any)[serviceName][listName].length === 0) {
      return [];
    }
    return listGenerator((data as any)[serviceName][listName], url, settings);
  };
  const extractSingle = (
    serviceName: ServiceNames | undefined,
    listName: string | undefined,
    url: string,
    settings: ListGeneratorSettings = {}
  ) => {
    if (!serviceName || !listName) {
      return null;
    }
    if (serviceName === "nhsr-service") {
      listName = listName.substring(4); // ! remove 'nhsr' from start
    }
    try {
      const organisationSingle = (data as any)[serviceName][listName];
      return listGenerator([organisationSingle], url, settings)[0];
    } catch {
      return null;
    }
    // return listGenerator((data as any)[serviceName][listName], url, settings);
  };

  return Object.entries(menuMap).reduce((final: ImportedMenu[], [categoryName, list]) => {
    let shouldShowCategory = false;
    const oneMenu = Object.entries(list || {}).reduce((finalOne: any, [key, one]: [string, any]) => {
      const url = INTERNAL_URLS[key as TInternalUrlKey];
      const twoMenu = one.menu === null ? null : extractList(one.serviceName, key, url, one.urlSettings);
      // const hasAnythingInList = !!twoMenu && twoMenu.length > 0;
      const OTHER_REPORT_LIST_KEYS = {
        resilience: "otherReportOrganisationList",
        nhsr: "nhsrotherReportOrganisationList",
      };
      // const dataNhsr = data["nhsr-service"] || { winterReportOrganisationList: [] };
      // if (
      //   key === "nhsrregionalReport" &&
      //   dataNhsr.winterReportOrganisationList.filter((value) => value.feature?.WINTER_REPORT_REQUESTER === true)
      //     .length === 0
      // ) {
      //   // ? if none winter reports are requester dont add regional menu
      //   return finalOne;
      // }
      // if (
      //   key === OTHER_REPORT_LIST_KEYS.nhsr &&
      //   dataNhsr.otherReportOrganisationList.filter((value) => value.feature?.OTHER_REPORT_REQUESTER === true)
      //     .length === 0
      // ) {
      //   // ? if none other reports are requester dont add regional menu
      //   return finalOne;
      // }
      if (key.endsWith("Single") && one.serviceName) {
        const single = extractSingle(one.serviceName, key, url, one.urlSettings);
        if (single) {
          shouldShowCategory = true;
          finalOne!.push({
            name: single.name,
            url: single.url,
            inverted: one.inverted,
            menu: null,
          });
        }
        return finalOne;
      }
      // vantage & elective list:
      const VEC_LIST_KEYS = {
        vantage: "vantageMenuList",
        elective: "electiveMenuList",
      };
      if (Object.values(VEC_LIST_KEYS).includes(key)) {
        if (twoMenu && twoMenu.length > 0) {
          shouldShowCategory = true;
        }
        const addedFinalX = generateVecMenu([...finalOne!], one, url, twoMenu!);
        finalOne = [...addedFinalX];
      } else if (Object.values(OTHER_REPORT_LIST_KEYS).includes(key)) {
        if (twoMenu && twoMenu.length > 0) {
          shouldShowCategory = true;
        }
        const addedFinal = generateOtherReportMenu(
          [...finalOne!],
          one,
          url,
          twoMenu!,
          key === OTHER_REPORT_LIST_KEYS.resilience ? true : false
        );
        finalOne = [...addedFinal];
      } else if (twoMenu === null || (!!twoMenu && twoMenu.length > 0)) {
        shouldShowCategory = true;
        const amendedUrl = url.startsWith(INTERNAL_URLS.winterReportOrganisationList)
          ? url + "/any/" + WINTER_REPORT_COSMETIC_NAME
          : url;
        finalOne!.push({
          name: one.name,
          url: amendedUrl,
          inverted: one.inverted,
          menu: twoMenu === null || twoMenu.length === 0 ? null : twoMenu,
        });
      }
      return finalOne;
    }, []);
    if (shouldShowCategory) {
      final.push({
        name: categoryName,
        menu: oneMenu,
      });
    }
    return final;
  }, []);
};
