import React from "react";
import { StageTotp } from "./stage-totp";
import { StageSms } from "./stage-sms";
import { StageEmail } from "./stage-email";
// ? TYPES:
import { SetupStagePropsBase } from "../../../../types/login-form";
// React.ComponentProps<typeof StageTotp>

export const WhichStage = (props: SetupStagePropsBase) => {
  if (props.mfaType === "TOTP") {
    return <StageTotp {...props} />;
  }
  if (props.mfaType === "SMS") {
    return <StageSms {...props} />;
  }
  return <StageEmail {...props} />;
};
