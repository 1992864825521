import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchWeeklyPattern, fetchUpdateWeeklyPattern } from "../../../store/actions/alerts";
import { Checkbox, TimePicker } from "@tscore/react-components";
// import { formatToDate } from "../../../lang/DateTimeFormats";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { momentTimeFormat } from "../../../lang/DateTimeFormats";
//? TYPES:
import { ApplicationState } from "../../../store/reducers";
import { WeeklyPattern } from "../../../store/types/alerts-state";
// import { RouteComponentProps } from "react-router-dom";

// type TabEscalationAlertsProps = {};
// type TabEscalationAlertsProps = RouteComponentProps<any>;

const MAIN_GET_NAME = "GET_WEEKLY_PATTERN";

// const PLACEHOLDER_DATE = "2000-12-12TX:00Z";

const getHoursAndMinutesFromString = (timeString: string): [number, number] => {
  return timeString.split(":").map((x) => parseFloat(x)) as [number, number];
};

function setLocalTime(timeString: string, d: Date = new Date()): Date {
  const [hours, minutes] = getHoursAndMinutesFromString(timeString);
  const m = moment(d).local();
  m.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 });
  return m.toDate();
  // d.setHours(hours);
  // d.setMinutes(minutes);
  // return d;
}

function getLocalTime(d: Date): string {
  return moment(d).local().format(momentTimeFormat);
  // en-GB
  // return d.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });
}

const EmptyHourHolder = () => (
  <div className="isFetching hour-holder">
    <div className="loading-element react-time-wrapper"></div>
    <div className="minus" />
    <div className="loading-element react-time-wrapper"></div>
  </div>
);

const generateTime = (int: number, isEnd = false): string => {
  if (int > 24) {
    // if db wrong
    int = 24;
  }
  return isEnd && int === 24 ? "23:59" : ("0" + int).slice(-2) + ":00";
};

export const TabWeeklyPattern: React.FC = () => {
  const { t } = useTranslation();
  const [slots, setSlots] = useState<WeeklyPattern<string>[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchWeeklyPattern());
    }
    fetchInitial();
  }, [dispatch]);
  const state = useSelector((state: ApplicationState) => state.alertsReducer);
  const { availableTimeSlotList } = state;
  const isFetching = state.isFetching[MAIN_GET_NAME];
  useEffect(() => {
    // console.log("setting slots");
    setSlots(availableTimeSlotList);
  }, [availableTimeSlotList, setSlots]);
  const updateLocalSlot = (
    i: number,
    updatedPartial: any,
    timeSlots: WeeklyPattern<string>[]
  ): WeeklyPattern<string>[] => {
    return timeSlots.map((slot, si) => {
      if (si === i) {
        return {
          ...slot,
          ...updatedPartial,
        };
      }
      return slot;
    });
  };
  const updateSlot = (i: number, updatedPartial: any): void => {
    setSlots((prevState) => {
      const newSlots = updateLocalSlot(i, updatedPartial, prevState);
      dispatch(fetchUpdateWeeklyPattern(newSlots));
      return newSlots;
    });
  };
  const onTimeChange = (date: Date, i: number, type: "startTime" | "endTime") => {
    const localTime = getLocalTime(date);
    const [hours, minutes] = getHoursAndMinutesFromString(localTime);
    // const hours = date.getHours();
    // const minutes = date.getMinutes();
    const newTimeInt = hours === 23 && minutes === 59 ? 24 : hours;
    // console.log({ date, hours, minutes });
    updateSlot(i, { [type]: newTimeInt, isEnabled: true });
  };
  const onCheckboxToggle = (i: number) => {
    const isEnabled = slots[i].isEnabled ? true : false;
    // console.log("xd");
    updateSlot(i, { isEnabled: !isEnabled });
    return true;
  };
  return (
    <div className="account-section">
      <hr style={{ marginTop: "-1.2rem", visibility: "hidden" }} />
      <section>
        <aside>
          <p>{t("myAccount:weeklyWorkPatternAside")}</p>
        </aside>
        <div className={`days-hours${isFetching ? " isFetching" : ""}`}>
          {slots.map((slot, i) => {
            const startTime = generateTime(slot.startTime);
            const endTime = generateTime(slot.endTime, true);
            const largestMaxTime = setLocalTime("23:59"); // formatToDate(PLACEHOLDER_DATE.replace("X", "23:59"))!;
            const smallestMinTime = setLocalTime("00:00"); // formatToDate(PLACEHOLDER_DATE.replace("X", "00:00"))!;
            const maxTime = setLocalTime(generateTime(slot.endTime - 1)); // formatToDate(PLACEHOLDER_DATE.replace("X", generateTime(slot.endTime - 1)));
            const minTime = setLocalTime(generateTime(slot.startTime + 1)); // formatToDate(PLACEHOLDER_DATE.replace("X", generateTime(slot.startTime + 1)));
            const name = t("generic:days." + i);
            return (
              <div className={slot.dayOfWeek === 6 || slot.dayOfWeek === 7 ? "isWeekend" : ""} key={slot.dayOfWeek}>
                <div>
                  <Checkbox disabled={isFetching} onCheckboxToggle={() => onCheckboxToggle(i)} checked={slot.isEnabled}>
                    {name}
                  </Checkbox>
                </div>
                {isFetching ? (
                  <EmptyHourHolder />
                ) : (
                  <div className="hour-holder">
                    <div className="react-time-wrapper">
                      <TimePicker
                        selected={setLocalTime(startTime)}
                        onChange={(date: Date) => onTimeChange(date, i, "startTime")}
                        minTime={smallestMinTime}
                        maxTime={maxTime}
                        timeIntervals={60}
                      />
                    </div>
                    <div className="minus" />
                    <div className="react-time-wrapper">
                      <TimePicker
                        selected={setLocalTime(endTime)}
                        onChange={(date: Date) => onTimeChange(date, i, "endTime")}
                        minTime={minTime}
                        maxTime={largestMaxTime}
                        timeIntervals={60}
                        injectTimes={[largestMaxTime]}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};
