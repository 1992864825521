import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { fetchEscalationAlerts, fetchUpdateEscalationAlerts } from "../../../store/actions/alerts";
import {
  fetchPersonalisedIndicators,
  fetchToggleIndicatorAlerts,
} from "../../../store/actions/personalised-indicators";
import { getToggleUpdatedAlerts } from "../../home/personalised-indicators";
import { Button, Input, Modal, Checkbox, InlineError } from "@tscore/react-components";
import { MODAL_INDICATOR_SETUP_STYLES, MODAL_INDICATOR_SETUP_STYLES_UPDATE } from "../../home/consts";
import { ModalIndicatorSetup } from "../../home/modal-indicator-setup";
import Pagination from "../../../components/structure/pagination";
import { decodeParams, searchUpdateQuery } from "../../../helpers/params";
import { navigateToPage } from "../../../helpers/change-page";
import debounce from "lodash/debounce";
import { DEFAULT_DEBOUNCE_LOCAL_SEARCH_MS } from "../../../globals/settings";
import { paginate } from "../../../helpers/paginate";
import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";
import { searchByKeys } from "../../../helpers/search-by-keys";
// import { TabWeeklyPattern } from "./tab-weekly-pattern";
import { useTranslation } from "react-i18next";
//? TYPES:
import { ApplicationState } from "../../../store/reducers";
import { RouteComponentProps } from "react-router-dom";
import { IndicatorExtendedSmart, IndicatorPersonalised } from "../../../types/indicator";

// type TabEscalationAlertsProps = {};
type TabPersonalisedAlertsProps = RouteComponentProps<any>;
type Alert = IndicatorExtendedSmart<IndicatorPersonalised> & { organisationId: number; organisationName: string };

const MAIN_GET_NAME = "GET_PERSONALISED_INDICATORS";

const EmptyAlertRow = () => (
  <tr className="isFetching">
    <td>
      <div className="loading-element">1234</div>
    </td>
    <td>
      <div className="loading-element">XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</div>
    </td>
    <td className="ta-center w1">
      <div className="loading-element">.</div>
    </td>
  </tr>
);

export const TabPersonalisedAlerts: React.FC<TabPersonalisedAlertsProps> = ({ history, location }) => {
  const { t } = useTranslation();
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const searchParams = decodeParams(location.search);
  const page = parseInt(searchParams.page, 10) || 1;
  const query: string | undefined = searchParams.query;
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchInitial() {
      await dispatch(fetchPersonalisedIndicators());
    }
    fetchInitial();
  }, [dispatch]);
  const state = useSelector((state: ApplicationState) => state.personalisedIndicatorsReducer);
  const { isFetching, indicatorGroups } = state;
  useEffect(() => {
    const newAlerts: Alert[] = indicatorGroups.reduce((final: Alert[], current) => {
      const { indicatorList, ...added } = current;
      const newIndicators: Alert[] = current.indicatorList.map((indicator) => {
        return {
          ...indicator,
          ...added,
        };
      });
      return [...final, ...newIndicators];
    }, []);
    const queriedAlerts =
      typeof query !== "undefined"
        ? searchByKeys(newAlerts, query, ["indicatorId", "indicatorName", "organisationName", "organisationId"])
        : newAlerts;
    setAlerts(queriedAlerts);
  }, [setAlerts, indicatorGroups, query]);
  const searchIndicator = (value: string | undefined) => searchUpdateQuery(value, history, location);
  const debouncedSearch = debounce(searchIndicator, DEFAULT_DEBOUNCE_LOCAL_SEARCH_MS);
  const meta = paginate(alerts.length, page, DEFAULT_PAGE_LIMIT);
  const paginatedAlerts = alerts.slice(meta.showingFrom() - 1, meta.showingFrom() + DEFAULT_PAGE_LIMIT - 1);
  const changePage = (page: number) => navigateToPage(history, location.search, page);
  const pageChanging = (_page: number): boolean => {
    return true;
  };
  const onAlertChange = (
    _e: any,
    organisationId: number,
    indicators: (typeof indicatorGroups)[number]["indicatorList"]
  ) => {
    dispatch(fetchToggleIndicatorAlerts(organisationId, getToggleUpdatedAlerts(indicators)));
    return true;
  };
  // const areAllSelected = paginatedAlerts.every((indicator) => indicator.alertEnabled);
  // const areAllSelected =
  //   indicatorGroups.length === 0
  //     ? false
  //     : indicatorGroups.every((group) => group.indicatorList.every((alert) => alert.alertEnabled));
  return (
    <div>
      <header className="mb12">
        {/* <h3></h3> */}
        <div className="alert-table-header">
          <span
            className="constrain-text"
            dangerouslySetInnerHTML={{
              __html: t("myAccount:constrainMessage"),
            }}
          />
          <Modal
            style={MODAL_INDICATOR_SETUP_STYLES}
            trigger={<Button colour="green">{t("home:Add Indicators")}</Button>}>
            <ModalIndicatorSetup title={t("home:Set Personalised Indicators")} />
          </Modal>
          <Input
            // ref={searchInputRef}
            icon="search"
            placeholder={t("generic:Search")}
            id="searchInput"
            autoComplete="escalation_alerts_search"
            onChange={(e: any) => debouncedSearch(e.target.value)}
            holderStyle={{ maxWidth: "240px" }}
            defaultValue={query || ""}
            isLoading={isFetching[MAIN_GET_NAME] && !!query}
          />
          {/* <Button
            onClick={() => onAllAlertToggle(areAllSelected)}
            disabled={indicatorGroups.length === 0}
            colour="link"
            length="shorter">
            {areAllSelected ? t("generic:Deselect All") : t("generic:Select All")}
          </Button> */}
        </div>
      </header>
      {!isFetching[MAIN_GET_NAME] && paginatedAlerts.length === 0 ? (
        <InlineError
          style={{ height: "30vh" }}
          className="middle"
          icon="grid_off"
          title={t("myAccount:No Personalized Indicators")}
        />
      ) : (
        <section>
          <table className="table main no-vertical-lines bordered">
            <thead>
              <tr>
                <th className="w1 nowrap">{t("myAccount:personalisedTable.0")}</th>
                <th>{t("myAccount:personalisedTable.1")}</th>
                <th className="w1 nowrap ta-center">{t("myAccount:personalisedTable.2")}</th>
              </tr>
            </thead>
            <tbody>
              {isFetching[MAIN_GET_NAME] && indicatorGroups.length === 0 ? (
                <EmptyAlertRow />
              ) : (
                paginatedAlerts.map((indicator, i) => {
                  const previousAlert = paginatedAlerts[i - 1] || {
                    organisationId: -1,
                  };
                  const showSubtitle = previousAlert.organisationId !== indicator.organisationId;
                  return (
                    <Fragment key={indicator.indicatorId}>
                      {showSubtitle && (
                        <tr>
                          <th className="subheading" style={{ paddingTop: "3px", paddingBottom: "3px" }} colSpan={3}>
                            <div className="display-table" style={{ width: "100%" }}>
                              <div className="display-cell v-mid">{indicator.organisationName}</div>
                              <div className="display-cell v-mid ta-right">
                                <Modal
                                  style={MODAL_INDICATOR_SETUP_STYLES_UPDATE}
                                  trigger={
                                    <Button colour="link" length="short" scale="small">
                                      {t("home:Edit Indicators")}
                                    </Button>
                                  }>
                                  <ModalIndicatorSetup
                                    title={t("home:Edit Personalised Indicators")}
                                    subtitle={indicator.organisationName}
                                    type="update"
                                    updateSettings={{
                                      organisationId: indicator.organisationId,
                                      indicators:
                                        indicatorGroups.find(
                                          (group) => group.organisationId === indicator.organisationId
                                        )?.indicatorList || [],
                                    }}
                                  />
                                </Modal>
                              </div>
                            </div>
                          </th>
                        </tr>
                      )}
                      <tr key={indicator.indicatorId}>
                        <td>{indicator.indicatorId}</td>
                        <td>{indicator.indicatorName}</td>
                        <td className="ta-center">
                          <Checkbox
                            isToggle
                            name="alertEnabled"
                            value={indicator.indicatorId}
                            checked={indicator.alertEnabled}
                            onCheckboxToggle={(e: any) => onAlertChange(e, indicator.organisationId, [indicator])}
                          />
                        </td>
                      </tr>
                    </Fragment>
                  );
                })
              )}
            </tbody>
          </table>
          <Pagination
            label={t("myAccount:personalisedIndicatorsPaginationLabel")}
            settings={meta}
            onChangePage={(page: number) => changePage(page)}
            onChangingPage={(page: number) => pageChanging(page)}
          />
        </section>
      )}
    </div>
  );
};
