import React from "react";
import { CURRENT_YEAR } from "../../globals/CompanyMeta";
// import { LANGUAGE_SWITCHER } from "../../globals/settings";
import { useTranslation, Trans } from "react-i18next";

// ? TYPES:
import { ModalRefMethods } from "../../types/modal";
import { RefObject } from "react";

interface FooterProps {
  cookieModalRef: RefObject<ModalRefMethods>;
  // languageModalRef: RefObject<ModalRefMethods>;
}

const Footer: React.FC<FooterProps> = ({ cookieModalRef }) => {
  const { t } = useTranslation();

  const openModal = (e?: any) => {
    if (cookieModalRef.current) {
      cookieModalRef.current.show();
    }
    e.preventDefault();
  };
  // const openLanguageModal = (e?: any) => {
  //   if (languageModalRef.current) {
  //     languageModalRef.current.show();
  //   }
  //   e.preventDefault();
  // };
  return (
    <footer id="footer">
      <div>
        <a href="#!privacy-and-cookies" onClick={openModal}>
          {t("footer:Privacy and cookies")}
        </a>
        {" | "}
        {/* {LANGUAGE_SWITCHER && (
          <>
            <a href="#!change-language" onClick={openLanguageModal}>
              {t("footer:Change Language", { language: i18n.language })}
            </a>
            {" | "}
          </>
        )} */}
        {/* <span
          dangerouslySetInnerHTML={{ __html: t("footer:Copyrights", { copySymbol: "&copy;", year: CURRENT_YEAR }) }}
        /> */}
        <span className="copyrights__wrapper">
          <Trans
            i18nKey="footer:Copyrights"
            values={{ copySymbol: "&copy;", year: CURRENT_YEAR }}
            shouldUnescape={true}
            components={[
              <a key={0} href={t("meta:URL")} target="_blank" rel="noopener noreferrer">
                VH
              </a>,
            ]}></Trans>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
