export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const readableToBytes = (str: string | undefined): number => {
  if (!str) {
    return -1;
  }
  const KB_FACTOR = 1024;
  const MB_FACTOR = 1024 * KB_FACTOR;
  const GB_FACTOR = 1024 * MB_FACTOR;
  const [numberString, type] = str.split(" ");
  const number = parseFloat(numberString);
  switch (type) {
    case "GB":
      return number * GB_FACTOR;
    case "MB":
      return number * MB_FACTOR;
    case "KB":
      return number * KB_FACTOR;
  }
  return -1;
};
